import React from 'react'

export function AddWeekIcon() {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00033 12.8333C10.222 12.8333 12.8337 10.2217 12.8337 7.00001C12.8337 3.77834 10.222 1.16667 7.00033 1.16667C3.77866 1.16667 1.16699 3.77834 1.16699 7.00001C1.16699 10.2217 3.77866 12.8333 7.00033 12.8333Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.16667"
      />
      <path d="M7 4.66667V9.33334" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.16667" />
      <path d="M4.66699 7H9.33366" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.16667" />
    </svg>
  )
}

export function AddShiftIcon() {
  return (
    <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.00033 12.8333C10.222 12.8333 12.8337 10.2217 12.8337 7.00001C12.8337 3.77834 10.222 1.16667 7.00033 1.16667C3.77866 1.16667 1.16699 3.77834 1.16699 7.00001C1.16699 10.2217 3.77866 12.8333 7.00033 12.8333Z"
        stroke="#7D8793"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.16667"
      />
      <path
        d="M7 4.66667V9.33334"
        stroke="#7D8793"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.16667"
      />
      <path
        d="M4.66699 7H9.33366"
        stroke="#7D8793"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.16667"
      />
    </svg>
  )
}

export function BreachIcon() {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" fill="white" r="8" />
      <path
        d="M7.12264 3.21025L2.77905 10.4615C2.68949 10.6166 2.64211 10.7925 2.64161 10.9715C2.6411 11.1506 2.6875 11.3267 2.77619 11.4823C2.86487 11.6379 2.99275 11.7676 3.1471 11.8584C3.30145 11.9492 3.47689 11.998 3.65597 12H12.3432C12.5222 11.998 12.6977 11.9492 12.852 11.8584C13.0064 11.7676 13.1342 11.6379 13.2229 11.4823C13.3116 11.3267 13.358 11.1506 13.3575 10.9715C13.357 10.7925 13.3096 10.6166 13.2201 10.4615L8.87648 3.21025C8.78506 3.05954 8.65634 2.93493 8.50274 2.84845C8.34913 2.76197 8.17583 2.71654 7.99956 2.71654C7.82329 2.71654 7.64999 2.76197 7.49638 2.84845C7.34278 2.93493 7.21406 3.05954 7.12264 3.21025Z"
        fill="#DC5A54"
      />
      <path
        clipRule="evenodd"
        d="M8.51197 5.84615C8.51197 5.56293 8.28237 5.33333 7.99915 5.33333C7.71593 5.33333 7.48633 5.56293 7.48633 5.84615V7.89743C7.48633 8.18065 7.71593 8.41025 7.99915 8.41025C8.28237 8.41025 8.51197 8.18065 8.51197 7.89743V5.84615ZM7.99915 9.43589C7.71593 9.43589 7.48633 9.66549 7.48633 9.94871C7.48633 10.2319 7.71593 10.4615 7.99915 10.4615H8.00428C8.2875 10.4615 8.5171 10.2319 8.5171 9.94871C8.5171 9.66549 8.2875 9.43589 8.00428 9.43589H7.99915Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function CreateBlockIcon() {
  return (
    <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
        fill="#1E2D36"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function EditIcon() {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M3.71319 5C2.21474 5 1 6.21474 1 7.71319V16.0868C1 17.5853 2.21473 18.8 3.71319 18.8H20.0868C21.5853 18.8 22.8 17.5853 22.8 16.0868V7.71319C22.8 6.21474 21.5853 5 20.0868 5H3.71319ZM2.8 7.71319C2.8 7.20885 3.20885 6.8 3.71319 6.8H10V9.74066C10 10.6019 10.6981 11.3 11.5593 11.3H18.2407C19.1019 11.3 19.8 10.6019 19.8 9.74066V6.8H20.0868C20.5912 6.8 21 7.20885 21 7.71319V16.0868C21 16.5912 20.5912 17 20.0868 17H3.71319C3.20885 17 2.8 16.5912 2.8 16.0868V7.71319ZM4.8 13.1C4.30294 13.1 3.9 13.5029 3.9 14C3.9 14.4971 4.30294 14.9 4.8 14.9H19C19.4971 14.9 19.9 14.4971 19.9 14C19.9 13.5029 19.4971 13.1 19 13.1H4.8ZM3.9 10.5C3.9 10.0029 4.30294 9.6 4.8 9.6L8 9.6C8.49706 9.6 8.9 10.0029 8.9 10.5C8.9 10.9971 8.49706 11.4 8 11.4H4.8C4.30294 11.4 3.9 10.9971 3.9 10.5ZM11.8 9.5V7.3H18V9.5H11.8Z"
        fill="#7D8793"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function ExportBlockIcon() {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5"
          stroke="#9CA5B0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          d="M5.83341 8.33333L10.0001 12.5L14.1667 8.33333"
          stroke="#9CA5B0"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path d="M10 12.5L10 2.5" stroke="#9CA5B0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </svg>
  )
}

export function AddIcon() {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99996 18.3334C14.6023 18.3334 18.3333 14.6024 18.3333 10C18.3333 5.39765 14.6023 1.66669 9.99996 1.66669C5.39759 1.66669 1.66663 5.39765 1.66663 10C1.66663 14.6024 5.39759 18.3334 9.99996 18.3334Z"
        stroke="#646E7B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path d="M10 6.66669L10 13.3334" stroke="#646E7B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M6.66663 10L13.3333 10" stroke="#646E7B" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}

export function PublishBlockIcon() {
  return (
    <svg fill="none" height="20" viewBox="0 0 21 20" width="21" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.84779 2.6792C10.4253 2.32277 12.0759 2.48584 13.5532 3.14409C13.9735 3.3314 14.4662 3.14246 14.6535 2.72206C14.8408 2.30167 14.6519 1.80902 14.2315 1.62171C12.4259 0.817181 10.4086 0.617872 8.48049 1.05351C6.55237 1.48914 4.81672 2.53637 3.53241 4.03902C2.24809 5.54167 1.48392 7.41922 1.35385 9.39166C1.22379 11.3641 1.73481 13.3257 2.8107 14.984C3.88658 16.6423 5.46969 17.9084 7.32392 18.5934C9.17814 19.2784 11.2041 19.3457 13.0997 18.7853C14.9953 18.2248 16.659 17.0666 17.8425 15.4834C19.0261 13.9002 19.6662 11.9763 19.6673 9.99956V9.2329C19.6673 8.77266 19.2942 8.39956 18.834 8.39956C18.3738 8.39956 18.0007 8.77266 18.0007 9.2329V9.99909C17.9997 11.6164 17.476 13.1901 16.5077 14.4855C15.5393 15.7808 14.1781 16.7284 12.6272 17.187C11.0762 17.6456 9.4186 17.5905 7.90151 17.03C6.38441 16.4695 5.08914 15.4337 4.20887 14.0769C3.3286 12.7201 2.9105 11.1151 3.01691 9.50132C3.12332 7.88751 3.74856 6.35133 4.79936 5.12189C5.85017 3.89245 7.27024 3.03562 8.84779 2.6792ZM19.4235 3.92186C19.7488 3.59626 19.7485 3.06862 19.4229 2.74335C19.0973 2.41807 18.5697 2.41834 18.2444 2.74394L10.5004 10.4958L8.58991 8.58531C8.26447 8.25987 7.73683 8.25987 7.4114 8.58531C7.08596 8.91074 7.08596 9.43838 7.4114 9.76382L9.9114 12.2638C10.0677 12.4202 10.2798 12.508 10.5009 12.5079C10.7219 12.5078 10.9339 12.4199 11.0902 12.2635L19.4235 3.92186Z"
        fill="#002033"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function CalendarHeaderIcon() {
  return (
    <svg fill="none" height="22" viewBox="0 0 20 22" width="20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7778 21.9048H2.22222C0.994923 21.9048 0 20.9241 0 19.7143V4.38095C0 3.17119 0.994923 2.19048 2.22222 2.19048H4.44444V0H6.66667V2.19048H13.3333V0H15.5556V2.19048H17.7778C19.0051 2.19048 20 3.17119 20 4.38095V19.7143C20 20.9241 19.0051 21.9048 17.7778 21.9048ZM2.22222 8.7619V19.7143H17.7778V8.7619H2.22222ZM2.22222 4.38095V6.57143H17.7778V4.38095H2.22222ZM11.1111 17.2238C11.1111 17.3895 10.9768 17.5238 10.8111 17.5238H9.18889C9.0232 17.5238 8.88889 17.3895 8.88889 17.2238V15.6333C8.88889 15.4676 9.0232 15.3333 9.18889 15.3333H10.8111C10.9768 15.3333 11.1111 15.4676 11.1111 15.6333V17.2238ZM6.66667 17.2238C6.66667 17.3895 6.53235 17.5238 6.36667 17.5238H4.74444C4.57876 17.5238 4.44444 17.3895 4.44444 17.2238V15.6333C4.44444 15.4676 4.57876 15.3333 4.74444 15.3333H6.36667C6.53235 15.3333 6.66667 15.4676 6.66667 15.6333V17.2238ZM15.5556 12.8429C15.5556 13.0085 15.4212 13.1429 15.2556 13.1429H13.6333C13.4676 13.1429 13.3333 13.0085 13.3333 12.8429V11.2524C13.3333 11.0867 13.4676 10.9524 13.6333 10.9524H15.2556C15.4212 10.9524 15.5556 11.0867 15.5556 11.2524V12.8429ZM11.1111 12.8429C11.1111 13.0085 10.9768 13.1429 10.8111 13.1429H9.18889C9.0232 13.1429 8.88889 13.0085 8.88889 12.8429V11.2524C8.88889 11.0867 9.0232 10.9524 9.18889 10.9524H10.8111C10.9768 10.9524 11.1111 11.0867 11.1111 11.2524V12.8429ZM6.66667 12.8429C6.66667 13.0085 6.53235 13.1429 6.36667 13.1429H4.74444C4.57876 13.1429 4.44444 13.0085 4.44444 12.8429V11.2524C4.44444 11.0867 4.57876 10.9524 4.74444 10.9524H6.36667C6.53235 10.9524 6.66667 11.0867 6.66667 11.2524V12.8429Z"
        fill="#9CA5B0"
      />
    </svg>
  )
}

export function DatePickerChevron() {
  return (
    <svg fill="none" height="8" viewBox="0 0 14 8" width="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 0.999999L7 7L13 1" stroke="#9CA5B0" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}

export function CloseButtonIcon() {
  return (
    <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
        fill="#9CA8B4"
        fillRule="evenodd"
      />
    </svg>
  )
}

export function TimeIcon() {
  return (
    <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 2a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16zm3.1-7.37L12 11.42V7a1 1 0 00-2 0v5.12a.65.65 0 00.05.2c.02.06.047.116.08.17.027.057.06.11.1.16l.16.13.09.09 2.6 1.5a1 1 0 00.5.13 1 1 0 00.5-1.87h.02z"
        fill="#7D8793"
      />
    </svg>
  )
}

export function FilterIcon() {
  return (
    <svg fill="none" height="23" viewBox="0 0 23 23" width="23" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1820_50307)">
        <path
          clipRule="evenodd"
          d="M16.0833 8.5H6.91667C6.4125 8.5 6 8.0875 6 7.58333C6 7.07917 6.4125 6.66667 6.91667 6.66667H16.0833C16.5875 6.66667 17 7.07917 17 7.58333C17 8.0875 16.5875 8.5 16.0833 8.5ZM14.25 12.1667H8.75C8.24583 12.1667 7.83333 11.7542 7.83333 11.25C7.83333 10.7458 8.24583 10.3333 8.75 10.3333H14.25C14.7542 10.3333 15.1667 10.7458 15.1667 11.25C15.1667 11.7542 14.7542 12.1667 14.25 12.1667ZM12.4167 15.8333H10.5833C10.0792 15.8333 9.66667 15.4208 9.66667 14.9167C9.66667 14.4125 10.0792 14 10.5833 14H12.4167C12.9208 14 13.3333 14.4125 13.3333 14.9167C13.3333 15.4208 12.9208 15.8333 12.4167 15.8333ZM11.5 0.25C5.42708 0.25 0.5 5.17708 0.5 11.25C0.5 17.3229 5.42708 22.25 11.5 22.25C17.5729 22.25 22.5 17.3229 22.5 11.25C22.5 5.17708 17.5729 0.25 11.5 0.25Z"
          fill="#9CA5B0"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1820_50307">
          <rect fill="white" height="23" transform="translate(0.5)" width="22" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function NoResultsIcon() {
  return (
    <svg fill="none" height="43" viewBox="0 0 48 48" width="43" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M45.56 20.74C45.3718 20.508 45.1339 20.3211 44.864 20.193C44.594 20.065 44.2988 19.999 44 20H40V18C40
              16.4087 39.3679 14.8826 38.2426 13.7574C37.1174 12.6322 35.5913 12 34 12H21.44L20.8 10C20.3851 8.82651 19.6155
              7.81105 18.5978 7.09437C17.5801 6.37768 16.3647 5.99525 15.12 6.00004H8C6.4087 6.00004 4.88258 6.63219 3.75736
              7.7574C2.63214 8.88262 2 10.4087 2 12V36C2 37.5913 2.63214 39.1175 3.75736 40.2427C4.88258 41.3679 6.4087 42
              8 42H36.8C38.1624 41.9961 39.4829 41.5285 40.5443 40.6743C41.6057 39.8201 42.3448 38.6301 42.64 37.3L46
              22.44C46.0608 22.1419 46.0528 21.8338 45.9766 21.5392C45.9003 21.2446 45.7578 20.9713 45.56 20.74ZM10.74
              36.44C10.6384 36.8912 10.3836 37.2932 10.0189 37.5776C9.65428 37.8621 9.20232 38.0113 8.74 38H8C7.46957
              38 6.96086 37.7893 6.58579 37.4143C6.21071 37.0392 6 36.5305 6 36V12C6 11.4696 6.21071 10.9609 6.58579
              10.5858C6.96086 10.2108 7.46957 10 8 10H15.12C15.5561 9.97728 15.9877 10.0979 16.3489 10.3435C16.71
              10.589 16.9808 10.9461 17.12 11.36L18.2 14.64C18.3283 15.0215 18.5688 15.3554 18.8899 15.5981C19.2111
              15.8407 19.598 15.9808 20 16H34C34.5304 16 35.0391 16.2108 35.4142 16.5858C35.7893 16.9609 36 17.4696
              36 18V20H16C15.5377 19.9887 15.0857 20.138 14.7211 20.4224C14.3564 20.7069 14.1016 21.1089 14 21.56L10.74
              36.44ZM38.74 36.44C38.6384 36.8912 38.3836 37.2932 38.0189 37.5776C37.6543 37.8621 37.2023 38.0113 36.74
              38H14.42C14.5233 37.7772 14.5972 37.5419 14.64 37.3L17.6 24H41.6L38.74 36.44Z"
        fill="#9CA5B0"
      />
    </svg>
  )
}

export function OpenIcon() {
  return (
    <svg fill="none" height="8" viewBox="0 0 14 8" width="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 0.999999L7 7L1 1" stroke="#7D8793" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}

export function EditShiftIcon() {
  return (
    <svg
      fill="none"
      height="35"
      style={{ cursor: 'pointer' }}
      viewBox="0 0 35 35"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <g filter="url(#filter0_d_3101_102327)">
          <rect fill="#9CA5B0" height="31.2766" rx="5.95745" width="31.2766" x="1.85986" y="1.35938" />
          <g opacity="0.5">
            <path
              d="M17.498 21.9648H23.0832"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.48936"
            />
            <path
              d="M20.2907 11.7215C20.5376 11.4746 20.8725 11.3359 21.2216 11.3359C21.3945 11.3359 21.5657 11.37 21.7254 11.4361C21.8851 11.5023 22.0302 11.5993 22.1524 11.7215C22.2747 11.8437 22.3717 11.9889 22.4378 12.1486C22.504 12.3083 22.538 12.4795 22.538 12.6524C22.538 12.8252 22.504 12.9964 22.4378 13.1561C22.3717 13.3158 22.2747 13.461 22.1524 13.5832L14.3954 21.3403L11.9131 21.9609L12.5337 19.4786L20.2907 11.7215Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.48936"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="34.2561"
          id="filter0_d_3101_102327"
          width="34.2551"
          x="0.370502"
          y="0.614694"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="0.744681" />
          <feGaussianBlur stdDeviation="0.744681" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
          <feBlend in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_3101_102327" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_3101_102327" mode="normal" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export function CheckIcon() {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3202_93909)" opacity="0.5">
        <path
          d="M14.2273 7.06799V7.63891C14.2265 8.97712 13.7932 10.2792 12.9919 11.351C12.1907 12.4229 11.0644 13.2069 9.78112 13.5864C8.49783 13.9658 7.12627 13.9202 5.871 13.4565C4.61572 12.9927 3.54398 12.1356 2.81563 11.013C2.08727 9.89035 1.74132 8.56236 1.82937 7.22705C1.91742 5.89175 2.43475 4.62068 3.30421 3.60341C4.17367 2.58614 5.34868 1.87718 6.65398 1.58227C7.95928 1.28735 9.32495 1.42228 10.5473 1.96693"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.48936"
        />
        <path
          d="M14.2275 2.67188L8.02186 8.88376L6.16016 7.02205"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.48936"
        />
      </g>
      <defs>
        <clipPath id="clip0_3202_93909">
          <rect fill="white" height="14.8936" transform="translate(0.574707 0.191406)" width="14.8936" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function AmendmentClock() {
  return (
    <svg
      fill="none"
      height="16"
      style={{
        right: '80px',
        top: '19px',
        position: 'absolute',
      }}
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.988 8.945a.967.967 0 00.712-.314h1.408a.656.656 0 000-1.313H7.699a.962.962 0 00-.586-.302L5.86 5.25a.657.657 0 00-1.07.761l1.25 1.761a.97.97 0 00.949 1.173z"
        fill="#FF6C6C"
      />
      <path
        d="M8.738 1.452l.155.144a.756.756 0 010 1.1l-.505.47a5.052 5.052 0 013.608 4.402h-.59a.438.438 0 000 .875h.592a5.054 5.054 0 01-4.094 4.518v-.948a.315.315 0 00-.529-.23l-1.97 1.836a.316.316 0 000 .46l1.97 1.837a.315.315 0 00.529-.23v-.95c.007 0 .014.002.022.001a6.8 6.8 0 005.843-6.732c0-3.142-2.136-5.774-5.031-6.553z"
        fill="#FF6C6C"
      />
      <path
        d="M4.868 13.853c0-.207.087-.408.24-.55l.504-.47a5.052 5.052 0 01-3.608-4.4h.59a.438.438 0 000-.876h-.592A5.055 5.055 0 016.096 3.04v.948a.315.315 0 00.529.23l1.97-1.837a.315.315 0 000-.46L6.625.084a.314.314 0 00-.529.23v.95c-.007 0-.014-.003-.022-.002A6.801 6.801 0 00.231 7.995c0 3.141 2.135 5.773 5.031 6.553l-.155-.144a.756.756 0 01-.24-.55z"
        fill="#FF6C6C"
      />
    </svg>
  )
}

export function ShowTimesheet() {
  return (
    <svg
      fill="none"
      height="20"
      style={{ marginLeft: 10 }}
      viewBox="0 0 24 24"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1625_4506)">
        <path
          clipRule="evenodd"
          d="M2.91682 2.78391C3.48556 2.27205 4.23637 2 5 2H16C16.5523 2 17 2.44772 17 3C17 3.55228 16.5523 4 16 4H5C4.70277 4 4.43616 4.10723 4.25475 4.2705C4.07682 4.43064 4 4.62386 4 4.8V19.2C4 19.3761 4.07682 19.5694 4.25475 19.7295C4.43616 19.8928 4.70277 20 5 20H11C11.5523 20 12 20.4477 12 21C12 21.5523 11.5523 22 11 22H5C4.23637 22 3.48555 21.7279 2.91682 21.2161C2.34461 20.7011 2 19.9786 2 19.2V4.8C2 4.02136 2.34461 3.2989 2.91682 2.78391Z"
          fill="#7D8793"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6 15C6 14.4477 6.44772 14 7 14H11C11.5523 14 12 14.4477 12 15C12 15.5523 11.5523 16 11 16H7C6.44772 16 6 15.5523 6 15Z"
          fill="#7D8793"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6 11C6 10.4477 6.44772 10 7 10H12C12.5523 10 13 10.4477 13 11C13 11.5523 12.5523 12 12 12H7C6.44772 12 6 11.5523 6 11Z"
          fill="#7D8793"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M6 7C6 6.44772 6.44772 6 7 6H14C14.5523 6 15 6.44772 15 7C15 7.55228 14.5523 8 14 8H7C6.44772 8 6 7.55228 6 7Z"
          fill="#7D8793"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M19.1876 4.64358C19.0226 4.72455 18.8966 4.86776 18.8371 5.04169L14.3657 18.1305L14.6481 19.4475L15.6773 18.5785L20.1488 5.48976C20.1782 5.40364 20.1903 5.31257 20.1846 5.22174C20.1788 5.13092 20.1552 5.04212 20.1151 4.96042C20.075 4.87872 20.0192 4.80571 19.9509 4.74558C19.8826 4.68544 19.8031 4.63934 19.717 4.60992C19.6309 4.5805 19.5398 4.56833 19.449 4.5741C19.3581 4.57988 19.2693 4.60349 19.1876 4.64358ZM16.9445 4.39513C17.1754 3.71925 17.6654 3.16277 18.3066 2.84812C18.624 2.69232 18.9691 2.60058 19.322 2.57814C19.675 2.55569 20.0289 2.60298 20.3635 2.71731C20.6982 2.83164 21.0071 3.01077 21.2725 3.24446C21.5379 3.47816 21.7548 3.76185 21.9106 4.07933C22.0664 4.39682 22.1581 4.74188 22.1805 5.09482C22.203 5.44776 22.1557 5.80166 22.0414 6.13632L17.4804 19.4872C17.4218 19.6587 17.3177 19.8111 17.1792 19.928L14.6649 22.0509C14.3965 22.2775 14.0289 22.3467 13.6965 22.2331C13.3641 22.1195 13.1156 21.8399 13.042 21.4965L12.3521 18.279C12.3141 18.1017 12.325 17.9175 12.3836 17.746L16.9445 4.39513Z"
          fill="#7D8793"
          fillRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="clip0_1625_4506">
          <rect fill="white" height="24" width="24" />
        </clipPath>
      </defs>
    </svg>
  )
}

export function HideTimesheet() {
  return (
    <svg
      fill="none"
      height="20"
      style={{ marginLeft: 10 }}
      viewBox="0 0 24 24"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM15.7071 8.29291C16.0976 8.68343 16.0976 9.3166 15.7071 9.70712L13.4142 12L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071 15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L12 13.4142L9.70711 15.7071C9.31659 16.0976 8.68342 16.0976 8.2929 15.7071C7.90237 15.3166 7.90237 14.6834 8.2929 14.2929L10.5858 12L8.2929 9.70712C7.90237 9.3166 7.90237 8.68343 8.2929 8.29291C8.68342 7.90238 9.31659 7.90238 9.70711 8.29291L12 10.5858L14.2929 8.29291C14.6834 7.90238 15.3166 7.90238 15.7071 8.29291Z"
        fill="#7D8793"
        fillRule="evenodd"
      />
    </svg>
  )
}
