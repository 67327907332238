import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
  FormToggleComponent,
  FormToggleContainer,
} from '../../Forms/FormElements'
import { CloseButton, Loading, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../Modals/ModalElements'
import { GET_ORGANISATION_AGENCIES, REACTIVATE_AGENCY, DEACTIVATE_AGENCY } from './AgencyListHelpers'
import OrganisationAgencyRow from './OrganisationAgencyRow'

function AgencyListModal({ agency, isMod, handleClose, organisationStaffGroups, refetchAgencies, show }) {
  const [action, setAction] = useState(undefined)
  const [error, setError] = useState({ title: '' })
  const [inputs, setInputs] = useState({
    title: '',
    notification_email: '',
    short_title: '',
  })
  const [loading, setLoading] = useState(false)
  const [manageAgencyUsers, setManageAgencyUsers] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showNewStaffGroup, setShowNewStaffgroup] = useState(false)

  const handleCloseConfirm = () => {
    setShowConfirm(false)
  }

  const handleShowConfirm = (actionId) => {
    setAction(actionId)
    setShowConfirm(true)
  }

  const [deactivateAgency] = useMutation(DEACTIVATE_AGENCY)
  const [reactivateAgency] = useMutation(REACTIVATE_AGENCY)

  const { data, refetch: refetchOrganisationAgencies } = useQuery(GET_ORGANISATION_AGENCIES, {
    variables: {
      agencyId: Number(agency?.id),
    },
  })

  const organisationAgencies = data?.organisationAgencies

  useEffect(() => {
    if (agency) {
      setInputs({
        title: agency.title ? agency.title : '',
        short_title: agency.shortTitle ? agency.shortTitle : '',
        description: agency.description ? agency.description : '',
        notification_email: agency.notificationEmail ? agency.notificationEmail : '',
      })
      setManageAgencyUsers(agency.canManageUsers)
    }
  }, [agency])

  const handleCloseAction = () => {
    setInputs({})
    setManageAgencyUsers(false)
    setError({})
    handleClose()
  }

  const handleInputChange = (event) => {
    setError({})
    event.persist()
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }

  const toggleManageAgencyUsers = () => setManageAgencyUsers(!manageAgencyUsers)

  return (
    <>
      <Modal backdrop="static" centered dialogClassName="modal-910" onHide={handleClose} show={show}>
        <ModalHeader>
          <ModalTitle>{agency ? `Edit ${agency.title}` : 'Create Agency'}</ModalTitle>
          <CloseButton onClick={() => handleCloseAction()}>
            <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
                fill="#9CA8B4"
                fillRule="evenodd"
              />
            </svg>
          </CloseButton>
        </ModalHeader>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ModalBody>
              <FormDetailGroup>
                <h2>Agency Information</h2>
                <AgencyForm>
                  <FormGrid columns={2} size="2fr 1fr">
                    <FormGroup
                      component="input"
                      error={error.title}
                      label="Agency Name"
                      name="title"
                      onChange={handleInputChange}
                      placeholder="Enter Agency name"
                      type="text"
                      value={inputs.title}
                    />
                    <FormGroup
                      component="input"
                      error={error.short_title}
                      label="Short Title"
                      name="short_title"
                      onChange={handleInputChange}
                      placeholder="Add a short title..."
                      type="text"
                      value={inputs.short_title}
                    />
                  </FormGrid>
                  <FormGrid columns={2} size="2fr 1fr">
                    <FormGroup
                      component="input"
                      error={error.notification_email}
                      label="Agency Email"
                      name="notification_email"
                      onChange={handleInputChange}
                      placeholder="Add an email..."
                      type="email"
                      value={inputs.notification_email}
                    />
                  </FormGrid>
                  <FormGrid columns={2} size="2fr 1fr">
                    <FormGroup
                      component="textarea"
                      defaultValue={inputs.description}
                      label="Agency Description"
                      name="description"
                      onChange={handleInputChange}
                      placeholder="Add a description..."
                      type="textarea"
                      value={inputs.description}
                    />
                    <FormToggleContainer style={{ marginTop: 24 }}>
                      <p>Can manage users</p>
                      <FormToggleComponent
                        className={manageAgencyUsers === true ? 'on' : ''}
                        onClick={toggleManageAgencyUsers}
                      >
                        <button />
                      </FormToggleComponent>
                    </FormToggleContainer>
                  </FormGrid>
                </AgencyForm>
              </FormDetailGroup>
              {agency?.id && (
                <FormDetailGroup>
                  <h2>Staff Group Permissions</h2>
                  <>
                    {organisationAgencies?.map((organisationAgency, index) => {
                      return (
                        <OrganisationAgencyRow
                          key={index}
                          index={index}
                          isMod={isMod}
                          organisationAgencies={organisationAgencies}
                          organisationAgency={organisationAgency}
                          organisationStaffGroups={organisationStaffGroups}
                          refetchAgencies={refetchAgencies}
                          refetchOrganisationAgencies={refetchOrganisationAgencies}
                        />
                      )
                    })}
                    {(showNewStaffGroup || !organisationAgencies?.length > 0) && (
                      <OrganisationAgencyRow
                        agencyId={agency?.id}
                        organisationAgencies={organisationAgencies}
                        organisationStaffGroups={organisationStaffGroups}
                        refetchAgencies={refetchAgencies}
                        refetchOrganisationAgencies={refetchOrganisationAgencies}
                        setShowNewStaffgroup={setShowNewStaffgroup}
                      />
                    )}
                    {organisationAgencies?.length > 0 &&
                      organisationAgencies?.length !== organisationStaffGroups?.length && (
                        <AddStaffGroupButton onClick={() => setShowNewStaffgroup(true)}>
                          Add Another Staff Group
                        </AddStaffGroupButton>
                      )}
                  </>
                </FormDetailGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <div>
                {agency && (
                  <>
                    {agency.deactivated !== true ? (
                      <button className="btn btn-white" onClick={() => handleShowConfirm('deactivate')}>
                        Deactivate Agency
                      </button>
                    ) : (
                      <button className="btn btn-white" onClick={() => handleShowConfirm('reactivate')}>
                        <svg fill="none" height="18" width="22" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clipRule="evenodd"
                            d="M11.697 10.584a.92.92 0 01-.907.927.92.92 0 01-.908-.927V5.486a.92.92 0 01.908-.927.92.92 0 01.907.927v5.098zm-.907 4.171a.92.92 0 01-.908-.927.92.92 0 01.908-.926.92.92 0 01.907.927.92.92 0 01-.907.926zm10.42-.338L12.69 1.05a2.245 2.245 0 00-3.801 0L.37 14.417C-.61 15.961.47 18 2.27 18h17.038c1.801 0 2.881-2.04 1.901-3.583z"
                            fill="#FF6C6C"
                            fillRule="evenodd"
                          />
                        </svg>{' '}
                        Reactivate
                      </button>
                    )}
                  </>
                )}
                {error && error.message && (
                  <ErrorContainer>
                    <p>{error.message}</p>
                  </ErrorContainer>
                )}
              </div>
              <div>
                <button className="btn btn-close" onClick={() => handleCloseAction()}>
                  Cancel
                </button>
                {agency && (
                  <button className="btn text-white btn-cyan--modal" onClick={() => handleCloseAction()}>
                    Save Changes
                  </button>
                )}
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
      {showConfirm && (
        <ConfirmModal
          action={action}
          agency={agency}
          deactivateAgency={deactivateAgency}
          handleCloseAction={handleCloseAction}
          handleCloseConfirm={handleCloseConfirm}
          reactivateAgency={reactivateAgency}
          refetchAgencies={refetchAgencies}
          setLoading={setLoading}
          showConfirm={showConfirm}
        />
      )}
    </>
  )
}

export default AgencyListModal

function ConfirmModal({
  action,
  agency,
  deactivateAgency,
  handleCloseAction,
  handleCloseConfirm,
  reactivateAgency,
  refetchAgencies,
  setLoading,
  showConfirm,
}) {
  const [error, setError] = useState({})

  const deactivateAgencyAction = (agencyId) => {
    setLoading(true)
    deactivateAgency({
      variables: {
        id: Number(agencyId),
      },
    }).then((data) => {
      setLoading(false)
      if (data.data.archiveAgency.success === true) {
        refetchAgencies()
        handleCloseConfirm()
        handleCloseAction()
      } else {
        setError({
          error: true,
          message: `There was a problem deactivating this Agency.`,
        })
      }
    })
  }

  const reactivateAgencyAction = (agencyId) => {
    setLoading(true)
    reactivateAgency({
      variables: {
        id: Number(agencyId),
      },
    }).then((data) => {
      setLoading(false)
      if (data.data.reactivateAgency.success === true) {
        refetchAgencies()
        handleCloseConfirm()
        handleCloseAction()
      } else {
        setError({
          error: true,
          message: `There was a problem reactivating this Agency.`,
        })
      }
    })
  }

  return (
    <Modal backdrop="static" centered onHide={handleCloseConfirm} show={showConfirm} size="xl">
      <ModalHeader>
        <ModalTitle>
          <span style={{ textTransform: 'capitalize' }}>{action}</span> Agency
        </ModalTitle>
        <CloseButton onClick={() => handleCloseConfirm()}>
          <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ fontSize: 20, minHeight: 0 }}>
        Are you sure you want to {action} {agency.title}?
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleCloseConfirm} type="button">
            Cancel
          </button>
          {action === 'deactivate' ? (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => deactivateAgencyAction(agency.id)}
              type="button"
            >
              Confirm Deactivate
            </button>
          ) : (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => reactivateAgencyAction(agency.id)}
              type="button"
            >
              Confirm Reactivate
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  )
}

const AgencyForm = styled.div`
  max-width: 700px;
  pointer-events: none;
`

const AddStaffGroupButton = styled.button`
  align-self: flex-end;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #1e2d36;
  height: fit-content;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px;
  padding: 0;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`
