import { gql } from "@apollo/client";

export async function createDivision(organisationId, name) {
  const query = `
    mutation createDivisionMut($organisationId: Int!, $name: String!){
      createDivision(organisationId: $organisationId, name: $name) {
        division {
          name
        }
        errors
      }
    }
  `;

  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { name: name, organisationId: organisationId },
    }),
  }).then((response) => {
    if (response.ok) return response.json();
    else return "err";
  });
  
  return data;
}

export async function updateDivision(divisionId, name) {
  const query = `
    mutation updateDivisionMut($id: ID!, $name: String!){
      updateDivision( id: $id, name: $name ) {
        division {
          name
        }
        errors
      }
    }
  `;

  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { id: divisionId, name: name },
    }),
  }).then((response) => {
    if (response.ok) return response.json();
    else return "err";
  });
  
  return data;
}

export async function deleteDivision(divisionId) {
  const query = `
    mutation deleteDivisionMut($id: ID!){
      deleteDivision( id: $id ) {
        errors
      }
    }
  `;

  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { id: divisionId },
    }),
  }).then(r => r.json())
    .then((data) => {
      if(data?.data?.deleteDivision?.errors?.length){
        return data?.data?.deleteDivision?.errors;
      }else{
        return 'success';
      }
    });

  return data;
}
