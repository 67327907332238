/* eslint-disable no-alert */
/* eslint-disable no-inner-declarations */
import {Controller} from 'stimulus'
import {application} from '../../frontend/controllers/index'

export default class OrganisationPreferencesController extends Controller {
  static targets = ['ppiSwitch', 'collabBankPPISwitch', 'collabBankPPISwitchWrapper']

  connect() {
    const ppiToggle = this.ppiSwitchTarget
    this.toggleCollabBankPPISwitch(ppiToggle.checked) // Initially show/hide the statement row based on the toggle value
  }

  toggleCollabBankPPI() {
    const ppiToggle = this.ppiSwitchTarget

    this.toggleCollabBankPPISwitch(ppiToggle.checked)
  }

  toggleCollabBankPPISwitch(show) {
    const collabBankPPISwitchWrapper = this.collabBankPPISwitchWrapperTarget

    if (show) {
      collabBankPPISwitchWrapper.style.display = ''
    } else {
      this.collabBankPPISwitchTarget.checked = false
      collabBankPPISwitchWrapper.style.display = 'none'
    }
  }
}

application.register('organisation_preferences', OrganisationPreferencesController)
