import { useQuery } from '@apollo/client'
import React from 'react'
import styled from 'styled-components'
import BlockOptions from './ActionBarOptions/BlockOptions'
import ShiftOptions from './ActionBarOptions/ShiftOptions'
import { GET_CURRENT_HUB_USER } from './helpers/BlockBookingsHelpers'
import { StyledButton } from './helpers/BlockComponents'

function ActionBarComponent({
  addShift,
  closeHandler,
  deleteBlock,
  deleteShifts,
  editShift,
  inputs,
  lockBlockToAgency,
  open,
  selectedShiftId,
  setRecallBlockFromAgencyModal,
  setSendBlockToAgencyModal,
  viewBlock,
}) {
  const { calendarDetails, modOrganisation, blocksPage, selectedBlock } = inputs

  const showBlockActions = blocksPage === 1
  const showShiftActions = !showBlockActions

  const { data } = useQuery(GET_CURRENT_HUB_USER)
  const canManageAgency = data?.currentHubUser.canManageAgency

  return (
    <ActionBar open={open}>
      {showBlockActions && (
        <BlockOptions
          canManageAgency={canManageAgency}
          deleteBlock={deleteBlock}
          lockBlockToAgency={lockBlockToAgency}
          modOrganisation={modOrganisation}
          selectedBlock={selectedBlock}
          setRecallBlockFromAgencyModal={setRecallBlockFromAgencyModal}
          setSendBlockToAgencyModal={setSendBlockToAgencyModal}
          viewBlock={viewBlock}
        />
      )}

      {showShiftActions && (
        <ShiftOptions
          addShift={addShift}
          calendarDetails={calendarDetails}
          deleteShifts={deleteShifts}
          editShift={editShift}
          selectedShiftId={selectedShiftId}
        />
      )}

      <StyledButton color="dark" onClick={closeHandler} right="20" width="80">
        Close
      </StyledButton>
    </ActionBar>
  )
}

export default ActionBarComponent

const ActionBar = styled.div`
  align-items: center;
  background: #353f4a;
  bottom: ${(props) => (props.open ? '0px' : '-200px')};
  display: flex;
  height: 89px;
  justify-content: center;
  left: 0;
  position: fixed;
  transition: all 0.35s ease-in-out;
  width: 100%;
  z-index: 200;
`
