import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { createRegBody, editRegBody } from "./RegulationBodyHelpers";

const RegulationBodyModal = ({ handleClose, regulationBody, show }) => {
  const [error, setError] = useState({ name: "" });
  const [inputs, setInputs] = useState({ name: "" });

  useEffect(() => {
    if (regulationBody) {
      setInputs({
        name: regulationBody.name ? regulationBody.name : "",
        name_abbr: regulationBody.name_abbr ? regulationBody.name_abbr : "",
        registration_number_max_length: regulationBody.registration_number_max_length
          ? regulationBody.registration_number_max_length
          : "",
      });
    }
  }, [regulationBody]);

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
    setInputs({ name: "" });
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const createRegBodyAction = () => {
    setError({});
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a name." });
    } else {
      setError({});
      if (inputs) {
        const body = {
          regulation_body: {
            name: inputs.name,
            name_abbr: inputs.name_abbr ? inputs.name_abbr : null,
            registration_number_max_length: inputs.registration_number_max_length
              ? inputs.registration_number_max_length
              : null,
          },
        };
        createRegBody(body).then((data) => {
          if (data !== "err") {
            window.location.reload(true);
          } else {
            setError({
              error: true,
              message: `There was a problem creating this Regulation Body.`,
            });
          }
        });
      }
    }
  };

  const editRegBodyAction = (regBodyId) => {
    setError({});
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a name." });
    } else {
      const body = {
        regulation_body: {
          name: inputs.name,
          name_abbr: inputs.name_abbr ? inputs.name_abbr : null,
          registration_number_max_length: inputs.registration_number_max_length
            ? inputs.registration_number_max_length
            : null,
        },
      };
      editRegBody(regBodyId, body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setError({
            error: true,
            message: `There was a problem updating this Regulation Body.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {regulationBody
            ? `Edit ${regulationBody.name}`
            : "Create Regulation Body"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody>
        <RegulationBodyForm>
          <FormDetailGroup>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                value={inputs.name}
                placeholder={"Enter a name"}
                label={"Regulation Body Name"}
                name="name"
                onChange={handleInputChange}
                type="text"
                error={error.name}
              />
              <FormGroup
                component={"input"}
                value={inputs.name_abbr}
                placeholder={"Enter an abbreviation"}
                label={"Regulation Body Name Abbr"}
                name="name_abbr"
                onChange={handleInputChange}
                type="text"
                error={error.name_abbr}
              />
            </FormGrid>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                value={inputs.registration_number_max_length}
                placeholder={"Enter a max length"}
                label={"Registration Number Max Length"}
                name="registration_number_max_length"
                onChange={handleInputChange}
                type="number"
                error={error.registration_number_max_length}
              />
            </FormGrid>
          </FormDetailGroup>
        </RegulationBodyForm>
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={() => handleCloseAction()}>
            Cancel
          </button>
          {regulationBody ? (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => editRegBodyAction(regulationBody.id)}
            >
              Save Changes
            </button>
          ) : (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => createRegBodyAction()}
            >
              Create Regulation Body
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default RegulationBodyModal;

const RegulationBodyForm = styled.div`
  max-width: 600px;
`;
