import { Controller } from 'stimulus'

export default class DropdownController extends Controller {

    static get targets() {
        return ['menu']
    }

    initialize() {
        this.isOpen = false;
    }

    toggle(event) {
        // check if any dropdowns are open
        let openDropdowns = document.querySelectorAll('.show')
        // if a dropdown is already open, remove .show before adding show to new dropdown
        if (openDropdowns && openDropdowns.length > 0) {
            openDropdowns[0].classList.toggle('show')
            // empty open dropdowns array
            openDropdowns = []
        } else {
            this.menuTarget.classList.toggle('show')
            this.menuTarget.setAttribute('id', 'dropdown')
            this.isOpen = !this.isOpen
            event.stopPropagation()
        }
    }

    hide() {
        if (this.isOpen) {
            this.menuTarget.classList.remove('show')
            this.menuTarget.removeAttribute('id', 'dropdown')
            this.isOpen = false
        }
    }
}

import { application } from '../../frontend/controllers/index'
application.register("dropdown", DropdownController)