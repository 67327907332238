/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import 'react-day-picker/lib/style.css'
import { useQuery } from '@apollo/client'
import { ModalBody, CloseButton, ModalHeader } from '@patchworkhealth/web-components'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import ViewAudit from '../../Audit/ViewAudit'
import { GET_RECEIPT } from '../helpers/ReceiptsHelpers'
import SignOffReceipt from './SignOffReceipt'

function ItemisedReceiptModal({ receiptId, onCloseModal, refetchReceipts }) {
  const { data } = useQuery(GET_RECEIPT, {
    variables: {
      receiptId: Number(receiptId),
    },
    skip: !receiptId,
  })

  const [activeTab, setActiveTab] = useState('1')
  const receipt = data?.paidPerItemReceipt
  const statusText = receipt?.status.replaceAll('_', ' ').toUpperCase()

  useEffect(() => {
    if (receipt) {
      setActiveTab('1')
    }
  }, [receipt])

  return (
    <Modal backdrop="static" centered className="receipt-modal" show={!!receiptId} size="xl">
      <>
        <ModalHeaderMod>
          <ItemModalHeaderDetailsContainer>
            <ModalHeaderItem>
              <ItemModalHeaderTitle>Receipt #{receiptId}</ItemModalHeaderTitle>
            </ModalHeaderItem>
            {receipt && (
              <>
                <ModalHeaderItem>
                  <h3 className="datetime">{moment(receipt.dateCompleted).format('Do MMM YYYY')}</h3>
                </ModalHeaderItem>
                <DepartmentInfo>
                  <div className="department">{receipt.department.name}</div>
                  <div className="organisation">{receipt.organisation.name}</div>
                </DepartmentInfo>
                {receipt?.grade && (
                  <Grades>
                    <GradeItem>{receipt.grade.title}</GradeItem>
                  </Grades>
                )}
              </>
            )}
          </ItemModalHeaderDetailsContainer>
          <ItemModalHeaderDetailsContainer>
            <ItemStatus status={receipt?.status}>{statusText}</ItemStatus>
            <CloseButton onClick={onCloseModal} />
          </ItemModalHeaderDetailsContainer>
        </ModalHeaderMod>

        <ItemModalContainer>
          <ItemModalSidebarContainer>
            <ItemModalSidebarNav>
              <ItemModalSidebarNavOption active={activeTab === '1'} onClick={() => setActiveTab('1')}>
                Sign Off
              </ItemModalSidebarNavOption>
              <ItemModalSidebarNavOption active={activeTab === '2'} onClick={() => setActiveTab('2')}>
                Audit Trail
              </ItemModalSidebarNavOption>
            </ItemModalSidebarNav>
          </ItemModalSidebarContainer>

          <ItemModalContentContainer>
            <ModalBody>
              {activeTab === '1' && receipt && (
                <SignOffReceipt
                  onCloseModal={onCloseModal}
                  receipt={receipt}
                  receiptId={receiptId}
                  refetchReceipts={refetchReceipts}
                />
              )}
              {activeTab === '2' && receipt?.ppiAuditEvents && <ViewAudit auditData={receipt} />}
            </ModalBody>
          </ItemModalContentContainer>
        </ItemModalContainer>
      </>
    </Modal>
  )
}

export default ItemisedReceiptModal

const ItemModalSidebarContainer = styled.div`
  background: rgb(228, 231, 235);
  border-bottom-left-radius: 16px;
  flex-shrink: 0;
  padding-top: 6px;
  width: 185px;
`

const ItemModalSidebarNav = styled.div`
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
`

const ItemModalSidebarNavOption = styled.button`
  align-items: center;
  background-color: ${({ active }) => (active ? '#CCD2D8' : 'transparent')};
  border: 0;
  display: flex;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  height: 40px;
  margin-bottom: 8px;
  padding: 0 8px 0 16px;
  width: 100%;
  border-radius: 4px;
`

const ItemModalContainer = styled.div`
  display: flex;
`

const ItemModalContentContainer = styled.div`
  width: 100%;
`

const ItemModalHeaderDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  div {
    display: flex;
  }
`

const ItemModalHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const ModalHeaderMod = styled(ModalHeader)`
  height: 80px;
  font-size: 12px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
  padding: 15px 23px !important;
`

const ModalHeaderItem = styled.div`
  height: 100%;
  border-right: 1px solid #cbd2d9;
  margin-right: 14px;
  padding-right: 14px;

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &.datetime {
      font-size: 14px;
      font-weight: 600 !important;
    }
  }

  &:not(:first-of-type) {
    h3 {
      font-weight: 400;
    }
  }
`

const DepartmentInfo = styled.div`
  height: 100%;
  border-right: 1px solid #cbd2d9;
  margin-right: 14px;
  padding-right: 14px;
  flex-direction: column;

  .department {
    font-size: 14px;
    font-weight: 600;
  }

  .organisation {
    font-size: 12px;
    font-weight: 400;
  }
`

const Grades = styled.div`
  display: flex;
`

const GradeItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  padding: 2px 13px;
  border-radius: 188px;
  background-color: #7b61ff;
  margin-right: 5px;
`

const ItemStatus = styled.div`
  max-width: 100px;
  text-align: center;
  border-radius: 4px;
  padding: 2px 9px;
  background-color: ${({ status }) =>
    status === 'sign_off_requested'
      ? '#D1F2F7'
      : status === 'signed_off'
      ? '#CCD2D8'
      : status === 'approved_for_payment'
      ? '#ffbd37'
      : status === 'paid'
      ? '#90D78F'
      : '#D1F2F7'};
  color: ${({ status }) =>
    status === 'sign_off_requested'
      ? '#007AC3'
      : status === 'signed_off'
      ? '#353F4A'
      : status === 'approved_for_payment'
      ? '#353F4A'
      : status === 'paid'
      ? '#353F4A'
      : '#007AC3'};
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-right: 24px;
`
