import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../packs/application";

import Skills from "./Skills";

function SkillsIndex({ organisation, skills }) {
  return (
    <ApolloProvider client={client}>
      <Skills organisation={organisation} skills={skills} />
    </ApolloProvider>
  );
}

export default SkillsIndex;
