import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'



export default class ExternalUnitSelectorController extends Controller {

    static get targets() {
        return ['select']
    }
    connect() {
        const target = this.selectTarget;
        const externalUnitVal = document.querySelector('.modal__validation--extunit');
        const closeOnSelect = this.data.get('keep-open') ? false : true

        this.select = new SlimSelect({
            select: target,
            onChange: () => {
                externalUnitVal.style.display = "none";
            },
            closeOnSelect: closeOnSelect,
        })
    }
}

import { application } from '../../frontend/controllers/index'
application.register("external_unit_selector", ExternalUnitSelectorController);