import React, { useState } from "react";
import moment from "moment";

import StaffGroupModal from "./StaffGroupModal";
import Pagination from "../Components/Pagination";
import {
  TableContainer,
  TableHeaderContainer,
  TableSearchInput,
  TableComponent,
} from "../Tables/TableElements";
import { TableRowEditIcon } from "../Assets/Icons";

const StaffGroups = ({
  grades,
  pagy,
  regulationBodies,
  specialities,
  staffGroups,
}) => {
  const data = { grades, regulationBodies, specialities };
  const [staffGroup, setStaffGroup] = useState(undefined);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setStaffGroup(undefined);
    setShow(false);
  };
  const handleShowCreate = () => {
    setStaffGroup(undefined);
    setShow(true);
  };

  const handleShowEdit = (staffGroup) => {
    setStaffGroup(staffGroup && staffGroup);
    setShow(true);
  };

  // search function
  const filteredStaffGroups = value
    ? staffGroups.filter((item) => {
        return item.title.toLowerCase().includes(value.toLowerCase());
      })
    : staffGroups;

  return (
    <>
      <TableContainer>
        <TableHeaderContainer>
          <TableSearchInput
            placeholder="Search Staff Groups..."
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button className="btn btn-cyan" onClick={handleShowCreate}>
            <svg
              width="22"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                fill="#1E2D36"
              />
            </svg>
            Create Staff Group
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Regulation Body</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredStaffGroups?.map((staffGroup, index) => (
              <tr key={index}>
                <th>{staffGroup.id}</th>
                <td>{staffGroup.title}</td>
                <td>{staffGroup.regulation_body?.name}</td>
                <td>{moment(staffGroup.created_at).format("MMMM Do YYYY")}</td>
                <td style={{ textAlign: "right" }}>
                  <button onClick={() => handleShowEdit(staffGroup)}>
                    <TableRowEditIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
        <Pagination pagy={pagy} />
      </TableContainer>
      <StaffGroupModal
        data={data}
        handleClose={handleClose}
        staffGroup={staffGroup}
        show={show}
      />
    </>
  );
};

export default StaffGroups;
