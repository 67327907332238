import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { formatTimeInTZ } from '../../../Helpers/momentHelpers'
import { AddShiftIcon } from './BlockIcons'

// Profile Image ************************************

export const defaultImgOnError = (event) => {
  event.currentTarget.onerror = null // prevents looping
  event.currentTarget.src =
    'https://api.patchwork.health/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg'
}

export const defaultImgURL = '/packs/media/img/avatars/profiles/standard-5e314be1bfe58eda32456e683744b335.jpg'

//--

// 1. Global Styled ********************************

export const ShiftModalTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`

// 2. Calendar Components **************************

export function DefaultShift() {
  return <DefaultCell />
}

const DefaultCell = styled.div`
  background: #e4e7eb;
  border: 1px solid #ccd2d8;
  display: flex;
  height: 50px;
  position: relative;
  width: 144px;
`

// 2. Empty Shift ***************************************

export function UnfilledShift({ day, addShift, status }) {
  return (
    <UnfilledCell status={status}>
      <CellLeft>{formatTimeInTZ(day.date, 'DD')}</CellLeft>

      <AddShift onClick={() => addShift(day.date)}>
        <span>
          <AddShiftIcon /> Add Shift
        </span>
      </AddShift>
    </UnfilledCell>
  )
}

const Checkbox = styled.div`
  background: #ffffff;
  border: 1px solid #ccd2d8;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  display: none;
  height: 20px;
  margin-top: 2px;
  width: 20px;
`

const AddShift = styled.div`
  background: #e6f2f9;
  border: 1px dashed #ccd2d8;
  border-radius: 5px;
  color: #55606c;
  font-size: 12px;
  height: 40px;
  margin-left: 4px;
  margin-top: 4px;
  opacity: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 97px;
  span {
    left: 24px;
    margin-left: 7px;
    opacity: 1;
    position: absolute;
    top: 10px;
  }
  svg {
    left: -20px;
    position: absolute;
    top: 2px;
  }
`

const UnfilledCell = styled.div`
  border: 1px solid #ccd2d8;
  cursor: pointer;
  display: flex;
  height: 50px;
  width: 144px;
  pointer-events: ${(props) => (props.status === 'BOOKED' ? 'none' : 'initial')};
  &:hover {
    ${Checkbox} {
      display: block;
    }
    ${AddShift} {
      opacity: 1 !important;
    }
  }
`

const UnfilledCellTwo = styled.div`
  border: 1px solid #ccd2d8;
  cursor: ${(props) => (props.filled ? 'initial' : 'pointer')};
  display: flex;
  height: 50px;
  text-overflow: ellipsis;
  width: 144px;
  &:hover {
    ${CheckboxWrap} {
      label {
        display: block;
      }
    }
  }
`

export const TableCell = styled.div`
  background: ${(props) => {
    if (props.color !== 'dark') return 'white'
    if (props.breach) return '#DC5A54'
    return '#646E7B'
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e4e7eb;
  border-left: 0px;
  color: ${(props) => (props.color !== 'dark' ? '#7D8793' : 'white')};
  height: 50px;
  padding: 12px 18px;
  position: relative;
  text-transform: uppercase;
  width: ${(props) => (props.color === 'dark' ? '90px' : '144px')};

  svg {
    margin-left: -9px;
    margin-top: 9px;
    width: 14px !important;
  }

  &:hover {
    ${CheckboxWrap} {
      label {
        display: block;
      }
    }
  }

  p {
    bottom: -1px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    right: 14px;
  }

  span {
    bottom: 3px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    position: absolute;
    right: 14px;
  }

  svg {
    bottom: 5px;
    height: 20px;
    left: 5px;
    position: absolute;
    width: 20px;
  }
`

const CellLeft = styled.div`
  align-items: center;
  background: ${(props) => (props.checked ? '#D1F2F7' : '#e4e7eb')};
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 100%;
  justify-content: center;
  width: 37px;
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const StyledCheckbox = styled.div`
  align-items: center;
  background: ${({ isChecked }) => (isChecked ? '#A2E5EF' : 'white')};
  border: 1px solid ${({ isChecked }) => (isChecked ? '#17BFD6' : '#CCD2D8')};
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: flex;
  height: 20px;
  justify-content: center;
  position: relative;
  top: -1px;
  transition: all 150ms;
  width: 20px;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.05);
  }
  svg {
    fill: '#002033';
    position: relative;
    strokewidth: 2px;
    visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  }
`

export const CheckboxWrap = styled.label`
  align-items: center;
  cursor: pointer;
  display: ${(props) => (props.checked ? 'block' : 'none')};
  justify-content: flex-start;
  margin-bottom: 0;
  opacity: 1;
  position: relative;
  user-select: none;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

export function CheckboxSVG() {
  return (
    <svg fill="none" height="12" viewBox="0 0 14 12" width="14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8407 1.9845L4.80966 10.0156L1.15918 6.36507"
        stroke="#002033"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export function AgencySVG() {
  return (
    <svg fill="none" height="18" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.198 0H2.218A2.22 2.22 0 000 2.218v15.179c0 .333.27.603.603.603h3.918v-2.864a2.22 2.22 0
           012.218-2.218h1.938a2.22 2.22 0 012.218 2.218V18h3.918c.333 0 .603-.27.603-.603V2.217A2.22 2.22
           0 0013.198 0zM4.26 11.364a.608.608 0 01-.426.177.606.606 0 01-.426-1.03.607.607 0 01.426-.176.608.608
           0 01.603.603.608.608 0 01-.177.426zm0-2.584a.606.606 0 01-.853 0 .606.606 0 010-.852.607.607 0 01.853
           0 .607.607 0 010 .852zm0-2.583a.607.607 0 01-1.03-.427.606.606 0 01.604-.603.607.607 0 01.427
           1.03zm0-2.584a.607.607 0 01-.853 0 .607.607 0 010-.853.607.607 0 01.853 0 .606.606 0 010 .853zm2.584
           7.751a.608.608 0 01-.427.177.608.608 0 01-.426-.177.607.607 0 01.427-1.03.607.607 0 01.602.604.606.606 0
           01-.176.426zm0-2.584a.607.607 0 01-.853 0 .608.608 0 01-.177-.426c0-.159.065-.314.177-.426a.608.608 0
           01.426-.177.608.608 0 01.427 1.03zm0-2.583a.608.608 0 01-.427.176.608.608 0 01-.426-.176.608.608 0 010-.853.608.608
           0 01.426-.177.608.608 0 01.427 1.03zm0-2.584a.608.608 0 01-.853 0 .607.607 0 01.427-1.03.606.606 0 01.602.604.609.609
           0 01-.176.426zm2.583 7.751a.608.608 0 01-.426.177.608.608 0 01-.426-.177.607.607 0 01.426-1.03.608.608 0 01.603.604.606.606
           0 01-.177.426zm0-2.584a.606.606 0 01-.852 0 .607.607 0 010-.852.607.607 0 01.852 0 .606.606 0 010 .852zM8.397 5.77A.608.608
           0 019 5.167a.608.608 0 01.426 1.03.607.607 0 01-.852 0 .607.607 0 01-.177-.427zm1.03-2.157a.607.607 0 01-.853 0A.607.607 0 019
           2.583a.606.606 0 01.602.604.608.608 0 01-.177.426zm2.583 7.751a.608.608 0 01-.426.177.608.608 0 01-.603-.603.606.606 0 01.603-.603c.158
           0 .314.064.426.176a.606.606 0 010 .853zm0-2.584a.606.606 0 01-.852 0 .606.606 0 010-.852.607.607 0 01.852 0 .607.607 0 010
           .852zm0-2.583a.607.607 0 01-1.03-.427.606.606 0 01.604-.603.607.607 0 01.426 1.03zm0-2.584a.607.607 0 01-1.03-.427.606.606 0
           01.604-.602.606.606 0 01.603.603.607.607 0 01-.177.426z"
        fill="#212D35"
      />
      <path
        d="M8.677 14.124h-.366V18H9.69v-2.864c0-.558-.454-1.011-1.012-1.011zM5.727 15.136V18h1.378v-3.876h-.366c-.558 0-1.012.454-1.012 1.012z"
        fill="#212D35"
      />
    </svg>
  )
}

// Table Header Checkbox ******************************

export function TableHeaderCheckbox({ day, index, toggleWeek, checked }) {
  return (
    <TableCell>
      {day}
      <CheckboxWrap checked>
        <HiddenCheckbox isChecked={checked} name="shift_checkbox--1" onChange={() => toggleWeek(index)} />
        <StyledCheckbox isChecked={checked} name="shift_checkbox--2">
          <CheckboxSVG />
        </StyledCheckbox>
      </CheckboxWrap>
    </TableCell>
  )
}

// 3. Filled Shift *************************************

export function FilledShift({ day, shiftSelected, toggleShiftSelection }) {
  const [shift] = day.shifts
  const { department, id, proposedEndTime, proposedStartTime } = shift
  const checked = shiftSelected(id)

  return (
    <UnfilledCellTwo filled>
      <CellLeft checked={checked}>
        {formatTimeInTZ(day.date, 'DD')}

        <CheckboxWrap checked={checked}>
          <HiddenCheckbox
            isChecked={checked}
            name={`shift_checkbox--${id}`}
            onChange={() => toggleShiftSelection(id)}
          />
          <StyledCheckbox isChecked={checked} name={`shift_checkbox--${id}`}>
            <CheckboxSVG />
          </StyledCheckbox>
        </CheckboxWrap>
      </CellLeft>

      <FilledCell>
        <p>
          {formatTimeInTZ(proposedStartTime)} - {formatTimeInTZ(proposedEndTime)}
        </p>

        <span>{department.label.slice(0, 12)}</span>
      </FilledCell>
    </UnfilledCellTwo>
  )
}

const FilledCell = styled.div`
  background: #e6f2f9;
  border: ${(props) => (props.breach ? '1px solid #DC5A54' : '#ccd2d8')};
  border-radius: 5px;
  color: #55606c;
  font-size: 12px;
  height: 40px;
  margin-left: 4px;
  margin-top: 4px;
  opacity: 1;
  padding: 6px 0 0 6px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 97px;
  p {
    color: #414c58;
    font-size: 14px;
    font-weight: bold;
    line-height: 12px;
    margin: 0;
  }
  span {
    color: #414c58;
  }
`

// MULTI GETTING REPLACED TODO ****************************************

const UnfilledCellThree = styled.div`
  border: 1px solid #ccd2d8;
  display: flex;
  height: 50px;
  text-overflow: ellipsis;
  width: 144px;
`

function MultiCell({ day, selectedShifts, shift, shiftSelected, toggleShiftSelection }) {
  const { id } = shift
  const { proposedEndTime, proposedStartTime } = day

  const [checked, setChecked] = useState(false)

  useEffect(() => {
    setChecked(shiftSelected(id))
  }, [selectedShifts, id])

  return (
    <MultiDayStyle checked={checked} onClick={() => toggleShiftSelection(id)}>
      {formatTimeInTZ(proposedStartTime, 'HH:mm')} - {formatTimeInTZ(proposedEndTime, ' HH:mm')}
    </MultiDayStyle>
  )
}

const MultiDayStyle = styled.div`
  background: ${(props) => (props.checked ? '#A2E5EF' : '#e6f2f9')};
  border-color: ${(props) => (props.checked ? '#17BFD6' : '#e6f2f9')};
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  color: #55606c;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  height: 18px;
  opacity: 1;
  padding: 0px 6px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;
`

const MultiCellRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2px;
  width: 105px;
`

// 4. Multi Shifts TODO ***********************

export function MultiShift({ day, selectedShifts, shiftSelected, toggleShiftSelection }) {
  const [shiftOne, shiftTwo] = day.shifts

  return (
    <UnfilledCellThree>
      <CellLeft checked={false}>{formatTimeInTZ(day?.date, 'DD')}</CellLeft>

      <MultiCellRight>
        <MultiCell
          day={shiftOne}
          selectedShifts={selectedShifts}
          shift={shiftOne}
          shiftSelected={shiftSelected}
          toggleShiftSelection={toggleShiftSelection}
        />

        <MultiCell
          day={shiftTwo}
          selectedShifts={selectedShifts}
          shift={shiftTwo}
          shiftSelected={shiftSelected}
          toggleShiftSelection={toggleShiftSelection}
        />
      </MultiCellRight>
    </UnfilledCellThree>
  )
}

export function ThreeShift({ day }) {
  return (
    <UnfilledCellThree>
      <CellLeft checked={false}>{formatTimeInTZ(day.date, 'DD')}</CellLeft>
      <FilledCell>{day.shifts.length} Shifts</FilledCell>
    </UnfilledCellThree>
  )
}

// 5. Styled Button

export const StyledButton = styled.button`
  align-items: center;
  background: ${(props) => {
    if (props.color === 'blue') return '#17BFD6'
    if (props.color === 'white') return 'white'
    if (props.color === 'dark') return '#55606C'
    return '#E4E7EB'
  }};

  border-radius: 8px;
  border-width: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  color: ${(props) => (props.color === 'dark' ? 'white' : '#002033')};
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  height: 42px;
  justify-content: center;
  margin-left: ${(props) => props.color === 'grey' && '12px'};
  position: ${(props) => (props.right ? 'absolute' : 'relative')};
  right: ${(props) => `${props.right}px`};
  top: ${(props) => props.right && props.color !== 'dark' && '-66px'};
  transition: all 0.3s ease-in;
  width: ${(props) => `${props.width}px`};
  &:hover {
    background: ${(props) => props.color === 'white' && '#e4e7eb'};
  }

  svg {
    margin-right: 8px;
  }
`
