import React, { useState } from 'react'

import AmendRateModal from './AmendRatesModal'

const AmendRate = () => {
  const [modalStep, setModalStep] = useState("step-one");
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setModalStep("step-one");
    setShow(false);
  }

  const handleShow = () => setShow(true);

  return (
    <>
      <button className="btn btn-white action-bar__drop-up-btn" onClick={handleShow} data-target="action_bar.amendRates">
        <svg width="14" height="14" style={{ marginRight: 8 }} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.646 2.337L.943 10.041a.306.306 0 00-.08.14L.009 13.61a.305.305 0 00.367.367l3.427-.854a.302.302 0 00.141-.08l7.704-7.703-3.002-3.002zM13.556 1.287L12.7.43c-.573-.573-1.572-.573-2.145 0l-1.05 1.05 3.002 3.002 1.05-1.05c.286-.287.444-.668.444-1.073 0-.405-.158-.786-.444-1.072z" fill="#9EA8B3" /></svg>
        Amend Rates
      </button>
      {show &&
        <AmendRateModal handleClose={handleClose} modalStep={modalStep} setModalStep={setModalStep} show={show} />
      }
    </>
  )
}

export default AmendRate