import { Controller } from 'stimulus'
import queryString from 'query-string'

export default class FilterToggleController extends SearchController {

    static get targets() {
        return ['left', 'right', 'param']
    }

    connect() {
    }

    left() {
        this.search(this.data.get('left-value'))
        this.rightTarget.classList.remove('filter__parameter-area--toggle-buttons')
        this.leftTarget.classList.add('filter__parameter-area--toggle-buttons')
    }

    right() {
        this.search(this.data.get('right-value'))
        this.leftTarget.classList.remove('filter__parameter-area--toggle-buttons')
        this.rightTarget.classList.add('filter__parameter-area--toggle-buttons')
    }

    search(search_value) {
        this.storeFilters(this.paramTarget.value, [{ value: search_value }])
    }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("filter_toggle", FilterToggleController)