import React from 'react'
import PropTypes from 'prop-types'
import PaymentsDashboard from './PaymentsDashboard'

function Payments({ collabBankMember, ppiSail, ppiCollabBank, staffGroups, onCall, hub3Url }) {
  return (
    <PaymentsDashboard
      collabBankMember={collabBankMember}
      onCall={onCall}
      ppiSail={ppiSail}
      ppiCollabBank={ppiCollabBank}
      staffGroups={staffGroups}
      hub3Url={hub3Url}
    />
  )
}

Payments.propTypes = {
  collabBankMember: PropTypes.bool.isRequired,
  ppiSail: PropTypes.bool.isRequired,
  ppiCollabBank: PropTypes.bool.isRequired,
  staffGroups: PropTypes.array.isRequired,
  onCall: PropTypes.bool.isRequired,
  hub3Url: PropTypes.string.isRequired,
}

export default Payments
