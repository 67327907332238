/* eslint-disable */
import React from 'react';

export default function EyeIcon() {
  return (
    <div className='reset-pin__eye-icon'>
      <svg width="21" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)" stroke="#9CA5B0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
          <path d="M1.3335 9.99998s3.33333-6.66667 9.1667-6.66667c5.8333 0 9.1666 6.66667 9.1666 6.66667s-3.3333 6.66662-9.1666 6.66662c-5.83337 0-9.1667-6.66662-9.1667-6.66662Z" fill="#fff"/>
          <path d="M10.5 12.5c1.3807 0 2.5-1.1193 2.5-2.5 0-1.38071-1.1193-2.5-2.5-2.5C9.11929 7.5 8 8.61929 8 10c0 1.3807 1.11929 2.5 2.5 2.5Z"/>
        </g>
        <defs>
          <clipPath id="a">
            <path fill="#fff" d="M.5 0h20v20H.5z"/>
          </clipPath>
        </defs>
      </svg>   
    </div>    
  )
}