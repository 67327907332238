import { Controller } from "stimulus";
import SlimSelect from "slim-select";

export default class StaffSelectorController extends SearchController {
  static get targets() {
    return ["staff_group_id", "grade_id", "label", "select"];
  }

  connect() {
    window.qs = queryString;
    this.element[this.identifier] = this;
    this.queryString = queryString.parse(location.search);
    const target = this.selectTarget;
    const searchOnSelect = this.data.get("search-on-select");
    const closeOnSelect = this.data.get("keep-open") ? false : true;
    const getGrades = document.getElementById("get_grades").value;

    this.select = new SlimSelect({
      select: target,
      placeholder: "Select staff group",
      closeOnSelect: closeOnSelect,
      onChange: (info) => {
        this.storeFilters("staff_group_ids[]", info);
        if (searchOnSelect) {
          this.multiSearchOnSelect("staff_group_ids[]", info);
        }
        if (getGrades) {
          this.fetchGrades();
        }
      },
    });

    if (this.data.get("initial-value")) {
      this.select.set(this.data.get("initial-value"));
    }
  }

  fetchGrades() {
    const staff_group_id = parseInt(this.selectTarget.value);
    const query = `query FilterGrades($staff_group_id: Int!){
        filterGrades(staffGroupId: $staff_group_id){
          id
          title
        }
      }`;
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { staff_group_id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const filterGrades = !!data.errors ? [] : data.data.filterGrades;
        let gradesNode = document.getElementById("filter-grade-selector");

        const gradesSelect = gradesNode;
        gradesSelect.options.length = null;
        const defaultOption = `<option value="">Select Grade</option>`;
        gradesSelect.insertAdjacentHTML("beforeend", defaultOption);

        filterGrades.forEach((grade) => {
          const option = `<option value=${grade.id}>${grade.title}</option>`;
          gradesSelect.insertAdjacentHTML("beforeend", option);
        });
      });
  }

  select(event) {
    event.preventDefault();
    const grade_id = event.toElement.dataset.id;
    this.grade_idTarget.value = grade_id;
    this.staff_group_idTarget.value = grade_id; // TODO: Use the real staff group

    const staff_group_label = this.findParentMenuButton(
      event
    ).textContent.trim();
    const grade_label = event.toElement.textContent.trim();
    const new_label = `${staff_group_label}, ${grade_label}`;
    this.labelTarget.textContent = new_label;
  }

  findParentMenuButton(event) {
    return event.toElement.parentElement.parentElement.getElementsByTagName(
      "button"
    )[0];
  }
}
import { application } from "../../frontend/controllers/index";
import queryString from "query-string";
import SearchController from "../search_controller";

application.register("staff_selector", StaffSelectorController);

