import { gql } from "@apollo/client";

export const VMS_AGENCY_SHIFTS = gql`
  query GetVmsAgencyShifts(
    $bookedByAgency: Boolean
    $departmentIds: [Int!]
    $siteIds: [Int!]
    $specialityIds: [Int!]
    $staffGroupIds: [Int!]
    $gradeIds: [Int!]
    $agencyWorkerIds: [Int!]
    $fromStartTime: DateTime
    $toStartTime: DateTime
    $shiftType: ShiftTypeEnum
    $rate: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    vmsShifts(
      bookedByAgency: $bookedByAgency
      departmentIds: $departmentIds
      siteIds: $siteIds
      specialityIds: $specialityIds
      staffGroupIds: $staffGroupIds
      gradeIds: $gradeIds
      agencyWorkerIds: $agencyWorkerIds
      fromStartTime: $fromStartTime
      toStartTime: $toStartTime
      rate: $rate
      shiftType: $shiftType
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        node {
          id
          startTime
          endTime
          department {
            id
            name
          }
          site {
            id
            name
          }
          grade {
            id
            title
          }
          organisation {
            id
            name
          }
          status
          shiftType
          agencyLocked
          sentToAgency
          withSecondaryBank
          dateSentToAgency
          totalRateSummary
          agencyRateSummary
          agencyBooking {
            agencyWorker {
              id
              firstName
              lastName
              grade {
                id
                title
              }
              staffGroup {
                id
                title
              }
              niNumber
              regBodyNumber
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;
