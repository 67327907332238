/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */

import { DefaultButton, FormGroup, FormLabel, GLOBAL, FormSelect, GREY } from '@patchworkhealth/web-components'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  removePercentageWithinSpans,
  AgencyProposalFooter,
  AgencyContracts,
} from '../../../../packs/agency_proposals/deContract'

export function ViewBlockProposalContract({
  proposal,
  employmentType,
  paymentType,
  inputs,
  showVatRegistered,
  setInputs,
  handleAssignContract,
}) {
  const contentRef = useRef(null)
  const filteredOptions = inputs.contractOptions.filter((option) =>
    option.contractType.includes(
      proposal.agencyWorker.paymentType === 'ltd' && proposal.agencyWorker.isSoleTrader
        ? 'de_sole_trader'
        : proposal.agencyWorker.paymentType === 'ltd'
        ? 'de_ltd'
        : 'de_paye'
    )
  )
  const { selectedContract } = inputs

  // useEffect to update the preview content when the selected contract changes
  useEffect(() => {
    const selectedValue = inputs.contractOptions.find((contract) => contract.id === selectedContract?.value)

    if (contentRef?.current) {
      contentRef.current.innerHTML = removePercentageWithinSpans(selectedValue?.content ?? '')
    }
  }, [selectedContract, inputs.contractOptions])

  useEffect(() => {
    // If there is only one option in the filtered list, preselect it
    if (filteredOptions.length === 1) {
      setInputs({ ...inputs, selectedContract: { label: filteredOptions[0].name, value: filteredOptions[0].id } })
    }
  }, [])

  return (
    <ProposeWorkerContainer>
      <ProposeWorkerDetails>
        <ProposeWorkerDetailsName onClick={() => setInputs({ ...inputs, showWorkerDetails: proposal.agencyWorker.id })}>
          {proposal?.agencyWorker?.firstName} {proposal?.agencyWorker.lastName}
        </ProposeWorkerDetailsName>
        <ProposeWorkerDetailsGrade color={proposal?.agencyWorker?.grade?.colour}>
          {proposal?.agencyWorker?.grade?.title}
        </ProposeWorkerDetailsGrade>
        <ProposeWorkerDetailsEmail>{proposal?.agencyWorker?.email}</ProposeWorkerDetailsEmail>

        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex' }}>
            <EmploymentIcon>{employmentType === 'direct_engagement' ? 'DE' : 'NonDE'}</EmploymentIcon>
            <EmploymentIcon>{paymentType}</EmploymentIcon>
          </div>
          {showVatRegistered && (
            <div style={{ marginLeft: 'auto' }}>
              <EmploymentIcon>VAT registered: {proposal?.agencyWorker?.isVatRegistered ? 'Yes' : 'No'}</EmploymentIcon>
            </div>
          )}
        </div>
      </ProposeWorkerDetails>

      <hr />

      <AgencyContracts>
        <FormGroup style={{ width: 200, marginRight: 22 }}>
          <FormLabel>Attach Contract</FormLabel>
          <FormSelect
            onChange={(option) => {
              setInputs({ ...inputs, selectedContract: option })
            }}
            options={filteredOptions.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            placeholder="Select Contract"
            value={selectedContract}
          />
        </FormGroup>
      </AgencyContracts>

      {selectedContract && (
        <div
          ref={contentRef}
          onInput={() => null}
          style={{
            position: 'relative',
            minHeight: 0,
            padding: 26,
            overflow: 'auto',
            maxHeight: 450,
            background: 'white',
            border: '1px solid #dddddd',
          }}
        />
      )}

      <AgencyProposalFooter>
        <div />
        <div>
          <DefaultButton
            onClick={() => setInputs({ ...inputs, deProposal: null, selectedContract: null })}
            style={{ marginRight: 5 }}
            type="white"
          >
            Cancel
          </DefaultButton>

          <DefaultButton
            onClick={() => handleAssignContract(proposal)}
            style={{
              pointerEvents: selectedContract ? 'all' : 'none',
              opacity: selectedContract ? '1' : '0.5',
            }}
          >
            Attach DE Contract
          </DefaultButton>
        </div>
      </AgencyProposalFooter>
    </ProposeWorkerContainer>
  )
}

export const ProposeWorkerContainer = styled.div`
  align-items: center;
  background-color: ${GLOBAL.backgroundColor};
  border-radius: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`

export const ProposeWorkerDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-align: center;
  margin-bottom: 30px;
`

export const ProposeWorkerDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProposeWorkerDetailsName = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: underline;
  cursor: pointer;
`

export const ProposeWorkerDetailsGrade = styled(ProposeWorkerDetailsName)`
  color: ${({ color }) => color || GLOBAL.fontDark};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: none;
`

export const ProposeWorkerDetailsEmail = styled(ProposeWorkerDetailsGrade)`
  color: ${GREY.six};
  font-weight: 400;
  margin: 0;
`

export const EmploymentIcon = styled.div`
  align-items: center;
  background-color: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  padding: 0 8px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 10px;
`

// (rest of the code remains unchanged)
