import {Controller} from 'stimulus'
import {application} from '../../frontend/controllers/index'

export default class QuickSightVisualController extends Controller {
  onVisible(element, callback) {
    new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          callback()
          observer.disconnect()
        }
      })
    }).observe(element)
    if (!callback) return new Promise((r) => (callback = r))
  }

  formatDate(date) {
    const year = date.toLocaleString('default', {year: 'numeric'})
    const month = date.toLocaleString('default', {month: '2-digit'})
    const day = date.toLocaleString('default', {day: '2-digit'})
    return `${year}/${month}/${day}`
  }

  connect() {
    const visual = async () => {
      const {createEmbeddingContext} = QuickSightEmbedding

      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {
        },
      })

      const frameOptions = {
        url: this.data.get('url'),
        container: `#${this.data.get('container')}`,
        height: '115px',
        width: '200px',
        resizeHeightOnSizeChangedEvent: false,
        onChange: (changeEvent, metadata) => {
        },
      }

      const today = new Date()
      const priorDate = new Date(new Date().setDate(today.getDate() - 30))

      const endDate = this.formatDate(today)
      const startDate = this.formatDate(priorDate)

      const contentOptions = {
        parameters: [
          {
            Name: 'Organisation',
            Values: [this.data.get('organisation')],
          },
          {
            Name: 'Department',
            Values: [this.data.get('department')],
          },
          {
            Name: 'ShiftType',
            Values: ['Hourly'],
          },
          {
            Name: 'GradeSpecific',
            Values: ['All'],
          },
          {
            Name: 'Grade',
            Values: [this.data.get('grade')],
          },
          {
            Name: 'StaffGroup',
            Values: ['All'],
          },
          {
            Name: 'Hours',
            Values: [this.data.get('hours')],
          },
          {
            Name: 'DateStart',
            Values: [startDate],
          },
          {
            Name: 'DateEnd',
            Values: [endDate],
          },
          {
            Name: 'Rate',
            Values: [this.data.get('rate')],
          },
        ],
        locale: 'en-US',
      }

      console.log('organisation: ', this.data.get('organisation'))
      console.log('department: ', this.data.get('department'))
      console.log('grade: ', this.data.get('grade'))
      console.log('rate: ', this.data.get('rate'))
      console.log('hours: ', this.data.get('hours'))
      console.log('startDate: ', startDate)
      console.log('endDate: ', endDate)

      await embeddingContext.embedVisual(frameOptions, contentOptions)
    }

    this.onVisible(document.querySelector(`#${this.data.get('wait-for')}`), () => {
      visual()
    })
  }
}
application.register('quicksight-visual', QuickSightVisualController)
