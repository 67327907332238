/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import React, { useEffect, useRef } from 'react'
import InputGroup from 'react-bootstrap/InputGroup'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components'

dayjs.extend(customParseFormat)

export function DateInput({ name, width, onChange, range, value, month }) {
  const ref = useRef()

  const parseInputAndDisplay = () => {
    const parsed = dayjs(value, ['DD MM YYYY', 'YYYY MM DD']).toDate()
    const parsedJsDate = dayjs(value) // support js date inputs

    if (dayjs(parsed).isValid()) return dayjs(parsed).format('DD/MM/YYYY')

    if (dayjs(parsedJsDate).isValid()) return dayjs(parsedJsDate).format('DD/MM/YYYY')
  }

  const handleClickOutside = (e) => {
    if (!ref.current.input.parentElement.parentElement.contains(e.target)) {
      ref.current.hideDayPicker()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <div style={width ? { width } : undefined}>
      <FormInputGroup>
        <FormInputGroup.Prepend
          onClick={() => {
            ref.current.showDayPicker()
          }}
          style={{ cursor: 'pointer' }}
        >
          <FormInputGroup.Text>
            <InputDate />
          </FormInputGroup.Text>
        </FormInputGroup.Prepend>
        <DayPickerInput
          ref={ref}
          dayPickerProps={{
            month: month && new Date(month),
            modifiers: {
              disabled: [range],
            },
          }}
          disabled
          format="DD/MM/YYYY"
          name={name}
          onDayChange={onChange}
          placeholder="DD/MM/YYYY"
          style={{ width: '100%' }}
          value={value && parseInputAndDisplay()}
        />
      </FormInputGroup>
    </div>
  )
}

export const FormInputGroup = styled(InputGroup).attrs((props) => ({
  width: props.width,
}))`
  flex-wrap: nowrap;

  input {
    height: 100%;
    border: 1px solid #ccd2d8;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-top-right-radius: 6px;
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-right-radius: 6px;
    width: 100%;
  }

  .input-group-text {
    background-color: '#F5F7FA';
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    justify-content: center;
    width: 50px;
  }
`

function InputDate() {
  return (
    <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.778 22.905H4.222c-1.227 0-2.222-.98-2.222-2.19V5.38c0-1.21.995-2.19 2.222-2.19h2.222V1h2.223v2.19h6.666V1h2.223v2.19h2.222c1.227 0 2.222.981 2.222 2.191v15.333c0 1.21-.995 2.19-2.222 2.19zM4.222 9.762v10.952h15.556V9.762H4.222zm0-4.381v2.19h15.556v-2.19H4.222zm8.89 12.843a.3.3 0 01-.3.3h-1.623a.3.3 0 01-.3-.3v-1.59a.3.3 0 01.3-.3h1.622a.3.3 0 01.3.3v1.59zm-4.445 0a.3.3 0 01-.3.3H6.744a.3.3 0 01-.3-.3v-1.59a.3.3 0 01.3-.3h1.623a.3.3 0 01.3.3v1.59zm8.889-4.381a.3.3 0 01-.3.3h-1.623a.3.3 0 01-.3-.3v-1.59a.3.3 0 01.3-.3h1.623a.3.3 0 01.3.3v1.59zm-4.445 0a.3.3 0 01-.3.3H11.19a.3.3 0 01-.3-.3v-1.59a.3.3 0 01.3-.3h1.622a.3.3 0 01.3.3v1.59zm-4.444 0a.3.3 0 01-.3.3H6.744a.3.3 0 01-.3-.3v-1.59a.3.3 0 01.3-.3h1.623a.3.3 0 01.3.3v1.59z"
        fill="#9CA5B0"
      />
    </svg>
  )
}
