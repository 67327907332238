/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client'
import {
  FormGroup,
  FormSelect,
  FormLabel,
  Loading,
  DefaultButton,
  CloseButton,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import {
  successToast,
  errorToast,
  loadingToast,
  SHIFT_BLOCK_SEND_BLOCK_TO_AGENCY_TIER,
  SHIFT_BLOCK_UPDATE_AGENCY_TIER_ON_BLOCK,
  SHIFT_BLOCK_TIERS,
} from '../helpers/BlockBookingsHelpers'

function SendBlockToAgencyModal({ openModal, block, handleClose, setSelectedBlock, refetch }) {
  const [tier, setTier] = useState('')

  // GQL ****************************************

  const [shiftBlockSendBlockToAgencyTier] = useMutation(SHIFT_BLOCK_SEND_BLOCK_TO_AGENCY_TIER)
  const [shiftBlockUpdateAgencyTier] = useMutation(SHIFT_BLOCK_UPDATE_AGENCY_TIER_ON_BLOCK)

  const { data, loading } = useQuery(SHIFT_BLOCK_TIERS, {
    fetchPolicy: 'network-only',
    skip: !block.id,
    variables: {
      shiftBlockIds: [Number(block.id)],
      statuses: [block.status],
    },
  })

  const sendBlockToAgency = async (blockId) => {
    toast.loading('Loading', loadingToast)
    try {
      const { data: result } = await shiftBlockSendBlockToAgencyTier({
        variables: {
          shiftBlockId: parseInt(blockId, 10),
          agencyTierId: Number(tier?.value),
        },
      })

      if (result?.shiftBlockSendBlockToAgencyTier?.errors?.length) {
        toast.update(2, errorToast)
      } else {
        toast.update(2, { ...successToast, render: 'Block sent to Agency' })

        refetch()
        setSelectedBlock(false)
        setTier()
        handleClose()
      }
    } catch (err) {
      toast.update(2, errorToast)
    }
  }

  const updateAgencyTier = async (blockId) => {
    toast.loading('Loading', loadingToast)

    try {
      const { data: result } = await shiftBlockUpdateAgencyTier({
        variables: {
          shiftBlockId: parseInt(blockId, 10),
          agencyTierId: Number(tier?.value),
        },
      })

      if (result?.shiftBlockUpdateAgencyTier?.errors?.length) {
        toast.update(2, errorToast)
      } else {
        toast.update(2, { ...successToast, render: 'Agency Tier Updated' })

        refetch()

        setSelectedBlock(false)
        setTier()
        handleClose()
      }
    } catch (err) {
      toast.update(2, errorToast)
    }
  }

  // Functions **********************************

  const handleCloseConfirm = () => {
    handleClose()
  }

  const blockData = data?.shiftBlocks?.nodes[0]
  const tiers = blockData?.grade?.organisationStaffGroup?.agencyTiers
  const update = block?.sentToAgency

  return (
    <Modal backdrop="static" centered onHide={handleCloseConfirm} show={openModal} size="lg">
      <ModalHeader style={{ height: 80, padding: '15px 23px' }}>
        <ModalTitle>{update ? 'Update Agency Tier' : 'Send to Agency'}</ModalTitle>
        <CloseButton onClick={handleCloseConfirm} />
      </ModalHeader>
      <>
        <ModalBody>
          {loading && <Loading />}

          {!loading && tiers && (
            <FormGroup>
              <FormLabel>Select Tier 1</FormLabel>
              <FormSelect
                disabled
                isMulti={false}
                onChange={(e) => setTier(e)}
                options={tiers}
                placeholder="Select tier"
                value={tier}
              />
            </FormGroup>
          )}

          {block?.autoCascadeTriggers?.length > 0 && (
            <div style={{ marginBottom: 24 }}>
              {tier !== '' && tier?.label !== block?.autoCascadeTriggers[0]?.toTier && (
                <div
                  style={{
                    border: '1px solid #e74c3c',
                    padding: 12,
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 22,
                  }}
                >
                  <svg fill="none" height="21" viewBox="0 0 24 21" width="24" xmlns="http://www.w3.org/2000/svg">
                    <path
                      clipRule="evenodd"
                      d="M10.528 1.28312C10.9773 1.03017 11.4842 0.897278 11.9998 0.897278C12.5154 0.897278 13.0223 1.03017 13.4716 1.28312C13.9209 1.53607 14.2974 1.90055 14.5648 2.34139L14.5677 2.34614L23.0376 16.4862L23.0458 16.5C23.3077 16.9536 23.4463 17.4679 23.4478 17.9917C23.4493 18.5156 23.3135 19.0307 23.0541 19.4858C22.7947 19.9408 22.4207 20.3201 21.9692 20.5857C21.5177 20.8514 21.0046 20.9942 20.4808 21L20.4698 21.0001L3.51879 21C2.99498 20.9943 2.48182 20.8514 2.03035 20.5857C1.57887 20.3201 1.20483 19.9408 0.945426 19.4858C0.686022 19.0307 0.550303 18.5156 0.55177 17.9917C0.553236 17.4679 0.691839 16.9536 0.953786 16.5L0.961909 16.4862L9.43191 2.34615L10.2898 2.86001L9.43478 2.34139C9.70218 1.90055 10.0787 1.53607 10.528 1.28312ZM11.146 3.37666L2.68246 17.5058C2.59729 17.6556 2.55224 17.8249 2.55176 17.9973C2.55127 18.172 2.59651 18.3436 2.68298 18.4953C2.76945 18.647 2.89413 18.7735 3.04462 18.862C3.1938 18.9498 3.36317 18.9973 3.53617 19H20.4634C20.6364 18.9973 20.8058 18.9498 20.9549 18.862C21.1054 18.7735 21.2301 18.647 21.3166 18.4953C21.403 18.3436 21.4483 18.172 21.4478 17.9973C21.4473 17.825 21.4023 17.6557 21.3171 17.5059L12.8548 3.37865L12.8536 3.37666C12.7645 3.23061 12.6395 3.10983 12.4904 3.02589C12.3406 2.94157 12.1716 2.89728 11.9998 2.89728C11.8279 2.89728 11.6589 2.94157 11.5092 3.02589C11.3601 3.10983 11.235 3.23061 11.146 3.37666ZM11.9998 7.00002C12.5521 7.00002 12.9998 7.44773 12.9998 8.00002V12C12.9998 12.5523 12.5521 13 11.9998 13C11.4475 13 10.9998 12.5523 10.9998 12V8.00002C10.9998 7.44773 11.4475 7.00002 11.9998 7.00002ZM11.9998 15C11.4475 15 10.9998 15.4477 10.9998 16C10.9998 16.5523 11.4475 17 11.9998 17H12.0098C12.5621 17 13.0098 16.5523 13.0098 16C13.0098 15.4477 12.5621 15 12.0098 15H11.9998Z"
                      fill="#E6515D"
                      fillRule="evenodd"
                    />
                  </svg>
                  <div style={{ marginLeft: 12 }}>
                    <p
                      style={{
                        fontSize: '14px',
                        fontWeight: 'bold',
                        margin: 0,
                      }}
                    >
                      You are about to override your departments rules for autocascading to agency.
                    </p>
                    <span style={{ display: 'block', fontSize: 14 }}>
                      Block trigger to be sent to Tier ({block.autoCascadeTriggers[0].toTier})
                    </span>
                  </div>
                </div>
              )}
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <div />
          <div>
            <DefaultButton action={() => handleCloseConfirm()} text="Cancel" type="white" />
            <DefaultButton
              action={() => {
                if (update) {
                  updateAgencyTier(blockData.id)
                  return
                }

                sendBlockToAgency(blockData.id)
              }}
              color="blue"
              text={update ? 'Update Agency Tier' : 'Send Block to Agency'}
            />
          </div>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default SendBlockToAgencyModal
