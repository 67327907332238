import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../../packs/application";

import AmendAgencyTier from "./AmendAgencyTier";

function AmendAgencyTierIndex() {
  return (
    <ApolloProvider client={client}>
      <AmendAgencyTier />
    </ApolloProvider>
  );
}

export default AmendAgencyTierIndex;
