import { Controller } from 'stimulus'

export default class TimesheetAuthoriserSelectorController extends Controller {

    static targets = ['filter', 'list'];

    connect() {
      this.filterTarget.addEventListener('keyup', (_e) => this.filterChange())
      this.authorisersList = this.listTarget.querySelectorAll('ul .authoriser__name');
    }

    filterChange() {
      const filter = this.filterTarget.value;

      if (filter) {
        this.filterResults(filter);
      } else {
        this.clearFilter();
      }
    }

    filterResults(filterInput) {
      const filterExp = new RegExp(filterInput, 'gi');

      this.authorisersList.forEach((authoriser) => {
        const classes = authoriser.parentElement.parentElement.classList;

        if (authoriser.innerText.match(filterExp)) {
          classes.remove('d-none');
        } else {
          classes.add('d-none');
        }
      })
    }

    clearFilter() {
      this.listTarget.querySelectorAll('li').forEach((li) => li.classList.remove('d-none'));
    }
}

import { application } from '../../frontend/controllers/index'

application.register("timesheet_authoriser_selector", TimesheetAuthoriserSelectorController)
