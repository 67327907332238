/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable sort-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/order */
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment'
import styled from 'styled-components'

import AppContext from '../../Context/AppContext'
import { fetchShift, sortShifts, watchShift, unwatchShift } from '../../Helpers/ShiftHelpers'
import { statusMap } from '../../Helpers/StatusHelpers'

import DropdownComponent, { ActionsDropdownMenuItem } from '../Dropdown'
import ShiftModal from '../Modals/ShiftModal/ShiftModal'
import { ErrorContainer } from '../Modals/ShiftModal/ShiftModalComponents'

import AssignAgency from '../../../img/illustrations/icons/action-assign-agency.svg'
import AssignWorker from '../../../img/illustrations/icons/action-assign-locum.svg'
import ActionWatch from '../../../img/illustrations/icons/action-watch.svg'
import ActionUnwatch from '../../../img/illustrations/icons/action-unwatch.svg'
import ActionDelete from '../../../img/illustrations/icons/action-cancel.svg'
import ActionViewShift from '../../../img/illustrations/icons/action-view-shift.svg'

const ShiftsCalenderModal = ({
  calendarTab,
  date,
  fetchShifts,
  handleClose,
  loading,
  setCalendarTab,
  shifts,
  show,
  usesMspShortlisting,
  canShortlistProposals,
}) => {
  const { user } = useContext(AppContext)
  const [error, setError] = useState({})
  const [selectedTab, setSelectedTab] = useState(undefined)
  const [shift, setShift] = useState(undefined)
  const [sortedShifts, setSortedShifts] = useState(undefined)
  const [showShift, setShowShift] = useState(false)

  useEffect(() => {
    if (shifts) {
      sortShifts(shifts, usesMspShortlisting, canShortlistProposals).then((data) => {
        if (data !== 'err') {
          setSortedShifts(data.events)
          setCalendarTab(calendarTab)
        }
      })
    }
  }, [shifts])

  const handleCloseShift = () => setShowShift(false)

  const shiftClick = (shift, selectedTab) => {
    setShift(shift)
    setSelectedTab(selectedTab)
    setTimeout(() => {
      setShowShift(true)
    }, 100)
  }

  return (
    <>
      {error && error.error && (
        <ErrorWrapper>
          <ErrorContainer>
            <p>{error.message}</p>
          </ErrorContainer>
        </ErrorWrapper>
      )}
      {loading ? (
        <LoadingBg>
          <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingBg>
      ) : (
        <ModalWrapper show={show} size="lg" onHide={handleClose} centered>
          <ShiftsCalenderModalHeader date={date} handleClose={handleClose} />
          <ModalBody>
            <ShiftCalendarModalMenu>
              <ul>
                {sortedShifts &&
                  sortedShifts.map((tab, index) => {
                    return (
                      <li
                        className={calendarTab === tab.extendedProps.status ? 'active' : ''}
                        onClick={() => setCalendarTab(tab.extendedProps.status)}
                        key={index}
                      >
                        {tab.extendedProps.status} ({tab.extendedProps.count})
                      </li>
                    )
                  })}
              </ul>
            </ShiftCalendarModalMenu>
            <div>
              {sortedShifts &&
                sortedShifts.map((tab, index) => {
                  return (
                    <ShiftCalendarModalBody
                      className={calendarTab === tab.extendedProps.status ? 'active' : ''}
                      key={index}
                    >
                      {tab.extendedProps.shifts?.map((shift) => {
                        return (
                          <Shift
                            key={shift.id}
                            setError={setError}
                            setShift={setShift}
                            shift={shift}
                            shiftClick={shiftClick}
                            userId={user.id}
                          />
                        )
                      })}
                    </ShiftCalendarModalBody>
                  )
                })}
            </div>
          </ModalBody>
        </ModalWrapper>
      )}
      {showShift && (
        <ShiftModal
          fetchShifts={fetchShifts}
          handleClose={handleCloseShift}
          shiftId={shift.id}
          show={showShift}
          tab={selectedTab}
          userId={user.id}
        />
      )}
    </>
  )
}

export default ShiftsCalenderModal

const Shift = ({ fetchShifts, setError, setShift, shift, shiftClick, userId }) => {
  const watchingShift = shift?.watchingHubUsers.map((item) => userId === item.user_id).length === 1
  const [watched, setWatched] = useState(watchingShift)
  const [statusAttributes, setStatusAttributes] = useState(undefined)

  useEffect(() => {
    if (shift) {
      setStatusAttributes({
        format: statusMap[shift.status].format,
        background: statusMap[shift.status].background,
        dark: statusMap[shift.status].dark,
      })
    }
  }, [shift])

  const watchAction = () => {
    if (watched) {
      return unwatchShift(shift).then((data) => {
        if (data !== 'err') {
          setWatched(false)
          updateShift(shift.id)
          fetchShifts
        } else {
          setError({ error: true, message: 'Unable to unwatch shift, please try again.' })
          setTimeout(() => {
            setError({})
          }, 5000)
        }
      })
    }
    return watchShift(shift).then((data) => {
      if (data !== 'err') {
        setWatched(true)
        updateShift(shift.id)
        fetchShifts
      } else {
        setError({ error: true, message: 'Unable to watch shift, please try again.' })
        setTimeout(() => {
          setError({})
        }, 5000)
      }
    })
  }

  const updateShift = (shiftId) => {
    if (shiftId)
      fetchShift(shiftId).then((data) => {
        if (data !== 'err') {
          setShift(data.data.shift)
        } else {
          setError({ error: true, message: 'Unable to update shift, please try again.' })
          setTimeout(() => {
            setError({})
          }, 5000)
        }
      })
  }

  const workerDetails = (user) => {
    const clickWorker = () => {
      window.open(`/workers?worker_id=${user.id}`, '_blank').focus()
    }

    return (
      <ShiftItem onClick={clickWorker}>
        <strong style={{ textDecoration: 'underline', cursor: 'pointer' }}>
          Bank Worker: {user.firstName} {user.lastName}
        </strong>
      </ShiftItem>
    )
  }

  return (
    <ShiftContainer>
      <div>
        <ShiftItem>
          <strong>Shift ID:</strong> {`#${shift.id}`}
        </ShiftItem>
        <ShiftItem>
          <strong>Dept:</strong> {shift.department ? shift.department.name : '-'}
        </ShiftItem>
        <ShiftItem>
          <strong>Site:</strong> {shift.site ? shift.site.name : '-'}
        </ShiftItem>
        <ShiftItem style={{ marginBottom: 20 }}>
          <strong>Total Rate (£/hr):</strong>{' '}
          {shift.escalatedBy ? (
            <ShiftItemEscalated style={{ color: 'rgb(255, 108, 108)' }}>
              {shift.totalRateSummary === '£' ? 'N/A (AFC)' : shift.totalRateSummary ? shift.totalRateSummary : '-'}
              <svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.133 13.635l-.005.023V3.99l3.046 3.04a.79.79 0 00.561.23.789.789 0 00.56-.23l.474-.473A.784.784 0 0012 5.998a.784.784 0 00-.23-.558L6.56.23A.783.783 0 006 0a.783.783 0 00-.56.23L.23 5.44a.784.784 0 00-.23.558c0 .212.082.41.23.559l.473.472a.783.783 0 001.106 0l3.063-3.073v9.69c0 .436.375.802.81.802h.67c.435 0 .781-.377.781-.813z"
                  fill="#FF6C6C"
                  fillRule="nonzero"
                />
              </svg>
            </ShiftItemEscalated>
          ) : shift.rateRequest ? (
            <>
              <ShiftItemEscalated style={{ borderBottom: '1px dotted', fontWeight: 400 }}>
                {shift.totalRateSummary === '£' ? 'N/A (AFC)' : shift.totalRateSummary ? shift.totalRateSummary : '-'}
              </ShiftItemEscalated>
              <svg
                style={{ marginLeft: 5, position: 'relative', top: '-2px' }}
                width="14"
                height="14"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M7 0C3.135 0 0 3.135 0 7s3.135 7 7 7 7-3.135 7-7-3.135-7-7-7z" fill="#9CA8B4" />
                  <path
                    d="M7.919 7.7c-.16.064-.336.216-.336.443v.315c0 .321-.262.584-.583.584a.585.585 0 01-.583-.584v-.315c0-.668.42-1.268 1.07-1.528.508-.204.834-.688.825-1.24a1.335 1.335 0 00-1.294-1.292 1.283 1.283 0 00-.94.38c-.25.247-.39.58-.39.933 0 .32-.263.583-.584.583a.585.585 0 01-.583-.583c0-.668.262-1.295.738-1.765A2.463 2.463 0 017 2.917h.035A2.494 2.494 0 019.479 5.36 2.495 2.495 0 017.92 7.7zM7 11.083a.585.585 0 01-.583-.583c0-.32.262-.583.583-.583.32 0 .583.262.583.583 0 .32-.262.583-.583.583z"
                    fill="#FFF"
                  />
                </g>
              </svg>
            </>
          ) : shift.totalRateSummary === '£' ? (
            'N/A (AFC)'
          ) : shift.totalRateSummary ? (
            shift.totalRateSummary
          ) : (
            '-'
          )}
        </ShiftItem>
        <DropdownComponent buttonText={'Actions'}>
          {/* {shift.availableActions.includes("accept_booking") &&
            <ActionsDropdownMenuItem
              onClick={() => shiftClick(shift, "applications")}
            >
              <div className="dropdown-item-circle">
                {shift.appliedBy.length}
              </div>
              Applications
            </ActionsDropdownMenuItem>
          } */}
          <ActionsDropdownMenuItem href={`/shifts?shift_ids[]=${shift.id}`} target="_blank">
            <div className="dropdown-item-icon">
              <img src={ActionViewShift} alt={'View Shift'} />
            </div>
            View Shift
          </ActionsDropdownMenuItem>
          {/* {shift.availableActions.includes("update_shift") ?
            (
              <ActionsDropdownMenuItem onClick={() => shiftClick(shift, "details")}>
                <div className="dropdown-item-icon">
                  <img src={ActionViewShift} alt={'View Shift'} />
                </div>
                View Shift
              </ActionsDropdownMenuItem>
            ) : (
              <ActionsDropdownMenuItem onClick={() => shiftClick(shift, "detailsReadOnly")}>
                <div className="dropdown-item-icon">
                  <img src={ActionViewShift} alt={'View Shift'} />
                </div>
                View Shift
              </ActionsDropdownMenuItem>
            )
          } */}
          {/* {shift.availableActions.includes("assign_agency_locum") &&
            <ActionsDropdownMenuItem onClick={() => shiftClick(shift, "assignWorker")}>
              <div className="dropdown-item-icon">
                <img src={AssignWorker} alt={'Assign Worker'} />
              </div>
            Assign Worker
            </ActionsDropdownMenuItem>
          } */}
          {/* {shift.availableActions.includes("assign_agency_locum") &&
            <ActionsDropdownMenuItem onClick={() => shiftClick(shift, "assignAgency")}>
              <div className="dropdown-item-icon">
                <img src={AssignAgency} alt={'Assign Agency Worker'} />
              </div>
              Assign Agency Worker
            </ActionsDropdownMenuItem>
          } */}
          {/* {(shift.availableActions.includes("watch_shift") || shift.availableActions.includes("unwatch_shift")) &&
            <>
              {watched === true ?
                <ActionsDropdownMenuItem onClick={() => watchAction()}>
                  <div className="dropdown-item-icon">
                    <img src={ActionUnwatch} alt={'Unwatch'} />
                  </div>
                Unwatch
            </ActionsDropdownMenuItem>
                :
                <ActionsDropdownMenuItem onClick={() => watchAction()}>
                  <div className="dropdown-item-icon">
                    <img src={ActionWatch} alt={'Watch'} />
                  </div>
                Watch
            </ActionsDropdownMenuItem>
              }
            </>
          } */}
          {/* {(shift.availableActions.includes("cancel_bank_worker") || shift.availableActions.includes("cancel_agency_worker")) &&
            <ActionsDropdownMenuItem onClick={() => shiftClick(shift, "cancelBooking")}>
              <div className="dropdown-item-icon">
                <img src={ActionDelete} alt={'Cancel Booking'} />
              </div>
              Cancel Booking
            </ActionsDropdownMenuItem>
          } */}
          {/* {shift.availableActions.includes("delete") &&
            <ActionsDropdownMenuItem href={``}>
              <div className="dropdown-item-icon">
                <img src={ActionDelete} alt={'Delete Shift'} />
              </div>
              Delete Shift
            </ActionsDropdownMenuItem>
          } */}
        </DropdownComponent>
      </div>
      <div>
        <ShiftItem>
          <strong>Date:</strong> {shift.startTime && moment(shift.startTime).format('HH:mm')} -{' '}
          {shift.endTime && moment(shift.endTime).format('HH:mm')},{' '}
          {shift.startTime && moment(shift.startTime).format('MMM Do YYYY')}
        </ShiftItem>
        <ShiftItem>
          <strong>Status:</strong>
          {shift.status === 'AVAILABLE' && shift.appliedBy.length > 0 ? (
            <ShiftItemStatus
              style={{
                background: '#ffffff',
                border: '1px solid #FF6C6C',
                boxShadow: '0 1px 3px 0 rgba(255, 108, 108, 0.25)',
                color: '#1e2d36',
                paddingLeft: 5,
                paddingRight: 5,
              }}
            >
              <span>{shift.appliedBy.length}</span> Applications
            </ShiftItemStatus>
          ) : (
            statusAttributes && (
              <ShiftItemStatus
                style={{
                  background: statusAttributes.background,
                  color: statusAttributes.dark,
                }}
              >
                {statusAttributes.format}
              </ShiftItemStatus>
            )
          )}
        </ShiftItem>
        <ShiftItem>
          <strong>Shift Grade:</strong> {shift.grade ? shift.grade.title : '-'}
        </ShiftItem>
        <ShiftItem>
          <strong>Agency Rate (£/hr):</strong> {shift.agencyRateSummary ? shift.agencyRateSummary : '-'}
        </ShiftItem>
        {shift.bookedBy && (
          <ShiftItem>
            <strong>Locum:</strong> {shift.bookedBy.firstName && shift.bookedBy.firstName}{' '}
            {shift.bookedBy.lastName && shift.bookedBy.lastName}
          </ShiftItem>
        )}

        {shift.agencyBooking && (
          <ShiftItem>
            <strong>Agency Worker:</strong>{' '}
            {shift.agencyBooking?.agencyWorker?.firstName && shift.agencyBooking?.agencyWorker?.firstName}{' '}
            {shift.agencyBooking?.agencyWorker?.lastName && shift.agencyBooking?.agencyWorker?.lastName}
          </ShiftItem>
        )}

        {shift.bookedBy && workerDetails(shift.bookedBy)}
      </div>
    </ShiftContainer>
  )
}

const ShiftsCalenderModalHeader = ({ date, handleClose }) => {
  return (
    <ModalHeader>
      <h4>{date}</h4>
      <button onClick={handleClose}>
        <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M12.888 10.717a1.538 1.538 0 010 2.171c-.299.3-.69.445-1.09.445-.39 0-.782-.145-1.081-.445L6.919 9.09 3.12 12.888c-.299.3-.69.445-1.082.445-.399 0-.79-.145-1.09-.445a1.538 1.538 0 010-2.171l3.8-3.798-3.8-3.799a1.538 1.538 0 010-2.171 1.538 1.538 0 012.172 0L6.92 4.747 10.717.95a1.538 1.538 0 012.171 0 1.538 1.538 0 010 2.171L9.09 6.92l3.798 3.798z"
            fill="#9CA8B4"
            fillRule="evenodd"
          />
        </svg>
      </button>
    </ModalHeader>
  )
}

const ModalWrapper = styled(Modal)``

const ErrorWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1060;
`

const LoadingBg = styled.div`
  align-items: center;
  background: rgba(18, 38, 63, 0.5);
  border-radius: 6px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`

const ShiftCalendarModalMenu = styled.div`
  ul {
    border-bottom: 1px solid #cbd2d9;
    display: flex;
    list-style: none;
    padding: 0;

    li {
      color: #879298;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      padding: 0 8px;
      padding-bottom: 16px;
      margin: 0 8px;
      margin-bottom: -1px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 2px solid #002035;
        color: #002035;
      }
    }
  }
`

const ShiftCalendarModalBody = styled.div`
  display: none;

  &.active {
    display: block;
  }
`

const ShiftContainer = styled.div`
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 8px;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  padding: 24px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;

    div {
      width: 50%;

      &:first-child {
        margin-right: 24px;
      }
    }
  }
`

const ShiftItem = styled.div`
  font-size: 16px;

  strong {
    font-weight: 700;
  }
`

const ShiftItemEscalated = styled.span`
  align-items: center;
  display: inline-flex;
  font-weight: 500;

  svg {
    margin-left: 5px;
  }
`

const ShiftItemStatus = styled.span`
  align-items: center;
  background: #ffccb8;
  border-radius: 4px;
  color: #c34412;
  display: inline-flex;
  height: 21px;
  font-size: 10px;
  font-weight: 900;
  letter-spacing: 0.56px;
  margin-left: 5px;
  padding-left: 8px;
  padding-right: 8px;
  position: relative;
  text-transform: uppercase;
  top: -1px;

  span {
    align-items: center;
    background: #ff6c6c;
    border-radius: 50%;
    color: #ffffff;
    font-size: 9px;
    font-weight: 700;
    height: 14px;
    justify-content: center;
    margin-right: 6px;
    min-width: 14px;
    text-align: center;
  }
`

const ModalHeader = styled(Modal.Header)`
  background: #f5f7fa;
  border-bottom: 1px solid #cbd2d9;

  h4 {
    color: #1e2d36;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 0;
  }

  button {
    background: transparent;
    border: 0;
    box-shadow: none;
  }
`

const ModalBody = styled(Modal.Body)`
  max-height: 700px;
  min-height: 700px;
  overflow-y: auto;
`
