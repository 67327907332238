import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import DepartmentsUploadModal from "./DepartmentsUploadModal";

import { Loading } from '@patchworkhealth/web-components'

import { uploadCsv } from "./DepartmentsUploadHelpers";

const DepartmentsUpload = ({ token }) => {
  const fileInputRef = useRef();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState([]);
  const [disable, setDisable] = useState(false)

  const [show, setShow] = useState(false)

  //
  const [loadingAuthoriser, setLoadingAuthoriser] = useState(false)
  //

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    let filteredArray = selectedFiles.reduce((file, current) => {
      const x = file.find((item) => item.name === current.name);
      if (!x) {
        return file.concat([current]);
      } else {
        return file;
      }
    }, []);
    setValidFiles([...filteredArray]);
  }, [selectedFiles]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const handleFiles = (files) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        // add to an array so we can display the name of file
        setSelectedFiles([files[i]]);
        setErrorMessage("");
        setSuccessMessage("");
      } else {
        // add a new property called invalid
        files[i]["invalid"] = true;
        setErrorMessage(
          "File type not permitted, please select a supported file."
        );
        setUnsupportedFiles([files[i]]);
      }
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }
    return true;
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    handleFiles(fileInputRef.current.files);
  };

  const uploadFiles = () => {
    console.log('Loading...')

    setLoadingAuthoriser(true)

    setDisable(true)
    console.log('disabled button')

    for (let i = 0; i < validFiles.length; i++) {
      const formData = new FormData();
      formData.append("authenticity_token", token);
      formData.append("upload[departments]", validFiles[i]);

      const url = `/departments/upload`;

      fetch(url, {
        method: 'POST',
        body: formData,
      }).then((response) => {
        setLoadingAuthoriser(false)
        console.log(response)
        if (response.status === 204) {
          console.log(response.status)
          console.log("good")
          setSuccessMessage("File uploaded successfully. Please check your emails inbox");
        } else {
          console.log("bad")
          setErrorMessage("Unable to upload file, please try again by checking " +
            "the columns exactly as it they are in the template.");
        }
      })
    }
  };

  return (
    <>
      <DepartmentsUploadContainer>
        <h5>Import Departments</h5>
        <DepartmentsUploadUploadBox
          onDragEnter={dragEnter}
          onDragLeave={dragLeave}
          onDragOver={dragOver}
          onDrop={fileDrop}
        >
          <CloudIcon />
          <p>
            Drag and drop your Excel file here <br />
            or browse below
          </p>
          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />
          {errorMessage ? (
            <p style={{ color: "red" }}>{errorMessage}</p>
          ) : successMessage ? (
            <p style={{ color: "green" }}>{successMessage}</p>
          ) : selectedFiles ? (
            selectedFiles?.map((data, i) => <p key={i}>{data.name}</p>)
          ) : (
            ""
          )}

          {loadingAuthoriser && <Loading />}

          {unsupportedFiles.length === 0 && validFiles.length ? (
            <button
              className="btn btn-cyan"
              disabled={disable}
              onClick={() => uploadFiles()}
              style={{ pointerEvents: loadingAuthoriser && 'none' }}
              style={{ marginBottom: 16 }}
            >
              Submit
            </button>
          ) : (
            <button
              style={{ marginBottom: 16 }}
              className="btn btn-cyan"
              onClick={fileInputClicked}
            >
              Browse
            </button>
          )}
          <p style={{ margin: 0, opacity: "0.5" }}>
            We only support Excel file uploads
          </p>
        </DepartmentsUploadUploadBox>
        <DepartmentsUploadTemplateBox>
          <DocumentIcon />
          <p>
            If you are not sure what the excel file should contain use our
            template file{" "}
            <a href="/departments/upload_template" target="_blank">
              download here
            </a>
          </p>
        </DepartmentsUploadTemplateBox>
        <DepartmentsUploadDisclaimer>
          <svg
            width="18"
            height="18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.125 5.999a.737.737 0 01-.735-.735c0-.404.33-.735.735-.735.404 0 .735.331.735.735 0 .404-.331.735-.735.735zm.735 6.612c0 .404-.331.735-.735.735a.737.737 0 01-.735-.735V7.836c0-.405.33-.735.735-.735.404 0 .735.33.735.735v4.775zM9.125.121A8.819 8.819 0 00.309 8.938a8.819 8.819 0 008.816 8.816 8.819 8.819 0 008.816-8.816A8.819 8.819 0 009.125.12z"
              fill="#9BA8B4"
            />
          </svg>
          <p>
            Not sure what to include in the file?{" "}
            <button onClick={handleShow}>Click here for help</button>
          </p>
        </DepartmentsUploadDisclaimer>
      </DepartmentsUploadContainer>
      <DepartmentsUploadModal handleClose={handleClose} show={show} />
    </>
  );
};

export default DepartmentsUpload;

const DepartmentsUploadContainer = styled.div`
  padding: 56px 0 24px;
  text-align: center;
  h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
  p {
    a {
      font-weight: 500;
      text-decoration: underline;
    }
  }
`;

const DepartmentsUploadUploadBox = styled.div`
  background: #ffffff;
  border: 1px dashed #a1b1c9;
  border-radius: 8px;
  margin: 0 auto;
  margin-bottom: 55px;
  max-width: 400px;
  padding: 32px;
  svg {
    margin-bottom: 16px;
  }
  button {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  .file-input {
    display: none;
  }
`;

const DepartmentsUploadTemplateBox = styled.div`
  align-items: center;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 50px;
  max-width: 640px;
  padding: 16px;
  svg {
    margin-right: 16px;
  }
  p {
    margin: 0;
  }
`;

const DepartmentsUploadDisclaimer = styled.div`
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin: 0 auto;
  svg {
    margin-right: 8px;
  }
  p {
    margin: 0;
    button {
      background: transparent;
      border: 0;
      box-shadow: none;
      font-weight: 500;
      margin: 0;
      padding: 0;
      text-decoration: underline;
    }
  }
`;

const CloudIcon = () => (
  <svg width="89" height="54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      opacity=".5"
      d="M75.605 24.547c.445-1.666.67-3.378.669-5.092-.003-5.27-2.086-10.18-5.865-13.83C66.653 1.998 61.633 0 56.26 0 47.49.005 39.884 5.343 37.254 13.309a11.654 11.654 0 00-8.116-3.245 11.656 11.656 0 00-8.255 3.392 11.434 11.434 0 00-3.415 8.181c0 1.072.15 2.134.444 3.162a15.169 15.169 0 00-2.987-.299A14.91 14.91 0 004.368 28.84C1.548 31.634-.002 35.302 0 39.253c.005 8.15 6.704 14.734 14.933 14.734h.009L74.075 54c3.99-.002 7.738-1.52 10.557-4.315 2.82-2.795 4.37-6.498 4.368-10.448-.004-7.64-5.75-13.918-13.395-14.69zM65.54 37.342a2.242 2.242 0 01-1.462.538c-.63 0-1.302-.26-1.745-.77l-.891-.975v8.643A2.231 2.231 0 0159.199 47a2.231 2.231 0 01-2.242-2.22v-8.51l-.69.84c-.443.51-1.052.771-1.681.771a2.215 2.215 0 01-1.451-.538 2.205 2.205 0 01-.228-3.133l4.735-5.434a2.258 2.258 0 013.164-.232c.064.055.216.21.235.232l4.731 5.434a2.207 2.207 0 01-.232 3.133z"
      fill="#A1B1C9"
    />
  </svg>
);

const DocumentIcon = () => (
  <svg width="30" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.492 14.167H20A4.168 4.168 0 0115.833 10V4.508c0-.741.9-1.108 1.425-.583l8.817 8.817c.525.525.158 1.425-.583 1.425zm3.291-3.717L19.55 1.217A4.162 4.162 0 0016.608 0H4.167A4.168 4.168 0 000 4.167v31.666C0 38.133 1.867 40 4.167 40h21.666c2.3 0 4.167-1.867 4.167-4.167V13.392a4.162 4.162 0 00-1.217-2.942z"
      fill="#D0D8E4"
    />
  </svg>
);
