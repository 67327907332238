/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { TableContainer } from '@patchworkhealth/web-components'
import cx from 'classnames'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FilterIcon } from '../BlockBookings/helpers/BlockIcons'
import { TableHeaderContainer } from '../Tables/TableElements'

function Loading() {
  return (
    <LoadingContainer>
      <svg height="38" stroke="#ff7f4d" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g strokeWidth="2" transform="translate(1 1)">
            <circle cx="18" cy="18" r="18" strokeOpacity=".5" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                dur="1s"
                from="0 18 18"
                repeatCount="indefinite"
                to="360 18 18"
                type="rotate"
              />
            </path>
          </g>
        </g>
      </svg>
    </LoadingContainer>
  )
}

function RowElement({ name, receipt, selectedRows, toggleRow, withCheckbox, setCurrentModalId, receiptItem }) {
  const statusText = receipt.status.replaceAll('_', ' ').toUpperCase()

  return (
    <TableRow key={receipt.id}>
      <TdCheckbox style={{ width: 50 }}>
        {withCheckbox && (
          <CheckboxButton
            className={cx({ active: selectedRows?.some((e) => e.receiptId === receipt.id) })}
            onClick={toggleRow}
          />
        )}
      </TdCheckbox>
      <TdFirst style={{ width: 110 }}>
        <PaymentTitle onClick={() => setCurrentModalId(receipt.id)}>{receipt.id}</PaymentTitle>
      </TdFirst>
      <td style={{ width: 170 }}>
        <div style={{ fontWeight: 'bold' }}>{moment(receipt.dateCompleted).format('Do MMMM YYYY')}</div>
      </td>
      <td style={{ width: 170 }}>
        <div style={{ fontWeight: 'bold' }}>{moment(receipt.createdAt).format('Do MMMM YYYY')}</div>
      </td>
      <td style={{ width: 137 }}>
        <div>{name}</div>
      </td>
      <td style={{ width: 100 }}>
        <div>{receiptItem.count}</div>
      </td>
      <TdDepartment style={{ width: 196 }}>
        <div style={{ fontWeight: 'bold' }}>{receipt.department.name}</div>
        <div style={{ fontSize: '12px' }}>{receipt.site.name}</div>
      </TdDepartment>
      <td style={{ width: 130 }}>{receipt.worker.fullName}</td>
      <td style={{ width: 150 }}>
        <div>{receipt.grade.title}</div>
      </td>
      <td style={{ width: 188 }}>
        <ItemStatus status={receipt.status}>{statusText}</ItemStatus>
      </td>
      <td style={{ width: 150, textAlign: 'center' }}>
        <div>£{receiptItem.totalCost.toFixed(2)}</div>
      </td>
      <td style={{ width: 150, textAlign: 'center' }}>
        <div>£{receipt.totalCost.toFixed(2)}</div>
      </td>
    </TableRow>
  )
}

function PayPerItemReceipts({
  receipts,
  inputs,
  loading,
  resetFilters,
  toggleFilters,
  setCurrentModalId,
  parsedReceiptIds,
  selectedRows,
  setSelectedRows,
}) {
  const { showFilters, showClearFilters } = inputs
  const [filterCount, setFilterCount] = useState(0)

  const toggleAllRows = () => {
    if (selectedRows.length === receipts.length) {
      setSelectedRows([])
    } else {
      setSelectedRows(
        receipts.map((receipt) => {
          return { receiptId: receipt.id, status: receipt.status }
        })
      )
    }
  }

  useEffect(() => {
    const { departmentIds, gradeIds, ids, itemIds, status, workerIds } = inputs
    const filters = { departmentIds, gradeIds, ids, itemIds, status, workerIds }
    const appliedFilterCount = Object.entries(filters).filter((filter) => {
      return filter[1].length > 0
    }).length
    setFilterCount(appliedFilterCount)
  }, [inputs])

  const receiptsToRender =
    receipts?.map((receipt) => {
      const toggleRow = () => {
        if (selectedRows.some((row) => row.receiptId === receipt.id)) {
          setSelectedRows(selectedRows.filter((row) => row.receiptId !== receipt.id))
        } else {
          setSelectedRows([...selectedRows, { receiptId: receipt.id, status: receipt.status }])
        }
      }

      const receiptItems =
        receipt.receiptItems.length > 1 ? (
          receipt.receiptItems.map((receiptItem, i) => (
            <RowElement
              key={receiptItem.id}
              name={receiptItem.itemGradeCost.item.name}
              receipt={receipt}
              receiptItem={receiptItem}
              selectedRows={selectedRows}
              setCurrentModalId={setCurrentModalId}
              toggleRow={toggleRow}
              withCheckbox={i === 0}
            />
          ))
        ) : (
          <RowElement
            key={receipt.id}
            name={receipt.receiptItems[0]?.itemGradeCost.item.name}
            receipt={receipt}
            receiptItem={receipt.receiptItems[0]}
            selectedRows={selectedRows}
            setCurrentModalId={setCurrentModalId}
            toggleRow={toggleRow}
            withCheckbox
          />
        )

      return receiptItems
    }) || []

  const clearReceiptId = (receiptId) => {
    const newReceiptIds = parsedReceiptIds.filter((id) => id !== receiptId)
    if (newReceiptIds.length === 0) {
      window.location.search = ''
    } else {
      window.location.search = `?ids=[${newReceiptIds}]`
    }
  }

  return (
    <BlockWrapper style={{ color: 'black' }}>
      <TableHeaderContainer
        style={{
          marginTop: parsedReceiptIds.length ? '-180px' : '-130px',
          marginBottom: parsedReceiptIds.length ? 0 : 18,
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', alignItems: 'normal' }}>
          <FilterPretitle>
            {filterCount} {filterCount === 1 ? `filter` : `filters`} applied
          </FilterPretitle>
          <div style={{ display: 'flex', alignItems: 'centre' }}>
            <button
              className="btn btn-white"
              onClick={toggleFilters}
              style={{ marginRight: 20, fontWeight: 500 }}
              type="button"
            >
              <FilterIcon />
              {showFilters ? 'Close' : 'Add'} Filters
            </button>

            {showClearFilters && (
              <button className="btn btn-white" onClick={resetFilters} type="button">
                Clear Filters
              </button>
            )}
          </div>
        </div>
      </TableHeaderContainer>
      {parsedReceiptIds.length > 0 && (
        <ReceiptIdsContainer>
          {parsedReceiptIds.map((receiptId) => (
            <button className="btn btn-white filter__dark-btn" onClick={() => clearReceiptId(receiptId)} type="button">
              Receipt ID: {receiptId}
              <svg fill="none" height="11" width="12" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.804 8.046c.174.174.261.38.261.618a.86.86 0 01-.261.632l-1.236 1.236a.86.86 0 01-.632.261.845.845 0 01-.618-.26L5.832 8.045l-2.486 2.486a.845.845 0 01-.619.261.861.861 0 01-.631-.26L.859 9.295a.86.86 0 01-.26-.632c0-.238.086-.444.26-.618L3.346 5.56.859 3.074a.844.844 0 01-.26-.618.86.86 0 01.26-.632L2.096.588a.86.86 0 01.631-.261c.239 0 .445.087.619.26l2.486 2.487L8.318.588a.844.844 0 01.618-.261.86.86 0 01.632.26l1.236 1.237a.86.86 0 01.261.632.845.845 0 01-.261.618L8.318 5.56l2.486 2.486z"
                  fill="#fff"
                  opacity=".304"
                />
              </svg>
            </button>
          ))}
        </ReceiptIdsContainer>
      )}
      <TableContainer style={{ minWidth: 1100 }}>
        <TableHead>
          <tr>
            <ThFirst>
              <CheckboxButton
                className={cx('select-all-toggle', {
                  active: receipts?.length > 0 && selectedRows.length === receipts?.length,
                })}
                onClick={toggleAllRows}
                style={{ marginLeft: '8px' }}
              />
            </ThFirst>
            <th className="th-align-left">ID</th>
            <th>Date Completed</th>
            <th>Date Submitted</th>
            <th>Item</th>
            <th>Count</th>
            <th className="th-align-left">Department</th>
            <th>Worker</th>
            <th>Grade</th>
            <th>Status</th>
            <th>Item Total</th>
            <th>Total Pay</th>
          </tr>
        </TableHead>
        <tbody>{receiptsToRender}</tbody>
      </TableContainer>
      {loading && <Loading />}
    </BlockWrapper>
  )
}

export default PayPerItemReceipts

const CheckboxButton = styled.button`
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #e4e7eb;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;

  &.active {
    background-color: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30859' y='0.627655' width='15' height='15' rx='3.5' fill='%2390D78F' stroke='%2377BF77'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.0233 6.355L8.38762 10.9906C8.23696 11.1413 8.03994 11.2186 7.84293 11.2186C7.64591 11.2186 7.4489 11.1413 7.29824 10.9906L4.59412 8.28652C4.28894 7.9852 4.28894 7.49846 4.59412 7.19714C4.89544 6.89196 5.38218 6.89196 5.68349 7.19714L7.84293 9.35272L11.9339 5.26563C12.2352 4.96045 12.7219 4.96045 13.0233 5.26563C13.3284 5.56694 13.3284 6.05369 13.0233 6.355Z' fill='white'/%3E%3C/svg%3E%0A");
    border: 0;
    box-shadow: none;
    width: 20px;
    height: 20px;
  }
`

const ReceiptIdsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 18px;

  button {
    margin-right: 10px;
    margin-bottom: 10px;
    font-weight: bold;
  }
`

const BlockWrapper = styled.div`
  margin-bottom: 40px;
  padding: 20px 25px;
`

const PaymentTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  color: #002033;
  margin: 0;
  cursor: pointer;
`

const TableRow = styled.tr`
  max-height: 64px;

  &:nth-of-type(even) {
    background-color: #f5f7fa;
  }

  * {
    font-size: 14px !important;
  }

  th {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 10px !important;
    color: #002033 !important;
  }

  td {
    padding: 5px 10px;
    text-align: center;
  }
`

export const TableIconRow = styled.td`
  position: relative;
  overflow: visible !important;
  svg:hover + div {
    opacity: 1 !important;
  }
`

const TableHead = styled.thead`
  th {
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 10px !important;
    color: #002033 !important;
    text-align: center !important;

    &.th-align-left {
      text-align: left !important;
    }
  }
`

const ThFirst = styled.th`
  text-align: left !important;
`

const TdCheckbox = styled.td`
  padding-left: 18px !important;
`

const TdFirst = styled.td`
  text-align: left !important;
`

const TdDepartment = styled.td`
  text-align: left !important;
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 220px;
`
const ItemStatus = styled.div`
  width: fit-content;
  border-radius: 5px;
  padding: 5px;
  background-color: ${({ status }) =>
    status === 'sign_off_requested'
      ? '#D1F2F7'
      : status === 'signed_off'
      ? '#CCD2D8'
      : status === 'approved_for_payment' || status === 'pending_approval'
      ? '#ffbd37'
      : status === 'paid'
      ? '#90D78F'
      : '#D1F2F7'};
  color: ${({ status }) =>
    status === 'sign_off_requested'
      ? '#007AC3'
      : status === 'signed_off'
      ? '#353F4A'
      : status === 'approved_for_payment' || status === 'pending_approval'
      ? '#353F4A'
      : status === 'paid'
      ? '#353F4A'
      : '#007AC3'};
  white-space: nowrap;
  font-weight: bold;
  font-size: 10px !important;
  margin: 0 auto;
`

const FilterPretitle = styled.h6`
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #fff;
  opacity: 0.5;
  text-transform: uppercase;
  margin-bottom: 10px;
  align-self: flex-start;
`
