import { Controller } from 'stimulus'

export default class ShiftTableController extends Controller {

    connect () {
    }
    
    selectAll() {
        document.getElementById('action-bar')['action_bar'].selectAll(false);
    }
}

import { application } from '../../frontend/controllers/index'
application.register("shift_table", ShiftTableController)