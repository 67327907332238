import queryString from 'query-string'
import { Controller } from 'stimulus'
import { application } from '../frontend/controllers/index'

const filters = []

export default class SearchController extends Controller {
  connect() {}

  // as the user selects filters we store them
  storeFilters(params_key, option) {
    // check if the filter you want to store is already stored
    function filterExists(params_key) {
      return filters.some(function (el) {
        return el.params_key === params_key
      })
    }

    // try to add the new filter
    function addFilter(params_key, option) {
      // if the filter exists, remove the old one
      if (filterExists(params_key)) {
        filters.pop(0)
      }
      // and then push the new one
      filters.push({ params_key, option })
      return true
    }

    // call the add filter function
    addFilter(params_key, option)
  }

  // on apply filters we trigger this function to create the search url
  applyFilters() {
    // set an empty url for the filters
    let url = ''

    // parse search params if exist
    const params = queryString.parse(location.search)

    // check filters array length
    const filtersLength = filters.length
    // if filters has objects map out the parameters
    if (filtersLength > 0) {
      // for each object in the filter array
      filters.map((filter) => {
        // set the parameter up to have a key
        const param = `${filter.params_key}=`
        // set the options to add to the url

        const options = filter.option.map((option) => {
          return `${param + option.value}&`
        })

        // Remove key from url params that have been overwritten by filters
        delete params[filter.params_key]

        // create the url with options
        return (url += options)
      })
    }

    // If there are no remaining params from the url or set from the filters, apply the clear parameter
    // needed to remove query params from filter storage
    if (Object.keys(params).length === 0 && url === '') {
      params.clear = true
    }

    // create a new strippedurl without uneeded `&`
    let strippedUrl = url.slice(0, -1)
    // stringify the search params
    const paramsUrl = queryString.stringify(params)
    // create a new stripped url for params with unicode fixes
    const strippedParamsUrl = paramsUrl
      .replace(/%5B/g, '[')
      .replace(/%5D/g, ']')
      .replace(/%2C/g, ',')
      .replace(/%3D/g, '=')

    // All other multi selects besides 'shiftType need stripping of &,

    const items = ['department_ids', 'grade_ids', 'requester_ids', 'site_ids', 'staff_group_ids', 'statuses']

    for (let i = 0; i < items.length; i += 1) {
      strippedUrl = strippedUrl.replace(new RegExp(`&,${items[i]}`, 'g'), `&${items[i]}`)
    }

    // trigger the filter search
    if (paramsUrl.length > 0) {
      window.location.search = `${strippedUrl}&${strippedParamsUrl}`
    } else {
      window.location.search = strippedUrl
    }
  }

  multiSearchOnSelect(params_key, option) {
    const params = queryString.parse(location.search)
    this.clearSpecialParams(params)
    params[params_key] = option.map((item) => item.value)
    const url = queryString.stringify(params)
    window.location.search = url
  }

  singleSearchOnSelect(params_key, option) {
    const params = queryString.parse(location.search)
    if (option.value === params[params_key]) {
      return
    }

    this.clearSpecialParams(params)
    if (option.value) {
      params[params_key] = option.value
    } else {
      delete params[params_key]
    }
    const url = queryString.stringify(params)
    window.location.search = url
  }

  clearSpecialParams(params) {
    delete params.page
    delete params.only_search_id
    delete params['shift_ids[]']
  }

  set filters(data) {
    this.data.set('filters', [])
  }
}

application.register('search_controller', SearchController)
