/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client'
import {
  GREY,
  Loading,
  ModalBody,
  CloseButton,
  ModalHeader,
  FormGrid,
  FormLabel,
  FormGroup,
  FormBreak,
  DefaultButton,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import 'react-day-picker/lib/style.css'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { formatTimeInTZ } from '../../../Helpers/momentHelpers'
import {
  successToast,
  errorToast,
  loadingToast,
  checkProposals,
  checkStatus,
  LeaveStatus,
  CancelWorker,
  REASONS_FOR_CANCELLATION,
  SHIFT_BLOCK_APPROVE_APPLICATION,
  SHIFT_BLOCK_DETAILS,
  SHIFT_BLOCK_REJECT_APPLICATION,
} from '../helpers/BlockBookingsHelpers'
import ViewTimesheets from '../Timesheets/ViewBlockTimesheeets'
import ViewBlockAgency from './ViewBlockAgency'
import ViewBlockApplications from './ViewBlockApplications'
import ViewBlockAssign from './ViewBlockAssign'
import ViewBlockAudit from './ViewBlockAudit'
import ViewBlockBooking from './ViewBlockBooking'
import ViewBlockDetails from './ViewBlockDetails'
import ViewBlockProposals from './ViewBlockProposals'
// import ViewTimesheets from './ViewBlockTimesheetsOld'

function ViewBlockModal({
  details,
  editBlock,
  handleClose,
  inputs,
  refetch,
  timesheets,
  canCancelBooking,
  canManageProposals,
  canShortlistProposals,
  canOnlyViewProposals,
  usesDeContracts,
}) {
  const { modOrganisation, organisation, showBlockModal: block } = inputs

  const [activeTab, setActiveTab] = useState('1')

  // Gql **************************************

  const [shiftBlockApproveApplication] = useMutation(SHIFT_BLOCK_APPROVE_APPLICATION)
  const [shiftBlockRejectApplication] = useMutation(SHIFT_BLOCK_REJECT_APPLICATION)

  const {
    data,
    loading: loadingShift,
    refetch: refetchDetails,
  } = useQuery(SHIFT_BLOCK_DETAILS, {
    fetchPolicy: 'no-cache',
    skip: !block.id,
    variables: {
      shiftBlockIds: [Number(block.id)],
      statuses: [inputs?.showBlockModal?.status],
    },
  })

  const { data: reasons } = useQuery(REASONS_FOR_CANCELLATION, {
    skip: !block.id,
    variables: {
      organisationId: organisation?.id,
    },
  })

  // UseEffects *********************************

  // useEffect(() => {
  //   if (block) {
  //     setActiveTab('1')

  //     if (block.status === 'BOOKED') {
  //       setSkip(false)
  //     }
  //   }
  // }, [block])

  // Functions **********************************

  const bookBlock = (worker) => {
    toast.loading('Loading', loadingToast)

    shiftBlockApproveApplication({
      variables: {
        shiftBlockApplicationId: Number(worker.id),
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBlockApproveApplication?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: 'Booking could not be confirmed' })

          return
        }
        handleClose()
        refetch()
        toast.update(2, { ...successToast, render: 'Booking confirmed' })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Booking could not be confirmed' })
      })
  }

  const rejectBlock = (worker) => {
    toast.loading('Loading', loadingToast)
    shiftBlockRejectApplication({
      variables: {
        shiftBlockApplicationId: Number(worker.id),
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBlockRejectApplication?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: 'Application could not be rejected' })

          return
        }
        handleClose()
        refetch()
        toast.update(2, { ...successToast, render: 'Application rejected' })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Application could not be rejected' })
      })
  }

  const blockData = data?.shiftBlocks?.nodes[0]
  const isAgencyBooking = block?.bookedBy?.__typename === 'AgencyRegistration'

  const workerDetails = (row) => {
    const firstName = row?.bookedBy?.user !== undefined ? row?.bookedBy?.user?.firstName : row?.bookedBy?.firstName
    const lastName = row?.bookedBy?.user !== undefined ? row?.bookedBy?.user?.lastName : row?.bookedBy?.lastName

    const clickWorker = () => {
      if (isAgencyBooking) {
        return
      }
      window.open(`/workers?worker_id=${row.bookedBy.user.id}`, '_blank').focus()
    }

    return (
      <td onClick={clickWorker}>
        <strong style={{ textDecoration: !isAgencyBooking && 'underline', cursor: !isAgencyBooking && 'pointer' }}>
          {firstName} {lastName}
        </strong>
      </td>
    )
  }

  const truncate = (name) => {
    const maxLength = 60
    if (name.length > maxLength) {
      return `${name.slice(0, maxLength - 3)}...`
    }
    return name
  }

  return (
    <Modal backdrop="static" onHide={handleClose} show={!!block} size="xl">
      {loadingShift ? (
        <Loading modal />
      ) : (
        <>
          <ModalHeader style={{ height: 80, fontSize: 12 }}>
            <ShiftModalHeaderDetailsContainer>
              <ShiftModalHeaderTitle>{`${block.name} (${block.uid})`}</ShiftModalHeaderTitle>
              <ShiftModalHeaderDivider />
              <div>
                <strong>
                  {formatTimeInTZ(block?.startDate, 'Do MMM YYYY')} - {formatTimeInTZ(block?.endDate, 'Do MMM YYYY')}
                </strong>
              </div>
              <ShiftModalHeaderDivider />
              <div>
                <strong style={{ color: block?.grade?.colour }}>{truncate(block?.grade?.label ?? '')}</strong>
              </div>
              <ShiftModalHeaderDivider />
              {block?.status && <LeaveStatus status={block?.status}>{block?.status}</LeaveStatus>}
              <ShiftModalHeaderDivider />

              {block?.status === 'BOOKED' && workerDetails(inputs.showBlockModal)}
            </ShiftModalHeaderDetailsContainer>
            <ShiftModalHeaderDetailsContainer>
              <CloseButton onClick={handleClose} />
            </ShiftModalHeaderDetailsContainer>
          </ModalHeader>

          <ShiftModalContainer>
            <ShiftModalSidebarContainer>
              <ShiftModalSidebarNav>
                <ShiftModalSidebarNavOption active={activeTab === '1'} onClick={() => setActiveTab('1')}>
                  Block Details
                </ShiftModalSidebarNavOption>

                {block?.status !== 'BOOKED' && checkProposals(block) > 0 && (
                  <ShiftModalSidebarNavOption
                    active={activeTab === '6'}
                    onClick={() => setActiveTab('6')}
                    style={{ position: 'relative' }}
                  >
                    Agency Proposals
                    <ApplicationsIcon>{checkProposals(block)}</ApplicationsIcon>
                  </ShiftModalSidebarNavOption>
                )}

                {block?.status === 'AVAILABLE' && block?.sentToAgency && (
                  <ShiftModalSidebarNavOption active={activeTab === '7'} onClick={() => setActiveTab('7')}>
                    Update Agency Tier
                  </ShiftModalSidebarNavOption>
                )}

                {block?.status === 'AVAILABLE' && !block?.sentToAgency && block?.organisationStaffGroup?.vms && (
                  <ShiftModalSidebarNavOption active={activeTab === '7'} onClick={() => setActiveTab('7')}>
                    Send to Agency
                  </ShiftModalSidebarNavOption>
                )}

                {block?.status === 'AVAILABLE' && (
                  <ShiftModalSidebarNavOption active={activeTab === '4'} onClick={() => setActiveTab('4')}>
                    Assign a Worker
                  </ShiftModalSidebarNavOption>
                )}

                {block?.status === 'BOOKED' && isAgencyBooking && (
                  <ShiftModalSidebarNavOption active={activeTab === '8'} onClick={() => setActiveTab('8')}>
                    Experience
                  </ShiftModalSidebarNavOption>
                )}

                {block?.status === 'BOOKED' && (
                  <ShiftModalSidebarNavOption active={activeTab === '3'} onClick={() => setActiveTab('3')}>
                    Cancel Booking
                  </ShiftModalSidebarNavOption>
                )}

                {Number.isInteger(checkStatus(block)) && (
                  <ShiftModalSidebarNavOption
                    active={activeTab === '2' || activeTab === '2.1' || activeTab === '2.2'}
                    onClick={() => setActiveTab('2')}
                    style={{ position: 'relative' }}
                  >
                    Applications
                    {block && Number.isInteger(checkStatus(block)) && (
                      <ApplicationsIcon>{checkStatus(block)}</ApplicationsIcon>
                    )}
                  </ShiftModalSidebarNavOption>
                )}

                <ShiftModalSidebarNavOption active={activeTab === '5'} onClick={() => setActiveTab('5')}>
                  Audit Trail
                </ShiftModalSidebarNavOption>

                {timesheets.timesheet && block?.status === 'BOOKED' && (
                  <ShiftModalSidebarNavOption
                    active={activeTab === '9'}
                    onClick={() => setActiveTab('9')}
                    style={{ position: 'relative' }}
                  >
                    Sign Off{' '}
                    {blockData?.activeTimesheets > 0 && (
                      <ApplicationsIcon>{blockData?.activeTimesheets}</ApplicationsIcon>
                    )}
                  </ShiftModalSidebarNavOption>
                )}

                {block.status !== 'DELETED' && (
                  <DefaultButton
                    action={() => {
                      editBlock(block)
                    }}
                    style={{
                      width: 152,
                      margin: '12px 17px',
                      height: 34,
                    }}
                    text="Edit Block"
                    type="green"
                  />
                )}

                <a
                  href={`/shifts?filter_by_shift_blocks=true&shift_block_uids=${block.id}`}
                  rel="noreferrer"
                  style={{
                    background: 'white',
                    width: 152,
                    margin: '6px 17px',
                    height: 34,
                    borderRadius: 8,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 14,
                    fontWeight: 'bold',
                    boxShadow: '0px 1px 2px rgb(0 0 0 / 10%)',
                  }}
                  target="_blank"
                >
                  Show Shifts
                </a>
              </ShiftModalSidebarNav>
            </ShiftModalSidebarContainer>

            <ShiftModalContentContainer>
              <ModalBody style={{ minHeight: 520, paddingBottom: 100 }}>
                {activeTab === '1' && (
                  <ViewBlockDetails
                    block={block}
                    blockData={blockData}
                    details={details}
                    editBlock={editBlock}
                    handleClose={handleClose}
                    modOrganisation={modOrganisation}
                    refetch={refetch}
                  />
                )}
                {activeTab === '2' && (
                  <ViewBlockApplications block={block} bookBlock={bookBlock} rejectBlock={rejectBlock} />
                )}

                {activeTab === '3' && (
                  <ViewBlockBooking
                    block={block}
                    blockData={blockData}
                    canCancelBooking={canCancelBooking}
                    handleClose={handleClose}
                    reasons={reasons}
                    refetch={refetch}
                  />
                )}
                {activeTab === '4' && <ViewBlockAssign block={block} handleClose={handleClose} refetch={refetch} />}

                {activeTab === '5' && <ViewBlockAudit block={block} />}
                {activeTab === '6' && (
                  <ViewBlockProposals
                    block={blockData}
                    canManageProposals={canManageProposals}
                    canShortlistProposals={canShortlistProposals}
                    canOnlyViewProposals={canOnlyViewProposals}
                    handleClose={handleClose}
                    refetch={refetch}
                    refetchDetails={refetchDetails}
                    usesDeContracts={usesDeContracts}
                  />
                )}

                {activeTab === '7' && <ViewBlockAgency block={block} handleClose={handleClose} refetch={refetch} />}

                {timesheets.timesheet && activeTab === '9' && (
                  <ViewTimesheets
                    block={block}
                    blockData={blockData}
                    handleClose={handleClose}
                    modOrganisation={modOrganisation}
                    refetch={refetchDetails}
                    ts={timesheets}
                  />
                )}

                {activeTab === '8' && (
                  <CancelWorker>
                    <FormGroup>
                      {isAgencyBooking && (
                        <FormLabel>
                          {block?.bookedBy?.user?.firstName} {block?.bookedBy?.user?.lastName}
                        </FormLabel>
                      )}
                    </FormGroup>

                    <FormBreak />

                    <FormGrid columns={1}>
                      <FormGroup>
                        <FormLabel>Email:</FormLabel>
                        <p>{block.bookedBy?.user?.email}</p>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel>Phone No:</FormLabel>
                        <p>{block.bookedBy?.user?.phoneNumber}</p>
                      </FormGroup>

                      <FormGroup>
                        <FormLabel>Agency:</FormLabel>
                        <p>{block.bookedBy?.agency?.title}</p>
                      </FormGroup>

                      {isAgencyBooking && (
                        <FormGroup>
                          <FormLabel>Reg Body Number:</FormLabel>
                          <p>{block.bookedBy?.user?.regBodyNumber}</p>
                        </FormGroup>
                      )}
                    </FormGrid>

                    <FormBreak />

                    {isAgencyBooking && (
                      <FormGrid columns={2}>
                        <FormGroup>
                          {blockData.bookedBy.compliancePackUrl === null && <FormLabel>No Compliance Pack</FormLabel>}

                          {blockData.bookedBy.compliancePackUrl !== null && (
                            <>
                              <FormLabel>Compliance Pack</FormLabel>
                              <a
                                href={`${window.location.origin}${blockData.bookedBy?.compliancePackUrl}`}
                                rel="noopener noreferrer"
                                style={{ display: 'block' }}
                                target="_blank"
                              >
                                Download
                              </a>
                            </>
                          )}
                        </FormGroup>

                        <FormGroup>
                          {blockData.bookedBy.cvUrl === null && <FormLabel>No CV Uploaded</FormLabel>}

                          {blockData.bookedBy.cvUrl !== null && (
                            <>
                              <FormLabel>CV</FormLabel>
                              <a
                                href={`${window.location.origin}${blockData.bookedBy?.cvUrl}`}
                                rel="noopener noreferrer"
                                style={{ display: 'block' }}
                                target="_blank"
                              >
                                Download
                              </a>
                            </>
                          )}
                        </FormGroup>
                      </FormGrid>
                    )}
                  </CancelWorker>
                )}
              </ModalBody>
            </ShiftModalContentContainer>
          </ShiftModalContainer>
        </>
      )}
    </Modal>
  )
}

export default ViewBlockModal

const ShiftModalSidebarContainer = styled.div`
  background: rgb(228, 231, 235);
  border-bottom-left-radius: 16px;
  flex-shrink: 0;
  padding-top: 6px;
  width: 185px;
`

const ShiftModalSidebarNav = styled.div`
  margin: 0;
  padding: 0;
`

const ShiftModalSidebarNavOption = styled.button`
  align-items: center;
  background-color: ${({ active }) => (active ? '#FFE4D9' : 'rgb(228, 231, 235)')};
  border: 0;
  border-left: ${({ active }) => active && '2px solid red'};
  display: flex;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  height: 40px;
  margin-bottom: 8px;
  padding: 0 8px 0 16px;
  width: 100%;
`

// New Stuff ********************

const ShiftModalContainer = styled.div`
  display: flex;
  min-height: 600px;
`

const ShiftModalContentContainer = styled.div`
  width: 100%;
`

const ShiftModalHeaderDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  div {
    display: flex;
  }
`

const ShiftModalHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const ShiftModalHeaderDivider = styled.div`
  background-color: ${GREY.three};
  height: 50px;
  margin-left: 20px;
  margin-right: 20px;
  width: 1px;
`

// Shift Application Info ***************

export const ApplicationsIcon = styled.div.attrs((props) => ({
  absolute: props.relative,
}))`
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  display: flex;
  font-weight: bold;
  height: 24px;
  justify-content: center;
  position: ${({ relative }) => (relative ? 'relative' : 'absolute')};
  right: 10px;
  top: 8px;
  width: 24px;
`
