// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.
import '@stimulus/polyfills'
import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

export const application = Application.start()
const context = require.context('controllers', true, /_controller\.js$/)
application.load(definitionsFromContext(context))

/**
 * Mountain View Components keep their Controllers in the same folder
 */
const mountain_view_context = require.context('../../components', true, /_controller\.js$/)
application.load(definitionsFromContext(mountain_view_context))
