import { Controller } from "stimulus";

export default class AgencyWorkerModalController extends Controller {
  static get targets() {
    return [
    "staffGroupSelect",
    "gradeSelect"
    ];
  }

  connect() {

    const staffGroupSelectTarget = this.staffGroupSelectTarget;
    const gradeSelectTarget = this.gradeSelectTarget;
    const staffSelectorVal = document.querySelector(
      ".modal__validation--staff"
    );
    const gradeSelectorVal = document.querySelector(
      ".modal__validation--grade"
    );

    new SlimSelect({
      select: staffGroupSelectTarget,
      onChange: (info) => {
        document.getElementById("staff-group-id").value = info.value;
        this.fetchGrades();
        staffSelectorVal.style.display = "none";
      },
    });

    new SlimSelect({
      select: gradeSelectTarget,
      placeholder: "Select Grade...",
      onChange: (info) => {
        gradeSelectorVal.style.display = "none";
      },
    });
    
    let initial = true;
    this.fetchGrades(initial);
  }

  fetchGrades(initial) {

    const staff_group_id = parseInt(this.staffGroupSelectTarget.value);
    const query = `query Grades($staff_group_id: Int!){
            grades(staffGroupId: $staff_group_id){
              id
              title
              rateNotApplicable
            }
          }`;
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { staff_group_id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const grades = !!data.errors ? [] : data.data.grades;
       
          if(initial){
             this.populateInitialGrades(grades);
          } else {
             this.populateGrades(grades);
          }
      });
  }

    populateGrades(grades) {
    const gradesSelect = this.gradeSelectTarget;

    gradesSelect.options.length = null;
    const defaultOption = `<option value="" selected>Select grade</option>`;
    gradesSelect.insertAdjacentHTML("beforeend", defaultOption);

    grades.forEach((grade) => {
      const option = `<option data-rate-not-applicable=${grade.rateNotApplicable} value=${grade.id}>${grade.title}</option>`;
      gradesSelect.insertAdjacentHTML("beforeend", option);
    });
  }

    populateInitialGrades(grades) {
   
    const gradesSelect = this.gradeSelectTarget;
    let existingGrade = grades.filter((cc) => cc.id == gradesSelect.value);
    gradesSelect.options.length = null;
        
    const defaultOption =  existingGrade.length > 0 ? 
    `<option data-rate-not-applicable=${existingGrade[0].rateNotApplicable}  value="${existingGrade[0].id}" selected>${existingGrade[0].title}</option>` : 
    `<option value="" selected>Select grade</option>` ;
    gradesSelect.insertAdjacentHTML("beforeend", defaultOption);

    grades.forEach((grade) => {
      const option = `<option data-rate-not-applicable=${grade.rateNotApplicable} value=${grade.id}>${grade.title}</option>`;
      gradesSelect.insertAdjacentHTML("beforeend", option);
    });
  }
}

import { application } from "../../frontend/controllers/index";
import SlimSelect from "slim-select";
application.register("agency_worker_modal", AgencyWorkerModalController);
