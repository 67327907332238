/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const ADD_WORKER_UPLOAD_FROM_HUB = gql`
  mutation addWorkerUploadFromHub(
    $workerId: Int!
    $file: String!
    $fileName: String!
    $documentTypeId: Int!
    $documentTitle: String!
    $documentNumber: String
    $issuer: String
    $issueDate: String
    $expirationDate: String
  ) {
    addWorkerUploadFromHub(
      workerId: $workerId
      file: $file
      fileName: $fileName
      documentTypeId: $documentTypeId
      documentNumber: $documentNumber
      documentTitle: $documentTitle
      issuer: $issuer
      issueDate: $issueDate
      expirationDate: $expirationDate
    ) {
      documentType {
        id
        title
        mid
        allowedFormats
        documentNumberVisibility
        documentTitleVisibility
        issuerVisibility
        issueDateVisibility
        expirationDateVisibility
        multipleUploadsAllowed
        workerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl

          fileName
          trashedAt
        }
        archivedWorkerUploads {
          id
          issuer
          documentNumber
          documentTitle
          qualificationName
          issueDate
          expirationDate
          imageUrl

          fileName
          trashedAt
        }
      }
      errors {
        attribute
        message
      }
    }
  }
`

export const SHORTLIST_MUTATION = gql`
  mutation UpdateProposalShortlistingStatus($id: ID!, $className: String!) {
    updateProposalShortlistingStatus(id: $id, className: $className) {
      errors {
        attribute
        message
      }
      shortlistedAt
      success
    }
  }
`
