import { ApolloProvider } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import createApolloClient from '../createApolloClient'
import ResetPin from './ResetPin'

document.addEventListener('DOMContentLoaded', () => {
  const validPaths = ['/timesheet_authorisers/pin_reset/new', '/timesheet_authorisers/pin/new']

  if (validPaths.includes(window.location.pathname)) {
    const rootElement = document.getElementById('reset-pin-root')

    if (rootElement) {
      const client = createApolloClient()
      ReactDOM.render(
        <ApolloProvider client={client}>
          <ResetPin stage={rootElement.dataset.stage} />
        </ApolloProvider>,
        rootElement
      )
    }
  }
})
