import SlimSelect from 'slim-select'
import queryString from 'query-string'


export default class OrganisationSelectorController extends SearchController {

    static get targets() {
        return ['select']
    }

    connect() {
        this.element[this.identifier] = this
        window.qs = queryString
        this.queryString = queryString.parse(location.search)
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');
        const organisationSelectorVal = document.querySelector('.modal__validation--organisation');

        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select organisation',
            onChange: (info) => {
                this.storeFilters('organisation_ids[]', info)
                if (searchOnSelect) {
                    this.multiSearchOnSelect('organisation_ids[]', info);
                }

                if (target) {
                    organisationSelectorVal.style.display = "none";
                }
            }
        });

        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }
    }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("organisation_selector", OrganisationSelectorController)