import React from 'react';

export function displayDescription(description) {
  if (!description) {
    return null;
  }

  return (
    <>
      {Object.entries(description).map(([key, value]) => {
        if (key === 'id' || value === null) {
          return null;
        }

        return (
          <React.Fragment key={key}>
            {formatValue(key, value)}
          </React.Fragment>
        );
      })}
    </>
  );
}

function displayNestedDescription(nestedDescription, parentKey) {
  const entries = Object.entries(nestedDescription);
  const hasId = entries.some(([key]) => key === 'id');

  if (entries.length === 2 && hasId) {
    const [[, nestedValue]] = entries.filter(([key]) => key !== 'id');
    return formatValue(parentKey, nestedValue);
  }

  if (Array.isArray(nestedDescription)) {
    return entries.map(([key, value]) => {
      if (key === 'id' || value === null) {
        return null;
      }

      return (
        <React.Fragment key={key}>
          {formatValue(key, value)}
        </React.Fragment>
      );
    });
  }

  return (
    <>
      {entries.map(([key, value]) => {
        if (key === 'id' || value === null) {
          return null;
        }

        return (
          <React.Fragment key={key}>
            {formatValue(key, value)}
          </React.Fragment>
        );
      })}
    </>
  );
}

function formatValue(key, value) {
  if (Array.isArray(value)) {
    return value.map((item, index) => {
      const reversedItem = Object.fromEntries(Object.entries(item).reverse());
      return (
        <React.Fragment key={index}>{displayNestedDescription(reversedItem, key)}</React.Fragment>
      );
    });
  }

  if (typeof value === 'object' && !Array.isArray(value)) {
    return (
      <React.Fragment key={key}>
        {displayNestedDescription(value, key)}
      </React.Fragment>
    );
  }

  return (
    <p>
      {key && <strong>{humanizeKey(key)}:</strong>} {value}
    </p>
  );
}

function humanizeKey(key) {
  return key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
}
