import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";

import { ErrorContainer, SuccessContainer } from "../../Forms/FormElements";
import {
  CloseButton,
  LoadingContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../Modals/ModalElements";

import {
  ADD_SKILLS_TO_ORG_STAFF_GROUP,
  DELETE_ORG_STAFF_GROUPS_SKILL,
  GET_ORG_STAFF_GROUP_SKILLS,
} from "./SkillsHelpers";

import { GET_SKILLS } from "../../SkillSets/SkillSetsHelpers";

const SkillsModal = ({ handleClose, organisation, show }) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [addSkillToOrgStaffGroup] = useMutation(ADD_SKILLS_TO_ORG_STAFF_GROUP);
  const [deleteOrgStaffGroupsSkill] = useMutation(
    DELETE_ORG_STAFF_GROUPS_SKILL
  );

  const { data: skills } = useQuery(GET_SKILLS);

  const { data, refetch } = useQuery(GET_ORG_STAFF_GROUP_SKILLS, {
    variables: {
      organisationStaffGroupId: Number(organisation?.id),
    },
  });

  useEffect(() => {
    setError({});
  }, [setError]);

  // remap skills into an array react-select can read
  const skillsMap = skills?.skills.map(function (skill) {
    return { value: skill.id, label: skill.name };
  });

  // remap skills into an array react-select can read
  const selectedSkills = data?.orgStaffGroupSkills.map(function (skill) {
    return { value: skill.skill.id, label: skill.skill.name, id: skill.id };
  });

  const addSkillAction = (skillId) => {
    setError({});
    setSuccess(false);
    addSkillToOrgStaffGroup({
      variables: {
        skillId: skillId,
        organisationStaffGroupId: Number(organisation?.id),
      },
    }).then((data) => {
      if (data.data.addSkillToOrgStaffGroup.orgStaffGroupsSkill.id) {
        setSuccess(true);
        refetch();
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } else {
        setError({
          error: true,
          message: `There was a problem adding this skill.`,
        });
      }
    });
  };

  const deleteSkillAction = (skillId) => {
    setError({});
    setSuccess(false);
    deleteOrgStaffGroupsSkill({
      variables: {
        id: Number(skillId),
      },
    }).then((data) => {
      if (data.data.deleteOrgStaffGroupsSkill.success === true) {
        setSuccess(true);
        refetch();
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      } else {
        setError({
          error: true,
          message: `There was a problem deleting this skill.`,
        });
      }
    });
  };

  const SelectContainer = (props) => {
    const deleteClick = (opt, props) => {
      props.selectOption(opt);
      deleteSkillAction(opt.id);
    };

    return (
      <React.Fragment>
        <components.SelectContainer {...props} />
        <div>
          {props.getValue().map((opt) => (
            <components.MultiValue
              {...props}
              components={{
                Container: components.MultiValueContainer,
                Label: components.MultiValueLabel,
                Remove: components.MultiValueRemove,
              }}
              key={props.selectProps.getOptionValue(opt)}
              removeProps={{
                onClick: () => deleteClick(opt, props),
                onTouchEnd: () => deleteClick(opt, props),
                onMouseDown: (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                },
              }}
              isFocused={false}
            >
              {props.selectProps.getOptionLabel(opt)}
            </components.MultiValue>
          ))}
        </div>
      </React.Fragment>
    );
  };

  const handleChange = (selectedOption, props) => {
    if (props.action === "select-option") {
      addSkillAction(props.option.value);
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
    >
      <ModalHeader>
        <ModalTitle>{organisation.title} Skills</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0, overflowY: "visible" }}>
            <label>Add Skills to {organisation.title}</label>
            <div>
              <SkillsSelect
                name="color"
                options={skillsMap}
                isMulti={true}
                placeholder="Select..."
                className={`react-select-container`}
                classNamePrefix="react-select"
                defaultValue={selectedSkills}
                onChange={(newOptions, props) => {
                  handleChange(newOptions, props);
                }}
                value={selectedSkills}
                components={{
                  SelectContainer,
                  ValueContainer,
                }}
                refetch={refetch}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              {success && <SuccessContainer>Skills Updated</SuccessContainer>}
              {error.error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleClose}>
                Close
              </button>
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

const ValueContainer = ({ children, ...props }) => {
  var new_children = React.Children.map(children, (child) => {
    return child.type === components.Input ? child : null;
  });
  return (
    <>
      Select skills...
      <components.ValueContainer {...props} children={new_children} />
    </>
  );
};

export default SkillsModal;

const SkillsSelect = styled(Select)`
  margin-bottom: 8px;
  width: 200px;

  &.react-select__multi-value {
    background: #ff7f4d;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    display: inline-flex;
    margin: 0;
    margin-bottom: 8px;
    margin-right: 8px;
    padding: 0;
    padding: 8px 16px;
    padding-right: 8px;
    width: auto;

    svg {
      fill: #ffffff;
      fill-opacity: 0.5;
    }

    .react-select__multi-value__label {
      box-shadow: none;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-right: 4px;
      padding: 0;
      width: auto;
    }

    .react-select__multi-value__remove {
      margin: 0;
      width: 22px;

      &:hover {
        background: transparent;
        cursor: pointer;

        svg {
          fill-opacity: 1;
        }
      }
    }
  }

  .react-select__control {
    -webkit-appearance: none;
    background: #ffffff;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    );
    border: 1px solid #cbd2d9;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    color: #1e2d36;
    font-size: 16px;
    height: ${(props) => (props.isMulti ? "auto" : "40px")};
    overflow: hidden;
    padding-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    ::placeholder {
      color: #3e4d58;
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid #cbd2d9;
    }

    &:active,
    &:focus {
      border: 1px solid #1e2d36;
      box-shadow: 0 0 3px 0 #9ca8b4;
      outline: none;
    }
  }

  .react-select__placeholder {
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__clear-indicator {
    display: none;
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option--is-focused {
    background-color: rgba(245, 221, 213, 0.25);
  }

  .react-select__option {
    &:hover {
      background-color: rgba(245, 221, 213, 0.25);
    }
  }

  .react-select__option--is-selected {
    background-color: #f5ddd5;
    color: #1e2d36;
  }

  .react-select__single-value {
    color: #1e2d36;
  }

  &.disabled {
    .react-select__control {
      pointer-events: none;
    }

    .react-select__single-value {
      color: #9ca8b4;
    }
  }
`;

const ShiftModalInputError = styled.span`
  color: #bd4848;
  margin-top: 5px;
`;

export const FormToggleContainer = styled.div`
  background: #eaedf2;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  padding: 12px;
  text-align: center;
  width: 100%;

  p {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;
