import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import EmailForm from './EmailForm'
import { PIN_RESET_MUTATION, SUBMIT_EMAIL_FORM_MUTATION } from './mutations'
import PinResetForm from './PinResetForm'
import SuccessStage from './SuccessStage'
import Logo from './svg/Logo'
import './styles/reset.scss'

const stageDispatcher = {
  email: {
    descr: 'We first need you to confirm your details before you can choose your PIN.',
    getForm: (formProps) => <EmailForm {...formProps} />, // eslint-disable-line react/jsx-props-no-spreading
    btnTitle: 'Submit',
  },
  'pin-reset': {
    descr: (
      <span>
        Please choose a four digit numerical PIN number. This will be associated with your timesheet authoriser account,
        and enable you to sign-off a worker&apos;s timesheet within the Patchwork app. <br /> <br />
        When a worker presents you with a shift to sign-off within the Patchwork app, you will be able to use your
        secure PIN to approve the shift.
      </span>
    ),
    getForm: (formProps) => <PinResetForm {...formProps} />, // eslint-disable-line react/jsx-props-no-spreading
    btnTitle: 'Choose PIN',
  },
  success: {
    // for the sake of consistency
    descr: '',
    getForm: (formProps) => <SuccessStage {...formProps} />, // eslint-disable-line react/jsx-props-no-spreading
    btnTitle: '',
  },
}

const defaultState = {
  currentStage: null,
  email: '',
  pin: '',
  pinConfirmation: '',
  isPinHidden: true,
  isConfirmHidden: true,
  successDescr: '',
  error: null,
}

function ResetPin({ stage }) {
  const [state, setState] = useState({ ...defaultState, currentStage: stage })
  const [submitEmailForm] = useMutation(SUBMIT_EMAIL_FORM_MUTATION)
  const [resetPinForm] = useMutation(PIN_RESET_MUTATION)
  const data = stageDispatcher[state.currentStage]
  const { descr, getForm, btnTitle } = data

  const handleEmailForm = async (e) => {
    e.preventDefault()

    const { email } = state

    if (email) {
      try {
        const {
          data: {
            timesheetAuthoriserRequestPinWithEmail: { success },
          },
        } = await submitEmailForm({ variables: { email } })

        if (success) {
          setState({
            ...state,
            currentStage: 'success',
            successDescr: 'Thank you. We have sent you an email with a link to reset your PIN.',
          })
        } else {
          setState({ ...state, error: 'Something went wrong' })
        }
      } catch (error) {
        setState({ ...state, error: 'Something went wrong' })
      }
    } else {
      setState({ ...state, error: 'Please provide your email' })
    }
  }

  const handlePinResetForm = async (e) => {
    e.preventDefault()
    const { pin, pinConfirmation } = state

    const errText = // eslint-disable-next-line no-nested-ternary
      !pin && !pinConfirmation
        ? 'Please enter your PIN number'
        : pin !== pinConfirmation
        ? "Oops, looks like your PIN numbers don't match. Please try again."
        : null

    if (errText) {
      setState({ ...state, error: errText })
    } else {
      try {
        const urlParams = new Proxy(new URLSearchParams(window.location.search), {
          get: (searchParams, prop) => searchParams.get(prop),
        })

        if (urlParams.token) {
          const {
            data: {
              timesheetAuthoriserResetPin: { success },
            },
          } = await resetPinForm({ variables: { token: urlParams.token, pin } })

          if (success) {
            setState({
              ...state,
              currentStage: 'success',
              successDescr:
                "Thank you. Your PIN number will be active shortly and you will be able to sign off shifts. If your PIN is not enabled, please contact your organisation's Patchwork administrator, who can reset your PIN for you.",
            })
          } else {
            setState({ ...state, error: 'Something went wrong' })
          }
        }
      } catch (error) {
        setState({ ...state, error: error.message || 'Something went wrong' })
      }
    }
  }

  const handleSubmit = (e) => (state.currentStage === 'email' ? handleEmailForm(e) : handlePinResetForm(e))

  const { error } = state
  return (
    <>
      <a href="/">
        <Logo />
      </a>
      <div className="reset-pin">
        <div className="reset-pin__wrapper">
          <div className="reset-pin__header" />
          <h1 className="reset-pin__title">Create or change your Authoriser PIN number</h1>
          <p className="reset-pin__descr">{descr}</p>
          <form className="reset-pin__form" onSubmit={handleSubmit}>
            {getForm({ state, setState })}
            {state.currentStage !== 'success' && (
              <>
                <p className="reset-pin__error">{error}</p>
                <button className="reset-pin__btn" type="submit">
                  {btnTitle}
                </button>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  )
}

ResetPin.propTypes = {
  stage: PropTypes.string.isRequired,
}

export default ResetPin
