/* eslint-disable react/function-component-definition */
/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable sort-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/order */
import React, { useState } from 'react'

import AuthHeader from '../AuthHeader'
import ShiftsCalendarView from './ShiftsCalendarView'

import AppContext from '../../Context/AppContext'
import { sortShifts } from '../../Helpers/ShiftHelpers'

const ShiftsCalender = ({
  costCentres,
  grades,
  organisation,
  organisationAgencies,
  organisationReasons,
  reasons,
  sites,
  staffTypes,
  user,
  usesMspShortlisting,
  canShortlistProposals,
}) => {
  var date = new Date(),
    y = date.getFullYear(),
    m = date.getMonth()
  var firstDay = new Date(y, m, 1)
  var lastDay = new Date(y, m + 2, 0)

  const [count, setCount] = useState(0)
  const [events, setEvents] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchShifts = (start, end) => {
    setEvents([])
    const from_start_time = start ? start : firstDay
    const to_start_time = end ? end : lastDay

    const query = `
      query Shifts($from_start_time: DateTime!, $to_start_time: DateTime! ){
        shifts(fromStartTime: $from_start_time, toStartTime: $to_start_time, limitToScope: NO_SCOPE, types: [HOURLY, SESSIONAL]){
          id
          appliedBy {
            id
          }
          agencyWorkerProposals {
            nodes {
              id
             shortlistedAt
            }
          }
          startTime
          status
        }
      }
    `

    fetch('/graphql', {
      method: 'POST',

      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      fetchPolicy: 'no-cache',
      body: JSON.stringify({
        query,
        variables: { from_start_time, to_start_time },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        setLoading(false)
        sortShifts(data.data.shifts, usesMspShortlisting, canShortlistProposals).then((data) => {
          if (data !== 'err') {
            setCount(data.count)
            setEvents(data.events)
          }
        })
      })
  }

  const providerValue = {
    costCentres,
    grades,
    reasons,
    sites,
    staffTypes,
    organisation,
    organisationAgencies,
    organisationReasons,
    user,
  }

  return (
    <AppContext.Provider value={providerValue}>
      <div className="filter">
        <div className="header-body">
          <AuthHeader subtitle={'Shifts Found'} title={`${count} Matches`} user={user} />
        </div>
      </div>
      <ShiftsCalendarView
        events={events}
        fetchShifts={fetchShifts}
        calendarLoading={loading}
        setCalendarLoading={setLoading}
        usesMspShortlisting={usesMspShortlisting}
        canShortlistProposals={canShortlistProposals}
      />
    </AppContext.Provider>
  )
}

export default ShiftsCalender
