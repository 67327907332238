/* eslint-disable camelcase */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
/* eslint-disable no-new */
/* eslint-disable radix */
import SlimSelect from 'slim-select'
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class RequestShiftController extends Controller {
  static get targets() {
    return [
      'shiftDates',
      'startDate',
      'endDate',
      'startTime',
      'endTime',
      'startTimeOnCall',
      'endTimeOnCall',
      'combinedDate',
      'combinedDateOnCall',
      'siteSelect',
      'departmentSelect',
      'costCentreToggle',
      'costCentreSelect',
      'costCentreCode',
      'defaultCostCentre',
      'staffGroupSelect',
      'staffGroupSelectOnCall',
      'gradeSelect',
      'skillsSelect',
      'overrideStandardShiftRatesToggle',
      'rateCore',
      'rateUnsocial',
      'rateAuthoriserSelect',
      'rateAmendmentDiv',
      'workerNameSelect',
      'ir35StatusContainer',
      'amountOfShifts',
      'increment',
      'decrement',
      'onCallRate',
      'callOutRate',
    ]
  }

  connect() {
    const { siteSelectTarget } = this
    const { departmentSelectTarget } = this
    const { costCentreSelectTarget } = this
    const { costCentreToggleTarget } = this
    const { costCentreCodeTarget } = this
    const { defaultCostCentreTarget } = this
    const { staffGroupSelectTarget } = this
    const { staffGroupSelectOnCallTarget } = this
    const { gradeSelectTarget } = this
    // const { onCallGradeSelectTarget } = this
    const { skillsSelectTarget } = this
    const { workerNameSelectTarget } = this
    const { shiftDatesTarget } = this
    const { rateAuthoriserSelectTarget } = this
    const { amountOfShiftsTarget } = this

    amountOfShiftsTarget.value = 1

    // Validation checks
    const siteSelectorVal = document.querySelector('.modal__validation--sites')
    const departmentSelectorVal = document.querySelector('.modal__validation--department')
    const staffSelectorVal = document.querySelector('.modal__validation--staff')
    const gradeSelectorVal = document.querySelector('.modal__validation--grade')
    //
    const staffSelectorOnCallVal = document.querySelector('.modal__validation--staff-on-call')

    this.vmsStaffGroups = JSON.parse(this.data.get('vmsStaffGroups'))

    new SlimSelect({
      select: siteSelectTarget,
      onChange: () => {
        // We can call fetch departments with "site" parameter
        this.fetchDepartments()
        siteSelectorVal.style.display = 'none'
      },
    })

    new SlimSelect({
      select: departmentSelectTarget,
      onChange: (info) => {
        this.fetchCostCentres()
        departmentSelectorVal.style.display = 'none'
        this.updateRateAuthorisersList()
      },
    })

    new SlimSelect({
      select: staffGroupSelectTarget,
      onChange: (info) => {
        const selectedValue = parseInt(info.value)
        document.getElementById('staff-group-id').value = selectedValue
        this.fetchGrades()
        this.fetchSkills()
        this.changeIr35Visibility(selectedValue)
        staffSelectorVal.style.display = 'none'
      },
    })

    // On call staff group -------------------------------

    new SlimSelect({
      select: staffGroupSelectOnCallTarget,
      onChange: (info) => {
        const selectedValue = parseInt(info.value)
        document.getElementById('staff-group-id-on-call').value = selectedValue
        this.fetchGrades()
        this.fetchSkills()
        this.changeIr35Visibility(selectedValue)
        staffSelectorOnCallVal.style.display = 'none'
      },
    })

    // End on call staff group

    new SlimSelect({
      select: gradeSelectTarget,
      placeholder: 'Select Grade...',
      onChange: (info) => {
        this.checkRateApplicable(info)
        this.updateOnCallRates(info)
        gradeSelectorVal.style.display = 'none'
      },
    })

    new SlimSelect({
      select: skillsSelectTarget,
      placeholder: 'Select Skills...',
    })

    const workerNameSlimSelect = new SlimSelect({
      select: workerNameSelectTarget,
      placeholder: 'Select worker',
      searchingText: 'Searching...',
      ajax: this.fetchWorkers(),
    })

    const costCentreSlimSelect = new SlimSelect({
      select: costCentreSelectTarget,
      onChange: (info) => {
        this.updateCostCentre()
      },
    })
    costCentreSlimSelect.disable()

    costCentreToggleTarget.onclick = function () {
      if (costCentreToggleTarget.checked === true) {
        // Set cost centre code from selected overridde
        costCentreSlimSelect.enable()
        costCentreCodeTarget.value = costCentreSelectTarget.value
      } else {
        // Set cost centre code from default code
        costCentreCodeTarget.value = defaultCostCentreTarget.innerText
        costCentreSlimSelect.disable()
      }
    }

    this.incrementTarget.onclick = function () {
      amountOfShiftsTarget.value++
      const amountSelectorVal = document.querySelector('.modal__validation--amount')
      if (amountSelectorVal) {
        amountSelectorVal.style.display = 'none'
      }

      changedAmountReg(amountOfShiftsTarget.value)
    }

    this.decrementTarget.onclick = function () {
      if (amountOfShiftsTarget.value == 1) {
        return
      }
      const amountSelectorVal = document.querySelector('.modal__validation--amount')
      if (amountSelectorVal) {
        amountSelectorVal.style.display = 'none'
      }

      amountOfShiftsTarget.value--

      changedAmountReg(amountOfShiftsTarget.value)
    }

    const changedAmountReg = (amountVal) => {
      if (amountVal > 1) {
        console.log('Over 1', amountVal)
        document.querySelector('#worker_name_selector .ss-hide').click()
        workerNameSlimSelect.disable()
      } else {
        console.log('under 1', amountVal)
        workerNameSlimSelect.enable()
      }
    }
  }

  overrideStandardShiftRatesToggled() {
    if (this.overrideStandardShiftRatesToggleTarget.checked) {
      this.showDivs(this.rateAmendmentDivTargets)
      if (this.rateAuthoriserSelect == null) {
        this.rateAuthoriserSelect = new SlimSelect({
          select: this.rateAuthoriserSelectTarget,
        })
      }
      this.updateRateAuthorisersList()
    } else {
      this.hideDivs(this.rateAmendmentDivTargets)
    }
  }

  showDivs(targets) {
    for (let i = 0; i < targets.length; i++) {
      targets[i].classList.remove('d-none')
    }
  }

  hideDivs(targets) {
    for (let i = 0; i < targets.length; i++) {
      targets[i].classList.add('d-none')
    }
  }

  updateRateAuthorisersList() {
    if (this.rateAuthoriserSelect == null) return
    if (!isNaN(parseInt(this.departmentSelectTarget.value))) {
      this.rateAuthoriserSelect.setData([{ text: 'Loading...' }])
      this.fetchRateAuthorisers()
    } else {
      this.rateAuthoriserSelect.setData([{ text: 'Select a department first' }])
    }
  }

  updateDate() {
    // unused
    const shiftDates = this.shiftDatesTarget.value
    const shiftStartTime = this.startTimeTarget.value
    const shiftEndTime = this.endTimeTarget.value
    // unused
    const combinedDate = `${shiftDates} ${shiftStartTime} ${shiftEndTime}`
    // unused
    this.combinedDateTarget.value = combinedDate

    // set up new variables and assign the times from the frontend values
    const time_start = new Date()
    const time_end = new Date()
    const value_start = shiftStartTime.split(':')
    const value_end = shiftEndTime.split(':')
    // convert the frontend values to readable props
    time_start.setHours(value_start[0], value_start[1])
    time_end.setHours(value_end[0], value_end[1])
    // get the difference between inputs in milliseconds and convert to hours
    const millisec = time_end - time_start
    const hours = Number((millisec / (1000 * 60 * 60)).toFixed(2))
    const hoursChar = hours.toString().charAt(0)
    const time = Number.isNaN(hours) ? 0 : hours == 0 ? 24 : hoursChar == '-' ? hours + 24 : hours
    // assign hours to the frontend target
    this.combinedDateTarget.innerText = time.toFixed(2)
  }

  updateDateOnCall() {
    // unused
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value
    const startTime = this.startTimeOnCallTarget.value
    const endTime = this.endTimeOnCallTarget.value

    // set up new variables and assign the times from the frontend values
    const startDateConverted = startDate.split('/').reverse().join('-')
    const endDateConverted = endDate.split('/').reverse().join('-')
    const startDateTime = new Date(`${startDateConverted}T${startTime}`)
    const endDateTime = new Date(`${endDateConverted}T${endTime}`)

    // get the difference between inputs in milliseconds and convert to hours
    const millisec = endDateTime - startDateTime
    const hours = Number((millisec / (1000 * 60 * 60)).toFixed(2))
    const hoursChar = hours.toString().charAt(0)
    const time = Number.isNaN(hours) ? 0 : hours == 0 ? 24 : hoursChar == '-' ? hours + 24 : hours
    // assign hours to the frontend target
    this.combinedDateOnCallTarget.innerText = time.toFixed(2)
  }

  fetchDepartments() {
    const site_id = parseInt(this.siteSelectTarget.value)
    const query = `query Departments($site_id: Int!){
            departments(siteId: $site_id){
              id
              name
            }
          }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { site_id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const departments = data.errors ? [] : data.data.departments
        this.populateDepartments(departments)
        this.setDefaultCostCentre(null)
        this.populateCostCentres(null)
      })
  }

  fetchCostCentres() {
    const id = parseInt(this.departmentSelectTarget.value)

    if (!id) {
      this.setDefaultCostCentre(null)
      this.populateCostCentres(null)
      return
    }

    const query = `query Department($id: Int!){
            department(id: $id){
              id
              name
              costCentre {
                id
                code
              }
              associatedCostCentres {
                id
                code
              }
            }
          }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const department = data.errors ? [] : data.data.department
        this.setDefaultCostCentre(department.costCentre)
        this.populateCostCentres(department.associatedCostCentres)
      })
  }

  fetchGrades() {
    const onCallButton = document.querySelector('#on-call-shift-button')

    let staff_group_id

    if (onCallButton.checked) {
      staff_group_id = parseInt(this.staffGroupSelectOnCallTarget.value)
    } else {
      staff_group_id = parseInt(this.staffGroupSelectTarget.value)
    }

    console.log('staff_group_id', staff_group_id)

    const with_on_call_rates = document.querySelector('#on-call-shift-button').checked
    const query = `query Grades($staff_group_id: Int!, $with_on_call_rates: Boolean){
            grades(staffGroupId: $staff_group_id, withOnCallRates: $with_on_call_rates){
              id
              title
              rateNotApplicable
              onCallRate
              callOutRate
            }
          }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { staff_group_id, with_on_call_rates },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const grades = data.errors ? [] : data.data.grades
        this.populateGrades(grades)
      })
  }

  fetchSkills() {
    const onCallButton = document.querySelector('#on-call-shift-button')

    let staffGroupId

    if (onCallButton.checked) {
      staffGroupId = parseInt(this.staffGroupSelectOnCallTarget.value)
    } else {
      staffGroupId = parseInt(this.staffGroupSelectTarget.value)
    }

    const query = `query OrgStaffGroupSkills($staffGroupId: Int!){
      orgStaffGroupSkills(staffGroupId: $staffGroupId){
        id
        skill {
          id
          name
        }
        organisationStaffGroup {
          usesSkills
        }
      }
    }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { staffGroupId },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (
          data.data?.orgStaffGroupSkills?.length > 0 &&
          data.data?.orgStaffGroupSkills[0]?.organisationStaffGroup?.usesSkills === true
        ) {
          const skills = data.errors ? [] : data.data.orgStaffGroupSkills
          this.populateSkills(skills)
        } else {
          this.populateSkills([])
        }
      })
  }

  fetchRateAuthorisers() {
    const department_id = parseInt(this.departmentSelectTarget.value)
    const query = `query ($department_id: Int!){
            rateAuthorisersNewShift(departmentId: $department_id){
              id
              name
            }
          }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: {
          department_id,
        },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        const rateAuthorisersData = data.errors ? [] : data.data.rateAuthorisersNewShift
        this.populateRateAuthorisers(rateAuthorisersData)
      })
  }

  changeIr35Visibility(staffGroupId) {
    if (this.vmsStaffGroups.includes(staffGroupId)) {
      this.ir35StatusContainerTarget.classList.remove('d-none')
    } else {
      this.ir35StatusContainerTarget.classList.add('d-none')
    }
  }

  populateRateAuthorisers(rateAuthorisersData) {
    const data = [
      {
        value: '',
        text: 'Not selected',
      },
    ]
    for (let i = 0; i < rateAuthorisersData.length; i++) {
      data.push({
        value: rateAuthorisersData[i].id,
        text: rateAuthorisersData[i].name,
      })
    }
    this.rateAuthoriserSelect.setData(data)
  }

  checkRateApplicable(info) {
    if (info.data.rateNotApplicable === 'false') {
      document.getElementById('shift-rate').style.display = 'block'
      document.getElementById('rate-checker').value = 1
    } else {
      document.getElementById('shift-rate').style.display = 'none'
      document.getElementById('rate-checker').value = 0
    }
  }

  updateOnCallRates(info) {
    this.onCallRateTarget.value = info.data.onCallRate
    this.callOutRateTarget.value = info.data.callOutRate
  }

  fetchWorkers() {
    function set_workers_data(workers) {
      const data = []

      const workerSuspended = '<span class="filter__worker-suspended"></span>'

      for (let i = 0; i < workers.length; i++) {
        const worker = workers[i]

        let workerSummary = `${worker.firstName} ${worker.lastName}`
        if (worker.onlySecondaryBank) {
          workerSummary +=
            '<span class="badge badge-type  badge-secondary-bank  badge-secondary-bank-dropdown">Secondary</span>'
        }

        workerSummary += worker.grade ? ` (${worker.grade.title})` : ' (missing grade)'

        if (worker.suspended) {
          workerSummary += workerSuspended
        }

        data.push({
          text: workerSummary,
          value: worker.id,
        })
      }
      return data
    }
    return function (search, callback) {
      const staffGroupId = parseInt(document.getElementById('staff-group-id').value)
      const query = `query Workers($name: String, $page: Int!, $items: Int, $staffGroupId: Int!){
                workers(name: $name, page: $page, items: $items, staffGroupId: $staffGroupId){
                    id
                    firstName
                    lastName
                    email
                    suspended
                    onlySecondaryBank
                    grade {
                        title
                    }
                }
            }`

      // Check search value. If you dont like it callback(false) or callback('Message String')
      if (search.length < 3) {
        callback('Need 3 characters')
        return
      }

      const name = search

      const page = 1
      const items = 10

      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: { name, page, items, staffGroupId },
        }),
      })
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          const { workers } = json.data
          const data = set_workers_data(workers)

          // Upon successful fetch send data to callback function.
          // Be sure to send data back in the proper format.
          // Refer to the method setData for examples of proper format.
          callback(data)
        })
        .catch(function (error) {
          // If any errors happened send false back through the callback
          callback(false)
        })
    }
  }

  updateCostCentre() {
    const code = this.costCentreSelectTarget.value
    this.costCentreCodeTarget.value = code
  }

  populateDepartments(departments) {
    const departmentSelect = this.departmentSelectTarget
    departmentSelect.options.length = null
    const defaultOption = `<option value="" selected>Select department</option>`
    departmentSelect.insertAdjacentHTML('beforeend', defaultOption)

    departments.forEach((department) => {
      const option = `<option value=${department.id}>${department.name}</option>`
      departmentSelect.insertAdjacentHTML('beforeend', option)
    })
  }

  setDefaultCostCentre(costCentre) {
    const costCentreLabel = document.getElementById('default-cost-centre')

    if (costCentre == null) {
      this.defaultCostCentreTarget.innerText = ''
      this.costCentreCodeTarget.value = ''
      costCentreLabel.style.display = 'none'
      return
    }

    this.defaultCostCentreTarget.innerText = costCentre.code
    this.costCentreCodeTarget.value = costCentre.code
    costCentreLabel.style.display = 'inline'
  }

  populateCostCentres(costCentres) {
    const costCentresSelect = this.costCentreSelectTarget
    costCentresSelect.options.length = null

    if (costCentres == null) {
      const defaultOption = `<option value="" disabled>Select Cost Centre...</option>`
      costCentresSelect.insertAdjacentHTML('beforeend', defaultOption)
      return
    }

    const defaultOption = `<option value="" disabled>Select Cost Centre...</option>`
    costCentresSelect.insertAdjacentHTML('beforeend', defaultOption)

    costCentres.forEach((costCentre) => {
      const option = `<option value=${costCentre.code}>${costCentre.code}</option>`
      costCentresSelect.insertAdjacentHTML('beforeend', option)
    })
  }

  populateGrades(grades) {
    const gradesSelect = this.gradeSelectTarget
    gradesSelect.options.length = null
    const defaultOption = `<option value="" selected>Select grade</option>`
    gradesSelect.insertAdjacentHTML('beforeend', defaultOption)

    grades.forEach((grade) => {
      const option = `
        <option data-rate-not-applicable=${grade.rateNotApplicable}
                data-on-call-rate=${grade.onCallRate}
                data-call-out-rate=${grade.callOutRate}
                value=${grade.id}>${grade.title}
        </option>
      `
      gradesSelect.insertAdjacentHTML('beforeend', option)
    })
  }

  populateSkills(skills) {
    const skillsSelect = this.skillsSelectTarget
    skillsSelect.options.length = null
    const defaultOption = `<option value="" disabled>Select Skills...</option>`
    skillsSelect.insertAdjacentHTML('beforeend', defaultOption)

    skills.forEach((skill) => {
      const option = `<option value=${skill.skill.id}>${skill.skill.name}</option>`
      skillsSelect.insertAdjacentHTML('beforeend', option)
    })
  }
}

application.register('request_shift', RequestShiftController)
