import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FormGrid, FormGroup } from '../../Forms/FormElements'
import { CREATE_ORGANISATION_AGENCY, DELETE_ORGANISATION_AGENCY, UPDATE_ORGANISATION_AGENCY } from './AgencyListHelpers'

function OrganisationAgencyRow({
  agencyId,
  index,
  isMod,
  organisationAgencies,
  organisationAgency,
  organisationStaffGroups,
  refetchAgencies,
  refetchOrganisationAgencies,
  setShowNewStaffgroup,
}) {
  const [filteredOrganisationStaffGroups, setFilteredOrganisationStaffGroups] = useState(undefined)
  const [inputs, setInputs] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [staffGroupId, setStaffGroupId] = useState(
    organisationAgency ? organisationAgency.organisationStaffGroup.id : undefined
  )
  const [update, setUpdate] = useState(undefined)
  const [updated, setUpdated] = useState(false)

  const [createOrganisationAgency] = useMutation(CREATE_ORGANISATION_AGENCY)
  const [deleteOrganisationAgency] = useMutation(DELETE_ORGANISATION_AGENCY)
  const [updateOrganisationAgency] = useMutation(UPDATE_ORGANISATION_AGENCY)

  useEffect(() => {
    if (organisationAgency) {
      setInputs({
        id: Number(organisationAgency.id),
        agencyMarginLocked: organisationAgency.agencyMarginLocked,
        agencyTierIds: organisationAgency.agencyTiers.map((element) => Number(element.id)),
        canAssignWorker: organisationAgency.canAssignWorker,
        canProposeWorker: organisationAgency.canProposeWorker,
        canEditChargeRate: organisationAgency.canEditChargeRate,
      })
    }
    if (organisationStaffGroups && organisationAgencies) {
      const a = []
      organisationAgencies.map((item) => a.push(item.organisationStaffGroup.id))
      setFilteredOrganisationStaffGroups(organisationStaffGroups.filter((i) => !a.includes(i.id)))
    }
  }, [organisationAgencies, organisationAgency, organisationStaffGroups])

  const handleStaffGroupChange = (event) => {
    setStaffGroupId(event.value)
    setUpdated(true)
    setLoading(true)
    setUpdate(Math.random())
    const staffGroup = organisationStaffGroups.filter((item) => item.id === event.value)
    setInputs((inputs) => ({
      ...inputs,
      staffGroupId: event.value,
      agencyTierIds: [
        Number(staffGroup[0]?.agencyTiers[0] ? staffGroup[0]?.agencyTiers[0].id : staffGroup[0]?.defaultAgencyTier.id),
      ],
    }))
    setTimeout(() => {
      setUpdated(false)
      setLoading(false)
    }, 2000)
  }

  const handleMultiAgencyTierChange = (event) => {
    const tierIds = event.map((element) => element.value)
    setUpdated(true)
    setLoading(true)
    setUpdate(Math.random())
    setInputs((inputs) => ({
      ...inputs,
      agencyTierIds: tierIds,
    }))
    setTimeout(() => {
      setUpdated(false)
      setLoading(false)
    }, 2000)
  }

  const staffGroup = organisationStaffGroups.filter((item) => item.id === staffGroupId)

  useEffect(() => {
    if (organisationAgency && !staffGroup[0]) {
      refetchOrganisationAgencies()
    }
  }, [staffGroup, refetchOrganisationAgencies])

  // remap tiers into an array react-select can read
  const agencyTiers =
    staffGroupId &&
    staffGroup[0] &&
    staffGroup[0].agencyTiers?.map(function (agencyTier) {
      return { value: agencyTier.id, label: agencyTier.rank }
    })

  // remap staffGroups into an array react-select can read
  const orgStaffGroups = filteredOrganisationStaffGroups?.map(function (staffGroup) {
    return { value: staffGroup.id, label: staffGroup.title }
  })

  const createOrganisationAgencyAction = () => {
    createOrganisationAgency({
      variables: {
        agencyId: Number(agencyId),
        agencyTierIds: inputs.agencyTierIds,
        organisationStaffGroupId: Number(inputs.staffGroupId),
      },
    }).then((data) => {
      if (!data.data.createOrganisationAgency.errors.length > 0) {
        setUpdated(true)
        refetchOrganisationAgencies()
        refetchAgencies()
        setShowNewStaffgroup(false)
      }
    })
  }

  const deleteOrganisationAgencyAction = (organisationAgencyId) => {
    deleteOrganisationAgency({
      variables: {
        id: Number(organisationAgencyId),
      },
    }).then((data) => {
      if (!data.data.deleteOrganisationAgency.errors.length > 0) {
        refetchOrganisationAgencies()
        refetchAgencies()
      }
    })
  }

  const updateOrganisationAgencyAction = () => {
    updateOrganisationAgency({
      variables: {
        id: Number(inputs.id),
        agencyMarginLocked: inputs.agencyMarginLocked,
        // TODO: This line is going to the mutationx
        agencyTierIds: inputs.agencyTierIds.map((tier) => Number(tier)),
        canAssignWorker: inputs.canAssignWorker,
        canProposeWorker: inputs.canProposeWorker,
        canEditChargeRate: inputs.canEditChargeRate,
      },
    }).then((data) => {
      if (!data.data.updateOrganisationAgency.errors.length > 0) {
        setUpdated(true)
        refetchOrganisationAgencies()
        refetchAgencies()
      }
    })
  }

  useEffect(() => {
    if (update) {
      if (!organisationAgency) {
        createOrganisationAgencyAction()
      } else {
        updateOrganisationAgencyAction()
      }
    }
  }, [organisationAgency, update])

  let tierDefaultValue = []

  if (!isMod) {
    tierDefaultValue = organisationAgency?.agencyTiers
      ?.map((tier) => ({
        label: tier.rank,
        value: tier.id,
      }))
      .sort((a, b) => a.label - b.label)[0]
  } else {
    tierDefaultValue = organisationAgency?.agencyTiers?.map((tier) => ({
      label: tier.rank,
      value: tier.id,
    }))
  }

  return (
    <StaffGroupRowContainer key={index} className={updated ? 'updated' : ''}>
      {loading && (
        <UpdatingLabel>
          <svg fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <rect fill="#7DD962" height="16" rx="3" width="16" />
            <path
              d="M6.334 11L3 7.937l.952-.874L6.413 9.32l-.08-.071L12.049 4l.952.875-5.714 5.25-.952.874V11z"
              fill="#fff"
            />
          </svg>
          <span>Details updated</span>
        </UpdatingLabel>
      )}
      <FormGrid columns={4} full>
        <FormGroup
          component="react-select"
          defaultValue={
            organisationAgency && {
              label: organisationAgency.organisationStaffGroup.title,
              value: organisationAgency.organisationStaffGroup.id,
            }
          }
          disabled={!!organisationAgency}
          label="Staff Group"
          name="staff_group_id"
          onChange={handleStaffGroupChange}
          options={orgStaffGroups}
          placeholder="Select Staff Group"
        />

        {/* Agency Tier Selector */}
        {organisationAgency && organisationAgency.organisationStaffGroup.vms === true && (
          <FormGroup
            component="react-select"
            defaultValue={tierDefaultValue}
            isClearable={false}
            isMulti={isMod}
            label="Agency Tier"
            name="agency_tier"
            onChange={(e) => {
              if (!isMod) {
                setUpdated(true)
                setLoading(true)
                setUpdate(Math.random())
                setInputs((inputs) => ({
                  ...inputs,
                  agencyTierIds: [e.value],
                }))
                setTimeout(() => {
                  setUpdated(false)
                  setLoading(false)
                }, 2000)

                return
              }

              handleMultiAgencyTierChange(e)
            }}
            options={agencyTiers}
            placeholder="Select Agency Tier"
          />
        )}
        <PermissionsContainer>
          {organisationAgency && organisationAgency.organisationStaffGroup.vms === true && (
            <div style={{ marginRight: 24 }}>
              <input
                checked={inputs ? inputs.canProposeWorker : false}
                id={`propose-${index}`}
                name="canProposeWorker"
                onChange={() => {
                  setUpdated(true)
                  setLoading(true)
                  setUpdate(Math.random())
                  setInputs((inputs) => ({
                    ...inputs,
                    canProposeWorker: !inputs.canProposeWorker,
                  }))
                  setTimeout(() => {
                    setUpdated(false)
                    setLoading(false)
                  }, 2000)
                }}
                type="checkbox"
              />
              <label htmlFor={`propose-${index}`}>Can propose agency worker</label>
            </div>
          )}
          {organisationAgency && (
            <div style={{ marginRight: 24 }}>
              <input
                checked={inputs ? inputs.canAssignWorker : false}
                id={`assign-${index}`}
                name="canAssignWorker"
                onChange={() => {
                  setUpdated(true)
                  setLoading(true)
                  setUpdate(Math.random())
                  setInputs((inputs) => ({
                    ...inputs,
                    canAssignWorker: !inputs.canAssignWorker,
                  }))
                  setTimeout(() => {
                    setUpdated(false)
                    setLoading(false)
                  }, 2000)
                }}
                type="checkbox"
              />
              <label htmlFor={`assign-${index}`}>Can assign agency worker</label>
            </div>
          )}
          {organisationAgency && organisationAgency.organisationStaffGroup.vms === true && (
            <div style={{ marginRight: 24 }}>
              <input
                checked={inputs ? inputs.canEditChargeRate : false}
                id={`can-edit-charge-rate-${index}`}
                name="canEditChargeRate"
                onChange={() => {
                  setUpdated(true)
                  setLoading(true)
                  setUpdate(Math.random())
                  setInputs((inputs) => ({
                    ...inputs,
                    canEditChargeRate: !inputs.canEditChargeRate,
                  }))
                  setTimeout(() => {
                    setUpdated(false)
                    setLoading(false)
                  }, 2000)
                }}
                type="checkbox"
              />
              <label htmlFor={`can-edit-charge-rate-${index}`}>Can edit charge rate</label>
            </div>
          )}
          {organisationAgency && (
            <div style={{ marginRight: 24 }}>
              <input
                checked={inputs ? inputs.agencyMarginLocked : false}
                id={`agency-margin-locked-${index}`}
                name="agencyMarginLocked"
                onChange={() => {
                  setUpdated(true)
                  setLoading(true)
                  setUpdate(Math.random())
                  setInputs((inputs) => ({
                    ...inputs,
                    agencyMarginLocked: !inputs.agencyMarginLocked,
                  }))
                  setTimeout(() => {
                    setUpdated(false)
                    setLoading(false)
                  }, 2000)
                }}
                type="checkbox"
              />
              <label htmlFor={`agency-margin-locked-${index}`}>Lock agency margin</label>
            </div>
          )}
        </PermissionsContainer>
        <DeleteStaffGroupContainer>
          {organisationAgency && (
            <button onClick={() => deleteOrganisationAgencyAction(organisationAgency.id)}>Delete Permission</button>
          )}
        </DeleteStaffGroupContainer>
      </FormGrid>
    </StaffGroupRowContainer>
  )
}

export default OrganisationAgencyRow

const StaffGroupRowContainer = styled.div`
  background: #f5f7fa;
  border: 1px dashed #cbd2d9;
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 840px;
  padding: 12px 16px 16px;
  position: relative;
  transition: all ease 0.25s;

  &.updated {
    background: rgba(125, 217, 98, 0.1);
    border-color: #7dd962;
  }
`

const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: max-content;

  input {
    margin-right: 8px;
    position: relative;
    top: 1px;
  }

  label {
    font-size: 15px;
    margin: 0;
  }
`

const UpdatingLabel = styled.div`
  align-items: center;
  background: rgba(125, 217, 98, 0.2);
  border-bottom: 1px dashed #7dd962;
  border-bottom-left-radius: 4px;
  border-left: 1px dashed #7dd962;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;

  svg {
    margin-right: 8px;
  }

  span {
    color: #1e2d36;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`

const DeleteStaffGroupContainer = styled.div`
  align-items: end;
  align-self: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: end;

  button {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #fd6a6a;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    text-decoration: underline;
  }
`
