import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components'
import { ShiftModalLabel, ShiftModalReactSelect } from '../../Forms/FormElements'
import { GET_VMS_AGENCY_WORKERS } from '../AgencyLocums/AgencyLocumHelpers'

function AgencyShiftsFilters({
  grades,
  departments,
  sites,
  staffGroups,
  handleChangeSite,
  handleChangeDepartment,
  handleChangeStaffGroup,
  handleChangeGrade,
  handleChangeWorkers,
  handleChangeType,
  handleChangeRate,
  handleChangeBooked,
  handleChangeToDate,
  handleChangeFromDate,
  ifFilters,
  filters,
}) {
  const { data } = useQuery(GET_VMS_AGENCY_WORKERS)
  const [view, setView] = useState(false)

  // remap grades into an array react-select can read
  const sortedLocums = data?.vmsAgencyWorkers.edges.map(function ({ node: locum }) {
    return { value: locum.id, label: `${locum.firstName} ${locum.lastName}` }
  })

  // remap grades into an array react-select can read
  const sortedGrades = grades?.map(function (grade) {
    return { value: grade.id, label: grade.title }
  })

  // remap organisations into an array react-select can read
  const sortedDepartments = departments?.map(function (dep) {
    return { value: dep.id, label: dep.name }
  })

  // remap sites into an array react-select can read
  const sortedSites = sites?.map(function (site) {
    return { value: site.id, label: site.name }
  })

  // remap specialities into an array react-select can read
  const sortedStaffGroups = staffGroups?.map(function (staffGroup) {
    return { value: staffGroup.id, label: staffGroup.title }
  })

  const typeSorted = [
    { value: 'HOURLY', label: 'Hourly Shifts' },
    { value: 'SESSIONAL', label: 'Sessional Shifts' },
  ]

  const rateSorted = [
    { value: 'escalated', label: 'Escalated' },
    { value: 'not_escalated', label: 'Not Escalated' },
  ]

  const sortedBooked = [{ value: 'true', label: 'Yes' }]

  return (
    <ShiftsFilterWrapper>
      <div className="shift-table__container">
        <div>
          <FilterButton onClick={() => setView(!view)} style={{ marginRight: 12 }}>
            <svg height="23" width="22" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.583 8.5H6.417a.92.92 0 01-.917-.917.92.92 0 01.917-.916h9.166a.92.92 0 01.917.916.92.92 0 01-.917.917zm-1.833 3.667h-5.5a.92.92 0 01-.917-.917.92.92 0 01.917-.917h5.5a.92.92 0 01.917.917.92.92 0 01-.917.917zm-1.833 3.666h-1.834a.92.92 0 01-.916-.916.92.92 0 01.916-.917h1.834a.92.92 0 01.916.917.92.92 0 01-.916.916zM11 .25c-6.073 0-11 4.927-11 11s4.927 11 11 11 11-4.927 11-11-4.927-11-11-11z"
                fill="#1E2D36"
                fillRule="evenodd"
                opacity=".5"
              />
            </svg>
            {view ? 'Close' : ifFilters ? 'Edit' : 'Add'} Filters
          </FilterButton>
          {ifFilters && (
            <FilterButton
              onClick={() => {
                handleChangeSite([])
                handleChangeDepartment([])
                handleChangeStaffGroup([])
                handleChangeGrade([])
                handleChangeWorkers([])
                handleChangeType(null)
                handleChangeRate(null)
                handleChangeBooked(null)
                handleChangeToDate(undefined)
                handleChangeFromDate(undefined)
              }}
            >
              <svg height="22" width="22" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.566 13.269a.918.918 0 010 1.297.911.911 0 01-.651.266.909.909 0 01-.646-.266L11 12.297l-2.269 2.269a.909.909 0 01-.646.266.911.911 0 01-.65-.266.918.918 0 010-1.297L9.702 11 7.434 8.731a.918.918 0 011.297-1.297L11 9.703l2.269-2.269a.918.918 0 011.297 1.297L12.297 11l2.269 2.269zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                  fill="#1E2D36"
                  fillOpacity=".5"
                  fillRule="evenodd"
                />
              </svg>
              Clear Filters
            </FilterButton>
          )}
        </div>
        {view && (
          <ShiftsFiltersContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Sites</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.sites}
                isClearable
                isMulti
                onChange={handleChangeSite}
                options={sortedSites}
                value={filters.sites}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Departments</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.departments}
                isClearable
                isMulti
                onChange={handleChangeDepartment}
                options={sortedDepartments}
                value={filters.departments}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Staff Groups</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.staffGroups}
                isClearable
                isMulti
                onChange={handleChangeStaffGroup}
                options={sortedStaffGroups}
                value={filters.staffGroups}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Grades</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.grades}
                isClearable
                isMulti
                onChange={handleChangeGrade}
                options={sortedGrades}
                value={filters.grades}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Shift Type</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.typeValue}
                isClearable
                onChange={handleChangeType}
                options={typeSorted}
                value={filters.typeValue}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Rate</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.rateValue}
                isClearable
                onChange={handleChangeRate}
                options={rateSorted}
                value={filters.rateValue}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Booked</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.bookedValue}
                isClearable
                onChange={handleChangeBooked}
                options={sortedBooked}
                value={filters.bookedValue}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>Workers</ShiftModalLabel>
              <FilterDropdown
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={filters.agencyWorkers}
                isClearable
                isMulti
                onChange={handleChangeWorkers}
                options={sortedLocums}
                value={filters.agencyWorkers}
              />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>From Start Date</ShiftModalLabel>
              <DayPickerInput onDayChange={(day) => handleChangeFromDate(day)} value={filters.fromDateValue} />
            </ShiftsFilterContainer>
            <ShiftsFilterContainer>
              <ShiftModalLabel style={{ color: '#fff' }}>To End Date</ShiftModalLabel>
              <DayPickerInput onDayChange={(day) => handleChangeToDate(day)} value={filters.toDateValue} />
            </ShiftsFilterContainer>
            <div />
          </ShiftsFiltersContainer>
        )}
      </div>
    </ShiftsFilterWrapper>
  )
}

export default AgencyShiftsFilters

export const ShiftsFilterWrapper = styled.div`
  background: #1e2d36;

  .shift-table__container {
    margin-bottom: -15px;
    padding-bottom: 15px;
  }
`

export const ShiftsFiltersContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(auto-fill, 400px);
  margin-bottom: 24px;

  .DayPickerInput {
    width: 100%;

    input {
      background: #ffffff;
      border: 1px solid #cbd2d9;
      border-radius: 6px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
      color: #1e2d36;
      font-size: 16px;
      height: 40px;
      padding-bottom: 2px;
      padding-left: 12px;
      padding-right: 12px;
      width: 100%;

      ::placeholder {
        color: #3e4d58;
        opacity: 0.5;
      }

      &:active,
      &:focus {
        border: 1px solid #1e2d36;
        box-shadow: 0 0 3px 0 #9ca8b4;
        outline: none;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-flow: wrap;
  }
`

export const ShiftsFilterContainer = styled.div`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 10px;
    margin-right: 20px;
    width: calc(25% - 20px);
  }
`

export const FilterDropdown = styled(ShiftModalReactSelect)`
  min-width: 200px;

  .react-select__value-container {
    align-items: flex-start;
    display: flex;
    height: 30px;
    flex-direction: column;
    overflow-x: auto;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-thumb {
      height: 0;
      width: 0;
    }

    &::-webkit-scrollbar-track {
      height: 0;
      width: 0;
    }
  }
`

const FilterButton = styled.button`
  background-color: #ffffff;
  border: 1px solid transparent;
  border-color: #e3ebf6;
  border-radius: 8px;
  color: #12263f;
  font-weight: 500;
  margin-bottom: 16px;
  margin-top: 12px;
  padding: 0.5rem 0.75rem;
  text-align: center;
  vertical-align: middle;

  &:hover {
    text-decroration: underline;
  }

  svg {
    margin-right: 8px;
  }
`
