import { gql } from "@apollo/client";

export const AgencyUserFragment = gql`
  fragment AgencyUserFragment on AgencyUser {
    id
    firstName
    lastName
    email
    agency {
      id
      title
      organisationAgencies {
        organisationStaffGroup {
          id
        }
        canAssignWorker
        canProposeWorker
      }
      canManageUsers
    }
    phoneNumber
    jobTitle
    canManageUsers
    canCreateAgencyWorkers
    suspended
  }
`;

export const GET_AGENCY_USERS = gql`
  query GetAgencyUsers(
      $page: Int
      $items: Int
      $name: String
      $email: String
    ) {
    agencyUsers(page: $page, items: $items, name: $name, email: $email) {
      list {
        ...AgencyUserFragment
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
  ${AgencyUserFragment}
`;

export const GET_VMS_AGENCY_USERS = gql`
  query GetVmsAgencyUsers( 
      $page: Int
      $items: Int
      $name: String
      $email: String
    ) {
    vmsAgencyUsers(page: $page, items: $items, name: $name, email: $email) {
      list {
        ...AgencyUserFragment
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
  ${AgencyUserFragment}
`;

export const ADD_AGENCY_USER = gql`
  mutation AgencyUserCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $agencyId: Int!
    $phoneNumber: String!
    $jobTitle: String!
    $canManageUsers: Boolean!
    $canCreateAgencyWorkers: Boolean!
  ) {
    agencyUserCreate(
      firstName: $firstName
      lastName: $lastName
      email: $email
      agencyId: $agencyId
      phoneNumber: $phoneNumber
      jobTitle: $jobTitle
      canManageUsers: $canManageUsers
      canCreateAgencyWorkers: $canCreateAgencyWorkers
    ) {
      agencyUser {
        ...AgencyUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${AgencyUserFragment}
`;

export const ADD_VMS_AGENCY_USER = gql`
  mutation VmsAgencyUserCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $jobTitle: String!
    $canManageUsers: Boolean!
  ) # $canCreateAgencyWorkers: Boolean!
  {
    vmsAgencyUserCreate(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      jobTitle: $jobTitle
      canManageUsers: $canManageUsers
    ) # canCreateAgencyWorkers: $canCreateAgencyWorkers
    {
      agencyUser {
        ...AgencyUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${AgencyUserFragment}
`;

export const SUSPEND_AGENCY_USER = gql`
  mutation SuspendAgencyUser($id: Int!) {
    suspendAgencyUser(id: $id)
  }
`;

export const UNSUSPEND_AGENCY_USER = gql`
  mutation UnsuspendAgencyUser($id: Int!) {
    unsuspendAgencyUser(id: $id)
  }
`;

export const VMS_SUSPEND_AGENCY_USER = gql`
  mutation VmsAgencyUserSuspend($id: Int!) {
    vmsAgencyUserSuspend(id: $id)
  }
`;

export const VMS_UNSUSPEND_AGENCY_USER = gql`
  mutation VmsAgencyUserUnsuspend($id: Int!) {
    vmsAgencyUserUnsuspend(id: $id)
  }
`;

export const UPDATE_AGENCY_USER = gql`
  mutation AgencyUserUpdate(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $agencyId: Int!
    $phoneNumber: String!
    $jobTitle: String!
    $canManageUsers: Boolean!
    $canCreateAgencyWorkers: Boolean!
  ) {
    agencyUserUpdate(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      agencyId: $agencyId
      phoneNumber: $phoneNumber
      jobTitle: $jobTitle
      canManageUsers: $canManageUsers
      canCreateAgencyWorkers: $canCreateAgencyWorkers
    ) {
      agencyUser {
        ...AgencyUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${AgencyUserFragment}
`;

export const UPDATE_VMS_AGENCY_USER = gql`
  mutation VmsAgencyUserUpdate(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phoneNumber: String!
    $jobTitle: String!
    $canManageUsers: Boolean!
  ) # $canCreateAgencyWorkers: Boolean!
  {
    vmsAgencyUserUpdate(
      id: $id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phoneNumber: $phoneNumber
      jobTitle: $jobTitle
      canManageUsers: $canManageUsers
    ) # canCreateAgencyWorkers: $canCreateAgencyWorkers
    {
      agencyUser {
        ...AgencyUserFragment
      }
      errors {
        attribute
        message
      }
    }
  }
  ${AgencyUserFragment}
`;

export const GET_AGENCY_USER_PERMISSIONS = gql`
  query GetAgencyUserPermissions($agencyUserId: Int!) {
    agencyUserPermissions(agencyUserId: $agencyUserId) {
      id
      canAssignWorker
      canProposeWorker
      organisationStaffGroup {
        id
        title
      }
    }
  }
`;

export const GET_VMS_AGENCY_USER_PERMISSIONS = gql`
  query GetVMSAgencyUserPermissions($agencyUserId: Int!) {
    vmsAgencyUserPermissions(agencyUserId: $agencyUserId) {
      id
      canAssignWorker
      canProposeWorker
      organisationStaffGroup {
        id
        title
      }
    }
  }
`;

export const CREATE_AGENCY_USER_PERMISSION = gql`
  mutation AgencyUserPermissionCreate(
    $agencyId: Int!
    $agencyUserId: Int!
    $organisationStaffGroupId: Int!
    $canAssignWorker: Boolean!
    $canProposeWorker: Boolean!
  ) {
    agencyUserPermissionCreate(
      agencyId: $agencyId
      agencyUserId: $agencyUserId
      organisationStaffGroupId: $organisationStaffGroupId
      canAssignWorker: $canAssignWorker
      canProposeWorker: $canProposeWorker
    )
  }
`;

export const VMS_CREATE_AGENCY_USER_PERMISSION = gql`
  mutation VmsAgencyUserPermissionCreate(
    $agencyUserId: Int!
    $organisationStaffGroupId: Int!
    $canAssignWorker: Boolean!
    $canProposeWorker: Boolean!
  ) {
    vmsAgencyUserPermissionCreate(
      agencyUserId: $agencyUserId
      organisationStaffGroupId: $organisationStaffGroupId
      canAssignWorker: $canAssignWorker
      canProposeWorker: $canProposeWorker
    )
  }
`;

export const DELETE_AGENCY_USER_PERMISSION = gql`
  mutation AgencyUserPermissionDestroy($id: Int!) {
    agencyUserPermissionDestroy(id: $id)
  }
`;

export const VMS_DELETE_AGENCY_USER_PERMISSION = gql`
  mutation VmsAgencyUserPermissionDestroy($id: Int!) {
    vmsAgencyUserPermissionDestroy(id: $id)
  }
`;

export const UPDATE_AGENCY_USER_PERMISSION = gql`
  mutation AgencyUserPermissionUpdate(
    $id: Int!
    $agencyId: Int!
    $organisationStaffGroupId: Int!
    $canAssignWorker: Boolean!
    $canProposeWorker: Boolean!
  ) {
    agencyUserPermissionUpdate(
      id: $id
      agencyId: $agencyId
      organisationStaffGroupId: $organisationStaffGroupId
      canAssignWorker: $canAssignWorker
      canProposeWorker: $canProposeWorker
    )
  }
`;

export const VMS_UPDATE_AGENCY_USER_PERMISSION = gql`
  mutation VmsAgencyUserPermissionUpdate(
    $id: Int!
    $organisationStaffGroupId: Int!
    $canAssignWorker: Boolean!
    $canProposeWorker: Boolean!
  ) {
    vmsAgencyUserPermissionUpdate(
      id: $id
      organisationStaffGroupId: $organisationStaffGroupId
      canAssignWorker: $canAssignWorker
      canProposeWorker: $canProposeWorker
    )
  }
`;
