import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'



export default class CostCentreSelectorController extends Controller {

    static get targets() {
        return ['select']
    }
    connect() {
        const target = this.selectTarget;
        this.select = new SlimSelect({
            select: target
        })
    }
}

import { application } from '../../frontend/controllers/index'
application.register("cost_centre_selector", CostCentreSelectorController);