import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../../Forms/FormElements";
import {
  CloseButton,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../Modals/ModalElements";
import {
  createAgencyLocum,
  editAgencyLocum,
  CREATE_VMS_AGENCY_WORKER,
  UPDATE_VMS_AGENCY_WORKER,
  GET_GRADES,
} from "./AgencyLocumHelpers";

const AgencyLocumModal = ({
  agencies,
  agencyWorker,
  canCreateAgencyWorkers,
  disabled,
  handleClose,
  orgStaffGroups,
  refetch,
  show,
  vms,
}) => {
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    reg_body_number: "",
    ni_number: "",
    staff_group_id: "",
    grade_id: "",
    agency_id: "",
    cv: "",
  });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    reg_body_number: "",
    ni_number: "",
    staff_group_id: "",
    grade_id: "",
    agency_id: "",
    cv: "",
  });
  const [edited, setEdited] = useState(false);
  const [loading, setLoading] = useState(false);
  const [staffGroupId, setStaffGroupId] = useState(undefined);

  const [vmsAgencyWorkerCreate] = useMutation(CREATE_VMS_AGENCY_WORKER);
  const [vmsAgencyWorkerUpdate] = useMutation(UPDATE_VMS_AGENCY_WORKER);

  const { data: grades } = useQuery(GET_GRADES, {
    variables: {
      staffGroupId: staffGroupId,
    },
  });

  useEffect(() => {
    if (agencyWorker) {
      setInputs({
        first_name: agencyWorker.firstName ? agencyWorker.firstName : "",
        last_name: agencyWorker.lastName ? agencyWorker.lastName : "",
        reg_body_number: agencyWorker.regBodyNumber
          ? agencyWorker.regBodyNumber
          : "",
        ni_number: agencyWorker.niNumber ? agencyWorker.niNumber : "",
        staff_group_id: agencyWorker.staffGroup
          ? agencyWorker.staffGroup.idValue
          : "",
        grade_id: agencyWorker.grade ? agencyWorker.grade.id : "",
        cv: agencyWorker.cvUrl ? agencyWorker.cvUrl : "",
      });
      setEdited(false);
    }
  }, [agencyWorker]);

  // remap grades into an array react-select can read
  const orgGrades = grades?.grades.map(function (grade) {
    return { value: grade.id, label: grade.title };
  });

  const handleCloseAction = () => {
    handleClose();
    setInputs({});
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCvUpload = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({ ...inputs, cv: event.target.files[0] }));
    setEdited(true);
  };

  const handleStaffGroupChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, staff_group_id: Number(event.value) }));
    setStaffGroupId(Number(event.value));
  };

  const handleGradeChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, grade_id: Number(event.value) }));
  };

  const handleAgencyChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, agency_id: Number(event.value) }));
  };

  const createAgencyLocumAction = () => {
    setError({});
    if (inputs.first_name === "") {
      setError({ ...error, first_name: "Please enter a first name." });
    } else if (inputs.last_name === "") {
      setError({ ...error, last_name: "Please enter a last name." });
    } else if (inputs.reg_body_number === "") {
      setError({
        ...error,
        reg_body_number: "Please enter a registration body number.",
      });
    } else if (inputs.reg_body_number.length > 10) {
      setError({
        ...error,
        reg_body_number: "Registration body number is too long.",
      });
    } else if (inputs.ni_number === "") {
      setError({
        ...error,
        ni_number: "Please enter a NI  number.",
      });
    } else if (/^[A-Z]{2}\d{6}[A-Z]$/i.test(inputs.ni_number) == false) {
      setError({
        ...error,
        ni_number: "Incorrect NI number format.",
      });
    } else if (inputs.staff_group_id === "") {
      setError({
        ...error,
        staff_group_id: "Please select a Staff Group.",
      });
    } else if (inputs.grade_id === "") {
      setError({
        ...error,
        grade_id: "Please select a Grade.",
      });
    } else if (!vms && inputs.agency_id === "") {
      setError({
        ...error,
        agency_id: "Please select an Agency.",
      });
    } else {
      setLoading(true);
      setError({});
      if (inputs) {
        if (vms) {
          vmsAgencyWorkerCreate({
            variables: {
              firstName: inputs.first_name,
              lastName: inputs.last_name,
              regBodyNumber: inputs.reg_body_number,
              niNumber: inputs.ni_number,
              staffGroupId: inputs.staff_group_id,
              gradeId: inputs.grade_id,
              cv: edited ? inputs.cv : null,
            },
          }).then((data) => {
            setLoading(false);
            if (!data.data.vmsAgencyWorkerCreate.errors.length > 0) {
              refetch();
              handleCloseAction();
            } else {
              setError({
                error: true,
                message: `Error: ${(data.data.vmsAgencyWorkerCreate.errors[0].message).replace(/[\[\]''"]+/g,'')}`,
              });
            }
          }).catch((err) => {
            alert(err);
          })
        } 
        
        
        
        else {
          const body = {
            agency_locum: {
              first_name: inputs.first_name,
              last_name: inputs.last_name,
              reg_body_number: inputs.reg_body_number
                ? inputs.reg_body_number
                : null,
              ni_number: inputs.ni_number ? inputs.ni_number : null,
              staff_group_id: inputs.staff_group_id
                ? inputs.staff_group_id
                : null,
              grade_id: inputs.grade_id ? inputs.grade_id : null,
              agency_id: inputs.agency_id ? inputs.agency_id : null,
            },
          };
          createAgencyLocum(body).then((data) => {
            setLoading(false);
            if (data !== "err") {
              refetch();
              handleCloseAction();
            } else {
              setError({
                error: true,
                message: `There was a problem creating this Agency Worker.`,
              });
            }
          });
        }
      }
    }
  };

  const editAgencyLocumAction = (agencyLocumId) => {
    setError({});
    if (inputs.first_name === "") {
      setError({ ...error, first_name: "Please enter a first name." });
    } else if (inputs.last_name === "") {
      setError({ ...error, last_name: "Please enter a last name." });
    } else if (inputs.reg_body_number === "") {
      setError({
        ...error,
        reg_body_number: "Please enter a registration body number.",
      });
    } else if (inputs.reg_body_number.length > 10) {
      setError({
        ...error,
        reg_body_number: "Registration body number is too long.",
      });
    } else if (inputs.ni_number === "") {
      setError({
        ...error,
        ni_number: "Please enter a NI  number.",
      });
    } else if (inputs.staff_group_id === "") {
      setError({
        ...error,
        staff_group_id: "Please select a Staff Group.",
      });
    } else if (inputs.grade_id === "") {
      setError({
        ...error,
        grade_id: "Please select a Grade.",
      });
    } else if (!vms && inputs.agency_id === "") {
      setError({
        ...error,
        agency_id: "Please select an Agency.",
      });
    } else {
      setLoading(true);
      setError({});
      if (inputs) {
        if (vms) {
          vmsAgencyWorkerUpdate({
            variables: {
              id: Number(agencyWorker.id),
              firstName: inputs.first_name,
              lastName: inputs.last_name,
              regBodyNumber: inputs.reg_body_number,
              niNumber: inputs.ni_number,
              staffGroupId: Number(inputs.staff_group_id),
              gradeId: Number(inputs.grade_id),
              cv: edited ? inputs.cv : null,
            },
          })
            .then((data) => {
              setLoading(false);
              if (!data.data.vmsAgencyWorkerUpdate.errors.length > 0) {
                window.location.reload();
              } else {
                setError({
                  error: true,
                  message: `There was a problem updating this Agency Worker.`,
                });
              }
            })
            .catch((err) => {
              setLoading(false);
              setError({
                error: true,
                message: `There was a problem updating this Agency Worker.`,
              });
            });
        } else {
          const body = {
            agency_locum: {
              first_name: inputs.first_name,
              last_name: inputs.last_name,
              reg_body_number: inputs.reg_body_number
                ? inputs.reg_body_number
                : null,
              ni_number: inputs.ni_number ? inputs.ni_number : null,
              staff_group_id: inputs.staff_group_id
                ? inputs.staff_group_id
                : null,
              grade_id: inputs.grade_id ? inputs.grade_id : null,
              agency_id: inputs.agency_id ? inputs.agency_id : null,
            },
          };
          editAgencyLocum(agencyLocumId, body).then((data) => {
            setLoading(false);
            if (data !== "err") {
              refetch();
              handleCloseAction();
            } else {
              setError({
                error: true,
                message: `There was a problem editing this Agency Worker.`,
              });
            }
          });
        }
      }
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {agencyWorker
            ? !disabled
              ? `Edit ${agencyWorker.firstName} ${agencyWorker.lastName}`
              : `${agencyWorker.firstName} ${agencyWorker.lastName}`
            : "Create Agency Worker"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ModalBody style={{ overflow: "visible" }}>
            <AgencyForm>
              <FormDetailGroup>
                <h2>Worker Information</h2>
                <FormGrid columns={3}>
                  <FormGroup
                    component={"input"}
                    value={inputs.first_name}
                    placeholder={"Enter First Name"}
                    label={"First Name"}
                    name="first_name"
                    onChange={handleInputChange}
                    type="text"
                    error={error.first_name}
                    disabled={disabled}
                  />
                  <FormGroup
                    component={"input"}
                    value={inputs.last_name}
                    placeholder={"Enter Last Name"}
                    label={"Last Name"}
                    name="last_name"
                    onChange={handleInputChange}
                    type="text"
                    error={error.last_name}
                    disabled={disabled}
                  />
                  <FormGroup
                    component={"input"}
                    value={inputs.reg_body_number}
                    placeholder={"Enter No. here"}
                    label={"Registration Body Number"}
                    name="reg_body_number"
                    onChange={handleInputChange}
                    type="text"
                    error={error.reg_body_number}
                    disabled={disabled}
                  />
                </FormGrid>
                <FormGrid columns={3}>
                  <FormGroup
                    component={"input"}
                    value={inputs.ni_number}
                    placeholder={"Enter NI No."}
                    label={"National Insurance No."}
                    name="ni_number"
                    onChange={handleInputChange}
                    type="text"
                    error={error.ni_number}
                    disabled={disabled}
                  />
                  <FormGroup
                    component={"react-select"}
                    label={"Staff Group"}
                    options={orgStaffGroups}
                    placeholder="Select Staff Group"
                    name="staff_group_id"
                    onChange={handleStaffGroupChange}
                    defaultValue={
                      agencyWorker && {
                        label: agencyWorker.staffGroup?.title,
                        value: agencyWorker.staffGroup?.id,
                      }
                    }
                    error={error.staff_group_id}
                    disabled={disabled}
                  />
                  <FormGroup
                    component={"react-select"}
                    label={"Select Grade"}
                    options={orgGrades}
                    placeholder="Select Grade"
                    name="grade_id"
                    onChange={handleGradeChange}
                    defaultValue={
                      agencyWorker && {
                        label: agencyWorker.grade?.title,
                        value: agencyWorker.grade?.id,
                      }
                    }
                    error={error.grade_id}
                    disabled={disabled}
                  />
                </FormGrid>
                {vms && (
                  <FormGroup label={"CV Upload"}>
                    <FileWrapper>
                      <label className="custom-file-upload">
                        <input type="file" onChange={handleCvUpload} />
                        <i className="fa fa-cloud-upload"></i>
                        {inputs?.cv ? "Change File" : "Upload File"}
                      </label>
                      {agencyWorker && agencyWorker?.cvUrl !== null && (
                        <a href={`${inputs?.cv}`} target="_blank">
                          Link to CV
                        </a>
                      )}
                    </FileWrapper>
                  </FormGroup>
                )}
              </FormDetailGroup>
              {!vms && (
                <FormDetailGroup>
                  <h2>Assign to Agency</h2>
                  <FormGrid columns={3}>
                    <FormGroup
                      component={"react-select"}
                      label={"Select Agency"}
                      options={agencies}
                      placeholder="Assign to Agency"
                      name="agency_id"
                      onChange={handleAgencyChange}
                      defaultValue={
                        agencyWorker && {
                          label: agencyWorker.agency?.title
                            ? agencyWorker.agency.title
                            : "",
                          value: agencyWorker.agency?.id
                            ? agencyWorker.agency.id
                            : "",
                        }
                      }
                      error={error.agency_id}
                    />
                  </FormGrid>
                </FormDetailGroup>
              )}
            </AgencyForm>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button
                className="btn btn-close"
                onClick={() => handleCloseAction()}
              >
                {disabled ? "Close" : "Cancel"}
              </button>
              {canCreateAgencyWorkers && (
                <>
                  {!disabled &&
                    (agencyWorker ? (
                      <button
                        className="btn text-white btn-cyan--modal"
                        onClick={() => editAgencyLocumAction(agencyWorker.id)}
                      >
                        Save Changes
                      </button>
                    ) : (
                      <button
                        className="btn text-white btn-cyan--modal"
                        onClick={() => createAgencyLocumAction()}
                      >
                        Create Agency Worker
                      </button>
                    ))}
                </>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default AgencyLocumModal;

const AgencyForm = styled.div`
  max-width: 800px;
`;

const FileWrapper = styled.div`
  /* border: 1px solid red; */
  display: flex;
  width: 100%;
  align-items: center;

  a {
    margin-left: 15px;
    margin-top: -12px;
    text-decoration: underline;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    width: 128px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #e4e7eb;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    padding: 11px 20px;
    height: 42px;
    margin-right: 8px;
    text-align: center;
    position: relative;

    @media (max-width: 568px) {
      margin-top: 1rem;
    }
  }
`;
