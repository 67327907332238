export async function createCollaborativeBank(body) {
  const url = `/collaborative_banks`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status === 200) {
      window.location.reload(true);
    } else {
      return response;
    }
  });
  return data;
}

export async function editCollaborativeBank(id, body) {
  const url = `/collaborative_banks/${id}`;

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status === 200) {
      window.location.reload(true);
    } else {
      return response;
    }
  });
  return data;
}
