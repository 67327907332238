import Table from 'react-bootstrap/Table'
import styled from 'styled-components'

export const TableContainer = styled.div`
  margin-top: -75px;
`

export const TableHeaderWrapper = styled.div`
  background: #1e2d36;

  .shift-table__container {
    margin-bottom: -15px;
    padding-bottom: 15px;
  }
`

export const TableHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;

  button {
    flex-shrink: 0;
    height: 40px;
    padding: 0;
    padding-left: 12px;
    padding-right: 12px;

    svg {
      margin-right: 4px;
      position: relative;
      top: -1px;
    }
  }
`

export const TableSearchInput = styled.input`
  background: #ffffff;
  border: 0;
  border-radius: 6px;
  box-shadow: none;
  font-size: 16px;
  height: 40px;
  margin-right: 20px;
  max-width: 320px;
  padding: 0 16px 2px;
  width: 100%;
`

export const TableComponent = styled(Table)`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 24px !important;
  overflow: hidden;

  thead {
    box-shadow: none;

    th {
      border-top: 0;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  tbody {
    th {
      font-weight: 500;
    }
  }

  th,
  td {
    font-size: 12px;
    vertical-align: middle;

    a {
      cursor: pointer;
      font-weight: 500;
      text-decoration: underline !important;
    }
  }

  td {
    padding: 10px 16px;
  }

  button {
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  .icon-shadow {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
`
