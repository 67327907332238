import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class ButtonToggleController extends Controller {
  static targets = ['hourlyBtn', 'sessionalBtn', 'onCallBtn', 'buttonsContainer']

  connect() {
    this.initElements()
    this.hideRate()

    if (this.onlyOneType()) this.hideComponent()
  }

  initElements() {
    this.form = document.querySelector('#shift-form')
    this.rateTitle = document.querySelector('#rate')
    this.rate = document.querySelector('#shift-rate')
    this.rates = document.querySelector('#shift-rates-area')
    this.hideRatesArea = this.rates.getAttribute('data-org-hide-rates')
    this.defaultDatesCont = document.querySelector('#default-dates-container')
    this.onCallDatesCont = document.querySelector('#on-call-dates-container')

    //

    this.defaultInfo = document.querySelector('#default-info-container')
    this.oncallInfo = document.querySelector('#oncall-info-container')
  }

  addHourly(e) {
    e.preventDefault()

    this.resetShiftType()
    this.setFormAction('/shifts')

    this.hourlyBtnTarget.checked = true
    if (this.hideRatesArea === 'false') {
      this.rates.classList.remove('d-none')
    }

    this.defaultInfo.classList.remove('d-none')
    this.oncallInfo.classList.add('d-none')
  }

  addSessional(e) {
    e.preventDefault()

    this.resetShiftType()
    this.setFormAction('/shifts/sessional')

    this.sessionalBtnTarget.checked = true
    this.rateTitle.innerHTML = 'Rate'
    this.rate.classList.remove('d-none')
    if (this.hideRatesArea === 'false') {
      this.rates.classList.add('d-none')
    }

    this.defaultInfo.classList.remove('d-none')
    this.oncallInfo.classList.add('d-none')
  }

  addOnCall(e) {
    e.preventDefault()

    this.resetShiftType()
    this.setFormAction('/shifts/on_call')

    this.onCallBtnTarget.checked = true
    this.defaultDatesCont.classList.add('d-none')
    this.onCallDatesCont.classList.remove('d-none')
    this.rates.classList.add('d-none')
    //
    this.defaultInfo.classList.add('d-none')
    this.oncallInfo.classList.remove('d-none')
  }

  hideRate() {
    this.rate.classList.add('d-none')
  }

  setFormAction(path) {
    this.form.setAttribute('action', path)
  }

  resetShiftType() {
    this.rateTitle.innerHTML = ''
    this.hideRate()

    this.defaultDatesCont.classList.remove('d-none')
    this.onCallDatesCont.classList.add('d-none')

    this.hourlyBtnTarget.checked = false
    this.sessionalBtnTarget.checked = false
    this.onCallBtnTarget.checked = false
  }

  onlyOneType() {
    return this.buttonsContainerTarget.querySelectorAll('button').length < 2
  }

  hideComponent() {
    this.element.classList.add('d-none')
  }
}

application.register('button_toggle', ButtonToggleController)
