import { Controller } from "stimulus";

export default class AuthoriserListController extends Controller {
  static get targets() {
    return ["assignButton", "authToken", "authoriserPosition"];
  }

  updateInput(event) {
    event.preventDefault();

    const value = this.assignButtonTarget.dataset.value;

    let chooseAuthoriser = document.querySelector(
      "#" + this.data.get("update-input-id")
    );
    chooseAuthoriser.value = value;

    const selectBtns = Array.from(document.querySelectorAll(".authoriser-btn"));

    selectBtns.forEach(function (selectBox, i) {
      if (selectBox.classList.contains("innertext--toggle")) {
        selectBox.innerText = "Select";
        selectBox.classList.remove("innertext--toggle");
      }
    });

    if (
      this.assignButtonTarget.innerText == "Select" ||
      this.assignButtonTarget.innerText == " Select "
    ) {
      this.assignButtonTarget.innerText = "Selected";
      this.assignButtonTarget.classList.add("innertext--toggle");
    }
  }

  assignAuthoriser(event) {
    event.preventDefault();

    let selectButton = event.srcElement;

    const selectBtns = Array.from(document.querySelectorAll(".authoriser-btn"));

    selectBtns.forEach(function (selectBox, i) {
      if (selectBox.classList.contains("innertext--toggle")) {
        selectBox.innerText = "Select";
        selectBox.classList.remove("innertext--toggle");
      }
    });

    let authoriserValue = selectButton.dataset.value;
    this.authoriserPositionTarget.value = authoriserValue;

    if (
      selectButton.innerText == "Select" ||
      selectButton.innerText == " Select "
    ) {
      selectButton.innerText = "Selected";
      selectButton.classList.add("innertext--toggle");
    }
  }
}

import { application } from "../../frontend/controllers/index";
application.register("authoriser_list", AuthoriserListController);
