export const statusMap = {
  APPROVED: {
    format: "Approved",
    background: "#FFF4D4",
    border: "#AA861B",
    dark: "#AA861B"
  },
  AVAILABLE: {
    format: "Available",
    background: "#FFCCB8",
    border: "#FFD8C8",
    dark: "#C34412"
  },
  BOOKED: {
    format: "Booked",
    background: "#C5F2C4",
    border: "rgba(158,211,104,0.50)",
    dark: "#338532"
  },
  NEEDS_SIGN_OFF: {
    format: "Sign-off Requested",
    background: "#DBF7FB",
    border: "#A0EAF4",
    dark: "#1AA1B5"
  },
  PAID: {
    format: "Payroll",
    background: "#1E2D36",
    border: "#1E2D36",
    dark: "#FFFFFF"
  },
  TO_SIGN_OFF_ON_HUB: {
    format: "Booked",
    background: "#C5F2C4",
    border: "rgba(158,211,104,0.50)",
    dark: "#338532"
  },
  TO_SIGN_OFF_ON_HUB_OR_APP: {
    format: "Booked",
    background: "#C5F2C4",
    border: "rgba(158,211,104,0.50)",
    dark: "#338532"
  },
  SIGN_OFF_REQUESTED: {
    format: "Sign-off Requested",
    background: "#DBF7FB",
    border: "#A0EAF4",
    dark: "#1AA1B5"
  },
  SIGNED_OFF: {
    format: "Signed Off",
    background: "#9CA8B4",
    border: "#7C8C9B",
    dark: "#FFFFFF"
  },
  URGENT: {
    format: "Urgent",
    background: "#DBF7FB",
    border: "#A0EAF4",
    dark: "#1AA1B5"
  },
}