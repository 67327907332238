import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../packs/application";

import AgencyLocums from "./AgencyLocums";

function AgencyLocumsIndex({
  agencies,
  currentAgencyUser,
  organisationId,
  vms,
}) {
  const agencyUserOrganisationId = currentAgencyUser?.agency.organisation.id;
  const canCreateAgencyWorkers =
    !vms || currentAgencyUser?.["create_workers?"] === true;

  return (
    <ApolloProvider client={client}>
      <AgencyLocums
        agencies={agencies}
        canCreateAgencyWorkers={canCreateAgencyWorkers}
        organisationId={
          currentAgencyUser ? agencyUserOrganisationId : organisationId
        }
        vms={vms}
      />
    </ApolloProvider>
  );
}

export default AgencyLocumsIndex;
