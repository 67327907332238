/* eslint-disable sort-keys */
import {
  DefaultButton,
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@patchworkhealth/web-components'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'

function DeleteItemModal({ title, description, submitText, isOpen, handleClose, handleDelete }) {
  return (
    <Modal backdrop="static" centered onHide={handleClose} show={isOpen} size="lg">
      <ModalHeaderMod>
        <ModalTitleMod>{title}</ModalTitleMod>
        <CloseButton onClick={() => handleClose()} />
      </ModalHeaderMod>

      <ModalBody>{description}</ModalBody>

      <ModalFooter>
        <div />
        <div>
          <DefaultButton action={() => handleClose()} text="Cancel" type="white" />
          <DefaultButton
            action={() => {
              handleDelete()
              handleClose()
            }}
            color="blue"
            text={submitText}
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteItemModal

const ModalHeaderMod = styled(ModalHeader)`
  height: 80px;
  font-size: 12px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
`

const ModalTitleMod = styled(ModalTitle)`
  font-weight: 500;
  font-size: 20px !important;
  text-transform: none !important;
`
