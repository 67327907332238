import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../packs/application";

import Specialities from "./Specialities";

function SpecialitiesIndex() {
  return (
    <ApolloProvider client={client}>
      <Specialities />
    </ApolloProvider>
  );
}

export default SpecialitiesIndex;
