/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import { AgencyTabSingle, AgencyTabSplit, AgencyOptionalInfo, AssignAgencyWorkerTabs } from '../../ShiftModalAgency'
import ShiftModalAssign from '../../ShiftModalAssign'
import {
  ErrorContainer,
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalDetailGroup,
  ShiftModalButtonClose,
} from '../../ShiftModalComponents'
import {
  GET_VMS_AGENCY_WORKERS,
  GET_VMS_AGENCY_WORKER_PROPOSALS,
  VMS_AGENCY_WORKER_PROPOSAL_CREATE,
  VMS_AGENCY_WORKER_PROPOSAL_DELETE,
  VMS_SHIFT_ASSIGN_PROPOSED_AGENCY_WORKER,
} from './VMSProposeAgencyWorkerHelpers'

function ShiftModalVMSProposeAgencyWorker({
  fetchShift,
  fetchShifts,
  handleClose,
  setSelectedTab,
  setShiftLoading,
  shift,
}) {
  const [agencyTab, setAgencyTab] = useState('single')
  const [error, setError] = useState({ error: false, message: null })
  const [modalStep, setModalStep] = useState(1)
  const [optionalInputs, setOptionalInputs] = useState({
    requisition_number: '',
    po_number: '',
    receipt_number: '',
    comment: '',
  })
  const [singleDirectEngagement, setSingleDirectEngagement] = useState(false)
  const [singleFrameWorkerCompliant, setSingleFrameWorkerCompliant] = useState(false)
  const [singleInputs, setSingleInputs] = useState({
    core_total_rate: 0,
    core_locum_rate: 0,
    core_agency_margin: 0,
    core_ni_contribution: 0,
  })
  const [splitDirectEngagement, setSplitDirectEngagement] = useState(false)
  const [splitFrameWorkerCompliant, setSplitFrameWorkerCompliant] = useState(false)
  const [splitInputs, setSplitInputs] = useState({
    core_total_rate: 0,
    unsocial_total_rate: 0,
    core_locum_rate: 0,
    unsocial_locum_rate: 0,
    core_ni_contribution: 0,
    unsocial_ni_contribution: 0,
    core_agency_margin: 0,
    unsocial_agency_margin: 0,
  })
  const [worker, setWorker] = useState(undefined)
  const [workerName, setWorkerName] = useState('')

  // const [workers, setWorkers] = useState(undefined);

  const toggleSingleDirectEngagement = () => setSingleDirectEngagement(!singleDirectEngagement)
  const toggleSplitDirectEngagement = () => setSplitDirectEngagement(!splitDirectEngagement)

  const toggleSingleFrameWorkerCompliant = () => setSingleFrameWorkerCompliant(!singleFrameWorkerCompliant)
  const toggleSplitFrameWorkerCompliant = () => setSplitFrameWorkerCompliant(!splitFrameWorkerCompliant)

  const [vmsAgencyWorkerProposalCreate] = useMutation(VMS_AGENCY_WORKER_PROPOSAL_CREATE)
  const [vmsAgencyWorkerProposalDelete] = useMutation(VMS_AGENCY_WORKER_PROPOSAL_DELETE)
  const [vmsShiftAssignProposedAgencyWorker] = useMutation(VMS_SHIFT_ASSIGN_PROPOSED_AGENCY_WORKER)

  const { loading, data } = useQuery(GET_VMS_AGENCY_WORKERS, {
    variables: {
      items: 25,
      name: workerName,
      page: 1,
    },
  })

  const { data: workerProposals, refetch: refetchWorkerProposals } = useQuery(GET_VMS_AGENCY_WORKER_PROPOSALS, {
    variables: {
      shiftId: Number(shift.id),
    },
  })

  const uniqueGrades = new Set()
  const shiftType = shift?.staffGroup?.title
  const workers = data?.vmsAgencyWorkers?.list.filter((i) => i?.staffGroup?.title === shiftType)

  if (workers) {
    workers.filter((el) => {
      uniqueGrades.add(el.grade)
    })
  }

  const gradeOptions = Array.from(uniqueGrades).map((cc) => ({
    label: cc.title,
    value: cc.id,
  }))

  const setWorkerAction = (worker) => {
    setWorker(worker)
    setModalStep(2)
  }

  const handleSingleInputChange = (event) => {
    setError({ error: false })
    event.persist()
    setSingleInputs((singleInputs) => ({
      ...singleInputs,
      [event.target.name]: event.target.value,
    }))
  }

  const handleSplitInputChange = (event) => {
    setError({ error: false })
    event.persist()
    setSplitInputs((splitInputs) => ({
      ...splitInputs,
      [event.target.name]: event.target.value,
    }))
  }

  const handleOptionalInputChange = (event) => {
    setError({ error: false })
    event.persist()
    setOptionalInputs((optionalInputs) => ({
      ...optionalInputs,
      [event.target.name]: event.target.value,
    }))
  }

  const assignAgencySingleAction = (e) => {
    setError({ error: false })
    if (singleInputs.core_total_rate <= 0) {
      setError({
        ...error,
        single_core_total_rate: true,
        message: 'Please enter the total rate.',
      })
    } else if (singleInputs.core_locum_rate <= 0) {
      setError({
        ...error,
        single_core_locum_rate: true,
        message: 'Please enter the workers rate.',
      })
    } else if (singleInputs.core_agency_margin <= 0) {
      setError({
        ...error,
        single_core_agency_margin: true,
        message: 'Please enter the agency margin.',
      })
    } else if (singleInputs.core_ni_contribution <= 0) {
      setError({
        ...error,
        single_core_ni_contribution: true,
        message: 'Please enter the NI contribution.',
      })
    } else {
      setShiftLoading(true)
      vmsAgencyWorkerProposalCreate({
        variables: {
          agencyWorkerId: Number(worker.id),
          shiftId: Number(shift.id),
          coreTotalRate: parseFloat(singleInputs.core_total_rate),
          coreLocumRate: parseFloat(singleInputs.core_locum_rate),
          coreAgencyMargin: parseFloat(singleInputs.core_agency_margin),
          coreNiContribution: parseFloat(singleInputs.core_ni_contribution),
          directEngagement: singleDirectEngagement,
          onFramework: singleFrameWorkerCompliant,
          requisitionNumber: optionalInputs.requisition_number,
          poNumber: optionalInputs.po_number,
          receiptNumber: optionalInputs.receipt_number,
          comment: optionalInputs.comment,
          splitRate: false,
        },
      }).then((data) => {
        setShiftLoading(false)
        if (data.data.vmsAgencyWorkerProposalCreate.success === true) {
          fetchShift(shift.id)
          setWorker(undefined)
          refetchWorkerProposals()
          setWorkerName('')
          setModalStep(1)
          setTimeout(() => {
            fetchShifts()
          }, 300)
          alert('Success! Worker Proposal Sent')
        } else {
          setError({
            ...error,
            main: true,
            message: 'There was a problem assigning the agency worker, please try again.',
          })
        }
      })
    }
  }

  const assignAgencySplitAction = (e) => {
    setError({ error: false })
    if (splitInputs.core_total_rate <= 0) {
      setError({
        ...error,
        split_core_total_rate: true,
        message: 'Please enter the total rate.',
      })
    } else if (splitInputs.unsocial_total_rate <= 0) {
      setError({
        ...error,
        split_unsocial_total_rate: true,
        message: 'Please enter the unsocial total rate.',
      })
    } else if (splitInputs.core_locum_rate <= 0) {
      setError({
        ...error,
        split_core_locum_rate: true,
        message: 'Please enter the workers rate.',
      })
    } else if (splitInputs.unsocial_locum_rate <= 0) {
      setError({
        ...error,
        split_unsocial_locum_rate: true,
        message: 'Please enter the unsocial workers rate.',
      })
    } else if (splitInputs.core_ni_contribution <= 0) {
      setError({
        ...error,
        split_core_ni_contribution: true,
        message: 'Please enter the NI contribution.',
      })
    } else if (splitInputs.unsocial_ni_contribution <= 0) {
      setError({
        ...error,
        split_unsocial_ni_contribution: true,
        message: 'Please enter the unsocial NI contribution.',
      })
    } else if (splitInputs.core_agency_margin <= 0) {
      setError({
        ...error,
        split_core_agency_margin: true,
        message: 'Please enter the agency margin.',
      })
    } else if (splitInputs.unsocial_agency_margin <= 0) {
      setError({
        ...error,
        split_unsocial_agency_margin: true,
        message: 'Please enter the unsocial agency margin.',
      })
    } else {
      setShiftLoading(true)
      vmsAgencyWorkerProposalCreate({
        variables: {
          agencyWorkerId: Number(worker.id),
          shiftId: Number(shift.id),
          coreTotalRate: parseFloat(splitInputs.core_total_rate),
          unsocialTotalRate: parseFloat(splitInputs.unsocial_total_rate),
          coreLocumRate: parseFloat(splitInputs.core_locum_rate),
          unsocialLocumRate: parseFloat(splitInputs.unsocial_locum_rate),
          coreAgencyMargin: parseFloat(splitInputs.core_agency_margin),
          unsocialAgencyMargin: parseFloat(splitInputs.unsocial_agency_margin),
          coreNiContribution: parseFloat(splitInputs.core_ni_contribution),
          unsocialNiContribution: parseFloat(splitInputs.unsocial_ni_contribution),
          directEngagement: splitDirectEngagement,
          onFramework: singleFrameWorkerCompliant,
          requisitionNumber: optionalInputs.requisition_number,
          poNumber: optionalInputs.po_number,
          receiptNumber: optionalInputs.receipt_number,
          comment: optionalInputs.comment,
          splitRate: true,
        },
      }).then((data) => {
        setShiftLoading(false)
        if (data.data.vmsAgencyWorkerProposalCreate.success === true) {
          fetchShift(shift.id)
          setWorker(undefined)
          refetchWorkerProposals()
          setWorkerName('')
          setModalStep(1)
          setTimeout(() => {
            fetchShifts()
          }, 300)
          alert('Success! Worker Proposal Sent')
        } else {
          setError({
            ...error,
            main: true,
            message: 'There was a problem assigning the agency worker, please try again.',
          })
        }
      })
    }
  }

  const removeAgencyWorkerProposalAction = (proposalId) => {
    setError({ error: false })
    setShiftLoading(true)
    vmsAgencyWorkerProposalDelete({
      variables: {
        agencyWorkerProposalId: Number(proposalId),
      },
    }).then((data) => {
      setShiftLoading(false)
      if (data.data.vmsAgencyWorkerProposalDelete.success === true) {
        fetchShift(shift.id)
        refetchWorkerProposals()
        setTimeout(() => {
          setSelectedTab('vmsProposeAgencyWorker')
          fetchShifts()
        }, 300)
      } else {
        setError({
          ...error,
          main: true,
          message: 'There was a problem removing the agency worker, please try again.',
        })
      }
    })
  }

  const assignProposedAgencyWorkerAction = (proposalId) => {
    setError({ error: false })
    setShiftLoading(true)
    vmsShiftAssignProposedAgencyWorker({
      variables: {
        agencyWorkerProposalId: Number(proposalId),
      },
    }).then((data) => {
      setShiftLoading(false)
      if (data.data.vmsShiftAssignProposedAgencyWorker.success === true) {
        fetchShift(shift.id)
        refetchWorkerProposals()
        setTimeout(() => {
          setSelectedTab('detailsReadOnly')
          fetchShifts()
        }, 300)
      } else {
        setError({
          ...error,
          main: true,
          message: 'There was a problem assigning the agency worker, please try again.',
        })
      }
    })
  }

  const inputFunction = (e) => {
    setWorkerName(e.target.value)
  }

  return (
    <>
      {worker && (
        <ShiftModalDetailFooter>
          <div>
            {error && error.main && (
              <ErrorContainer>
                <p>{error.message}</p>
              </ErrorContainer>
            )}
          </div>
          <div>
            <ShiftModalButtonClose onClick={handleClose}>Close</ShiftModalButtonClose>
            {modalStep === 2 ? (
              agencyTab === 'single' ? (
                <ShiftModalButton onClick={() => assignAgencySingleAction()} theme="blue">
                  Confirm Propose Worker
                </ShiftModalButton>
              ) : (
                <ShiftModalButton onClick={() => assignAgencySplitAction()} theme="blue">
                  Confirm Propose Worker
                </ShiftModalButton>
              )
            ) : (
              ''
            )}
          </div>
        </ShiftModalDetailFooter>
      )}
      <ShiftModalContainer locked={!worker}>
        {modalStep === 1 ? (
          <>
            <h2>Propose Agency Worker for Shift</h2>
            {workerProposals?.vmsAgencyWorkerProposals.length > 0 && (
              <ShiftModalDetailGroup>
                <p>To propose another worker select a worker below.</p>
                {workerProposals.vmsAgencyWorkerProposals.map((proposal, index) => (
                  <AgencyWorkerBox key={index}>
                    <div>
                      <AgencyWorkerBoxName>
                        {proposal.agencyWorker.firstName} {proposal.agencyWorker.lastName}
                      </AgencyWorkerBoxName>
                      <AgencyWorkerBoxGrade>
                        <strong>{proposal.agencyWorker.grade.title}</strong>, {proposal.agencyUser.firstName}{' '}
                        {proposal.agencyUser.lastName}
                      </AgencyWorkerBoxGrade>
                      <ProposalRates>
                        {proposal.directEngagement ? (
                          <h3 className="agency__green">Direct Engagement</h3>
                        ) : (
                          <h3 className="agency__red">Non-Direct Engagement</h3>
                        )}

                        {!proposal.splitRate ? (
                          <>
                            <p>
                              Total Rate: <span>£{proposal.coreTotalRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Worker Rate: <span> £{proposal.coreLocumRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              NI Contribution: <span> £{proposal.coreNiContribution.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Agency Margin: <span> £{proposal.coreAgencyMargin.toFixed(2)}/hr</span>
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              Total Rate: <span>£{proposal?.coreTotalRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Worker Rate: <span> £{proposal?.coreLocumRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              NI Contribution: <span> £{proposal?.coreNiContribution.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Agency Margin: <span> £{proposal?.coreAgencyMargin.toFixed(2)}/hr</span>
                            </p>
                            <hr />
                            <p>
                              Unsocial Total Rate: <span> £{proposal?.unsocialTotalRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Unsocial Worker Rate: <span> £{proposal?.unsocialLocumRate.toFixed(2)}/hr</span>
                            </p>
                            <p>
                              Unsocial NI Contribution:{' '}
                              <span>
                                £{proposal?.unsocialNiContribution.toFixed(2)}
                                /hr
                              </span>
                            </p>
                            <p>
                              Unsocial Agency fee:{' '}
                              <span>
                                £{proposal?.unsocialAgencyMargin.toFixed(2)}
                                /hr
                              </span>
                            </p>
                          </>
                        )}
                      </ProposalRates>
                    </div>
                    <div style={{ display: 'flex' }}>
                      {shift.availableForAgencyUserActions.includes('assign_worker') && (
                        <AgencyWorkerBoxRemove
                          onClick={() => {
                            assignProposedAgencyWorkerAction(proposal.id)
                          }}
                          style={{ background: '#4dd6ea', marginRight: 12 }}
                        >
                          Assign
                        </AgencyWorkerBoxRemove>
                      )}
                      <AgencyWorkerBoxRemove
                        onClick={() => {
                          removeAgencyWorkerProposalAction(proposal.id)
                        }}
                      >
                        Remove
                      </AgencyWorkerBoxRemove>
                    </div>
                  </AgencyWorkerBox>
                ))}
              </ShiftModalDetailGroup>
            )}
            <ShiftModalDetailGroup>
              <AssignWorkerContainer>
                <ShiftModalAssign
                  action={setWorkerAction}
                  actionPressText="Proposed"
                  actionText="Propose"
                  data={workers}
                  error={error}
                  gradeOptions={gradeOptions}
                  inputFunction={inputFunction}
                  inputValue={workerName}
                  label="Search Agency Workers"
                  loading={loading}
                  selectedValue={worker && worker.id}
                  type="worker"
                />
              </AssignWorkerContainer>
            </ShiftModalDetailGroup>
          </>
        ) : modalStep === 2 ? (
          <div>
            <h2>Propose Agency Worker for Shift</h2>
            {worker && (
              <AgencyWorkerBox style={{ marginBottom: 24 }}>
                <div>
                  <AgencyWorkerBoxName>
                    {worker.firstName} {worker.lastName}
                  </AgencyWorkerBoxName>
                  <AgencyWorkerBoxGrade>
                    {worker.grade.title}, {worker.agency?.title}
                  </AgencyWorkerBoxGrade>
                </div>
                <AgencyWorkerBoxRemove
                  onClick={() => {
                    setWorker(undefined)
                    setWorkerName('')
                    setModalStep(1)
                  }}
                >
                  Remove
                </AgencyWorkerBoxRemove>
              </AgencyWorkerBox>
            )}
            <ShiftModalDetailGroup>
              <AssignAgencyWorkerTabs>
                <button className={agencyTab === 'single' ? 'active' : ''} onClick={() => setAgencyTab('single')}>
                  Single Rate
                </button>
                <button className={agencyTab === 'split' ? 'active' : ''} onClick={() => setAgencyTab('split')}>
                  Split Core/Unsocial Rate
                </button>
              </AssignAgencyWorkerTabs>
              {agencyTab === 'single' && (
                <AgencyTabSingle
                  error={error}
                  handleSingleInputChange={handleSingleInputChange}
                  singleDirectEngagement={singleDirectEngagement}
                  singleFrameWorkerCompliant={singleFrameWorkerCompliant}
                  toggleSingleDirectEngagement={toggleSingleDirectEngagement}
                  toggleSingleFrameWorkerCompliant={toggleSingleFrameWorkerCompliant}
                  vms
                />
              )}
              {agencyTab === 'split' && (
                <AgencyTabSplit
                  error={error}
                  handleSplitInputChange={handleSplitInputChange}
                  splitDirectEngagement={splitDirectEngagement}
                  splitFrameWorkerCompliant={splitFrameWorkerCompliant}
                  toggleSplitDirectEngagement={toggleSplitDirectEngagement}
                  toggleSplitFrameWorkerCompliant={toggleSplitFrameWorkerCompliant}
                  vms
                />
              )}
            </ShiftModalDetailGroup>
            <ShiftModalDetailGroup>
              <AgencyOptionalInfo handleOptionalInputChange={handleOptionalInputChange} />
            </ShiftModalDetailGroup>
          </div>
        ) : (
          ''
        )}
      </ShiftModalContainer>
    </>
  )
}

export default ShiftModalVMSProposeAgencyWorker

const AssignWorkerContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 250px;
`

const AgencyWorkerBox = styled.div`
  align-items: center;
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  padding: 12px 24px;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const AgencyWorkerBoxRemove = styled.button`
  align-items: center;
  background: #ec7571;
  border: 0;
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 800;
  height: 32px;
  justify-content: center;
  text-transform: uppercase;
  width: 96px;
`

const AgencyWorkerBoxName = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const AgencyWorkerBoxGrade = styled.p`
  margin: 0;
`

const ProposalRates = styled.div`
  padding-top: 20px;

  p {
    line-height: 0.7;
  }
  span {
    font-weight: bold;
  }

  .agency__green {
    color: #209d1d;
    position: absolute;
    right: 20px !important;
    top: 15px !important;
  }
  .agency__red {
    color: #e45b5b;
    position: absolute;
    right: 20px !important;
    top: 15px !important;
  }
`
