/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/extensions */
import Sortable from 'sortablejs/modular/sortable.complete.esm.js'
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'
import Ajax from '../../frontend/packs/ajax'

export default class BatchExportConfigsListController extends Controller {
  static get targets() {
    return ['deleteButton', 'toggleDeButton']
  }

  connect() {
    this.initSortable()
  }

  baseUrl() {
    console.log('Base URL is:', this.data.get('base-url'))
    return this.data.get('base-url')
      ? this.data.get('base-url')
      : `/organisation_staff_groups/${this.orgStaffGroupId()}/batch_export_config`
  }

  deleteConfig(e) {
    const ajax = new Ajax()
    const configId = e.currentTarget.id.split('-')[1]
    const url = `${this.baseUrl()}/remove_column`
    ajax.post(url, { column: { id: configId } }, (data) => {
      data = JSON.parse(data)
      if (data.success) {
        document.getElementById(`batch_export_config_${data.column_id}`).remove()
      }
    })
  }

  toggleDeAction(e) {
    console.log('toggleDeAction()', e.target.value)

    const ajax = new Ajax()
    const currentValue = e.target.value
    const url = `${this.baseUrl()}/toggle_de_export`
    ajax.post(url, { column_id: currentValue }, (data) => {
      // Ensure correct params passed

      console.log('data_________________________________________', data)
      const result = JSON.parse(data)
      console.log('data', data)
      if (result.success) {
        // alert('Field has been toggled')

        // Update the button text
        const button = document.getElementById(`batch_agency_config_${currentValue}`)

        if (button.innerText === 'Enable Field') {
          button.innerText = 'Disable Field'
          button.classList.remove('de_enable_button')
          button.classList.add('de_disable_button')
        } else {
          button.innerText = 'Enable Field'
          button.classList.remove('de_disable_button')
          button.classList.add('de_enable_button')
        }

        console.log('button', button)
      } else {
        console.error('Toggling failed.')
      }
    })
  }

  initSortable() {
    console.log('initSortable()')
    const sort = Sortable.create(this.data.element, {
      handle: '.handle',
      onUpdate: () => {
        this.saveOrder()
      },
    })
  }

  saveOrder() {
    const order = this.deleteButtonTargets.map((e) => {
      return e.id.split('-')[1]
    })
    const url = `${this.baseUrl()}/reorder`
    const ajax = new Ajax()
    ajax.post(url, { order }, function () {})
  }

  orgStaffGroupId() {
    return this.data.get('org-staff-group-id')
  }
}

application.register('batch_export_configs_list', BatchExportConfigsListController)
