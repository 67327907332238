export async function createRegBody(body) {
  const url = `/regulation_bodies`

  const data = fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}

export async function editRegBody(regBodyId, body) {
  const url = `/regulation_bodies/${regBodyId}`

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}