import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class RequesterSelectorController extends SearchController {

    static get targets() {
        return ['select']
    }

    connect() {
        window.qs = queryString
        this.element[this.identifier] = this
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');
        const closeOnSelect = this.data.get('keep-open') ? false : true
        
        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select requesters',
            closeOnSelect: closeOnSelect,
            onChange: (info) => {
                this.storeFilters('requester_ids[]', info)
                if (searchOnSelect) {
                    this.multiSearchOnSelect('requester_ids[]', info)
                }
            }
        })

        if (this.data.get('initial-value')) {
            this.select.set(this.data.get('initial-value'))
        }
    }
}

import { application } from '../../frontend/controllers/index'
import queryString from "query-string";
import SearchController from "../search_controller";
application.register("requester_selector", RequesterSelectorController)