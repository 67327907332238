import React, { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import { useQuery } from "@apollo/client";

import DepartmentPermissionContext from "./context";
import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
  FormToggleComponent,
  FormToggleContainer,
  SuccessContainer,
} from "../Forms/FormElements";
import {
  CloseButton,
  LoadingContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";

import {
  createDepartment,
  editDepartment,
  editDepartmentContactDetails,
  GET_DEPARTMENT,
  GET_DEPARTMENT_INFO,
} from "./DepartmentsHelpers";
import { FormLabel } from "react-bootstrap";

const DepartmentsModal = ({
  handleClose,
  show,
  locked,
  department,
  organisation,
  refetch,
}) => {
  const permission = useContext(DepartmentPermissionContext);
  const [errors, setErrors] = useState({ name: "" });
  const [submitLoading, setSubmitLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    paymentApproverEmail: "",
    number: "",
    colour: "#000000",
    primarySpecialityOptions: [],
    primarySpecialityChosen: null,
    specialityOptions: [],
    specialityChosen: [],
    siteOptions: [],
    siteChosen: [],
    divisionChosen: null,
    costValue: null,
    associatedValuesChosen: [],
    cutoffAlert: "",
    cutoffAgency: "",
    autoApprove: false,
    emailOnCancellation: false,
    emailOnBooking: false,
    emailOnSignoff: false,
    signatureForShiftsSignOff: false,
    enhanced: false,
    signOff: false,
    earlySignOff: false,
    bookLowerGrade: false,
    lowerPayGrade: false,
    lowerInstantBook: false,
    bookHigher: false,
    higherPayGrade: false,
    secondaryBank: false,
    instantBookings: false,
    excludeRpa: false,
    covidRating: false,
    externalName: "",
    shiftReleaseOverride: false,
    shiftReleaseOption: {
      label: "",
      value: "",
    },
    timedReleaseDays: "",
  });

  const { data, loading } = useQuery(GET_DEPARTMENT, {
    variables: {
      id: Number(department?.id),
    },
  });

  const { data: data1 } = useQuery(GET_DEPARTMENT_INFO, {
    variables: {
      orgId: Number(organisation?.id),
    },
  });

  useEffect(() => {
    if (data && data1) {
      setInputs({
        name: data.department?.name ? data.department?.name : "",
        email: data.department?.email ? data.department?.email : "",
        paymentApproverEmail: data.department?.paymentApproverEmail ? data.department?.paymentApproverEmail : "",
        number: data.department?.number ? data.department?.number : "",
        colour: data.department?.colour ? data.department?.colour : "",
        primarySpecialityChosen: data.department?.primarySpeciality
          ? {
            label: data.department?.primarySpeciality.title,
            value: data.department?.primarySpeciality.id
          }
          : null,
        primarySpecialityOptions: data1.specialities
          ? data1.specialities.map((group) => {
            return {
              label: group.title,
              value: group.id,
            };
          })
          : [],
        specialityChosen: data.department?.specialities
          ? data.department?.specialities.map((group) => {
            return {
              label: group.title,
              value: group.id,
            };
          })
          : [],
        specialityOptions: data1.specialities
          ? data1.specialities.map((group) => {
            return {
              label: group.title,
              value: group.id,
            };
          }).filter((group) => {
            return group.value != data.department?.primarySpeciality?.id
          })
          : [],
        siteChosen: data.department?.sites
          ? data.department?.sites.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],

        siteOptions: data1.organisation?.sites
          ? data1.organisation?.sites.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],
        divisionChosen:  data.department?.division
          ?  {label: data.department.division.name, value: data.department.division.id } : [],

        divisonOptions: data1?.organisation?.divisions
          ? data1.organisation.divisions.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],
        costCentreOptions: data1.organisation?.costCentres
          ? data1.organisation?.costCentres.map((group) => {
            return {
              label: group.code,
              value: group.id,
            };
          })
          : [],
        costValue: {
          label: data.department?.costCentre?.code
            ? data.department?.costCentre.code
            : "",
          value: data.department?.costCentre?.id
            ? data.department?.costCentre.id
            : "",
        },
        associatedValuesChosen: data.department?.associatedCostCentres
          ? data.department?.associatedCostCentres.map((group) => {
            return {
              label: group.code,
              value: group.id,
            };
          })
          : [],
        // Department Preferences
        cutoffAlert: data.department?.departmentsPreference?.cutOffAlert
          ? data.department?.departmentsPreference?.cutOffAlert
          : "",
        cutoffAgency: data.department?.departmentsPreference?.cutOffAgency
          ? data.department?.departmentsPreference?.cutOffAgency
          : "",
        autoApprove: data.department?.departmentsPreference?.autoApprove
          ? data.department?.departmentsPreference.autoApprove
          : false,
        emailOnCancellation: data.department?.departmentsPreference
          ?.emailOnCancellation
          ? data.department?.departmentsPreference.emailOnCancellation
          : false,
        emailOnBooking: data.department?.departmentsPreference?.emailOnBooking
          ? data.department?.departmentsPreference.emailOnBooking
          : false,
        emailOnSignoff: data.department?.departmentsPreference?.emailOnSignOff
          ? data.department?.departmentsPreference.emailOnSignOff
          : false,
        enhanced: data.department?.departmentsPreference?.isEnhanced
          ? data.department?.departmentsPreference?.isEnhanced
          : false,
        signOff: data.department?.departmentsPreference?.allowAppSignOff
          ? data.department?.departmentsPreference.allowAppSignOff
          : false,
        earlySignOff: data.department?.departmentsPreference
          ?.allowEarlyAppSignOff
          ? data.department?.departmentsPreference.allowEarlyAppSignOff
          : false,
        signatureForShiftsSignOff: data.department?.departmentsPreference?.signatureForShiftsSignOff
          ? data.department?.departmentsPreference.signatureForShiftsSignOff
          : false,

        // Lower Grade Booking
        bookLowerGrade: data.department?.departmentsPreference?.bookLower
          ? data.department?.departmentsPreference.bookLower
          : false,
        lowerPayGrade: data.department?.departmentsPreference?.lowerPayToGrade
          ? data.department?.departmentsPreference.lowerPayToGrade
          : false,

        lowerInstantBook: data.department?.departmentsPreference
          ?.lowerGradeSelfBook
          ? data.department?.departmentsPreference.lowerGradeSelfBook
          : false,

        // Higher Grade Booking
        bookHigher: data.department?.departmentsPreference?.bookHigher
          ? data.department?.departmentsPreference.bookHigher
          : false,
        higherPayGrade: data.department?.departmentsPreference?.higherPayToGrade
          ? data.department?.departmentsPreference.higherPayToGrade
          : false,

        // Collab Bank
        secondaryBank: data.department?.departmentsPreference
          ?.autoReleaseToSecondaryBank
          ? data.department?.departmentsPreference.autoReleaseToSecondaryBank
          : false,
        instantBookings: data.department?.departmentsPreference?.instantBookingForCollabBanks ? data.department?.departmentsPreference.instantBookingForCollabBanks
          : false,

        // RPA
        excludeRpa: data.department?.departmentsPreference?.excludeFromRpa
          ? data.department?.departmentsPreference.excludeFromRpa
          : false,
        externalName: data.department?.externalName
          ? data.department?.externalName
          : "",

        // covid rating
        covidRating: data.department?.covidRating
          ? data.department?.covidRating
          : false,

        // timed release
        shiftReleaseOverride:
          data.department?.departmentsPreference?.shiftReleaseOverride ?? false,
        shiftReleaseOption: {
          label:
            data.department?.departmentsPreference?.shiftReleaseOption ===
              "AUTO_RELEASE" ||
              data.department?.departmentsPreference?.shiftReleaseOption === "0"
              ? "Auto Release"
              : data.department?.departmentsPreference?.shiftReleaseOption ===
                "TIMED_RELEASE" ||
                data.department?.departmentsPreference?.shiftReleaseOption === "1"
                ? "Timed Release"
                : "Manual Release",
          value:
            data.department?.departmentsPreference?.shiftReleaseOption ===
              "AUTO_RELEASE"
              ? "0"
              : data.department?.departmentsPreference?.shiftReleaseOption ===
                "TIMED_RELEASE"
                ? "1"
                : "2",
        },
        timedReleaseDays:
          data.department?.departmentsPreference?.timedReleaseDays ?? "",
      });
    }

    // For creating a dept we will only have to populate dropdown options
    if (department == undefined && data1) {
      setInputs((inputs) => ({
        ...inputs,
        primarySpecialityOptions: data1.specialities
          ? data1.specialities.map((group) => {
            return {
              label: group.title,
              value: group.id,
            };
          })
          : [],
        specialityOptions: data1.specialities
          ? data1.specialities.map((group) => {
            return {
              label: group.title,
              value: group.id,
            };
          }).filter((group) => {
            return group.value != data?.department?.primarySpeciality?.id
          })
          : [],
        siteOptions: data1.organisation?.sites
          ? data1.organisation?.sites.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],
        costCentreOptions: data1.organisation?.costCentres
          ? data1.organisation?.costCentres.map((group) => {
            return {
              label: group.code,
              value: group.id,
            };
          })
          : [],
        divisonOptions: data1.organisation?.divisions
          ? data1.organisation?.divisions.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],
      }));
    }
  }, [data, data1]);

  const handleCloseAction = () => {
    setInputs({ title: "" });
    setErrors({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setErrors({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckboxInput = (event) => {
    setErrors({});
    event.persist();
    // Hub admins can toggle this. Details: BD-278
    const isSignatureShiftsSignOff = event.target.id === 'signatureForShiftsSignOff'
    const isNotLocked = isSignatureShiftsSignOff
      ? (permission['superAdmin?'] || permission['admin?'])
      : !locked

    if (isNotLocked) {
      setInputs((inputs) => ({
        ...inputs,
        [event.target.id]: !inputs[event.target.id],
      }));
    }

    if(!inputs.signOff) {
      setInputs((inputs) => ({
        ...inputs,
        signatureForShiftsSignOff: false,
      }));
    }
  };

  const updateDepartmentAction = (id, method) => {
    setErrors({});
    if (inputs.name === "") {
      setErrors({ ...errors, name: "Please enter a name." });
    } else if (inputs.email === "") {
      setErrors({ ...errors, email: "Please enter an email address" });
    } else if (inputs.number === "") {
      setErrors({ ...errors, number: "Please enter a Number" });
    } else if (inputs.number.length < 10 || inputs.number.length > 15) {
      setErrors({ ...errors, number: "Please enter a Number with 10-15 digits" });
    } else if (inputs.siteChosen.length < 1) {
      setErrors({ ...errors, site_ids: "Please Select a site" });
    } else if (inputs.costValue === null) {
      setErrors({ ...errors, costCentre: "Please Select a cost centre" });
    } else if (inputs.cutoffAlert === "") {
      setErrors({ ...errors, alert: "Enter a value" });
    } else if (inputs.cutoffAgency === "") {
      setErrors({ ...errors, agency: "Enter a value" });
    } else if (inputs.colour === "") {
      setErrors({ ...errors, colour: "Please Select a Colour" });
    } else {
      setSubmitLoading(true);
      const body = {
        department: {
          name: inputs.name,
          dept_email: inputs.email,
          payment_approver_email: inputs.paymentApproverEmail,
          dept_number: inputs.number,
          colour: inputs.colour,
          site_ids: inputs.siteChosen
            ? inputs.siteChosen.map((site) => site.value)
            : [],
          division_id: inputs.divisionChosen ? inputs.divisionChosen.value : "",
          cost_centre_id: inputs.costValue ? inputs.costValue.value : "",
          associated_cost_centre_ids: inputs.associatedValuesChosen
            ? inputs.associatedValuesChosen.map((cc) => cc.value)
            : [],
          primary_speciality_id: inputs.primarySpecialityChosen ? inputs.primarySpecialityChosen.value : "",
          speciality_ids: inputs.specialityChosen
            ? inputs.specialityChosen.map((cc) => cc.value)
            : [],
          // DepartmentPreferences
          cut_off_alert: inputs.cutoffAlert,
          cut_off_agency: inputs.cutoffAgency,
          auto_approve: inputs.autoApprove,
          email_on_cancellation: inputs.emailOnCancellation,
          email_on_booking: inputs.emailOnBooking,
          enhanced: inputs.enhanced,
          allow_app_sign_off: inputs.signOff,
          allow_early_app_sign_off: inputs.earlySignOff,
          email_on_sign_off: inputs.emailOnSignoff,
          signature_for_shifts_sign_off: inputs.signatureForShiftsSignOff,

          // Lower Booking
          can_worker_book_lower_shift_grade: inputs.bookLowerGrade,
          pay_at_worker_grade_if_worker_is_higher_than_shift:
            inputs.lowerPayGrade,
          lower_grade_self_book: inputs.lowerInstantBook,
          // higher Booking
          can_worker_book_higher_shift_grade: inputs.bookHigher,
          pay_at_worker_grade_if_worker_is_lower_than_shift:
            inputs.higherPayGrade,
          // Collab Bank
          auto_release_to_secondary_bank: inputs.secondaryBank,
          instant_booking_for_collab_banks: inputs.instantBookings,
          //  RPA
          exclude_from_rpa: inputs.excludeRpa,
          external_name: inputs.externalName,
          // covid rating
          covid_rating: organisation["covid_rating?"]
            ? inputs.covidRating === "NOT_APPLICABLE"
              ? "not_applicable"
              : inputs.covidRating === "LOW"
                ? "low"
                : inputs.covidRating === "MEDIUM"
                  ? "medium"
                  : inputs.covidRating === "HIGH"
                    ? "high"
                    : inputs.covidRating
            : undefined,
          // timed_release
          shift_release_override: inputs.shiftReleaseOverride,
          shift_release_option: Number(inputs.shiftReleaseOption.value),
          timed_release_days: Number(inputs.timedReleaseDays),
        },
      };

      // Check whether to send signature param. If we aren't using PIN for the org sending the param as false
      // will cause problems
      if (organisation[`uses_pin_sign_off?`]) {
        body.department.signature_for_shifts_sign_off = inputs.signatureForShiftsSignOff
      }

      if (method == "create") {
        createDepartment(body).then((res) => {
          if (res == "ok") {
            setTimeout(() => {
              setSubmitLoading(false);
              setSuccess(true);
              refetch();
              handleCloseAction();
            }, 400);
          } else {
            setSubmitLoading(false);
            setErrors({
              error: true,
              message: `${res}`,
            });
          }
        });
      } else if (method == "update") {
        editDepartment(id, body).then((res) => {
          if (res == "ok") {
            setTimeout(() => {
              setSubmitLoading(false);
              setSuccess(true);
              refetch();
              handleCloseAction();
            }, 400);
          } else {
            setSubmitLoading(false);
            setErrors({
              error: true,
              message: `${res}`,
            });
          }
        });
      } else {
        editDepartmentContactDetails(id, body).then((res) => {
          if (res == "ok") {
            setTimeout(() => {
              setSubmitLoading(false);
              setSuccess(true);
              refetch();
              handleCloseAction();
            }, 400);
          } else {
            setSubmitLoading(false);
            setErrors({
              error: true,
              message: `${res}`,
            });
          }
        });
      }
    }
  };

  const covidTranslation = (data) => {
    if (data === "NOT_APPLICABLE") {
      return {
        label: "Not Applicable",
        value: "not_applicable",
      };
    }
    if (data === "LOW") {
      return {
        label: "Low",
        value: "low",
      };
    }
    if (data === "MEDIUM") {
      return {
        label: "Medium",
        value: "medium",
      };
    }
    if (data === "HIGH") {
      return {
        label: "High",
        value: "high",
      };
    }
  };

  const signatureForShiftsSignOffClassName = `${inputs.signatureForShiftsSignOff === true ? "on" : ""} ${permission['superAdmin?'] || permission['admin?'] ? "" : "disabled"}`

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="xl"
    >
      <ModalHeader>
        <ModalTitle>
          {department ? `${department.name}` : "Create Department"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http:www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading || submitLoading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <ModalBody style={{ minHeight: 0, overflowY: "scroll" }}>
          <DepartmentForm>
            <FormDetailGroup>
              <h2>Department Information</h2>
              <FormGrid columns={3} full>
                <FormGroup
                  component={"input"}
                  value={inputs.name}
                  placeholder={"Enter Dept. Title"}
                  label={"Department title"}
                  name="name"
                  onChange={handleInputChange}
                  type="text"
                  error={errors.name}
                  disabled={locked}
                />
                <FormGroup
                  component={"input"}
                  value={inputs.email}
                  placeholder={"Enter Email"}
                  label={"Email Address"}
                  name="email"
                  onChange={handleInputChange}
                  type="text"
                  error={errors.email}
                />
                <FormGroup
                  component={"input"}
                  value={inputs.number}
                  placeholder={"No."}
                  label={"Phone No."}
                  name="number"
                  onChange={handleInputChange}
                  type="text"
                  error={errors.number}
                />
              </FormGrid>
              <FormGrid columns={3} full>
                <FormGroup
                  component={"react-select"}
                  label={"Sites"}
                  options={inputs.siteOptions}
                  isClearable
                  isMulti={true}
                  placeholder="Select Site"
                  value={inputs.siteChosen}
                  error={errors.site_ids}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      siteChosen: option === null ? [] : option,
                    }));
                  }}
                  disabled={locked}
                />
                <FormGroup
                  component={"react-select"}
                  label={"Primary speciality"}
                  options={inputs.specialityOptions}
                  isClearable
                  placeholder="Select primary speciality"
                  isMulti={false}
                  value={inputs.primarySpecialityChosen}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      primarySpecialityChosen: option === null ? null : option,
                      specialityOptions: inputs.primarySpecialityOptions.filter((group) => {
                        return option === null ? true : group.value != option.value
                      }),
                      specialityChosen: inputs.specialityChosen.filter((group) => {
                        return option === null ? true : group.value != option.value
                      })
                    }));
                  }}
                  disabled={locked}
                />
                <FormGroup
                  component={"react-select"}
                  label={"Specialities"}
                  options={inputs.specialityOptions}
                  isClearable
                  isMulti={true}
                  value={inputs.specialityChosen}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      specialityChosen: option === null ? [] : option,
                    }));
                  }}
                  disabled={locked}
                />
              </FormGrid>
              <FormGrid columns={3} full>
                <FormGroup
                  component={"input"}
                  value={inputs.colour}
                  placeholder={""}
                  label={"Colour"}
                  name="colour"
                  onChange={handleInputChange}
                  type="color"
                  error={errors.colour}
                  disabled={locked}
                />
                <FormGroup
                  component={"input"}
                  value={inputs.paymentApproverEmail}
                  placeholder={"Enter Email"}
                  label={"Payment Approver Email Address"}
                  name="paymentApproverEmail"
                  onChange={handleInputChange}
                  type="text"
                  error={errors.paymentApproverEmail}
                />
                <FormGroup
                  component={"react-select"}
                  label={"Division"}
                  options={inputs.divisonOptions}
                  isClearable
                  placeholder="Select Division"
                  value={inputs.divisionChosen}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      divisionChosen: option === null ? [] : option,
                    }));
                  }}
                  disabled={locked}
                />
              </FormGrid>
            </FormDetailGroup>
            <FormDetailGroup>
              <h2>Cost Centre</h2>
              <FormGrid columns={3} full>
                <FormGroup
                  component={"react-select"}
                  label={"Default Cost Centre"}
                  options={inputs.costCentreOptions}
                  isClearable
                  placeholder="Select organisation"
                  value={inputs.costValue}
                  error={errors.costCentre}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      costValue: option === null ? [] : option,
                    }));
                  }}
                  disabled={locked}
                />
                <FormGroup
                  component={"react-select"}
                  label={"Associated Cost Centres"}
                  options={inputs.costCentreOptions}
                  isClearable
                  isMulti={true}
                  placeholder="Select Overide"
                  value={inputs.associatedValuesChosen}
                  onChange={(option) => {
                    setErrors({});
                    setInputs((inputs) => ({
                      ...inputs,
                      associatedValuesChosen: option === null ? [] : option,
                    }));
                  }}
                  disabled={locked}
                />
              </FormGrid>
            </FormDetailGroup>
            <FormDetailGroup>
              <h2>Department Preferences</h2>
              <FormGrid columns={3} full>
                <FormGroup
                  component={"input"}
                  value={inputs.cutoffAlert}
                  placeholder={""}
                  label={'Shift Becomes "urgent"app (days)'}
                  name="cutoffAlert"
                  onChange={handleInputChange}
                  type="number"
                  error={errors.alert}
                  disabled={locked}
                />
                <FormGroup
                  component={"input"}
                  value={inputs.cutoffAgency}
                  placeholder={""}
                  label={"Agency kick-out period cut-off(days)"}
                  name="cutoffAgency"
                  onChange={handleInputChange}
                  type="number"
                  error={errors.agency}
                  disabled={locked}
                />
              </FormGrid>
              <FormGrid columns={3} full>
                <FormToggleContainer>
                  <p>
                    Auto approve shifts if clinician is associated to department
                  </p>
                  <FormToggleComponent
                    className={`${inputs.autoApprove === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="autoApprove"
                    value={inputs.autoApprove}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Always email on cancellation</p>
                  <FormToggleComponent
                    className={`${inputs.emailOnCancellation === true ? "on" : ""
                      } ${locked ? "disabled" : ""}`}
                    onClick={handleCheckboxInput}
                    id="emailOnCancellation"
                    value={inputs.emailOnCancellation}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Always email on booking</p>
                  <FormToggleComponent
                    className={`${inputs.emailOnBooking === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="emailOnBooking"
                    value={inputs.emailOnBooking}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
              </FormGrid>
              <FormGrid columns={3} full>
                <FormToggleContainer>
                  <p>Enhanced department</p>
                  <FormToggleComponent
                    className={`${inputs.enhanced === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="enhanced"
                    value={inputs.enhanced}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Enable locum app shift sign off</p>
                  <FormToggleComponent
                    className={`${inputs.signOff === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="signOff"
                    value={inputs.signOff}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Locum app sign-off allowed before shift end time</p>
                  <FormToggleComponent
                    className={`${inputs.earlySignOff === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="earlySignOff"
                    value={inputs.earlySignOff}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
              </FormGrid>
              <FormGrid columns={3} full>
                <FormToggleContainer>
                  <p>Always email on locum app shift sign off</p>
                  <FormToggleComponent
                    className={`${inputs.emailOnSignoff === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="emailOnSignoff"
                    value={inputs.emailOnSignoff}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                {(organisation["uses_pin_sign_off?"] && inputs.signOff) && (
                  <FormToggleContainer>
                    <p>Signature for shift sign-off</p>
                    <FormToggleComponent
                      className={signatureForShiftsSignOffClassName}
                      onClick={handleCheckboxInput}
                      id="signatureForShiftsSignOff"
                      value={inputs.signatureForShiftsSignOff}
                    >
                      <button />
                    </FormToggleComponent>
                  </FormToggleContainer>
                )}
              </FormGrid>
            </FormDetailGroup>
            <FormDetailGroup>
              <h2>Lower Grade Booking(Booking'down')</h2>
              <FormGrid columns={3} full>
                <FormToggleContainer>
                  <p>Can clinician book lower to their grade?</p>
                  <FormToggleComponent
                    className={`${inputs.bookLowerGrade === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="bookLowerGrade"
                    value={inputs.bookLowerGrade}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Pay to clinician grade?</p>
                  <FormToggleComponent
                    className={`${inputs.lowerPayGrade === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="lowerPayGrade"
                    value={inputs.lowerPayGrade}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Allow Instant Booking?</p>
                  <FormToggleComponent
                    className={`${inputs.lowerInstantBook === true ? "on" : ""
                      } ${locked ? "disabled" : ""}`}
                    onClick={handleCheckboxInput}
                    id="lowerInstantBook"
                    value={inputs.lowerInstantBook}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
              </FormGrid>
            </FormDetailGroup>
            <FormDetailGroup>
              <h2>Higher Grade Booking (Booking 'up')</h2>
              <FormGrid columns={3} full>
                <FormToggleContainer>
                  <p>Can clinician book higher to their grade?</p>
                  <FormToggleComponent
                    className={`${inputs.bookHigher === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="bookHigher"
                    value={inputs.bookHigher}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
                <FormToggleContainer>
                  <p>Pay to clinician grade?</p>
                  <FormToggleComponent
                    className={`${inputs.higherPayGrade === true ? "on" : ""} ${locked ? "disabled" : ""
                      }`}
                    onClick={handleCheckboxInput}
                    id="higherPayGrade"
                    value={inputs.higherPayGrade}
                  >
                    <button />
                  </FormToggleComponent>
                </FormToggleContainer>
              </FormGrid>
            </FormDetailGroup>
            {/* Disable if Collab Bank is not active*/}
            {organisation["in_collaborative_bank?"] && (
              <FormDetailGroup>
                <h2>Collaborative Bank</h2>
                <FormGrid columns={3} full>
                  <FormToggleContainer>
                    <p>Override default shift release settings?</p>
                    <FormToggleComponent
                      className={`${inputs.shiftReleaseOverride === true ? "on" : ""
                        } ${locked ? "disabled" : ""}`}
                      onClick={handleCheckboxInput}
                      id="shiftReleaseOverride"
                      value={inputs.shiftReleaseOverride}
                    >
                      <button />
                    </FormToggleComponent>
                  </FormToggleContainer>
                  {inputs.shiftReleaseOverride && (
                    <FormGroup
                      component={"react-select"}
                      label={"Shift Release Options"}
                      options={[
                        { label: "Auto Release", value: "0" },
                        { label: "Timed Release", value: "1" },
                        { label: "Manual Release", value: "2" },
                      ]}
                      isClearable
                      placeholder={"Select Option"}
                      value={
                        inputs.shiftReleaseOption
                          ? inputs.shiftReleaseOption
                          : null
                      }
                      onChange={(option) => {
                        setErrors({});
                        setInputs((inputs) => ({
                          ...inputs,
                          shiftReleaseOption: option === null ? null : option,
                        }));
                      }}
                      disabled={locked}
                    />
                  )}
                  {inputs.shiftReleaseOption?.value === "1" && (
                    <FormGroup
                      component={"input"}
                      value={inputs.timedReleaseDays}
                      placeholder={"No. of Days..."}
                      label={"Days to release before shift start"}
                      name="timedReleaseDays"
                      onChange={handleInputChange}
                      type="number"
                      error={errors.timedReleaseDays}
                    />
                  )}
                </FormGrid>
                <FormGrid columns={3} full>
                  <div>
                    <FormLabel>Instant Bookings</FormLabel>
                    <FormToggleContainer>
                      <p>Allow Instant Booking for collab banks?</p>
                      <FormToggleComponent
                        className={`${inputs.instantBookings === true ? "on" : ""
                          } ${locked ? "disabled" : ""}`}
                        onClick={handleCheckboxInput}
                        id="instantBookings"
                        value={inputs.instantBookings}
                      >
                        <button />
                      </FormToggleComponent>
                    </FormToggleContainer>
                  </div>
                </FormGrid>
              </FormDetailGroup>
            )}
            {organisation["rpa?"] && (
              <FormDetailGroup>
                <h2>RPA</h2>
                <FormGrid columns={3} full>
                  <FormToggleContainer>
                    <p>Exclude department from RPA</p>
                    <FormToggleComponent
                      className={`${inputs.excludeRpa === true ? "on" : ""} ${locked ? "disabled" : ""
                        }`}
                      onClick={handleCheckboxInput}
                      id="excludeRpa"
                      value={inputs.excludeRpa}
                    >
                      <button />
                    </FormToggleComponent>
                  </FormToggleContainer>
                  <FormGroup
                    component={"input"}
                    value={inputs.externalName}
                    placeholder={"Name will be used if blank"}
                    label={"EXTERNAL NAME - OPTIONAL MAPPING"}
                    name="externalName"
                    onChange={handleInputChange}
                    type="text"
                    disabled={locked}
                  />
                </FormGrid>
              </FormDetailGroup>
            )}
            {organisation["covid_rating?"] && (
              <FormDetailGroup>
                <h2>COVID Risk</h2>
                <FormGrid columns={3} full>
                  <FormGroup
                    component={"react-select"}
                    menuPlacement={"top"}
                    label={"COVID Risk Level"}
                    options={[
                      {
                        label: "Not Applicable",
                        value: "not_applicable",
                      },
                      {
                        label: "Low",
                        value: "low",
                      },
                      {
                        label: "Medium",
                        value: "medium",
                      },
                      {
                        label: "High",
                        value: "high",
                      },
                    ]}
                    isClearable
                    placeholder="Select risk level"
                    value={covidTranslation(inputs.covidRating)}
                    error={errors.covidRating}
                    onChange={(option) => {
                      setErrors({});
                      setInputs((inputs) => ({
                        ...inputs,
                        covidRating: option === null ? null : option.value,
                      }));
                    }}
                  />
                </FormGrid>
              </FormDetailGroup>
            )}
          </DepartmentForm>
        </ModalBody>
      )}
      <ModalFooter>
        <div>
          {success && (
            <SuccessContainer>{department?.name} Updated</SuccessContainer>
          )}
          {errors && errors.message && (
            <ErrorContainer>
              <p>{errors.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleCloseAction}>
            Cancel
          </button>

          {!!!department?.trashedAt && (
            department ? (
              <button
                style={{ pointerEvents: success ? "none" : "pointer" }}
                className="btn text-white btn-cyan--modal"
                onClick={() => updateDepartmentAction(department.id, locked ? "update_contact" : "update")}
              >
                Save Changes
              </button>
            ) : (
              <button
                className="btn text-white btn-cyan--modal"
                onClick={() => updateDepartmentAction(null, "create")}
              >
                Create Department
              </button>
            )
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DepartmentsModal;

const DepartmentForm = styled.div`
  max-width: 900px;
`;
