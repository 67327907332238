import { Controller } from 'stimulus'
import Huebee from 'huebee'

export default class ColorPickerController extends Controller {
  static get targets() {
    return ['field'];
  }

  connect() {
    this.regexp = new RegExp('#[0-9a-fA-F]{6}');
    this.initPicker();
  }

  initPicker() {
    this.ValidateFieldValue();
    var hueb = new Huebee( this.fieldTarget, {
      notation: 'hex'
    });
  }

  ValidateFieldValue() {
    if(this.regexp.test(this.fieldTarget.value)) return;

    this.fieldTarget.value = '#000000';
  }
}
import { application } from '../../frontend/controllers/index'

application.register("color_picker", ColorPickerController)
