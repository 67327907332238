import { stringify } from 'qs'
import { Controller } from 'stimulus'

// setup new array
var deparmentIds = []

export default class BookingPreferencesController extends Controller {

  static get targets() {
    return ['departmentsAll', 'departmentsData', 'departmentsIds', 'departmentsShow', 'departmentsSpecific']
  }

  connect() {
    if (this.departmentsAllTarget.checked === true) {
      this.departmentsShowTarget.style.opacity = 0.5
      this.departmentsShowTarget.style.pointerEvents = "none"
    }
  }

  click() {
    if (this.departmentsAllTarget.checked === true) {
      this.departmentsShowTarget.style.opacity = 0.5
      this.departmentsShowTarget.style.pointerEvents = "none"
      // select all options in department selector
      Array.from(document.getElementById('department-selector-instant-book').options).map((option) => {
        option.selected = true
      })
    } else {
      this.departmentsShowTarget.style.opacity = 1
      this.departmentsShowTarget.style.pointerEvents = "all"
      document.getElementById('department-selector-instant-book').name = "worker[department_ids][]"
    }
  }
}

import { application } from '../../frontend/controllers/index'

application.register("booking_preferences", BookingPreferencesController)