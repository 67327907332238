import { gql } from "@apollo/client";
import { AgencyWorkerFragment } from "../Containers/VMS/AgencyLocums/AgencyLocumHelpers";

export async function fetchWorkers(workerName) {
  const name = workerName;
  const query = `
    query Workers($name: String){
      workers(name: $name){
        id
        firstName
        lastName
        email
        grade {
          title
        }
      }
    }
  `;
  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { name },
    }),
  }).then((response) => {
    if (response.ok) return response.json();
    else return "err";
  });
  return data;
}

export async function assignWorker(shiftId, workerId) {
  const query = `
    mutation AssignWorker($shiftId: Int!, $workerId: Int!){
      assignWorker(shiftId: $shiftId, workerId: $workerId){
        errors {
          attribute
          message
        }
        shift {
          id
        }
      }
    }
  `;
  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { shiftId, workerId },
    }),
  }).then((response) => {
    if (response.ok) return response.json();
    else return "err";
  });
  return data;
}

export const GET_VMS_AGENCY_WORKERS = gql`
  query GetVmsAgencyWorkers(
    $name: String
    $page: Int
    $items: Int
    $staffGroupId: Int
  ) {
    vmsAgencyWorkers(
      name: $name
      page: $page
      items: $items
      staffGroupId: $staffGroupId
    ) {
      ...AgencyWorkerFragment
    }
  }
  ${AgencyWorkerFragment}
`;
