import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import Pagy from '../../../Helpers/Pagy'
import { TableRowEditIcon } from '../../Assets/Icons'
import { ErrorContainer, ShiftModalLabel, ShiftModalReactSelect } from '../../Forms/FormElements'
import { Loading } from '../../Modals/ModalElements'
import {
  TableContainer,
  TableHeaderContainer,
  TableHeaderWrapper,
  TableSearchInput,
  TableComponent,
} from '../../Tables/TableElements'
import { GET_AGENCY_WORKERS, GET_VMS_AGENCY_WORKERS, GET_ORGANISATION_STAFF_GROUPS } from './AgencyLocumHelpers'
import AgencyLocumModal from './AgencyLocumModal'

function AgencyLocums({ agencies, canCreateAgencyWorkers, organisationId, vms }) {
  const [agencyId, setAgencyId] = useState(undefined)
  const [agencyWorker, setAgencyWorker] = useState(undefined)
  const [name, setName] = useState(undefined)
  const [show, setShow] = useState(false)
  const [page, setPage] = useState(1)
  const [staffGroupId, setStaffGroupId] = useState(undefined)

  const { loading, error, data, refetch } = useQuery(vms ? GET_VMS_AGENCY_WORKERS : GET_AGENCY_WORKERS, {
    variables: {
      agencyId,
      items: vms ? null : 25,
      name,
      page: vms ? null : page,
      staffGroupId,
    },
  })

  const { data: organisationStaffGroups } = useQuery(GET_ORGANISATION_STAFF_GROUPS, {
    variables: {
      organisationId: Number(organisationId),
    },
  })

  // remap agencies into an array react-select can read
  const orgAgencies = agencies?.map((agency) => {
    return { value: agency.id, label: agency.title }
  })

  // remap staffGroups into an array react-select can read
  const orgStaffGroups = organisationStaffGroups?.organisationStaffGroups.map((staffGroup) => {
    return { value: staffGroup.staffGroup.idValue, label: staffGroup.title }
  })

  const handleClose = () => {
    setAgencyWorker(undefined)
    setShow(false)
  }

  const handleShowCreate = () => {
    setAgencyWorker(undefined)
    setShow(true)
  }

  const handleShowEdit = (agencyWorker) => {
    setAgencyWorker(agencyWorker)
    setShow(true)
  }

  const handleAgencyFilterChange = (event) => {
    if (event === null) {
      setAgencyId(undefined)
    } else {
      setAgencyId(Number(event.value))
    }
  }

  const handleStaffGroupFilterChange = (event) => {
    if (event === null) {
      setStaffGroupId(undefined)
    } else {
      setStaffGroupId(Number(event.value))
    }
  }

  const agencyWorkers = vms ? data?.vmsAgencyWorkers.edges : data?.agencyWorkers.list
  const pagination = vms ? null : data?.agencyWorkers.pagination

  return (
    <>
      <TableContainer style={{ marginTop: '-95px' }}>
        <TableHeaderWrapper>
          <div className="shift-table__container">
            <TableHeaderContainer>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 20 }}>
                  <ShiftModalLabel style={{ color: '#fff' }}>Search Workers</ShiftModalLabel>
                  <TableSearchInput
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Search Agency Worker..."
                    type="text"
                    value={name}
                  />
                </div>
                <div>
                  {vms ? (
                    <>
                      <ShiftModalLabel style={{ color: '#fff' }}>Staff Group</ShiftModalLabel>
                      <FilterDropdown
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                        onChange={handleStaffGroupFilterChange}
                        options={orgStaffGroups}
                        placeholder="Please select..."
                      />
                    </>
                  ) : (
                    <>
                      <ShiftModalLabel style={{ color: '#fff' }}>Agency</ShiftModalLabel>
                      <FilterDropdown
                        className="react-select-container"
                        classNamePrefix="react-select"
                        isClearable
                        onChange={handleAgencyFilterChange}
                        options={orgAgencies}
                        placeholder="Please select..."
                      />
                    </>
                  )}
                </div>
              </div>

              <div>
                {canCreateAgencyWorkers && (
                  <>
                    <ShiftModalLabel />
                    <button className="btn btn-cyan" onClick={handleShowCreate} style={{ flexShrink: 0 }} type="submit">
                      <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
                        <path
                          clipRule="evenodd"
                          d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                          fill="#1E2D36"
                          fillRule="evenodd"
                        />
                      </svg>{" "}
                      Create Agency Worker
                    </button>
                  </>
                )}
              </div>
            </TableHeaderContainer>
          </div>
        </TableHeaderWrapper>
        {loading ? (
          <Loading />
        ) : error ? (
          <ErrorContainer>
            There was an error loading the Agency Workers.
          </ErrorContainer>
        ) : (
          <div className="shift-table__container">
            <TableComponent striped>
              <thead>
                <tr>
                  <th>Worker Name</th>
                  {!vms && <th>Agency</th>}
                  <th>Staff Group</th>
                  <th>Grade</th>
                  <th>Registration Body Number</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {agencyWorkers?.map(({ node: agencyWorker }, index) => (
                  <tr key={index}>
                    <td>
                      <a onClick={() => handleShowEdit(agencyWorker)}>
                        {agencyWorker.firstName} {agencyWorker.lastName}
                      </a>
                    </td>
                    {!vms && <td>{agencyWorker.agency?.title}</td>}
                    <td>
                      {agencyWorker.grade?.organisationStaffGroup?.title}
                    </td>
                    <td>{agencyWorker.grade?.title}</td>
                    <td>{agencyWorker.regBodyNumber}</td>
                    <td style={{ textAlign: "right" }}>
                      {canCreateAgencyWorkers && (
                        <button
                          className="btn btn-text"
                          onClick={() => handleShowEdit(agencyWorker)}
                        >
                          <TableRowEditIcon />
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableComponent>
            {!vms && <Pagy pagination={pagination} setPage={setPage} />}
          </div>
        )}
      </TableContainer>
      {show && (
        <AgencyLocumModal
          agencies={orgAgencies}
          agencyWorker={agencyWorker}
          canCreateAgencyWorkers={canCreateAgencyWorkers}
          handleClose={handleClose}
          orgStaffGroups={orgStaffGroups}
          refetch={refetch}
          show={show}
          vms={vms}
        />
      )}
    </>
  )
}

export default AgencyLocums

const FilterDropdown = styled(ShiftModalReactSelect)`
  min-width: 200px;
`
