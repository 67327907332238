import queryString from 'query-string'
import SlimSelect from 'slim-select'
import { application } from '../../frontend/controllers/index'
import SearchController from '../search_controller'

export default class AgencyWorkerPickerSamController extends SearchController {
  static get targets() {
    return ['select']
  }

  connect() {
    window.qs = queryString

    this.element[this.identifier] = this
    this.queryString = queryString.parse(window.location.search)

    this.select = new SlimSelect({
      closeOnSelect: false,
      onChange: (info) => {
        this.storeFilters(`${this.element.dataset.selectName}[]`, info)
      },
      placeholder: 'Select agency worker(s)',
      select: this.selectTarget,
    })
  }
}

application.register('agency_worker_picker_sam', AgencyWorkerPickerSamController)
