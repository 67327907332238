import {Controller} from 'stimulus'

export default class PaymentReceiptsTableController extends Controller {

    static get targets() {
        return ['selectedReceiptsOutput']
    }

    connect() {
        this.selectedReceipts = []
    }
}

import {application} from '../../frontend/controllers/index'

application.register("payment_receipts_table", PaymentReceiptsTableController)
