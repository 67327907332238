import SlimSelect from 'slim-select'

export default class WorkerNameSelectorController extends SearchController {
    static get targets() {
        return ['label', 'select']
    }

    connect() {
        window.qs = queryString;
        this.element[this.identifier] = this
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');

        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select worker',
            searchingText: 'Searching...',
            // allowDeselect: true,
            ajax: this.workerQuery(),
            onChange: (info) => {
                this.storeFilters('worker_id', [info])
                if (searchOnSelect) {
                    this.singleSearchOnSelect('worker_id', info)
                }
            }
        });

        if (this.data.get('initial-value')) {
            this.select.set(this.data.get('initial-value'))
        }
    }

    workerQuery() {
        const internal = this.data.get('internal');
        if (internal) {
            function set_workers_data(workers) {
                let data = [];

                const workerSuspended = '<span class="filter__worker-suspended"></span>';

                for (let i = 0; i < workers.length; i++) {
                    let worker = workers[i];

                    // ' (' + worker.preferredName + ')'
                    let workerSummary =  `${worker.firstName} ${worker.lastName}  ${worker.preferredName !== null ? `(${worker.preferredName})` : ""}`;
                    if (worker.onlySecondaryBank) {
                        workerSummary += '<span class="badge badge-type  badge-secondary-bank  badge-secondary-bank-dropdown">Secondary</span>'
                    }

                    workerSummary += worker.grade ? ' (' + worker.grade.title + ')' : ' (missing grade)';

                    if (worker.suspended) {
                        workerSummary += workerSuspended
                    }

                    data.push(
                        {
                            text: workerSummary,
                            value: worker.id
                        })
                }
                return data;
            }

            return function (search, callback) {
                const query = `query Workers($name: String, $page: Int!, $items: Int!){
                    workers(name: $name, page: $page, items: $items){
                        id
                        firstName
                        lastName
                        email
                        suspended
                        preferredName
                        onlySecondaryBank
                        grade {
                            title
                        }
                    }
                }`;

                const page = 1;
                const items = 10;

                // Check search value. If you dont like it callback(false) or callback('Message String')
                if (search.length < 3) {
                    callback('Need 3 characters')
                    return
                }

                let name = search;

                // Perform your own ajax request here
                fetch('/graphql', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                    },
                    body: JSON.stringify({
                        query,
                        variables: { name, page, items },
                    })
                })
                    .then(function (response) {
                        return response.json()
                    })
                    .then(function (json) {
                        let workers = json['data']['workers'];
                        let data = set_workers_data(workers);

                        // Upon successful fetch send data to callback function.
                        // Be sure to send data back in the proper format.
                        // Refer to the method setData for examples of proper format.
                        callback(data)
                    })
                    .catch(function (error) {
                        // If any errors happened send false back through the callback
                        callback(false)
                    })
            };
        }
    }
}
import { application } from '../../frontend/controllers/index'
import queryString from "query-string";
import SearchController from "../search_controller";

application.register("worker_name_selector", WorkerNameSelectorController)