import { Controller } from 'stimulus'

export default class MountainViewController extends Controller {
    toggle() {
        const components = document.getElementsByClassName('mv-component__description')
        for (let component of components) {
            component.classList.toggle('d-none')
        }
    }
}
