import { gql } from "@apollo/client";

export const GET_ORG_STAFF_GROUP_SKILLS = gql`
  query GetOrgStaffGroupSkills($organisationStaffGroupId: Int!) {
    orgStaffGroupSkills(organisationStaffGroupId: $organisationStaffGroupId) {
      id
      skill {
        id
        name
      }
    }
  }
`;

export const ADD_SKILLS_TO_ORG_STAFF_GROUP = gql`
  mutation AddSkillToOrgStaffGroup(
    $skillId: Int!
    $organisationStaffGroupId: Int!
  ) {
    addSkillToOrgStaffGroup(
      skillId: $skillId
      organisationStaffGroupId: $organisationStaffGroupId
    ) {
      orgStaffGroupsSkill {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const DELETE_ORG_STAFF_GROUPS_SKILL = gql`
  mutation DeleteOrgStaffGroupsSkill($id: Int!) {
    deleteOrgStaffGroupsSkill(id: $id) {
      success
      errors {
        attribute
        message
      }
    }
  }
`;
