/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const successToast = {
  render: 'Success',
  type: 'success',
  isLoading: false,
  autoClose: 3000,
  position: 'top-right',
}
export const errorToast = {
  render: 'An error occurred',
  type: 'error',
  isLoading: false,
  autoClose: 3000,
  position: 'top-right',
}

export const loadingToast = {
  position: 'top-right',
  toastId: 2,
}

export const PAY_PER_ITEM_ITEMS = gql`
  query paidPerItemItems($departmentId: Int, $gradeId: Int, $items: Int, $organisationStaffGroupId: Int, $page: Int) {
    paidPerItemItems(
      departmentId: $departmentId
      gradeId: $gradeId
      items: $items
      organisationStaffGroupId: $organisationStaffGroupId
      page: $page
    ) {
      list {
        id
        name
        createdAt
        department {
          id
          name
          externalName
          site {
            name
          }
        }
        itemGradeCosts {
          id
          cost
          grade {
            id
            title
          }
        }
        organisation {
          id
          name
        }
        organisationStaffGroup {
          id
        }
        ppiAuditEvents {
          createdAt
          eventType
          userDetails
          description
        }
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
`

export const PAID_PER_ITEM_CREATE_ITEM = gql`
  mutation paidPerItemCreateItem(
    $departmentId: Int!
    $name: String!
    $organisationId: Int!
    $organisationStaffGroupId: Int!
    $itemGradeCostsAttributes: [ItemGradeCostInput!]!
  ) {
    paidPerItemCreateItem(
      departmentId: $departmentId
      name: $name
      organisationId: $organisationId
      organisationStaffGroupId: $organisationStaffGroupId
      itemGradeCostsAttributes: $itemGradeCostsAttributes
    ) {
      errors {
        message
        attribute
      }
      item {
        id
      }
    }
  }
`

export const PAID_PER_ITEM_UPDATE_ITEM = gql`
  mutation paidPerItemUpdateItem(
    $id: Int!
    $departmentId: Int!
    $organisationId: Int!
    $organisationStaffGroupId: Int!
    $itemGradeCostsAttributes: [ItemGradeCostInput!]!
  ) {
    paidPerItemUpdateItem(
      id: $id
      departmentId: $departmentId
      organisationId: $organisationId
      organisationStaffGroupId: $organisationStaffGroupId
      itemGradeCostsAttributes: $itemGradeCostsAttributes
    ) {
      errors {
        message
        attribute
      }
      item {
        id
      }
    }
  }
`

export const PAID_PER_ITEM_DELETE_ITEM = gql`
  mutation paidPerItemDeleteItem($id: Int!) {
    paidPerItemDeleteItem(id: $id) {
      success
      errors {
        message
      }
    }
  }
`

export const STAFF_GROUPS = gql`
  query GetStaffGroups($organisationId: Int!) {
    organisationStaffGroups(organisationId: $organisationId) {
      id
      title
      grades {
        value: id
        label: title
      }
    }
  }
`
