import SearchController from "../search_controller";
// import flatpickr from "flatpickr";
// import queryString from 'query-string'
// import moment from "moment";

class InputFilterController extends SearchController {
  static targets = ["filterName", "input"]

  onChange() {
    console.log('connected')
    this.storeFilters(this.filterNameTarget.value, [{ value: this.inputTarget.value }])
  }
}

import { application } from '../../frontend/controllers/index'
application.register("input_filter", InputFilterController);