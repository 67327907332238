import PropTypes from 'prop-types'
import React from 'react'

function EmailForm({ state, setState }) {
  const { email } = state
  return (
    <div>
      <label className="reset-pin__label" htmlFor="name">
        {/* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/label-has-associated-control.md#case-the-label-is-a-sibling-of-the-control */}
        {/* eslint-disable-line jsx-a11y/label-has-associated-control */}
        Email<span>*</span>
      </label>
      <input
        className="reset-pin__input"
        id="email"
        onChange={(e) => setState({ ...state, email: e.target.value })}
        placeholder="Email"
        required
        type="email"
        value={email}
      />
    </div>
  )
}

EmailForm.propTypes = {
  state: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
}

export default EmailForm
