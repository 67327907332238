import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { client } from '../../../packs/application'
import BlockBookings from './BlockBookings'
import 'react-toastify/dist/ReactToastify.css'

function BlockBookingsIndex({
  agencyWorkers,
  bankWorkers,
  modOrganisation,
  organisation,
  user,
  canCancelBooking,
  canManuallySignOff,
  canManageProposals,
  canApproveShifts,
  canOverrideBreakDuration,
  blockTimesheets,
  canShortlistProposals,
  canOnlyViewProposals,
  usesDeContracts,
}) {
  console.log('user', canManageProposals, canShortlistProposals)
  console.log('uses_de_contracts', usesDeContracts)
  return (
    <Router>
      <ToastContainer position="top-center" transition={Slide} />

      <ApolloProvider client={client}>
        <BlockBookings
          agencyWorkers={agencyWorkers}
          bankWorkers={bankWorkers}
          canCancelBooking={canCancelBooking}
          canManageProposals={canManageProposals}
          canShortlistProposals={canShortlistProposals}
          canOnlyViewProposals={canOnlyViewProposals}
          modOrganisation={modOrganisation}
          organisation={organisation}
          timesheets={{
            timesheet: blockTimesheets,
            canApproveShifts,
            canManuallySignOff,
            canOverrideBreakDuration,
          }}
          user={user}
          usesDeContracts={usesDeContracts}
        />
      </ApolloProvider>
    </Router>
  )
}

export default BlockBookingsIndex
