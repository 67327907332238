import React from "react";
import Select from "react-select";
import styled from "styled-components";

export const ModalContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
  }
`;

export const ModalContentContainer = styled.div`
  max-height: 800px;
  min-height: 800px;
  overflow-y: auto;
  padding: 30px;
  position: relative;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  background: #ff6c6c;
  border: 1px solid #bd4848;
  border-radius: 6px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const SuccessContainer = styled.div`
  background: rgba(139, 230, 138, 0.5);
  border: 1px solid #338532;
  border-radius: 6px;
  color: #338532;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const ShiftModalDetailFooter = styled.div`
  align-items: center;
  background: #fff;
  border-bottom-right-radius: 0.5rem;
  border-top: 1px solid #cbd2d9;
  bottom: 0;
  display: flex;
  height: 100px;
  justify-content: space-between;
  padding: 0 24px;
  position: absolute;
  right: 1px;
  width: calc(1038px - 210px);
  z-index: 1;
`;

export const ShiftModalContainer = styled.div`
  margin-bottom: ${(props) => (props.locked ? "" : "-30px")};
  margin-left: ${(props) => (props.locked ? "" : "-30px")};
  margin-right: ${(props) => (props.locked ? "" : "-30px")};
  margin-top: ${(props) => (props.locked ? "" : "-30px")};
  max-height: ${(props) => (props.locked ? "" : "700px")};
  overflow: ${(props) => (props.locked ? "" : "auto")};
  padding: ${(props) => (props.locked ? "" : "30px")};
`;

export const ShiftModalMenu = styled.div`
  background: #eceff3;
  border-bottom-left-radius: 0.5rem;
  border-right: 1px solid #cbd2d9;
  padding: 15px 0;
  position: relative;
  z-index: 2;

  ul {
    list-style: none;
    padding: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    min-width: 210px;
  }
`;

export const ShiftModalButtonClose = styled.button`
  background: transparent;
  border: 0;
  box-shadow: none;
  color: ${(props) => (props.color === "red" ? "#FF6C6C" : "#9CA8B4")};
  font-size: 16px;
  font-weight: 700;
  margin-right: 30px;
  padding: 0;
`;

export const ShiftModalButton = styled.button`
  background: ${(props) =>
    props.theme === "red"
      ? "#FF6C6C"
      : props.theme === "white"
      ? "#fff"
      : props.theme === "blue"
      ? "#4DD6EA"
      : "#000"};
  border: 1px solid
    ${(props) =>
      props.theme === "red"
        ? "#BD4848"
        : props.theme === "white"
        ? "rgba(0,0,0,0.25)"
        : props.theme === "blue"
        ? "#43B5C6"
        : "#000"};
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  color: ${(props) => (props.color === "red" ? "#FF6C6C" : "#fff")};
  font-size: 18px;
  font-weight: 700;
  height: 48px;
  padding: 0 20px;
  width: max-content;
`;

export const ShiftModalMenuOption = styled.button`
  background: transparent;
  border: 0;
  box-shadow: none;
  font-size: 16px;
  font-weight: 400;
  margin: 1px 0;
  padding: 7px 25px;
  text-align: left;
  width: 100%;

  &.active {
    background: #f5ddd5;
    border-left: 2px solid #ff7f4d;
    font-weight: 500;
    padding-left: 23px;
  }

  &:hover {
    background: #f5ddd5;
    // border-left: 2px solid #FF7F4D;
    // font-weight: 500;
    // padding-left: 23px;
  }
`;

export const ShiftModalDetailGroup = styled.div`
  padding-bottom: 25px;

  &:not(:last-of-type) {
    border-bottom: 4px solid #f0f2f4;
    margin-bottom: 25px;
  }
`;

export const ShiftModalInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShiftModalInput = styled.input`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36;
  font-size: 16px;
  height: 40px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;

  input[type=\'time\']::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none !important;
  }

  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

export const ShiftModalInputTest = styled.input`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36;
  font-size: 16px;
  height: 40px;
  padding-bottom: 3px;
  padding-left: 12px;
  padding-right: 12px;
  flex: 1;

  input[type=\'time\']::-webkit-calendar-picker-indicator {
    background: none !important;
    display: none !important;
  }

  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

export const ShiftInputTestWrapper = styled.div`
  display: flex;
  width: 100%;
  div {
    flex: 1;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #cbd2d9;
    border-left: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  }
`;

export const ShiftModalSelect = styled.select`
  -webkit-appearance: none;
  background: #ffffff;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(0, 0, 0, 0.03) 100%
  );
  border: 1px solid #cbd2d9;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36;
  font-size: 16px;
  height: 40px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

const ShiftModalLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100% !important;
`;

export const ShiftModalLabel = styled.label`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const ShiftModalTextarea = styled.textarea`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-size: 15px;
  height: 190px;
  line-height: 20px;
  padding: 12px 16px;

  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

const ShiftModalCheckbox = styled.div`
  align-items: center;
  background: #f5f7fa;
  border-radius: 4px;
  display: flex;
  padding: 16px;
  width: 100% !important;

  input {
    margin-right: 8px;
  }

  label {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    text-transform: capitalize;
  }

  button {
    align-items: center;
    background: #ffffff;
    border: 1px solid #cbd2d9;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 22px;
    justify-content: center;
    margin-right: 8px;
    width: 23px;

    &.on {
      background: #ff7f4d;
      border: 1px solid #ec7040;
      border-radius: 4px;
      box-shadow: none;
      padding: 0;
    }
  }
`;

export const ShiftModalFormGroup = ({
  charCount,
  children,
  component,
  defaultValue,
  disabled,
  error,
  label,
  name,
  onChange,
  options,
  placeholder,
  required,
  type,
  value,
}) => {
  return (
    <ShiftModalInputGroupContainer>
      {component === "checkbox" ? (
        <ShiftModalCheckbox>
          <input id={name} name={name} onChange={onChange} type={type} />
          <ShiftModalLabel
            htmlFor={name}
            style={{ fontWeight: 400, textTransform: "unset" }}
          >
            {label}
          </ShiftModalLabel>
        </ShiftModalCheckbox>
      ) : (
        <>
          <ShiftModalLabelContainer>
            <ShiftModalLabel>{label}</ShiftModalLabel>
            {charCount && (
              <ShiftModalLabel style={{ fontWeight: 400 }}>
                {charCount}
              </ShiftModalLabel>
            )}
          </ShiftModalLabelContainer>
          {component === "input" ? (
            <ShiftModalInput
              defaultValue={defaultValue}
              disabled={disabled}
              type={type}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
            />
          ) : component === "select" ? (
            <ShiftModalSelect
              onChange={onChange}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
            >
              {children}
            </ShiftModalSelect>
          ) : component === "textarea" ? (
            <ShiftModalTextarea
              defaultValue={defaultValue}
              disabled={disabled}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              required={required}
            />
          ) : component === "react-select" ? (
            <ShiftModalReactSelect
              className={`react-select-container ${disabled && "disabled"}`}
              classNamePrefix="react-select"
              onChange={onChange}
              placeholder={placeholder}
              required={required}
              name={name}
              defaultValue={defaultValue}
              options={options}
            />
          ) : component === "currency" ? (
            <ShiftModalInput
              defaultValue={defaultValue}
              disabled={disabled}
              type={type}
              name={name}
              onChange={onChange}
              min="0.01"
              step="0.01"
            />
          ) : component === "currency__test" ? (
            <ShiftInputTestWrapper>
              <ShiftModalInputTest
                defaultValue={defaultValue}
                disabled={disabled}
                type={type}
                name={name}
                onChange={onChange}
                min="0.01"
                step="0.01"
              />
              <div>GBP</div>
            </ShiftInputTestWrapper>
          ) : component === "custom-checkbox" ? (
            <ShiftModalCheckbox>
              <button name={name} onClick={onChange} className={value === true ? "on" : ""}>
                {value === true ? (
                  <svg width="14" height="9" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.795 2.23L6.421 8.602a1.059 1.059 0 01-.749.314c-.27 0-.542-.107-.749-.314L1.205 4.885a1.049 1.049 0 010-1.498 1.049 1.049 0 011.498 0l2.97 2.964 5.624-5.62a1.049 1.049 0 011.498 0c.42.415.42 1.084 0 1.498z"
                      fill="#FFF"
                      fillRule="evenodd"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </button>{" "}
              <ShiftModalLabel onClick={onChange}>{label}</ShiftModalLabel>
            </ShiftModalCheckbox>
          ) : (
            children
          )}
        </>
      )}
      {error && <ShiftModalInputError>{error}</ShiftModalInputError>}
    </ShiftModalInputGroupContainer>
  );
};

export const ShiftModalFormGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  max-width: ${(props) => (props.full === true ? "100%" : "658px")};

  &:not(:last-of-type) {
    margin-bottom: 25px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;

    div {
      width: 33%;

      &:not(:last-child) {
        margin-right: 30px;
      }
    }
  }
`;

const ShiftModalReactSelect = styled(Select)`
  .react-select__control {
    -webkit-appearance: none;
    background: #ffffff;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    );
    border: 1px solid #cbd2d9;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    color: #1e2d36;
    font-size: 16px;
    height: 40px;
    overflow: hidden;
    padding-left: 12px;
    // padding-right: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    ::placeholder {
      color: #3e4d58;
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid #cbd2d9;
    }

    &:active,
    &:focus {
      border: 1px solid #1e2d36;
      box-shadow: 0 0 3px 0 #9ca8b4;
      outline: none;
    }
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option--is-focused {
    background-color: rgba(245, 221, 213, 0.25);
  }

  .react-select__option {
    &:hover {
      background-color: rgba(245, 221, 213, 0.25);
    }
  }

  .react-select__option--is-selected {
    background-color: #f5ddd5;
    color: #1e2d36;
  }

  .react-select__single-value {
    color: #1e2d36;
  }

  &.disabled {
    .react-select__control {
      pointer-events: none;
    }

    .react-select__single-value {
      color: #9ca8b4;
    }
  }
`;

const ShiftModalInputError = styled.span`
  color: #bd4848;
  margin-top: 5px;
`;
