import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class BatchExportConfigInputController extends Controller {

    static get targets() {
        return ['select','label']
    }

    connect() {
        const selectTarget = this.selectTarget;
        const labelTarget = this.labelTarget
        new SlimSelect({
            select: selectTarget,
            showSearch: false,
            placeholder: 'Select column to add to export',
            onChange: (info) => {
                labelTarget.value = info.text
            }

        })
    }
}

import { application } from '../../frontend/controllers/index'
import queryString from "query-string";
application.register("batch_export_config_input", BatchExportConfigInputController)