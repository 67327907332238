import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class PayElementEscalationController extends Controller {
  static get targets() {
    return ['escalateRate', 'escalatePercentage']
  }

  connect() {
    this.escalatePercentageTarget.style.display = 'none'
  }

  calculatePercentage() {
    const oldRate = this.escalateRateTarget.dataset.old_rate
    const newRate = this.escalateRateTarget.value
    const percentIncreased = ((newRate / oldRate) * 100 - 100).toFixed(2)

    this.escalatePercentageTarget.style.display = 'block'
    this.escalatePercentageTarget.innerText = `${percentIncreased}%`
  }
}
application.register('pay_element_escalation', PayElementEscalationController)
