import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../packs/application";

import AgencyTiers from "./AgencyTiers";

function AgencyTiersIndex({ organisationId }) {
  return (
    <ApolloProvider client={client}>
      <AgencyTiers organisationId={organisationId} />
    </ApolloProvider>
  );
}

export default AgencyTiersIndex;
