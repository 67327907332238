/* eslint-disable no-unsafe-optional-chaining */
import { useMutation } from '@apollo/client'
import {
  DefaultButton,
  FormBreak,
  FormGrid,
  FormErrorLabel,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  ModalFooter,
} from '@patchworkhealth/web-components'
import { useFormik } from 'formik'
import moment from 'moment'
import 'react-day-picker/lib/style.css'

import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as Yup from 'yup'
import { parseTimeInTZ } from '../../../Helpers/momentHelpers'
import {
  loadingToast,
  successToast,
  errorToast,
  securityClearance,
  SHIFT_BLOCK_UPDATE,
  footerStyling,
} from '../helpers/BlockBookingsHelpers'
import { ShiftModalTitle } from '../helpers/BlockComponents'
import { DateInput } from '../helpers/dateInput'
import WorkerRatesTable from './WorkerRatesTable'

function ViewBlockDetails({ handleClose, block, editBlock, details, blockData, refetch }) {
  const [shiftBlockUpdate] = useMutation(SHIFT_BLOCK_UPDATE)

  const checkDisabled = () => {
    return block.status !== 'DRAFT'
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      adminNotes: blockData?.adminNotes,
      name: block.name,
      reasonForBooking: blockData?.reasonForBooking,
      workerNotes: blockData?.workerNotes,
      startDate: blockData?.startDate,
      endDate: blockData?.endDate,
    },
    validationSchema: Yup.object({
      name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
    }),
    onSubmit: (values) => {
      toast.loading('Loading', loadingToast)

      shiftBlockUpdate({
        variables: {
          shiftBlockId: Number(block.id),
          shiftBlockAttributes: {
            name: values.name,
            adminNotes: values.adminNotes,
            workerNotes: values.workerNotes,
            reasonForBookingId: values.reasonForBooking.value,
            startDate: parseTimeInTZ(values.startDate).startOf('day').format('YYYY-MM-DDTHH:mm:00'),
            endDate: parseTimeInTZ(values.endDate).endOf('day').format('YYYY-MM-DDTHH:mm:00'),
          },
        },
      })
        .then((response) => {
          const errors = response.data?.shiftBlockUpdate?.errors

          if (errors.length > 0) {
            toast.update(2, { ...errorToast, render: errors[0].message })
            return
          }

          handleClose()
          refetch()
          toast.update(2, { ...successToast, render: 'Block updated' })
        })
        .catch(() => {
          toast.update(2, { ...errorToast, render: 'Block could not be updated' })
        })
    },
  })

  let chosenSecurity = []

  if (blockData?.modFinancialApprovalCode !== null) {
    chosenSecurity = securityClearance.filter((dd) => dd.value === blockData?.modSecurityClearance)
  }

  return (
    <FormGridContainer>
      <ShiftModalTitle>Block Info</ShiftModalTitle>
      <FormGrid columns={3} style={{ marginBottom: 28 }}>
        <FormGroup>
          <FormLabel>Block Name</FormLabel>
          <FormInput
            name="name"
            onChange={formik.handleChange}
            placeholder="Block Name"
            type="text"
            value={formik.values.name}
          />
          {formik.errors.name && <FormErrorLabel>{formik.errors.name}</FormErrorLabel>}
        </FormGroup>

        <FormGroup disabled={checkDisabled()}>
          <FormLabel>Start Date</FormLabel>
          <DateInput
            name="startDate"
            onChange={(e) => formik.setFieldValue('startDate', e)}
            range={{ before: new Date() }}
            value={formik.values.startDate}
          />
        </FormGroup>
        <FormGroup disabled={checkDisabled()}>
          <FormLabel>End Date</FormLabel>
          <DateInput
            error={formik.errors.endDate}
            name="endDate"
            onChange={(e) => formik.setFieldValue('endDate', e)}
            range={{ before: new Date() }}
            value={formik.values.endDate}
          />
        </FormGroup>
      </FormGrid>

      <FormGrid columns={3} style={{ marginBottom: 28 }}>
        <FormGroup>
          <FormLabel>Reason for Booking</FormLabel>
          <FormSelect
            onChange={(e) => formik.setFieldValue('reasonForBooking', e)}
            options={details && details.reasons.nodes}
            placeholder="Select reason"
            value={formik.values.reasonForBooking}
          />
        </FormGroup>

        <FormGroupDisable>
          <FormLabel>Number of Shifts in Block</FormLabel>
          <FormInput placeholder="Number of shifts" type="number" value={blockData?.shiftCount} />
        </FormGroupDisable>
      </FormGrid>

      <FormGrid columns={3}>
        <ShiftCard>
          <FormLabel>Total rate for block</FormLabel>
          <p>£{blockData?.totalRate?.toFixed(2)}</p>
        </ShiftCard>
        <ShiftCard>
          <FormLabel>Total hours in block</FormLabel>
          <p>{blockData?.totalHours.toFixed(2)} hours</p>
        </ShiftCard>
        <ShiftCard color="blue">
          <FormLabel>Avg rate / Hr</FormLabel>
          <p>{blockData?.totalHours < 1 ? ' - ' : <>£{(blockData?.totalRate / blockData?.totalHours).toFixed(2)}</>}</p>
        </ShiftCard>
      </FormGrid>
      <FormBreak />

      {/* Auto cascade stuff ----------------------------------------- */}

      {block.deContractVersionAssignment && (
        <>
          <ShiftModalTitle>DE Contract Info</ShiftModalTitle>

          {!block.deContractVersionAssignment.signedAt && (
            <FormLabel
              style={{
                border: '2px solid red',
                padding: '14px',
              }}
            >
              Worker has not yet signed their DE Contract for Block: {block.id}
            </FormLabel>
          )}

          {block.deContractVersionAssignment.signedAt && (
            <div
              style={{
                padding: '14px 0px 20px',
                margin: '10px 0px',
              }}
            >
              <FormLabel>Worker has signed the DE contract</FormLabel>

              <img
                alt="Signature"
                src={block.deContractVersionAssignment.signature}
                style={{
                  width: '200px',
                  height: '200px',
                  margin: '5px 0px  30px 0px',
                  border: '1px solid #e0e0e0',
                  display: 'block',
                }}
              />
              <button
                onClick={() => {
                  window.open(block.deContractVersionAssignment.contractFileUrl, '_blank')
                }}
                style={{
                  background: '#05405C',
                  padding: '12px',
                  color: 'white',
                  borderRadius: '5px',
                }}
                type="button"
              >
                Download Contract
              </button>

              <p style={{ marginTop: '20px' }}>
                Signed: {moment(block.deContractVersionAssignment.signedAt).format('DD MMM YYYY, HH:mm')}
              </p>
            </div>
          )}
          <FormBreak />
        </>
      )}

      {/* End of Auto Cascade ----------------------------------------- */}

      <ShiftModalTitle>Clinical Info</ShiftModalTitle>
      <FormGrid columns={3} style={{ marginBottom: 16 }}>
        <FormGroupDisable>
          <FormLabel>Staff Group</FormLabel>
          <FormSelect
            disabled
            options={details && details.organisation.organisationStaffGroups}
            placeholder="Select type"
            value={blockData?.staffGroup}
          />
        </FormGroupDisable>

        <FormGroupDisable>
          <FormLabel>Grade</FormLabel>
          <FormSelect
            disabled
            isMulti={false}
            options={details && details?.organisation.grades}
            placeholder="Select grade"
            value={blockData?.grade}
          />
        </FormGroupDisable>
      </FormGrid>
      {/* WORKER RATES */}
      <WorkerRatesTable blockData={blockData} />

      {/* MOD STUFF ******************************************* */}

      {blockData?.modFinancialApprovalCode !== null && (
        <>
          <FormBreak />
          <ShiftModalTitle>MOD Custom Fields</ShiftModalTitle>

          <FormGrid columns={2}>
            {blockData?.modAccommodationExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Accomodation Expenses Budget</FormLabel>
                <p>£{blockData?.modAccommodationExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}
            <ShiftCard>
              <FormLabel>Approved Weekly Hours</FormLabel>
              <p>{blockData?.modApprovalBudgetValue?.toFixed(2)}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Out of Hours - Estimated Weekly Hours</FormLabel>
              <p>{blockData?.modOutOfHoursEstimatedWeeklyHours}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Out of Hours - Budget</FormLabel>
              <p>{blockData?.modOutOfHoursBudget?.toFixed(2)}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Financial Approval Code</FormLabel>
              <p>{blockData?.modFinancialApprovalCode}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Position Number</FormLabel>
              <p>{blockData?.modPositionNumber}</p>
            </ShiftCard>

            <ShiftCard>
              <FormLabel>Security Clearance</FormLabel>
              <p>{chosenSecurity[0]?.label}</p>
            </ShiftCard>

            {blockData?.modSubsistenceExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Subsistence Expenses Budget</FormLabel>
                <p>£{blockData?.modSubsistenceExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}

            {blockData?.modTravelExpensesBudget !== null && (
              <ShiftCard>
                <FormLabel>Travel Expenses Budget</FormLabel>
                <p>£{blockData?.modTravelExpensesBudget?.toFixed(2)}</p>
              </ShiftCard>
            )}
          </FormGrid>
        </>
      )}

      <FormBreak />
      <ShiftModalTitle>Optional Info</ShiftModalTitle>
      <FormGrid columns={2}>
        <FormGroup>
          <FormLabel>Worker Notes (Visible in Worker App)</FormLabel>
          <FormInput
            as="textarea"
            name="workerNotes"
            onChange={formik.handleChange}
            placeholder="Enter notes here"
            rows={6}
            value={formik.values.workerNotes}
          />

          <p style={{ fontSize: 12, marginTop: 6 }}>
            eg. Bleep numbers, who and where to report to, logins for computers and access codes for doors.
          </p>
        </FormGroup>

        <FormGroup>
          <FormLabel>Admin notes (Visible only to Hub users)</FormLabel>
          <FormInput
            as="textarea"
            name="adminNotes"
            onChange={formik.handleChange}
            placeholder="Enter notes here"
            rows={6}
            value={formik.values.adminNotes}
          />
        </FormGroup>
      </FormGrid>
      <ModalFooter style={footerStyling}>
        <div />
        <div>
          <DefaultButton action={() => handleClose()} text="Cancel" type="white" />
          <DefaultButton action={() => formik.handleSubmit()} color="blue" text="Save Changes" />
        </div>
      </ModalFooter>
    </FormGridContainer>
  )
}

export default ViewBlockDetails

export const FormGroupDisable = styled.div`
  pointer-events: none;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    -ms-flex: 300px !important;
    margin-bottom: 16px !important;
    margin-right: 30px !important;
    width: '220px' !important;
  }
`

export const ShiftCard = styled.div`
  background: ${(props) => (props.color === 'blue' ? '#d1f2f7' : '#f5f7fa')};
  border: 1px solid ${(props) => (props.color === 'blue' ? '#17bfd6' : '#e4e7eb')};

  border-radius: 8px;
  height: auto;

  padding: 11px 16px;
  width: 100%;
  p {
    color: #414c58;
    font-size: 14px;
    margin-bottom: 0;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 10px;
  }
`
