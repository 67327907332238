import { useQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { ErrorContainer, ShiftModalReactSelect } from '../Forms/FormElements'
import { LoadingContainer } from '../Modals/ModalElements'
import { TableComponent, TableHeaderContainer, TableSearchInput } from '../Tables/TableElements'
import DepartmentPermissionContext from './context'
import { GET_DEPARTMENTS, DELETE_DEPARTMENT, RESTORE_DEPARTMENT } from './DepartmentsHelpers'
import DepartmentsModal from './DepartmentsModal'

function Departments({ locked, organisation, sites }) {
  const [name, setName] = useState(undefined)
  const [show, setShow] = useState(false)
  const [value, setValue] = useState('')
  const [department, setDepartment] = useState(undefined)
  const [siteIds, setSiteIds] = useState(undefined)
  const [siteValues, setSiteValues] = useState(undefined)
  const permission = useContext(DepartmentPermissionContext)
  const isSuperAdmin = permission['super_admin?']

  const [deleteDepartment] = useMutation(DELETE_DEPARTMENT)
  const [restoreDepartment] = useMutation(RESTORE_DEPARTMENT)
  const { loading, error, data, refetch } = useQuery(GET_DEPARTMENTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      siteIds,
      includeDeleted: true,
    },
  })

  const handleClose = () => {
    setDepartment(undefined)
    setShow(false)
  }

  const handleShowCreate = () => {
    setDepartment(undefined)
    setShow(true)
  }

  const handleDeleteDepartment = (department) => {
    if (confirm('Are you sure you want to delete this Department?')) {
      deleteDepartment({
        variables: {
          departmentId: department.id,
        },
      }).then((data) => {
        refetch()
      })
    }
  }

  const handleRestoreDepartment = (department) => {
    if (confirm('Are you sure you want to restore this Department?')) {
      restoreDepartment({
        variables: {
          departmentId: department.id,
        },
      }).then((data) => {
        refetch()
      })
    }
  }

  const handleShowEdit = (department) => {
    setDepartment(department && department)
    setShow(true)
  }

  const handleSitesChange = (selectedOptions) => {
    if (selectedOptions === null) {
      setSiteIds(undefined)
      setSiteValues(undefined)
    } else if (selectedOptions.length === 0) {
      setSiteIds(undefined)
      setSiteValues(undefined)
    } else {
      setSiteIds(selectedOptions.map((dep) => dep.value))
      setSiteValues(selectedOptions.map((dep) => dep))
    }
  }

  const handleSitesString = (array) => {
    let string = ''
    for (let x = 0; x < array.length; x++) {
      x == array.length - 1 ? (string += array[x].name) : (string += `${array[x].name} / `)
    }
    return string
  }

  // remap sites into an array react-select can read
  const sitesOptions = sites.map(function (site) {
    return { value: site.id, label: site.name }
  })

  // FilteredDepartments List
  const filteredDepartments = value
    ? data?.departments?.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase())
      })
    : data?.departments

  return (
    <>
      <DepartmentsContainer>
        <TableHeaderContainer>
          <div style={{ display: 'flex' }}>
            <TableSearchInput
              onChange={(e) => setValue(e.target.value)}
              placeholder="Search Departments..."
              type="text"
              value={value}
            />
            <FilterDropdown
              className="react-select-container"
              classNamePrefix="react-select"
              isClearable
              isMulti
              onChange={handleSitesChange}
              options={sitesOptions}
              placeholder="Choose a Site..."
              value={siteValues}
            />
          </div>
          <div style={{ display: 'flex' }}>
            {!locked && (
              <>
                <button className="btn btn-cyan" type="button">
                  <a href="departments/upload">
                    <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
                      <path
                        clipRule="evenodd"
                        d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                        fill="#1E2D36"
                        fillRule="evenodd"
                      />
                    </svg>{' '}
                    Upload departments
                  </a>
                </button>

                <button className="btn btn-cyan" onClick={handleShowCreate} style={{ marginLeft: 16 }} type="button">
                  <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
                    <path
                      clipRule="evenodd"
                      d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                      fill="#1E2D36"
                      fillRule="evenodd"
                    />
                  </svg>{' '}
                  Create Department
                </button>
              </>
            )}
          </div>
        </TableHeaderContainer>
        {loading ? (
          <LoadingContainer>
            <svg height="38" stroke="#ff7f4d" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
              <g fill="none" fillRule="evenodd">
                <g strokeWidth="2" transform="translate(1 1)">
                  <circle cx="18" cy="18" r="18" strokeOpacity=".5" />
                  <path d="M36 18c0-9.94-8.06-18-18-18">
                    <animateTransform
                      attributeName="transform"
                      dur="1s"
                      from="0 18 18"
                      repeatCount="indefinite"
                      to="360 18 18"
                      type="rotate"
                    />
                  </path>
                </g>
              </g>
            </svg>
          </LoadingContainer>
        ) : error ? (
          <ErrorContainer>Theres an error loading Departments.</ErrorContainer>
        ) : (
          <TableComponent striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Sites</th>
                <th>Date Created</th>
                <th />
                {isSuperAdmin && <th style={{ textAlign: 'center' }}>Delete/Restore</th>}
              </tr>
            </thead>
            <tbody>
              {filteredDepartments.map((department, index) => (
                <tr key={index}>
                  <td>{department.name}</td>
                  <td>{handleSitesString(department?.sites)}</td>
                  <td>{moment(department.createdAt).format('MMMM Do YYYY')}</td>
                  <td style={{ textAlign: 'right' }}>
                    <button onClick={() => handleShowEdit(department)}>
                      <svg
                        className="icon-shadow"
                        fill="none"
                        height="34"
                        width="34"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.182.5h21.636A5.682 5.682 0 0133.5 6.182v21.636a5.682 5.682 0 01-5.682 5.682H6.182A5.682 5.682 0 01.5 27.818V6.182A5.682 5.682 0 016.182.5z"
                          fill="#fff"
                          stroke="#CBD2D9"
                        />
                        <path
                          clipRule="evenodd"
                          d="M17 19.688c-1.749 0-3.167-1.428-3.167-3.188 0-1.76 1.418-3.188 3.167-3.188 1.749 0 3.167 1.428 3.167 3.188 0 1.76-1.418 3.188-3.167 3.188zm7.647-1.198l-.51-.297a.71.71 0 01-.346-.708 6.964 6.964 0 000-1.97c-.038-.287.096-.563.412-.747l.444-.258a.71.71 0 00.313-.85 8.143 8.143 0 00-.567-1.265 8.287 8.287 0 00-.971-1.413c-.222-.262-.595-.322-.954-.113l-.45.262a.693.693 0 01-.781-.053 6.745 6.745 0 00-1.693-.988.702.702 0 01-.433-.652v-.599a.712.712 0 00-.577-.7A8.44 8.44 0 0017 8a8.44 8.44 0 00-1.534.138.713.713 0 00-.577.701v.599a.702.702 0 01-.433.652 6.745 6.745 0 00-1.693.988.693.693 0 01-.781.053l-.514-.301a.702.702 0 00-.89.152 8.29 8.29 0 00-.971 1.413 8.145 8.145 0 00-.567 1.265.71.71 0 00.313.85l.51.297a.71.71 0 01.346.708 6.964 6.964 0 000 1.97.71.71 0 01-.345.708l-.51.297a.71.71 0 00-.314.85c.152.44.345.861.567 1.265a8.29 8.29 0 00.971 1.413.702.702 0 00.89.152l.514-.3a.693.693 0 01.781.052c.51.408 1.077.74 1.693.988a.702.702 0 01.433.652v.599c0 .343.243.637.577.7.5.093 1.01.139 1.534.139s1.035-.046 1.534-.138a.712.712 0 00.577-.701v-.599c0-.283.17-.545.433-.652a6.745 6.745 0 001.693-.988.693.693 0 01.781-.053l.514.301c.295.17.668.11.89-.152a8.287 8.287 0 00.971-1.413c.222-.404.415-.825.567-1.265a.71.71 0 00-.313-.85z"
                          fill="#9CA8B4"
                          fillRule="evenodd"
                        />
                      </svg>
                    </button>
                  </td>

                  {isSuperAdmin && (
                    <td style={{ textAlign: 'center' }}>
                      {department?.trashedAt ? (
                        <button onClick={() => handleRestoreDepartment(department)}>
                          <svg
                            height="17px"
                            version="1.1"
                            viewBox="0 0 16 17"
                            width="16px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd" id="Action-Bar" stroke="none" strokeWidth="1">
                              <g fill="#9CA8B4" id="ActionBar/Other" transform="translate(-1013.000000, -118.000000)">
                                <g id="Group-6" transform="translate(996.000000, 0.000000)">
                                  <g id="Group-5" transform="translate(15.000000, 13.000000)">
                                    <g id="29-Update,-reset" transform="translate(2.200000, 105.701667)">
                                      <path
                                        d="M11.3479458,1.09892683 C8.70365312,-0.312888889 5.5900271,-0.125918699 3.17086179,1.47668293 L2.00706775,0.312888889 C1.52628726,-0.167891599 0.705907859,0.171707317 0.705907859,0.850905149 L0.705907859,5.11687805 C0.705907859,5.53660705 1.04550678,5.88002168 1.46905149,5.88002168 L5.73120867,5.88002168 C6.4104065,5.88002168 6.75000542,5.05582656 6.26922493,4.57504607 L5.39924119,3.70506233 C6.79960976,3.01823306 8.46707859,3.02204878 9.90942005,3.78900813 C12.3209539,5.07872087 13.2367263,8.09313821 11.9508293,10.5046721 C10.6611165,12.916206 7.64669919,13.8319783 5.23516531,12.5460813 C4.26215718,12.0233279 3.50664499,11.2105799 3.06020596,10.2223089 C2.90376152,9.87507859 2.49929539,9.71100271 2.13298645,9.82165854 L0.679197832,10.2604661 C0.251837398,10.3902005 0.0152628726,10.8633496 0.18697019,11.2754472 C0.896693767,12.9658103 2.15206504,14.3547317 3.79663957,15.2361626 C4.99477507,15.8772033 6.2844878,16.178645 7.55512195,16.178645 C10.4207263,16.178645 13.1985691,14.6409106 14.6409106,11.9431978 C16.720477,8.04734959 15.243794,3.18230894 11.3479458,1.09892683"
                                        id="action-undo-approval"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </button>
                      ) : (
                        <button onClick={() => handleDeleteDepartment(department)}>
                          <svg
                            height="18px"
                            version="1.1"
                            viewBox="0 0 14 18"
                            width="14px"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                              <g fill="#9CA8B4" fillRule="nonzero" id="action-delete">
                                <path
                                  d="M9.57894733,14.25 C9.57894733,14.6625 9.24736845,15 8.84210526,15 C8.43684207,15 8.10526319,14.6625 8.10526319,14.25 L8.10526319,6.75 C8.10526319,6.3375 8.43684207,6 8.84210526,6 C9.24736845,6 9.57894733,6.3375 9.57894733,6.75 L9.57894733,14.25 Z M5.89473684,14.25 C5.89473684,14.6625 5.5631579,15 5.15789473,15 C4.75263158,15 4.42105263,14.6625 4.42105263,14.25 L4.42105263,6.75 C4.42105263,6.3375 4.75263158,6 5.15789473,6 C5.5631579,6 5.89473684,6.3375 5.89473684,6.75 L5.89473684,14.25 Z M12.8947368,1.875 L8.84210526,1.875 C8.84210526,0.840000003 8.01684207,0 7,0 C5.9831579,0 5.15789473,0.840000003 5.15789473,1.875 L1.10526316,1.875 C0.493684207,1.875 0,2.3775 0,3 C0,3.6225 0.493684207,4.125 1.10526316,4.125 L1.74631579,16.22625 C1.80157895,17.22 2.60842105,18 3.58842105,18 L10.411579,18 C11.391579,18 12.198421,17.22 12.2536842,16.22625 L12.8947368,4.125 C13.5063158,4.125 14,3.6225 14,3 C14,2.3775 13.5063158,1.875 12.8947368,1.875 L12.8947368,1.875 Z"
                                  id="Fill-290"
                                />
                              </g>
                            </g>
                          </svg>
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </TableComponent>
        )}
      </DepartmentsContainer>
      {show && (
        <DepartmentsModal
          department={department}
          handleClose={handleClose}
          locked={locked || !!department?.trashedAt}
          organisation={organisation}
          refetch={refetch}
          show={show}
        />
      )}
    </>
  )
}

export default Departments

const DepartmentsContainer = styled.div`
  margin-top: -75px;
`

const FilterDropdown = styled(ShiftModalReactSelect)`
  min-width: 200px;
`
