import { gql } from "@apollo/client";
import { AgencyWorkerFragment } from "../../../../VMS/AgencyLocums/AgencyLocumHelpers";

export const GET_VMS_AGENCY_WORKERS = gql`
  query GetVmsAgencyWorkers(
    $name: String
    $page: Int
    $items: Int
    $staffGroupId: Int
  ) {
    vmsAgencyWorkers(
      name: $name
      page: $page
      items: $items
      staffGroupId: $staffGroupId
    ) {
      list {
        ...AgencyWorkerFragment
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
  ${AgencyWorkerFragment}
`;

export const VMS_SHIFT_ASSIGN_AGENCY_WORKER = gql`
  mutation VmsShiftAssignAgencyWorker(
    $agencyWorkerId: Int!
    $shiftId: Int!
    $coreTotalRate: Float!
    $coreLocumRate: Float!
    $coreAgencyMargin: Float!
    $coreNiContribution: Float!
    $directEngagement: Boolean!
    $requisitionNumber: String!
    $poNumber: String!
    $receiptNumber: String!
    $comment: String!
    $splitRate: Boolean!
    $unsocialTotalRate: Float
    $unsocialLocumRate: Float
    $unsocialAgencyMargin: Float
    $unsocialNiContribution: Float
  ) {
    vmsShiftAssignAgencyWorker(
      agencyWorkerId: $agencyWorkerId
      shiftId: $shiftId
      coreTotalRate: $coreTotalRate
      coreLocumRate: $coreLocumRate
      coreAgencyMargin: $coreAgencyMargin
      coreNiContribution: $coreNiContribution
      directEngagement: $directEngagement
      requisitionNumber: $requisitionNumber
      poNumber: $poNumber
      receiptNumber: $receiptNumber
      comment: $comment
      splitRate: $splitRate
      unsocialTotalRate: $unsocialTotalRate
      unsocialLocumRate: $unsocialLocumRate
      unsocialAgencyMargin: $unsocialAgencyMargin
      unsocialNiContribution: $unsocialNiContribution
    ) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;

export const VMS_SHIFT_CANCEL_AGENCY_WORKER = gql`
  mutation VmsShiftCancelAgencyWorker($shiftId: Int!, $reason: String!) {
    vmsShiftCancelAgencyWorker(shiftId: $shiftId, reason: $reason) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;
