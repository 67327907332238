import SlimSelect from "slim-select"
import queryString from "query-string"

export default class TimedReleaseSelectorController extends SearchController {

    static get targets() {
        return ["select"]
    }

    connect() {
        const timedReleaseInput = document.getElementById("timed_release_days")

        timedReleaseInput.style.display = "none";

        if (this.selectTarget.selectedOptions[0].value === "1") {
            timedReleaseInput.style.display = "block";
        }

        this.element[this.identifier] = this
        window.qs = queryString
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;

        this.select = new SlimSelect({
            select: target,
            placeholder: "Select option",
            onChange: (info) => {
                if (info.value !== "1") {
                    timedReleaseInput.style.display = "none";
                } else {
                    timedReleaseInput.style.display = "block";
                }
            }
        })
    }
}

import { application } from "../../frontend/controllers/index"
import SearchController from "../search_controller";
application.register("timed_release_selector", TimedReleaseSelectorController)
