import { useMutation } from '@apollo/client'
import {
  DefaultButton,
  CloseButton,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalFooter,
} from '@patchworkhealth/web-components'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import { successToast, errorToast, loadingToast, SHIFT_BLOCK_RECALL_FROM_AGENCY } from '../helpers/BlockBookingsHelpers'

function RecallFromAgencyModal({ openModal, block, handleClose, setSelectedBlock, refetch }) {
  const [shiftBlockRecallBlockFromAgency] = useMutation(SHIFT_BLOCK_RECALL_FROM_AGENCY)

  const recallBlockFromAgency = async () => {
    toast.loading('Loading', loadingToast)
    try {
      const { data: result } = await shiftBlockRecallBlockFromAgency({
        variables: {
          shiftBlockId: Number(block?.id),
        },
      })

      if (result?.shiftBlockRecallFromAgency?.errors?.length > 0) {
        toast.update(2, errorToast)
      } else {
        toast.update(2, { ...successToast, render: 'Block recalled from Agency' })
        refetch()
        setSelectedBlock(false)
        handleClose()
      }
    } catch (err) {
      toast.update(2, errorToast)
    }
  }

  return (
    <Modal backdrop="static" centered onHide={handleClose} show={openModal}>
      <ModalHeader style={{ height: 80, padding: '15px 23px' }}>
        <ModalTitle>Recall from Agency</ModalTitle>
        <CloseButton onClick={handleClose} />
      </ModalHeader>
      <>
        <ModalBody>
          Are you sure you want to recall <strong>B{block.id}</strong> from agency?
        </ModalBody>

        <ModalFooter>
          <div />
          <div>
            <DefaultButton action={handleClose} text="Cancel" type="white" />
            <DefaultButton action={() => recallBlockFromAgency()} color="blue" text="Recall Block" />
          </div>
        </ModalFooter>
      </>
    </Modal>
  )
}

export default RecallFromAgencyModal
