import queryString from 'query-string'
import React from 'react'
import styled from 'styled-components'
import {
  AuthHeaderContainer,
  AuthHeaderIcon,
  AuthHeaderIconCount,
  AuthHeaderRight,
  AuthHeaderSubtitle,
  AuthHeaderTitle,
} from '../../AuthHeader/AuthHeader'
import DropdownComponent, {ActionsDropdownMenuItem} from '../../Dropdown'

function ItemisedReceiptsHeader({count, user, handleSearch}) {
  const unreadCount = user?.unread_hub_notifications_count ? user.unread_hub_notifications_count : 0
  const useKeycloak = user?.use_keycloak ? user?.use_keycloak : false

  const logoutAction = async () => {
    try {
      const res = await fetch('/hub_users/sign_out', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      })

      if (res.ok) {
        window.location.href = '/hub_users/sign_in'
      }
    } catch (e) {
      console.log(e) // eslint-disable-line no-console
    }
  }

  const receiptIdsFromUrlQuery = queryString.parse(window.location.search)?.ids || ''
  const parsedReceiptIds = receiptIdsFromUrlQuery.replace(/\[|\]/g, '')

  return (
    <AuthHeaderContainer>
      <div>
        <AuthHeaderSubtitle> Itemised Receipts found</AuthHeaderSubtitle>
        <AuthHeaderTitle>
          {count} {count === 1 ? `Match` : `Matches`}
        </AuthHeaderTitle>
      </div>
      <AuthHeaderRight>
        <SearchInput
          defaultValue={parsedReceiptIds}
          onKeyDown={handleSearch}
          placeholder="Search Receipt IDs"
          type="text"
        />
        <AuthHeaderIcon>
          {unreadCount > 0 && <AuthHeaderIconCount>{unreadCount}</AuthHeaderIconCount>}

          <a href="/hub_notifications">
            <svg height="26" width="21" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.268 22.4c.832 0 1.333.906.912 1.623a3.197 3.197 0 01-5.515 0c-.421-.717.08-1.623.912-1.623h3.69zM2.465 7.45A7.978 7.978 0 0110.903.015c4.053.238 7.226 3.603 7.493 7.653l.501 7.644c.016.268.101.527.245.754l1.451 2.276c.677 1.065-.09 2.459-1.35 2.459H1.608c-1.264 0-2.032-1.394-1.355-2.46l1.45-2.275c.145-.227.23-.486.246-.754z"
                fill="#FFF"
                fillRule="evenodd"
                opacity={unreadCount === 0 ? 0.4 : 1}
              />
            </svg>
          </a>
        </AuthHeaderIcon>
        <DropdownComponent user={user}>
          {useKeycloak === false && (
            <ActionsDropdownMenuItem href="/hub_users/edit">Change Password</ActionsDropdownMenuItem>
          )}
          <ActionsDropdownMenuItem onClick={logoutAction}>Logout</ActionsDropdownMenuItem>
        </DropdownComponent>
      </AuthHeaderRight>
    </AuthHeaderContainer>
  )
}

export default ItemisedReceiptsHeader

const SearchInput = styled.input`
  background: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.143 7.714c0-3.072 2.5-5.571 5.571-5.571 3.072 0 5.572 2.5 5.572 5.571 0 3.072-2.5 5.572-5.572 5.572a5.578 5.578 0 01-5.571-5.572zm12.326 3.725a7.714 7.714 0 10-3.03 3.03l2.903 2.904a2.143 2.143 0 003.03-3.031l-2.903-2.903z' fill='%239CA8B4' fill-rule='evenodd' /%3E%3C/svg%3E") #fff no-repeat;
  background-position: center left 10px;
  width: 200px;
  height: 40px;
  padding: 10px 18px 10px 36px;
  box-sizing: border-box;
  border: 1px solid #ccd2d8;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  margin-right: 20px;
`
