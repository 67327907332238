import { Controller } from "stimulus";
import SlimSelect from "slim-select";
import { application } from "../../frontend/controllers/index";

export default class SendToAgencyTiersController extends Controller {
  static get targets() {
    return ["shiftId", "tiersSelect"];
  }

  connect() {
    new SlimSelect({
      select: this.tiersSelectTarget,
      placeholder: "Select tier",
      allowDeselectOption: true,
    });
  }

  submit() {
    document.querySelector(".modal__validation--agency-tier").style.display =
      "none";
    const shiftId = Number(this.shiftIdTarget.value);
    const agencyTierId = Number(this.tiersSelectTarget.value);

    const query = `mutation SendToAgencyTier($shiftId: Int!, $agencyTierId: Int){
      sendToAgencyTier(shiftId: $shiftId, agencyTierId: $agencyTierId){
        success
        errors {
          attribute
          message
        }
      }
    }`;

    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { shiftId, agencyTierId },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.data.sendToAgencyTier.success === true) {
          window.location.reload(true);
        } else {
          document.querySelector(
            ".modal__validation--agency-tier"
          ).style.display = "block";
        }
      });
  }
}

application.register("send_to_agency_tiers", SendToAgencyTiersController);
