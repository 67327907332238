import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'



export default class CovidRiskLevelSelectorController extends Controller {

    static get targets() {
        return ['select']
    }
    connect() {
        const target = this.selectTarget;
        const closeOnSelect = this.data.get('keep-open') ? false : true
        this.select = new SlimSelect({
            select: target,
            closeOnSelect: closeOnSelect,
        })
    }
}

import { application } from '../../frontend/controllers/index'
application.register("covid_risk_level_selector", CovidRiskLevelSelectorController);