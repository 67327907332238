import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { client } from '../../../../packs/application'
import AgencyList from './AgencyList'

function AgencyListIndex({ organisationId, isMod }) {
  return (
    <ApolloProvider client={client}>
      <AgencyList isMod={isMod} organisationId={organisationId} />
    </ApolloProvider>
  )
}

export default AgencyListIndex
