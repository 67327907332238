import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";

import Pagination from "../Components/Pagination";
import CollaborativeBanksModal from "./CollaborativeBanksModal";
import {
  TableHeaderContainer,
  TableSearchInput,
  TableComponent,
} from "../Tables/TableElements";

const CollaborativeBanks = ({ collaborativeBanks, pagy, organisations }) => {
  const [bank, setBank] = useState(undefined);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setBank(undefined);
    setShow(false);
  };
  const handleShowCreate = () => {
    setBank(undefined);
    setShow(true);
  };

  const handleShowEdit = (bank) => {
    setBank(bank && bank);
    setShow(true);
  };

  // search function
  const filteredBanks = value
    ? collaborativeBanks.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      })
    : collaborativeBanks;

  return (
    <>
      <CollabBankContainer>
        <TableHeaderContainer>
          <TableSearchInput
            placeholder="Search Collab Banks..."
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button className="btn btn-cyan" onClick={handleShowCreate}>
            <svg
              width="22"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                fill="#1E2D36"
              />
            </svg>{" "}
            Create Collaborative Bank
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>Name</th>
              <th>Organisations</th>
              <th>Date Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredBanks?.map((bank, index) => (
              <tr key={index}>
                <td style={{ maxWidth: 200 }}>{bank.name}</td>
                <td style={{ maxWidth: 200 }}>{bank.organisations_list}</td>
                <td>{moment(bank.created_at).format("Do MMM YYYY")}</td>
                <td style={{ textAlign: "right" }}>
                  <button onClick={() => handleShowEdit(bank)}>
                    <svg
                      className="icon-shadow"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.182.5h21.636A5.682 5.682 0 0133.5 6.182v21.636a5.682 5.682 0 01-5.682 5.682H6.182A5.682 5.682 0 01.5 27.818V6.182A5.682 5.682 0 016.182.5z"
                        fill="#fff"
                        stroke="#CBD2D9"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 19.688c-1.749 0-3.167-1.428-3.167-3.188 0-1.76 1.418-3.188 3.167-3.188 1.749 0 3.167 1.428 3.167 3.188 0 1.76-1.418 3.188-3.167 3.188zm7.647-1.198l-.51-.297a.71.71 0 01-.346-.708 6.964 6.964 0 000-1.97c-.038-.287.096-.563.412-.747l.444-.258a.71.71 0 00.313-.85 8.143 8.143 0 00-.567-1.265 8.287 8.287 0 00-.971-1.413c-.222-.262-.595-.322-.954-.113l-.45.262a.693.693 0 01-.781-.053 6.745 6.745 0 00-1.693-.988.702.702 0 01-.433-.652v-.599a.712.712 0 00-.577-.7A8.44 8.44 0 0017 8a8.44 8.44 0 00-1.534.138.713.713 0 00-.577.701v.599a.702.702 0 01-.433.652 6.745 6.745 0 00-1.693.988.693.693 0 01-.781.053l-.514-.301a.702.702 0 00-.89.152 8.29 8.29 0 00-.971 1.413 8.145 8.145 0 00-.567 1.265.71.71 0 00.313.85l.51.297a.71.71 0 01.346.708 6.964 6.964 0 000 1.97.71.71 0 01-.345.708l-.51.297a.71.71 0 00-.314.85c.152.44.345.861.567 1.265a8.29 8.29 0 00.971 1.413.702.702 0 00.89.152l.514-.3a.693.693 0 01.781.052c.51.408 1.077.74 1.693.988a.702.702 0 01.433.652v.599c0 .343.243.637.577.7.5.093 1.01.139 1.534.139s1.035-.046 1.534-.138a.712.712 0 00.577-.701v-.599c0-.283.17-.545.433-.652a6.745 6.745 0 001.693-.988.693.693 0 01.781-.053l.514.301c.295.17.668.11.89-.152a8.287 8.287 0 00.971-1.413c.222-.404.415-.825.567-1.265a.71.71 0 00-.313-.85z"
                        fill="#9CA8B4"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
        <Pagination pagy={pagy} />
      </CollabBankContainer>
      {show && (
        <CollaborativeBanksModal
          bank={bank}
          handleClose={handleClose}
          organisationsList={organisations}
          show={show}
        />
      )}
    </>
  );
};

export default CollaborativeBanks;

const CollabBankContainer = styled.div`
  margin-top: -75px;
`;
