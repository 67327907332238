import { Controller } from 'stimulus'

export default class FileInputController extends Controller {

  static get targets() {
    return ['value', 'input']
  }

  display(_event) {
    const fileName = this.inputTarget.files[0].name
    this.valueTarget.innerText = fileName
  }
}
