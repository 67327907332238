import { Controller } from 'stimulus'

export default class WorkerTableController extends Controller {
    static get targets() {
        return ['selectedWorkersOutput']
    }

    connect () {
        this.selectedWorkers = []
    }

    toggle(event) {
        let checkboxes = document.querySelectorAll('input[type="checkbox"]');
        let source = event.currentTarget;
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i] != source)
                checkboxes[i].checked = source.checked;
        }
    }

    selectRow(event) {
        let workerId = parseInt(event.srcElement.dataset['workerId']);
        if (this.selectedWorkers.includes(workerId)) {
            this.selectedWorkers = this.selectedWorkers.filter(id => id !== workerId)
        } else {
            this.selectedWorkers.push(workerId)
        }
    }
}

import { application } from '../../frontend/controllers/index'
application.register("worker_table", WorkerTableController)