import moment from "moment";
import qs from 'qs';
import queryString from 'query-string';
import { Controller } from 'stimulus';
import { application } from '../../frontend/controllers/index';
export default class FilterController extends Controller {

    static get targets() {
        return ['parameterArea', 'buttonText', 'headerArea', 'appendArea', 'filterCount', 'clearFilter', 'shiftBlockFilter']
    }

    connect() {
        this.element[this.identifier] = this
        this.adjustText()
        const controller_this = this
        setTimeout(() => { controller_this.filterArea() }, 50)

        // count the filters applied
        var pos = window.location.href.indexOf("?");
        if (pos == -1) return [];
        const query = window.location.href.substr(pos + 1);

        var count = 0
        var result = {};
        query.split("&").forEach(function (part) {
            if (part.includes("payroll_cycle")) {
            } else if (part.includes("organisation_staff_group_id")) {
            } else if (part.includes("in_collaborative_bank")) {
            } else if (part.includes("page_items")) {
            } else if (part === '') {
            } else {
                count++
            }
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });

        // set the count to the filterCount target
        if (count > 0) {
            this.filterCountTarget.innerText = count;
            this.clearFilterTarget.style.display = "block";
        }
    }

    toggle() {
        this.flipState();
        this.adjustText();
        this.parameterAreaTarget.classList.toggle('filter__parameter-area--toggle');
        this.headerAreaTarget.classList.toggle('filter--toggle');
    }

    filterArea() {
        const siteController = document.getElementById('site_selector');
        const departmentController = document.getElementById('department_selector');
        const staffController = document.getElementById('staff_selector');
        const gradeController = document.getElementById('grade_selector');
        const statusController = document.getElementById('status_selector');
        const specialitiesController = document.getElementById('worker_specialities_selector');
        const requesterController = document.getElementById('requester_selector');
        const shiftTypeController = document.getElementById('shift_type_selector');
        const paymentTypeController = document.getElementById('payment_type_selector');
        const workerController = document.getElementById('worker_name_selector');
        const agencyWorkerPickerController = document.getElementById('agency_worker_picker');
        const rateController = document.querySelector('.rate-selector')
        const internalExternalController = document.querySelector('.internal-external-selector')
        const dateController = document.querySelector('.date-input')

        const filterButtons = (filterFor, optionValue, optionText) => {


            if (filterFor === 'shift_block_uids') {
               optionText = optionText.split(',').join(' , ')
            }

            const appendArea = this.appendAreaTarget;
            const button = document.getElementById("filter-button-template").cloneNode(true);
            const identifier = filterFor + '__' + optionValue
            button.classList.remove('filter__btn-container__btn-hide');
            button.setAttribute('id', identifier);
            button.setAttribute('data-filter', filterFor)
            button.prepend(optionText)
            appendArea.appendChild(button);

            if (optionText == 'Select requester' || optionText == 'Select status' || optionText == 'Worker: Select worker' ||   optionText == 'Agency Worker: Select worker' || optionText == 'Shift Type: Select shift type') {
                button.remove();
            }

            const removeButton = (identifier, optionText) => {
                const button = document.getElementById(`${identifier.srcElement.id}`);
                const filter = button.dataset['filter']
                const id_to_remove = button.id.split('__')[1]
                button.remove();

                let params = queryString.parse(location.search)
                delete (params['page'])

                if (Array.isArray(params[filter + '[]'])) {
                    params[filter + '[]'] = params[filter + '[]'].filter(p => p != id_to_remove)
                } else if (params[filter + '[]'] == id_to_remove) {
                    delete (params[filter + '[]'])
                } else if (filter in params) {
                    delete (params[filter])
                }

                if (Object.keys(params).length === 0) {
                    params['clear'] = true
                }

                const url = queryString.stringify(params)
                window.location.search = url
            }

            let element = document.getElementById(`${identifier}`)
            if (element) {
                element.addEventListener('click', removeButton)
            }
        }

        // deconstruct url for filter buttons
        const urlParse = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        // shift_ids pill buttons
        if (urlParse.shift_ids) {
            urlParse.shift_ids.map(shift => {
                filterButtons('shift_ids', shift, `Shift ID: ${shift}`)
            })
        }

        if (urlParse.external_id) {
            filterButtons('external_id', urlParse.external_id, `External Shift ID: ${urlParse.external_id}`)
        }

        if (urlParse.shift_block_uids) {
            filterButtons('shift_block_uids', urlParse.shift_block_uids, `Block ID(s): ${urlParse.shift_block_uids}`)
        }

        if (urlParse.rate) {
            filterButtons('rate', rateController.value, `Rate: ${rateController.value}`)
        }

        if (urlParse.internal_or_external) {
            filterButtons('internal_or_external', internalExternalController.value, `Worker Type: ${internalExternalController.value}`)
        }

        if (departmentController) {
            Array.from(departmentController["department_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('department_ids', option.value, `Department: ${option.text}`) : null
            )
        }

        if (siteController) {
            Array.from(siteController["site_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('site_ids', option.value, `Site: ${option.text}`) : null
            )
        }

        if (shiftTypeController) {
            Array.from(shiftTypeController["shift_type_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('types', option.value, `Shift Type: ${option.text}`) : null
            )
        }

        if (paymentTypeController) {
            Array.from(paymentTypeController["payment_type_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('shift_type', option.value, `Shift Type: ${option.text}`) : null
            )
        }

        if (staffController) {
            Array.from(staffController["staff_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('staff_group_ids', option.value, `Staff Group: ${option.text}`) : null
            )
        }

        if (gradeController) {
            Array.from(gradeController["grade_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('grade_ids', option.value, `Grade: ${option.text}`) : null
            )
        }

        if (statusController) {
            Array.from(statusController["status_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('statuses', option.value, `Status: ${option.text}`) : null
            )
        }

        if (requesterController) {
            Array.from(requesterController["requester_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('requester_ids', option.value, `Requester: ${option.text}`) : null
            )
        }

        if (workerController) {
            Array.from(workerController["worker_name_selector"].selectTarget.options).forEach(
                option => option.selected ? filterButtons('worker_id', option.value, `Worker: ${option.text}`) : null
            )
        }

        if (agencyWorkerPickerController) {
            const agencyWorkerPickerSelect = agencyWorkerPickerController.agency_worker_picker.selectTarget
            const { agencyWorkerQueryParam } = agencyWorkerPickerSelect.dataset

            Array.from(agencyWorkerPickerSelect.options)
                .filter((option) => option.selected)
                .forEach((option) => {
                    const { text, value } = option
                    return filterButtons(agencyWorkerQueryParam, value, `Agency Worker: ${text}`)
                })
        }

        if (rateController) {
            filterButtons('rate', rateController.value, `Rate: ${rateController.value}`)
        }

        if (dateController) {
            if (urlParse.from_start_time) {
                filterButtons('from_start_time', urlParse.from_start_time, `Date from ${moment(urlParse.from_start_time).format("DD/MM/YYYY").toString()}`)
            }

            if (urlParse.to_start_time) {
                filterButtons('to_start_time', urlParse.to_start_time, `Date to ${moment(urlParse.to_start_time).format("DD/MM/YYYY").toString()}`)
            }

            if (urlParse.from_date) {
                filterButtons('from_time', urlParse.from_time, `Date from ${moment(urlParse.from_time).format("DD/MM/YYYY").toString()}`)
            }

            if (urlParse.to_date) {
                filterButtons('to_time', urlParse.to_time, `Date to ${moment(urlParse.to_time).format("DD/MM/YYYY").toString()}`)
            }
        }
    }

    clear(event) {
        event.preventDefault();
        let clear_to_path = this.data.get('path') ? this.data.get('path') : "/shifts?clear=true";

        window.location = clear_to_path;
    }

    clearBlocks(event) {
        event.preventDefault();
        let clear_to_path = this.data.get('path') ? this.data.get('path') : "/blocks?clear=true";

        window.location = clear_to_path;
    }


    toggleShiftBlockFilter(event) {
        let checked = event.target.checked ? 'true' : 'false';
        let params = queryString.parse(location.search)

        if(checked === 'true') {
            params['filter_by_shift_blocks'] = 'true'
        } else {
            params['filter_by_shift_blocks'] = 'false'
        }

        if(Object.keys(params).length === 0) {
            params['clear'] = 'true'
        }

        const url = queryString.stringify(params)

        window.location.search = url
    }

    clearOutstandingPayments(event) {
        event.preventDefault();
        this.deletePaymentFilters()
    }

    clearPaymentsReview(event) {
        event.preventDefault()
        this.deletePaymentFilters()
    }

    deletePaymentFilters() {
        let params = queryString.parse(location.search)
        delete (params['department_ids[]'])
        delete (params['shift_ids[]'])
        delete (params['grade_ids[]'])
        delete (params['from_start_time'])
        delete (params['to_start_time'])
        delete (params['organisation_ids[]'])
        delete (params['internal_or_external'])
        window.location.search = queryString.stringify(params)
    }

    clearWorkersFilters(event) {
        event.preventDefault();
        window.location = "/workers";
    }

    clearExternalApplicationsFilters(event) {
        event.preventDefault();
        window.location = "/external_applications";
    }

    clearInternalApplicationsFilters(event) {
        event.preventDefault();
        window.location = "/internal_applications";
    }

    clearDepartmentsFilters(event) {
        event.preventDefault();
        window.location = "/departments";
    }

    flipState() {
        this.visibleValue = !this.visibleValue
    }

    newText() {
        // count the filters applied
        var pos = window.location.href.indexOf("?");
        if (pos == -1) return "Add Filters";

        const query = window.location.href.substr(pos + 1);
        var count = 0
        var result = {};
        query.split("&").forEach(function (part) {
            if (part.includes("payroll_cycle")) {
            } else if (part.includes("organisation_staff_group_id")) {
            } else {
                count++
            }
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });

        return this.visibleValue ? 'Close Filters' : count > 0 ? 'Edit Filters' : 'Add Filters'
    }

    adjustText() {
      if (this.hasButtonTextTarget) {
        this.buttonTextTarget.innerText = this.newText()
      }
    }
}

application.register("filter", FilterController)
