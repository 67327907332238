/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

// Export Doc ---------------------------------------------------------

export const AGENCY_WORKER_DOCS_EXPORT_SHIFT_AND_BLOCK_COMPLIANCE = gql`
  mutation agencyWorkerDocsExportShiftAndBlockCompliance(
    $agencyRegistrationId: Int!
    $objectId: Int!
    $objectType: String!
  ) {
    agencyWorkerDocsExportShiftAndBlockCompliance(
      agencyRegistrationId: $agencyRegistrationId
      objectId: $objectId
      objectType: $objectType
    ) {
      errors {
        message
        attribute
      }
      success
      zipString
    }
  }
`
