import { Controller } from 'stimulus'

export default class TabController extends Controller {

    static get targets() {
        return ['navbar','content']
    }

    open(event) {
        const id = event.target.dataset.value
        event.preventDefault();
        this.contentTarget.querySelectorAll('.modal-section').forEach( function(el){el.classList.add('d-none')} )
        this.contentTarget.querySelector(`#${id}_content`).classList.remove('d-none')
        this.navbarTarget.querySelectorAll('.nav-link').forEach( function(el){el.classList.remove('active')} )
        this.navbarTarget.querySelector(`#${id}_menu_item .nav-link`).classList.add('active')
    }
}
import {application} from '../../frontend/controllers/index'
application.register("tab", TabController)