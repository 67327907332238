import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { client } from '../../../packs/application'
import ItemisedRates from './ItemisedRates'

import 'react-toastify/dist/ReactToastify.css'

function ItemisedRatesIndex({ departments, organisation, user }) {
  return (
    <>
      <ToastContainer position="top-center" transition={Slide} />

      <ApolloProvider client={client}>
        <ItemisedRates departments={departments} organisation={organisation} user={user} />
      </ApolloProvider>
    </>
  )
}

export default ItemisedRatesIndex
