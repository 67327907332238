export const resetInputs = () => {
  const inputFields = window.document.querySelectorAll('.documents__value')
  inputFields.forEach((element) => (element.value = "")) // eslint-disable-line
}

export const convertToBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()

    fileReader.readAsDataURL(file)

    fileReader.onload = () => {
      resolve(fileReader.result)
    }

    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}
