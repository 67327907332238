import React from "react";
import Pagination from "react-bootstrap/Pagination";

const PaginationComponent = ({ pagy }) => {
  let items = [];
  for (let number = 1; number <= pagy.last; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === pagy.page}
        href={`${window.location.pathname}?page=${number}&page_items=25`}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.Prev
        href={`${window.location.pathname}?page=${pagy.prev}&page_items=25`}
        disabled={pagy.prev ? false : true}
      >
        ‹&nbsp;Prev
      </Pagination.Prev>
      {items}
      <Pagination.Next
        href={`${window.location.pathname}?page=${pagy.next}&page_items=25`}
        disabled={pagy.next ? false : true}
      >
        Next&nbsp;›
      </Pagination.Next>
    </Pagination>
  );
};

export default PaginationComponent;
