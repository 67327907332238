import { Controller } from 'stimulus'

export default class ButtonToggleNewController extends Controller {

    static get targets() {
        return ['checkbox', 'buttonOne', 'buttonTwo']
    }

    connect() {
        if(this.buttonOneTarget.disabled) {
            this.buttonTwoTarget.classList.add('checked')
        } else {
            this.buttonOneTarget.classList.add('checked')
        }
    }

    check(e) {
        e.preventDefault()
        this.checkboxTarget.checked = true;
        this.buttonOneTarget.classList.add('checked')
        this.buttonTwoTarget.classList.remove('checked')
    }

    uncheck(e) {
        e.preventDefault()
        this.checkboxTarget.checked = false;
        this.buttonTwoTarget.classList.add('checked')
        this.buttonOneTarget.classList.remove('checked')
    }
}

import { application } from '../../frontend/controllers/index'

application.register("button_toggle_new", ButtonToggleNewController)
