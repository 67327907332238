import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../packs/application";

import SkillSets from "./SkillSets";

function SkillSetsIndex() {
  return (
    <ApolloProvider client={client}>
      <SkillSets />
    </ApolloProvider>
  );
}

export default SkillSetsIndex;
