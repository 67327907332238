import {Controller} from 'stimulus'
import consumer from "../../channels/consumer"

export default class BatchesShowController extends Controller {
    static get targets() {
        return ['loading', 'loaded', 'form']
    }

    connect() {
        let controllerThis = this
        this.consumer = consumer.subscriptions.create(
            {
                channel: "BatchesChannel",
                batch_id: this.data.get('batch_id')
            },
            {
                connected() {
                },

                disconnected() {
                    // Called when the subscription has been terminated by the server
                },

                received(data) {
                    // Called when there's incoming data on the websocket for this channel
                    controllerThis.updateWithData(data)
                }
            }
        );

        setTimeout(function() { controllerThis.refreshViaRest() }, 5000)
    }

    refreshViaRest() {
        let controllerThis = this
        const url = '/batches/' + this.data.get('batch_id') + '.json'
        fetch(url)
            .then(
                response => {
                    if (response.status !== 200) {
                        return;
                    }

                    response.text().then(data => {
                        controllerThis.updateWithData({ batch: data } )
                    });
                }
            )
            .catch(err => {
                console.log('Fetch Error', err);
            });
    }

    updateWithData(data) {
        if(data.batch.csv_url) {
            this.loadingTarget.classList.add('d-none')
            this.loadedTarget.classList.remove('d-none')
            this.formTarget.action = data.batch.csv_url
        }
    }

    disconnect() {

    }
}
