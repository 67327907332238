import React, { useState } from "react";
import moment from "moment";

import RegulationBodyModal from "./RegulationBodyModal";
import Pagination from "../Components/Pagination";
import {
  TableContainer,
  TableHeaderContainer,
  TableSearchInput,
  TableComponent,
} from "../Tables/TableElements";
import { TableRowEditIcon } from "../Assets/Icons";

const RegulationBody = ({ data, pagy }) => {
  const regulationBodies = data;
  const [regulationBody, setRegulationBody] = useState(undefined);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setRegulationBody(undefined);
    setShow(false);
  };
  const handleShowCreate = () => {
    setRegulationBody(undefined);
    setShow(true);
  };

  const handleShowEdit = (regulationBody) => {
    setRegulationBody(regulationBody && regulationBody);
    setShow(true);
  };

  // search function
  const filteredRegBodies = value
    ? regulationBodies.filter((item) => {
        return item.name.toLowerCase().includes(value.toLowerCase());
      })
    : regulationBodies;

  return (
    <>
      <TableContainer>
        <TableHeaderContainer>
          <TableSearchInput
            placeholder="Search Regulation Bodies..."
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button className="btn btn-cyan" onClick={handleShowCreate}>
            <svg
              width="22"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                fill="#1E2D36"
              />
            </svg>
            Create Regulation Body
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Abbr</th>
              <th>Registration # Max Length</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredRegBodies?.map((regulationBody, index) => (
              <tr key={index}>
                <th>{regulationBody.id}</th>
                <td>{regulationBody.name}</td>
                <td>
                  {regulationBody.name_abbr ? regulationBody.name_abbr : ""}
                </td>
                <td>
                  {regulationBody.registration_number_max_length
                    ? regulationBody.registration_number_max_length
                    : ""}
                </td>
                <td>
                  {moment(regulationBody.created_at).format("MMMM Do YYYY")}
                </td>
                <td style={{ textAlign: "right" }}>
                  <button onClick={() => handleShowEdit(regulationBody)}>
                    <TableRowEditIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
        <Pagination pagy={pagy} />
      </TableContainer>
      {show && (
        <RegulationBodyModal
          handleClose={handleClose}
          regulationBody={regulationBody}
          show={show}
        />
      )}
    </>
  );
};

export default RegulationBody;
