import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import moment from "moment";
import styled from "styled-components";

import {
  ErrorContainer,
  FormButtonToggle,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../../../Forms/FormElements";
import {
  CloseButton,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../Modals/ModalElements";
import { bulkRatesEscalation } from "./AmendRatesHelpers";

const AmendRateModal = ({ handleClose, modalStep, setModalStep, show }) => {
  const [authorisers, setAuthorisers] = useState([]);
  const [error, setError] = useState({});
  const [inputs, setInputs] = useState({ rate_amount: 0, rate_percent: 0 });
  const [loading, setLoading] = useState(true);
  const [rate, setRate] = useState("percent");
  const [shiftList, setShiftList] = useState([]);

  const checkedShifts = document.querySelector(".list");
  const shifts = Array.from(
    checkedShifts.querySelectorAll(".custom-control-input:checked")
  ).map((item) => item.value);

  const fetchShift = (shift) => {
    setLoading(true);
    const id = Number(shift);
    const query = `query Shift($id: Int!){
        shift(id: $id){
          department {
            name
          }
          endTime
          grade {
            title
          }
          id
          site {
            name
          }
          startTime
          coreTotalRate
          unsocialTotalRate
        }
      }`;
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        setShiftList((shiftList) => [...shiftList, data.data.shift]);
        setLoading(false);
      });
  };

  const shiftIds = shifts.map(function (item) {
    return parseInt(item);
  });

  const fetchAuthorisers = () => {
    const query = `query($shiftIds: [Int!]!){
      rateAuthorisersBulk(shiftIds: $shiftIds) {
        id
        name
      }
    }`;
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { shiftIds },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        setAuthorisers(
          data.data.rateAuthorisersBulk.map(function (authoriser) {
            return { value: authoriser.id, label: authoriser.name };
          })
        );
      });
  };

  const fetchRatesPreview = () => {
    const query = `query($shift_ids: [Int!]!, $rate_authoriser_id: Int!, $amendment_type: String!, $raise_percent: Float, $raise_amount: Float){
      bulkRatesEscalationPreview(shiftIds: $shift_ids, rateAuthoriserId: $rate_authoriser_id, amendmentType: $amendment_type, raisePercent: $raise_percent, raiseAmount: $raise_amount) {
        success
        preview {
          shift {
            department {
              name
            }
            endTime
            grade {
              title
            }
            id
            site {
              name
            }
            startTime
            coreTotalRate
            unsocialTotalRate
          }
          newCoreRate
          newUnsocialRate
        }
      }
    }`;
    const rateAuthoriserId = inputs.rate_authoriser
      ? Number(inputs.rate_authoriser)
      : null;
    const amendmentType = rate === "percent" ? "by_percent" : "by_amount";
    const raisePercent = inputs.rate_percent
      ? Number(inputs.rate_percent)
      : null;
    const raiseAmount = inputs.rate_amount ? Number(inputs.rate_amount) : null;

    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: {
          shift_ids: shiftIds,
          rate_authoriser_id: rateAuthoriserId,
          amendment_type: amendmentType,
          raise_percent: raisePercent,
          raise_amount: raiseAmount,
        },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        var obtainedShifts = data.data.bulkRatesEscalationPreview.preview.map(
          function (previewItem) {
            previewItem.shift.newCoreRate = previewItem.newCoreRate;
            previewItem.shift.newUnsocialRate = previewItem.newUnsocialRate;
            return previewItem.shift;
          }
        );
        setShiftList((shiftList) => obtainedShifts);
        setLoading(false);
      });
  };

  const firstAmendAction = () => {
    if (rate === "percent" && inputs.rate_percent === 0) {
      setError({ ...error, rate_percent: "Please enter a percent." });
    } else if (rate === "fixed" && inputs.rate_amount === 0) {
      setError({ ...error, rate_amount: "Please enter an amount." });
    } else if (inputs.rate_authoriser === undefined) {
      setError({ ...error, rate_authoriser: "Please select an authoriser." });
    } else {
      fetchRatesPreview();
      setModalStep("step-two");
    }
  };

  const amendRatesAction = () => {
    setLoading(true);
    const rateAuthoriserId = inputs.rate_authoriser
      ? Number(inputs.rate_authoriser)
      : null;
    const amendmentType = rate === "percent" ? "by_percent" : "by_amount";
    const raisePercent = inputs.rate_percent
      ? Number(inputs.rate_percent)
      : null;
    const raiseAmount = inputs.rate_amount ? Number(inputs.rate_amount) : null;

    bulkRatesEscalation(
      shiftIds,
      rateAuthoriserId,
      amendmentType,
      raisePercent,
      raiseAmount
    ).then((data) => {
      if (
        data.data.bulkRatesEscalation.success === true &&
        data.data.bulkRatesEscalation.failedShifts.length > 0
      ) {
        setLoading(false);
        setError({
          amend: `There was an error escalating rates for these shifts.`,
        });
      } else if (
        data.data.bulkRatesEscalation.success === true &&
        data.data.bulkRatesEscalation.failedShifts.length === 0
      ) {
        window.location.reload(true);
      }
    });
  };

  useEffect(() => {
    fetchAuthorisers();
    // shifts?.map((shift) => (
    //   fetchShift(shift)
    // ))
  }, []);

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleAuthoriserChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, rate_authoriser: event.value }));
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size={modalStep === "step-one" ? "lg" : "xl"}
    >
      <ModalHeader>
        <ModalTitle>Amending Rate for {shifts?.length} Shifts</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {modalStep === "step-one" ? (
        <>
          <ModalBody style={{ overflowY: "unset" }}>
            <AmendRateForm>
              <FormDetailGroup>
                <FormGrid columns={1}>
                  <FormGroup label="Amend by">
                    <FormButtonToggle>
                      <button
                        onClick={() => setRate("percent")}
                        className={rate === "percent" ? "active" : ""}
                      >
                        Percentage
                      </button>
                      <button
                        onClick={() => setRate("fixed")}
                        className={rate === "fixed" ? "active" : ""}
                      >
                        Fixed Amount
                      </button>
                    </FormButtonToggle>
                  </FormGroup>
                </FormGrid>
                <FormGrid columns={2}>
                  {rate === "percent" && (
                    <FormGroup
                      component={"input"}
                      defaultValue={0}
                      label={"Amend Rates by Percent %"}
                      name="rate_percent"
                      onChange={handleInputChange}
                      type="number"
                      error={error.rate_percent}
                    />
                  )}
                  {rate === "fixed" && (
                    <FormGroup
                      component={"input"}
                      defaultValue={0}
                      label={"Amend Rates by Amount £"}
                      name="rate_amount"
                      onChange={handleInputChange}
                      type="number"
                      error={error.rate_amount}
                    />
                  )}
                  <FormGroup
                    component={"react-select"}
                    label={"Rate Esc Authoriser"}
                    options={authorisers}
                    placeholder="Select an authoriser"
                    name="rate_authoriser"
                    onChange={handleAuthoriserChange}
                    required
                    error={error.rate_authoriser}
                  />
                </FormGrid>
              </FormDetailGroup>
            </AmendRateForm>
          </ModalBody>
          <ModalFooter>
            <div />
            <div>
              <button className="btn btn-close" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn text-white btn-cyan--modal"
                onClick={() => firstAmendAction()}
              >
                Amend Shifts
              </button>
            </div>
          </ModalFooter>
        </>
      ) : modalStep === "step-two" ? (
        <>
          {loading ? (
            <>
              <ModalBody>
                <Loading />
              </ModalBody>
              <ModalFooter>
                <div />
                <div>
                  <button className="btn btn-close" onClick={handleClose}>
                    Cancel
                  </button>
                </div>
              </ModalFooter>
            </>
          ) : (
            <>
              <ModalBody>
                <TableComponent hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Date</th>
                      <th>Hours</th>
                      <th>Department</th>
                      <th>Grade</th>
                      <th>Total Rate</th>
                      <th>Amended Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shiftList?.map((shift) => {
                      const percentage = inputs.rate_percent;
                      const amendedCore =
                        rate === "percent"
                          ? Number(
                              (percentage / 100) * shift.coreTotalRate +
                                shift.coreTotalRate
                            ).toFixed(2)
                          : Number(
                              Number(inputs.rate_amount) +
                                Number(shift.coreTotalRate)
                            ).toFixed(2);
                      const amendedUnsocial =
                        rate === "percent"
                          ? Number(
                              (percentage / 100) * shift.unsocialTotalRate +
                                shift.unsocialTotalRate
                            ).toFixed(2)
                          : Number(
                              Number(inputs.rate_amount) +
                                Number(shift.unsocialTotalRate)
                            ).toFixed(2);

                      return (
                        <tr key={shift.id}>
                          <td>{shift.id}</td>
                          <td>{moment(shift.startTime).format("Do MMM YY")}</td>
                          <td>
                            {moment(shift.startTime).format("h:mma")} - <br />
                            {moment(shift.endTime).format("h:mma")}
                          </td>
                          <td>
                            {shift.department?.name}
                            <br />
                            {shift.site?.name}
                          </td>
                          <td>{shift.grade?.title}</td>
                          <td>
                            £{Number(shift.coreTotalRate).toFixed(2)} / £
                            {Number(shift.unsocialTotalRate).toFixed(2)}
                          </td>
                          <td style={{ color: "#FC6B6B" }}>
                            £{shift.newCoreRate} / £{shift.newUnsocialRate}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </TableComponent>
              </ModalBody>
              <ModalFooter>
                <div>
                  {error?.amend && (
                    <ErrorContainer>
                      <p>{error.amend}</p>
                    </ErrorContainer>
                  )}
                </div>
                <div>
                  <button className="btn btn-close" onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    className="btn text-white btn-cyan--modal"
                    onClick={() => amendRatesAction()}
                  >
                    Amend {shifts?.length} Shifts
                  </button>
                </div>
              </ModalFooter>
            </>
          )}
        </>
      ) : (
        ""
      )}
    </Modal>
  );
};

export default AmendRateModal;

const AmendRateForm = styled.div`
  max-width: 500px;
`;

const TableComponent = styled(Table)`
  margin: 0;

  thead {
    box-shadow: none;

    th {
      border-top: 0;
      font-size: 10px;
      text-transform: uppercase;
    }
  }

  th,
  td {
    font-size: 15px;
    padding: 16px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
`;
