/* eslint-disable react/button-has-type */
import React, { useState } from 'react'
import { TableRowDeleteIcon, TableRowEditIcon } from '../Assets/Icons'
import { TableContainer, TableHeaderContainer, TableSearchInput, TableComponent } from '../Tables/TableElements'
import ConfirmModal from './ConfirmModal'
import ReasonsModal from './ReasonsModal'

function Reasons({ reasons, type }) {
  const [reason, setReason] = useState(undefined)
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [value, setValue] = useState('')

  const handleClose = () => {
    setReason(undefined)
    setShow(false)
  }

  const handleCloseConfirm = () => {
    setReason(undefined)
    setShowConfirm(false)
  }

  const handleShowConfirm = (reason) => {
    setReason(reason && reason)
    setShowConfirm(true)
  }

  const handleShowCreate = () => {
    setReason(undefined)
    setShow(true)
  }

  const handleShowEdit = (reason) => {
    setReason(reason && reason)
    setShow(true)
  }

  // // search function
  const filteredReasons = value
    ? reasons.filter((item) => {
        return item.reason.toLowerCase().includes(value.toLowerCase())
      })
    : reasons

  return (
    <>
      <TableContainer>
        <TableHeaderContainer>
          <TableSearchInput
            onChange={(e) => setValue(e.target.value)}
            placeholder="Search Reasons..."
            type="text"
            value={value}
          />
          <button className="btn btn-cyan" onClick={handleShowCreate}>
            <svg fill="none" height="22" width="22" xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                fill="#1E2D36"
                fillRule="evenodd"
              />
            </svg>
            Create Reason
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>{type}</th>
              {/* <th>Created At</th> */}
            </tr>
          </thead>
          <tbody>
            {filteredReasons?.map((reason, index) => (
              <tr key={index}>
                <td>{reason.reason}</td>
                <td>{reason.reason_type}</td>
                <td style={{ textAlign: 'right' }}>
                  <button onClick={() => handleShowEdit(reason)}>
                    <TableRowEditIcon />
                  </button>
                  <button onClick={() => handleShowConfirm(reason)}>
                    <TableRowDeleteIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </TableContainer>
      <ReasonsModal handleClose={handleClose} reason={reason} show={show} type={type} />
      <ConfirmModal handleCloseConfirm={handleCloseConfirm} reason={reason} show={showConfirm} type={type} />
    </>
  )
}

export default Reasons
