import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../../packs/application";

import SendToAgency from "./SendToAgency";

function SendToAgencyIndex() {
  return (
    <ApolloProvider client={client}>
      <SendToAgency />
    </ApolloProvider>
  );
}

export default SendToAgencyIndex;
