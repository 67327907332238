/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */

import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  FormErrorLabel,
  FormGrid,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  FormBreak,
  FormToggle,
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@patchworkhealth/web-components'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import DayPickerInput, { DateUtils } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import * as Yup from 'yup'
import { formatTimeInTZ, parseTimeInTZ } from '../../../Helpers/momentHelpers'
import {
  loadingToast,
  errorToast,
  successToast,
  CREATE_SHIFTS,
  DEPARTMENT,
  DEPARTMENTS,
  UPDATE_DRAFT_SHIFT,
} from '../helpers/BlockBookingsHelpers'
import { CheckboxSVG, CheckboxWrap, HiddenCheckbox, StyledCheckbox, ShiftModalTitle } from '../helpers/BlockComponents'
import { TimeIcon } from '../helpers/BlockIcons'
import { DateInput, FormInputGroup } from '../helpers/dateInput'
import { modHelperTextFunction, FormGroupMod } from './CreateBlockModal'
import { ShiftCard } from './ViewBlockDetails'

function ShiftsModal({
  calendarDetails,
  details,
  edit,
  handleClose,
  modOrganisation,
  refetch,
  shiftDetails,
  shiftModalDate,
}) {
  // State *************************
  const [selectedDays, setSelectedDays] = useState([])
  //
  const [totalHours, setTotalHours] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  //
  const [sitesSkip, setSitesSkip] = useState(true)
  const [departmentSkip, setDepartmentSkip] = useState(true)
  //
  const [formikValidation, setFormikValidation] = useState(false)
  //
  const [departments, setDepartments] = useState([])
  const [costCentres, setCostCentres] = useState([])
  const [defaultCentre, setDefaultCentre] = useState(null)
  //

  const { useDefaultBreaks } = calendarDetails.grade

  // Gql ****************************

  const [addMutation] = useMutation(CREATE_SHIFTS)
  const [updateMutation] = useMutation(UPDATE_DRAFT_SHIFT)

  // Functions *********************************

  const handleCloseAction = () => {
    setSelectedDays([])
    setStartTime(null)
    setEndTime(null)
    setTotalHours(null)
    setFormikValidation(false)
    handleClose()
  }

  const handleDayClick = (day, { selected }) => {
    const selectedDays1 = selectedDays.concat()
    if (selected) {
      const selectedIndex = selectedDays1.findIndex((selectedDay) => DateUtils.isSameDay(selectedDay, day))
      selectedDays1.splice(selectedIndex, 1)
    } else {
      selectedDays1.push(day)
    }
    setSelectedDays(selectedDays1)
  }

  const getTimes = () => {
    const start = [startTime.split(':')[0], startTime.split(':')[1]]
    const end = [endTime.split(':')[0], endTime.split(':')[1]]
    const timeArray = [Number(start[0] * 60) + Number(start[1]), Number(end[0] * 60) + Number(end[1])]
    return { timeArray }
  }

  const checkDisabled = () => {
    const status = calendarDetails.status === 'BOOKED'
    return status
  }

  // UseEffects *********************

  useEffect(() => {
    if (shiftModalDate) {
      setSelectedDays([new Date(shiftModalDate)])
    }
  }, [shiftModalDate])

  useEffect(() => {
    if (startTime !== null && endTime !== null) {
      const { timeArray } = getTimes()

      let diff = 0

      if (timeArray[1] <= timeArray[0]) {
        diff = timeArray[1] + 1440 - timeArray[0]
      } else {
        diff = timeArray[1] - timeArray[0]
      }

      setTotalHours(diff / 60)
    }
  }, [startTime, endTime])

  // Form ************************************

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: formikValidation,
    initialValues: {
      adminNotes: shiftDetails ? shiftDetails.adminNote : '',
      costCentre: shiftDetails ? [{ label: shiftDetails.costCentre, value: shiftDetails.costCentre }] : [],
      department: shiftDetails ? [shiftDetails?.department] : [],
      frequency: {
        label: 'Does not repeat',
        value: 0,
      },
      breaks: shiftDetails ? shiftDetails.breaks : useDefaultBreaks ? null : '',
      overrideCC: false,
      site: shiftDetails ? [shiftDetails?.site] : [],
      untilDate: '',
      untilEnd: false,
      workerNotes: shiftDetails ? shiftDetails.note : '',
    },
    validationSchema: Yup.object({
      department: Yup.array().min(1, 'Required'),
      site: Yup.array().min(1, 'Required'),
    }),
    onSubmit: (values) => {
      const frequency = values.frequency.value

      let frequencyValue

      if (frequency === 1) {
        frequencyValue = 'WEEKLY'
      } else if (frequency === 2) {
        frequencyValue = 'EVERY_WEEKDAY'
      } else if (frequency === 3) {
        frequencyValue = 'CUSTOM'
      } else {
        frequencyValue = 'SINGLE'
      }

      if (startTime == null || endTime === null) {
        toast.error('Please enter a start and end time', { hideProgressBar: true, position: 'top-right' })
        return
      }

      if ((frequency === 1 || frequency === 2) && !values.untilEnd && values.untilDate === '') {
        toast.error('Please Select an End Date', { hideProgressBar: true, position: 'top-right' })

        return
      }

      toast.loading('Loading', loadingToast)

      /*
          Here, we grab 24 hour clock [20:53], calculate hours + mins,
          covert to total mins,
          then add to start of moment() day
      */

      const { timeArray } = getTimes()

      if (timeArray[1] <= timeArray[0]) {
        timeArray[1] += 1440
      }

      const createStartTime = parseTimeInTZ(shiftModalDate)
        .startOf('day')
        .add(timeArray[0], 'minutes')
        .format('YYYY-MM-DDTHH:mm:00')
      const createEndTime = parseTimeInTZ(shiftModalDate)
        .startOf('day')
        .add(timeArray[1], 'minutes')
        .format('YYYY-MM-DDTHH:mm:00')

      const editStartTime = parseTimeInTZ(shiftDetails?.proposedStartTime)
        .startOf('day')
        .add(timeArray[0], 'minutes')
        .format('YYYY-MM-DDTHH:mm:00')

      const editEndTime = parseTimeInTZ(shiftDetails?.proposedStartTime)
        .startOf('day')
        .add(timeArray[1], 'minutes')
        .format('YYYY-MM-DDTHH:mm:00')

      /*
      1. Build Conditional Params
      2. Based if its edit/create fire off correct mutation
      3. Don't perform refetch, use object data from mutation, write to cache, and rebuild Table locally

      */

      const baseVariables = {
        adminNote: values.adminNotes,
        breaks: Number(values.breaks),
        departmentId: values.department[0].value,
        note: values.workerNotes,
        proposedEndTime: edit ? editEndTime : createEndTime,
        proposedStartTime: edit ? editStartTime : createStartTime,
        siteId: values.site[0].value,
        ...((frequency === 1 || frequency === 2) && {
          frequencyEnd: values.untilEnd ? calendarDetails?.endDate : values.untilDate,
        }),
        ...(frequency === 3 && {
          frequencyStartDates: selectedDays.map((element) => parseTimeInTZ(element).format('YYYY-MM-DDTHH:mm:00')),
        }),
        ...(values.overrideCC && {
          costCentreCode: values?.costCentre[0]?.label,
        }),
      }

      let customVariables = {}

      if (edit) {
        customVariables = {
          draftShiftId: Number(shiftDetails.id),
        }
      } else {
        customVariables = {
          shiftBlockId: Number(calendarDetails.id),
          frequency: frequencyValue,
        }
      }

      // Create Mutation *************************************
      !edit
        ? addMutation({
            variables: {
              shiftsAttributes: {
                ...baseVariables,
                ...customVariables,
              },
            },
            update(cache, res) {
              const errors = res.data?.bulkCreateOnBlock?.errors

              if (errors.length > 0) {
                let [{ message }] = errors

                if (errors[0].attribute) {
                  message = `${errors[0].attribute.charAt(0).toUpperCase() + errors[0].attribute.slice(1)} ${message}`
                }

                toast.update(2, { ...errorToast, render: message })
                return
              }

              const chosenShifts = res.data.bulkCreateOnBlock.shifts
              const shiftIds = new Set([...calendarDetails.shiftIds, chosenShifts[0].id])

              const newObject = {
                nodes: [
                  {
                    ...calendarDetails,
                    shifts: [...calendarDetails.shifts, ...chosenShifts],
                    shiftIds: [...shiftIds],
                  },
                ],
              }

              const message = `Shift${chosenShifts.length > 1 ? `(s)` : ''} Created`

              handleCloseAction()
              refetch(newObject.nodes[0])
              formik.resetForm()
              toast.update(2, { ...successToast, render: message })
            },
          }).catch(() => {
            toast.update(2, errorToast)
          })
        : updateMutation({
            variables: {
              draftShiftAttributes: {
                ...baseVariables,
                ...customVariables,
              },
            },
            update(cache, res) {
              const errors = res.data?.updateDraftShift?.errors

              if (errors.length > 0) {
                toast.update(2, { ...errorToast, render: errors[0].message })
                return
              }

              const chosenShifts = res.data.updateDraftShift.draftShift

              const newObject = {
                nodes: [
                  {
                    ...calendarDetails,

                    shifts: calendarDetails.shifts.map((cc) => {
                      return cc.id === chosenShifts.id
                        ? {
                            ...chosenShifts,
                          }
                        : cc
                    }),
                  },
                ],
              }

              handleCloseAction()
              refetch(newObject.nodes[0])
              formik.resetForm()
              toast.update(2, { ...successToast, render: 'Shift Updated' })
            },
          }).catch(() => {
            toast.update(2, { ...errorToast, render: 'Shift could not be updated' })
          })
    },
  })

  // Handle onChange GQL fetches ******************************

  useQuery(DEPARTMENT, {
    skip: departmentSkip,
    fetchPolicy: 'no-cache',
    variables: {
      id: formik?.values?.department[0]?.value,
    },
    onCompleted: (res) => {
      setDepartmentSkip(true)
      setCostCentres(res.department.associatedCostCentres)
      setDefaultCentre(res.department.costCentre.label)

      if (edit && shiftDetails.costCentre !== res.department.costCentre.label) {
        formik.setFieldValue('overrideCC', !formik.values.overrideCC)
      }
    },
  })

  useQuery(DEPARTMENTS, {
    skip: sitesSkip,
    fetchPolicy: 'no-cache',
    variables: {
      siteId: formik?.values?.site[0]?.value,
    },
    onCompleted: (res) => {
      setSitesSkip(true)
      setDepartments(res.departments)
    },
  })

  useEffect(() => {
    if (!edit) {
      formik.resetForm()
      return
    }
    setStartTime(formatTimeInTZ(shiftDetails.proposedStartTime))
    setEndTime(formatTimeInTZ(shiftDetails.proposedEndTime))
    setDepartmentSkip(false)
  }, [edit])

  return (
    <Modal backdrop="static" centered onHide={handleClose} show size="lg">
      <ModalHeader>
        {edit && (
          <ModalTitle>
            Shift{' '}
            <a
              href={`/shifts?filter_by_shift_blocks=true&only_search_id=true&shift_ids%5B%5D=${shiftDetails.id}`}
              rel="noreferrer"
              style={{ textDecoration: 'underline' }}
              target="_blank"
            >
              {shiftDetails?.id}
            </a>
          </ModalTitle>
        )}

        {!edit && <ModalTitle>Add Shift</ModalTitle>}

        <strong style={{ marginRight: -340, fontWeight: 500 }}>
          {formatTimeInTZ(calendarDetails?.startDate, 'Do MMM YYYY')} -{' '}
          {formatTimeInTZ(calendarDetails?.endDate, 'Do MMM YYYY')}
        </strong>
        <CloseButton
          onClick={() => {
            handleCloseAction()
            formik.resetForm()
          }}
        />
      </ModalHeader>

      <ModalBody style={{ minHeight: 700 }}>
        <ShiftModalTitle>Location Information</ShiftModalTitle>
        <FormGrid columns={3} style={{ marginBottom: 28 }}>
          <FormGroupMod disabled={checkDisabled()}>
            <FormLabel>
              Site <span>*</span>
            </FormLabel>
            <FormSelect
              onChange={(option) => {
                formik.setFieldValue('site', option !== null ? [option] : [])
                formik.setFieldValue('department', [])
                setSitesSkip(false)
              }}
              options={details && details.sites}
              placeholder="Select sites"
              value={formik.values.site}
            />

            {modOrganisation && modHelperTextFunction(9, true)}
            {formik.errors.site && <FormErrorLabel>{formik.errors.site}</FormErrorLabel>}
          </FormGroupMod>

          <FormGroupMod disabled={checkDisabled()}>
            <FormLabel>
              Unit <span>*</span>
            </FormLabel>
            <FormSelect
              label="Unit"
              onChange={(option) => {
                formik.setFieldValue('department', option !== null ? [option] : [])
                formik.setFieldValue('costCentre', [])
                setDepartmentSkip(false)
              }}
              options={departments}
              placeholder="Select Unit"
              value={formik.values.department}
            />

            {modOrganisation && modHelperTextFunction(10, true)}
            {formik.errors.department && <FormErrorLabel>{formik.errors.department}</FormErrorLabel>}
          </FormGroupMod>

          <FormGroupMod disabled={useDefaultBreaks}>
            <FormLabel>Length of Break (Minutes)</FormLabel>
            <FormInput
              name="breaks"
              onChange={(e) =>
                formik.setFieldValue('breaks', e.target.value === '' ? useDefaultBreaks : e.target.value)
              }
              placeholder={useDefaultBreaks ? 'Default breaks are enabled' : 'Enter break minutes'}
              type="number"
              value={formik.values.breaks}
            />

            {modOrganisation && modHelperTextFunction(11, true)}
          </FormGroupMod>
        </FormGrid>

        <FormBreak />

        <ShiftModalTitle>
          Date Selected:
          {formatTimeInTZ(edit ? shiftDetails.proposedStartTime : shiftModalDate, ' Do MMMM YYYY')}
        </ShiftModalTitle>
        <FormGrid columns={3} style={{ marginBottom: 28 }}>
          <FormGroup disabled={checkDisabled()}>
            <FormLabel>
              Start Time <span>*</span>
            </FormLabel>

            <FormInputGroup>
              <FormInputGroup.Prepend>
                <FormInputGroup.Text style={{ background: '#F5F7FA' }}>
                  <TimeIcon />
                </FormInputGroup.Text>
              </FormInputGroup.Prepend>

              <input name="startTime" onChange={(e) => setStartTime(e.target.value)} type="time" value={startTime} />
            </FormInputGroup>
          </FormGroup>
          <FormGroup disabled={checkDisabled()}>
            <FormLabel>
              End Time <span>*</span>
            </FormLabel>

            <FormInputGroup>
              <FormInputGroup.Prepend>
                <FormInputGroup.Text style={{ background: '#F5F7FA' }}>
                  <TimeIcon />
                </FormInputGroup.Text>
              </FormInputGroup.Prepend>

              <input name="endTime" onChange={(e) => setEndTime(e.target.value)} type="time" value={endTime} />
            </FormInputGroup>
          </FormGroup>

          <ShiftCard color="blue">
            <FormLabel>Total hours including breaks</FormLabel>
            <p>{totalHours !== null ? totalHours.toFixed(2) : '-'}</p>
          </ShiftCard>
        </FormGrid>

        <FormGrid columns={2} full>
          {!edit && (
            <FormGroup>
              <FormLabel>Frequency</FormLabel>
              <FormSelect
                label="Frequency"
                onChange={(option) => {
                  formik.setFieldValue('frequency', option)
                }}
                options={[
                  {
                    label: 'Does not repeat',
                    value: 0,
                  },
                  {
                    label: `Weekly on a ${formatTimeInTZ(shiftModalDate, 'dddd')}`,
                    value: 1,
                  },
                  {
                    label: 'Every Weekday (Monday to Friday)',
                    value: 2,
                  },
                  {
                    label: 'Custom',
                    value: 3,
                  },
                ]}
                value={formik.values.frequency}
              />
            </FormGroup>
          )}

          {(formik.values.frequency.value === 2 || formik.values.frequency.value === 1) && !edit && (
            <FormGroup>
              <FormLabel>Until</FormLabel>
              <DateInput
                month={shiftModalDate && new Date(shiftModalDate)}
                name="untilDate"
                onChange={(e) => formik.setFieldValue('untilDate', e)}
                range={{
                  after: new Date(calendarDetails?.endDate),
                  before: new Date(calendarDetails?.startDate),
                }}
              />
              <div
                style={{
                  alignItems: 'center',
                  display: 'flex',
                  marginTop: 10,
                }}
              >
                <CheckboxWrap checked>
                  <HiddenCheckbox
                    isChecked={formik.values.untilEnd}
                    onChange={() => {
                      formik.setFieldValue('untilEnd', !formik.values.untilEnd)
                    }}
                  />
                  <StyledCheckbox isChecked={formik.values.untilEnd}>
                    <CheckboxSVG />
                  </StyledCheckbox>
                </CheckboxWrap>
                <span style={{ margin: '-7px 0 0 5px' }}>Repeat until block ends</span>
              </div>
            </FormGroup>
          )}

          {formik.values.frequency.value === 3 && !edit && (
            <DayPickerInput
              disabledDays={{
                after: new Date(calendarDetails?.endDate),
                before: new Date(calendarDetails?.startDate),
              }}
              month={shiftModalDate && new Date(shiftModalDate)}
              onDayClick={handleDayClick}
              selectedDays={selectedDays}
            />
          )}
        </FormGrid>

        <FormBreak />

        <FormGrid columns={2}>
          <FormGroupMod disabled={false}>
            {defaultCentre !== null && <DefaultCostCentre>{defaultCentre}</DefaultCostCentre>}
            <FormLabel>Cost Centre</FormLabel>
            <FormToggle
              action={() => {
                formik.setFieldValue('overrideCC', !formik.values.overrideCC)
              }}
              label="Override default"
              name="overrideCC"
              value={formik.values.overrideCC}
            />

            {modOrganisation && modHelperTextFunction(12, false)}
          </FormGroupMod>

          <FormGroupMod>
            <FormLabel>Override With</FormLabel>
            <FormSelect
              disabled={!formik?.values.overrideCC}
              isClearable
              onChange={(option) => {
                formik.setFieldValue('costCentre', option !== null ? [option] : [])
              }}
              options={costCentres}
              placeholder="Select an override"
              value={formik.values.costCentre}
            />
          </FormGroupMod>
        </FormGrid>

        <FormBreak />
        <FormGrid columns={2}>
          <FormGroup>
            <FormLabel>Worker Notes (Visible in Worker App)</FormLabel>
            <FormInput
              as="textarea"
              name="workerNotes"
              onChange={formik.handleChange}
              placeholder="Enter notes here"
              rows={6}
              value={formik.values.workerNotes}
            />

            <p style={{ fontSize: 12, marginTop: 6 }}>
              eg. Bleep numbers, who and where to report to, logins for computers and access codes for doors.
            </p>
          </FormGroup>

          <FormGroup>
            <FormLabel>Admin notes (Visible only to Hub users)</FormLabel>
            <FormInput
              as="textarea"
              name="adminNotes"
              onChange={formik.handleChange}
              placeholder="Enter notes here"
              rows={6}
              value={formik.values.adminNotes}
            />
          </FormGroup>
        </FormGrid>
      </ModalBody>

      <ModalFooter>
        <div />
        <div>
          <DefaultButton
            action={() => {
              handleCloseAction()
              formik.resetForm()
            }}
            text="Cancel"
            type="white"
          />

          {calendarDetails.status === 'DRAFT' && (
            <DefaultButton
              action={() => {
                formik.handleSubmit()
                setFormikValidation(true)
              }}
              color="blue"
              text={edit ? 'Save Changes' : 'Add Shift'}
            />
          )}

          {calendarDetails.status === 'AVAILABLE' && !edit && (
            <DefaultButton
              action={() => {
                formik.handleSubmit()
                setFormikValidation(true)
              }}
              color="blue"
              text={edit ? 'Save Changes' : 'Add Shift'}
            />
          )}
        </div>
      </ModalFooter>
    </Modal>
  )
}
export default ShiftsModal

const DefaultCostCentre = styled.div`
  align-items: center;
  background: #ffece4;
  border-radius: 4px;
  display: flex;
  height: 23px;
  left: 102px;
  padding: 4px 8px;
  position: absolute;
  top: -4px;
`
