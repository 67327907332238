import { Controller } from 'stimulus'

export default class AccordionSectionController extends Controller {

    static get targets() {
        return ['content']
    }

    connect () {
        this.isOpen = false
    }

    open() {
        this.contentTarget.classList.remove('d-none')
        this.isOpen = true
    }

    close() {
        this.contentTarget.classList.add('d-none')
        this.isOpen = false
    }

    toggle() {
        if(this.isOpen) {
            this.close()
        } else {
            this.open()
        }
    }
}

import { application } from '../../frontend/controllers/index'
application.register("accordion_section", AccordionSectionController)