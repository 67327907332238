export async function bulkRatesEscalation(shift_ids, rate_authoriser_id, amendment_type, raise_percent, raise_amount) {

  const query = `mutation($shift_ids: [Int!]!, $rate_authoriser_id: Int!, $amendment_type: String!, $raise_percent: Float, $raise_amount: Float){
    bulkRatesEscalation(shiftIds: $shift_ids, rateAuthoriserId: $rate_authoriser_id, amendmentType: $amendment_type, raisePercent: $raise_percent, raiseAmount: $raise_amount) {
      success
      failedShifts {
        id
        errors
      }
    }
  }`;

  const data = fetch("/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({
      query,
      variables: { shift_ids, rate_authoriser_id, amendment_type, raise_percent, raise_amount },
    }),
  }).then(r => r.json())
    .then(data => data);
  return data
}
