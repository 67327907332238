import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import queryString from'query-string'


export default class AgencyTitleSelectorController extends Controller {

    static get targets() {
        return ['title', 'agency_title_selector']
    }
    connect() {
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target =  document.getElementById('agencies-selector');
        const searchOnSelect = this.data.get('search-on-select');

        this.select = new SlimSelect({
            select: '#agencies-selector',
            onChange: () => {
                let params = queryString.parse(location.search);
                if(searchOnSelect){
                    if( params['agency_title'] != target.value) {
                        params['agency_title'] = target.value;
                        const url = queryString.stringify(params);
                        window.location.search = url
                    }
                }
            }
        });

        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }

    }


}
import { application } from '../../frontend/controllers/index'


application.register("agency_title_selector", AgencyTitleSelectorController)