import React, { useState, useEffect } from 'react'
import { Slide, ToastContainer, toast } from 'react-toastify'
import styled from 'styled-components'
import consumer from '../../../channels/consumer'
import DropdownComponent, { ActionsDropdownMenuItem } from '../Dropdown'

function AuthHeader({ paymentGroup, subtitle, title, user, userType }) {
  // Setup WebSocket listening connection for NotificationsChannel

  useEffect(() => {
    const subscription = consumer.subscriptions.create('NotificationsChannel', {
      received(data) {
        const toastOptions = {
          position: 'top-right',
          autoClose: 8000,
          isLoading: false,
          hideProgressBar: true,
          closeOnClick: true,
        }

        if (data.message === "Operation 'Bulk assign worker' succeeded") {
          window.location.reload()
        }

        if (!window.location.pathname.includes('/blocks')) {
          if (data.success) toast.success(data.message, toastOptions)
          else toast.error(data.message, toastOptions)
        }
      },
    })

    // Unsubscribe from the NotificationsChannel when the component unmounts
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  const [search, setSearch] = useState('')
  const unreadCount = user?.unread_hub_notifications_count ? user.unread_hub_notifications_count : 0
  const useKeycloak = user?.use_keycloak ? user?.use_keycloak : false

  const searchIds = (e) => {
    e.preventDefault()
    if (search.length > 0) {
      const shiftIds = search.split(',')

      const url = `shift_ids[]=${shiftIds.join('&shift_ids[]=')}`
      window.location.href = `shifts?${url}`
    }
  }

  const logoutAction = () => {
    const url = `/${userType === 'AgencyUser' ? 'agency_users' : 'hub_users'}/sign_out`

    fetch(url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        window.location.href = `/${userType === 'AgencyUser' ? 'agency_users' : 'hub_users'}/sign_in`
      }
    })
  }

  return (
    <>
      <ToastContainer position="top-center" transition={Slide} />
      <AuthHeaderContainer>
        <div>
          <AuthHeaderSubtitle>{subtitle}</AuthHeaderSubtitle>
          <AuthHeaderTitle>{title}</AuthHeaderTitle>
        </div>
        <AuthHeaderRight>
          {paymentGroup ? (
            <AuthHeaderPaymentGroup href="/payments/dashboard">
              Staff Group: <span>{paymentGroup}</span>
            </AuthHeaderPaymentGroup>
          ) : (
            <form onSubmit={(e) => searchIds(e)}>
              <AuthHeaderSearch onChange={(e) => setSearch(e.target.value)} placeholder="Search Shift IDs" />
            </form>
          )}
          <AuthHeaderIcon>
            {unreadCount > 0 && <AuthHeaderIconCount>{unreadCount}</AuthHeaderIconCount>}

            {userType === 'AgencyUser' ? (
              <span>
                <svg height="26" width="21" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.268 22.4c.832 0 1.333.906.912 1.623a3.197 3.197 0 01-5.515 0c-.421-.717.08-1.623.912-1.623h3.69zM2.465 7.45A7.978 7.978 0 0110.903.015c4.053.238 7.226 3.603 7.493 7.653l.501 7.644c.016.268.101.527.245.754l1.451 2.276c.677 1.065-.09 2.459-1.35 2.459H1.608c-1.264 0-2.032-1.394-1.355-2.46l1.45-2.275c.145-.227.23-.486.246-.754z"
                    fill="#FFF"
                    fillRule="evenodd"
                    opacity={unreadCount === 0 ? 0.4 : 1}
                  />
                </svg>
              </span>
            ) : (
              <a href="/hub_notifications">
                <svg height="26" width="21" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.268 22.4c.832 0 1.333.906.912 1.623a3.197 3.197 0 01-5.515 0c-.421-.717.08-1.623.912-1.623h3.69zM2.465 7.45A7.978 7.978 0 0110.903.015c4.053.238 7.226 3.603 7.493 7.653l.501 7.644c.016.268.101.527.245.754l1.451 2.276c.677 1.065-.09 2.459-1.35 2.459H1.608c-1.264 0-2.032-1.394-1.355-2.46l1.45-2.275c.145-.227.23-.486.246-.754z"
                    fill="#FFF"
                    fillRule="evenodd"
                    opacity={unreadCount === 0 ? 0.4 : 1}
                  />
                </svg>
              </a>
            )}
          </AuthHeaderIcon>
          <DropdownComponent user={user}>
            {useKeycloak === false && (
              <ActionsDropdownMenuItem href="/hub_users/edit">Change Password</ActionsDropdownMenuItem>
            )}
            <ActionsDropdownMenuItem onClick={logoutAction}>Logout</ActionsDropdownMenuItem>
          </DropdownComponent>
        </AuthHeaderRight>
      </AuthHeaderContainer>
    </>
  )
}

export default AuthHeader

export const AuthHeaderContainer = styled.div`
  align-items: 'flex-start';
  border-bottom: 1px solid rgba(151, 151, 151, 0.1);
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 24px;
`

export const AuthHeaderTitle = styled.h1`
  color: #fff;
  font-size: 28px;
  font-weight: 500;
  line-height: 0.75;
  margin: 0;
`

export const AuthHeaderSubtitle = styled.h6`
  align-self: flex-start;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.7px;
  margin-bottom: 10px;
  opacity: 0.5;
  text-transform: uppercase;
`

export const AuthHeaderRight = styled.div`
  align-items: center;
  display: flex;
`

export const AuthHeaderIcon = styled.div`
  margin-right: 20px;
  position: relative;
`

export const AuthHeaderIconCount = styled.div`
  align-items: center;
  background: #ff6c6c;
  border: 1px solid #1e2d36;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: 700;
  height: 17px;
  justify-content: center;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 17px;
`

export const AuthHeaderSearch = styled.input`
  background: url("data:image/svg+xml,%3Csvg width='18' height='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.143 7.714c0-3.072 2.5-5.571 5.571-5.571 3.072 0 5.572 2.5 5.572 5.571 0 3.072-2.5 5.572-5.572 5.572a5.578 5.578 0 01-5.571-5.572zm12.326 3.725a7.714 7.714 0 10-3.03 3.03l2.903 2.904a2.143 2.143 0 003.03-3.031l-2.903-2.903z' fill='%239CA8B4' fill-rule='evenodd' /%3E%3C/svg%3E")
    #fff no-repeat;
  background-position: center left 10px;
  border: 0;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  height: 40px;
  margin-right: 20px;
  padding-left: 36px;
  width: 200px;
`

const AuthHeaderPaymentGroup = styled.a`
  align-items: center;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 4px;
  color: hsla(0, 0%, 100%, 0.7);
  display: flex;
  font-size: 14px;
  height: 40px;
  margin-right: 20px;
  padding: 0 15px;
  &:hover {
    color: hsla(0, 0%, 100%, 0.7);
  }
  span {
    color: #fff;
    font-weight: 500;
    margin-left: 3px;
  }
`
