import { Controller } from 'stimulus'
import Ajax from '../../frontend/packs/ajax'

export default class WorkerPersonalInfoEditorController extends Controller {
    static get targets() {
        return ['workerFirstName', 'workerLastName', 'workerDOB', 'saveWorkerPersonalInfoButton',
                'firstNameError', 'firstNameWarning', 'lastNameError', 'lastNameWarning',
                'dOBError']
    }

    connect() {
      this.saveButtonDisabled = true;
      this.workerId = this.data.get('worker-id');
      this.saveInitialValues();
    }

    save() {
      if(this.saveButtonDisabled) {
        return; // Do not save invalid data
      }

      this.disableSaveButton();
      this.cleanAllBorders();
      this.cleanErrors();

      var ajax = new Ajax();
      ajax.post('/workers/update_personal_info', {
        worker_id: this.workerId,
        first_name: this.workerFirstNameTarget.value,
        last_name: this.workerLastNameTarget.value,
        date_of_birth: this.workerDOBTarget.value
      }, (data) => {
        this.processResponse(data);
      });
    }

    processResponse(data){
      var data;
      try {
        data = JSON.parse(data);
      }
      catch(err) {
        alert('Error connecting to the server!');
        return;
      }

      if(data.success) {
        this.saveWorkerPersonalInfoButtonTarget.classList.add('d-none');
        this.cleanBorder(this.workerFirstNameTarget);
        this.cleanBorder(this.workerLastNameTarget);
        this.cleanBorder(this.workerDOBTarget);
        this.saveInitialValues();
      } else {
        this.showErrors(data.errors);
      }
    }

    fieldChanged(event) {
      var target = event.target;
      // check if changed
      var amended = this.fields[target.id]['initial'] != target.value;
      this.fields[target.id]['amended'] = amended;
      // check validity
      this.validateField(this.fields[target.id])

      this.decorateFields();
      this.decorateButton();
    }

    validateField(field) {
      switch(field.id) {
        case 'workerFirstName':
          var target = this.workerFirstNameTarget;
          field.valid = this.nameRegExp().test(target.value);
          this.extraNameValidation(target, this.firstNameWarningTarget);
          break;
        case 'workerLastName':
          var target = this.workerLastNameTarget;
          field.valid = this.nameRegExp().test(target.value);
          this.extraNameValidation(target, this.lastNameWarningTarget);
          break;
        case 'workerDOB':
          var val = this.workerDOBTarget.value;
          // regex for valid dates in DD/MM/YYYY 
          var regex = /^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/g;

          field.valid = regex.test(val);
          break;
      }
    }

    extraNameValidation(valueTarget, warningTarget) {
      var val = valueTarget.value;
      var doubleSpaceRegex = new RegExp(" {2,}");
      var trailingSpaceRegex = new RegExp(". +$");
      if(
        doubleSpaceRegex.test(val) ||
        trailingSpaceRegex.test(val)
       ) {
        warningTarget.classList.remove('d-none')
      } else {
        warningTarget.classList.add('d-none')
      }
    }

    decorateButton() {
      if( this.anythingAmended() ) {
        this.saveWorkerPersonalInfoButtonTarget.classList.remove('d-none');
      } else {
        this.saveWorkerPersonalInfoButtonTarget.classList.add('d-none');
      }

      if( this.everythingValid() ) {
        this.enableSaveButton();
        this.saveButtonDisabled = false;
      } else {
        this.disableSaveButton();
        this.saveButtonDisabled = true;
      }
    }

    enableSaveButton() {
      this.saveWorkerPersonalInfoButtonTarget.classList.remove('disabled');
    }

    disableSaveButton() {
      this.saveWorkerPersonalInfoButtonTarget.classList.add('disabled');
    }

    anythingAmended() {
      return (this.fields.workerFirstName.amended ||
              this.fields.workerLastName.amended ||
              this.fields.workerDOB.amended);
    }

    everythingValid() {
      return (this.fields.workerFirstName.valid &&
              this.fields.workerLastName.valid &&
              this.fields.workerDOB.valid);
    }

    decorateFields() {
      this.decorateField(this.fields.workerFirstName, this.workerFirstNameTarget);
      this.decorateField(this.fields.workerLastName, this.workerLastNameTarget);
      this.decorateField(this.fields.workerDOB, this.workerDOBTarget);
    }

    decorateField(field, target) {
      this.cleanBorder(target);
      if(field.valid == false) {
        target.classList.add('border-danger');
        target.classList.add('fat-border');
      } else if (field.amended) {
        target.classList.add('border-primary');
        target.classList.add('fat-border');
      }
    }

    showErrors(errors) {
      if(errors == undefined) {
        alert('Error connecting to the server!');
        return;
      }
      if(errors.first_name != null) {
        this.firstNameErrorTarget.innerHTML(errors.first_name.join('; '));
        this.firstNameErrorTarget.classList.remove('d-none')
        this.workerFirstNameTarget.classList.add('border-danger');
        this.workerFirstNameTarget.classList.add('fat-border');
      }
      if(errors.last_name != null) {
        this.lastNameErrorTarget.innerHTML(errors.last_name.join('; '));
        this.lastNameErrorTarget.classList.remove('d-none')
        this.workerLastNameTarget.classList.add('border-danger');
        this.workerLastNameTarget.classList.add('fat-border');
      }
      if(errors.date_of_birth != null) {
        this.dOBErrorTarget.innerHTML(errors.date_of_birth.join('; '));
        this.dOBErrorTarget.classList.remove('d-none')
        this.workerDOBTarget.classList.add('border-danger');
        this.workerDOBTarget.classList.add('fat-border');
      }
    }

    cleanErrors() {
      this.firstNameErrorTarget.classList.add('d-none')
      this.lastNameErrorTarget.classList.add('d-none')
      this.dOBErrorTarget.classList.add('d-none')
    }

    cleanBorder(target) {
      target.classList.remove('border-primary');
      target.classList.remove('border-danger');
      target.classList.remove('fat-border');
    }

    cleanAllBorders() {
      this.cleanBorder(this.workerFirstNameTarget);
      this.cleanBorder(this.workerLastNameTarget);
      this.cleanBorder(this.workerDOBTarget);
    }

    saveInitialValues() {
      this.fields = {
        workerFirstName: {
          id: 'workerFirstName',
          initial: this.workerFirstNameTarget.value,
          amended: false,
          valid: true
        },
        workerLastName: {
          id: 'workerLastName',
          initial: this.workerLastNameTarget.value,
          amended: false,
          valid: true
        },
        workerDOB: {
          id: 'workerDOB',
          initial: this.workerDOBTarget.value,
          amended: false,
          valid: true
        }
      }
      this.extraNameValidation(this.workerFirstNameTarget, this.firstNameWarningTarget);
      this.extraNameValidation(this.workerLastNameTarget, this.lastNameWarningTarget);
    }

    nameRegExp() {
      return new RegExp("^[A-ZÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'\-]*$");
    }
}

import { application } from '../../frontend/controllers/index'

application.register("worker_personal_info_editor", WorkerPersonalInfoEditorController)
