import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";

const DepartmentsUploadModal = ({ handleClose, show }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size={"lg"}
    >
      <ModalHeader>
        <ModalTitle>File Information</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ minHeight: 0 }}>
        <p style={{ margin: 0 }}>
          <strong style={{ fontSize: 20 }}>
            What to include in the spreadsheet:
          </strong>
          <br />
          Name
          <br />
          Specialities (Comma separated)
          <br />
          Sites (Comma separated)
          <br />
          Department Email
          <br />
          Department Phone Number
          <br />
          Default Cost Centre
          <br />
          Payment Approver Email (Comma separated)
          <br />
          Associated Cost Centres (Comma separated)
          <br />
          Shift Becomes "URGENT" APP (Days)
          <br />
          Agency Kick-Out Period Cut-Off (Days)
          <br />
          Auto approve shifts if clinician is associated to department (True or False)
          <br />
          Always email on cancellation (True or False)
          <br />
          Always email on booking (True or False)
          <br />
          Enhanced department (True or False)
          <br />
          Enhanced locum app shift sign off (True or False)
          <br />
          Locum app sign-off allowed before shift end time (True or False)
          <br />
          Always email on locum app shift sign-off (True or False)
          <br />
          Can clinician book lower to their grade (True or False)
          <br />
          Pay to clinician grade (True or False)
          <br />
          Allow instance booking (True or False)
          <br />
          Can clinician book higher to their grade (True or False)
          <br />
          Pay to clinician grade (True or False)
          <br />
          Override default shift release (True or False)
          <br />
          Allow instant bookings for collab banks (True or False)
          <br />
          Exclude department from RPA (True or False)
          <br />
          <i style={{ fontSize: 12 }}> Note: Please note that the sites, cost_centres, associated cost_centres and specialties associated with the department must already be configured within the Patchwork system, please ensure that the spelling/formatting conventions match. If the department names do not align, that worker will not be uploaded.
          </i>
        </p>
      </ModalBody>
      <ModalFooter>
        <div />
        <div>
          <button
            className="btn text-white btn-cyan--modal"
            onClick={handleClose}
          >
            OK, Got it
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DepartmentsUploadModal;
