import React, { useContext, useState } from "react";
import styled from "styled-components";

import AppContext from "../../../../../Context/AppContext";
import {
  ErrorContainer,
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalButtonClose,
  ShiftModalDetailGroup,
  ShiftModalFormGrid,
  ShiftModalFormGroup,
} from "../../ShiftModalComponents";
import ShiftModalAssign from "../../ShiftModalAssign";

import {
  fetchAuthorisers,
  removeRateEscalation,
  requestRate,
  withdrawRequestRate,
} from "../../../../../Helpers/ShiftHelpers";

const ShiftRequestEscalation = ({
  fetchShift,
  fetchShifts,
  handleClose,
  setSelectedTab,
  setShiftLoading,
  shift,
}) => {
  const { user } = useContext(AppContext);
  const [error, setError] = useState({ error: false, message: null });
  const [approvalPermission, setApprovalPermission] = useState(false);
  const [authoriser, setAuthoriser] = useState({});
  const [authorisers, setAuthorisers] = useState(undefined);
  const [inputs, setInputs] = useState({
    core_rate: 0,
    unsocial_rate: 0,
    reason: "",
    approval_on_behalf: false,
  });
  const [needPermission, setNeedPermission] = useState(false);
  const [reasonCharCount, setReasonCharCount] = useState(0);
  const [selfApproval, setSelfApproval] = useState(false);
  const [showSelfApproval, setShowSelfApproval] = useState(false);
  const [showAuthorisers, setShowAuthorisers] = useState(false);

  const shiftId = shift.id;
  const newCoreRate = inputs.core_rate
    ? Number(inputs.core_rate)
    : shift.rateRequest
    ? Number(shift.rateRequest.newCoreRate)
    : shift.coreTotalRate;
  const newUnsocialRate = inputs.unsocial_rate
    ? Number(inputs.unsocial_rate)
    : shift.rateRequest
    ? Number(shift.rateRequest.newUnsocialRate)
    : shift.unsocialTotalRate;

  const fetchAuthorisersAction = () => {
    fetchAuthorisers(newCoreRate, newUnsocialRate, shiftId).then((data) => {
      if (data !== "err") {
        setAuthorisers(data.data.rateAuthorisers);
      } else {
        setError({
          ...error,
          message: "Unable to fetch authorisers, please try again.",
        });
      }
    });
  };

  const handleInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
    if (event.target.name === "reason") {
      setReasonCharCount(event.target.value.length);
    }
    fetchAuthorisersAction();
  };

  const assignAction = (worker) => {
    setAuthoriser(worker);
  };

  const needPermissionAction = () => {
    setAuthoriser({});
    setError({ error: false });
    setSelfApproval(false);
    setShowSelfApproval(false);
    setApprovalPermission(false);
    setShowAuthorisers(true);
    setNeedPermission(true);
  };

  const approvalPermissionAction = () => {
    setAuthoriser({});
    setError({ error: false });
    setNeedPermission(false);
    setSelfApproval(false);
    setShowSelfApproval(false);
    setShowAuthorisers(true);
    setApprovalPermission(true);
    fetchAuthorisersAction();
  };

  const selfApprovalAction = () => {
    setAuthoriser({});
    setError({ error: false });
    setNeedPermission(false);
    setShowAuthorisers(false);
    setApprovalPermission(false);
    setShowSelfApproval(true);
  };

  const body = {
    shift: {
      rate_authoriser_id: authoriser.id,
      core_rate: newCoreRate,
      unsocial_rate: newUnsocialRate,
      reason: inputs.reason ? inputs.reason : shift.rateRequest?.reason,
      self_approval: inputs.self_approval === "on" ? true : false,
      approval_on_behalf: inputs.approval_on_behalf === "on" ? true : false,
    },
  };

  const isEscalated = () => {
    if (shift.rateRequest && shift.rateRequest.approved !== true) {
      return true;
    } else {
      return false;
    }
  };

  const withdrawRequestRateAction = (e) => {
    const withdrawBody = {
      shift: {
        rate_change_id: shift.rateRequest?.id,
      },
    };

    setShiftLoading(true);
    e.preventDefault();
    withdrawRequestRate(shift.id, withdrawBody).then((data) => {
      if (data !== "err") {
        fetchShift(shift.id);
        setTimeout(() => {
          setSelectedTab("details");
          setShiftLoading(false);
          fetchShifts();
        }, 300);
      } else {
        setShiftLoading(false);
        setError({
          ...error,
          main: true,
          message:
            "There was an error withdrawing the rate escalation for this shift.",
        });
      }
    });
  };

  const removeRateEscalationAction = (e) => {
    setShiftLoading(true);
    e.preventDefault();
    removeRateEscalation(shift.id).then((data) => {
      if (data !== "err") {
        fetchShift(shift.id);
        setTimeout(() => {
          setSelectedTab("details");
          setShiftLoading(false);
          fetchShifts();
        }, 300);
      } else {
        setShiftLoading(false);
        setError({
          ...error,
          main: true,
          message:
            "There was an error withdrawing the rate escalation for this shift.",
        });
      }
    });
  };

  const requestRateAction = (e) => {
    setError({ error: false });
    if (!isEscalated() && inputs.core_rate <= 0) {
      setError({
        ...error,
        core_rate: true,
        message: "Please enter the core rate.",
      });
    } else if (!isEscalated() && inputs.unsocial_rate <= 0) {
      setError({
        ...error,
        unsocial_rate: true,
        message: "Please enter the unsocial rate.",
      });
    } else if (
      (!isEscalated() && inputs.reason === "") ||
      (!isEscalated() && inputs.reason.length > 255)
    ) {
      setError({
        ...error,
        reason: true,
        message: "Please enter a reason for the escalation, max 255 chars.",
      });
    } else if (
      approvalPermission === false &&
      needPermission === false &&
      selfApproval === false
    ) {
      setError({
        ...error,
        authoriser: true,
        message: "Please select a way of requesting your escalation.",
      });
    } else if (
      (approvalPermission === true && !authoriser.id) ||
      (needPermission === true && !authoriser.id)
    ) {
      setError({
        ...error,
        authoriser: true,
        message: "Please select an authoriser.",
      });
    } else if (
      (approvalPermission === true && !authoriser.id) ||
      (needPermission === true && !authoriser.id)
    ) {
      setError({
        ...error,
        authoriser: true,
        message: "Please select an authoriser.",
      });
    } else if (
      approvalPermission === true &&
      authoriser.id &&
      inputs.approval_on_behalf === false
    ) {
      setError({
        ...error,
        permission: true,
        message: "Please confirm you have permission.",
      });
    } else if (setShowSelfApproval === true && inputs.self_approval === false) {
      setError({
        ...error,
        selfPermission: true,
        message: "Please confirm you have permission.",
      });
    } else {
      setShiftLoading(true);
      e.preventDefault();
      requestRate(shift.id, body).then((data) => {
        if (data !== "err") {
          fetchShift(shift.id);
          setTimeout(() => {
            setSelectedTab("details");
            setShiftLoading(false);
            fetchShifts();
          }, 300);
        } else {
          setShiftLoading(false);
          setError({
            ...error,
            main: true,
            message:
              "There was an error requesting an escalation for this shift.",
          });
        }
      });
    }
  };

  const showPermissions = () => {
    if (shift.rateRequest && shift.rateRequest.approved === false) {
      return true;
    } else if (inputs.core_rate !== 0 && inputs.unsocial_rate !== 0) {
      return true;
    } else {
      return false;
    }
  };

  const showWithdrawButton = () => {
    if (isEscalated()) {
      if (authoriser.id) {
        return false;
      } else if (selfApproval) {
        return false;
      } else {
        return true;
      }
    } else {
      false;
    }
  };

  const newCoreTotal = inputs.core_rate
    ? inputs.core_rate * shift.coreHours
    : 0;
  const newUnsocialTotal = inputs.unsocial_rate
    ? inputs.unsocial_rate * shift.unsocialHours
    : 0;
  const newTotal = newCoreTotal + newUnsocialTotal;

  return (
    <>
      <ShiftModalDetailFooter>
        <div>
          {error.main && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          {showWithdrawButton() ? (
            <>
              <ShiftModalButtonClose
                color={"red"}
                onClick={(e) => withdrawRequestRateAction(e)}
              >
                Withdraw Request
              </ShiftModalButtonClose>
              <ShiftModalButton theme={"blue"}>
                Continue Waiting
              </ShiftModalButton>
            </>
          ) : (
            <>
              <ShiftModalButtonClose onClick={handleClose}>
                Close
              </ShiftModalButtonClose>
              <ShiftModalButton
                theme={"blue"}
                onClick={(e) => requestRateAction(e)}
              >
                Request Escalation
              </ShiftModalButton>
            </>
          )}
        </div>
      </ShiftModalDetailFooter>
      <ShiftModalContainer>
        <ShiftModalDetailGroup>
          <RequestEscalationContainer>
            <div>
              {isEscalated() ? (
                <RequestEscalationRequestedContainer>
                  <RequestEscalationNumbers>
                    <h5>Current Core</h5>
                    <p>
                      <span>£</span>
                      {Number(shift.rateRequest.oldCoreRate).toFixed(2)}
                    </p>
                    <h5>Current Unsocial</h5>
                    <p>
                      <span>£</span>
                      {Number(shift.rateRequest.oldUnsocialRate).toFixed(2)}
                    </p>
                    <hr />
                    <h5>Total Current Pay</h5>
                    <p style={{ marginBottom: 0 }}>
                      <span>£</span>
                      {Number(shift.rateRequest.oldTotalPay).toFixed(2)}
                    </p>
                    {shift.breakMinutes > 0 && (
                      <RequestEscalationBreaks>
                        Includes Breaks
                      </RequestEscalationBreaks>
                    )}
                  </RequestEscalationNumbers>
                  <svg
                    width="32"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M31.38 10.552L20.94.557c-1.267-1.214-3.38-.313-3.38 1.448v4.657H2c-1.107 0-2 .894-2 2.002v6.672c0 1.108.893 2.002 2 2.002h15.56v4.657c0 1.761 2.113 2.662 3.38 1.448l10.44-9.995a2 2 0 000-2.896"
                      fill="#FF7F4D"
                    />
                  </svg>
                  <RequestEscalationNumbers
                    style={{
                      background: "rgba(255, 127, 77, 0.05)",
                      borderColor: "#FF7F4D",
                    }}
                  >
                    <h5>Escalated Core</h5>
                    <p>
                      <span>£</span>
                      {Number(shift.rateRequest.newCoreRate).toFixed(2)}
                    </p>
                    <h5>Escalated Unsocial</h5>
                    <p>
                      <span>£</span>
                      {Number(shift.rateRequest.newUnsocialRate).toFixed(2)}
                    </p>
                    <hr />
                    <h5>Escalated Total Pay</h5>
                    <p style={{ marginBottom: 0 }}>
                      <span>£</span>
                      {Number(shift.rateRequest.newTotalPay).toFixed(2)}
                    </p>
                    {shift.breakMinutes > 0 && (
                      <RequestEscalationBreaks
                        style={{ background: "rgba(255, 127, 77, 0.301601)" }}
                      >
                        Includes Breaks
                      </RequestEscalationBreaks>
                    )}
                  </RequestEscalationNumbers>
                </RequestEscalationRequestedContainer>
              ) : (
                <>
                  <h2>Request Escalation</h2>
                  <RequestEscalationSplit>
                    <div>
                      <ShiftModalFormGrid columns={1}>
                        <ShiftModalFormGroup
                          component={"currency"}
                          defaultValue={"0.00"}
                          label={"Escalate Core Rate"}
                          name="core_rate"
                          onChange={handleInputChange}
                          type="number"
                          error={error.core_rate && error.message}
                        />
                      </ShiftModalFormGrid>
                      <ShiftModalFormGrid columns={1}>
                        <ShiftModalFormGroup
                          component={"currency"}
                          defaultValue={"0.00"}
                          label={"Escalate Unsocial Rate"}
                          name="unsocial_rate"
                          onChange={handleInputChange}
                          type="number"
                          error={error.unsocial_rate && error.message}
                        />
                      </ShiftModalFormGrid>
                    </div>
                    <RequestEscalationNumbers
                      style={{ padding: 16, textAlign: "right" }}
                    >
                      <h5>Total Pay after Escalation</h5>
                      <p>£{Number(newTotal).toFixed(2)}</p>
                    </RequestEscalationNumbers>
                  </RequestEscalationSplit>
                  <ShiftModalFormGrid columns={1}>
                    <ShiftModalFormGroup
                      charCount={`${255 - reasonCharCount} chars remaining`}
                      component={"textarea"}
                      label={"Reasons for Escalation"}
                      name="reason"
                      onChange={handleInputChange}
                      placeholder={
                        "Leave helpful notes for to show why the shift was cancelled."
                      }
                      type="textarea"
                      error={error.reason && error.message}
                    />
                  </ShiftModalFormGrid>
                </>
              )}
            </div>
            {isEscalated() ? (
              ""
            ) : (
              <div>
                <RequestEscalationNumbers
                  style={
                    shift.escalatedBy && {
                      background: "rgba(255, 127, 77, 0.05)",
                      borderColor: "#FF7F4D",
                    }
                  }
                >
                  <h5>
                    {shift.escalatedBy ? `Escalated Core` : `Current Core`}
                  </h5>
                  <p>
                    <span>£</span>
                    {Number(shift.coreTotalRate).toFixed(2)}
                  </p>
                  <h5>
                    {shift.escalatedBy
                      ? `Escalated Unsocial`
                      : `Current Unsocial`}
                  </h5>
                  <p>
                    <span>£</span>
                    {Number(shift.unsocialTotalRate).toFixed(2)}
                  </p>
                  <hr />
                  <h5>
                    {shift.escalatedBy
                      ? `Escalated Total Pay`
                      : `Total Current Pay`}
                  </h5>
                  <p style={{ marginBottom: 0 }}>
                    <span>£</span>
                    {Number(shift.totalPay).toFixed(2)}
                  </p>
                  {shift.breakMinutes > 0 && (
                    <RequestEscalationBreaks
                      style={
                        shift.escalatedBy && {
                          background: "rgba(255, 127, 77, 0.301601)",
                        }
                      }
                    >
                      Includes Breaks
                    </RequestEscalationBreaks>
                  )}
                </RequestEscalationNumbers>
                {shift.escalatedBy &&
                  (user.job_title === "Super Admin" ||
                    user.job_title === "Admin") && (
                    <ShiftModalButton
                      color={"red"}
                      theme={"white"}
                      onClick={removeRateEscalationAction}
                      style={{ marginTop: 10, width: "100%" }}
                    >
                      Remove Escalation
                    </ShiftModalButton>
                  )}
              </div>
            )}
          </RequestEscalationContainer>
        </ShiftModalDetailGroup>
        {showPermissions() && (
          <ShiftModalDetailGroup>
            <div>
              <h2>Permissions</h2>
              {shift.rateRequest && shift.rateRequest.approved === false && (
                <p>
                  If you have permission from an authoriser, you can select them
                  below to overide waiting for escalation.
                </p>
              )}
              <PermissionsContainer>
                {isEscalated() ? (
                  ""
                ) : (
                  <PermissionOption>
                    <PermissionButton onClick={needPermissionAction}>
                      <span className={needPermission ? "active" : ""} /> I need
                      this authorised by:
                    </PermissionButton>
                  </PermissionOption>
                )}
                <PermissionOption>
                  <PermissionButton onClick={approvalPermissionAction}>
                    <span className={approvalPermission ? "active" : ""} /> I
                    have permission from:
                  </PermissionButton>
                </PermissionOption>
                {(user.job_title === "Super Admin" ||
                  user.job_title === "Manager") && (
                  <PermissionOption>
                    <PermissionButton onClick={setShowSelfApproval}>
                      <span className={showSelfApproval ? "active" : ""} /> I
                      can authorise myself
                    </PermissionButton>
                  </PermissionOption>
                )}
              </PermissionsContainer>
              <AuthoriserContainer>
                {error.authoriser && error.message && (
                  <ErrorContainer>
                    <p>{error.message}</p>
                  </ErrorContainer>
                )}
                {showAuthorisers && (
                  <ShiftModalFormGrid columns={1}>
                    {authorisers && (
                      <ShiftModalAssign
                        action={assignAction}
                        data={authorisers}
                        selectedValue={authoriser.id}
                        // error={error}
                        // inputFunction={inputFunction}
                        type={"authoriser"}
                      />
                    )}
                  </ShiftModalFormGrid>
                )}
                {approvalPermission && (
                  <>
                    {error.permission && error.message && (
                      <ErrorContainer>
                        <p>{error.message}</p>
                      </ErrorContainer>
                    )}
                    <ShiftModalFormGroup
                      component={"checkbox"}
                      label={"I confirm I have the authorisers permission"}
                      name="approval_on_behalf"
                      type="checkbox"
                      onChange={handleInputChange}
                    />
                  </>
                )}
                {showSelfApproval && (
                  <>
                    {error.selfPermission && error.message && (
                      <ErrorContainer>
                        <p>{error.message}</p>
                      </ErrorContainer>
                    )}
                    <SelfAuthoriserContainer>
                      <svg
                        width="22"
                        height="18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.925 10.584c0 .51-.416.927-.925.927a.929.929 0 01-.925-.927V5.486c0-.51.416-.927.925-.927.509 0 .925.417.925.927v5.098zM11 14.755a.929.929 0 01-.925-.927c0-.51.416-.926.925-.926.509 0 .925.417.925.927s-.416.926-.925.926zm10.623-.338L12.938 1.05c-.911-1.4-2.965-1.4-3.876 0L.377 14.417C-.622 15.961.48 18 2.315 18h17.37c1.836 0 2.937-2.04 1.938-3.583z"
                          fill="#FF7F4D"
                        />
                      </svg>
                      <div>
                        <p>
                          <strong>
                            Heads-up: You must follow your Organisation's policy
                            for Rate Escalations.
                          </strong>
                        </p>
                        <p>
                          A copy of this Escalation will be sent to the Staff
                          Bank and it will be logged in the Patchwork audit
                          trail.
                        </p>
                      </div>
                    </SelfAuthoriserContainer>
                    <ShiftModalFormGroup
                      component={"checkbox"}
                      label={
                        "I have permission to make this escalation myself."
                      }
                      name="self_approval"
                      type="checkbox"
                      onChange={handleInputChange}
                    />
                  </>
                )}
              </AuthoriserContainer>
            </div>
          </ShiftModalDetailGroup>
        )}
        {isEscalated() && (
          <ShiftModalDetailGroup>
            <div style={{ display: "flex" }}>
              <svg
                style={{
                  marginRight: "12px",
                  position: "relative",
                  top: "2px",
                }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 6.66667C9.54167 6.66667 9.16667 6.29167 9.16667 5.83333C9.16667 5.375 9.54167 5 10 5C10.4583 5 10.8333 5.375 10.8333 5.83333C10.8333 6.29167 10.4583 6.66667 10 6.66667ZM10.8333 14.1667C10.8333 14.625 10.4583 15 10 15C9.54167 15 9.16667 14.625 9.16667 14.1667V8.75C9.16667 8.29167 9.54167 7.91667 10 7.91667C10.4583 7.91667 10.8333 8.29167 10.8333 8.75V14.1667ZM10 0C4.47917 0 0 4.47917 0 10C0 15.5208 4.47917 20 10 20C15.5208 20 20 15.5208 20 10C20 4.47917 15.5208 0 10 0V0Z"
                  fill="#9CA8B4"
                />
              </svg>
              <p>
                <strong>Need to make a new Escalation Request?</strong> <br />{" "}
                Withdraw this request and create a new one.
              </p>
            </div>
          </ShiftModalDetailGroup>
        )}
      </ShiftModalContainer>
    </>
  );
};

export default ShiftRequestEscalation;

const RequestEscalationContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 250px;
`;

const RequestEscalationSplit = styled.div`
  align-items: flex-end;
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr 150px;
  margin-bottom: 24px;
`;

const RequestEscalationNumbers = styled.div`
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  height: fit-content;
  padding: 24px;

  h5 {
    color: #9ca8b4;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.5px;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  p {
    color: #1e2d36;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      font-size: 18px;
    }
  }

  hr {
    margin-bottom: 16px;
    margin-top: 16px;
  }
`;

const RequestEscalationBreaks = styled.span`
  background: rgba(203, 210, 217, 0.4);
  border-radius: 4px;
  color: #1e2d36;
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  margin-top: 4px;
  padding: 5px 15px;
  text-transform: uppercase;
`;

const RequestEscalationRequestedContainer = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 210px 32px 210px;
`;

const PermissionsContainer = styled.div`
  align-items: center;
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 200px);
  margin-bottom: 25px;
`;

const PermissionOption = styled.div`
  align-items: center;
  display: flex;
`;

const PermissionButton = styled.button`
  align-items: center;
  background: #ffffff;
  border: 0;
  box-shadow: none;
  display: flex;
  padding: 0;

  span {
    background: #ffffff;
    border: 1px solid #cbd2d9;
    border-radius: 50%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    display: block;
    height: 18px;
    margin-right: 10px;
    width: 18px;

    &.active {
      background: #ff7f4d;
      border: 1px solid rgba(255, 127, 77, 0.2);
    }
  }
`;

const AuthoriserContainer = styled.div`
  max-width: 500px;
`;

const SelfAuthoriserContainer = styled.div`
  background: #f5f7fa;
  border-radius: 5px;
  display: flex;
  margin-bottom: 10px;
  padding: 16px;

  svg {
    margin-right: 10px;
    min-width: 22px;
    position: relative;
    top: 4px;
  }
`;
