import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class FilterGradeSelectorController extends Controller {

    static get targets() {
        return ['select']
    }

    connect() {
        const target = this.selectTarget;
        new SlimSelect({
            select: target,
        })
    }
}

import { application } from '../../frontend/controllers/index'
application.register("filter_grade_selector", FilterGradeSelectorController)