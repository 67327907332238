import React, { useState } from 'react'
import styled from 'styled-components'

import ShiftModal from '../Modals/ShiftModal'

import AppContext from "../../Context/AppContext";

const ShiftListLink = ({
  shift,
  costCentres,
  grades,
  organisation,
  organisationAgencies,
  organisationReasons,
  reasons,
  sites,
  staffTypes,
  tab,
  user,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const providerValue = {
    costCentres,
    grades,
    reasons,
    sites,
    staffTypes,
    organisation,
    organisationAgencies,
    organisationReasons,
    user,
  };

  return (
    <AppContext.Provider value={providerValue}>
      <ShiftListLinkButton onClick={handleShow}>{shift.id}</ShiftListLinkButton>
      <ShiftModal />
      {show &&
        <ShiftModal
          handleClose={handleClose}
          shiftId={shift.id}
          show={show}
          tab={tab}
          userId={user.id}
        />
      }
    </AppContext.Provider>
  )
}

export default ShiftListLink

const ShiftListLinkButton = styled.button`
  background: transparent;
  border: 0;
  font-weight: 500;
  text-decoration: underline;
`