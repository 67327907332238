import React from "react";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const DropdownComponent = ({
  buttonText,
  children,
  direction,
  style,
  user,
}) => {
  return (
    <ActionsDropown drop={direction} style={style && { marginTop: 20 }}>
      {buttonText ? (
        <ActionsDropdownButton id="dropdown-basic">
          {buttonText}
        </ActionsDropdownButton>
      ) : (
        <ActionsDropdownImageButton>
          <span>{user.first_name || user.firstName}</span>
        </ActionsDropdownImageButton>
      )}
      <ActionsDropdownMenu style={style}>{children}</ActionsDropdownMenu>
    </ActionsDropown>
  );
};

export default DropdownComponent;

const ActionsDropown = styled(Dropdown)``;

const ActionsDropdownButton = styled(Dropdown.Toggle)`
  background: #ffffff !important;
  border: 1px solid #cbd2d9 !important;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36 !important;

  &:hover,
  &:focus {
    background: #ffffff !important;
    border: 1px solid #ff7f4d !important;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08) !important;
    color: #1e2d36 !important;
  }

  &:after {
    vertical-align: middle !important;
  }
`;

const ActionsDropdownImageButton = styled(Dropdown.Toggle)`
  background-color: transparent !important;
  border: 0 !important;
  padding: 0;

  &:hover,
  &:focus {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    margin-right: 5px;
    max-width: 100px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    top: 3px;
    white-space: nowrap;
  }
`;

const ActionsDropdownMenu = styled(Dropdown.Menu)`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  border-radius: 6px;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  min-width: ${(props) => (props.style === "shiftModal" ? "235px" : "auto")};
  padding: 6px 0;
`;

export const ActionsDropdownMenuItem = styled(Dropdown.Item)`
  color: #1e2d36;
  font-size: 16px;
  line-height: 1;
  min-height: 32px;
  padding: 5px 12px;

  .dropdown-item {
    &-circle {
      align-items: center;
      background: #ff6c6c;
      border-radius: 50%;
      color: #fff;
      display: flex;
      font-size: 11px;
      font-weight: 600;
      height: 22px;
      justify-content: center;
      margin-right: 10px;
      width: 22px;
    }

    &-icon {
      align-items: center;
      display: flex;
      height: 22px;
      justify-content: center;
      margin-right: 10px;
      width: 22px;

      img {
        margin: 0;
      }
    }
  }
`;
