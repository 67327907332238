import {Controller} from 'stimulus'

export default class PaymentCollaborativeBanksTableController extends Controller {

    static get targets() {
        return ['selectedShiftsOutput']
    }

    connect() {
        this.selectedShifts = []
    }

    selectRow(event) {
        let shiftId = parseInt(event.srcElement.dataset['shiftId']);
        if (this.selectedShifts.includes(shiftId)) {
            document.getElementById('action-bar')['action_bar'].decrement('', this.selectedShifts, true)
            this.selectedShifts = this.selectedShifts.filter(id => id !== shiftId)
        } else {
            document.getElementById('action-bar')['action_bar'].increment('', this.selectedShifts, true);
            this.selectedShifts.push(shiftId)
        }

    }

    selectAll() {
        document.getElementById('action-bar')['action_bar'].selectAll(true);
    }
}

import {application} from '../../frontend/controllers/index'

application.register("payment_collaborative_banks_table", PaymentCollaborativeBanksTableController)