import { TableContainer } from '@patchworkhealth/web-components'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { displayDescription } from './AuditEventDescription'

function ViewAudit({ auditData }) {
  return (
    <TableContainer style={{ width: '100%' }}>
      <thead>
        <tr>
          <th>Date & Time</th>
          <th>Event Type</th>
          <th>User</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {auditData?.ppiAuditEvents?.map((row, index) => {
          return (
            <tr key={index}>
              <TableCustomRow>
                <p>
                  <strong>{moment(row?.createdAt).format('h:mm A')}</strong>
                </p>
                <p>
                  <strong>{moment(row?.createdAt).format('DD MMM YYYY')}</strong>
                </p>
              </TableCustomRow>

              <TableCustomRow>
                <p>
                  <strong>
                    {row?.eventType}
                  </strong>
                </p>
              </TableCustomRow>

              <TableCustomRow>
                <p>
                  <strong>
                    {row?.userDetails?.user_name}
                  </strong>
                </p>
                <p>{row?.userDetails?.user_email}</p>
              </TableCustomRow>


              <TableCustomRow>
                {displayDescription(row?.description)}
              </TableCustomRow>
            </tr>
          )
        })}
      </tbody>
    </TableContainer>
  )
}

export default ViewAudit

const TableCustomRow = styled.td`
  p {
    font-size: 14px;
    margin: 0;
  }
`
