/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-props-no-spreading */

import { useQuery, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Pagy from '../../Helpers/Pagy'
import SectionAuthHeader from '../BlockBookings/BlockHeader'
import {
  errorToast,
  loadingToast,
  successToast,
  PAY_PER_ITEM_ITEMS,
  PAID_PER_ITEM_DELETE_ITEM,
} from './helpers/PayPerItemItemsHelpers'
import CreateItemModal from './Modals/CreateItemModal'
import DeleteItemModal from './Modals/DeleteItemModal'
import ViewItemModal from './Modals/ViewItemModal'
import PayPerItemItems from './PayPerItemItems'

const staticDeleteModalFields = {
  title: 'Are you sure you want to disable this item?',
  submitText: 'Yes, disable item',
  description:
    'Please note that when disabling this item, it will still be displayed within submitted receipts that are still in progress but will not be available for any new receipt requests. Once the receipt for this item has been approved to payroll, it will be removed from the list of items. This can not be undone.',
}

function ItemisedRates({ departments, organisation, user }) {
  const permissions = user?.userPermission
  const [page, setPage] = useState(1)
  const [inputs, setInputs] = useState({
    departmentId: null,
    currentItem: null,
    blocksPage: 1,
    organisation,
    rowsSearched: 25,
    showCreateModal: false,
    showItemModal: false,
    showDeleteItemModal: false,
    user,
  })

  const { data, loading, refetch } = useQuery(PAY_PER_ITEM_ITEMS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      items: 25,
      departmentId: inputs.departmentId,
    },
  })

  const [deleteModalState, setDeleteModalState] = useState({
    ...staticDeleteModalFields,
    isOpen: false,
    itemId: null,
    handleClose: () => setDeleteModalState({ ...deleteModalState, itemId: null, isOpen: false }),
  })

  const [deleteItem] = useMutation(PAID_PER_ITEM_DELETE_ITEM)

  const handleDelete = async () => {
    toast.loading('Loading', loadingToast)
    try {
      const res = await deleteItem({
        variables: {
          id: deleteModalState.itemId,
        },
      })

      if (res?.data?.paidPerItemDeleteItem?.success) {
        refetch()
        deleteModalState.handleClose()
        toast.update(2, { ...successToast, render: 'Item deleted' })
      }
    } catch (error) {
      toast.update(2, { ...errorToast, render: 'Item could not be deleted' })
    }
  }

  const pagination = data?.paidPerItemItems.pagination

  return (
    <>
      <ItemisedRatesWrapper>
        <SectionHeader>
          <SectionAuthHeader subtitle="Departments" title="Itemised Rates" user={user} />
        </SectionHeader>
        <>
          <PayPerItemItems
            departments={departments}
            inputs={inputs}
            items={data?.paidPerItemItems?.list}
            loading={loading}
            permissions={permissions}
            setCurrentItem={(item) => setInputs({ ...inputs, currentItem: item })}
            setDeleteModalState={setDeleteModalState}
            setInputs={setInputs}
            showCreateModal={() => setInputs({ ...inputs, showCreateModal: true })}
            showItemModal={() => setInputs({ ...inputs, showItemModal: true })}
          />
          {data?.paidPerItemItems?.list.length ? <Pagy pagination={pagination} setPage={setPage} /> : null}
        </>
      </ItemisedRatesWrapper>
      {(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) && (
        <CreateItemModal
          departments={departments}
          handleClose={() => setInputs({ ...inputs, showCreateModal: false })}
          inputs={inputs}
          refetchItems={refetch}
        />
      )}
      <ViewItemModal
        departments={departments}
        handleClose={() => setInputs({ ...inputs, currentItem: null })}
        inputs={inputs}
        permissions={permissions}
        refetchItems={refetch}
      />
      {(permissions?.canDeletePpi || permissions?.isSuperAdmin) && (
        <DeleteItemModal {...deleteModalState} handleDelete={handleDelete} />
      )}
    </>
  )
}

export default ItemisedRates

const ItemisedRatesWrapper = styled.section`
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: #fff;
`

const SectionHeader = styled.div`
  background: #002033;
  height: ${(props) => (props.open ? '430px' : '280px')};
  margin: 0;
  padding: 20px;
  transition: 250ms ease-in-out;
  @media (max-width: 1605px) {
    height: ${(props) => (props.open ? '530px' : '280px')};
  }
`
