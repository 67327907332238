import { gql } from "@apollo/client";

export const AgencyWorkerFragment = gql`
  fragment AgencyWorkerFragment on AgencyWorker {
    id
    firstName
    lastName
    grade {
      id
      title
      organisationStaffGroup {
        id
        title
      }
    }
    niNumber
    regBodyNumber
    cvUrl
    staffGroup {
      idValue
      title
    }
  }
`;

export const GET_AGENCY_WORKERS = gql`
  query GetAgencyWorkers(
    $agencyId: Int
    $name: String
    $page: Int
    $items: Int
  ) {
    agencyWorkers(
      agencyId: $agencyId
      name: $name
      page: $page
      items: $items
    ) {
      list {
        ...AgencyWorkerFragment
        agency {
          id
          title
        }
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
  ${AgencyWorkerFragment}
`;

export const GET_VMS_AGENCY_WORKERS = gql`
  query GetVmsAgencyWorkers(
    $name: String
    $staffGroupId: Int
  ) {
    vmsAgencyWorkers(
      name: $name
      staffGroupId: $staffGroupId
    ) {
      edges {
        node {
        ...AgencyWorkerFragment
        }
      }
      totalCount
    }
  }
  ${AgencyWorkerFragment}
`;

export const GET_GRADES = gql`
  query GetGrades($staffGroupId: Int) {
    grades(staffGroupId: $staffGroupId) {
      id
      title
    }
  }
`;

export const GET_ORGANISATION_STAFF_GROUPS = gql`
  query GetOrganisationStaffGroups($organisationId: Int!) {
    organisationStaffGroups(organisationId: $organisationId) {
      id
      title
      staffGroup {
        idValue
        regulationBody {
          registrationNumberMaxLength
        }
      }
    }
  }
`;

export const CREATE_VMS_AGENCY_WORKER = gql`
  mutation VmsAgencyWorkerCreate(
    $firstName: String!
    $lastName: String!
    $regBodyNumber: String!
    $niNumber: String!
    $staffGroupId: Int!
    $gradeId: Int!
    $cv: Upload
  ) {
    vmsAgencyWorkerCreate(
      firstName: $firstName
      lastName: $lastName
      regBodyNumber: $regBodyNumber
      niNumber: $niNumber
      staffGroupId: $staffGroupId
      gradeId: $gradeId
      cv: $cv
    ) {
      agencyWorker {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export const UPDATE_VMS_AGENCY_WORKER = gql`
  mutation VmsAgencyWorkerUpdate(
    $id: Int!
    $firstName: String!
    $lastName: String!
    $regBodyNumber: String!
    $niNumber: String!
    $staffGroupId: Int!
    $gradeId: Int!
    $cv: Upload
  ) {
    vmsAgencyWorkerUpdate(
      id: $id
      firstName: $firstName
      lastName: $lastName
      regBodyNumber: $regBodyNumber
      niNumber: $niNumber
      staffGroupId: $staffGroupId
      gradeId: $gradeId
      cv: $cv
    ) {
      agencyWorker {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`;

export async function createAgencyLocum(body) {
  const url = `/agency_locums`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function editAgencyLocum(agencyLocumId, body) {
  const url = `/agency_locums/${agencyLocumId}`;

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}
