/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import { DefaultButton, TableContainer, FormLabel, FormSelect } from '@patchworkhealth/web-components'
import { ButtonDelete as DeleteIcon, Settings as SettingsIcon } from '@patchworkhealth/web-components/icons'
import moment from 'moment'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CreateBlockIcon as CreateItemIcon } from '../BlockBookings/helpers/BlockIcons'
import { TableHeaderContainer } from '../Tables/TableElements'

function Loading() {
  return (
    <LoadingContainer>
      <svg height="38" stroke="#ff7f4d" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd">
          <g strokeWidth="2" transform="translate(1 1)">
            <circle cx="18" cy="18" r="18" strokeOpacity=".5" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                dur="1s"
                from="0 18 18"
                repeatCount="indefinite"
                to="360 18 18"
                type="rotate"
              />
            </path>
          </g>
        </g>
      </svg>
    </LoadingContainer>
  )
}

function PayPerItemItems({
  items,
  showCreateModal,
  setDeleteModalState,
  setCurrentItem,
  departments,
  setInputs,
  inputs,
  permissions,
  loading,
}) {
  const itemsToRender = items?.map((item) => {
    const paymentRange = item.itemGradeCosts.map((gradeCost) => gradeCost.cost)
    const minPayment = Math.min(...paymentRange)
    const maxPayment = Math.max(...paymentRange)

    return (
      <TableRow key={item.id}>
        <TdFirst>
          <PaymentTitle onClick={() => setCurrentItem(item)}>{item.name}</PaymentTitle>
        </TdFirst>
        <td style={{ width: 189 }}>
          <div style={{ fontWeight: 'bold' }}>{item.department.name}</div>
          <div>{item.department.site.name}</div>
        </td>
        <td style={{ width: 217 }}>{item.itemGradeCosts.map((gradeCost) => gradeCost.grade.title).join(', ')}</td>
        <td style={{ width: 173 }}>
          {paymentRange?.length > 1 ? `£${minPayment} - £${maxPayment}` : `£${minPayment}`}
        </td>
        <td style={{ minWidth: 100 }}>{moment(item.createdAt).format('Do MMMM YYYY')}</td>
        <td style={{ minWidth: 100 }}>
          {(permissions?.canDeletePpi || permissions?.isSuperAdmin) && (
            <DeleteButton
              as="a"
              onClick={() =>
                setDeleteModalState((prevState) => ({
                  ...prevState,
                  itemId: item.id,
                  isOpen: true,
                }))
              }
            >
              <DeleteIcon />
            </DeleteButton>
          )}
          <SettingsButton as="a" onClick={() => setCurrentItem(item)}>
            <SettingsIcon />
          </SettingsButton>
        </td>
      </TableRow>
    )
  })

  const departmentOptions = useMemo(
    () => [{ label: 'All' }, ...departments.map((d) => ({ label: d.name, value: d.id }))],
    []
  )

  return (
    <BlockWrapper>
      <TableHeaderContainer>
        <div style={{ marginTop: '-28px', width: 320 }}>
          <FormLabel style={{ color: '#fff' }}>Select Department</FormLabel>
          <FormSelect
            defaultValue={departmentOptions.find((o) => o.value === inputs.departmentId)}
            name="department"
            onChange={(option) => {
              setInputs((localInputs) => ({ ...localInputs, departmentId: Number(option.value) }))
            }}
            options={departmentOptions}
            placeholder="All"
          />
        </div>
        {(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) && (
          <div style={{ paddingRight: '10px' }}>
            <button
              className="btn btn-cyan"
              onClick={() => showCreateModal(true)}
              style={{ marginLeft: '10px' }}
              type="button"
            >
              <CreateItemIcon />
              New Item
            </button>
          </div>
        )}
      </TableHeaderContainer>
      <TableContainer style={{ minWidth: 1100 }}>
        <TableHead>
          <tr>
            <th>
              <ThFirst>Item</ThFirst>
            </th>
            <th>Department</th>
            <th>Staff Grades</th>
            <th>Payment Range</th>
            <th>Date Added</th>
            <th>&nbsp;</th>
          </tr>
        </TableHead>
        <tbody>
          {itemsToRender}
          <tr />
        </tbody>
      </TableContainer>
      {loading && <Loading />}
    </BlockWrapper>
  )
}

export default PayPerItemItems

const DeleteButton = styled(DefaultButton)`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 0;
  cursor: pointer;
`

const SettingsButton = styled(DefaultButton)`
  display: inline-block;
  width: 24px;
  height: 24px;
  padding: 0;
  margin-left: 14px;
  margin-right: 10px;
  cursor: pointer;
`

const ThFirst = styled.span`
  padding-left: 65px;
`

const TdFirst = styled.td`
  width: 303px;
  padding-left: 75px !important;
`

const BlockWrapper = styled.div`
  margin-top: -130px;
  margin-bottom: 40px;
  padding: 20px;
`

const PaymentTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
  color: #002033;
  margin: 0;
  cursor: pointer;
`

const TableRow = styled.tr`
  * {
    font-size: 14px !important;
  }

  th {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 10px !important;
    color: #002033 !important;
  }

  td {
    padding: 14px 10px;
  }
`

export const TableIconRow = styled.td`
  position: relative;
  overflow: visible !important;
  svg:hover + div {
    opacity: 1 !important;
  }
`

const TableHead = styled.thead`
  th {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    line-height: 10px !important;
    color: #002033 !important;
  }
`

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 220px;
`
