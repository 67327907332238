/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import { ModalBody, CloseButton, ModalHeader } from '@patchworkhealth/web-components'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import 'react-day-picker/lib/style.css'
import styled from 'styled-components'
import ViewAudit from '../../Audit/ViewAudit'
import ViewItemDetails from './ViewItemDetails'

function ViewItemModal({ departments, handleClose, inputs, permissions, refetchItems }) {
  const { currentItem } = inputs

  const [activeTab, setActiveTab] = useState('1')
  useEffect(() => {
    if (currentItem) {
      setActiveTab('1')
    }
  }, [currentItem])

  return (
    <Modal backdrop="static" centered onHide={handleClose} show={!!currentItem} size="xl">
      <>
        <ModalHeaderMod>
          <ItemModalHeaderDetailsContainer>
            <ItemModalHeaderTitle>{currentItem?.name}</ItemModalHeaderTitle>
          </ItemModalHeaderDetailsContainer>
          <ItemModalHeaderDetailsContainer>
            <CloseButton onClick={handleClose} />
          </ItemModalHeaderDetailsContainer>
        </ModalHeaderMod>

        <ItemModalContainer>
          <ItemModalSidebarContainer>
            <ItemModalSidebarNav>
              <ItemModalSidebarNavOption active={activeTab === '1'} onClick={() => setActiveTab('1')}>
                Item Details
              </ItemModalSidebarNavOption>
              <ItemModalSidebarNavOption active={activeTab === '2'} onClick={() => setActiveTab('2')}>
                Audit Trail
              </ItemModalSidebarNavOption>
            </ItemModalSidebarNav>
          </ItemModalSidebarContainer>

          <ItemModalContentContainer>
            <ModalBody>
              {activeTab === '1' && (
                <ViewItemDetails
                  departments={departments}
                  handleClose={handleClose}
                  inputs={inputs}
                  permissions={permissions}
                  refetchItems={refetchItems}
                />
              )}
              {activeTab === '2' && currentItem?.ppiAuditEvents && <ViewAudit auditData={currentItem} />}
            </ModalBody>
          </ItemModalContentContainer>
        </ItemModalContainer>
      </>
    </Modal>
  )
}

export default ViewItemModal

const ItemModalSidebarContainer = styled.div`
  background: rgb(228, 231, 235);
  border-bottom-left-radius: 16px;
  flex-shrink: 0;
  padding-top: 6px;
  width: 185px;
`

const ItemModalSidebarNav = styled.div`
  margin: 0;
  padding-left: 8px;
  padding-right: 8px;
`

const ItemModalSidebarNavOption = styled.button`
  align-items: center;
  background-color: ${({ active }) => (active ? '#CCD2D8' : 'transparent')};
  border: 0;
  display: flex;
  font-size: 14px;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  height: 40px;
  margin-bottom: 8px;
  padding: 0 8px 0 16px;
  width: 100%;
  border-radius: 4px;
`

const ItemModalContainer = styled.div`
  display: flex;
`

const ItemModalContentContainer = styled.div`
  width: 100%;
`

const ItemModalHeaderDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  div {
    display: flex;
  }
`

const ItemModalHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

const ModalHeaderMod = styled(ModalHeader)`
  height: 80px;
  font-size: 12px;
  border-top-left-radius: 7px !important;
  border-top-right-radius: 7px !important;
`
