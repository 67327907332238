/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-underscore-dangle */
import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  FormBreak,
  FormGrid,
  FormErrorLabel,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormInputWithSymbol,
  FormLabel,
  FormSelect,
  ModalFooter,
} from '@patchworkhealth/web-components'
import { ButtonAddDark, Info } from '@patchworkhealth/web-components/icons'
import { useFormik } from 'formik'
import 'react-day-picker/lib/style.css'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import {
  errorToast,
  loadingToast,
  successToast,
  STAFF_GROUPS,
  PAID_PER_ITEM_UPDATE_ITEM,
} from '../helpers/PayPerItemItemsHelpers'
import DeleteItemModal from './DeleteItemModal'

const staticModalFields = {
  title: 'Are you sure you want to disable this grade?',
  submitText: 'Yes, disable grade',
  description:
    'Please note that when disabling this grade, it will still be displayed within submitted receipts that are still in progress but will not be available for any new receipt requests for the item. Once the receipt for this grade has been approved to payroll, it will be removed from the list of grades for the item. This can not be undone.',
}

function ViewItemDetails({ departments, handleClose, inputs, permissions, refetchItems }) {
  const { organisation, currentItem: item } = inputs
  const [organisationStaffGroups, setOrganisationStaffGroups] = useState([])
  const [validate, setValidate] = useState(false)
  const [grades, setGrades] = useState([])
  const [, triggerStateUpdate] = useState(null)
  const [updateItem] = useMutation(PAID_PER_ITEM_UPDATE_ITEM)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: item?.name,
      departmentId: item?.department.id,
      organisationId: organisation.id,
      organisationStaffGroupId: Number(item?.organisationStaffGroup.id),
      itemGradeCostsAttributes: item?.itemGradeCosts.map((igc) => ({
        id: igc.id,
        gradeId: Number(igc.grade.id),
        cost: igc.cost,
        _destroy: false,
      })),
    },
    validateOnChange: validate,
    validationSchema: Yup.object({
      itemGradeCostsAttributes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required('Required'),
          gradeId: Yup.number().typeError('Required').required('Select a grade'),
          cost: Yup.number()
            .typeError('Required')
            .required('Provide a unit cost')
            .max(999.99, 'Unit cost must be less than £1000'),
        })
      ),
      departmentId: Yup.number().typeError('Required').required('Select a department'),
      organisationId: Yup.number().typeError('Required').required('Select an organisation'),
      organisationStaffGroupId: Yup.number().typeError('Required').required('Select a staff group'),
    }),
    onSubmit: (values, { resetForm }) => {
      toast.loading('Loading', loadingToast)

      const gradesWithoutId = values.itemGradeCostsAttributes.map((gradeCost) =>
        typeof gradeCost.id === 'string' ? { ...gradeCost, id: null } : gradeCost
      )

      updateItem({
        variables: {
          id: item.id,
          departmentId: values.departmentId,
          organisationStaffGroupId: values.organisationStaffGroupId,
          organisationId: organisation.id,
          itemGradeCostsAttributes: gradesWithoutId,
        },
      })
        .then((response) => {
          const errors = response.data?.paidPerItemUpdateItem?.errors

          if (errors.length > 0) {
            toast.update(2, { ...errorToast, render: `${errors[0].message}` })
            return
          }

          handleClose()
          resetForm()
          refetchItems()

          toast.update(2, { ...successToast, render: 'Item updated' })
        })
        .catch(() => {
          toast.update(2, { ...errorToast, render: 'Item could not be updated' })
        })
    },
  })
  const [deleteModalState, setDeleteModalState] = useState({
    ...staticModalFields,
    isOpen: false,
    itemId: null,
    handleClose: () => setDeleteModalState({ ...deleteModalState, isOpen: false }),
  })

  useQuery(STAFF_GROUPS, {
    variables: {
      organisationId: organisation.id,
    },
    onCompleted: (res) => {
      if (res) {
        setOrganisationStaffGroups(res.organisationStaffGroups)
        setGrades(
          res.organisationStaffGroups.find((sg) => Number(sg.id) === formik.values.organisationStaffGroupId)?.grades
        )
      }
    },
  })

  if (!item) return null

  const gradeInputs = formik.values.itemGradeCostsAttributes
  const isOnlyOneGradeLeft = gradeInputs.filter((grade) => !grade._destroy).length === 1
  const departmentOptions = departments.map((d) => ({ label: d.name, value: d.id }))
  const organisationStaffGroupOptions = organisationStaffGroups.map((sg) => ({ label: sg.title, value: Number(sg.id) }))
  const sortedGrades = [...grades]?.sort((a, b) => a.label.localeCompare(b.label))

  return (
    <>
      <FormGridContainer>
        <FormGrid columns={2}>
          <FormGroupMod>
            <FormLabel>
              Item Name <span>*</span>
            </FormLabel>
            <ModFormInput
              disabled
              name="name"
              onChange={formik.handleChange}
              placeholder="Enter item name..."
              type="text"
              value={item.name}
            />
            {formik.errors.name && <FormErrorLabel>{formik.errors.name}</FormErrorLabel>}
          </FormGroupMod>

          <FormGroup>
            <FormLabel>
              Department <span>*</span>
            </FormLabel>
            <FormSelect
              isDisabled
              name="reason"
              onChange={(option) => {
                formik.setFieldValue('departmentId', option !== null ? Number(option.value) : null)
              }}
              options={departmentOptions}
              placeholder="Select department"
              value={departmentOptions?.find((d) => d.value === formik.values.departmentId)}
            />
            {formik.errors.departmentId && <FormErrorLabel>{formik.errors.departmentId}</FormErrorLabel>}
          </FormGroup>
        </FormGrid>
      </FormGridContainer>

      <FormBreak />

      <FormGridContainer>
        <ItemModalTitle>Staff grade rates</ItemModalTitle>

        <FormGrid columns={2} style={{ marginBottom: 16 }}>
          <FormGroupMod>
            <FormLabel>
              Staff Group <span>*</span>
            </FormLabel>
            <FormSelect
              isDisabled
              name="reason"
              onChange={(option) => {
                formik.setFieldValue('organisationStaffGroupId', option !== null ? Number(option.value) : null)
              }}
              options={organisationStaffGroupOptions}
              placeholder="Select Staff Group"
              value={organisationStaffGroupOptions?.find(
                (sg) => Number(sg.value) === Number(formik.values.organisationStaffGroupId)
              )}
            />
            {formik.errors.organisationStaffGroupId && (
              <FormErrorLabel>{formik.errors.organisationStaffGroupId}</FormErrorLabel>
            )}
          </FormGroupMod>
        </FormGrid>
        {gradeInputs.map((gradeCost, index) => (
          <GradesFormGrid key={gradeCost.id} columns={3} style={{ width: '80%' }}>
            <FormGroupMod>
              <FormLabel>
                Select Grade <span>*</span>
              </FormLabel>
              <FormSelect
                isDisabled={!(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) || gradeCost._destroy}
                name="grade"
                onChange={(option) => {
                  const newGrade = { ...gradeCost, gradeId: Number(option.value) }
                  const updatedInputs = [...gradeInputs]
                  updatedInputs[index] = newGrade
                  formik.setFieldValue('itemGradeCostsAttributes', updatedInputs)
                  triggerStateUpdate(null)
                }}
                options={sortedGrades?.filter((sg) => {
                  const isNotCurrentGrade = Number(sg.value) !== gradeCost.gradeId
                  const isNotSelectedAlready = !gradeInputs.map((gi) => gi.gradeId).includes(Number(sg.value))

                  return isNotCurrentGrade && isNotSelectedAlready
                })}
                placeholder="Select Grade"
                value={sortedGrades?.find((g) => Number(g.value) === gradeCost.gradeId)}
              />

              {formik.errors.itemGradeCostsAttributes?.[index]?.gradeId && (
                <FormErrorLabel>{formik.errors.itemGradeCostsAttributes[index].gradeId}</FormErrorLabel>
              )}
            </FormGroupMod>
            <FormGroupMod>
              <FormLabel>
                Unit cost <span>*</span>
              </FormLabel>
              <ModFormInputWithSymbol
                before
                disabled={!(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) || gradeCost._destroy}
                max={999.99}
                name="name"
                onChange={(e) => {
                  const newGrade = { ...gradeCost, cost: Number(e.target.value) || null }
                  const updatedInputs = [...gradeInputs]
                  updatedInputs[index] = newGrade
                  formik.setFieldValue('itemGradeCostsAttributes', updatedInputs)
                  triggerStateUpdate(null)
                }}
                placeholder="Enter cost"
                symbol=" £ "
                type="number"
                value={gradeCost.cost}
              />
              {formik.errors.itemGradeCostsAttributes?.[index]?.cost && (
                <FormErrorLabel>{formik.errors.itemGradeCostsAttributes[index].cost}</FormErrorLabel>
              )}
            </FormGroupMod>
            {gradeCost._destroy && (
              <RemovalAlert>
                <Info style={{ width: 15, marginRight: 7 }} />
                <span>Will be removed upon save</span>
              </RemovalAlert>
            )}
            {gradeInputs.length > 1 && !gradeCost._destroy && !isOnlyOneGradeLeft && (permissions.canCreateAndEditPpi || permissions.isSuperAdmin) && (
              <FormGroupMod>
                <RemoveBtn
                  as="a"
                  onClick={() => {
                    const isNewGrade = !item?.itemGradeCosts.find((grade) => grade.id === gradeCost.id)

                    if (isNewGrade) {
                      formik.setFieldValue(
                        'itemGradeCostsAttributes',
                        gradeInputs.filter((g) => g.id !== gradeCost.id)
                      )
                      triggerStateUpdate(null)
                      return
                    }

                    setDeleteModalState({
                      ...deleteModalState,
                      isOpen: true,
                      itemId: gradeCost.id,
                    })
                  }}
                >
                  Remove
                </RemoveBtn>
              </FormGroupMod>
            )}
          </GradesFormGrid>
        ))}
        {(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) && gradeInputs.length < sortedGrades.length && (
          <FormGrid columns={2}>
            <AddStaffGroupButton
              action={() => {
                const newGrade = { id: uuidv4(), gradeId: null, cost: null }
                formik.setFieldValue('itemGradeCostsAttributes', [...gradeInputs, newGrade])
                triggerStateUpdate(null)
              }}
              icon={ButtonAddDark}
              style={{ marginRight: 20 }}
              text="Add another staff grade"
              type="grey"
            />
          </FormGrid>
        )}
      </FormGridContainer>
      {(permissions?.canCreateAndEditPpi || permissions?.isSuperAdmin) && (
        <>
          <ItemModalFooter>
            <div />
            <div>
              <DefaultButton action={() => handleClose()} text="Cancel" type="white" />
              <DefaultButton
                action={() => {
                  setValidate(true)
                  formik.handleSubmit()
                }}
                color="blue"
                text="Save changes"
              />
            </div>
          </ItemModalFooter>
          <DeleteItemModal
            {...deleteModalState} // eslint-disable-line react/jsx-props-no-spreading
            handleDelete={() => {
              const updatedGradeCosts = formik.values.itemGradeCostsAttributes.map((gradeCost) => {
                if (gradeCost.id === deleteModalState.itemId) return { ...gradeCost, _destroy: true }
                return gradeCost
              })

              formik.setFieldValue('itemGradeCostsAttributes', updatedGradeCosts)
              triggerStateUpdate(null)
            }}
          />
        </>
      )}
    </>
  )
}

export default ViewItemDetails

const RemovalAlert = styled.div`
  display: flex;
  margin-top: 34px;

  span {
    display: block;
    font-size: 15px;
    color: #dc5a54;
    white-space: nowrap;
  }
`

const ModFormInputWithSymbol = styled(FormInputWithSymbol)`
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`

const ModFormInput = styled(FormInput)`
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`

const ItemModalTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`

const AddStaffGroupButton = styled(DefaultButton)`
  width: 70%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;

  span {
    top: 0;
  }
`

const RemoveBtn = styled(DefaultButton)`
  display: inline-block;
  color: #dc5a54;
  text-decoration: underline;
  margin-top: 33px;

  &:hover {
    color: #dc5a54;
    cursor: pointer;
    text-decoration: underline;
  }
`

const ItemModalFooter = styled(ModalFooter)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
`

const GradesFormGrid = styled(FormGrid)`
  margin-bottom: 20px;
`

export const FormGroupMod = styled(FormGroup)`
  position: relative;
  svg:hover + div {
    opacity: 1 !important;
  }

  .input-group-text {
    border: 1px solid #ccd2d8;
  }
`
