import { Controller } from 'stimulus'

export default class TableToggleController extends Controller {

    static get targets() {
    }

    connect() {
    }

    goToDataDestination() {
        event.preventDefault();
        const destination = event.target.dataset.destination
        window.location = destination;
    }
}

import { application } from '../../frontend/controllers/index'

application.register("table_toggle", TableToggleController)