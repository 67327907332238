import React from "react";
import moment from "moment";
import styled from "styled-components";

const ShiftAuditTrail = ({ shift }) => {
  return (
    <div>
      <ShiftAuditDetailContainer>
        <div>
          <ShiftItem>
            <strong>Site:</strong> {shift.site ? shift.site.name : "-"}
          </ShiftItem>
          <ShiftItem>
            <strong>Start Time:</strong>{" "}
            {shift.startTime && moment(shift.startTime).format("h:mma")},{" "}
            {shift.startTime && moment(shift.startTime).format("MMMM Do YYYY")}
          </ShiftItem>
          <ShiftItem>
            <strong>Total Rate (£/hr):</strong>{" "}
            {shift.totalRateSummary ? shift.totalRateSummary : "-"}
          </ShiftItem>
          <ShiftItem>
            <strong>Shift Grade:</strong>{" "}
            {shift.grade ? shift.grade.title : "-"}
          </ShiftItem>
        </div>
        <div>
          <ShiftItem>
            <strong>Dept:</strong>{" "}
            {shift.department ? shift.department.name : "-"}
          </ShiftItem>
          <ShiftItem>
            <strong>End Time:</strong>{" "}
            {shift.endTime && moment(shift.endTime).format("h:mma")},{" "}
            {shift.startTime && moment(shift.startTime).format("MMMM Do YYYY")}
          </ShiftItem>
          <ShiftItem>
            <strong>Agency Rate (£/hr):</strong>{" "}
            {shift.agencyRateSummary ? shift.agencyRateSummary : "-"}
          </ShiftItem>
          {shift.bookedBy && (
            <ShiftItem>
              <strong>Worker:</strong>{" "}
              {shift.bookedBy.firstName && shift.bookedBy.firstName}{" "}
              {shift.bookedBy.lastName && shift.bookedBy.lastName}
            </ShiftItem>
          )}
        </div>
      </ShiftAuditDetailContainer>
      <ShiftAuditTable className="table table-hover">
        <thead>
          <tr>
            <th className="center">Date &amp; Time</th>
            <th className="center">Event Type</th>
            <th>User</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {shift.auditEvents.map((event) => (
            <tr key={event.id}>
              <th className="center">
                {moment(event.time).format("h:mm a Do MMM YY")}
              </th>
              <th className="center">{event.event}</th>
              <td>
                <div dangerouslySetInnerHTML={{ __html: event.user }} />
              </td>
              <td>
                <div dangerouslySetInnerHTML={{ __html: event.description }} />
              </td>
            </tr>
          ))}
        </tbody>
      </ShiftAuditTable>
    </div>
  );
};

export default ShiftAuditTrail;

const ShiftAuditDetailContainer = styled.div`
  border-radius: 8px;
  display: flex;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 24px;
`;

const ShiftItem = styled.div`
  font-size: 16px;
  margin-bottom: 16px;

  strong {
    font-weight: 700;
  }
`;

const ShiftAuditTable = styled.table`
  background: #fff;
  border: 1px solid #cbd2d9;
  border-radius: 4px;

  .centre {
    text-align: center;
  }

  thead {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;

    th {
      border: 0;
      font-size: 14px;
      font-weight: 500;
      padding: 0;
      text-transform: capitalize;
      letter-spacing: 0;
      padding: 8px 16px;

      &:not(:last-child) {
        border-right: 1px solid #cbd2d9;
      }
    }
  }

  tbody {
    tr {
      background: #f5f7fa;

      td,
      th {
        border-color: #cbd2d9;
        font-size: 14px;
        padding: 24px 16px;

        &:not(:last-child) {
          border-right: 1px solid #cbd2d9;
        }
      }

      &:hover {
        background: #eceff3;
      }
    }
  }
`;
