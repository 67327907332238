export async function createSuperAdmins(body) {
  const url = `/super_admins`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function editSuperAdmins(adminId, body) {
  const url = `/super_admins/${adminId}`;

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function deleteSuperAdmin(adminId) {
  const url = `/super_admins/${adminId}/remove`;

  const data = fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}
