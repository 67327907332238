/* eslint-disable */
import React from 'react';

export default function Success() {
  return (
    <div className='reset-pin__success-svg'>
      <svg width="236" height="195" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M168.6 44.1466h-1.677c-.331 0-.599.2669-.599.5962v22.3975c0 .3293.268.5962.599.5962h1.677c.331 0 .599-.2669.599-.5962V44.7428c0-.3293-.268-.5962-.599-.5962Z" fill="#3F3D56"/>
        <path d="M157.679 0H81.9039C76.2734 0 71.709 4.545 71.709 10.1515V184.458c0 5.606 4.5644 10.151 10.1949 10.151h75.7751c5.631 0 10.195-4.545 10.195-10.151V10.1515C167.874 4.545 163.31 0 157.679 0Z" fill="#3F3D56"/>
        <path d="M154.489 5.06897h-11.031v1.32134c0 .82248-.163 1.63691-.479 2.39674-.316.75984-.779 1.45015-1.364 2.03165-.584.5814-1.277 1.0425-2.041 1.3569-.763.3145-1.581.4761-2.407.4757h-35.082c-.826.0004-1.644-.1612-2.4072-.4757-.7633-.3144-1.4568-.7755-2.041-1.3569-.5843-.5815-1.0477-1.27181-1.3639-2.03165-.3162-.75983-.479-1.57426-.479-2.39674V5.06897H85.4656c-2.0256 0-3.9682.80123-5.4005 2.22743-1.4323 1.42621-2.237 3.3606-2.237 5.3775V181.949c0 2.017.8047 3.951 2.237 5.377 1.4323 1.426 3.3749 2.228 5.4005 2.228h69.0234c2.026 0 3.969-.802 5.401-2.228 1.432-1.426 2.237-3.36 2.237-5.377V12.6869c.002-.9998-.195-1.9901-.578-2.91426-.383-.92417-.945-1.76407-1.655-2.47164-.709-.70756-1.551-1.2689-2.479-1.6519-.927-.38299-1.922-.58013-2.926-.58013Z" fill="#F5F7FA"/>
        <path d="M122.933 5.81177h-11.404c-.559 0-1.012.45112-1.012 1.0076v.69855c0 .55649.453 1.00761 1.012 1.00761h11.404c.559 0 1.012-.45112 1.012-1.00761v-.69855c0-.55648-.453-1.0076-1.012-1.0076ZM127.523 8.70986c.855 0 1.548-.68997 1.548-1.54109 0-.85112-.693-1.54108-1.548-1.54108-.854 0-1.547.68996-1.547 1.54108s.693 1.54109 1.547 1.54109Z" fill="#E6E8EC"/>
        <path d="M120.379 117.808c10.047 0 18.192-8.11 18.192-18.1141 0-10.0042-8.145-18.1142-18.192-18.1142s-18.191 8.11-18.191 18.1142c0 10.0041 8.144 18.1141 18.191 18.1141Z" fill="#fff"/>
        <path d="M144.307 97.1645c-.001 4.7885-1.427 9.4695-4.099 13.4505-2.671 3.981-6.469 7.084-10.912 8.916-4.442 1.833-9.331 2.312-14.048 1.378-4.716-.935-9.048-3.241-12.448-6.627-3.4007-3.386-5.7162-7.699-6.6542-12.396-.9381-4.6962-.4564-9.5641 1.384-13.9879 1.8404-4.4238 4.9572-8.2048 8.9552-10.865 3.999-2.6601 8.7-4.0799 13.508-4.0797 3.193.0001 6.355.6264 9.305 1.8432 2.95 1.2168 5.63 3.0002 7.888 5.2484 2.257 2.2482 4.048 4.9172 5.27 7.8545 1.222 2.9374 1.851 6.0857 1.851 9.265ZM117.183 109.98l18.036-17.9595c.146-.1449.261-.3171.34-.5067.079-.1896.12-.3929.12-.5981 0-.2053-.041-.4086-.12-.5981-.079-.1896-.194-.3618-.34-.5068L133 87.6173c-.146-.1453-.318-.2605-.509-.3392-.19-.0786-.394-.1191-.6-.1191-.205 0-.409.0405-.599.1191-.191.0787-.363.1939-.509.3392l-14.71 14.6477-6.868-6.8553c-.295-.292-.694-.4559-1.11-.4559-.416 0-.814.1639-1.109.4559l-2.219 2.2227c-.294.2936-.458.6908-.458 1.1048 0 .4141.164.8113.458 1.1049l10.197 10.1509c.146.145.319.26.509.339.19.079.395.119.601.119.206 0 .41-.04.6-.119.191-.079.364-.194.509-.339v-.013Z" fill="#57A886"/>
        <path d="M74.7197 38.3663h-.2518c-.3307 0-.5988.2669-.5988.5962v5.7886c0 .3293.2681.5963.5988.5963h.2518c.3307 0 .5988-.267.5988-.5963v-5.7886c0-.3293-.2681-.5962-.5988-.5962ZM74.8518 51.1493h-.4356c-.3307 0-.5988.2669-.5988.5962v10.9577c0 .3292.2681.5962.5988.5962h.4356c.3307 0 .5988-.267.5988-.5962V51.7455c0-.3293-.2681-.5962-.5988-.5962ZM74.7985 67.5509h-.3569c-.3307 0-.5988.2669-.5988.5962v11.0621c0 .3293.2681.5962.5988.5962h.3569c.3307 0 .5988-.2669.5988-.5962V68.1471c0-.3293-.2681-.5962-.5988-.5962Z" fill="#3F3D56"/>
        <path d="M54.5811 99.9559c.0415.04.0949.0651.1522.0731.0573.007.1154-.004.1657-.0324-.1074.0016-.2144-.0121-.3179-.0407Z" fill="url(#a)"/>
        <path d="m66.5989 104.637-.3138 1.702-.2421 1.302-.4404 2.381-4.2696 2.976s-.3849-.328-1.0198-.845c-1.7498-1.434-5.4274-4.356-8.128-5.924-.6946-.441-1.4462-.786-2.234-1.025l16.6477-.567Z" fill="#FEB9B9"/>
        <path d="M63.467 105.903s4.9783.851 5.2698-1.266c.2916-2.117-2.5625-3.261-4.9811-3.403-2.4187-.143-16.9353.85-16.9353.85s-2.5607 3.401 6.6872 3.97l9.9594-.151Z" fill="#3F3D56"/>
        <path d="M74.3536 36.046H48.3105v30.7498h26.0431V36.046Z" fill="#2F2E41"/>
        <path opacity=".1" d="M74.3536 36.1855H48.3105v30.7499h26.0431V36.1855Z" fill="#000"/>
        <path d="M17.788 88.6855c-.4288.1076-.9769.5208-1.5864 1.118-2.6247 2.5875-6.38395 8.6597-6.38395 8.6597-4.8062-5.7006.51225-10.2047 2.77925-11.7525.5512-.3747.9214-.5809.9214-.5809l4.2697 2.5557ZM66.3128 45.751s1.2813 8.9268 5.6927 11.0524c4.4113 2.1256-5.6927 6.0982-5.6927 6.0982l-13.803-5.1052s7.8275-2.5516 6.2613-10.0622l7.5417-1.9832ZM51.5131 99.4562l-.3122 1.6958-.2536 1.307-.4404 2.381-4.2696 2.974s-.3849-.325-1.0198-.842c-1.7498-1.435-5.4272-4.356-8.1319-5.924-.6943-.442-1.4459-.786-2.234-1.025l16.6615-.5668Z" fill="#FEB9B9"/>
        <path d="M47.8197 101.548s4.4678.031 4.7366-2.0917c.2688-2.123-2.4349-3.261-4.7366-3.4035-2.3018-.1424-16.2871.917-16.2871.917s-1.9841 4.0092 6.8127 4.5782h9.4744Z" fill="#2F2E41"/>
        <path d="M79.145 134.899c-.1954-.096-.3909-.195-.5834-.29l-.525-.66-6.8855-8.694v.151c-.0465 1.452-.5423 12.958-4.4095 14.02-4.1266 1.133-9.1049 1.417-11.3743-6.234-2.2693-7.652-6.4158-24.373-10.6739-25.366l6.3342-5.172s4.1938 4.884 5.9026 9.56c-.0641-.291-2.9602-13.7649-.5834-16.149h20.7809s3.208 9.098 5.4068 13.605c1.7119 3.499 4.2811 11.773 5.9355 17.371 1.6376 5.529-4.1164 10.41-9.325 7.858Z" fill="#3F3D56"/>
        <path opacity=".1" d="M78.5528 98.6054c-3.2081 1.8906-17.2912 1.1209-21.8728.8131l-1.2483-.0901c-.1341 0 .0204-.0755.0204-.0755l.1284-1.2864 3.4151-8.6422 18.2856-10.5588c-.3705 1.1123-.4929 2.814-.4636 4.7626.0845 6.2609 1.7352 15.0773 1.7352 15.0773Z" fill="#000"/>
        <path d="M78.5527 98.1786c-3.208 1.8904-17.2911 1.1209-21.8727.8132l-1.2483-.0873-.2071-.0174.2275-.0581 1.1256-.2903 2.4177-9.6352 18.2855-10.5559c-.3702 1.1123-.4929 2.814-.4636 4.7625.0847 6.255 1.7354 15.0685 1.7354 15.0685Z" fill="#EFEFEF"/>
        <path opacity=".1" d="M76.8058 83.1042c-3.7184.8537-11.9016 3.3586-15.0456 9.4087-2.0968 4.0278-3.8612 5.7556-5.092 6.4786l-1.2483-.0872c.0082-.0248.015-.05.0204-.0755l1.1257-.2903 2.4176-9.6353 18.2856-10.5556c-.3661 1.1063-.4927 2.808-.4634 4.7566ZM13.5181 86.1361l4.2697 2.5497c-.4289.1076-.9769.5208-1.5864 1.118-.3559-1.0339-2.5886-2.4771-3.6047-3.0926.5509-.3689.9214-.5751.9214-.5751ZM54.6597 99.0702c-.0468.0242-.1.0334-.1523.0261-.0522-.0073-.1009-.0306-.1392-.0668.0946.0277.1929.0413.2915.0407Z" fill="#000"/>
        <path d="M64.3209 56.2371s-3.8437.4238-6.5471-2.4102c0 0-11.3743 3.261-15.2263 10.6283-3.8521 7.3672-30.0387 21.8231-30.0387 21.8231s4.5554 2.5497 4.1266 3.8244l17.2211-9.9198s6.1243-4.3908 7.8247-5.3838c1.7004-.993 11.1025-7.0855 13.0945 9.4931 1.9919 16.5788 0 14.5952 0 14.5952s2.8464.9932 6.973-6.9434c4.1266-7.9366 16.9325-9.7776 16.9325-9.7776l.7114-8.3577s2.5403-5.5408.5834-7.8406c-.5929-.6809-.9872-1.5105-1.1403-2.3987-.3237-2.1227 0-5.6597 4.5319-8.5985 6.973-4.536 9.6765-8.2181 9.6765-8.2181s.1427-2.6947-4.1267-3.9697c-4.2694-1.275-21.6306-8.2181-21.6306-8.2181l-.5834 2.4102 13.6983 9.6266s-6.8301 5.5174-12.2486 5.3838c-.0003 0 1.0059 4.1091-3.8322 4.2515Z" fill="#17BFD6"/>
        <path opacity=".1" d="M66.9582 49.6364c-.3366-1.1359-.5996-2.2921-.7874-3.4615l-7.5417 1.9839c.3325 1.4336.2731 2.9298-.1721 4.3327 1.542.4097 3.171.3573 4.6832-.1505 1.5121-.5079 2.8402-1.4487 3.818-2.7046Z" fill="#000"/>
        <path d="M60.6935 52.3402c4.4793 0 8.1105-3.6157 8.1105-8.0759s-3.6312-8.0759-8.1105-8.0759-8.1105 3.6157-8.1105 8.0759 3.6312 8.0759 8.1105 8.0759Z" fill="#FEB9B9"/>
        <path opacity=".1" d="M69.5156 109.303s4.6954 9.212 3.2721 12.043l-3.2721-12.043Z" fill="#000"/>
        <path d="M65.007 25.6122c2.2513.2556 4.3891 1.2166 6.6056 1.7423.554.1715 1.1437.1935 1.709.0638.8603-.2643 1.5603-1.1615 2.4527-1.1354.5491.0174 1.0352.3892 1.4873.7462.7249.5525 1.4072 1.1582 2.0413 1.8121 1.5281 1.6523 2.4759 3.8768 3.8699 5.6743 1.3939 1.7976 3.3819 3.9055 5.3621 3.0812-1.3472 5.227-5.5488 8.4969-10.3226 8.8224-1.9043.1301-3.8555-.2643-5.6986.2903-.4591.1874-.9439.3048-1.4381.3483-1.0616 0-1.9161-.9698-2.5782-1.9136-1.5516-2.2186-2.7792-4.7363-4.5262-6.7604-.5833-.6706-1.2569-1.3067-2.0735-1.4286-.8166-.1218-1.566.2672-2.2921.6418-1.1986.6187-2.4233 1.2573-3.3537 2.3232-.9303 1.066-1.5165 2.6396-1.1316 4.0656.2071.7725.6798 1.4695.6882 2.2766.0175 1.2893-1.1257 2.2303-1.3503 3.4847-.0507.527-.0692 1.0565-.0554 1.5856-.0991 1.7184-1.229 3.1244-2.4056 4.2078-.4607.4181-.9675.8711-1.1024 1.5186-.0905.4442 0 .9113-.0583 1.362-.1721 1.1151-1.2891 1.6814-2.2777 1.8267-1.1551.1703-2.335.031-3.418-.4035 1.3824-.651 2.234-2.5032 1.9132-4.1614-.4403-2.3001-2.7617-3.9523-2.5625-6.2927.1091-.7181.3254-1.416.6417-2.0704.5058-1.2648.8735-2.5801 1.0966-3.9232.1749-1.0367.2597-2.1373-.0672-3.1275-.2915-.9003-.901-1.6173-1.4175-2.3784-.6998-1.0353-1.2523-2.1621-1.6418-3.3482-.1411-.3519-.1949-.7325-.1569-1.1094.117-.4453.366-.8451.7147-1.1472 1.2686-1.3279 2.6247-2.7065 4.325-3.0869.8113-.1341 1.6364-.1653 2.4556-.0929 1.4246.0445 2.8507-.0157 4.2665-.1802 1.1664-.1364 3.1377-.119 4.1266-.8712.9214-.7029 1.4407-1.5856 2.575-2.0326 1.1487-.424 2.3835-.5645 3.5987-.4096l-.0015-.0002Z" fill="#2F2E41"/>
        <path d="M236 194.254H0v.474h236v-.474Z" fill="#E4E7EB"/>
        <defs>
          <linearGradient id="a" x1="54.7416" y1="100.031" x2="54.7416" y2="99.9559" gradientUnits="userSpaceOnUse">
            <stop stopColor="gray" stopOpacity=".251"/>
            <stop offset=".54" stopColor="gray" stopOpacity=".122"/>
            <stop offset="1" stopColor="gray" stopOpacity=".102"/>
          </linearGradient>
        </defs>
      </svg>
    </div>    
  )
}