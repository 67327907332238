/* eslint-disable react/jsx-props-no-spreading */

import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { client } from '../../../packs/application'
import ItemisedReceipts from './ItemisedReceipts'

import 'react-toastify/dist/ReactToastify.css'

function ItemisedReceiptsIndex(props) {
  return (
    <>
      <ToastContainer position="top-center" transition={Slide} />

      <ApolloProvider client={client}>
        <ItemisedReceipts {...props} />
      </ApolloProvider>
    </>
  )
}

export default ItemisedReceiptsIndex
