import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../helpers/BlockComponents'
import { EditIcon } from '../helpers/BlockIcons'

function BlockOptions({
  selectedBlock,
  deleteBlock,
  viewBlock,
  setRecallBlockFromAgencyModal,
  setSendBlockToAgencyModal,
  modOrganisation,
  canManageAgency,
}) {
  // eslint-disable-next-line no-underscore-dangle
  // const agencyReg = selectedBlock.bookedBy?.__typename === 'AgencyRegistration'
  const deleteable = ['DRAFT', 'AVAILABLE'].includes(selectedBlock.status)
  const canSendToAgency = canManageAgency && ['AVAILABLE'].includes(selectedBlock.status)
  const editable = modOrganisation ? true : selectedBlock.status === 'DRAFT'
  const canRecallBlock = canManageAgency && selectedBlock.status !== 'BOOKED'
  const canUpdateBlockAgencyTier = canManageAgency && selectedBlock.sentToAgency && selectedBlock.status !== 'BOOKED'
  // const locked = selectedBlock.agencyLocked

  return (
    <BlockOptionsContainer>
      <SelectedRow>Block {selectedBlock.uid}</SelectedRow>

      {editable && (
        <StyledButton color="white" onClick={() => viewBlock(selectedBlock)} width="200">
          <EditIcon />
          Edit Block
        </StyledButton>
      )}

      <StyledButton
        color="white"
        onClick={() => deleteBlock(selectedBlock)}
        style={{
          opacity: deleteable ? 1 : '0.5',
          pointerEvents: deleteable ? 'initial' : 'none',
        }}
        width="200"
      >
        Delete Block
      </StyledButton>

      {!selectedBlock.sentToAgency && selectedBlock?.organisationStaffGroup?.vms && (
        <StyledButton
          color="white"
          onClick={() => setSendBlockToAgencyModal(selectedBlock)}
          style={{
            opacity: canSendToAgency ? 1 : '0.5',
            pointerEvents: canSendToAgency ? 'initial' : 'none',
          }}
          width="200"
        >
          Send Block to Agency
        </StyledButton>
      )}

      {selectedBlock.sentToAgency && (
        <StyledButton
          color="white"
          onClick={() => setRecallBlockFromAgencyModal(selectedBlock)}
          style={{
            opacity: canRecallBlock ? 1 : '0.5',
            pointerEvents: canRecallBlock ? 'initial' : 'none',
            width: 200,
          }}
        >
          Recall from Agency
        </StyledButton>
      )}

      {selectedBlock.status !== 'BOOKED' && selectedBlock.sentToAgency && (
        <StyledButton
          color="white"
          onClick={() => setSendBlockToAgencyModal(selectedBlock)}
          style={{
            opacity: canUpdateBlockAgencyTier ? 1 : '0.5',
            pointerEvents: canUpdateBlockAgencyTier ? 'initial' : 'none',
          }}
          width="200"
        >
          Update Agency Tier
        </StyledButton>
      )}
    </BlockOptionsContainer>
  )
}

export default BlockOptions

const BlockOptionsContainer = styled.div`
  display: flex;
  gap: 12px;
`

const SelectedRow = styled.div`
  color: white;
  font-size: 16px;
  left: 20px;
  position: absolute;
`
