import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import AppContext from "../../../../../Context/AppContext";
import ShiftModalWorkerCard from "../../ShiftModalWorkerCard";
import {
  ErrorContainer,
  SuccessContainer,
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalButtonClose,
  ShiftModalDetailGroup,
  ShiftModalFormGroup,
  ShiftModalFormGrid,
} from "../../ShiftModalComponents";

import { updateShift } from "../../../../../Helpers/ShiftHelpers";

const ShiftModalDetails = ({
  fetchShift,
  fetchShifts,
  handleClose,
  locked,
  setShiftLoading,
  shift,
  show,
}) => {
  const store = useContext(AppContext);
  const [siteId, setSiteId] = useState(shift.site.id);
  const [departments, setDepartments] = useState(undefined);
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState({});
  const [reasonLabel, setReasonLabel] = useState(undefined);
  const [inputs, setInputs] = useState({
    startDay: moment(shift.startTime).format("YYYY-MM-DD"),
    startTime: moment(shift.startTime).format("hh:mm"),
    endTime: moment(shift.endTime).format("hh:mm"),
    breaks: shift.breakMinutes,
    site: shift.site.id,
    department: shift.department.id,
    reason: shift.reasonForRequestId,
    staffType: shift.staffGroupId,
    grade: shift.grade.id,
    costCentre: shift.costCentre,
    note: shift.note,
    adminNote: shift.adminNote,
  });

  // // remap sites into an array react-select can read
  // const sites = store.sites.map(function (site) {
  //   return { value: site.id, label: site.name };
  // });

  // remap reasons into an array react-select can read
  // const reasons = store.reasons.map(function (reason) {
  //   return { value: reason.id, label: reason.reason };
  // });

  // // remap staffTypes into an array react-select can read
  // const staffTypes = store.staffTypes.map(function (staffType) {
  //   return { value: staffType.id, label: staffType.title };
  // });

  // // remap grades into an array react-select can read
  // const grades = store.grades.map(function (grade) {
  //   return { value: grade.id, label: grade.title };
  // });

  // // remap costCentres into an array react-select can read
  // const costCentres = store.costCentres.map(function (costCentre) {
  //   return { value: costCentre.code, label: costCentre.code };
  // });

  // useEffect(() => {
  //   if (show) {
  //     fetchDepartments(siteId);
  //   }

  //   // if (reasons) {
  //   //   let reason = reasons.find((obj) => obj.value == shift.reasonForRequestId);
  //   //   setReasonLabel(reason.label);
  //   // }
  // }, [show, siteId]);

  // const fetchDepartments = (siteId) => {
  //   const site_id = Number(siteId);

  //   const query = `
  //     query Departments($site_id: Int!){
  //       departments(siteId: $site_id){
  //         id
  //         name
  //       }
  //     }
  //   `;
  //   fetch("/graphql", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     body: JSON.stringify({
  //       query,
  //       variables: { site_id },
  //     }),
  //   })
  //     .then((r) => r.json())
  //     .then((data) => {
  //       setDepartments(data.data.departments);
  //     });
  // };

  // remap costCentres into an array react-select can read
  const remapDepartments =
    departments &&
    departments.map(function (department) {
      return { value: department.id, label: department.name };
    });

  const handleSelectChange = (event, name) => {
    setSuccess(undefined);
    setError({});
    setInputs((inputs) => ({ ...inputs, [name]: event.value }));
  };

  const handleInputChange = (event) => {
    setSuccess(undefined);
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const updateShiftAction = (e) => {
    const body = {
      shift: {
        organisation_id: store.organisation.id,
        start_day: inputs.startDay,
        proposed_start_time: inputs.startTime,
        proposed_end_time: inputs.endTime,
        breaks: inputs.breaks ? inputs.breaks : 0,
        site_id: inputs.site,
        department_id: inputs.department,
        reason_for_request_id: inputs.reason,
        staff_group_id: inputs.staffType,
        grade_id: Number(inputs.grade),
        cost_centre: inputs.costCentre,
        note: inputs.note,
        admin_note: inputs.adminNote,
      },
    };

    if (
      body.shift.start_day === "" ||
      body.shift.proposed_start_time === "" ||
      body.shift.proposed_end_time === ""
    ) {
      setError({
        ...error,
        time: true,
        message: "Please fill in all of the date/time fields.",
      });
    } else {
      setShiftLoading(true);
      e.preventDefault();
      updateShift(shift.id, body).then((data) => {
        if (data !== "err") {
          fetchShift(shift.id);
          setSuccess("Changes saved.");
          setTimeout(() => {
            setShiftLoading(false);
            fetchShifts();
          }, 300);
        } else {
          setShiftLoading(false);
          setError({
            ...error,
            main: true,
            message: "There was an error saving the shift, please try again.",
          });
        }
      });
    }
  };

  return (
    <>
      {locked ? (
        ""
      ) : (
        <ShiftModalDetailFooter>
          <div>
            {error && error.time && (
              <ErrorContainer>
                <p>{error.message}</p>
              </ErrorContainer>
            )}
            {success && (
              <SuccessContainer>
                <p>{success}</p>
              </SuccessContainer>
            )}
          </div>
          <div>
            <ShiftModalButtonClose onClick={handleClose}>
              Close
            </ShiftModalButtonClose>
            <ShiftModalButton
              theme={"blue"}
              onClick={(e) => updateShiftAction(e)}
            >
              Save Changes
            </ShiftModalButton>
          </div>
        </ShiftModalDetailFooter>
      )}
      <ShiftModalContainer locked={locked}>
        <ShiftModalDetailGroup>
          <ShiftModalDetailGroupWorkerGrid>
            <div>
              <h2>Date &amp; Hours</h2>
              {error && error.main && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
              <ShiftModalFormGrid columns={2}>
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={moment(shift.startTime).format("YYYY-MM-DD")}
                  label={"Start Date"}
                  name="startDay"
                  onChange={handleInputChange}
                  type="date"
                  disabled={locked}
                />
              </ShiftModalFormGrid>
              <ShiftModalFormGrid columns={2}>
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={moment(shift.startTime).format("hh:mm")}
                  label={"Start Time"}
                  name="startTime"
                  onChange={handleInputChange}
                  type="time"
                  disabled={locked}
                />
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={moment(shift.endTime).format("hh:mm")}
                  label={"End Time"}
                  name="endTime"
                  onChange={handleInputChange}
                  type="time"
                  disabled={locked}
                />
              </ShiftModalFormGrid>
              <ShiftModalFormGrid columns={2}>
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={shift.breakMinutes}
                  label={"Break Minutes"}
                  name="breaks"
                  onChange={handleInputChange}
                  type="number"
                  disabled={locked}
                />
              </ShiftModalFormGrid>
            </div>
            <div>
              {shift.bookedBy && <ShiftModalWorkerCard shift={shift} />}
            </div>
          </ShiftModalDetailGroupWorkerGrid>
        </ShiftModalDetailGroup>
        <ShiftModalDetailGroup>
          <h2>Shift Info - {shift.organisation.name}</h2>
          <ShiftModalFormGrid columns={3}>
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={shift.site.name}
                label={"Site"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Site"}
                options={sites}
                placeholder="Please select a site"
                name="site"
                defaultValue={{ label: shift.site.name, value: shift.site.id }}
                disabled={true}
              />
            )}
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={shift.department.name}
                label={"Department"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Department"}
                options={remapDepartments}
                placeholder="Please select a department"
                name="department"
                defaultValue={{
                  label: shift.department.name,
                  value: shift.department.id,
                }}
                onChange={(event) => handleSelectChange(event, "department")}
                disabled={locked}
              />
            )}
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={reasonLabel}
                label={"Reason for Booking"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Reason for Booking"}
                options={reasons}
                placeholder="Please select a reason"
                name="department"
                defaultValue={{
                  label: reasonLabel,
                  value: shift.reasonForRequestId,
                }}
                onChange={(event) => handleSelectChange(event, "reason")}
                disabled={locked}
              />
            )}
          </ShiftModalFormGrid>
        </ShiftModalDetailGroup>
        <ShiftModalDetailGroup>
          <h2>Clinical Info</h2>
          <ShiftModalFormGrid columns={3}>
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={shift.staffGroup.title}
                label={"Staff Group"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Staff Group"}
                options={staffTypes}
                placeholder="Please select a staff group"
                name="staffType"
                defaultValue={{
                  label: shift.staffGroup.title,
                  value: shift.staffGroupId,
                }}
                disabled={true}
              />
            )}
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={shift.grade.title}
                label={"Grade"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Grade"}
                options={grades}
                placeholder="Please select a grade"
                name="grade"
                defaultValue={{
                  label: shift.grade.title,
                  value: shift.grade.id,
                }}
                onChange={(event) => handleSelectChange(event, "grade")}
                disabled={locked}
              />
            )}
            {locked ? (
              <ShiftModalFormGroup
                component={"input"}
                defaultValue={shift.costCentre}
                label={"Cost Centre"}
                type="text"
                disabled={locked}
              />
            ) : (
              <ShiftModalFormGroup
                component={"react-select"}
                label={"Cost Centre"}
                options={costCentres}
                name="costCentre"
                defaultValue={{
                  label: shift.costCentre,
                  value: shift.costCentre,
                }}
                onChange={(event) => handleSelectChange(event, "costCentre")}
                disabled={locked}
              />
            )}
          </ShiftModalFormGrid>
        </ShiftModalDetailGroup>
        <ShiftModalDetailGroup>
          <h2>Optional Info</h2>
          <ShiftModalFormGrid columns={2} full>
            <ShiftModalFormGroup
              component={"textarea"}
              defaultValue={shift.note}
              label={"Worker Notes"}
              name="note"
              onChange={handleInputChange}
              placeholder={
                "Leave helpful notes for the worker. e.g. Bleep numbers, who and where to report to, logins for computers and access codes for doors."
              }
              type="textarea"
              disabled={locked}
            />
            <ShiftModalFormGroup
              component={"textarea"}
              defaultValue={shift.adminNote}
              label={"Admin Notes"}
              name="adminNote"
              onChange={handleInputChange}
              placeholder={"Don’t worry only Admin staff can see these notes."}
              type="textarea"
              disabled={locked}
            />
          </ShiftModalFormGrid>
        </ShiftModalDetailGroup>
      </ShiftModalContainer>
    </>
  );
};

export default ShiftModalDetails;

const ShiftModalDetailGroupWorkerGrid = styled.div`
  display: grid;
  grid-gap: 90px;
  grid-template-columns: 1fr 250px;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;

    div {
      &:first-child {
        margin-right: 90px;
      }

      &:nth-child(2) {
        min-width: 250px;
      }
    }
  }
`;
