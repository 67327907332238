/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import {gql} from '@apollo/client'
import {BLUE, GLOBAL, GREEN, GREY, ORANGE, RED, YELLOW} from '@patchworkhealth/web-components'
import React from 'react'
import styled from 'styled-components'

export const GET_CURRENT_HUB_USER = gql`
  query CurrentHubUser($appId: String) {
    currentHubUser(appId: $appId) {
      canManageAgency
    }
  }
`

// All Blocks ***************************

export const SHIFT_BLOCKS = gql`
  query shiftBlocks(
    $gradeIds: [Int!]
    $departmentIds: [Int!]
    $startDateTo: DateTime
    $startDateFrom: DateTime
    $endDateFrom: DateTime
    $endDateTo: DateTime
    $shiftBlockIds: [Int!]
    $statuses: [ShiftBlockStatusEnum!]
    $siteIds: [Int!]
    $first: Int
    $last: Int
    $after: String
    $before: String
    $agencyRegistrationId: Int
    $organisationRegistrationId: Int
    $activeTimesheet: Boolean = false
    $amendedTimesheet: Boolean = false
  ) {
    shiftBlocks(
      gradeIds: $gradeIds
      departmentIds: $departmentIds
      startDateTo: $startDateTo
      startDateFrom: $startDateFrom
      endDateFrom: $endDateFrom
      endDateTo: $endDateTo
      statuses: $statuses
      shiftBlockIds: $shiftBlockIds
      siteIds: $siteIds
      first: $first
      last: $last
      after: $after
      before: $before
      agencyRegistrationId: $agencyRegistrationId
      organisationRegistrationId: $organisationRegistrationId
      activeTimesheet: $activeTimesheet
      amendedTimesheet: $amendedTimesheet
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
      nodes {
        # cascade triggers

        autoCascadeTriggers {
          id
          createdAt
          updatedAt
          fromTier
          toTier
          trashedAt
          triggerType
          triggerableType
          triggerableId
          triggeredAt
          status
        }

        # other
        activeTimesheets @include(if: $activeTimesheet)
        amendedTimesheets @include(if: $amendedTimesheet)
        endDate
        createdAt
        id
        uid
        name
        status
        shiftCount
        startDate
        deContractVersionAssignment {
          id
          signedAt
          signature
          contractFileUrl
        }
        departments {
          name
          id
        }
        sentToAgency
        # provisionallyBooked
        hubUser {
          fullName
        }
        agencyTier {
          rank
        }

        bookedBy {
          ... on OrganisationRegistration {
            user {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
          ... on AgencyWorker {
            id
            firstName
            lastName
          }
          ... on AgencyRegistration {
            agency {
              title
            }
            user {
              id
              firstName
              lastName
              email
              phoneNumber
              regBodyNumber
            }
          }
          ... on Worker {
            id
            firstName
            lastName
          }
        }

        agencyWorkerBlockProposals {
          id
          status
          shortlistedAt
        }

        shiftBlockApplications {
          nodes {
            id
            status

            applicant {
              ... on OrganisationRegistration {
                user {
                  firstName
                  lastName
                  email
                  filterGrade {
                    title
                  }
                  profilePictureUrl
                }
              }
            }
          }
        }

        organisationStaffGroup {
          vms
        }

        staffGroup {
          label: title
          value: id
          idValue
        }
        grade {
          id
          label: title
          colour
          useDefaultBreaks
        }
      }
    }
  }
`

// Single BLock *****************
export const SHIFT_BLOCK = gql`
  query ShiftBlock($shiftBlockIds: [Int!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds) {
      nodes {
        endDate
        id
        name
        status
        startDate
        modApprovalBudgetValue
        agencyLocked
        shiftIds
        sentToAgency
        simpleSendBlockToAgency
        provisionallyBooked
        organisationStaffGroup {
          vms
        }

        grade {
          label: title
          value: id
          colour
          breakRepeatMinutes
          useDefaultBreaks
        }
        staffGroup {
          label: title
          value: id
        }

        agencyWorkerBlockProposals {
          id
          status
          shortlistedAt
        }

        shifts {
          id
          proposedEndTime
          proposedStartTime
          breaks
          status

          costCentre
          adminNote
          note
          totalHours

          site {
            label: name
            value: id
          }

          department {
            label: name
            value: id
          }
        }
      }
    }
  }
`

// Single ShiftBlock Details *********************
export const SHIFT_BLOCK_DETAILS = gql`
  query shiftBlockDetails($shiftBlockIds: [Int!], $statuses: [ShiftBlockStatusEnum!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds, statuses: $statuses) {
      nodes {
        adminNotes
        agencyLocked
        bookingPreference
        endDate
        id

        modAccommodationExpensesBudget
        modApprovalBudgetValue
        modApprovedWeeklyHours
        modFinancialApprovalCode
        modOutOfHoursBudget
        modOutOfHoursEstimatedWeeklyHours
        modPositionNumber
        modSecurityClearance
        modSubsistenceExpensesBudget
        modTravelExpensesBudget
        name
        shiftCount
        skills
        startDate
        status
        totalHours
        totalRate
        workerNotes

        departments {
          id
        }

        reasonForBooking {
          value: id
          label: reason
        }
        staffGroup {
          value: id
          label: title
        }

        organisationStaffGroup {
          vms
        }

        site
        grade {
          value: id
          label: title
          breakRepeatMinutes
          useDefaultBreaks
          organisationStaffGroup {
            agencyTiers {
              value: id
              label: rank
            }
          }
        }

        activeTimesheets
        agencyWorkerRates {
          id
          name
          agencyMargin
          comparatorRate
          workerRate
          niContribution

          initialRates {
            comparatorRate
          }
        }

        agencyWorkerBlockProposals {
          id
          status
          shortlistedAt
          poNumber
          requisitionNumber
          agencyUser {
            id
            firstName
            lastName
          }

          rates {
            id
            name
            agencyMargin
            comparatorRate
            ltdUmbrellaRate
            workerRate
            workerBaseRate
            workerPayeRate
            niContribution
            initialRates {
              agencyMargin
              comparatorRate
              ltdUmbrellaRate
              workerBaseRate
              workerPayeRate
              workerRate
            }
            agencyMarginBreakdown
          }

          agencyWorker {
            id
            isSoleTrader
            isVatRegistered
            firstName
            email
            cvUrl
            compliancePackUrl
            niNumber
            regBodyNumber
            staffGroup {
              idValue
              title
            }
            grade {
              colour
              title
            }
            agency {
              title
            }
            lastName
            email
            employmentType
            paymentType
            user {
              profilePictureUrl
              firstName
              lastName
              email
            }
          }
        }

        bookedBy {
          ... on OrganisationRegistration {
            user {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }

          ... on AgencyRegistration {
            employmentType
            paymentType
            compliancePackUrl
            cvUrl
            grade {
              title
              colour
            }
            user {
              id
              firstName
              lastName
              email
              phoneNumber
            }
          }
        }

        shiftBlockApplications {
          nodes {
            id
            status
            applicant {
              ... on OrganisationRegistration {
                id
                user {
                  firstName
                  lastName
                  email
                  id

                  filterGrade {
                    id
                    title
                  }
                  profilePictureUrl
                }
              }
            }
          }
        }
      }
    }
  }
`

export const SHIFT_BLOCK_TIERS = gql`
  query shiftBlockTiers($shiftBlockIds: [Int!], $statuses: [ShiftBlockStatusEnum!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds, statuses: $statuses) {
      nodes {
        id
        grade {
          organisationStaffGroup {
            agencyTiers {
              value: id
              label: rank
            }
          }
        }
      }
    }
  }
`

export const SHIFT_BLOCK_REASONS = gql`
  query shiftBlockReasons($shiftBlockIds: [Int!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds) {
      nodes {
        reasonsForDeletion {
          value: id
          label: reason
        }
      }
    }
  }
`

export const SHIFT_BLOCK_AUDIT = gql`
  query shiftBlockAuditEvents($shiftBlockIds: [Int!]) {
    shiftBlocks(shiftBlockIds: $shiftBlockIds) {
      nodes {
        shiftBlockAuditEvents {
          nodes {
            createdAt
            event
            changesData
            currentUser {
              firstName
              lastName
              userType
            }
          }
          totalCount
        }
      }
    }
  }
`

// Create ******************************************

export const SHIFT_BLOCK_CREATE = gql`
  mutation shiftBlockCreate(
    $name: String!
    $gradeId: Int!
    $staffGroupId: Int!
    $organisationId: Int!
    $shiftIds: [Int!]
    $reasonForBookingId: Int
    $adminNotes: String
    $workerNotes: String
    $bookingPreference: Boolean
    $startDate: DateTime
    $endDate: DateTime
    $modAccommodationExpensesBudget: Float
    $modApprovalBudgetValue: Float
    $modFinancialApprovalCode: String
    $modOutOfHoursBudget: Float
    $modOutOfHoursEstimatedWeeklyHours: Int
    $modPositionNumber: Int
    $modSecurityClearance: ModSecurityClearanceEnum
    $modSubsistenceExpensesBudget: Float
    $modTravelExpensesBudget: Float
  ) {
    shiftBlockCreate(
      shiftBlockAttributes: {
        name: $name
        gradeId: $gradeId
        staffGroupId: $staffGroupId
        organisationId: $organisationId
        shiftIds: $shiftIds
        reasonForBookingId: $reasonForBookingId
        adminNotes: $adminNotes
        workerNotes: $workerNotes
        bookingPreference: $bookingPreference
        startDate: $startDate
        endDate: $endDate
        modAccommodationExpensesBudget: $modAccommodationExpensesBudget
        modApprovalBudgetValue: $modApprovalBudgetValue
        modFinancialApprovalCode: $modFinancialApprovalCode
        modOutOfHoursBudget: $modOutOfHoursBudget
        modOutOfHoursEstimatedWeeklyHours: $modOutOfHoursEstimatedWeeklyHours
        modPositionNumber: $modPositionNumber
        modSecurityClearance: $modSecurityClearance
        modSubsistenceExpensesBudget: $modSubsistenceExpensesBudget
        modTravelExpensesBudget: $modTravelExpensesBudget
      }
    ) {
      errors {
        attribute
        message
      }
      shiftBlock {
        id
      }
    }
  }
`

export const SHIFT_BLOCK_UPDATE = gql`
  mutation shiftBlockUpdate($shiftBlockId: Int!, $shiftBlockAttributes: ShiftBlockUpdateInput!) {
    shiftBlockUpdate(shiftBlockId: $shiftBlockId, shiftBlockAttributes: $shiftBlockAttributes) {
      errors {
        attribute
        message
      }
    }
  }
`

export const SHIFT_BLOCK_PUBLISH = gql`
  mutation shiftBlockPublish($shiftBlockId: Int!) {
    shiftBlockPublish(shiftBlockId: $shiftBlockId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const SHIFT_BLOCK_DELETE = gql`
  mutation shiftBlockDelete($shiftBlockId: Int!, $reasonForDeletionId: Int) {
    shiftBlockDelete(shiftBlockId: $shiftBlockId, reasonForDeletionId: $reasonForDeletionId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const SHIFT_BLOCK_APPROVE_APPLICATION = gql`
  mutation shiftBlockApproveApplication($shiftBlockApplicationId: Int!) {
    shiftBlockApproveApplication(shiftBlockApplicationId: $shiftBlockApplicationId) {
      errors {
        attribute
        message
      }
    }
  }
`

export const SHIFT_BLOCK_REJECT_APPLICATION = gql`
  mutation shiftBlockRejectApplication($shiftBlockApplicationId: Int!) {
    shiftBlockRejectApplication(shiftBlockApplicationId: $shiftBlockApplicationId) {
      errors {
        attribute
        message
      }
    }
  }
`

// SHIFTS  ******************************

// 1. Create Shifts for Draft & Published Blocks

export const CREATE_SHIFTS = gql`
  mutation bulkCreateOnBlock($shiftsAttributes: ShiftCreateInput!) {
    bulkCreateOnBlock(shiftsAttributes: $shiftsAttributes) {
      errors {
        attribute
        message
      }
      shifts {
        id
        note
        breaks
        adminNote
        proposedEndTime
        proposedStartTime
        costCentre
        totalHours
        department {
          label: name
          value: id
        }
        site {
          label: name
          value: id
        }
      }
    }
  }
`

export const UPDATE_DRAFT_SHIFT = gql`
  mutation updateDraftShift($draftShiftAttributes: DraftShiftUpdateInput!) {
    updateDraftShift(draftShiftAttributes: $draftShiftAttributes) {
      errors {
        attribute
        message
      }
      draftShift {
        id
        breaks
        proposedEndTime
        proposedStartTime
        totalHours
        costCentre
        adminNote
        note
        site {
          label: name
          value: id
        }
        department {
          label: name
          value: id
        }
      }
    }
  }
`

export const SHIFT_BULT_DELETE = gql`
  mutation shiftBulkDelete($shiftIds: [Int!]!) {
    shiftBulkDelete(shiftIds: $shiftIds) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

// Applications ****************************

export const SHIFT_BLOCK_CANCEL_WORKER_APPLICATION = gql`
  mutation shiftBlockCancelWorkerApplication($shiftBlockId: Int!, $reasonForCancellationId: Int) {
    shiftBlockCancelWorkerApplication(shiftBlockId: $shiftBlockId, reasonForCancellationId: $reasonForCancellationId) {
      errors {
        attribute
        message
      }
    }
  }
`

export const REASONS_FOR_CANCELLATION = gql`
  query ReasonsForCancellation($organisationId: Int!) {
    reasonsForCancellation(organisationId: $organisationId) {
      value: id
      label: reason
    }
  }
`

// Fetch all Cost Centres
export const COST_CENTRES = gql`
  query costCentres {
    costCentres {
      nodes {
        value: id
        label: code
      }
    }
  }
`

// Fetch Cost Centres associated to Department

export const DEPARTMENT = gql`
  query department($id: Int!) {
    department(id: $id) {
      id
      name
      associatedCostCentres {
        value: id
        label: code
      }
      costCentre {
        value: id
        label: code
      }
    }
  }
`

// Fetch Departments/Units associated to Site

export const DEPARTMENTS = gql`
  query departments($siteId: Int) {
    departments(siteId: $siteId) {
      value: id
      label: name
    }
  }
`

export const SHIFT_DETAILS = gql`
  query shiftDetails($id: Int!) {
    sites {
      value: id
      label: name
    }
    reasons {
      nodes {
        value: id
        label: reason
        type
      }
    }

    staffGroups(extended: true) {
      value: idValue
      label: title
    }
    organisation(id: $id) {
      departments {
        value: id
        label: name
      }

      grades {
        value: id
        label: title
      }
      organisationStaffGroups {
        value: id
        label: title
      }
    }
  }
`

// ASSIGN WORKER ***************

export const SHIFT_BLOCK_ASSIGN_ORGANISATION_REGISTRATION = gql`
  mutation shiftBlockAssignOrganisationRegistration($shiftBlockId: Int!, $organisationRegistrationId: Int!) {
    shiftBlockAssignOrganisationRegistration(
      shiftBlockId: $shiftBlockId
      organisationRegistrationId: $organisationRegistrationId
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

export const WORKERS = gql`
  query shiftBlockWorkers($workerName: String) {
    shiftBlockWorkers(workerName: $workerName) {
      id
      fullName
      organisationRegistration {
        id
        grade {
          title
        }
        organisation {
          id
          name
        }
      }
      email
    }
  }
`

export const WORKERS_NEW = gql`
  query workersNew($gradeId: Int, $staffGroupId: Int) {
    workers(gradeId: $gradeId, staffGroupId: $staffGroupId) {
      grade {
        id
        title
      }
      email
      organisationRegistration {
        id
      }

      profilePictureUrl
      id
      staffGroup {
        title
        idValue
      }
      fullName
      firstName
      lastName
    }
  }
`

// AGENCY LOCKED *********************

export const BLOCK_LOCK_TO_AGENCY = gql`
  mutation bulkLockToAgency($agencyLocked: Boolean!, $shiftIds: [Int!]!) {
    bulkLockToAgency(shiftIds: $shiftIds, agencyLocked: $agencyLocked) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

// SEND BLOCK TO AGENCY (complex) *********************

export const SHIFT_BLOCK_SEND_BLOCK_TO_AGENCY_TIER = gql`
  mutation shiftBlockSendBlockToAgencyTier($shiftBlockId: Int!, $agencyTierId: Int!) {
    shiftBlockSendBlockToAgencyTier(agencyTierId: $agencyTierId, shiftBlockId: $shiftBlockId) {
      errors {
        attribute
        message
      }
    }
  }
`

export const SHIFT_BLOCK_UPDATE_AGENCY_TIER_ON_BLOCK = gql`
  mutation shiftBlockUpdateAgencyTierOnBlock($agencyTierId: Int!, $shiftBlockId: Int!) {
    shiftBlockUpdateAgencyTierOnBlock(agencyTierId: $agencyTierId, shiftBlockId: $shiftBlockId) {
      errors {
        attribute
        message
      }
    }
  }
`

export const SHIFT_BLOCK_RECALL_FROM_AGENCY = gql`
  mutation shiftBlockRecallFromAgency($shiftBlockId: Int!) {
    shiftBlockRecallFromAgency(shiftBlockId: $shiftBlockId) {
      errors {
        attribute
        message
      }
    }
  }
`

export const VMS_SHIFT_BLOCK_PROPOSAL_REJECT = gql`
  mutation vmsShiftBlockProposalReject($agencyWorkerBlockProposalId: Int!, $reason: String) {
    vmsShiftBlockProposalReject(agencyWorkerBlockProposalId: $agencyWorkerBlockProposalId, reason: $reason) {
      errors {
        attribute
        message
      }
    }
  }
`

export const VMS_SHIFT_BLOCK_PROPOSAL_ACCEPT = gql`
  mutation vmsShiftBlockProposalAccept($agencyWorkerBlockProposalId: Int!, $deContractVersionId: Int) {
    vmsShiftBlockProposalAccept(
      agencyWorkerBlockProposalId: $agencyWorkerBlockProposalId
      deContractVersionId: $deContractVersionId
    ) {
      errors {
        attribute
        message
      }
    }
  }
`

// Cancel Booking **************************
export const VMS_SHIFT_BLOCK_CANCEL_AGENCY_BOOKING = gql`
  mutation vmsShiftBlockCancelAgencyBooking($cancelAgencyBookingAttributes: CancelAgencyBookingAttributes!) {
    vmsShiftBlockCancelAgencyBooking(cancelAgencyBookingAttributes: $cancelAgencyBookingAttributes) {
      errors {
        attribute
        message
      }
    }
  }
`

// TimeSheets **************************************

export const SHIFT_BLOCK_TIMESHEETS = gql`
  query shiftBlockTimesheets($afterDate: Date, $beforeDate: Date, $historic: Boolean, $id: Int, $shiftBlockId: Int!) {
    shiftBlockTimesheets(
      afterDate: $afterDate
      beforeDate: $beforeDate
      historic: $historic
      id: $id
      shiftBlockId: $shiftBlockId
    ) {
      id
      shiftBlockId
      status
      startsAt
      endsAt
      hoursExpected
      hoursWorked
      hoursDifference
    }
  }
`

export const SHIFT_BLOCK_TIMESHEET = gql`
  query shiftBlockTimesheets($afterDate: Date, $beforeDate: Date, $historic: Boolean, $id: Int, $shiftBlockId: Int!) {
    shiftBlockTimesheets(
      afterDate: $afterDate
      beforeDate: $beforeDate
      historic: $historic
      id: $id
      shiftBlockId: $shiftBlockId
    ) {
      id
      status
      shifts {
        id

        breakMinutes
        totalHours
        startTime
        endTime
        status
        confirmedBy
        confirmedAt
        paymentApproverNotes
        bulkPaymentApproveUuid
        #
        shouldEnforceBreaks
        gradeBreakMinutes
        gradeBreakRepeatMinutes
        #
        breaksExceptionNotes
        minimumBreakDuration
        #
        workerSignOff {
          id
          reportedStartTime
          reportedEndTime
          reportedBreaks
          reportedNotes
          confirmationNotes
          status
          originalStartTime: initialStartTime
          originalBreaks: initialBreaks
          originalEndTime: initialEndTime
          approvedBy
          approvedDate
        }
      }
    }
  }
`

export const BULK_SIGNOFF = gql`
  mutation bulkSignOff($shiftIds: [Int!]!, $signOffAuthoriserId: Int!, $forTimesheet: Boolean) {
    bulkSignOff(shiftIds: $shiftIds, signOffAuthoriserId: $signOffAuthoriserId, forTimesheet: $forTimesheet) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const SHIFT_BULK_APPROVE = gql`
  mutation shiftBulkApprove($shiftIds: [Int!]!, $forTimesheet: Boolean, $paymentApproverNotes: String) {
    shiftBulkApprove(shiftIds: $shiftIds, forTimesheet: $forTimesheet, paymentApproverNotes: $paymentApproverNotes) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

/* NEW QUERY - Will allow us to sign off multiple amended shifts at once */

export const HUB_USER_SIGN_OFF_WITH_TIMES_AMENDED_MULTIPLE = gql`
  mutation hubUserSignOffWithTimesAmendedMultiple($shifts: [InputMultipleAmendedShift!]!) {
    hubUserSignOffWithTimesAmendedMultiple(shifts: $shifts) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const AUTHORISERS_QUERY = gql`
  query authorisersQuery($id: Int!) {
    department(id: $id) {
      timesheetAuthorisers {
        value: id
        label: name
      }
    }
  }
`

export const SHORTLIST_MUTATION = gql`
  mutation UpdateProposalShortlistingStatus($id: ID!, $className: String!) {
    updateProposalShortlistingStatus(id: $id, className: $className) {
      errors {
        attribute
        message
      }
      shortlistedAt
      success
    }
  }
`

// HELPERS *********************

export const GRADES = gql`
  query grades($staffGroupId: Int!, $organisationId: Int!, $orderBy: String!) {
    grades(staffGroupId: $staffGroupId, organisationId: $organisationId, orderBy: $orderBy) {
      value: id
      label: title
    }
  }
`

// DE contracts ******************************

export const DE_CONTRACTS_CONTRACTS = gql`
  query deContractsContracts($status: Int, $paymentType: Int, $staffGroupId: Int) {
    deContractsContracts(status: $status, paymentType: $paymentType, staffGroupId: $staffGroupId) {
      list {
        id
        name
        content
        contractType
        activeVersion {
          id
        }
      }
    }
  }
`

export const securityClearance = [
  {
    label: 'Baseline Personnel Security Standard (BPSS)',
    value: 'BASELINE_PERSONNEL_SECURITY_STANDARD',
  },

  {
    label: 'Counter Terrorism Check (CTC)',
    value: 'COUNTER_TERRORISM_CHECK',
  },
  {
    label: 'Security Check (SC)',
    value: 'SECURITY_CHECK',
  },
  {
    label: 'Enhanced Security Check (eSC)',
    value: 'ENHANCED_SECURITY_CHECK',
  },
  {
    label: 'Developed Vetting (DV)',
    value: 'DEVELOPED_VETTING',
  },

  {
    label: 'Enhanced Developed Vetting (eDV)',
    value: 'ENHANCED_DEVELOPED_VETTING',
  },
]

export const statuses = [
  {
    label: 'Available',
    value: 'AVAILABLE',
  },
  {
    label: 'Proposals',
    value: 'PROPOSALS',
  },
  {
    label: 'Booked',
    value: 'BOOKED',
  },

  {
    label: 'Cancelled',
    value: 'CANCELLED',
  },

  {
    label: 'Draft',
    value: 'DRAFT',
  },

  {
    label: 'Deleted',
    value: 'DELETED',
  },
]

export const paginationOptions = [
  {
    label: 25,
    value: 25,
  },
  {
    label: 50,
    value: 50,
  },
  {
    label: 75,
    value: 75,
  },
  {
    label: 100,
    value: 100,
  },
]

export const infoToast = {
  render: 'Some Info',
  type: 'info',
  position: 'top-right',
  autoClose: false,
  isLoading: false,
  hideProgressBar: true,
  closeOnClick: true,
  theme: 'colored',
}

export const successToast = {
  render: 'Success',
  type: 'success',
  isLoading: false,
  autoClose: 3000,
  position: 'top-right',
}
export const errorToast = {
  render: 'An error occurred',
  type: 'error',
  isLoading: false,
  autoClose: 3000,
  position: 'top-right',
}

export const loadingToast = {
  position: 'top-right',
  toastId: 2,
}

export const checkBookedWorker = (row) => {
  const filty = row.shiftBlockApplications.nodes.filter((cc) => cc.status === 'APPROVED')
  return filty.length > 0 && `${filty[0].applicant.user.firstName} ${filty[0].applicant.user.lastName}`
}

export const checkStatus = (row) => {
  let total = 0

  row.shiftBlockApplications?.nodes?.forEach((element) => {
    if (element.status !== 'REJECTED' && element.status !== 'REVOKED') {
      total += 1
    }
  })

  if (row.status === 'DELETED') return 'Deleted'
  if (row.status === 'Draft') return 'Draft'
  if (total > 0) return total

  return 'AVAILABLE'
}

export const checkProposals = (row) => {
  let proposals = 0
  row.agencyWorkerBlockProposals?.forEach((element) => {
    if (element.status === 'proposed') {
      proposals += 1
    }
  })
  return proposals
}

export const formatToIds = (arrayOrObject) => {
  if (!arrayOrObject) return null
  if (Array.isArray(arrayOrObject)) {
    return arrayOrObject.map((e) => +e.value || +e.id || +e.idValue)
  }
  return arrayOrObject.value || arrayOrObject.id || arrayOrObject.idValue
}

export const footerStyling = {
  position: 'absolute',
  left: 0,
  right: 0,
}

// Shift Statuses WebComponents ******************

export function flexCenter() {
  return `
      display: flex;
      justify-content: center;
      align-items: center;
  `
}

export function ShiftStatus({ type, onClick, number }) {
  let status = type

  if (number && number !== 1) status += 'S'
  if (type.match(/TO_SIGN_OFF/)) status = 'TO SIGN OFF'
  if (type === 'SIGN_OFF_REQUESTED') status = 'SIGN OFF REQUESTED'
  if (type === 'SIGNED_OFF') status = 'SIGNED OFF'
  if (type === 'IN_PROGRESS') status = 'IN PROGRESS'

  return (
    <ShiftStyles number={number} onClick={onClick} type={type}>
      {number && <span>{number}</span>} {status}
    </ShiftStyles>
  )
}

const ShiftStyles = styled.div`
  ${flexCenter()};

  align-items: center;
  background: ${({ type }) =>
    (type === 'URGENT' && RED.one) ||
    (type === 'APPLIED' && YELLOW.one) ||
    (type === 'BOOKED' && GREEN.two) ||
    (type === 'TO_SIGN_OFF_ON_HUB_OR_APP' && GREEN.two) ||
    (type === 'IN_PROGRESS' && GREEN.five) ||
    (type === 'DRAFT' && '#C4C4C4') ||
    (type === 'SIGNED_OFF' && GREY.four) ||
    (type === 'SIGN_OFF_REQUESTED' && GREY.two) ||
    (type === 'APPROVED' && '#ffedb7') ||
    (type === 'PAYROLL' && GREY.nine) ||
    (type === 'DELETED' && RED.three) ||
    (type.match(/TO_SIGN_OFF/) && BLUE.one) ||
    ((type === 'PROPOSAL' || type === 'APPLICATION') && GLOBAL.white) ||
    (type === 'AVAILABLE' && ORANGE.one)};

  border: ${({ number }) => number && `1px solid ${RED.five}`};
  border-radius: 4px;
  color: ${({ type }) =>
    (type === 'URGENT' && RED.five) ||
    (type === 'APPLIED' && YELLOW.six) ||
    (type === 'BOOKED' && GREEN.five) ||
    (type === 'TO_SIGN_OFF_ON_HUB_OR_APP' && GREEN.five) ||
    (type === 'IN_PROGRESS' && GLOBAL.white) ||
    (type === 'AVAILABLE' && ORANGE.five) ||
    (type === 'DELETED' && RED.six) ||
    (type === 'APPROVED' && '#a48428') ||
    type === 'SIGN_OFF_REQUESTED' ||
    ((type === 'PROPOSAL' || type === 'APPLICATION') && GREY.ten) ||
    (type.match(/TO_SIGN_OFF/) && BLUE.seven) ||
    GLOBAL.white};
  display: inline-flex;
  font-size: 11px;
  font-weight: 600;
  height: 21px;
  margin-left: 10px;
  padding: 0px 12px;
  text-transform: uppercase;

  span {
    ${flexCenter()};
    background: ${RED.five};
    border-radius: 50%;
    color: ${GLOBAL.white};
    font-size: 10px;
    font-weight: 600 !important;
    height: 12px;
    margin-right: 3px;
    width: 12px;
  }
`

export const shiftStatus = {
  URGENT: 0,
  AVAILABLE: 1,
  APPLIED: 2,
  BOOKED: 3,
  PROVISIONALLY_BOOKED: 4,
  TO_SIGN_OFF_ON_HUB_OR_APP: 5,
  TO_SIGN_OFF_ON_HUB: 5,
  SIGN_OFF_REQUESTED: 6,
  SIGNED_OFF: 7,
  APPROVED: 8,
  PAYROLL: 9,
}

const replaceUnderscoreWithSpace = (string) => string.replace(/_/g, ' ')

const upperCaseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1)

export function formatError(errors) {
  const { attribute, message } = errors[0]

  if (!attribute) return upperCaseFirstLetter(message)

  return `${replaceUnderscoreWithSpace(upperCaseFirstLetter(attribute))} ${message}`
}

export const LeaveStatus = styled.div.attrs((props) => ({
  status: props.status,
}))`
  align-items: center;
  background: ${({ status }) =>
    status === 'approved'
      ? '#FFEDB7'
      : status === 'AVAILABLE'
      ? '#FFC8B2'
      : status === 'DRAFT'
      ? '#C4C4C4'
      : status === 'BOOKED' ||
        status === 'PROVISIONALLY_BOOKED' ||
        status === 'TO_SIGN_OFF_ON_HUB' ||
        status === 'TO_SIGN_OFF_ON_HUB_OR_APP'
      ? '#D1F5E3'
      : status === 'SIGN_OFF_REQUESTED'
      ? '#D1F2F7'
      : status === 'SIGNED_OFF'
      ? '#cbd2d9'
      : status === 'DELETED'
      ? '#FAB1B2'
      : status === 'APPROVED'
      ? '#ffedb7'
      : status === 'PROVISIONAL'
      ? '#FFD24C'
      : status === 'IN_PROGRESS'
      ? '#316959'
      : status === 'PAID'
      ? '#3e4d58'
      : status === 'PAYROLL'
      ? '#3e4d58'
      : status.match(/APPLICATION/)
      ? 'white'
      : '#F9D0D5'};
  border: ${(props) => props.status.match(/APPLICATION/) && '1px solid #DC5A54'};
  border-radius: 6px;
  color: ${({ status }) =>
    status === 'approved'
      ? '#316959'
      : status === 'DRAFT'
      ? '#fff'
      : status === 'AVAILABLE'
      ? '#C54714'
      : status === 'BOOKED' ||
        status === 'PROVISIONALLY_BOOKED' ||
        status === 'TO_SIGN_OFF_ON_HUB' ||
        status === 'TO_SIGN_OFF_ON_HUB_OR_APP'
      ? '#316959'
      : status === 'SIGNED_OFF'
      ? '#1e2d36'
      : status === 'SIGN_OFF_REQUESTED'
      ? '#007AC3'
      : status === 'APPROVED'
      ? '#a48428'
      : status === 'PAID' || status === 'PAYROLL'
      ? 'white'
      : status === 'IN_PROGRESS'
      ? 'white'
      : status === 'DELETED'
      ? '#9E3636'
      : '#002033'};
  cursor: ${(props) => (props.status.match(/APPLICATION/) ? 'pointer' : 'initial')};
  padding: 0px 12px;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 21px;
  justify-content: center;
  right: 50px;
  text-transform: uppercase;
  span {
    align-items: center;
    background: #dc5a54;
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: 9px;
    font-weight: 600 !important;
    height: 14px;
    justify-content: center;
    margin-right: 3px;
    width: 14px;
  }
`

export const ShiftModalTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`

export const CancelWorker = styled.div`
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 5px;
  box-shadow: 0 0 0.2rem rgb(79 81 82 / 25%);
  height: auto;
  padding: 16px;
  width: 300px;

  a {
    text-decoration: underline;
  }

  p {
    font-size: 11px;
  }
`

// Button from Web-Components, local for webpack memory fix

export const StyledCloseButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
`

export function CloseButton(props) {
  return (
    <StyledCloseButton {...props}>
      <svg fill="none" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path d="M18 6L6 18M6 6l12 12" stroke="#7D8793" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </svg>
    </StyledCloseButton>
  )
}

export function DefaultButton({ action, color, fontSize, icon, text, type, width, children, ...props }) {
  const Icon = icon

  return (
    <ButtonStyled
      color={color}
      fontSize={fontSize}
      icon={icon}
      onClick={action ? () => action() : undefined}
      type={type}
      width={width}
      {...props}
    >
      {icon && <Icon aria-hidden="true" style={{ marginRight: 8 }} />}
      <ButtonSpan icon={icon} type={type}>
        {text}
      </ButtonSpan>
      {children}
    </ButtonStyled>
  )
}

export const ButtonStyled = styled.button.attrs((props) => ({
  color: props.color,
  fontSize: props.fontSize,
  icon: props.icon,
  type: props.type,
  width: props.width,
}))`
  align-items: center;
  background: #05405c;
  border: 1px solid 'transparent';
  border-radius: 8px;
  box-shadow: ${(props) => (props.type === 'grey' ? 'none' : '0px 1px 2px rgba(0, 0, 0, 0.1)')};
  color: #fff;
  display: inline-flex;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '13px')};
  font-weight: 500;
  height: 42px;
  justify-content: center;
  line-height: 1;
  padding: ${(props) => (props.icon ? '10px 16px' : '12px 16px')};
  width: ${(props) => (props.width === 'full' ? '100%' : 'auto')};
  &:hover {
    background: '#042c3f';
  }
`

const ButtonSpan = styled.span.attrs((props) => ({
  icon: props.icon,
  type: props.type,
}))`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: #fff;
  display: inline-flex;
  position: relative;
  text-transform: capitalize;
  top: ${(props) => (props.icon ? '1px' : '0')};
`
