/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
/* eslint-disable react/jsx-props-no-spreading */

import { useQuery } from '@apollo/client'
import queryString from 'query-string'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import Pagy from '../../Helpers/Pagy'
import ItemisedReceiptsActionBar from './ActionBar/ActionBar'
import SectionAuthHeader from './helpers/ItemisedReceiptsHeader'
import ReceiptFilters from './helpers/ReceiptFilters'
import { PAID_PER_ITEM_RECEIPTS } from './helpers/ReceiptsHelpers'
import ViewReceiptSignOffModal from './Modals/ItemisedReceiptModal'
import PayPerItemReceipts from './PayPerItemReceipts'

function ItemisedReceipts({ user, ...rest }) {
  const [currentModalId, setCurrentModalId] = useState(null)
  const onCloseModal = () => setCurrentModalId(null)

  // const permissions = user?.userPermission
  const receiptIdsFromUrlQuery = queryString.parse(window.location.search)?.ids || ''
  const parsedReceiptIds =
    receiptIdsFromUrlQuery.length > 0
      ? receiptIdsFromUrlQuery
          .replace(/\[|\]/g, '')
          .split(',')
          .map((id) => Number(id))
      : []

  const [page, setPage] = useState(1)
  const [inputs, setInputs] = useState({
    showFilters: false,
    showClearFilters: false,
    departmentIds: [],
    gradeIds: [],
    itemIds: [],
    items: 25,
    status: [],
    workerIds: [],
    ids: parsedReceiptIds,
  })
  const [selectedRows, setSelectedRows] = useState([])

  const { showFilters, showClearFilters, ...dynamicFilterFields } = inputs
  const filterEntries = Object.entries(dynamicFilterFields).filter((keyVal) => keyVal[1].length > 0)
  const filterArgs = Object.fromEntries(new Map(filterEntries))

  const { data, loading, refetch } = useQuery(PAID_PER_ITEM_RECEIPTS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    variables: {
      page,
      ...filterArgs,
    },
    onError: (error) => {
      toast.error(error.message, { hideProgressBar: true, position: 'top-right' })
    },
  })

  const pagination = data?.paidPerItemReceipts.pagination

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      if (e.target.value === '') {
        // setInputs({ ...inputs, ids: [] })
        window.location.search = ''
      } else {
        // setInputs({ ...inputs, ids: [Number(e.target.value)] })
        window.location.search = `?ids=[${e.target.value}]`
      }
    } else if (e.key === 'Backspace' && e.target.value.length === 1 && window.location.search !== '') {
      window.location.search = ''
    }
  }

  return (
    <>
      <ItemisedReceiptsWrapper>
        <SectionHeader open={inputs.showFilters}>
          <SectionAuthHeader count={pagination?.count} handleSearch={handleSearch} user={user} />
          <FilterContainer open={inputs.showFilters}>
            <ReceiptFilters
              handleFilter={() => null}
              inputs={inputs}
              setInputs={setInputs}
              {...rest}
              resetPagination={() => setPage(1)}
            />
          </FilterContainer>
        </SectionHeader>
        <PayPerItemReceipts
          inputs={inputs}
          loading={loading}
          receipts={data?.paidPerItemReceipts?.list}
          resetFilters={() => setInputs({ ...inputs, showFilters: false })}
          setCurrentModalId={setCurrentModalId}
          toggleFilters={() => setInputs({ ...inputs, showFilters: !inputs.showFilters })}
          {...rest}
          parsedReceiptIds={parsedReceiptIds}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        {data?.paidPerItemReceipts?.list.length ? <Pagy pagination={pagination} setPage={setPage} /> : null}
        <ItemisedReceiptsActionBar
          refetchReceipts={refetch}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </ItemisedReceiptsWrapper>
      <ViewReceiptSignOffModal onCloseModal={onCloseModal} receiptId={currentModalId} refetchReceipts={refetch} />
    </>
  )
}

export default ItemisedReceipts

const ItemisedReceiptsWrapper = styled.section`
  min-height: 100vh;
  padding-bottom: 40px;
  background-color: #fff;
`

const SectionHeader = styled.div`
  background: #002033;
  height: ${(props) => (props.open ? '360px' : '280px')};
  margin: 0;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 1.5rem;
  transition: 250ms ease-in-out;
  @media (max-width: 1740px) {
    height: ${(props) => (props.open ? '450px' : '280px')};
  }
`

const FilterContainer = styled.div`
  max-height: ${({ open }) => (open ? '200px' : 0)};
  opacity: ${({ open }) => (open ? '1' : '0')};
  display: ${({ open }) => !open && 'none'};
  transition: 250ms ease-in-out;
  * {
    pointer-events: ${({ open }) => (open ? 'initial' : 'none')};
  }
`
