import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../packs/application";
import DepartmentPermissionContext from "./context";
import Departments from "./Departments";


function DepartmentsIndex({ permission, organisation, sites }) {
  const locked = permission["super_admin?"] ? false : true;
  return (
    <ApolloProvider client={client}>
      <DepartmentPermissionContext.Provider value={permission}>
        <Departments locked={locked} organisation={organisation} sites={sites} />
      </DepartmentPermissionContext.Provider>
    </ApolloProvider>
  );
}

export default DepartmentsIndex;
