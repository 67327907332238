import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import queryString from 'query-string'

class DateInputController extends Flatpickr {
    connect() {
        const defaultDates = []
        if(this.data.get('from_date')) {
            let shortFromDate = this.shortDateFromIsoString(this.data.get('from_date'));
            defaultDates.push(shortFromDate)
        }

        if(this.data.get('to_date')) {
            let shortToDate = this.shortDateFromIsoString(this.data.get('to_date'));
            defaultDates.push(shortToDate)
        }

        if (this.data.get('default_date')) {
            let shortDefaultDate = this.shortDateFromIsoString(this.data.get('default_date'));
            defaultDates.push(shortDefaultDate)
        }

        this.config = {
            ...this.config, //spread options in case some where defined in initialize
            dateFormat: "d/m/Y",
            defaultDate: defaultDates,
            // allowInput: true,
            altInput: true
        };

        super.connect();
    }

    open(selectedDates, dateStr, instance) {
        this.previousSelectedDates = instance.selectedDates
        // instance.selectedDates = []
        console.log(0, selectedDates)
    }
    // all flatpickr hooks are available as callbacks in your Stimulus controller
    close(selectedDates, dateStr, instance) {
        const searchOnSelect = this.data.get('search_on_select');
        const datePickerVal = document.querySelector('.modal__validation--date')

        if (selectedDates.length == 0) {
            console.log(1)
            datePickerVal.style.display = "block";
            instance.selectedDates = this.previousSelectedDates
        } else if (selectedDates.length > 0 && datePickerVal) {
            datePickerVal.style.display = "none";
        } else if (searchOnSelect) {
            let params = queryString.parse(location.search)
            delete(params['page'])
            delete(params['only_search_id'])
            delete(params['shift_ids[]'])
            if (selectedDates.length > 1) {
                params['from_start_time'] = this.isoDate(selectedDates[0])
                params['to_start_time'] = this.isoDate(selectedDates[1])
            } else {
                params['from_start_time'] = this.isoDate(selectedDates[0])
                delete(params['to_start_time'])
            }
            const url = queryString.stringify(params)
            window.location.search = url
        }
    }

    shortDateFromIsoString(isoStringDate) {
        let date = new Date(isoStringDate);
        return this.shortDate(date)
    }

    shortDate(date) {
        if (date instanceof Date && !isNaN(date)) {
            return date.getDate() +
                "/" +  (date.getMonth() + 1) +
                "/" +  date.getFullYear();
        }
    }

    isoDate(date) {
        if (date instanceof Date && !isNaN(date)) {
            return date.getFullYear() +
                "-" +  (date.getMonth() + 1) +
                "-" + date.getDate();
        }
    }
}
import { application } from '../../frontend/controllers/index'
application.register("date_input", DateInputController);
