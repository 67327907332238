import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import { ErrorContainer, FormDetailGroup, FormGrid, FormGroup } from '../Forms/FormElements'
import { CloseButton, LoadingContainer, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../Modals/ModalElements'
import { createReason, editReason } from './ReasonsHelpers'

function ReasonsModal({ handleClose, reason, show, type }) {
  let callType = ''
  if (type === 'Reason for Cancellation') {
    callType = 'reason_for_cancellations'
  } else if (type === 'Reason for Deletion') {
    callType = 'reason_for_deletions'
  } else {
    callType = 'reason_for_requests'
  }
  const [error, setError] = useState({ reason: '' })
  const [inputs, setInputs] = useState({ reason: '' })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setInputs({ reason: '' })
    if (reason) {
      setInputs({ reason: reason.reason ? reason.reason : '' })
    }
  }, [reason])

  const handleCloseAction = () => {
    handleClose()
    setInputs({ reason: '' })
  }

  const handleInputChange = (event) => {
    setError({})
    event.persist()
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }

  const createReasonAction = () => {
    setError({})
    if (inputs.reason === '') {
      setError({ ...error, reason: 'Please enter a reason.' })
    } else {
      setLoading(true)
      setError({})
      if (inputs) {
        if (type === 'Reason for Cancellation') {
          const body = {
            reason_for_cancellation: {
              reason: inputs.reason,
              // reason_type: 'ReasonForCancellationWorker',
              type: 'ReasonForCancellationTrust',
              // ReasonForCancellationWorker
              // ReasonForCancellationTrust
            },
          }
          createReason(body, callType).then((data) => {
            if (data !== 'err') {
              window.location.reload(true)
            } else {
              setLoading(false)
              setError({
                error: true,
                message: `There was a problem creating this Reason.`,
              })
            }
          })
        } else if (type === 'Reason for Deletion') {
          const body = {
            reason_for_deletion: {
              reason: inputs.reason,
              reason_type: 'ReasonForDeletion',
            },
          }
          createReason(body, callType).then((data) => {
            if (data !== 'err') {
              window.location.reload(true)
            } else {
              setLoading(false)
              setError({
                error: true,
                message: `There was a problem creating this Reason.`,
              })
            }
          })
        } else {
          const body = {
            reason_for_request: {
              reason: inputs.reason,
            },
          }
          createReason(body, callType).then((data) => {
            if (data !== 'err') {
              window.location.reload(true)
            } else {
              setLoading(false)
              setError({
                error: true,
                message: `There was a problem creating this Reason.`,
              })
            }
          })
        }
      }
    }
  }

  const editReasonAction = (reasonId) => {
    console.log('reasonId', reasonId, inputs)
    setError({})
    if (inputs.name === '') {
      setError({ ...error, reason: 'Please enter a reason.' })
    } else {
      setLoading(true)
      if (type === 'Reason for Cancellation') {
        const body = {
          reason_for_cancellation: {
            reason: inputs.reason,
            reason_type: 'ReasonForCancellationWorker',
          },
        }
        editReason(reasonId, body, callType).then((data) => {
          if (data !== 'err') {
            window.location.reload(true)
          } else {
            setLoading(false)
            setError({
              error: true,
              message: `There was a problem updating this Reason.`,
            })
          }
        })
      } else if (type === 'Reason for Deletion') {
        const body = {
          reason_for_deletion: {
            reason: inputs.reason,
            reason_type: 'ReasonForDeletion',
          },
        }

        editReason(reasonId, body, callType).then((data) => {
          if (data !== 'err') {
            window.location.reload(true)
          } else {
            setLoading(false)
            setError({
              error: true,
              message: `There was a problem updating this Reason.`,
            })
          }
        })
      } else {
        const body = {
          reason_for_request: {
            reason: inputs.reason,
          },
        }
        editReason(reasonId, body, callType).then((data) => {
          if (data !== 'err') {
            window.location.reload(true)
          } else {
            setLoading(false)
            setError({
              error: true,
              message: `There was a problem updating this Reason.`,
            })
          }
        })
      }
    }
  }

  return (
    <Modal backdrop="static" centered onHide={handleClose} show={show} size="lg">
      <ModalHeader>
        <ModalTitle>{reason ? `Edit Reason` : 'Create Reason'}</ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg height="38" stroke="#ff7f4d" viewBox="0 0 38 38" width="38" xmlns="http://www.w3.org/2000/svg">
            <g fill="none" fillRule="evenodd">
              <g strokeWidth="2" transform="translate(1 1)">
                <circle cx="18" cy="18" r="18" strokeOpacity=".5" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    from="0 18 18"
                    repeatCount="indefinite"
                    to="360 18 18"
                    type="rotate"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0 }}>
            <ReasonsBodyForm>
              <FormDetailGroup>
                <FormGrid columns={2}>
                  <FormGroup
                    component="input"
                    error={error.reason}
                    label={type}
                    name="reason"
                    onChange={handleInputChange}
                    placeholder="Enter a reason"
                    type="text"
                    value={inputs.reason}
                  />
                </FormGrid>
              </FormDetailGroup>
            </ReasonsBodyForm>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={() => handleCloseAction()}>
                Cancel
              </button>
              {reason ? (
                <button className="btn text-white btn-cyan--modal" onClick={() => editReasonAction(reason.id)}>
                  Save Changes
                </button>
              ) : (
                <button className="btn text-white btn-cyan--modal" onClick={() => createReasonAction()}>
                  Create Reason
                </button>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

export default ReasonsModal

const ReasonsBodyForm = styled.div`
  max-width: 600px;
`
