import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

function PaymentsDashboard({ staffGroups, collabBankMember, ppiSail, ppiCollabBank, hub3Url }) {
  return (
    <PaymentsDashboardContainer>
      <PaymentsDashboardBody>
        {staffGroups?.map((staffGroup, index) => {
          return (
            <PaymentsDashboardRow key={index}>
              <PaymentsDashboardRowInner>
                <div style={{ width: '250px' }}>
                  <strong>{staffGroup.title}</strong>
                </div>

                <PaymentsDashboardRowButtons>
                  <PaymentsDashboardRowButton
                    href={`/payments?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}`}
                  >
                    <p>Payments: </p>
                    <p>Weekly Payments</p>
                  </PaymentsDashboardRowButton>
                  <PaymentsDashboardRowButton
                    href={`/batches?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}`}
                  >
                    <p>Payments: </p>
                    <p>Weekly Batches</p>
                  </PaymentsDashboardRowButton>
                  <PaymentsDashboardRowButton
                    href={`/payments?payroll_cycle=monthly&organisation_staff_group_id=${staffGroup.id}`}
                  >
                    <p>Payments: </p>
                    <p>Monthly Payments</p>
                  </PaymentsDashboardRowButton>
                  <PaymentsDashboardRowButton
                    href={`/batches?payroll_cycle=monthly&organisation_staff_group_id=${staffGroup.id}`}
                  >
                    <p>Payments: </p>
                    <p>Monthly Batches</p>
                  </PaymentsDashboardRowButton>

                  {collabBankMember && (
                    <>
                      <PaymentsDashboardRowButton
                        href={`/payment_collaborative_banks?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}&in_collaborative_bank=1`}
                      >
                        <p>Collaborative Bank: </p>
                        <p>Weekly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`/batches?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}&in_collaborative_bank=1`}
                      >
                        <p>Collaborative Bank: </p>
                        <p>Weekly Batches</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`/payment_collaborative_banks?payroll_cycle=monthly&organisation_staff_group_id=${staffGroup.id}&in_collaborative_bank=1`}
                      >
                        <p>Collaborative Bank: </p>
                        <p>Monthly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`/batches?payroll_cycle=monthly&organisation_staff_group_id=${staffGroup.id}&in_collaborative_bank=1`}
                      >
                        <p>Collaborative Bank: </p>
                        <p>Monthly Batches</p>
                      </PaymentsDashboardRowButton>
                    </>
                  )}

                  {staffGroup.vms && (
                    <>
                      <PaymentsDashboardRowButton
                        href={`/payments?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}&in_agency=true`}
                      >
                        <p>Agency:</p>
                        <p>Weekly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`/batches?payroll_cycle=weekly&organisation_staff_group_id=${staffGroup.id}&in_agency=true`}
                      >
                        <p>Agency:</p>
                        <p>Weekly Batches</p>
                      </PaymentsDashboardRowButton>
                    </>
                  )}

                  {ppiSail && (
                    <>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/payments/bank/${staffGroup.id}/itemised/weekly?status=outstanding`}
                      >
                        <p>Itemised: </p>
                        <p>Weekly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton href={`${hub3Url}/batches/bank/${staffGroup.id}/itemised/weekly`}>
                        <p>Itemised: </p>
                        <p>Weekly Batches</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/payments/bank/${staffGroup.id}/itemised/monthly?status=outstanding`}
                      >
                        <p>Itemised: </p>
                        <p>Monthly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton href={`${hub3Url}/batches/bank/${staffGroup.id}/itemised/monthly`}>
                        <p>Itemised: </p>
                        <p>Monthly Batches</p>
                      </PaymentsDashboardRowButton>
                    </>
                  )}

                  {ppiSail && collabBankMember && ppiCollabBank && (
                    <>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/payments/collab-bank/${staffGroup.id}/itemised/weekly?status=outstanding`}
                      >
                        <p>Collab Bank Itemised: </p>
                        <p>Weekly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/batches/collab-bank/${staffGroup.id}/itemised/weekly`}
                      >
                        <p>Collab Bank Itemised: </p>
                        <p>Weekly Batches</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/payments/collab-bank/${staffGroup.id}/itemised/monthly?status=outstanding`}
                      >
                        <p>Collab Bank Itemised: </p>
                        <p>Monthly Payments</p>
                      </PaymentsDashboardRowButton>
                      <PaymentsDashboardRowButton
                        href={`${hub3Url}/batches/collab-bank/${staffGroup.id}/itemised/monthly`}
                      >
                        <p>Collab Bank Itemised: </p>
                        <p>Monthly Batches</p>
                      </PaymentsDashboardRowButton>
                    </>
                  )}
                </PaymentsDashboardRowButtons>
              </PaymentsDashboardRowInner>
            </PaymentsDashboardRow>
          )
        })}
      </PaymentsDashboardBody>
    </PaymentsDashboardContainer>
  )
}

export default PaymentsDashboard

const PaymentsDashboardContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin-top: -75px;
  overflow: auto !important;
`

const PaymentsDashboardBody = styled.div`
  padding: 32px 24px;
`

const PaymentsDashboardRow = styled.div`
  max-width: 1100px;
  width: 100%;
  &:not(:last-child) {
    border-bottom: solid 1px #e5e9ec;
    margin-bottom: 32px;
    padding-bottom: 32px;
  }
`

const PaymentsDashboardRowInner = styled.div`
  display: flex;

  div {
    &:not(:last-child) {
      margin-right: 20px;
    }

    &:first-child {
      width: 170px;
    }
  }
`

const PaymentsDashboardRowButtons = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-wrap: wrap;

    &:not(:first-child) {
      max-width: 415px;
      width: 100%;
    }
  }
`

const PaymentsDashboardRowButton = styled.a`
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  background-color: #f5f7fa;
  color: #002033;
  line-height: 1.1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  height: 40px;
  justify-content: center;
  padding: 25px;
  margin: 4px;
  align-items: center;

  p {
    margin: 0;

    &:first-child {
      font-weight: 600;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-width: 170px;
    width: 100%;

    &:first-child {
      margin-bottom: 16px;
    }

    &:first-child,
    &:nth-child(3) {
      margin-right: 16px;
    }
  }

  &:hover {
    background: #eaebeb;
  }
`
PaymentsDashboard.propTypes = {
  collabBankMember: PropTypes.bool.isRequired,
  ppiSail: PropTypes.bool.isRequired,
  ppiCollabBank: PropTypes.bool.isRequired,
  staffGroups: PropTypes.array.isRequired,
  onCall: PropTypes.bool.isRequired,
  hub3Url: PropTypes.string.isRequired,
}
