import { Controller } from "stimulus";

export default class TimesheetAuthoriserTableController extends Controller {

    static get targets() {
      return ["showResetSection", "resetSection", "successSection" ]
    }

    connect() {
        this.selectedTimesheetAuthorisers = []
    }
    
  showResetPinSection(event) {
    event.preventDefault();
    this.resetSectionTarget.classList.remove('d-none')
    this.showResetSectionTarget.classList.add('d-none')
  }

  resetPin(event) {
    event.preventDefault();
    const query = `mutation($timesheet_authoriser_id: Int!){
      timesheetAuthoriserRequestPin(timesheetAuthoriserId: $timesheet_authoriser_id){
        success
        errors {
          attribute
          message
        }
      }
    }`;

    const timesheet_authoriser_id =  parseInt(event.target.id)

    event.target.disabled = true;

    // fetch call
    fetch("/graphql", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({
            query,
            variables: { timesheet_authoriser_id: timesheet_authoriser_id },
        }),
    }).then((response) => {
          console.log(response);
          if (response.status != 200) {
              console.log("error");
          } else if (response.status == 200) {
            this.resetSectionTarget.classList.add('d-none')
            this.successSectionTarget.classList.remove('d-none')
          }
      })
      .catch((err) => {
          console.log("Fetch Error", err);
      });
  }

  closeSuccessSection(event){
    event.preventDefault();
    this.successSectionTarget.classList.add('d-none')
    this.showResetSectionTarget.classList.remove('d-none')
  }
}

import { application } from "../../frontend/controllers/index";
application.register("timesheet_authoriser_table", TimesheetAuthoriserTableController);

