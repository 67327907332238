import PropTypes from 'prop-types'
import React from 'react'
import OtpInput from 'react-otp-input'
import EyeIcon from './svg/EyeIcon'

const inputStyle = {
  display: 'block',
  width: '42px',
  height: '42px',
  background: '#F9FBFD',
  border: '1px solid #CCD2D8',
  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.05)',
  borderRadius: '50%',
  marginRight: '28px',
}

function PinResetForm({ state, setState }) {
  const { pin, isPinHidden, pinConfirmation, isConfirmHidden } = state
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="reset-pin__label--pin">Choose Authorisation PIN</label>
      <div className="reset-pin__row">
        <OtpInput
          inputStyle={inputStyle}
          isInputSecure={isPinHidden}
          numInputs={4}
          onChange={(val) => setState({ ...state, pin: val })}
          value={pin}
        />
        <button
          className="reset-pin__toggle-view"
          onClick={() => setState({ ...state, isPinHidden: !isPinHidden })}
          type="button"
        >
          <EyeIcon />
          Show
        </button>
      </div>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className="reset-pin__label--pin">Re-enter Authorisation PIN</label>
      <div className="reset-pin__row">
        <OtpInput
          inputStyle={inputStyle}
          isInputSecure={isConfirmHidden}
          numInputs={4}
          onChange={(val) => setState({ ...state, pinConfirmation: val })}
          value={pinConfirmation}
        />
        <button
          className="reset-pin__toggle-view"
          onClick={() => setState({ ...state, isConfirmHidden: !isConfirmHidden })}
          type="button"
        >
          <EyeIcon />
          Show
        </button>
      </div>
    </>
  )
}

PinResetForm.propTypes = {
  state: PropTypes.shape({
    pin: PropTypes.string.isRequired,
    isPinHidden: PropTypes.bool.isRequired,
    pinConfirmation: PropTypes.string.isRequired,
    isConfirmHidden: PropTypes.bool.isRequired,
  }).isRequired,
  setState: PropTypes.func.isRequired,
}

export default PinResetForm
