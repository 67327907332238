import React, { useState } from 'react'

import ProcessPaymentsModal from './ProcessPaymentsModal'

const ProccessPayments = ({ inAgency, collabBank, forReceipts, orgStaffGroupId, payrollCycle }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    document.getElementById('all-shifts-toggle').value = 0;
    setShow(false);
  }

  const handleShow = () => setShow(true);
  const allShiftsToggle = document.getElementById('all-shifts-toggle').value === "1" ? true : false

  return (
    <>
      <button className="btn ml-3 btn-white action-bar__button action-bar__main-button" onClick={handleShow} data-target="action_bar.processPayment">
        <svg width="19" height="18" style={{ marginRight: 8 }} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M0.5 9C0.5 4.03125 4.53125 0 9.5 0C14.4688 0 18.5 4.03125 18.5 9C18.5 13.9688 14.4688 18 9.5 18C4.53125 18 0.5 13.9688 0.5 9ZM9.09125 11.7787L13.5913 7.27875C13.8875 6.98625 13.8875 6.51375 13.5913 6.22125C13.2988 5.925 12.8263 5.925 12.5338 6.22125L8.5625 10.1888L6.46625 8.09625C6.17375 7.8 5.70125 7.8 5.40875 8.09625C5.1125 8.38875 5.1125 8.86125 5.40875 9.15375L8.03375 11.7787C8.18 11.925 8.37125 12 8.5625 12C8.75375 12 8.945 11.925 9.09125 11.7787Z" fill="#56B655"/>
        </svg>
        Process Payments
      </button>
      {show &&
        <ProcessPaymentsModal inAgency={inAgency} allShiftsToggle={allShiftsToggle} collabBank={collabBank} forReceipts={forReceipts} handleClose={handleClose} orgStaffGroupId={orgStaffGroupId} payrollCycle={payrollCycle} show={show} />
      }
    </>
  )
}

export default ProccessPayments
