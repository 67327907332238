/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import { DefaultButton, FormGroup, FormLabel, FormSelect } from '@patchworkhealth/web-components'
import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

export const removePercentageWithinSpans = (htmlContent) => {
  const parser = new DOMParser()
  const serializer = new XMLSerializer()
  const doc = parser.parseFromString(htmlContent, 'text/html')
  const spans = doc.querySelectorAll('span.word-sub')
  spans.forEach((span) => {
    const textContent = span.textContent || ''
    const newTextContent = textContent.replace(/%/g, '')
    // eslint-disable-next-line no-param-reassign
    span.textContent = newTextContent
  })

  return serializer.serializeToString(doc)
}

/*
  agencyWorker PaymentTypes: 1: 'ltd', 2: 'paye'
  contractTypes: 1: 'de_ltd', 2: 'de_paye', 3: 'de_sole_trader'
*/

function DeContract({ setInputs, inputs, handleAssignContract, proposal }) {
  const contentRef = useRef(null)
  const filteredOptions = inputs.contractOptions.filter((option) =>
    option.contractType.includes(
      proposal.agencyWorker.paymentType === 'ltd' && proposal.agencyWorker.isSoleTrader
        ? 'de_sole_trader'
        : proposal.agencyWorker.paymentType === 'ltd'
        ? 'de_ltd'
        : 'de_paye'
    )
  )
  const { selectedContract } = inputs

  useEffect(() => {
    const selectedValue = inputs.contractOptions.find((contract) => contract.id === selectedContract?.value)

    if (contentRef?.current) {
      contentRef.current.innerHTML = removePercentageWithinSpans(selectedValue?.content ?? '')
    }
  }, [selectedContract, inputs.contractOptions])

  useEffect(() => {
    // If there is only one option in the filtered list, preselect it
    if (filteredOptions.length === 1) {
      setInputs({ ...inputs, selectedContract: { label: filteredOptions[0]?.name, value: filteredOptions[0]?.id } })
    }
  }, [])

  return (
    <>
      <div>
        <AgencyContracts>
          <FormGroup style={{ width: 200, marginRight: 22 }}>
            <FormLabel>Attach Contract</FormLabel>
            <FormSelect
              onChange={(option) => {
                setInputs({ ...inputs, selectedContract: option })
              }}
              options={filteredOptions.map((option) => ({
                label: option.name,
                value: option.id,
              }))}
              placeholder="Select Contract"
              value={selectedContract}
            />
          </FormGroup>
        </AgencyContracts>

        {selectedContract && (
          <div
            ref={contentRef}
            onInput={() => null}
            style={{
              position: 'relative',
              minHeight: 0,
              maxHeight: 450,
              padding: 18,
              overflow: 'auto',
              background: 'white',
              border: '1px solid #e9eef4',
            }}
          />
        )}
      </div>

      <AgencyProposalFooter>
        <div />
        <div>
          <DefaultButton
            onClick={() => setInputs({ ...inputs, selectedContract: null, deProposal: null })}
            style={{ marginRight: 5 }}
            type="white"
          >
            Cancel
          </DefaultButton>

          <DefaultButton onClick={() => handleAssignContract(proposal)}>Assign Contract</DefaultButton>
        </div>
      </AgencyProposalFooter>
    </>
  )
}

export default DeContract

export const AgencyProposalCard = styled.div`
  margin-bottom: 24px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  p,
  a {
    font-size: 12px;
  }
  border-radius: 6px;
  border: 1px solid #c3ccd3;
  background: #f2f5f8;
  padding: 20px 20px 0px;
  position: relative;

  .span {
    text-align: center;
    background: #77797b;
    color: white;
    border-radius: 4px;
    font-weight: 600;
    font-size: 12px;
    margin-left: 4px;
    padding: 4px 12px;
  }
`

export const AgencyProposalFooter = styled.div`
  margin-top: 22px;
  border-top: 1px solid #e9eef4;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

export const AgencyContracts = styled.div`
  width: 300px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 12px;
`
