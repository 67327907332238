import { Controller } from "stimulus";
import SlimSelect from "slim-select";

export default class ApplicationEditController extends Controller {
  static get targets() {
    return ["departmentSelect", "gradeSelect"];
  }

  connect() {
    const departmentSelectTarget = this.departmentSelectTarget;
    const gradeSelectTarget = this.gradeSelectTarget;
    const gradeSelectorVal = document.querySelector('.modal__validation--grade');

    new SlimSelect({
      select: departmentSelectTarget,
    });

    new SlimSelect({
      select: gradeSelectTarget,
      onChange: (info) => {
        gradeSelectorVal.style.display = "none";
      }
    });
  }

  submit(event) {
    event.preventDefault();
    const form = document.getElementById('external-accept-form') ? document.getElementById('external-accept-form') : document.getElementById('internal-accept-form');
    const esrNumberInput = document.querySelector('.esr-number-input');
    const gradeSelector = document.querySelector('#grade-selector');

    // Validation boxes
    const esrInputVal = document.querySelector('.modal__validation--esr');
    const gradeSelectorVal = document.querySelector('.modal__validation--grade');

    if (esrNumberInput.value === "") {
      esrInputVal.style.display = "block";
    } else {
      esrInputVal.style.display = "none";
    }

    if (gradeSelector.value === "") {
      gradeSelectorVal.style.display = "block";
    } else {
      gradeSelectorVal.style.display = "none";
    }

    if (!form.checkValidity()) {
      let tmpSubmit = document.createElement('button')
      form.appendChild(tmpSubmit)
      tmpSubmit.click()
      form.removeChild(tmpSubmit)
    } else {
      form.submit();
    }
  }
}

import { application } from "../../frontend/controllers/index";
application.register("application_edit", ApplicationEditController);
