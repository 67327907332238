import { Controller } from "stimulus";
import moment from "moment";

export default class TimesheetSignoff extends Controller {
  static get targets() {
    return [
      "toggleButton",
      "amendedDetails",
      "intialDetails",
      "isAmended",
      "shiftType",
      "rejectReason",
      "enforceBreaksAmended",
      "enforceBreaksNotAmended",
      "amendedDate",
      "amendedStart",
      "amendedStartData",
      "amendedStartDateValue",
      "amendedStartValue",
      "amendedEnd",
      "amendedEndData",
      "amendedEndDateValue",
      "amendedEndValue",
      "amendedBreaks",
      "amendedBreaksValue",
      "initialDate",
      "initialStart",
      "initialStartData",
      "initialEnd",
      "initialEndData",
      "initialBreaks",
      "acceptWorker",
    ];
  }

  connect() {
    if (this.isAmendedTarget.value == "true") {
      // set initial values to nil for the form
      this.initialDateTarget.name = "";
      this.initialStartTarget.name = "";
      this.initialEndTarget.name = "";
      this.initialBreaksTarget.name = "";
      // check the difference of value in the breaks
      const amendedBreaksDiff =
        this.amendedBreaksTarget.value - this.initialBreaksTarget.value;
      // append the difference with a + or -

      const amendedBreaksDiffAppend =
        amendedBreaksDiff.toString().charAt(0) === "-" ? "" : "+";
      // set the difference in the input span
      this.amendedBreaksValueTarget.innerHTML =
        amendedBreaksDiffAppend + amendedBreaksDiff + " mins";
      // hide initial details + reject reason on load if isAmended
      this.intialDetailsTarget.style.display = "none";
      this.rejectReasonTarget.style.display = "none";
      this.enforceBreaksAmendedTarget.style.display = "flex";
      this.enforceBreaksNotAmendedTarget.style.display = "none";

      // Sort StartTimes ******************************************

      let initialStart = this.initialStartDataTarget.value.slice(0, 19);
      let reportedStart = this.amendedStartDataTarget.value.slice(0, 19);

      let momentInitialStartTime = moment(initialStart, "YYYY-MM-DD HH:mm:ss");
      let momentReportedStartTime = moment(reportedStart, "YYYY-MM-DD HH:mm:ss");

      if(this.shiftTypeTarget.value == 'on_call'){
        let startDaysDiff = momentReportedStartTime.diff(momentInitialStartTime, "days");
        let startDaysSign = startDaysDiff.toString().charAt(0) === "-" ? "" : "+";
        this.amendedStartDateValueTarget.innerHTML = startDaysSign + startDaysDiff + " days";

        momentInitialStartTime.add(startDaysDiff, 'days');
      }

      let startDiff = momentReportedStartTime.diff(momentInitialStartTime, "minutes");
      let startSign = startDiff.toString().charAt(0) === "-" ? "" : "+";
      this.amendedStartValueTarget.innerHTML = startSign + startDiff + " mins";


      // Sort EndTimes ******************************************

      let initialEnd = this.initialEndDataTarget.value.slice(0, 19);
      let reportedEnd = this.amendedEndDataTarget.value.slice(0, 19);

      let initialEndTime = moment(initialEnd, "YYYY-MM-DD HH:mm:ss");
      let reportedEndTime = moment(reportedEnd, "YYYY-MM-DD HH:mm:ss");

      if (this.shiftTypeTarget.value == 'on_call') {
        let endDaysDiff = reportedEndTime.diff(initialEndTime, "days");
        let endDaysSign = endDaysDiff.toString().charAt(0) === "-" ? "" : "+";
        this.amendedEndDateValueTarget.innerHTML = endDaysSign + endDaysDiff + " days";

        initialEndTime.add(endDaysDiff, 'days');
      }

      let endDiff = reportedEndTime.diff(initialEndTime, "minutes");
      let endSign = endDiff.toString().charAt(0) === "-" ? "" : "+";
      this.amendedEndValueTarget.innerHTML = endSign + endDiff + " mins";
    }
  }

  toggleClick(e) {
    e.preventDefault();
    // reset to shift default
    if (this.isAmendedTarget.value === "true") {
      // reject worker amendment
      this.acceptWorkerTarget.value = "false";
      // set amended names to nil for the form
      this.amendedDateTarget.name = "";
      this.amendedStartTarget.name = "";
      this.amendedEndTarget.name = "";
      this.amendedBreaksTarget.name = "";
      // set initial names to correct value for form
      this.initialDateTarget.name = "shift[start_day]";
      this.initialStartTarget.name = "shift[confirmed_start_time]";
      this.initialEndTarget.name = "shift[confirmed_end_time]";
      this.initialBreaksTarget.name = "shift[confirmed_breaks]";
      //
      this.amendedDetailsTarget.style.display = "none";
      this.intialDetailsTarget.style.display = "";
      this.rejectReasonTarget.style.display = "flex";
      this.enforceBreaksAmendedTarget.style.display = "none";
      this.enforceBreaksNotAmendedTarget.style.display = "flex";
      this.toggleButtonTarget.innerHTML = "Revert to worker’s proposed times";
      this.isAmendedTarget.value = "false";
      // revert to worker amendment
    } else if (this.isAmendedTarget.value === "false") {
      // accept worker amendment
      this.acceptWorkerTarget.value = "true";
      // set initial names to nil for the form
      this.initialDateTarget.name = "";
      this.initialStartTarget.name = "";
      this.initialEndTarget.name = "";
      this.initialBreaksTarget.name = "";
      // set amended names to correct value for form
      this.amendedDateTarget.name = "shift[start_day]";
      this.amendedStartTarget.name = "shift[confirmed_start_time]";
      this.amendedEndTarget.name = "shift[confirmed_end_time]";
      this.amendedBreaksTarget.name = "shift[confirmed_breaks]";
      //
      this.intialDetailsTarget.style.display = "none";
      this.amendedDetailsTarget.style.display = "";
      this.rejectReasonTarget.style.display = "none";
      this.enforceBreaksAmendedTarget.style.display = "flex";
      this.enforceBreaksNotAmendedTarget.style.display = "none";
      this.toggleButtonTarget.innerHTML = "Reset time to shift default";
      this.isAmendedTarget.value = "true";
    }
  }
}

import { application } from "../../frontend/controllers/index";
application.register("timesheet_signoff", TimesheetSignoff);
