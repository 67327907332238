import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import queryString from 'query-string'

class ExternalApplicationAppliedController extends Flatpickr {
    connect() {
        const defaultDates = []
        if (this.data.get('from_date')) {
            defaultDates.push(this.data.get('from_date'))
        }
        if (this.data.get('to_date')) {
            defaultDates.push(this.data.get('to_date'))
        }
        this.config = {
            ...this.config, //spread options in case some where defined in initialize
            dateFormat: "Y-m-d",
            defaultDate: defaultDates
        };
        super.connect();
    }

    open(selectedDates, dateStr, instance) {
        this.previousSelectedDates = instance.selectedDates
        instance.selectedDates = []

    }
    // all flatpickr hooks are available as callbacks in your Stimulus controller
    close(selectedDates, dateStr, instance) {
        const searchOnSelect = this.data.get('search_on_select');
        if (selectedDates.length == 0) {
            instance.selectedDates = this.previousSelectedDates
        } else if (searchOnSelect) {
            let params = queryString.parse(location.search)
            delete (params['page'])

            if (selectedDates.length > 1) {
                params['from_date'] = selectedDates[0].toISOString().split('T')[0]
                params['to_date'] = selectedDates[1].toISOString().split('T')[0]
            } else {
                params['from_date'] = selectedDates[0].toISOString().split('T')[0]
                delete (params['to_date'])
            }

            const url = queryString.stringify(params)
            window.location.search = url
        }
    }
}
import { application } from '../../frontend/controllers/index'
application.register("external_application_applied", ExternalApplicationAppliedController);
