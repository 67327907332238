import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class TotalPayAfterRateCardEscalationController extends Controller {
  static get targets() {
    return ['escalateRate', 'totalPayRate', 'percent']
  }

  calculate() {
    const allRates = this.escalateRateTargets.flatMap((x) => [
      { new_rate: x.value, old_rate: x.dataset.old_rate, hours: x.dataset.hours, total: x.value * x.dataset.hours },
    ])

    const totalPayAfterEscalation = allRates
      .reduce((acc, curr) => {
        return acc + curr.total
      }, 0)
      .toFixed(2)

    this.totalPayRateTarget.innerText = `£${totalPayAfterEscalation}`

    const percentIncreased = ((totalPayAfterEscalation / this.data.get('total_shift_pay')) * 100 - 100).toFixed(2)
    this.percentTarget.innerText = `${percentIncreased}%`

    const rateDetail = this.escalateRateTargets.flatMap((x) => [
      { newRate: parseFloat(x.value), oldRate: parseFloat(x.dataset.old_rate) },
    ])

    const allRatesSet = rateDetail.every((item) => !Number.isNaN(item.newRate))

    if (allRatesSet) {
      const updateEvent = new CustomEvent('total-pay-after-escalation-updated', { detail: { rateChanges: rateDetail } })
      document.dispatchEvent(updateEvent)
    }
  }
}
application.register('total_pay_after_rate_card_escalation', TotalPayAfterRateCardEscalationController)
