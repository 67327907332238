import { Controller } from 'stimulus'

export default class AmountSelectorController extends Controller {

    static get targets() {
        return ['amountOfShifts', 'increment', 'decrement', 'amountOfShifts']
    }

    connect() {
        const amountOfShiftsTarget = this.amountOfShiftsTarget;

        this.amountOfShiftsTarget.value = 1;

        this.incrementTarget.onclick = function () {
          amountOfShiftsTarget.value++
          const amountSelectorVal = document.querySelector('.modal__validation--amount');
          if (amountSelectorVal) {
              amountSelectorVal.style.display = "none";
          }
        };

        this.decrementTarget.onclick = function () {
          if (amountOfShiftsTarget.value == 1) {
              return;
          }
          const amountSelectorVal = document.querySelector('.modal__validation--amount');
          if (amountSelectorVal) {
              amountSelectorVal.style.display = "none";
          }

          amountOfShiftsTarget.value--
        };
    }

}

import { application } from '../../frontend/controllers/index'
application.register("amount_selector", AmountSelectorController)