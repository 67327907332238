import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import Ajax from '../../frontend/packs/ajax'

export default class AgencyLocumPickerController extends Controller {
  static get targets() { return ['agencySelect', 'workerSelect'] }

  connect () {
    this.selectAgency = new SlimSelect({
        select: this.agencySelectTarget,
        placeholder: 'Select agency',
    });

    this.selectWorker = new SlimSelect({
        select: this.workerSelectTarget,
        placeholder: 'Select agency first',
    });
  }

  agencyChanged() {
    var agencyId = parseInt(this.agencySelectTarget.value);
    if(agencyId != NaN && agencyId > 0) {
      this.selectWorker.setData([
        { value: '', text: 'Loading...' }
      ]);
      this.fetchWorkers(agencyId)
    } else {
      this.cleanWorkers();
    }

  }

  fetchWorkers(agencyId) {
    var ajax = new Ajax();
    var url = '/agencies/'+agencyId+'/workers_list'
    ajax.post(url, {}, (data) => {
      var data = JSON.parse(data);
      data.unshift({id: '', text: 'Select worker'})
      this.selectWorker.setData(data);
    });
  }

  cleanWorkers(){
    this.selectWorker.setData([
      { value: '', text: 'Select agency first' }
    ]);

  }

}

import { application } from '../../frontend/controllers/index'

application.register("agency_locum_picker", AgencyLocumPickerController)
