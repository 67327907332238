import { gql } from '@apollo/client'

export const SUBMIT_EMAIL_FORM_MUTATION = gql`
  mutation submitEmailForm($email: String!) {
    timesheetAuthoriserRequestPinWithEmail(email: $email) {
      success
      errors {
        message
      }
    }
  }
`

export const PIN_RESET_MUTATION = gql`
  mutation timesheetAuthoriserResetPin($token: String!, $pin: String!) {
    timesheetAuthoriserResetPin(token: $token, pin: $pin) {
      success
      errors {
        message
      }
    }
  }
`
