import { Controller } from "stimulus";
import queryString from "query-string";

export default class ActionBarTimesheetAuthoriserController extends Controller {
  static get targets() {
    return [
      'selectAll',
      'close',
      'actionBar',
      'authToken'
    ];
  }

  connect() {
    this.count = 0;
    const controller_this = this;
    this.element[this.identifier] = this;
    const selectAll = this.selectAllTarget;
    const close = this.closeTarget;
    const timesheetAuthoriserSelectAll = document.getElementsByClassName("table-authorisers-all")[0];
    const allAuthoriserCheckboxes = document.getElementsByClassName('table-timesheet-authoriser-checkbox')
    const authoriserSelectedLabel = document.querySelector(".action-bar__label");
    const timesheetAuthoriserList = document.getElementsByClassName("timesheet-authoriser-row--available");

    function check() {
      for (let i = 0; i < timesheetAuthoriserList.length; i++) {
        let tsAuthoriserCheckbox = timesheetAuthoriserList[i].getElementsByTagName("input")[0];
        tsAuthoriserCheckbox.addEventListener("change", function () {

        this.checked
          ? controller_this.increment()
          : controller_this.decrement();

        });
      }

      close.addEventListener("click", function () {
        timesheetAuthoriserSelectAll.checked = false
        controller_this.actionBarTarget.classList.remove("action-bar--toggle");

        for (let i = 0; i < allAuthoriserCheckboxes.length; i++) {
          allAuthoriserCheckboxes[i].checked = false
          controller_this.actionBarTarget.classList.remove("action-bar--toggle");
        }
      })

      timesheetAuthoriserSelectAll.addEventListener("click", function () {
        if (
          !controller_this.actionBarTarget.classList.contains(
            "action-bar--toggle"
          )
        ) {
          controller_this.actionBarTarget.classList.add("action-bar--toggle");
        }

        if(this.checked == true){
          for (let i = 0; i < allAuthoriserCheckboxes.length; i++) {
            allAuthoriserCheckboxes[i].checked = true
          }
            selectAll.innerText = "Deselect All";
        } else {
          selectAll.innerText = "Select All";

          for (let i = 0; i < allAuthoriserCheckboxes.length; i++) {
            allAuthoriserCheckboxes[i].checked = false
            controller_this.actionBarTarget.classList.remove("action-bar--toggle");
          }
        }

        const count = document.querySelectorAll(
          'input[name="ordersSelect"]:checked'
        ).length;

        let conditionalCount =
          count <= 1
            ? (authoriserSelectedLabel.innerHTML =
                count + " Authoriser selected")
            : (authoriserSelectedLabel.innerHTML =
                count + " Authorisers selected");

      });
    }
    check();
  }


  increment(selectedAuthorisers) {
    this.actionBarTarget.classList.add("action-bar--toggle");

    const count = document.querySelectorAll(
      'input[name="ordersSelect"]:checked'
    ).length;

    const authoriserSelectedLabel = document.querySelector(".action-bar__label");
    let conditionalCount =
      count <= 1
        ? (authoriserSelectedLabel.innerHTML = count + " Authoriser selected")
        : (authoriserSelectedLabel.innerHTML = count + " Authorisers selected");
  }


  decrement(selectedAuthorisers) {
    const count = document.querySelectorAll(
      'input[name="ordersSelect"]:checked'
    ).length;

    if(count < 1){
      this.actionBarTarget.classList.remove("action-bar--toggle");
    }

    const authoriserSelectedLabel = document.querySelector(".action-bar__label");
    let conditionalCount =
      count <= 1
        ? (authoriserSelectedLabel.innerHTML = count + " Authoriser selected")
        : (authoriserSelectedLabel.innerHTML = count + " Authorisers selected");
  }

  sendBulkPinRequest(event) {
    event.preventDefault();

    let sendBulkPinRequestForm = document.createElement("form");
    sendBulkPinRequestForm.setAttribute("method", "post");
    sendBulkPinRequestForm.setAttribute(
      "action",
      `/timesheet_authorisers/send_bulk_pin_requests/?` + this.authoriserQuery()
    );
    sendBulkPinRequestForm.style.display = "hidden";
    sendBulkPinRequestForm.appendChild(this.authTokenTarget.cloneNode());
    document.body.appendChild(sendBulkPinRequestForm);
    sendBulkPinRequestForm.submit();
    event.target.disabled = true;
  }


  authoriserQuery() {
    const authoriserTable = document.querySelector("#timesheet_authoriser_table");
    const checked = Array.from(
      authoriserTable.querySelectorAll("input[type=checkbox]:checked")
    ).map((item) => item.value);

    let params = {};

    params["timesheet_authoriser_ids[]"] = checked;
    return queryString.stringify(params);
  }
}

import { application } from "../../frontend/controllers/index";
application.register("action_bar_timesheet_authoriser", ActionBarTimesheetAuthoriserController);
