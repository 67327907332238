export async function processPayments(url, body) {
  const data = fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response.json();
  })
  return data;
}
