/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { ApolloProvider, useMutation } from '@apollo/client'
import { gql } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { Slide, ToastContainer, toast } from 'react-toastify'
import styled from 'styled-components'
import createApolloClient from '../createApolloClient'

export const DE_CONTRACTS_RESEND_VERSION_ASSIGNMENT = gql`
  mutation deContractsResendVersionAssignment($shiftId: Int!) {
    deContractsResendVersionAssignment(shiftId: $shiftId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

document.addEventListener('DOMContentLoaded', () => {
  const client = createApolloClient()
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const observer = new MutationObserver(() => {
    const rootElement = document.querySelector('[data-react-component="rollback_de_contract"]')
    const shiftId = rootElement?.getAttribute('shiftId')

    if (rootElement && rootElement.childElementCount === 0) {
      ReactDOM.render(
        <>
          <ToastContainer position="top-center" transition={Slide} />
          <ApolloProvider client={client}>
            <RollbackContract shiftId={shiftId} />
          </ApolloProvider>
        </>,
        rootElement
      )
    }
  })
  observer.observe(document, config)
})

const initialInputs = {
  contractOptions: [],
  selectedProposal: null,
  deProposal: null,
  selectedContract: null,
  loading: false,
  showRejection: null,
  rejectionReason: '',
}

function RollbackContract({ shiftId }) {
  // const [inputs, setInputs] = useState(initialInputs)
  // const [activeTabs, setActiveTabs] = useState({})

  // const setActiveTab = (proposalId, tab) => {
  //   setActiveTabs((prevTabs) => ({
  //     ...prevTabs,
  //     [proposalId]: tab,
  //   }))
  // }

  console.log('Shift', shiftId)
  const [acceptProposal] = useMutation(DE_CONTRACTS_RESEND_VERSION_ASSIGNMENT)

  const handleRollback = async () => {
    try {
      const { data: result } = await acceptProposal({
        variables: {
          shiftId: parseInt(shiftId, 10),
        },
      })

      const errors = result?.deContractsResendVersionAssignment?.errors

      if (errors.length > 0) {
        alert(errors[0].message)
        return
      }

      alert('Contract Rolled Back Successfully!')
      window.location.reload()
    } catch (err) {
      alert('Error', err)
    }
  }

  return (
    <StyledButton onClick={handleRollback} type="button">
      Resend DE Contract
    </StyledButton>
  )
}

export default RollbackContract

const StyledButton = styled.div`
  background: #ffffff;
  border: 1px solid #05405c;
  color: #05405c;
  border-radius: 4px;
  height: 43px;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-left: 6px;
`
