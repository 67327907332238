import { IdleTimer, IdleTimerNotification, IdleTimerContext } from '@patchworkhealth/web-components'
import React, { useState, useMemo, useEffect } from 'react'
import ReactDOM from 'react-dom'

const TimerWrapper = function Timer({ logout, onTimeout }) {
  return <IdleTimer logout={logout} onTimeout={onTimeout} timeoutMs={3600000} />
}

const TimerNotificationWrapper = function TimerNotification() {
  const [hasTimedOut, setHasTimedOut] = useState(sessionStorage.getItem('hasTimedOut') === 'true')
  const contextValue = useMemo(() => ({ hasTimedOut, setHasTimedOut }), [hasTimedOut])

  useEffect(() => sessionStorage.removeItem('hasTimedOut'), [hasTimedOut])

  return (
    <IdleTimerContext.Provider value={contextValue}>
      <IdleTimerNotification />
    </IdleTimerContext.Provider>
  )
}

const logout = (hasTimedOut = false) => {
  fetch('/hub_users/sign_out', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Cookie: 'has_timed_out=true',
    },
    method: 'DELETE',
  }).then((response) => {
    if (response.ok) {
      if (hasTimedOut) sessionStorage.setItem('hasTimedOut', true)
      window.location.href = '/hub_users/sign_in'
    }
  })
}

const onTimeout = () => logout(true)

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname !== '/hub_users/sign_in') {
    const rootElement = document.getElementById('idle-timer')
    if (rootElement) ReactDOM.render(<TimerWrapper logout={logout} onTimeout={onTimeout} />, rootElement)
  } else {
    const id = 'idle-timer-notification'
    const form = document.querySelector('.new_hub_user')
    if (!form) return

    const notificationWrapperElement = document.createElement('div')
    notificationWrapperElement.setAttribute('id', id)
    form.prepend(notificationWrapperElement)

    const rootElement = document.getElementById(id)
    ReactDOM.render(<TimerNotificationWrapper />, rootElement)
  }
})
