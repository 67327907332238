export async function createReferenceCode(body) {
  const url = `/reference_codes`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function editReferenceCode(Id, body) {
  const url1 = `/reference_codes/${Id}`;
  const data = fetch(url1, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}
