import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  FormGroup,
  FormSelect,
  FormLabel,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import {
  successToast,
  errorToast,
  loadingToast,
  SHIFT_BLOCK_DELETE,
  SHIFT_BLOCK_REASONS,
} from '../helpers/BlockBookingsHelpers'

function DeleteBlockModal({ blockToDelete, handleCloseConfirm, refetch }) {
  const [shiftBlockDelete] = useMutation(SHIFT_BLOCK_DELETE)
  const showModal = typeof blockToDelete !== 'undefined' && blockToDelete !== null

  const [deleteReasons, setDeleteReasons] = useState([])
  const [reasonValue, setReasonValue] = useState(null)

  useQuery(SHIFT_BLOCK_REASONS, {
    fetchPolicy: 'network-only',
    skip: !blockToDelete,
    onCompleted: (res) => {
      setDeleteReasons(res.shiftBlocks.nodes[0].reasonsForDeletion)
    },
    variables: {
      shiftBlockIds: [parseInt(blockToDelete?.id, 10)],
    },
  })

  const deleteReasonAction = (id) => {
    toast.loading('Loading', loadingToast)

    shiftBlockDelete({
      variables: {
        shiftBlockId: Number(id),
        reasonForDeletionId: reasonValue.value,
      },
    })
      .then((res) => {
        const errors = res.data?.shiftBlockDelete?.errors

        if (errors.length > 0) {
          toast.update(2, errorToast)
          return
        }

        refetch()
        handleCloseConfirm()

        toast.update(2, { ...successToast, render: 'Block Deleted' })
      })
      .catch(() => {
        toast.update(2, errorToast)
      })
  }

  if (!showModal) {
    return null
  }

  return (
    <Modal backdrop="static" centered onHide={handleCloseConfirm} show={showModal} size="lg">
      <ModalHeader>
        <ModalTitle>Confirm Delete</ModalTitle>
        <CloseButton onClick={handleCloseConfirm} />
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <FormLabel>Reason for Deleting</FormLabel>
          <FormSelect
            disabled
            isMulti={false}
            onChange={(e) => setReasonValue(e)}
            options={deleteReasons}
            placeholder="Select Reason"
            value={reasonValue}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <div />
        <div>
          <DefaultButton
            action={() => {
              handleCloseConfirm()
            }}
            text="Cancel"
            type="white"
          />
          <DefaultButton
            action={() => {
              deleteReasonAction(blockToDelete.id)
            }}
            color="red"
            style={{ opacity: reasonValue ? 1 : 0.5, pointerEvents: reasonValue ? 'auto' : 'none' }}
            text="Confirm Delete"
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default DeleteBlockModal
