/* eslint-disable no-nested-ternary */
import queryString from 'query-string'
import { useState, useCallback } from 'react'

export const usePagination = (numberOfItems) => {
  const [pagination, setPagination] = useState(() => initPagination(numberOfItems))
  const resetPagination = useCallback(() => {
    setPagination((s) => {
      window.history.replaceState(null, null, s.url)
      // reset url as we reset pagination
      return {
        ...s,
        after: undefined,
        before: undefined,
      }
    })
  }, [])
  return [pagination, setPagination, resetPagination]
}

export const initPagination = (numberOfItems) => {
  const items = numberOfItems ?? 25 // per page
  const pageParams = queryString.parse(window.location.search)
  return {
    after: pageParams.afterPage,
    before: pageParams.beforePage,
    first: pageParams.afterPage ? items : pageParams.beforePage ? undefined : items,
    last: pageParams.beforePage ? items : undefined,
    items,
    url: window.location.pathname,
  }
}
