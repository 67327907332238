import { Controller } from 'stimulus'

export default class CheckboxController extends Controller {

    connect() {}

}

import { application } from '../../frontend/controllers/index'

application.register("checkbox_controller", CheckboxController)