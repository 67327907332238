import { useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid';
import { FormGrid, FormGroup } from '../../Forms/FormElements'
import {
  CREATE_AGENCY_USER_PERMISSION,
  DELETE_AGENCY_USER_PERMISSION,
  UPDATE_AGENCY_USER_PERMISSION,
  VMS_CREATE_AGENCY_USER_PERMISSION,
  VMS_DELETE_AGENCY_USER_PERMISSION,
  VMS_UPDATE_AGENCY_USER_PERMISSION,
} from './AgencyUsersHelpers'

function AgencyUserPermissions({
  agencyId,
  agencyUserId,
  agencyUserPermission,
  agencyUserPermissions,
  index,
  organisationAgencies,
  refetchAgencyUsers,
  refetchAgencyUserPermissions,
  setShowNewAgencyUserPermissions,
  vms,
}) {
  const [filteredOrganisationAgencies, setFilteredOrganisationAgencies] = useState(undefined)
  const [inputs, setInputs] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [update, setUpdate] = useState(undefined)
  const [updated, setUpdated] = useState(false)

  const [createAgencyUserPermission] = useMutation(
    vms ? VMS_CREATE_AGENCY_USER_PERMISSION : CREATE_AGENCY_USER_PERMISSION
  )
  const [deleteAgencyUserPermission] = useMutation(
    vms ? VMS_DELETE_AGENCY_USER_PERMISSION : DELETE_AGENCY_USER_PERMISSION
  )
  const [updateAgencyUserPermission] = useMutation(
    vms ? VMS_UPDATE_AGENCY_USER_PERMISSION : UPDATE_AGENCY_USER_PERMISSION
  )

  useEffect(() => {
    if (agencyUserPermission) {
      setInputs({
        id: Number(agencyUserPermission.id),
        organisationStaffGroupId: Number(agencyUserPermission.organisationStaffGroup.id),
        canAssignWorker: agencyUserPermission.canAssignWorker,
        canProposeWorker: agencyUserPermission.canProposeWorker,
      })
    }

    if (agencyUserPermissions && organisationAgencies) {
      const a = []
      agencyUserPermissions.map((item) => a.push(item.organisationStaffGroup.id))
      setFilteredOrganisationAgencies(organisationAgencies.filter((i) => !a.includes(i.organisationStaffGroup.id)))
    }
  }, [agencyUserPermission, agencyUserPermissions, organisationAgencies])

  // remap organisationAgencies into an array react-select can read
  const organisationStaffGroups = filteredOrganisationAgencies?.map((organisationAgency) => {
    return {
      value: organisationAgency.organisationStaffGroup.id,
      label: organisationAgency.organisationStaffGroup.title,
    }
  })

  const handleStaffGroupChange = (event) => {
    setUpdated(true)
    setLoading(true)
    setUpdate(uuidv4())
    setInputs((newInputs) => ({
      ...newInputs,
      organisationStaffGroupId: Number(event.value),
    }))
    setTimeout(() => {
      setUpdated(false)
      setLoading(false)
    }, 2000)
  }

  const createAgencyUserPermissionAction = () => {
    createAgencyUserPermission({
      variables: {
        agencyId: Number(agencyId),
        agencyUserId: Number(agencyUserId),
        organisationStaffGroupId: inputs.organisationStaffGroupId,
        canAssignWorker: false,
        canProposeWorker: false,
      },
    }).then((data) => {
      if (vms) {
        if (data.data.vmsAgencyUserPermissionCreate) {
          setUpdated(true)
          refetchAgencyUserPermissions()
          refetchAgencyUsers()
          setShowNewAgencyUserPermissions(false)
        }
      } else if (data.data.agencyUserPermissionCreate) {
        setUpdated(true)
        refetchAgencyUserPermissions()
        refetchAgencyUsers()
        setShowNewAgencyUserPermissions(false)
      }
    })
  }

  const deleteAgencyUserPermissionAction = (agencyPermissionId) => {
    deleteAgencyUserPermission({
      variables: {
        id: Number(agencyPermissionId),
      },
    }).then((data) => {
      if (vms) {
        if (data.data.vmsAgencyUserPermissionDestroy) {
          refetchAgencyUserPermissions()
          refetchAgencyUsers()
        }
      } else if (data.data.agencyUserPermissionDestroy) {
        refetchAgencyUserPermissions()
        refetchAgencyUsers()
      }
    })
  }

  const updateAgencyUserPermissionAction = () => {
    updateAgencyUserPermission({
      variables: {
        id: Number(inputs.id),
        agencyId: Number(agencyId),
        organisationStaffGroupId: Number(inputs.organisationStaffGroupId),
        canAssignWorker: inputs.canAssignWorker,
        canProposeWorker: inputs.canProposeWorker,
      },
    }).then((data) => {
      if (vms) {
        if (data.data.vmsAgencyUserPermissionUpdate) {
          setUpdated(true)
          refetchAgencyUserPermissions()
          refetchAgencyUsers()
        }
      } else if (data.data.agencyUserPermissionUpdate) {
        setUpdated(true)
        refetchAgencyUserPermissions()
        refetchAgencyUsers()
      }
    })
  }

  useEffect(() => {
    if (update) {
      if (!agencyUserPermission) {
        createAgencyUserPermissionAction()
      } else {
        updateAgencyUserPermissionAction()
      }
    }
  }, [agencyUserPermission, update])

  const organisationAgencyCanProposeWorker = (organisationStaffGroupId) => {
    const organisationAgency = (organisationAgencies ?? []).find((item) => {
      return item.organisationStaffGroup.id === organisationStaffGroupId
    })

    return organisationAgency?.canProposeWorker
  }

  return (
    <AgencyUserPermissionContainer key={index} className={updated ? 'updated' : ''}>
      {loading && (
        <UpdatingLabel>
          <svg fill="none" height="16" width="16" xmlns="http://www.w3.org/2000/svg">
            <rect fill="#7DD962" height="16" rx="3" width="16" />
            <path
              d="M6.334 11L3 7.937l.952-.874L6.413 9.32l-.08-.071L12.049 4l.952.875-5.714 5.25-.952.874V11z"
              fill="#fff"
            />
          </svg>
          <span>Details updated</span>
        </UpdatingLabel>
      )}
      <FormGrid columns={3} full>
        <FormGroup
          component="react-select"
          defaultValue={
            agencyUserPermission && {
              label: agencyUserPermission.organisationStaffGroup.title,
              value: agencyUserPermission.organisationStaffGroup.id,
            }
          }
          disabled={!!agencyUserPermission}
          label="Staff Group"
          name="staff_group_id"
          onChange={handleStaffGroupChange}
          options={organisationStaffGroups}
          placeholder="Select Staff Group"
        />
        {organisationAgencyCanProposeWorker(agencyUserPermission?.organisationStaffGroup.id) ? (
          <PermissionsContainer>
            <div>
              <input
                checked={inputs ? inputs.canProposeWorker : false}
                id={`propose-${index}`}
                name="canProposeWorker"
                onChange={() => {
                  setUpdated(true)
                  setLoading(true)
                  setUpdate(uuidv4())
                  setInputs((newInputs) => ({
                    ...newInputs,
                    canProposeWorker: !newInputs.canProposeWorker,
                  }))
                  setTimeout(() => {
                    setUpdated(false)
                    setLoading(false)
                  }, 2000)
                }}
                type="checkbox"
              />
              <label htmlFor={`propose-${index}`}>Can propose agency worker</label>
            </div>
          </PermissionsContainer>
        ) : (
          <div>
            <input name="canProposeWorker" type="hidden" value="false" />
          </div>
        )}

        <DeletePermissionsContainer>
          {agencyUserPermission && (
            <button onClick={() => deleteAgencyUserPermissionAction(agencyUserPermission.id)} type="button">
              Delete Permission
            </button>
          )}
        </DeletePermissionsContainer>
      </FormGrid>
    </AgencyUserPermissionContainer>
  )
}

export default AgencyUserPermissions

const AgencyUserPermissionContainer = styled.div`
  background: #f5f7fa;
  border: 1px dashed #cbd2d9;
  border-radius: 4px;
  margin-bottom: 10px;
  max-width: 840px;
  padding: 12px 16px 16px;
  position: relative;
  transition: all ease 0.25s;

  &.updated {
    background: rgba(125, 217, 98, 0.1);
    border-color: #7dd962;
  }
`

const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: max-content;

  input {
    margin-right: 8px;
    position: relative;
    top: 1px;
  }

  label {
    font-size: 15px;
    margin: 0;
  }
`

const UpdatingLabel = styled.div`
  align-items: center;
  background: rgba(125, 217, 98, 0.2);
  border-bottom: 1px dashed #7dd962;
  border-bottom-left-radius: 4px;
  border-left: 1px dashed #7dd962;
  display: flex;
  height: 32px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 150px;

  svg {
    margin-right: 8px;
  }

  span {
    color: #1e2d36;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
`

const DeletePermissionsContainer = styled.div`
  align-items: end;
  align-self: end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: end;

  button {
    background: transparent;
    border: 0;
    border-radius: 0;
    color: #fd6a6a;
    font-size: 14px;
    font-weight: 600;
    padding: 0;
    text-decoration: underline;
  }
`
