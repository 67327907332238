/* eslint-disable no-alert */
/* eslint-disable radix */
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class AgencyProposalsController extends Controller {
  static get targets() {
    return ['assignButton', 'authToken', 'shiftId', 'proposalId']
  }

  toggleShortlist(e) {
    // Access the data-value attribute
    const dataValue = e.currentTarget.getAttribute('data-value')

    // reject applicant function
    const query = ` mutation UpdateProposalShortlistingStatus($id: ID!, $className: String!) {
    updateProposalShortlistingStatus(id: $id, className: $className) {
      errors {
        attribute
        message
      }
      shortlistedAt
      success
    }
  }`

    // Perform the GraphQL mutation - Add icon toggle logic back in when ready
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id: dataValue, className: 'AgencyWorkerProposal' },
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        const isShortlisted = data.data.updateProposalShortlistingStatus.shortlistedAt
        const buttonElement = document.getElementById(`shortlist-${dataValue}`)

        // const shortlistIcon = document.getElementById(`shortlist__icon-${dataValue}`)
        // const shortlistedIcon = document.getElementById(`shortlisted__icon-${dataValue}`)

        // Update the button's text and class
        if (isShortlisted) {
          buttonElement.innerText = 'Shortlisted'
          buttonElement.classList.add('shortlisted')
          // shortlistIcon.classList.add('hide')
          // shortlistedIcon.classList.remove('hide')
        } else {
          buttonElement.innerText = 'Shortlist'
          buttonElement.classList.remove('shortlisted')
          // shortlistIcon.classList.remove('hide')
          // shortlistedIcon.classList.add('hide')
        }

        if (isShortlisted) {
          alert('Proposal Shortlisted')
        } else {
          alert('Removed Shortlisted Proposal')
        }
      })
      .catch((err) => {
        console.log('Fetch Error', err)
      })

    // You can now use the dataValue in your function as needed
  }

  rejectOverlay(e) {
    e.preventDefault()
    // get applicant id
    const rejectButton = e.srcElement
    const { value } = rejectButton.dataset

    // close all rejection overlays when a reject button is pressed
    const rejectOverlays = Array.from(document.querySelectorAll('.assign-a-worker__overlay'))
    rejectOverlays.forEach(function (rejectOverlay) {
      rejectOverlay.style.display = 'none'
    })

    // set low opacity and no pointer-events on applicant boxes
    const applicantBoxes = Array.from(document.querySelectorAll('.assign-a-worker'))
    applicantBoxes.forEach(function (applicantBox) {
      applicantBox.style.opacity = '0.2'
      applicantBox.style.pointerEvents = 'none'
    })

    // set high opacity and pointer-events on selected applicant box
    document.querySelector(`.assign-a-worker-${value}`).style.opacity = '1'
    document.querySelector(`.assign-a-worker-${value}`).style.pointerEvents = 'all'

    //  open overlay
    document.querySelector(`.assign-a-worker__overlay-${value}`).style.display = 'flex'
  }

  closeRejectOverlay(e) {
    e.preventDefault()
    // set opacity back on applicant boxes
    const applicantBoxes = Array.from(document.querySelectorAll('.assign-a-worker'))
    applicantBoxes.forEach(function (applicantBox) {
      applicantBox.style.opacity = '1'
      applicantBox.style.pointerEvents = 'all'
    })
    // set pointer-events back on applicant boxes
    const rejectOverlays = Array.from(document.querySelectorAll('.assign-a-worker__overlay'))
    rejectOverlays.forEach(function (rejectOverlay) {
      rejectOverlay.style.display = 'none'
    })
  }

  rejectApplicant(e) {
    e.preventDefault()
    const rejectButton = e.srcElement
    const { value } = rejectButton.dataset

    const id = Number(e.srcElement.id)

    const query = `mutation AgencyWorkerProposalDecline($id: ID!){
      agencyWorkerProposalDecline(id: $id) {
        success
        errors {
          attribute
          message
        }
      }
    }`

    // fetch call
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.data.agencyWorkerProposalDecline.success !== true) {
          alert('There was an error')
        } else {
          alert('Proposal Rejected')
          // Removes an element from the document
          const element = document.querySelector(`.assign-a-worker-${value}`)
          element.parentNode.removeChild(element)
          const applicantBoxes = Array.from(document.querySelectorAll('.assign-a-worker'))
          applicantBoxes.forEach(function (applicantBox) {
            applicantBox.style.opacity = '1'
            applicantBox.style.pointerEvents = 'all'
          })
          // set pointer-events back on applicant boxes
          const rejectOverlays = Array.from(document.querySelectorAll('.assign-a-worker__overlay'))
          rejectOverlays.forEach(function (rejectOverlay) {
            rejectOverlay.style.display = 'none'
          })
        }
      })
      .catch((err) => {
        console.log('Fetch Error', err)
      })
  }

  assignApplicant(e) {
    e.preventDefault()
    const selectButton = e.srcElement
    const selectBtns = Array.from(document.querySelectorAll('.applicant-btn'))
    selectBtns.forEach(function (selectBox, i) {
      if (selectBox.classList.contains('innertext--toggle')) {
        selectBox.innerText = 'Select'
        selectBox.classList.remove('innertext--toggle')
      }
    })

    const authoriserValue = selectButton.dataset.value
    document.getElementById('shift-proposal-id').value = authoriserValue

    if (
      selectButton.innerText === 'Select' ||
      selectButton.innerText === ' Select ' ||
      selectButton.innerText === ' Select'
    ) {
      selectButton.innerText = 'Selected'
      selectButton.classList.add('innertext--toggle')
    }
  }

  approveProposal(e) {
    e.preventDefault()

    const confirm = window.confirm('Are you sure you want to approve this proposal?')
    const id = Number(e.srcElement.id)

    if (!confirm) {
      return
    }

    const query = `mutation AgencyWorkerProposalAccept($id: ID!){
        agencyWorkerProposalAccept(id: $id) {
          success
          errors {
            attribute
            message
          }
        }
      }`
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { id },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.data.agencyWorkerProposalAccept.success !== true) {
          console.log('error')
        } else {
          window.location.reload(true)
        }
      })
  }
}

application.register('agency_proposals', AgencyProposalsController)
