import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class ReasonSelectorController extends Controller {

    static get targets() {
        return ['select']
    }

    connect() {
        const target = this.selectTarget;
        const reasonForBookingVal = document.querySelector('.modal__validation--reason');

        new SlimSelect({
            select: target,
            onChange: () => {
                reasonForBookingVal.style.display = "none";
            },
            showSearch: true
        })
    }
}

import { application } from '../../frontend/controllers/index'
application.register("reason_selector", ReasonSelectorController)