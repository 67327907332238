import React, { useState } from "react";
import styled from "styled-components";

import SkillsModal from "./SkillsModal";

const OrganisationStaffGroupsSkills = ({ organisation, skills }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <SkillsButton onClick={handleShow}>Skills</SkillsButton>
      {show && (
        <SkillsModal
          handleClose={handleClose}
          organisation={organisation}
          show={show}
          skills={skills}
        />
      )}
    </>
  );
};

export default OrganisationStaffGroupsSkills;

const SkillsButton = styled.button`
  background: transparent;
  border: 0;
  color: #12263f;
  line-height: 1;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0;
  text-decoration: underline;
`;
