import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class UserListItemController extends Controller {
    static get targets() {
        return ['userValue', 'authToken', 'workerName']
    }

    connect() {
        this.adjustText()
    }

    flipText() {
        this.visibleValue = !this.visibleValue
    }

    newText() {
        return this.visibleValue ? 'Selected' : 'Select'
    }

    adjustText() {
        if (this.hasUserValueTarget) {
            this.userValueTarget.innerText = this.newText()
        }
    }

    updateInput(event) {
        event.preventDefault()
        const { value } = this.userValueTarget.dataset
        const chooseWorker = document.querySelector('#assign-worker-id')
        chooseWorker.value = value
        this.flipText()
        this.adjustText()
    }

    // assignWorker(event) {
    //     event.preventDefault();
    //     const value = document.querySelector("#assign-worker-id").value
    //     const shiftId = this.data.get('shift-id')

    //     let assignWorker = document.createElement('form');
    //     assignWorker.method = 'post'
    //     if (shiftId) {
    //         assignWorker.setAttribute('action', `/shifts/${shiftId}/assign_worker?shift[worker_id]=${value}`);
    //         assignWorker.innerHTML += '<input name="_method" type="hidden" value="patch" />'
    //     }
    //     assignWorker.style.display = 'hidden';
    //     assignWorker.appendChild(this.authTokenTarget.cloneNode())
    //     document.body.appendChild(assignWorker)
    //     assignWorker.submit();
    // }
}
application.register('user_item_list', UserListItemController)
