import { Controller } from 'stimulus'

export default class PasswordInputController extends Controller {

    static get targets() {
        return ['input']
    }

    initialize() {
        this.isShown = false;
    }

    toggle(event) {
        if(this.isShown){
            this.hide()
        } else {
            this.show()
        }
        event.stopPropagation()
    }

    show() {
        this.inputTarget.type = 'text'
        this.isShown = true;
    }

    hide() {
        this.inputTarget.type = 'password'
        this.isShown = false;
    }
}

import {application} from '../../frontend/controllers/index'
application.register("password_input", PasswordInputController)