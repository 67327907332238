import { gql } from "@apollo/client";

export const GET_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
      numOfOrgStaffGroup
    }
  }
`;

export const CREATE_SKILL = gql`
  mutation CreateSkill($name: String!) {
    createSkill(name: $name) {
      errors {
        attribute
        message
      }
      skill {
        id
        name
      }
    }
  }
`;

export const UPDATE_SKILL = gql`
  mutation UpdateSkill($id: Int!, $name: String!) {
    updateSkill(id: $id, name: $name) {
      errors {
        attribute
        message
      }
      skill {
        id
        name
      }
    }
  }
`;

export const DELETE_SKILL = gql`
  mutation DeleteSkill($id: Int!) {
    deleteSkill(id: $id) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;
