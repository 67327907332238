/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-sort-props */
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import AmendAgencyTierModal from './AmendAgencyTierModal'
import { GET_SHIFT } from '../SendToAgency/SendToAgencyHelpers'

const AmendAgencyTier = () => {
  const [shiftId, setShiftId] = useState(null)
  const [show, setShow] = useState(false)

  const { data: shift } = useQuery(GET_SHIFT, {
    skip: !shiftId,
    variables: {
      id: Number(shiftId),
    },
  })

  const handleClose = () => {
    setShiftId(null)
    setShow(false)
  }

  const handleShow = () => setShow(true)

  const checkShifts = () => {
    const checkedShifts = document.querySelector('.list')
    const shifts = Array.from(checkedShifts.querySelectorAll('.custom-control-input:checked')).map((item) =>
      Number(item.value)
    )
    setShiftId(shifts[0])
    handleShow()
  }

  return (
    <>
      <button
        className="btn btn-white action-bar__drop-up-btn send-to-agency-vms"
        onClick={() => checkShifts()}
        data-target="action_bar.updateAgencyTier"
      >
        <svg width="14" height="18" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 8 }}>
          <path
            d="M11.443 2.53a1.84 1.84 0 011.837 1.837V16.94a.5.5 0 01-.5.5H9.535v-2.372a1.84 1.84 0 00-1.838-1.837H6.092a1.84 1.84 0 00-1.837 1.837v2.372H1.01a.5.5 0 01-.5-.5V4.367A1.84 1.84 0 012.347 2.53h9.096zm-3.746 11.7c.463 0 .838.376.838.838v2.372h-1.14v-3.21h.302zm-1.302 0v3.21h-1.14v-2.372a.84.84 0 01.837-.838h.303zm-2.71-3.14a.503.503 0 00-.5.5.502.502 0 00.5.5.503.503 0 00.5-.5.502.502 0 00-.5-.5zm2.14 0a.504.504 0 00-.5.5.503.503 0 00.5.5.503.503 0 00.5-.5.501.501 0 00-.5-.5zm2.14 0a.503.503 0 00-.5.5.503.503 0 00.5.5.503.503 0 00.5-.5.502.502 0 00-.5-.5zm2.14 0a.503.503 0 00-.5.5.502.502 0 00.5.5.503.503 0 00.5-.5.502.502 0 00-.5-.5zm-6.42-2.14a.502.502 0 00-.5.5.502.502 0 00.5.5.502.502 0 00.5-.5.503.503 0 00-.5-.5zm2.14 0a.503.503 0 00-.353.853.503.503 0 00.706 0 .503.503 0 000-.706.504.504 0 00-.353-.146zm2.14 0a.503.503 0 00-.5.5.503.503 0 00.5.5.502.502 0 00.5-.5.502.502 0 00-.5-.5zm2.14 0a.502.502 0 00-.5.5.502.502 0 00.5.5.502.502 0 00.5-.5.503.503 0 00-.5-.5zm-6.42-2.14a.503.503 0 00-.5.5.502.502 0 00.5.5.503.503 0 00.353-.853.504.504 0 00-.353-.147zm2.14 0a.503.503 0 00-.353.853.504.504 0 00.706 0 .503.503 0 000-.706.504.504 0 00-.353-.147zm2.14 0a.503.503 0 00-.5.5.503.503 0 00.5.5.503.503 0 00.5-.5.503.503 0 00-.5-.5zm2.14 0a.503.503 0 00-.5.5.502.502 0 00.5.5.503.503 0 00.353-.853.504.504 0 00-.353-.147zm-6.42-2.14a.503.503 0 00-.5.5.503.503 0 00.5.5.503.503 0 00.5-.5.502.502 0 00-.5-.5zm2.14 0a.502.502 0 00-.5.5.503.503 0 00.5.5.504.504 0 00.5-.5.503.503 0 00-.5-.5zm2.14 0a.502.502 0 00-.5.5.503.503 0 00.5.5.503.503 0 00.5-.5.503.503 0 00-.5-.5zm2.14 0a.501.501 0 00-.353.853.502.502 0 00.706 0 .503.503 0 000-.706.502.502 0 00-.353-.147zM9.303.39a1.84 1.84 0 011.7 1.141H2.787A1.84 1.84 0 014.488.39h4.815z"
            fill="#9CA8B4"
            fillRule="nonzero"
          />
        </svg>
        Update Agency Tier
      </button>
      {show && <AmendAgencyTierModal handleClose={handleClose} show={show} shift={shift} />}
    </>
  )
}

export default AmendAgencyTier
