/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client'

export const statuses = [
  {
    label: 'Sign off requested',
    value: 'SIGN_OFF_REQUESTED',
  },
  {
    label: 'Signed off',
    value: 'SIGNED_OFF',
  },

  {
    label: 'Pending approval',
    value: 'PENDING_APPROVAL',
  },

  {
    label: 'Approved for payment',
    value: 'APPROVED_FOR_PAYMENT',
  },

  {
    label: 'Paid',
    value: 'PAID',
  },
]

export const PAID_PER_ITEM_RECEIPTS = gql`
  query paidPerItemReceipts(
    $departmentIds: [Int!]
    $gradeIds: [Int!]
    $itemIds: [Int!]
    $siteIds: [Int!]
    $items: Int
    $page: Int
    $status: [ReceiptStatusEnum!]
    $workerIds: [Int!]
    $fromDate: Date
    $toDate: Date
    $ids: [Int!]
  ) {
    paidPerItemReceipts(
      departmentIds: $departmentIds
      gradeIds: $gradeIds
      itemIds: $itemIds
      siteIds: $siteIds
      items: $items
      page: $page
      status: $status
      workerIds: $workerIds
      fromDate: $fromDate
      toDate: $toDate
      ids: $ids
    ) {
      list {
        id
        createdAt
        dateCompleted
        site {
          id
          name
        }
        grade {
          id
          title
        }
        status
        totalCost
        department {
          id
          name
          site {
            name
          }
        }
        organisation {
          id
        }
        receiptItems {
          id
          count
          totalCost
          itemGradeCost {
            id
            item {
              id
              name
            }
          }
        }
        worker {
          id
          fullName
        }
      }
      pagination {
        count
        last
        next
        page
        pages
        prev
      }
    }
  }
`

export const PAID_PER_ITEM_RECEIPTS_FILTERS = gql`
  query paidPerItemReceiptsFilters {
    paidPerItemReceiptsFilters {
      itemFilterOptions {
        id
        name
      }
    }
  }
`

export const GET_RECEIPT = gql`
  query PaidPerItemReceipt($receiptId: Int!) {
    paidPerItemReceipt(receiptId: $receiptId) {
      id
      createdAt
      dateCompleted
      totalCost
      department {
        name
      }
      grade {
        id
        title
      }
      organisation {
        name
      }
      worker {
        firstName
        fullName
        grades {
          id
          title
          colour
        }
        phoneNumber
        email
      }
      status
      ppiAuditEvents {
        createdAt
        eventType
        userDetails
        description
      }
      receiptItems {
        id
        count
        itemCost
        itemCostWithWtd
        totalCost
        wtdPercentage
        costCentre
        itemGradeCost {
          item {
            id
            name
            department {
              id
              name
              costCentre {
                code
                id
              }
              associatedCostCentres {
                code
                id
              }
            }
          }
        }
      }
    }
  }
`

export const SIGN_OFF_RECEIPT = gql`
  mutation SignOffReceipt($receiptId: Int!) {
    paidPerItemSignOffReceipt(receiptId: $receiptId) {
      success
      errors {
        attribute
        message
      }
    }
  }
`

export const UPDATE_RECEIPT = gql`
  mutation UpdateReceipt($id: Int!, $receiptItemsAttributes: [ReceiptItemInput!]!) {
    paidPerItemUpdateReceipt(id: $id, receiptItemsAttributes: $receiptItemsAttributes) {
      receipt {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`

export const COST_CENTRES = gql`
  query costCentres {
    costCentres {
      nodes {
        value: id
        label: code
      }
    }
  }
`

export const BULK_APPROVE_FOR_PAYMENT = gql`
  mutation ReceiptsBulkApproveForPayment($receiptIds: [Int!]!) {
    paidPerItemReceiptsBulkApproveForPayment(receiptIds: $receiptIds) {
      success
      errors {
        attribute
        message
      }
    }
  }
`
