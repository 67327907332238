import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import AppContext from "../../../../../Context/AppContext";
import ShiftModalWorkerCard from '../../ShiftModalWorkerCard'
import { ErrorContainer, ShiftModalButton, ShiftModalDetailGroup, ShiftModalFormGrid, ShiftModalFormGroup } from '../../ShiftModalComponents'

import { cancelShift } from '../../../../../Helpers/ShiftHelpers'

const ShiftModalCancel = ({ fetchShift, fetchShifts, setSelectedTab, setShiftLoading, shift }) => {
  const [error, setError] = useState({})
  const { organisationReasons } = useContext(AppContext);

  // remap org reasons into an array react-select can read
  const reasons = organisationReasons.map(function (reason) {
    return { value: reason.id, label: reason.reason }
  })

  const [inputs, setInputs] = useState({
    reason_for_cancellation_id: "",
    comments: "",
  })

  const handleReasonChange = (event) => {
    setError({});
    setInputs(inputs => ({ ...inputs, reason_for_cancellation_id: event.value }));
  }

  const handleCommentsChange = (event) => {
    event.persist();
    setError({});
    setInputs(inputs => ({ ...inputs, comments: event.target.value }));
  }

  const body = {
    shift: {
      reason_for_cancellation_id: inputs.reason_for_cancellation_id,
      comments: inputs.comments
    },
    shift_ids: [shift.id]
  }

  const cancelShiftAction = (e, shiftId) => {
    if (inputs.reason_for_cancellation_id === "" || inputs.comments === "") {
      if (inputs.reason_for_cancellation_id === "") {
        setError({ ...error, reason: true })
      }
      else if (inputs.comments === "") {
        setError({ ...error, comments: true })
      }
    } else {
      setShiftLoading(true);
      e.preventDefault()
      cancelShift(body).then((data) => {
        if (data !== "err") {
          fetchShift(shiftId);
          setTimeout(() => {
            setSelectedTab("details");
            setShiftLoading(false);
            fetchShifts();
          }, 300)
        } else {
          setShiftLoading(false);
          setError({ ...error, cancel: true })
        }
      })
    }
  }

  return (
    <ShiftModalCancelGroupWorkerGrid>
      <div>
        <ShiftModalDetailGroup>
          <h2>Cancellation</h2>
          {(error && error.cancel) &&
            <ErrorContainer><p>There was a problem cancelling the booking, please try again.</p></ErrorContainer>
          }
          <ShiftModalFormGrid columns={1}>
            <ShiftModalFormGroup
              component={"react-select"}
              label={"Reason for Cancelling"}
              options={reasons}
              placeholder="Please select a cancellation reason"
              name="reason_for_cancellation_id"
              onChange={handleReasonChange}
              required
              error={error.reason && "Please select a cancellation reason."}
            />
          </ShiftModalFormGrid>
          <ShiftModalFormGrid columns={1}>
            <ShiftModalFormGroup
              component={"textarea"}
              label={"Comments"}
              name="comments"
              onChange={handleCommentsChange}
              placeholder={"Leave helpful notes for to show why the shift was cancelled."}
              type="textarea"
              required
              error={error.comments && "Please leave a note to show why the shift was cancelled."}
            />
          </ShiftModalFormGrid>
          <ShiftModalFormGrid columns={1}>
            <ShiftModalButton theme={'red'} onClick={(e) => cancelShiftAction(e, shift.id)}>Cancel Booking</ShiftModalButton>
          </ShiftModalFormGrid>
        </ShiftModalDetailGroup>
      </div>
      <div>
        {shift.bookedBy &&
          <ShiftModalWorkerCard shift={shift} />
        }
      </div>
    </ShiftModalCancelGroupWorkerGrid>

  )
}

export default ShiftModalCancel

const ShiftModalCancelGroupWorkerGrid = styled.div`
  display: grid;
  grid-gap: 90px;
  grid-template-columns: 1fr 250px;
`