import { useQuery } from '@apollo/client'
import { TableContainer } from '@patchworkhealth/web-components'
import React from 'react'
import styled from 'styled-components'
import { formatTimeInTZ } from '../../../Helpers/momentHelpers'
import { SHIFT_BLOCK_AUDIT } from '../helpers/BlockBookingsHelpers'

function ViewBlockAudit({ block }) {
  // State **************************************

  const { data } = useQuery(SHIFT_BLOCK_AUDIT, {
    fetchPolicy: 'no-cache',
    skip: !block.id,
    variables: {
      shiftBlockIds: [Number(block.id)],
    },
  })

  const auditEvents = data?.shiftBlocks?.nodes[0]?.shiftBlockAuditEvents?.nodes ?? []

  return (
    <TableContainer style={{ width: '100%' }}>
      <thead data-cy="shift-table-header">
        <tr>
          <th>Date & Time</th>

          <th>Event Type</th>
          <th>User</th>
        </tr>
      </thead>
      <tbody data-testid="shifts">
        {auditEvents.map((row) => {
          return (
            <tr key={row?.id}>
              <TableCustomRow>
                <p>
                  <strong>{formatTimeInTZ(row?.createdAt, 'h:mm A')}</strong>
                </p>
                <p>
                  <strong>{formatTimeInTZ(row?.createdAt, 'DD MMM YYYY')}</strong>
                </p>
              </TableCustomRow>

              {row?.event !== 'Block - Shifts approved' && row?.event !== 'Block - Shifts signed off' && (
                <TableCustomRow>
                  <p>
                    <strong>{row.event}</strong>
                  </p>
                </TableCustomRow>
              )}

              {row?.event === 'Block - Shifts approved' && (
                <TableCustomRow>
                  <p>
                    <strong>Shifts Approved</strong>
                  </p>
                  <p>{row?.changesData?.shift_ids?.map((i) => `${i}, `)}</p>
                </TableCustomRow>
              )}

              {row?.event === 'Block - Shifts signed off' && (
                <TableCustomRow>
                  <p>
                    <strong>Shifts Signed Off</strong>
                  </p>
                  <p>{row?.changesData?.shift_ids?.map((i) => `${i}, `)}</p>
                </TableCustomRow>
              )}

              <TableCustomRow>
                <p>
                  <strong>
                    {row?.currentUser?.firstName} - {row?.currentUser?.lastName}
                  </strong>
                </p>
                <p>{row?.currentUser?.userType}</p>
              </TableCustomRow>
            </tr>
          )
        })}
      </tbody>
    </TableContainer>
  )
}

export default ViewBlockAudit

const TableCustomRow = styled.td`
  p {
    font-size: 14px;
    margin: 0;
  }
`
