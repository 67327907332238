import {
  DefaultButton,
  Loading,
  CloseButton,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@patchworkhealth/web-components'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { parseTimeInTZ, formatTimeInTZ } from '../../Helpers/momentHelpers'
import {
  DefaultShift,
  FilledShift,
  MultiShift,
  ThreeShift,
  UnfilledShift,
  TableHeaderCheckbox,
  TableCell,
} from './helpers/BlockComponents'
import { PublishBlockIcon } from './helpers/BlockIcons'
import { HelpIcon } from './Modals/CreateBlockModal'

function BlockTable({
  addShift,
  deselectAllShifts,
  inputs,
  isShiftSelected,
  publishBlock,
  saveChanges,
  selectAllShifts,
  selectedShifts,
  toggleShiftSelection,
  selectWeeklyShifts,
  //
  activeWeek,
  setActiveWeek,
}) {
  const { calendarArray, calendarDetails } = inputs

  const [show, setShow] = useState(false)
  const [massShiftSelectionLabel, setMassShiftSelectionLabel] = useState()

  useEffect(() => {
    let selectionVerb = 'Select'

    if (calendarDetails?.shifts?.length === selectedShifts.length) {
      selectionVerb = 'Deselect'
    }

    setMassShiftSelectionLabel(`${selectionVerb} all shifts`)
  }, [selectedShifts])

  if (calendarDetails === null) {
    return (
      <div style={{ paddingTop: 50 }}>
        <Loading />
      </div>
    )
  }

  const modBudget = calendarDetails?.modApprovalBudgetValue

  const checkCompliance = () => {
    let ans = true

    if (!modBudget) {
      return true
    }
    calendarArray.forEach((cc) => {
      if (cc.totalHours > modBudget) {
        ans = false
      }
    })

    if (!ans) {
      toast.error(`Block exceeds Weekly Approved Hours`, { hideProgressBar: true, position: 'top-right' })
    }
    return ans
  }

  const massShiftSelectionHandler = () => {
    if (calendarDetails.shifts.length === selectedShifts.length) {
      return deselectAllShifts()
    }

    return selectAllShifts(calendarDetails.shifts.map((cc) => cc.id))
  }

  const toggleWeekHandler = (index) => {
    const newArray = activeWeek.slice()
    newArray[index - 1] = !newArray[index - 1]
    setActiveWeek(newArray)

    const selectedShifts1 = calendarDetails.shifts
      .filter((shift) => parseTimeInTZ(shift.proposedStartTime).weekday() === index)
      .map((row) => row.id)

    selectWeeklyShifts(selectedShifts1)
  }

  return (
    <>
      <BlockTableButtons>
        <h2>
          {formatTimeInTZ(calendarDetails?.startDate, 'Do MMM YYYY')} -{' '}
          {formatTimeInTZ(calendarDetails?.endDate, 'Do MMM YYYY')}
        </h2>

        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
          {calendarDetails?.status === 'DRAFT' && (
            <>
              <DefaultButton
                action={() => {
                  const ans = checkCompliance()
                  if (ans) saveChanges()
                }}
                text="Return"
                type="white"
              />

              <button
                className="btn btn-cyan"
                onClick={() => {
                  const ans = checkCompliance()
                  if (ans) publishBlock(calendarDetails?.id)
                }}
                style={{ cursor: 'pointer' }}
                type="button"
              >
                <PublishBlockIcon />
                Publish Block
              </button>

              {modBudget && (
                <DefaultButton onMouseEnter={() => setShow(true)} text="" type="white">
                  <HelpIcon />
                </DefaultButton>
              )}
            </>
          )}

          {calendarDetails?.status !== 'DRAFT' && (
            <button
              className="btn btn-cyan"
              onClick={() => {
                const ans = checkCompliance()
                if (ans) saveChanges()
              }}
              style={{ cursor: 'pointer' }}
              type="button"
            >
              <PublishBlockIcon />
              Save Changes
            </button>
          )}
        </div>
      </BlockTableButtons>

      <BlockBody>
        <TableHeader>
          <TableCell color="dark">Week</TableCell>
          <TableHeaderCheckbox checked={activeWeek[0]} day="Mon" index={1} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[1]} day="Tue" index={2} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[2]} day="Wed" index={3} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[3]} day="Thu" index={4} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[4]} day="Fri" index={5} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[5]} day="Sat" index={6} toggleWeek={toggleWeekHandler} />
          <TableHeaderCheckbox checked={activeWeek[6]} day="Sun" index={7} toggleWeek={toggleWeekHandler} />
        </TableHeader>

        {calendarArray.length > 0 &&
          calendarArray.map((row) => {
            return (
              <TableRow key={row.startWeekOfFirstShift}>
                <TableCell breach={modBudget && row.totalHours > modBudget} color="dark">
                  {row.totalHours > 0 && <TotalHours>{row.totalHours.toFixed(1)} Hrs</TotalHours>}

                  {modBudget && row.totalHours > modBudget && <BreachIcon />}

                  <p>
                    <abbr title="Week commencing">w/c</abbr>
                    {formatTimeInTZ(row.startWeekOfFirstShift, 'DD')}{' '}
                  </p>
                  <span> {formatTimeInTZ(row.startWeekOfFirstShift, 'MMM')} </span>
                </TableCell>
                {row.days.map((day) => {
                  if (
                    !parseTimeInTZ(day.date).isBetween(calendarDetails.startDate, calendarDetails.endDate, 'days', '[]')
                  ) {
                    return <DefaultShift key={day.date} />
                  }

                  if (day?.shifts?.length === 0) {
                    return (
                      <UnfilledShift key={day.date} addShift={addShift} day={day} status={calendarDetails?.status} />
                    )
                  }

                  if (day?.shifts?.length === 1) {
                    return (
                      <FilledShift
                        key={day.date}
                        day={day}
                        shiftSelected={isShiftSelected}
                        toggleShiftSelection={toggleShiftSelection}
                      />
                    )
                  }

                  if (day?.shifts?.length === 2) {
                    return (
                      <MultiShift
                        key={day.date}
                        day={day}
                        selectedShifts={selectedShifts}
                        shiftSelected={isShiftSelected}
                        toggleShiftSelection={toggleShiftSelection}
                      />
                    )
                  }

                  if (day?.shifts?.length > 2) {
                    return <ThreeShift key={day.date} day={day} />
                  }

                  return null
                })}
              </TableRow>
            )
          })}

        {calendarDetails?.shifts.length > 1 && (
          <DefaultButton
            action={massShiftSelectionHandler}
            style={{ cursor: 'pointer', marginTop: 23 }}
            text={massShiftSelectionLabel}
            type="grey"
          />
        )}
      </BlockBody>

      {modBudget && (
        <Modal backdrop="static" centered onHide={() => setShow(false)} show={show} size="lg">
          <ModalHeader>
            <ModalTitle>MOD Tutorial</ModalTitle>
            <CloseButton onClick={() => setShow(false)} />
          </ModalHeader>
          <ModalBody>
            <p>
              Don’t forget to assign a worker if you know the worker you want for this block. The video below outlines
              this process.
            </p>

            <iframe
              allowFullScreen
              frameBorder="0"
              mozallowfullscreen
              msallowfullscreen
              oallowfullscreen
              src="https://www.loom.com/embed/3d6509ecd6174c8893f65cd1a0a1820f"
              style={{ width: '100%', height: '400px' }}
              title="ModVideo"
              webkitallowfullscreen
            />
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

export default BlockTable

// Wrappers ************************************

const BlockBody = styled.div`
  background: #f9fbfd;
  height: auto;
  height: auto;
  margin: 0px auto 120px;
  max-width: 1110px;
  position: relative;
  width: 100%;
  width: 1098px;
  z-index: 20;

  @media (max-width: 1300px) {
    overflow-x: scroll;
    h2 {
      margin-top: 30px;
    }
  }
`

// Table **************************************

const TotalHours = styled.h6`
  color: 'white';
  font-size: 9px;
  left: 4px;
  position: absolute;
  top: 4px;
`

const TableHeader = styled.div`
  display: flex;
`

const TableRow = styled.div`
  display: flex;
`

const BlockTableButtons = styled.div`
  align-items: center;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: -120px auto 22px auto;
  max-width: 1110px;
  width: 100%;
  button {
    margin-right: 10px;
  }
`

function BreachIcon() {
  return (
    <svg fill="none" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="8" fill="white" r="8" />
      <path
        d="M7.12313 3.21015L2.77954 10.4614C2.68998 10.6165 2.6426 10.7923 2.64209 10.9714C2.64159 11.1505 2.68799 11.3266 2.77668 11.4822C2.86536 11.6378 2.99324 11.7675 3.14759 11.8583C3.30194 11.9491 3.47738 11.9979 3.65646 11.9999H12.3436C12.5227 11.9979 12.6982 11.9491 12.8525 11.8583C13.0069 11.7675 13.1347 11.6378 13.2234 11.4822C13.3121 11.3266 13.3585 11.1505 13.358 10.9714C13.3575 10.7923 13.3101 10.6165 13.2206 10.4614L8.87697 3.21015C8.78555 3.05943 8.65683 2.93482 8.50323 2.84834C8.34962 2.76186 8.17632 2.71643 8.00005 2.71643C7.82377 2.71643 7.65047 2.76186 7.49687 2.84834C7.34327 2.93482 7.21455 3.05943 7.12313 3.21015Z"
        fill="#DC5A54"
      />
      <path
        clipRule="evenodd"
        d="M8.51282 5.84607C8.51282 5.56285 8.28323 5.33325 8 5.33325C7.71678 5.33325 7.48718 5.56285 7.48718 5.84607V7.89735C7.48718 8.18058 7.71678 8.41017 8 8.41017C8.28323 8.41017 8.51282 8.18058 8.51282 7.89735V5.84607ZM8 9.43582C7.71678 9.43582 7.48718 9.66541 7.48718 9.94864C7.48718 10.2319 7.71678 10.4615 8 10.4615H8.00513C8.28835 10.4615 8.51795 10.2319 8.51795 9.94864C8.51795 9.66541 8.28835 9.43582 8.00513 9.43582H8Z"
        fill="white"
        fillRule="evenodd"
      />
    </svg>
  )
}
