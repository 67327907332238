import { gql } from '@apollo/client'

export const AGENCY_WORKER_PROPOSALS = gql`
  query getShifts($id: Int!) {
    shift(id: $id) {
      id
      endTime
      grade {
        id
        title
      }
      agencyWorkerProposals {
        nodes {
          id
          shortlistedAt
          coreTotalRate
          agencyUser {
            agency {
              title
            }
          }
          rates {
            id
            hours
            name
            workerRate
            niContribution
            agencyMargin
            agencyChargeIncVat
            comparatorRate
            comparatorRateIncVat
            initialRates {
              comparatorRate
            }
          }
          agencyWorkerDocs {
            id
            agencyRegistrationId
            content
            createdAt
            updatedAt
            fileUrls
            categoryType
          }
          agencyWorker {
            id
            isSoleTrader
            isVatRegistered
            staffGroup {
              idValue
              title
            }
            lastName
            firstName
            grade {
              id
              title
            }
            paymentType
            employmentType
            compliancePackUrl
            cvUrl
            email
            niNumber
            regBodyNumber
          }
        }
      }
    }
  }
`

export const DE_CONTRACTS = gql`
  query deContractsContracts($status: Int, $paymentType: Int, $staffGroupId: Int) {
    deContractsContracts(status: $status, paymentType: $paymentType, staffGroupId: $staffGroupId) {
      list {
        id
        name
        content
        contractType
        activeVersion {
          id
        }
      }
    }
  }
`

export const AGENCY_WORKER_PROPOSAL_ACCEPT = gql`
  mutation AgencyWorkerProposalAccept($id: ID!, $deContractVersionId: Int) {
    agencyWorkerProposalAccept(id: $id, deContractVersionId: $deContractVersionId) {
      success
      errors {
        attribute
        message
      }
    }
  }
`

export const AGENCY_WORKER_PROPOSAL_REJECT = gql`
  mutation AgencyWorkerProposalDecline($id: ID!, $reasonForRejection: String) {
    agencyWorkerProposalDecline(id: $id, reasonForRejection: $reasonForRejection) {
      success
      errors {
        attribute
        message
      }
    }
  }
`

export const UPDATE_PROPOSAL_SHORTLISTING_STATUS = gql`
  mutation UpdateProposalShortlistingStatus($id: ID!, $className: String!) {
    updateProposalShortlistingStatus(id: $id, className: $className) {
      errors {
        attribute
        message
      }
      shortlistedAt
      success
    }
  }
`

// Export Doc ---------------------------------------------------------

export const AGENCY_WORKER_DOCS_EXPORT_DOCUMENTATIONS_UPLOADS = gql`
  mutation agencyWorkerDocsExportDocumentationsUploads($agencyRegistrationId: Int!) {
    agencyWorkerDocsExportDocumentationsUploads(agencyRegistrationId: $agencyRegistrationId) {
      errors {
        attribute
        message
      }
      success
      zipString
    }
  }
`
