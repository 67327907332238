/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { lazy, useState, Suspense } from 'react'
import 'react-day-picker/lib/style.css'
import { toast } from 'react-toastify'
import {
  SHIFT_BLOCK_TIMESHEETS,
  SHIFT_BLOCK_TIMESHEET,
  AUTHORISERS_QUERY,
  successToast,
  loadingToast,
  errorToast,
  SHIFT_BULK_APPROVE,
  HUB_USER_SIGN_OFF_WITH_TIMES_AMENDED_MULTIPLE,
} from '../helpers/BlockBookingsHelpers'
import {
  SignOffContainer,
  TimesheetsTable,
  TimesheetsTableHeader,
  TimesheetTabs,
  TabButton,
  TimesheetPermissions,
} from './TimesheetHelpers'
import { TimesheetsApproveModal, TimesheetsSignOffModal } from './TimesheetModals'

const TimesheetsTableOne = lazy(() => import('./TimesheetsTableOne'))

const initialInputs = {
  activeTimesheet: [],
  activeTimesheets: [],
  inProgressTimesheets: [],
  bookedTimesheets: [],
  historicTimesheets: [],
  //
  tab: 1,
  expandedRow: null,
  editShift: null,
  //
  showApprovalModal: false,
  showSignOffModal: false,
  approverNote: '',
  authoriserValue: null,
  skip: true,
  //
  modDeclarationStatement: false,
}

function ViewBlockTimesheets({ block, refetch, ts, modOrganisation }) {
  /* State ------------------------------------------------------------ */

  const [inputs, setInputs] = useState(initialInputs)

  const { canApproveShifts, canManuallySignOff, canOverrideBreakDuration } = ts
  const authId = block.departments && block.departments[0] ? block.departments[0].id : 0

  const { activeTimesheets, activeTimesheet, expandedRow } = inputs

  const requestedShifts = activeTimesheet?.filter((s) => s.status === 'SIGN_OFF_REQUESTED')
  const approvedShifts = activeTimesheet?.filter((s) => s.status === 'SIGNED_OFF')
  const expectedHours = activeTimesheets?.filter((s) => s.id === expandedRow)[0]?.hoursExpected

  /* Mutations -------------------------------------------------- */

  const [bulkApprovefMutation] = useMutation(SHIFT_BULK_APPROVE)
  const [bulkSignoffMutation] = useMutation(HUB_USER_SIGN_OFF_WITH_TIMES_AMENDED_MULTIPLE)

  /* Queries ---------------------------------------------------- */

  const { loading, refetch: refetchTimesheets } = useQuery(SHIFT_BLOCK_TIMESHEETS, {
    skip: !block?.id,
    fetchPolicy: 'no-cache',
    variables: {
      shiftBlockId: Number(block.id),
    },
    onCompleted: ({ shiftBlockTimesheets }) => {
      const active = shiftBlockTimesheets.filter((e) => e.status === 'SIGN_OFF_REQUESTED' || e.status === 'SIGNED_OFF')
      const inProgressTimesheets = shiftBlockTimesheets.filter((e) => e.status === 'IN_PROGRESS')
      const bookedTimesheets = shiftBlockTimesheets.filter((e) => e.status === 'BOOKED')
      const historicTimesheets = shiftBlockTimesheets.filter((e) => e.status === 'PAYROLL' || e.status === 'APPROVED')

      setInputs({
        ...inputs,
        activeTimesheets: active,
        inProgressTimesheets,
        bookedTimesheets,
        historicTimesheets,
      })
    },
  })

  const { refetch: refetchTimesheet } = useQuery(SHIFT_BLOCK_TIMESHEET, {
    skip: inputs.skip,
    fetchPolicy: 'no-cache',
    variables: {
      shiftBlockId: Number(block.id),
      id: Number(inputs.expandedRow),
    },
    onCompleted: ({ shiftBlockTimesheets }) => {
      setInputs({
        ...inputs,
        skip: inputs.skip,
        activeTimesheet: shiftBlockTimesheets[0]?.shifts?.map((cc) => ({
          ...cc,
          ...(cc.workerSignOff && {
            workerSignOff: {
              ...cc.workerSignOff,
              startTimeDiff: moment(cc?.workerSignOff?.reportedStartTime).diff(moment(cc.startTime), 'minutes'),
              endTimeDiff: moment(cc?.workerSignOff?.reportedEndTime).diff(moment(cc.endTime), 'minutes'),
              breaksDiff: Number(cc?.workerSignOff?.reportedBreaks) - cc.breakMinutes,
              initialStartTime: cc?.workerSignOff?.reportedStartTime,
              initialEndTime: cc?.workerSignOff?.reportedEndTime,
              initialBreaks: cc?.workerSignOff?.reportedBreaks,
              breaksExceptionNotes: cc?.breaksExceptionNotes,
              confirmationNotes: cc?.workerSignOff?.confirmationNotes,
              reset: false,
              reason: '',
            },
          }),
        })),
      })
    },
  })

  const { data: authoriser } = useQuery(AUTHORISERS_QUERY, {
    variables: {
      id: authId,
    },
  })

  /* SignOff Permissions -------------------------------------------------- */

  const checkSignOffPermissions = () => {
    if (!canManuallySignOff) {
      toast.error('You do not have Signoff Permissions', {
        hideProgressBar: true,
        position: 'top-right',
      })
      return false
    }
    return true
  }

  const checkAuthoriser = () => {
    if (inputs.authoriserValue === null) {
      toast.error('Please Select an Authoriser before Signing Off', {
        hideProgressBar: true,
        position: 'top-right',
      })
      return false
    }
    return true
  }

  /* Signoff Mutation -------------------------------------------------- */

  const signOffHandler = async () => {
    if (!checkSignOffPermissions()) return
    if (!checkAuthoriser()) return

    const variables = requestedShifts.map((s) => ({
      shiftId: s.id,
      breaksExceptionNotes: s.breaksExceptionNotes,
      confirmationNotes: s.workerSignOff.confirmationNotes,
      confirmedBreaks: Number(s?.workerSignOff?.reportedBreaks),
      confirmedEndTime: s?.workerSignOff?.reportedEndTime,
      confirmedStartTime: s?.workerSignOff?.reportedStartTime,
      signOffAuthoriserId: inputs.authoriserValue?.value,
    }))

    toast.loading('Loading', loadingToast)

    try {
      const { data: result } = await bulkSignoffMutation({
        variables: {
          shifts: variables,
        },
      })

      const errors = result?.hubUserSignOffWithTimesAmendedMultiple?.errors

      if (errors.length > 0) {
        toast.update(2, {
          ...errorToast,
          render: 'Could not Signoff Shifts',
        })
        return
      }

      toast.update(2, { ...successToast, render: `${requestedShifts.length} Shift(s) Signed Off` })
      refetch()
      refetchTimesheet()
      refetchTimesheets()

      setInputs({
        ...inputs,
        authoriserValue: null,
        showSignOffModal: false,
        editShift: null,
      })
    } catch (error) {
      toast.update(2, {
        ...errorToast,
        render: 'Shifts Could not Be Signed Off',
      })
    }
  }

  /* Approve Mutation -------------------------------------------------- */

  const approveHandler = () => {
    if (!canApproveShifts) {
      toast.error('You do not have Approval Permissions', {
        hideProgressBar: true,
        position: 'top-right',
      })
      return
    }

    if (approvedShifts.length === 0) {
      toast.error('No Shifts to Approve', {
        hideProgressBar: true,
        position: 'top-right',
      })
      return
    }

    toast.loading('Loading', loadingToast)

    bulkApprovefMutation({
      variables: {
        shiftIds: approvedShifts.map((s) => s.id),
        forTimesheet: true,
        paymentApproverNotes: inputs.approverNote,
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBulkApprove?.errors

        if (errors.length > 0) {
          toast.update(2, {
            ...errorToast,
            render: 'Booking could not be Approve',
          })
          return
        }

        toast.update(2, { ...successToast, render: 'Shift(s) Approved' })
        refetch()
        refetchTimesheet()
        refetchTimesheets()

        setInputs({
          ...inputs,
          approverNote: '',
          showApprovalModal: false,
          tab: 4,
        })
      })
      .catch(() => {
        toast.update(2, {
          ...errorToast,
          render: 'Shifts Could not Be Approved',
        })
      })
  }

  /* Render Tabs -------------------------------------------------- */

  const getTableTimesheets = (tab) => {
    const timesheetsMap = {
      1: inputs.activeTimesheets,
      2: inputs.inProgressTimesheets,
      3: inputs.bookedTimesheets,
      4: inputs.historicTimesheets,
    }
    return timesheetsMap[tab]
  }

  if (loading) {
    return <div>Loading...</div>
  }

  // const url = window.location.href
  // const shouldRenderComponent = url.includes('qa')

  // if (shouldRenderComponent) {
  //   return <h2>Timesheets disabled on Feature env, please contact patchwork if you need it enabled.</h2>
  // }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SignOffContainer>
        {/* Timesheet Tabs ----------------------------------------------- */}
        <TimesheetTabs>
          {['Active', 'Awaiting', 'Upcoming', 'Historical'].map((status, index) => (
            <TabButton
              key={status}
              active={inputs.tab === index + 1}
              onClick={() => {
                setInputs({
                  ...inputs,
                  tab: index + 1,
                })
              }}
              type="button"
            >
              {status}
            </TabButton>
          ))}
        </TimesheetTabs>

        {/* Timesheet Table -------------------------------------------- */}
        <TimesheetsTable>
          <TimesheetsTableHeader>
            <div>Week</div>
            <div>Hrs Per Week</div>
            <div>Hrs Worked</div>
            <div>Status</div>
            <div />
          </TimesheetsTableHeader>
          <div>
            {/* Render All Different Types of Times ----------------------- */}

            {/* <Suspense fallback={<div>Loading...</div>}> */}
            <TimesheetsTableOne
              canOverrideBreakDuration={canOverrideBreakDuration}
              inputs={inputs}
              setInputs={setInputs}
              timesheets={getTableTimesheets(inputs.tab)}
            />
            {/* </Suspense> */}
            {/* End ------------------------------------------------------ */}

            {inputs.showApprovalModal && (
              <TimesheetsApproveModal
                approveHandler={approveHandler}
                approvedShifts={approvedShifts}
                inputs={inputs}
                setInputs={setInputs}
              />
            )}

            {inputs.showSignOffModal && (
              <TimesheetsSignOffModal
                authoriser={authoriser}
                expectedHours={expectedHours}
                inputs={inputs}
                modOrganisation={modOrganisation}
                requestedShifts={requestedShifts}
                setInputs={setInputs}
                signOffHandler={signOffHandler}
              />
            )}
          </div>
        </TimesheetsTable>

        <TimesheetPermissions>
          <p>canApproveShifts: {canApproveShifts ? 'true' : 'false'}</p>
          <p>canManuallySignOff: {canManuallySignOff ? 'true' : 'false'}</p>
          <p>canOverrideBreakDuration: {canOverrideBreakDuration ? 'true' : 'false'}</p>
        </TimesheetPermissions>
      </SignOffContainer>
    </Suspense>
  )
}

export default ViewBlockTimesheets
