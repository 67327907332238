import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const ShiftModalWorkerCard = ({ shift }) => {
  return (
    <WorkerProfileContainer>
      <WorkerProfileDetails>
        <WorkerProfileDetailsUser>
          <WorkerProfileAvatar />
          <span>{shift.bookedBy.firstName} {shift.bookedBy.lastName}</span>
        </WorkerProfileDetailsUser>
        {shift.userBookedShift &&
          <WorkerProfileDetailsApproved>
            <span>Approved by</span>
            <p>{shift.userBookedShift.approver?.fullName}</p>
            <span><em>{moment(shift.userBookedShift?.approvedAt).fromNow()}</em></span>
          </WorkerProfileDetailsApproved>
        }
      </WorkerProfileDetails>
      {/* <WorkerProfileFooter>Contact Details</WorkerProfileFooter> */}
    </WorkerProfileContainer>
  )
}

export default ShiftModalWorkerCard

const WorkerProfileContainer = styled.div`
  background: #F5F7FA;
  border: 1px solid #CBD2D9;
  border-radius: 5px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
`

const WorkerProfileDetails = styled.div`
  padding: 20px;
`

const WorkerProfileAvatar = styled.div`
  background: #eee;
  border-radius: 30px;
  height: 60px;
  margin-bottom: 8px;
  width: 60px;
`

const WorkerProfileFooter = styled.div`
  background: #ECEFF3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid #CBD2D9;
  padding: 15px 20px;
`

const WorkerProfileDetailsUser = styled.div`
  border-bottom: 1px solid #CBD2D9;
  margin-bottom: 10px;
  padding-bottom: 10px;

  span {
    font-size: 18px;
    font-weight: 600;
  }
`

const WorkerProfileDetailsApproved = styled.div`
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
    margin-bottom: 4px;
  }
  
  span {
    display: block;
    font-size: 14px;
    line-height: 1;

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
`