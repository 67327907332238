import { Controller } from "stimulus";

export default class SwitchController extends Controller {
  connect() {}

  checkSessional(e) {
    let inputCanRequestCheckbox = document.querySelectorAll("input[id$='can_request_shifts']")[0];
    let inputSessionalCheckbox = document.querySelectorAll("input[id$='can_request_sessional_shifts']")[0];
    let checkRequestShiftClicked = /can_request_shifts$/.test(e.target.getAttribute('for'));
    let checkSessionalClicked = /can_request_sessional_shifts$/.test(e.target.getAttribute('for'));

    if(checkRequestShiftClicked && inputCanRequestCheckbox.checked === true){
      inputSessionalCheckbox.checked = false;
    }

    if(checkSessionalClicked && inputSessionalCheckbox.checked === false){
      inputCanRequestCheckbox.checked = true;
    }
  }
}

import { application } from "../../frontend/controllers/index";

application.register("switch", SwitchController);
