import moment from 'moment-timezone'

export const DEFAULT_TIMEZONE = 'Europe/London'
export const DEFAULT_TIME_FORMAT = 'HH:mm'

export const formatTimeInTZ = (time, format = DEFAULT_TIME_FORMAT, timezone = DEFAULT_TIMEZONE) => {
  return moment.tz(time, timezone).format(format)
}

export const parseTimeInTZ = (time, timezone = DEFAULT_TIMEZONE) => {
  return moment.tz(time || new Date(), timezone)
}
