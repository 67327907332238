import React from 'react'
import styled from 'styled-components'
import { StyledButton } from '../helpers/BlockComponents'
import { EditIcon } from '../helpers/BlockIcons'

function ShiftOptions({ addShift, calendarDetails, deleteShifts, editShift, selectedShiftId }) {
  const selectedShiftDate = () => {
    const [shift] = calendarDetails.shifts.filter((t) => t.id === selectedShiftId)
    return shift.proposedStartTime
  }

  const singleShift = typeof selectedShiftId !== 'undefined' && selectedShiftId !== null
  let deleteLabel = 'Delete shift'

  if (!singleShift) {
    deleteLabel = 'Delete shifts'
  }

  if (!calendarDetails) {
    return null
  }

  return (
    <ShiftOptionsContainer>
      {singleShift && (
        <>
          {calendarDetails.status !== 'BOOKED' && (
            <StyledButton color="white" onClick={() => addShift(selectedShiftDate())} style={{ margin: 0 }} width="200">
              Add Shift
            </StyledButton>
          )}

          {calendarDetails.status === 'DRAFT' && (
            <StyledButton color="white" onClick={editShift} style={{ margin: 0 }} width="200">
              <EditIcon />
              Edit Shift
            </StyledButton>
          )}

          {calendarDetails.status !== 'DRAFT' && (
            <StyledButton color="white" onClick={editShift} style={{ margin: 0 }} width="200">
              <EditIcon />
              View Shift
            </StyledButton>
          )}
        </>
      )}

      <StyledButton color="white" onClick={deleteShifts} width="200">
        {deleteLabel}
      </StyledButton>
    </ShiftOptionsContainer>
  )
}

export default ShiftOptions

const ShiftOptionsContainer = styled.div`
  display: flex;
  gap: 12px;
`
