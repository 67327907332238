import { ApolloClient, from as LinkWrap, InMemoryCache } from '@apollo/client'
import apolloLogger from 'apollo-link-logger'
import { createUploadLink } from 'apollo-upload-client'

export default function createApolloClient() {
  const csrfToken = document.querySelector('meta[name=csrf-token]').getAttribute('content')

  const uploadLink = createUploadLink({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  })

  return new ApolloClient({
    apolloLogger,
    link: process.env.NODE_ENV === 'production' ? LinkWrap([uploadLink]) : LinkWrap([apolloLogger, uploadLink]),
    cache: new InMemoryCache(),
  })
}
