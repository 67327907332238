import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Loading } from "../ModalElements";
import { ErrorContainer, FormGroup } from "../../Forms/FormElements";

const ShiftModalAssign = ({
  action,
  actionText,
  actionPressText,
  data,
  error,
  inputFunction,
  inputValue,
  label,
  loading,
  selectedValue,
  title,
  type,
  gradeOptions,
}) => {
  const [filteredData, setFilteredData] = useState(null);

  const handleGradeOptions = (event) => {
    if (event == null) {
      setFilteredData(data);
    } else {
      let copy = data.slice().filter((cc) => cc.grade.title === event.label);
      setFilteredData(copy);
    }
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <AssignContainer>
      <div style={{ position: "relative" }}>
        {title && <h2>{title}</h2>}
        {inputFunction && (
          <>
            <FormGroup
              component={"input"}
              onChange={(e) => inputFunction(e)}
              type="text"
              defaultValue={inputValue}
              label={label}
              placeholder={"Type a name..."}
            />
            <br />

            <FormGroup
              component={"react-select"}
              label={"Filter Grade"}
              options={gradeOptions}
              placeholder="Filter Grade"
              onChange={handleGradeOptions}
              isClearable={true}
            />
          </>
        )}
        {error && error.error && (
          <ErrorContainer>
            <p>{error.message}</p>
          </ErrorContainer>
        )}
        {loading ? (
          <Loading />
        ) : (
          <AssignWorkerList>
            {filteredData?.map((item, index) => {
              return (
                <AssignWorkerListItem key={index}>
                  <AssignWorkerListItemDetails>
                    <AssignWorkerListItemAvatar />
                    <div>
                      {item.name ? (
                        <p>{item.name}</p>
                      ) : item.fullName ? (
                        <p>{item.fullName}</p>
                      ) : (
                        <p>
                          {item.firstName} {item.lastName}
                        </p>
                      )}
                      {type === "worker"
                        ? item.grade && <span>{item.grade.title}</span>
                        : type === "applicant"
                        ? item.grades && (
                            <span>
                              <strong>{item.grades[0].title},</strong>{" "}
                              {item.departments[0] && item.departments[0].name}
                            </span>
                          )
                        : ""}
                    </div>
                  </AssignWorkerListItemDetails>
                  <AssignWorkerListItemButton
                    onClick={() => action(item)}
                    className={`${selectedValue !== undefined && "grey"} ${
                      selectedValue === item.id && "blue"
                    } ${selectedValue === item.id && "active"}`}
                  >
                    {actionText
                      ? selectedValue === item.id
                        ? actionPressText
                        : actionText
                      : selectedValue === item.id
                      ? "Selected"
                      : "Select"}
                  </AssignWorkerListItemButton>
                </AssignWorkerListItem>
              );
            })}
          </AssignWorkerList>
        )}
      </div>
    </AssignContainer>
  );
};

export default ShiftModalAssign;

const AssignContainer = styled.div`
  width: 100%;
`;

const AssignWorkerList = styled.div`
  height: 100%;
  margin-top: 10px;
  max-height: 480px;
  overflow-y: auto;
  position: relative;
`;

const AssignWorkerListItem = styled.div`
  align-items: center;
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`;

const AssignWorkerListItemDetails = styled.div`
  align-items: center;
  display: flex;

  p {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  span {
    font-size: 16px;
  }
`;

const AssignWorkerListItemAvatar = styled.div`
  background: #eee;
  border-radius: 50%;
  height: 55px;
  margin-right: 16px;
  width: 55px;
`;

const AssignWorkerListItemButton = styled.button`
  align-items: center;
  background: #4dd6ea;
  border: 1px solid #43b5c6;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  height: 40px;
  font-size: 14px;
  font-weight: 700;
  padding: 0 25px;
  text-align: center;
  text-transform: uppercase;

  &.grey {
    background: #dfe2e5;
    border: 1px solid #dfe2e5;
    color: #1e2d36;
  }

  &.blue {
    background: #4dd6ea;
    border: 1px solid #43b5c6;
    color: #ffffff;
  }

  &.active {
    background: #2696a7;
    border: 1px solid #2591a1;
    color: #ffffff;
  }
`;
