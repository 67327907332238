import React, { useEffect } from 'react'
import { toast } from 'react-toastify'
import consumer from '../../../channels/consumer'
import {
  AuthHeaderContainer,
  AuthHeaderIcon,
  AuthHeaderIconCount,
  AuthHeaderRight,
  AuthHeaderSubtitle,
  AuthHeaderTitle,
} from '../AuthHeader/AuthHeader'
import DropdownComponent, { ActionsDropdownMenuItem } from '../Dropdown'

function BlockAuthHeader({ subtitle, title, user, refetch }) {
  const unreadCount = user?.unreadHubNotificationsCount ? user.unreadHubNotificationsCount : 0
  const useKeycloak = user?.useKeycloak ? user?.useKeycloak : false

  const toastOptions = {
    position: 'top-right',
    autoClose: 7000,
  }

  const logoutAction = () => {
    fetch('/hub_users/sign_out', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'DELETE',
    }).then((response) => {
      if (response.ok) {
        window.location.href = '/hub_users/sign_in'
      }
    })
  }

  useEffect(() => {
    // Subscribe to the NotificationsChannel when the component mounts
    const subscription = consumer.subscriptions.create('NotificationsChannel', {
      connected() {
        console.log('WebSocket Block connected')
      },

      disconnected() {
        console.log('WebSocket Block disconnected')
      },

      received(data) {
        if (window.location.pathname.includes('/blocks')) {
          console.log('on Blocks page, do something')
          console.log(window.location.pathname)

          refetch()
          if (data.success) toast.success(data.message, toastOptions)
          else toast.error(data.message, toastOptions)
        }
      },
    })

    // Unsubscribe from the NotificationsChannel when the component unmounts
    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <AuthHeaderContainer>
      <div>
        <AuthHeaderSubtitle>{subtitle}</AuthHeaderSubtitle>
        <AuthHeaderTitle>{title}</AuthHeaderTitle>
      </div>
      <AuthHeaderRight>
        <AuthHeaderIcon>
          {unreadCount > 0 && <AuthHeaderIconCount>{unreadCount}</AuthHeaderIconCount>}

          <a href="/hub_notifications">
            <svg height="26" width="21" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M12.268 22.4c.832 0 1.333.906.912 1.623a3.197 3.197 0 01-5.515 0c-.421-.717.08-1.623.912-1.623h3.69zM2.465 7.45A7.978 7.978 0 0110.903.015c4.053.238 7.226 3.603 7.493 7.653l.501 7.644c.016.268.101.527.245.754l1.451 2.276c.677 1.065-.09 2.459-1.35 2.459H1.608c-1.264 0-2.032-1.394-1.355-2.46l1.45-2.275c.145-.227.23-.486.246-.754z"
                fill="#FFF"
                fillRule="evenodd"
                opacity={unreadCount === 0 ? 0.4 : 1}
              />
            </svg>
          </a>
        </AuthHeaderIcon>
        <DropdownComponent user={user}>
          {useKeycloak === false && (
            <ActionsDropdownMenuItem href="/hub_users/edit">Change Password</ActionsDropdownMenuItem>
          )}
          <ActionsDropdownMenuItem onClick={logoutAction}>Logout</ActionsDropdownMenuItem>
        </DropdownComponent>
      </AuthHeaderRight>
    </AuthHeaderContainer>
  )
}

export default BlockAuthHeader
