/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-else-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable sort-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/order */

export async function watchShift(shift) {
  const url = `/shifts/${shift.id}/watch`
  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    else return 'err'
  })
  return data
}

export async function unwatchShift(shift) {
  const url = `/shifts/${shift.id}/unwatch`
  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    else return 'err'
  })
  return data
}

export async function fetchAgencyWorkers(agencyId) {
  const url = `/agencies/${agencyId}/workers_list`
  const data = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    else return 'err'
  })
  return data
}

export async function fetchShift(shiftId) {
  const id = shiftId

  const query = `
    query Shift($id: Int!){
      shift(id: $id){
        adminNote
        agencyLocum {
          firstName
          lastName
          grade {
            id
            title
          }
        }
        agencyBooking{
          coreAgencyMargin
          coreLocumRate
          coreNiContribution
          coreTotalRate
          directEngagement
          id
          splitRate
          unsocialAgencyMargin
          unsocialLocumRate
          unsocialNiContribution
          unsocialTotalRate
        }
        appliedBy {
          departments {
            id
            name
          }
          fullName
          id
          grades {
            id
            title
          }
        }
        auditEvents {
          description
          id
          time
          event
          user
        }
        availableActions
        availableForAgencyUserActions
        bookedBy {
          firstName
          lastName
          grade {
            id
            title
          }
        }
        breakMinutes
        coreHours
        coreTotalRate
        costCentre
        department {
          id
          name
        }
        endTime
        escalatedBy
        grade {
          id
          title
        }
        id
        locumFirstName
        locumLastName
        reasonForRequestId
        note
        organisation {
          name
        }
        rateRequest {
          id
          approved
          createdAt
          newCoreRate
          newTotalPay
          newUnsocialRate
          oldCoreRate
          oldTotalPay
          oldUnsocialRate
          reason
          requester {
            id
            fullName
          }
        }
        site {
          id
          name
        }
        staffGroup {
          title
        }
        staffGroupId
        startTime
        status
        totalPay
        unsocialHours
        unsocialTotalRate
        userBookedShift {
          id
          approvedAt
          approver {
            id
            fullName
          }
        }
        watchingHubUsers {
          id
        }
      }
    }
  `
  const data = fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: { id },
    }),
  }).then((response) => {
    if (response.ok) return response.json()
    else return 'err'
  })
  return data
}

export async function updateShift(shiftId, body) {
  const url = `/shifts/${shiftId}/update`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function signOffShift(shiftId, body) {
  const url = `/shifts/${shiftId}/hub_user_sign_off`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function cancelShift(body) {
  const url = `/bulk_cancel_worker`

  const data = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function requestRate(shiftId, body) {
  const url = `/shifts/${shiftId}/request_rate`

  const data = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function withdrawRequestRate(shiftId, body) {
  const url = `/shifts/${shiftId}/withdraw_rate_request`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function removeRateEscalation(shiftId) {
  const url = `/bulk_remove_rate_escalation/?shift_ids[]=${shiftId}]`

  const data = fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function approveApplication(shiftId, body) {
  const url = `/shifts/${shiftId}/approve_application`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function assignAgencySingle(shiftId, body) {
  const url = `/shifts/${shiftId}/assign_agency_worker_single_rate`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function assignAgencySplit(shiftId, body) {
  const url = `/shifts/${shiftId}/assign_agency_worker_split_rate`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function amendAgencySingle(shiftId, body) {
  const url = `/shifts/${shiftId}/amend_agency_booking_single_rate`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function amendAgencySplit(shiftId, body) {
  const url = `/shifts/${shiftId}/amend_agency_booking_split_rate`

  const data = fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return 'err'
    else return response
  })
  return data
}

export async function fetchAuthorisers(newCoreRate, newUnsocialRate, shiftId) {
  const query = `query  RateAuthorisers( $newCoreRate: Float!, $newUnsocialRate: Float!, $shiftId: Int!){
    rateAuthorisers(newCoreRate: $newCoreRate, newUnsocialRate: $newUnsocialRate, shiftId: $shiftId){
      id
      name
      email
      position
    }
  }`

  const data = fetch('/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      query,
      variables: { newCoreRate, newUnsocialRate, shiftId },
    }),
  }).then((response) => {
    if (response.ok) return response.json()
    else return 'err'
  })
  return data
}

export async function sortShifts(shifts, usesMspShortlisting, canShortlistProposals) {
  // state

  const statusShifts = shifts
      .map((dataItem) => dataItem.status) // get all status
      .filter((dupeStatus, index, array) => array.indexOf(dupeStatus) === index),
    statusShiftsSorted = statusShifts.map((dupeStatus) => ({
      status: dupeStatus,
      shifts: shifts.filter((item) => item.status === dupeStatus),
    }))

  let approvedShifts = []
  let availableShifts = []
  let appliedShifts = []
  let bookedShifts = []
  let needsSignoffShifts = []
  let payrollShifts = []
  let signedOffShifts = []
  let urgentShifts = []
  let proposedShifts = []

  statusShiftsSorted.map((statusShifts) => {
    let availabledArray =
      statusShifts.status === 'AVAILABLE' &&
      statusShifts.shifts.filter((e) => e.agencyWorkerProposals.nodes.length === 0 && e.appliedBy.length === 0)

    if (availabledArray.length > 0) {
      availableShifts.push(...availabledArray)
    }

    // check for applications + status available and push to appliedShifts Array
    const appliedArray =
      statusShifts.status === 'AVAILABLE' && statusShifts.shifts.filter((e) => e.appliedBy.length > 0)
    if (appliedArray.length > 0) {
      appliedShifts.push(...appliedArray)
    }

    const filteredProposedArray =
      statusShifts.status === 'AVAILABLE' && // Check if status is 'AVAILABLE'
      statusShifts.shifts // Access the shifts array
        .map((shift) => {
          if (
            // Check conditions for filtering proposals
            shift.agencyWorkerProposals.nodes.length > 0 && // Ensure there are proposals
            (!usesMspShortlisting || (usesMspShortlisting && !canShortlistProposals)) // Check conditions for filtering proposals
          ) {
            const filteredNodes = shift.agencyWorkerProposals.nodes.filter(
              (proposal) => proposal.shortlistedAt !== null // Filter proposals based on condition
            )

            return {
              // Return the modified shift object
              ...shift,
              agencyWorkerProposals: {
                nodes: filteredNodes, // Replace proposals with filtered ones
              },
            }
          } else if (shift.agencyWorkerProposals.nodes.length > 0 && usesMspShortlisting && canShortlistProposals) {
            return {
              ...shift,
            }
          } else {
            // If conditions aren't met, return null
            return null
          }
        })
        .filter(Boolean) // Filter out null values

    if (filteredProposedArray.length > 0) {
      proposedShifts.push(...filteredProposedArray) // Push filtered shifts into proposedShifts array
    }

    // then continue with statusShifts loop
    statusShifts.status === 'APPROVED'
      ? approvedShifts.push(...statusShifts.shifts)
      : statusShifts.status === 'BOOKED' ||
        statusShifts.status === 'TO_SIGN_OFF_ON_HUB_OR_APP' ||
        statusShifts.status === 'TO_SIGN_OFF_ON_HUB'
      ? bookedShifts.push(...statusShifts.shifts)
      : statusShifts.status === 'SIGN_OFF_REQUESTED'
      ? needsSignoffShifts.push(...statusShifts.shifts)
      : statusShifts.status === 'PAID'
      ? payrollShifts.push(...statusShifts.shifts)
      : statusShifts.status === 'SIGNED_OFF'
      ? signedOffShifts.push(...statusShifts.shifts)
      : statusShifts.status === 'URGENT'
      ? urgentShifts.push(...statusShifts.shifts)
      : null
  })

  const shiftCount =
    approvedShifts.length +
    availableShifts.length +
    appliedShifts.length +
    bookedShifts.length +
    needsSignoffShifts.length +
    payrollShifts.length +
    signedOffShifts.length +
    urgentShifts.length +
    proposedShifts.length

  const approvedShiftsSorted = approvedShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    approvedSorted = approvedShiftsSorted.map((startDate) => ({
      title: '6',
      date: startDate,
      className: 'approved',
      extendedProps: {
        status: 'Approved',
        count: approvedShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: approvedShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const availableShiftsSorted = availableShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index),
    availableSorted = availableShiftsSorted.map((startDate) => ({
      title: '1',
      date: startDate,
      className: 'available',
      extendedProps: {
        status: 'Available',
        count: availableShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: availableShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const appliedShiftsSorted = appliedShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    appliedSorted = appliedShiftsSorted.map((startDate) => ({
      title: '2',
      date: startDate,
      className: 'applications',
      extendedProps: {
        status: 'Applications',
        count: appliedShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: appliedShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const proposedShiftsSorted = proposedShifts
    .map((dataItem) => dataItem.startTime.substring(0, 10))
    .filter((startDate, index, array) => array.indexOf(startDate) === index)

  let proposedSorted = proposedShiftsSorted.map((startDate) => ({
    title: '9',
    date: startDate,
    className: 'proposed',
    extendedProps: {
      status: 'Proposed',
      count: proposedShifts.filter((item) => item.startTime.substring(0, 10) === startDate)[0].agencyWorkerProposals
        ?.nodes?.length,
      shifts: proposedShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
    },
  }))

  const bookedShiftsSorted = bookedShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    bookedSorted = bookedShiftsSorted.map((startDate) => ({
      title: '3',
      date: startDate,
      className: 'booked',
      extendedProps: {
        status: 'Booked',
        count: bookedShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: bookedShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const needsSignoffShiftsSorted = needsSignoffShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    needsSignoffSorted = needsSignoffShiftsSorted.map((startDate) => ({
      title: '4',
      date: startDate,
      className: 'needsSignoff',
      extendedProps: {
        status: 'Sign-off Request',
        count: needsSignoffShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: needsSignoffShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const payrollShiftsSorted = payrollShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    payrollSorted = payrollShiftsSorted.map((startDate) => ({
      title: '7',
      date: startDate,
      className: 'paid',
      extendedProps: {
        status: 'Payroll',
        count: payrollShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: payrollShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const signedoffShiftsSorted = signedOffShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    signedoffSorted = signedoffShiftsSorted.map((startDate) => ({
      title: '5',
      date: startDate,
      className: 'signedOff',
      extendedProps: {
        status: 'Signed Off',
        count: signedOffShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: signedOffShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const urgentShiftsSorted = urgentShifts
      .map((dataItem) => dataItem.startTime.substring(0, 10)) // get all dates
      .filter((startDate, index, array) => array.indexOf(startDate) === index), // filter out duplicates
    urgentSorted = urgentShiftsSorted.map((startDate) => ({
      title: '8',
      date: startDate,
      className: 'urgent',
      extendedProps: {
        status: 'Urgent',
        count: urgentShifts.filter((item) => item.startTime.substring(0, 10) === startDate).length,
        shifts: urgentShifts.filter((item) => item.startTime.substring(0, 10) === startDate),
      },
    }))

  const data = {
    count: shiftCount,
    events: [
      ...proposedSorted,
      ...availableSorted,
      ...appliedSorted,
      ...bookedSorted,
      ...needsSignoffSorted,
      ...signedoffSorted,
      ...approvedSorted,
      ...payrollSorted,
      ...urgentSorted,
    ],
  }

  return data
}
