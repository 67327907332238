import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
  FormToggleContainer,
  FormToggleComponent
} from "../Forms/FormElements";
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  LoadingContainer,
} from "../Modals/ModalElements";
import {
  createCollaborativeBank,
  editCollaborativeBank,
} from "./CollaborativeBankHelpers";
import CollaborativeBanks from "./CollaborativeBanks";

const CollaborativeBanksModal = ({
  bank,
  handleClose,
  organisationsList,
  show,
}) => {
  const [error, setError] = useState({ name: "" });
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
  });

  useEffect(() => {
    if (bank) {
      setInputs({
        name: bank.name ? bank.name : "",
        contract: bank.contract_text ? bank.contract_text : "",
        orgMultiValues: bank.organisations
          ? bank.organisations.map((group) => {
            return {
              label: group.name,
              value: group.id,
            };
          })
          : [],
        leadEmployer: bank.lead_employer_organisation_id
          ? bank.lead_employer_organisation_id
          : null,
        emailSubject: bank.collaborative_bank_setting?.welcome_email_subject
          ? bank.collaborative_bank_setting.welcome_email_subject
          : "",
        emailBody: bank.collaborative_bank_setting?.welcome_email_body
          ? bank.collaborative_bank_setting.welcome_email_body
          : "",
      });
    }
  }, [bank]);

  // Remap Organisations into an array for react-select.
  const organisations = organisationsList?.map((org) => {
    return { value: org.id, label: org.name };
  });

  // Match LeadEmployer id to Org.
  const defaultOption = organisations?.filter(
    (option) => option.value === inputs.leadEmployer
  );

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckboxInput = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.id]: !inputs[event.target.id],
    }));
  };

  const handleRegulationBodyChange = (event) => {
    if (event === null) {
      setError({});
      setInputs((inputs) => ({ ...inputs, leadEmployer: null }));
    } else {
      setError({});
      setInputs((inputs) => ({ ...inputs, leadEmployer: event.value }));
    }
  };

  const createBankAction = (bankId) => {
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a Name." });
    } else {
      setLoading(true);
      let body = {
        collaborative_bank: {
          name: inputs.name,
          organisation_ids: inputs.orgMultiValues?.map((org) => org.value),
          lead_employer_organisation_id: inputs.leadEmployer,
          contract_text: inputs.contract
        },
        welcome_email_subject: inputs.emailSubject,
        welcome_email_body: inputs.emailBody,
      };

      createCollaborativeBank(body)
        .then((data) => data.text())
        .then((res) => {
          setLoading(false);
          setError({
            error: true,
            message: res,
          });
        });
    }
  };

  const editBankAction = (bankId) => {
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a Name." });
    } else {
      setLoading(true);
      let body = {
        collaborative_bank: {
          name: inputs.name,
          organisation_ids:
            inputs.orgMultiValues !== null
              ? inputs.orgMultiValues.map((org) => org.value)
              : [],
          lead_employer_organisation_id:
            inputs.leadEmployer === "" ? null : inputs.leadEmployer,
          contract_text: inputs.contract
        },
        welcome_email_subject: inputs.emailSubject,
        welcome_email_body: inputs.emailBody,
      };

      editCollaborativeBank(bankId, body)
        .then((data) => data.text())
        .then((res) => {
          setLoading(false);
          setError({
            error: true,
            message: res,
          });
        });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {bank ? `Edit Collaborative Bank` : "Create Collaborative Bank"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http:www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0, overflowY: "scroll" }}>
            <CollaborativeBankForm>
              <FormDetailGroup>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"input"}
                    value={inputs.name}
                    placeholder={"Enter a name"}
                    label={"Name"}
                    name="name"
                    onChange={handleInputChange}
                    type="text"
                    required
                    error={error.name}
                  />
                </FormGrid>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"react-select"}
                    label={"Organisations"}
                    options={organisations}
                    isClearable
                    isMulti={true}
                    placeholder="Select organisation"
                    value={inputs.orgMultiValues}
                    onChange={(option) => {
                      setInputs((inputs) => ({
                        ...inputs,
                        orgMultiValues: option,
                      }));
                    }}
                  />
                </FormGrid>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"react-select"}
                    label={"Lead Employer"}
                    isClearable
                    options={organisations}
                    placeholder="Select organisation"
                    name="LeadEmployer"
                    onChange={handleRegulationBodyChange}
                    defaultValue={{
                      value: defaultOption[0]?.value,
                      label: defaultOption[0]?.label,
                    }}
                    value={{
                      value: defaultOption[0]?.value,
                      label: defaultOption[0]?.label,
                    }}
                    footnote="If a lead employer is set, this collaborative bank will
                        follow the lead employer model."
                  />
                </FormGrid>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"textarea"}
                    label={"Contract"}
                    name="contract"
                    value={inputs.contract}
                    placeholder={"Contract"}
                    onChange={handleInputChange}
                    type="text"
                  />
                </FormGrid>
              </FormDetailGroup>
              <FormDetailGroup>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"input"}
                    label={"Welcome email"}
                    placeholder="Email Subject"
                    name="emailSubject"
                    value={inputs.emailSubject}
                    onChange={handleInputChange}
                  />
                </FormGrid>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"textarea"}
                    label={"Email Body"}
                    value={inputs.emailBody}
                    onChange={handleInputChange}
                    placeholder="Email Body"
                    name="emailBody"
                    footnote="You can insert variables in email subject and body:
                      {{ bank_name }}, {{ organisation_name }},
                      {{ tso_email }}, {{ tso_phone }}, {{cv_link}}."
                  />
                </FormGrid>
              </FormDetailGroup>
            </CollaborativeBankForm>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleCloseAction}>
                Cancel
              </button>
              {bank ? (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => editBankAction(bank.id)}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => createBankAction()}
                >
                  Create
                </button>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default CollaborativeBanksModal;

const CollaborativeBankForm = styled.div``;
