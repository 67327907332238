/* eslint-disable no-underscore-dangle */
import { useMutation } from '@apollo/client'
import {
  DefaultButton,
  FormGrid,
  FormBreak,
  FormGridContainer,
  FormGroup,
  FormLabel,
  FormSelect,
  ModalFooter,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import 'react-day-picker/lib/style.css'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
  infoToast,
  errorToast,
  successToast,
  loadingToast,
  SHIFT_BLOCK_CANCEL_WORKER_APPLICATION,
  VMS_SHIFT_BLOCK_CANCEL_AGENCY_BOOKING,
} from '../helpers/BlockBookingsHelpers'
import { ShiftModalTitle } from '../helpers/BlockComponents'

function ViewBlockBooking({ block, handleClose, refetch, reasons, blockData, canCancelBooking }) {
  const isAgencyBooking = block?.bookedBy?.__typename === 'AgencyRegistration'
  const [reason, setReason] = useState(null)

  // GQL **************************************

  const [shiftBlockCancelWorkerApplication] = useMutation(SHIFT_BLOCK_CANCEL_WORKER_APPLICATION)
  const [shiftBlockCancelAgencyBooking] = useMutation(VMS_SHIFT_BLOCK_CANCEL_AGENCY_BOOKING)

  const cancelBooking = () => {
    if (!reason) {
      toast.error('Please select a reason!', { hideProgressBar: true })
      return
    }

    toast.loading('Loading', loadingToast)

    shiftBlockCancelWorkerApplication({
      variables: {
        reasonForCancellationId: reason?.value,
        shiftBlockId: Number(block?.id),
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBlockCancelWorkerApplication?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: errors[0].message })

          return
        }

        handleClose()
        refetch()

        toast.update(2, { ...successToast, render: 'Booking cancelled' })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Booking could not be cancelled' })
      })
  }

  const cancelAgencyBooking = () => {
    if (!reason) {
      toast.error('Please select a reason!', { hideProgressBar: true })
      return
    }

    toast.loading('Loading', loadingToast)

    shiftBlockCancelAgencyBooking({
      variables: {
        cancelAgencyBookingAttributes: {
          reasonForCancellationId: reason?.value,
          shiftBlockId: Number(block?.id),
        },
      },
    })
      .then((response) => {
        const errors = response.data?.vmsShiftBlockCancelAgencyBooking?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: errors[0].message })

          return
        }

        handleClose()
        refetch()

        toast.update(2, {
          ...infoToast,
          render:
            'Please Wait, Cancel Booking is being processed! We will inform you upon completion. ( Click to dismiss )',
        })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Booking could not be cancelled' })
      })
  }

  return (
    <FormGridContainer>
      {!isAgencyBooking && <ShiftModalTitle>Cancel Booking</ShiftModalTitle>}
      {isAgencyBooking && <ShiftModalTitle>Cancel Agency Booking</ShiftModalTitle>}

      <FormGrid columns={2} style={{ minHeight: 500 }}>
        {!canCancelBooking && (
          <FormGroup>
            <FormLabel>You do not have permission to cancel booking</FormLabel>
          </FormGroup>
        )}

        {canCancelBooking && (
          <FormGroup>
            <FormLabel>Reason for Cancellation</FormLabel>
            <FormSelect
              isClearable
              name="cancelReason"
              onChange={(e) => setReason(e)}
              options={reasons && reasons.reasonsForCancellation}
              placeholder="Select reason"
              value={reason}
            />
          </FormGroup>
        )}

        <FormGroup>
          {block?.bookedBy && (
            <CancelWorker>
              <FormGroup>
                {isAgencyBooking && (
                  <FormLabel>
                    {block.bookedBy?.user?.firstName} {block.bookedBy.user?.lastName}
                  </FormLabel>
                )}

                {!isAgencyBooking && (
                  <a href={`/workers?worker_id=${block.bookedBy.user?.id}`} rel="noreferrer" target="_blank">
                    <strong>
                      {block.bookedBy?.user?.firstName} {block.bookedBy.user?.lastName}
                    </strong>
                  </a>
                )}
              </FormGroup>

              <FormBreak />

              <FormGrid columns={1}>
                <FormGroup>
                  <FormLabel>Email:</FormLabel>
                  <p>{block.bookedBy?.user?.email}</p>
                </FormGroup>

                <FormGroup>
                  <FormLabel>Phone No:</FormLabel>
                  <p>{block.bookedBy?.user?.phoneNumber}</p>
                </FormGroup>

                {isAgencyBooking && (
                  <FormGroup>
                    <FormLabel>Agency:</FormLabel>
                    <p>{block.bookedBy?.agency?.title}</p>
                  </FormGroup>
                )}

                {isAgencyBooking && (
                  <FormGroup>
                    <FormLabel>Reg Body Number:</FormLabel>
                    <p>{block.bookedBy?.user?.regBodyNumber}</p>
                  </FormGroup>
                )}
              </FormGrid>

              <FormBreak />

              {isAgencyBooking && (
                <FormGrid columns={2}>
                  <FormGroup>
                    {blockData?.bookedBy?.compliancePackUrl === null ? (
                      <FormLabel>No Compliance Pack</FormLabel>
                    ) : (
                      <>
                        <FormLabel>Compliance Pack</FormLabel>
                        <a
                          href={`${window.location.origin}${blockData?.bookedBy?.compliancePackUrl}`}
                          rel="noopener noreferrer"
                          style={{ display: 'block' }}
                          target="_blank"
                        >
                          Download
                        </a>
                      </>
                    )}
                  </FormGroup>

                  <FormGroup>
                    {blockData?.bookedBy?.cvUrl === null ? (
                      <FormLabel>No CV Uploaded</FormLabel>
                    ) : (
                      <>
                        <FormLabel>CV</FormLabel>
                        <a
                          href={`${window.location.origin}${blockData?.bookedBy?.cvUrl}`}
                          rel="noopener noreferrer"
                          style={{ display: 'block' }}
                          target="_blank"
                        >
                          Download
                        </a>
                      </>
                    )}
                  </FormGroup>
                </FormGrid>
              )}
            </CancelWorker>
          )}
        </FormGroup>
      </FormGrid>

      {canCancelBooking && (
        <ModalFooter
          style={{
            position: 'absolute',
            left: 0,
            right: 0,
          }}
        >
          <div />
          <div>
            <DefaultButton action={() => handleClose()} text="Cancel" type="white" />
            {!isAgencyBooking && <DefaultButton action={cancelBooking} color="red" text=" Cancel Booking" />}
            {isAgencyBooking && (
              <DefaultButton action={cancelAgencyBooking} color="red" text=" Cancel Agency Booking" />
            )}
          </div>
        </ModalFooter>
      )}
    </FormGridContainer>
  )
}

export default ViewBlockBooking

// Shift Application Info ***************
const CancelWorker = styled.div`
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 5px;
  box-shadow: 0 0 0.2rem rgb(79 81 82 / 25%);
  height: auto;
  padding: 16px;
  position: absolute;
  right: 0;
  top: -45px;
  width: 300px;

  a {
    text-decoration: underline;
  }

  p {
    font-size: 11px;
  }
`
