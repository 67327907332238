import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class WorkerExperienceController extends Controller {
  static get targets() {
    return ['organisationId', 'workerId']
  }

  connect() {}

  saveSkills() {
    const mutation = `mutation WorkerUpdateSkills($worker_id: Int!, $organisation_id: Int!, $skill_ids: [Int!]!) {
      workerUpdateSkills(workerId: $worker_id, organisationId: $organisation_id, skillIds: $skill_ids) {
        errors {
          attribute
          message
        }
      }
    }`

    const organisation_id = parseInt(this.organisationIdTarget.value)
    const worker_id = parseInt(this.workerIdTarget.value)
    const skill_ids = []

    document.getElementById('skills_selector').skills_selector.select.data.data.map((option) => {
      if (option.selected === true) {
        skill_ids.push(Number(option.value))
      }
    })

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query: mutation,
        variables: { worker_id, organisation_id, skill_ids },
      }),
    })
      .then((response) => {
        if (response.status != 200) {
          console.log('error')
        } else if (response.status == 200) {
          window.location.reload(true)
        }
      })
      .catch((err) => {})
  }
}

application.register('worker_experience', WorkerExperienceController)
