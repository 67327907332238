import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import styled from "styled-components";

import {
  ErrorContainer,
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalDetailGroup,
  ShiftModalButtonClose,
} from "../../ShiftModalComponents";
import ShiftModalAssign from "../../ShiftModalAssign";
import {
  AgencyTabSingle,
  AgencyTabSplit,
  AgencyOptionalInfo,
  AssignAgencyWorkerTabs,
} from "../../ShiftModalAgency";

import {
  GET_VMS_AGENCY_WORKERS,
  VMS_SHIFT_ASSIGN_AGENCY_WORKER,
  VMS_SHIFT_CANCEL_AGENCY_WORKER,
} from "./VMSAssignAgencyWorkerHelpers";

const ShiftModalVMSAssignAgencyWorker = ({
  fetchShift,
  fetchShifts,
  handleClose,
  setSelectedTab,
  setShiftLoading,
  shift,
}) => {
  const [agencyTab, setAgencyTab] = useState("single");
  const [error, setError] = useState({ error: false, message: null });
  const [modalStep, setModalStep] = useState(1);
  const [optionalInputs, setOptionalInputs] = useState({
    requisition_number: "",
    po_number: "",
    receipt_number: "",
    comment: "",
  });
  const [singleDirectEngagement, setSingleDirectEngagement] = useState(false);
  const [singleFrameWorkerCompliant, setSingleFrameWorkerCompliant] = useState(
    false
  );
  const [singleInputs, setSingleInputs] = useState({
    core_total_rate: 0,
    core_locum_rate: 0,
    core_agency_margin: 0,
    core_ni_contribution: 0,
  });
  const [splitDirectEngagement, setSplitDirectEngagement] = useState(false);
  const [splitFrameWorkerCompliant, setSplitFrameWorkerCompliant] = useState(
    false
  );
  const [splitInputs, setSplitInputs] = useState({
    core_total_rate: 0,
    unsocial_total_rate: 0,
    core_locum_rate: 0,
    unsocial_locum_rate: 0,
    core_ni_contribution: 0,
    unsocial_ni_contribution: 0,
    core_agency_margin: 0,
    unsocial_agency_margin: 0,
  });
  const [worker, setWorker] = useState(undefined);
  const [workerName, setWorkerName] = useState("");

  const toggleSingleDirectEngagement = () =>
    setSingleDirectEngagement(!singleDirectEngagement);
  const toggleSplitDirectEngagement = () =>
    setSplitDirectEngagement(!splitDirectEngagement);

  const toggleSingleFrameWorkerCompliant = () =>
    setSingleFrameWorkerCompliant(!singleFrameWorkerCompliant);
  const toggleSplitFrameWorkerCompliant = () =>
    setSplitFrameWorkerCompliant(!splitFrameWorkerCompliant);

  const [vmsShiftAssignAgencyUser] = useMutation(
    VMS_SHIFT_ASSIGN_AGENCY_WORKER
  );
  const [vmsShiftCancelAgencyWorker] = useMutation(
    VMS_SHIFT_CANCEL_AGENCY_WORKER
  );

  const { loading, data } = useQuery(GET_VMS_AGENCY_WORKERS, {
    variables: {
      items: 25,
      name: workerName,
      page: 1,
    },
  });

  const uniqueGrades = new Set();
  const shiftType = shift?.staffGroup?.title;
  const workers = data?.vmsAgencyWorkers?.list.filter(
    (i) => i?.staffGroup?.title === shiftType
  );

  if (workers) {
    workers.filter((el) => {
      uniqueGrades.add(el.grade);
    });
  }

  const gradeOptions = Array.from(uniqueGrades).map((cc) => ({
    label: cc.title,
    value: cc.id,
  }));

  const setWorkerAction = (worker) => {
    setWorker(worker);
    setError({ error: false });
    setModalStep(2);
  };

  const handleSingleInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setSingleInputs((singleInputs) => ({
      ...singleInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSplitInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setSplitInputs((splitInputs) => ({
      ...splitInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOptionalInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setOptionalInputs((optionalInputs) => ({
      ...optionalInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const assignAgencySingleAction = (e) => {
    setError({ error: false });
    if (singleInputs.core_total_rate <= 0) {
      setError({
        ...error,
        single_core_total_rate: true,
        message: "Please enter the total rate.",
      });
    } else if (singleInputs.core_locum_rate <= 0) {
      setError({
        ...error,
        single_core_locum_rate: true,
        message: "Please enter the workers rate.",
      });
    } else if (singleInputs.core_agency_margin <= 0) {
      setError({
        ...error,
        single_core_agency_margin: true,
        message: "Please enter the agency margin.",
      });
    } else if (singleInputs.core_ni_contribution <= 0) {
      setError({
        ...error,
        single_core_ni_contribution: true,
        message: "Please enter the NI contribution.",
      });
    } else {
      setShiftLoading(true);
      vmsShiftAssignAgencyUser({
        variables: {
          agencyWorkerId: Number(worker.id),
          shiftId: Number(shift.id),
          coreTotalRate: parseFloat(singleInputs.core_total_rate),
          coreLocumRate: parseFloat(singleInputs.core_locum_rate),
          coreAgencyMargin: parseFloat(singleInputs.core_agency_margin),
          coreNiContribution: parseFloat(singleInputs.core_ni_contribution),
          directEngagement: singleDirectEngagement,
          onFramework: singleFrameWorkerCompliant,
          requisitionNumber: optionalInputs.requisition_number,
          poNumber: optionalInputs.po_number,
          receiptNumber: optionalInputs.receipt_number,
          comment: optionalInputs.comment,
          splitRate: false,
        },
      }).then((data) => {
        setShiftLoading(false);
        if (data.data.vmsShiftAssignAgencyWorker.success === true) {
          fetchShift(shift.id);
          setWorker(undefined);
          setWorkerName("");
          setModalStep(1);
          setSelectedTab("detailsReadOnly");
          setTimeout(() => {
            fetchShifts();
          }, 300);
        } else {
          setError({
            ...error,
            main: true,
            message:
              "There was a problem assigning the agency worker, please try again.",
          });
        }
      });
    }
  };

  const assignAgencySplitAction = (e) => {
    setError({ error: false });
    if (splitInputs.core_total_rate <= 0) {
      setError({
        ...error,
        split_core_total_rate: true,
        message: "Please enter the total rate.",
      });
    } else if (splitInputs.unsocial_total_rate <= 0) {
      setError({
        ...error,
        split_unsocial_total_rate: true,
        message: "Please enter the unsocial total rate.",
      });
    } else if (splitInputs.core_locum_rate <= 0) {
      setError({
        ...error,
        split_core_locum_rate: true,
        message: "Please enter the workers rate.",
      });
    } else if (splitInputs.unsocial_locum_rate <= 0) {
      setError({
        ...error,
        split_unsocial_locum_rate: true,
        message: "Please enter the unsocial workers rate.",
      });
    } else if (splitInputs.core_ni_contribution <= 0) {
      setError({
        ...error,
        split_core_ni_contribution: true,
        message: "Please enter the NI contribution.",
      });
    } else if (splitInputs.unsocial_ni_contribution <= 0) {
      setError({
        ...error,
        split_unsocial_ni_contribution: true,
        message: "Please enter the unsocial NI contribution.",
      });
    } else if (splitInputs.core_agency_margin <= 0) {
      setError({
        ...error,
        split_core_agency_margin: true,
        message: "Please enter the agency margin.",
      });
    } else if (splitInputs.unsocial_agency_margin <= 0) {
      setError({
        ...error,
        split_unsocial_agency_margin: true,
        message: "Please enter the unsocial agency margin.",
      });
    } else {
      setShiftLoading(true);
      vmsShiftAssignAgencyUser({
        variables: {
          agencyWorkerId: Number(worker.id),
          shiftId: Number(shift.id),
          coreTotalRate: parseFloat(splitInputs.core_total_rate),
          unsocialTotalRate: parseFloat(splitInputs.unsocial_total_rate),
          coreLocumRate: parseFloat(splitInputs.core_locum_rate),
          unsocialLocumRate: parseFloat(splitInputs.unsocial_locum_rate),
          coreAgencyMargin: parseFloat(splitInputs.core_agency_margin),
          unsocialAgencyMargin: parseFloat(splitInputs.unsocial_agency_margin),
          coreNiContribution: parseFloat(splitInputs.core_ni_contribution),
          unsocialNiContribution: parseFloat(
            splitInputs.unsocial_ni_contribution
          ),
          directEngagement: splitDirectEngagement,
          onFramework: splitFrameWorkerCompliant,
          requisitionNumber: optionalInputs.requisition_number,
          poNumber: optionalInputs.po_number,
          receiptNumber: optionalInputs.receipt_number,
          comment: optionalInputs.comment,
          splitRate: true,
        },
      }).then((data) => {
        setShiftLoading(false);
        if (data.data.vmsShiftAssignAgencyWorker.success === true) {
          fetchShift(shift.id);
          setWorker(undefined);
          setWorkerName("");
          setModalStep(1);
          setTimeout(() => {
            fetchShifts();
          }, 300);
        } else {
          setError({
            ...error,
            main: true,
            message:
              "There was a problem assigning the agency worker, please try again.",
          });
        }
      });
    }
  };

  const vmsShiftCancelAgencyWorkerAction = () => {
    setError({ error: false });
    setShiftLoading(true);
    vmsShiftCancelAgencyWorker({
      variables: {
        shiftId: Number(shift.id),
        reason: "",
      },
    }).then((data) => {
      setShiftLoading(false);
      if (data.data.vmsShiftCancelAgencyWorker.success === true) {
        fetchShift(shift.id);
        setTimeout(() => {
          setSelectedTab("vmsAssignAgencyWorker");
          fetchShifts();
        }, 300);
      } else {
        setError({
          ...error,
          main: true,
          message:
            "There was a problem removing the agency worker, please try again.",
        });
      }
    });
  };

  const inputFunction = (e) => {
    setWorkerName(e.target.value);
  };

  return (
    <>
      {worker && (
        <ShiftModalDetailFooter>
          <div>
            {error && error.main && (
              <ErrorContainer>
                <p>{error.message}</p>
              </ErrorContainer>
            )}
          </div>
          <div>
            <ShiftModalButtonClose onClick={handleClose}>
              Close
            </ShiftModalButtonClose>
            {modalStep === 2 ? (
              agencyTab === "single" ? (
                <ShiftModalButton
                  theme={"blue"}
                  onClick={() => assignAgencySingleAction()}
                >
                  Confirm Assign Worker
                </ShiftModalButton>
              ) : (
                <ShiftModalButton
                  theme={"blue"}
                  onClick={() => assignAgencySplitAction()}
                >
                  Confirm Assign Worker
                </ShiftModalButton>
              )
            ) : (
              ""
            )}
          </div>
        </ShiftModalDetailFooter>
      )}
      <ShiftModalContainer locked={worker ? false : true}>
        {modalStep === 1 ? (
          <>
            <h2>
              {shift.agencyLocum
                ? "Assigned Agency Worker"
                : "Assign Agency Worker for Shift"}
            </h2>
            {shift.agencyLocum ? (
              <ShiftModalDetailGroup>
                <p>To assign another worker remove assigned worker below.</p>
                <AgencyWorkerBox>
                  <div>
                    <AgencyWorkerBoxName>
                      {shift.agencyLocum.firstName} {shift.agencyLocum.lastName}
                    </AgencyWorkerBoxName>
                    <AgencyWorkerBoxGrade>
                      <strong>{shift.agencyLocum.grade.title}</strong>
                    </AgencyWorkerBoxGrade>
                    <ProposalRates>
                      {shift?.agencyBooking?.directEngagement ? (
                        <h3 class="agency__green">Direct Engagement</h3>
                      ) : (
                        <h3 class="agency__red">Non-Direct Engagement</h3>
                      )}

                      {!shift?.agencyBooking?.splitRate ? (
                        <>
                          <p>
                            Total Rate:{" "}
                            <span>
                              £{shift?.agencyBooking?.coreTotalRate.toFixed(2)}
                              /hr
                            </span>
                          </p>
                          <p>
                            Worker Rate:{" "}
                            <span>
                              {" "}
                              £{shift?.agencyBooking?.coreLocumRate.toFixed(2)}
                              /hr
                            </span>
                          </p>
                          <p>
                            NI Contribution:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.coreNiContribution.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <p>
                            Agency Margin:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.coreAgencyMargin.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p>
                            Total Rate:{" "}
                            <span>
                              £{shift?.agencyBooking?.coreTotalRate.toFixed(2)}
                              /hr
                            </span>
                          </p>
                          <p>
                            Worker Rate:{" "}
                            <span>
                              {" "}
                              £{shift?.agencyBooking?.coreLocumRate.toFixed(2)}
                              /hr
                            </span>
                          </p>
                          <p>
                            NI Contribution:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.coreNiContribution.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <p>
                            Agency Margin:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.coreAgencyMargin.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <hr />
                          <p>
                            Unsocial Total Rate:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.unsocialTotalRate.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <p>
                            Unsocial Worker Rate:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.unsocialLocumRate.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <p>
                            Unsocial NI Contribution:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.unsocialNiContribution.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                          <p>
                            Unsocial Agency fee:{" "}
                            <span>
                              {" "}
                              £
                              {shift?.agencyBooking?.unsocialAgencyMargin.toFixed(
                                2
                              )}
                              /hr
                            </span>
                          </p>
                        </>
                      )}
                    </ProposalRates>
                  </div>
                  <AgencyWorkerBoxRemove
                    onClick={() => {
                      alert(
                        "Agency workers cannot be removed from a shift after the booking has been confirmed. \n\nIf you would like to amend this booking, please contact the relevant organisation's staffing team directly - thank you!"
                      );
                    }}
                  >
                    Remove
                  </AgencyWorkerBoxRemove>
                </AgencyWorkerBox>
              </ShiftModalDetailGroup>
            ) : (
              <ShiftModalDetailGroup>
                <AssignWorkerContainer>
                  <ShiftModalAssign
                    action={setWorkerAction}
                    actionText={"Assign"}
                    actionPressText={"Assigned"}
                    data={workers}
                    error={error}
                    inputFunction={inputFunction}
                    inputValue={workerName}
                    label={"Search Agency Workers"}
                    loading={loading}
                    selectedValue={worker && worker.id}
                    type={"worker"}
                    gradeOptions={gradeOptions}
                  />
                </AssignWorkerContainer>
              </ShiftModalDetailGroup>
            )}
          </>
        ) : modalStep === 2 ? (
          <div>
            <h2>Assign Agency Worker for Shift</h2>
            {worker && (
              <AgencyWorkerBox style={{ marginBottom: 24 }}>
                <div>
                  <AgencyWorkerBoxName>
                    {worker.firstName} {worker.lastName}
                  </AgencyWorkerBoxName>
                  <AgencyWorkerBoxGrade>
                    {worker.grade.title}, {worker.agency?.title}
                  </AgencyWorkerBoxGrade>
                </div>

                <AgencyWorkerBoxRemove
                  onClick={() => {
                    setWorker(undefined);
                    setWorkerName("");
                    setModalStep(1);
                    setError({ error: false });
                  }}
                >
                  Remove
                </AgencyWorkerBoxRemove>
              </AgencyWorkerBox>
            )}
            <ShiftModalDetailGroup>
              <AssignAgencyWorkerTabs>
                <button
                  className={agencyTab === "single" ? "active" : ""}
                  onClick={() => setAgencyTab("single")}
                >
                  Single Rate
                </button>
                <button
                  className={agencyTab === "split" ? "active" : ""}
                  onClick={() => setAgencyTab("split")}
                >
                  Split Core/Unsocial Rate
                </button>
              </AssignAgencyWorkerTabs>
              {agencyTab === "single" && (
                <AgencyTabSingle
                  error={error}
                  handleSingleInputChange={handleSingleInputChange}
                  singleDirectEngagement={singleDirectEngagement}
                  toggleSingleDirectEngagement={toggleSingleDirectEngagement}
                  toggleSingleFrameWorkerCompliant={
                    toggleSingleFrameWorkerCompliant
                  }
                  singleFrameWorkerCompliant={singleFrameWorkerCompliant}
                  vms={true}
                />
              )}
              {agencyTab === "split" && (
                <AgencyTabSplit
                  error={error}
                  handleSplitInputChange={handleSplitInputChange}
                  toggleSplitDirectEngagement={toggleSplitDirectEngagement}
                  splitDirectEngagement={splitDirectEngagement}
                  toggleSplitFrameWorkerCompliant={
                    toggleSplitFrameWorkerCompliant
                  }
                  splitFrameWorkerCompliant={splitFrameWorkerCompliant}
                  vms={true}
                />
              )}
            </ShiftModalDetailGroup>
            <ShiftModalDetailGroup>
              <AgencyOptionalInfo
                handleOptionalInputChange={handleOptionalInputChange}
              />
            </ShiftModalDetailGroup>
          </div>
        ) : (
          ""
        )}
      </ShiftModalContainer>
    </>
  );
};

export default ShiftModalVMSAssignAgencyWorker;

const AssignWorkerContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 250px;
`;

const AgencyWorkerBox = styled.div`
  align-items: center;
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  padding: 12px 24px;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const AgencyWorkerBoxRemove = styled.button`
  align-items: center;
  background: #ec7571;
  border: 0;
  border-radius: 6px;
  color: #fff;
  display: flex;
  font-size: 12px;
  font-weight: 800;
  height: 32px;
  justify-content: center;
  text-transform: uppercase;
  width: 96px;
`;

const AgencyWorkerBoxName = styled.p`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`;

const AgencyWorkerBoxGrade = styled.p`
  margin: 0;
`;

const ProposalRates = styled.div`
  padding-top: 20px;

  p {
    line-height: 0.7;
  }
  span {
    font-weight: bold;
  }

  .agency__green {
    color: #209d1d;
    position: absolute;
    right: 20px !important;
    top: 15px !important;
  }

  .agency__red {
    color: #e45b5b;
    position: absolute;
    right: 20px !important;
    top: 15px !important;
  }
`;
