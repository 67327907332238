import { Controller } from 'stimulus'

export default class RequestRateController extends Controller {
    static get targets() {
        return ['authoriser']
    }
    connect() {
        this.element[this.identifier] = this
    }
    ping(event) {
        return 'pong'
    }
}
