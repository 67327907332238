import { Controller } from 'stimulus'
import queryString from 'query-string'

export default class ToggleController extends SearchController {

    static get targets() {
        return ['left', 'right']
    }

    connect() {
    }

    left() {
        this.search(this.data.get('left-value'))
    }

    right() {
        this.search(this.data.get('right-value'))
    }

    search(search_value) {
        let params = queryString.parse(location.search)
        if (params[this.data.get('param')] == search_value) {
            delete (params[this.data.get('param')])
        }
        else {
            params[this.data.get('param')] = search_value
        }

        this.clearSpecialParams(params);

        const url = queryString.stringify(params)
        window.location.search = url
    }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("toggle", ToggleController)