import { Controller } from 'stimulus'

export default class AgencyTableRowController extends Controller {

    connect () {
    }

    get actionBarController() {
        const action_bar_element = document.getElementById('action-bar')
        const action_bar = this.application.getControllerForElementAndIdentifier(action_bar_element, "action_bar")
        return action_bar
    }

    toggle () {
        this.actionBarController.toggle()
    }
}

import { application } from '../../frontend/controllers/index'
application.register("table-row", AgencyTableRowController)