export async function createAgencyTier(body) {
  const url = `/agency_tiers/add`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      if (data.success === false) return "err";
      else return data;
    });
  return data;
}

export async function removeAgencyTier(agency_tier_id, body) {
  const url = `/agency_tiers/${agency_tier_id}/remove`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => {
      return data;
    });
  return data;
}
