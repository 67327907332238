import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

import { ErrorContainer } from "../../../Forms/FormElements";
import {
  CloseButton,
  Loading,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../../Modals/ModalElements";
import { processPayments } from "./ProcessPaymentsHelpers";

const ProcessPaymentsModal = ({
  inAgency,
  allShiftsToggle,
  collabBank,
  forReceipts,
  handleClose,
  orgStaffGroupId,
  payrollCycle,
  show,
}) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setError({});
  }, [setError]);

  const checkedShifts = document.querySelector(".list");
  const duplicatesShifts = Array.from(
    checkedShifts.querySelectorAll(".custom-control-input:checked")
  ).map((item) => item.value);

  const shifts = duplicatesShifts.filter(function (elem, pos) {
    return duplicatesShifts.indexOf(elem) == pos;
  });

  const allShifts = document.getElementById("all-shifts").value;
  const removeBrackets = allShifts.substring(1, allShifts.length - 1);
  const cleanedShifts = removeBrackets.split(", ");

  const bodyShifts = allShiftsToggle ? cleanedShifts : shifts;

  const processPaymentAction = (e) => {
    e.preventDefault();
    setError({});
    setLoading(true);

    let url = ''
    let body = {}

    if (forReceipts) {
      url = `/ppi_batches`
      body = {
        ppi_batch: {
          receipt_ids: bodyShifts,
          payroll_cycle: payrollCycle,
          organisation_staff_group_id: orgStaffGroupId.toString(),
        },
      }
    } else {
      url = `/batches`
      body = {
        batch: {
          shift_ids: bodyShifts,
          payroll_cycle: payrollCycle,
          organisation_staff_group_id: orgStaffGroupId.toString(),
          in_collaborative_bank: collabBank ? 1 : 0,
          in_agency: inAgency ? 'true' : 'false',
        },
      }
    }
    processPayments(url, body).then((data) => {
      if (data !== "err") {
        window.location = `${url}/${data.id}`;
      } else {
        setLoading(false);
        setError({
          error: true,
          message: `There was a problem processing these payments.`,
        });
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
    >
      <ModalHeader>
        <ModalTitle>
          Submit {allShiftsToggle ? cleanedShifts.length : shifts.length} {forReceipts ? 'receipts' : 'shifts'} for
          payment
        </ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ModalBody style={{ fontSize: 20, minHeight: 0 }}>
            You are about to submit these{" "}
            <strong>
              <em>
                {allShiftsToggle ? cleanedShifts.length : shifts.length} {forReceipts ? 'receipts' : 'shifts' }
              </em>
            </strong>{" "}
            for payment. <br />
            Please click below to confirm or cancel this action.
          </ModalBody>
          <ModalFooter>
            <div>
              {error.error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleClose}>
                Cancel
              </button>
              <button
                className="btn text-white btn-cyan--modal"
                onClick={(e) => processPaymentAction(e)}
              >
                Submit {allShiftsToggle ? cleanedShifts.length : shifts.length}{" "}
                {forReceipts ? 'receipts' : 'shifts' }
              </button>
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ProcessPaymentsModal;
