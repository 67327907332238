import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  LoadingContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { createSite, editSite } from "./SitesHelpers";

const SitesModal = ({ handleClose, show, site, regions }) => {
  const [error, setError] = useState({ name: "" });
  const [inputs, setInputs] = useState({ name: "" });
  const [loading, setLoading] = useState(false);
  const [regionOptions, setRegionOptions] = useState({});

  const mappedRegions = () => {
    return regions.map((region) => {
      return {
        label: region.name,
        value: region.id,
      }
    });
  };

  useEffect(() => {
    setRegionOptions(mappedRegions());
  }, []);

  useEffect(() => {
    setInputs({});

    if (site) {
      let regionChosen = null;
      if (site.region_id) {
        let region = regions.find((r) => r.id === site.region_id)
        regionChosen = { label: region.name, value: region.id }
      }
      setInputs({
        name: site.name ? site.name : "",
        latitude: site.latitude ? site.latitude : "",
        longitude: site.longitude ? site.longitude : "",
        maps_url: site.maps_url ? site.maps_url : "",
        regionChosen: site.region_id ? regionChosen : null,
      });
    }
  }, [site]);

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const createSiteAction = () => {
    setError({});

    // Check logic here?
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a name." });
    } else if (!isLatitude(inputs.latitude)) {
      setError({
        ...error,
        latitude: "Please select a correct latitude format",
      });
    } else if (!isLongitude(inputs.longitude)) {
      setError({
        ...error,
        longitude: "Enter Correct Longitude",
      });
    } else {
      setLoading(true);
      setError({});
      if (inputs) {
        const body = {
          site: {
            name: inputs.name,
            latitude: inputs.latitude,
            longitude: inputs.longitude,
            maps_url: inputs.maps_url,
            region_id: inputs.regionChosen
              ? inputs.regionChosen.value
              : null,
          },
        };
        createSite(body).then((data) => {
          if (data !== "err") {
            window.location.reload(true);
          } else {
            setLoading(false);
            setError({
              error: true,
              message: `There was a problem creating this Site.`,
            });
          }
        });
      }
    }
  };

  const editsiteAction = (siteId) => {
    setError({});
    if (inputs.name === "") {
      setError({ ...error, name: "Please enter a name." });
    } else if (!isLatitude(inputs.latitude)) {
      setError({
        ...error,
        latitude: "Enter correct Latitude.",
      });
    } else if (!isLongitude(inputs.longitude)) {
      setError({
        ...error,
        longitude: "Enter Correct Longitude",
      });
    } else {
      setLoading(true);
      const body = {
        site: {
          name: inputs.name,
          latitude: inputs.latitude,
          longitude: inputs.longitude,
          maps_url: inputs.maps_url,
          region_id: inputs.regionChosen
            ? inputs.regionChosen.value
            : null,
        },
      };
      editSite(siteId, body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setLoading(false);
          setError({
            error: true,
            message: `There was a problem updating this Site.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>{site ? `Edit ${site.name}` : "Create Site"}</ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http:www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0, overflowY: "visible" }}>
            <SiteForm>
              <FormDetailGroup>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"input"}
                    value={inputs.name}
                    placeholder={"Enter a name"}
                    label={"Site Name"}
                    name="name"
                    onChange={handleInputChange}
                    type="text"
                    required
                    error={error.name}
                  />
                </FormGrid>
                <FormGrid columns={3}>
                  <FormGroup
                    component={"input"}
                    value={inputs.latitude}
                    placeholder={"Latitude"}
                    label={"Latitude"}
                    name="latitude"
                    onChange={handleInputChange}
                    type="text"
                    error={error.latitude}
                  />
                  <FormGroup
                    component={"input"}
                    value={inputs.longitude}
                    placeholder={"Longitude"}
                    label={"Longitude"}
                    name="longitude"
                    onChange={handleInputChange}
                    type="text"
                    error={error.longitude}
                  />
                  <FormGroup
                    component={"input"}
                    value={inputs.maps_url}
                    placeholder={"Map Url"}
                    label={"Map Url"}
                    name="maps_url"
                    onChange={handleInputChange}
                    type="text"
                    error={error.maps_url}
                  />
                </FormGrid>
                <FormGrid>
                  <FormGroup
                    component={"react-select"}
                    label={"Region"}
                    options={regionOptions}
                    isClearable
                    isMulti={false}
                    placeholder="Select region"
                    value={inputs.regionChosen}
                    onChange={(option) => {
                      setError({});
                      setInputs(() => ({
                        ...inputs,
                        regionChosen: option,
                      }));
                    }}
                  />
                </FormGrid>
              </FormDetailGroup>
            </SiteForm>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleCloseAction}>
                Cancel
              </button>
              {site ? (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => editsiteAction(site.id)}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => createSiteAction()}
                >
                  Create Site
                </button>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default SitesModal;

const SiteForm = styled.div`
  max-width: 600px;
`;

function isLatitude(lat) {
  return isFinite(lat) && Math.abs(lat) <= 90;
}

function isLongitude(lng) {
  return isFinite(lng) && Math.abs(lng) <= 180;
}
