import { gql } from "@apollo/client";

export const GET_SPECIALITIES = gql`
  query GetSpecialities {
    specialities {
      id
      title
    }
  }
`;

export const CREATE_SPECIALITY = gql`
  mutation CreateSpeciality($title: String!) {
    createSpeciality(title: $title) {
      errors {
        attribute
        message
      }
      speciality {
        id
        title
      }
    }
  }
`;

export const UPDATE_SPECIALITY = gql`
  mutation UpdateSpeciality($id: Int!, $title: String!) {
    updateSpeciality(id: $id, title: $title) {
      errors {
        attribute
        message
      }
      speciality {
        id
        title
      }
    }
  }
`;

export const DELETE_SPECIALITY = gql`
  mutation DeleteSpeciality($id: Int!) {
    deleteSpeciality(id: $id) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;
