import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class LocumSearchController extends Controller {
    static get targets() {
        return ['locum_name','locum_id','label']
    }

    connect() {
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target = document.querySelector('#locum-selector');
        const searchOnSelect = this.data.get('search-on-select');

        this.select = new SlimSelect({
            select: '#locum-selector',
            onChange: () => {
                let params = queryString.parse(location.search);
                if (searchOnSelect) {
                    if (target.value === "All") {
                        params['locum_id'] = null;
                        const url = queryString.stringify(params);
                        window.location.search = url
                        return
                    }
                    if (params['locum_id'] != target.value) {
                        params['locum_id'] = target.value;
                        const url = queryString.stringify(params);
                        window.location.search = url
                    }
                }
            }
        });

        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }
    }

    select(event) {
        event.preventDefault()
        const locum_id = event.toElement.dataset.id
        this.locum_idTarget.value = locum_id
        this.staff_group_idTarget.value = "1" // TODO: Use the real staff group

        const staff_group_label = this.findParentMenuButton(event).textContent.trim()
        const locum_label = event.toElement.textContent.trim()
        const new_label = `${staff_group_label}, ${locum_label}`
        this.labelTarget.textContent = new_label
    }

    findParentMenuButton(event){
        return event.toElement.parentElement.parentElement.getElementsByTagName('button')[0]
    }
}
import { application } from '../../frontend/controllers/index'

application.register("locum_search", LocumSearchController)