import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { createStaffGroup, editStaffGroup } from "./StaffGroupHelpers";

const StaffGroupModal = ({ data, handleClose, staffGroup, show }) => {
  const [error, setError] = useState({ title: "" });
  const [inputs, setInputs] = useState({ title: "" });

  // remap regulationBodies into an array react-select can read
  const regulationBodies = data.regulationBodies.map(function (regulationBody) {
    return { value: regulationBody.id, label: regulationBody.name };
  });

  useEffect(() => {
    if (staffGroup) {
      setInputs({
        title: staffGroup.title ? staffGroup.title : "",
        regulation_body_id: staffGroup.regulation_body
          ? staffGroup.regulation_body.id
          : "",
        speciality_ids: staffGroup.specialities
          ? staffGroup.specialities
          : null,
        specialities: data.specialities
          .map((spec) => ({
            value: spec.id,
            label: spec.title,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        selectedSpecialities: staffGroup.specialities
          .map((spec) => ({
            value: spec.id,
            label: spec.title,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    } else {
      setInputs({
        ...inputs,
        specialities: data.specialities
          .map((spec) => ({
            value: spec.id,
            label: spec.title,
          }))
          .sort((a, b) => a.label.localeCompare(b.label)),
      });
    }
  }, [staffGroup]);

  const handleCloseAction = () => {
    setInputs({ title: "" });
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleRegulationBodyChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, regulation_body_id: event.value }));
  };

  const createStaffGroupAction = () => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a name." });
    } else {
      setError({});
      if (inputs) {
        const body = {
          staff_group: {
            title: inputs.title,
            regulation_body_id: inputs.regulation_body_id
              ? inputs.regulation_body_id
              : null,
            speciality_ids: inputs.speciality_ids
              ? inputs.speciality_ids
              : null,
          },
        };
        createStaffGroup(body).then((data) => {
          if (data !== "err") {
            window.location.reload(true);
          } else {
            setError({
              error: true,
              message: `There was a problem creating this Regulation Body.`,
            });
          }
        });
      }
    }
  };

  const editStaffGroupAction = (staffGroupId) => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a name." });
    } else {
      const body = {
        staff_group: {
          title: inputs.title,
          regulation_body_id: inputs.regulation_body_id
            ? inputs.regulation_body_id
            : null,
          speciality_ids: inputs.speciality_ids ? inputs.speciality_ids : null,
        },
      };
      editStaffGroup(staffGroupId, body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setError({
            error: true,
            message: `There was a problem updating this Regulation Body.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {staffGroup ? `Edit ${staffGroup.title}` : "Create Staff Group"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ overflowY: "visible" }}>
        <StaffGroupForm>
          <FormDetailGroup>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                value={inputs.title}
                placeholder={"Enter a name"}
                label={"Staff Group Name"}
                name="title"
                onChange={handleInputChange}
                type="text"
                required
                error={error.title}
              />
              <FormGroup
                component={"react-select"}
                label={"Regulation Body"}
                options={regulationBodies}
                placeholder="Please select a regulation body"
                name="regulation_body_id"
                onChange={handleRegulationBodyChange}
                defaultValue={
                  staffGroup && {
                    label: staffGroup.regulation_body?.name,
                    value: staffGroup.regulation_body?.id,
                  }
                }
              />
            </FormGrid>
            <FormGrid columns={1} style={{ position: "relative" }}>
              <AddButton
                onClick={() => {
                  setInputs((inputs) => ({
                    ...inputs,
                    selectedSpecialities: inputs.specialities,
                    speciality_ids: inputs.specialities.map((o) => o.value),
                  }));
                }}
              >
                ADD ALL
              </AddButton>
              <FormGroup
                component={"react-select"}
                label={"Specialities"}
                options={inputs.specialities}
                placeholder="Please select specialities"
                name="speciality_ids"
                value={inputs.selectedSpecialities}
                isMulti
                onChange={(option, e) => {
                  setError({});

                  if (e.action == "clear") {
                    let ans = window.confirm(
                      "Are you sure you want to remove Specialities?"
                    );
                    if (!ans) return;
                  }
                  setInputs((inputs) => ({
                    ...inputs,
                    selectedSpecialities: option === null ? [] : option,
                    speciality_ids: option.map((o) => o.value),
                  }));
                }}
              />
            </FormGrid>
          </FormDetailGroup>
        </StaffGroupForm>
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleClose}>
            Cancel
          </button>
          {staffGroup ? (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => editStaffGroupAction(staffGroup.id)}
            >
              Save Changes
            </button>
          ) : (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => createStaffGroupAction()}
            >
              Create Staff Group
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default StaffGroupModal;

const StaffGroupForm = styled.div`
  max-width: 100%;
`;

const AddButton = styled.button`
  font-size: 12px;
  position: absolute;
  right: 0px;
  top: -5px;
  border-radius: 5px;
  border: 0;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
  background-color: #4dd6ea;
  padding: 4px 8px;
`;
