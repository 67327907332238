/* eslint-disable react/jsx-no-useless-fragment */
import moment from 'moment'
import React from 'react'
import { toast } from 'react-toastify'
import { ShiftStatus, LeaveStatus, DefaultButton, CloseButton } from '../helpers/BlockBookingsHelpers'
import { OpenIcon, AmendmentClock, EditShiftIcon } from '../helpers/BlockIcons'
import {
  TableRow,
  TimeSheetActions,
  TimesheetsInnerTable,
  TimesheetsInnerTableHeader,
  TimesheetsInnerTableBody,
  TableInnerRow,
  TableIconFunction,
  ExpandedRow,
  checkStatus,
  InnerCell,
  calculateHours,
  calculateMinimumBreaks,
} from './TimesheetHelpers'

import 'react-datepicker/dist/react-datepicker.css'

function TimesheetsTableOne({ timesheets, canOverrideBreakDuration, inputs, setInputs }) {
  const { expandedRow } = inputs

  const getBreak = (s) => {
    if (s?.shouldEnforceBreaks && s?.minimumBreakDuration !== null && s?.minimumBreakDuration > 0) {
      return s?.minimumBreakDuration
    }
    return s?.breakMinutes
  }

  const changeShiftTimes = (date, s, isStart) => {
    const originalTime = isStart ? s.startTime : s.endTime

    const overTimeHours = moment(date.target.value).diff(moment(originalTime), 'minutes')
    let updatedTimesheet = inputs.activeTimesheet.map((j) => {
      if (j.id !== s.id) return j
      return {
        ...j,
        workerSignOff: {
          ...j.workerSignOff,
          [isStart ? 'startTimeDiff' : 'endTimeDiff']: overTimeHours,
          [isStart ? 'reportedStartTime' : 'reportedEndTime']: moment(date.target.value).format('YYYY-MM-DDTHH:mm:ss'),
        },
      }
    })

    if (s.shouldEnforceBreaks) {
      updatedTimesheet = updatedTimesheet.map((i) => {
        if (i.id === s.id) {
          const breaky = calculateMinimumBreaks(i)
          return {
            ...i,
            workerSignOff: {
              ...i.workerSignOff,
              reportedBreaks: breaky,
            },
          }
        }
        return i
      })
    }

    setInputs({ ...inputs, activeTimesheet: updatedTimesheet })
  }
  const handleReset = (s) => {
    setInputs({
      ...inputs,
      activeTimesheet: inputs.activeTimesheet.map((j) =>
        j.id === s.id
          ? {
              ...j,
              workerSignOff: {
                ...j.workerSignOff,
                reset: !j.workerSignOff.reset,
                breaksDiff: j.workerSignOff.reset ? Number(j?.workerSignOff?.initialBreaks) - getBreak(j) : 0,
                endTimeDiff: j.workerSignOff.reset
                  ? moment(j?.workerSignOff?.initialEndTime).diff(moment(j.endTime), 'minutes')
                  : 0,
                startTimeDiff: j.workerSignOff.reset
                  ? moment(j?.workerSignOff?.initialStartTime).diff(moment(j.startTime), 'minutes')
                  : 0,
                reportedStartTime: j.workerSignOff.reset ? j.workerSignOff.initialStartTime : j.startTime,
                reportedEndTime: j.workerSignOff.reset ? j.workerSignOff.initialEndTime : j.endTime,
                reportedBreaks: j.workerSignOff.reset ? j.workerSignOff.initialBreaks : getBreak(j),
              },
            }
          : j
      ),
    })
  }

  const amendBreaks = (e, s) => {
    if (s.shouldEnforceBreaks && !canOverrideBreakDuration) {
      if (Number(e.target.value) < calculateMinimumBreaks(s)) {
        toast.error(`User permissions required to override enforced breaks of ${calculateMinimumBreaks(s)} min `, {
          hideProgressBar: true,
          position: 'top-right',
        })
        return
      }
    }

    setInputs({
      ...inputs,
      activeTimesheet: inputs.activeTimesheet.map((j) =>
        j.id === s.id
          ? {
              ...j,
              workerSignOff: {
                ...j.workerSignOff,
                breaksDiff: Number(e.target.value) - Number(getBreak(s)),
                reportedBreaks: e.target.value,
              },
            }
          : j
      ),
    })
  }

  /* Check Timesheet Actions -------------------------------------------------- */

  const checkTimesheetActions = (status) => {
    const handleSignOffClick = () => {
      setInputs({
        ...inputs,
        showSignOffModal: true,
      })
    }

    const handleApprovalClick = () => {
      setInputs({
        ...inputs,
        showApprovalModal: true,
      })
    }

    return (
      <TimeSheetActions>
        {status === 'SIGN_OFF_REQUESTED' && (
          <DefaultButton action={handleSignOffClick} className="button" text="Bulk Sign Off" type="blue" />
        )}

        {status === 'SIGNED_OFF' && (
          <DefaultButton action={handleApprovalClick} className="button" text="Bulk Approve" type="blue" />
        )}
      </TimeSheetActions>
    )
  }

  /* Handle Row Click --------------------------------------------------------- */

  const handleRowClick = (rowId) => {
    setInputs({
      ...inputs,
      skip: false,
      expandedRow: rowId === inputs.expandedRow ? null : rowId,
      editShift: null,
      activeTimesheet: [],
    })
  }

  return (
    <>
      {timesheets?.map((row, index) => {
        return (
          <>
            <TableRow
              key={row.id}
              active={row.id === expandedRow}
              odd={index % 2 === 0}
              onClick={() => handleRowClick(row?.id)}
            >
              <div>
                <h3 style={{ fontWeight: '600', marginBottom: 8 }}>
                  {moment(row?.startsAt).format('Do')} - {moment(row?.endsAt).format('Do')}
                </h3>
                <h3> {moment(row?.endsAt).format('MMM YYYY')}</h3>
              </div>
              <div>{row.hoursExpected}</div>
              <div>{['SIGNED_OFF', 'APPROVED', 'PAYROLL'].includes(row.status) ? row.hoursWorked : '-'}</div>

              <div>
                <ShiftStatus type={row.status} />
              </div>

              <div className={`openIcon__wrapper ${row.id === expandedRow && 'active'}`}>
                <OpenIcon />
              </div>
            </TableRow>

            {/* 1st Dropdown content ---------------------------------------------------- */}

            {expandedRow === row.id && (
              <ExpandedRow>
                <div className="extended__row__header">
                  <h2>
                    Hours Worked:{' '}
                    {inputs.tab === 1 && <strong>({calculateHours(row.status, inputs.activeTimesheet)})</strong>}
                  </h2>
                  {inputs.tab === 1 && checkTimesheetActions(row.status)}
                </div>

                <TimesheetsInnerTable>
                  <TimesheetsInnerTableHeader>
                    <div style={{ minWidth: 70 }}>Shift ID</div>
                    <div>Scheduled Hrs</div>
                    <div style={{ minWidth: 160 }}>Hrs Worked</div>
                    <div>Breaks</div>
                    <div style={{ minWidth: 130 }}>Status</div>
                    <div style={{ minWidth: 50 }} />
                    <div />
                  </TimesheetsInnerTableHeader>
                  <TimesheetsInnerTableBody>
                    {inputs?.activeTimesheet?.map((shift) => {
                      const {
                        originalStartTime,
                        originalEndTime,
                        reportedStartTime,
                        reportedEndTime,
                        status,
                        reportedBreaks,
                        reset,
                      } = shift?.workerSignOff || {}

                      const calMinBreaks = inputs.tab === 1 ? calculateMinimumBreaks(shift) : 0

                      return (
                        <TableInnerRow key={row.id} active={row.id === expandedRow} odd={index % 2 === 0}>
                          <InnerCell width="70px">
                            {/* Shift ID ---------------------------------------------- */}
                            <a
                              href={`/shifts?filter_by_shift_blocks=true&only_search_id=true&shift_ids%5B%5D=${shift.id}`}
                              rel="noreferrer"
                              style={{
                                textDecoration: 'underline',
                                fontSize: 11,
                              }}
                              target="_blank"
                            >
                              {shift?.id}
                            </a>
                          </InnerCell>

                          {/* Scheduled Hours ---------------------------------------- */}

                          <InnerCell>
                            <h4 style={{ fontWeight: 500, marginBottom: 6 }}>
                              {moment(shift?.startTime).format('D MMM YY')}
                            </h4>
                            <h4>
                              {shift?.workerSignOff && (
                                <>
                                  {moment(originalStartTime).format('HH:mm')} -{moment(originalEndTime).format('HH:mm')}
                                </>
                              )}
                              {!shift?.workerSignOff && (
                                <>
                                  {moment(shift?.startTime).format('HH:mm')} -{moment(shift?.endTime).format('HH:mm')}
                                </>
                              )}
                            </h4>
                          </InnerCell>

                          {/* Hrs Worked ----------------------------------------- */}

                          {reset && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                minWidth: 160,
                                justifyContent: 'space-evenly',
                              }}
                            >
                              <input
                                className="custom__date__picker"
                                onChange={(e) => changeShiftTimes(e, shift, true)}
                                type="datetime-local"
                                value={moment(reportedStartTime).format('YYYY-MM-DDTHH:mm')}
                              />
                              <input
                                className="custom__date__picker"
                                onChange={(e) => changeShiftTimes(e, shift, false)}
                                type="datetime-local"
                                value={moment(reportedEndTime).format('YYYY-MM-DDTHH:mm')}
                              />
                            </div>
                          )}

                          {!reset && (
                            <InnerCell width="160px">
                              <h4 style={{ fontWeight: 500, marginBottom: 6 }}>
                                {moment(shift?.startTime).format('D MMM YY')}
                              </h4>
                              <h4>
                                {!shift?.workerSignOff && <div> - </div>}
                                {shift?.workerSignOff && (
                                  <>
                                    {status === 'changes_amended'
                                      ? moment(shift?.startTime).format('HH:mm')
                                      : moment(reportedStartTime).format('HH:mm')}
                                    -
                                    {status === 'changes_amended'
                                      ? moment(shift?.endTime).format('HH:mm')
                                      : moment(reportedEndTime).format('HH:mm')}
                                    {!status.match(/no_chang/) && <AmendmentClock />}
                                  </>
                                )}
                              </h4>
                            </InnerCell>
                          )}
                          {/* Breaks --------------------------------------------- */}

                          {!reset && (
                            <InnerCell>
                              {status === 'changes_amended' || shift.workerSignOff === null
                                ? shift.breakMinutes
                                : reportedBreaks}
                              mins
                            </InnerCell>
                          )}

                          {reset && (
                            <InnerCell>
                              <input
                                className="break__input"
                                onChange={(e) => amendBreaks(e, shift)}
                                placeholder="Enter Break in minutes"
                                type="number"
                                value={Number(reportedBreaks)}
                              />
                            </InnerCell>
                          )}

                          {/* Status --------------------------------------------- */}

                          <div style={{ minWidth: 140, display: 'flex', alignItems: 'center' }}>
                            <LeaveStatus status={shift?.status}>{checkStatus(shift?.status)}</LeaveStatus>
                          </div>

                          {/* Actions --------------------------------------------- */}

                          <InnerCell onClick={() => handleReset(shift)} width="40px">
                            {/* Only show sign off Requested Icon if status.row is ACTIVE */}
                            {['SIGN_OFF_REQUESTED'].includes(row.status) &&
                              shift.status === 'SIGN_OFF_REQUESTED' &&
                              (!shift.workerSignOff.reset ? <EditShiftIcon /> : <CloseButton />)}

                            {shift?.confirmedBy &&
                              ['SIGNED_OFF', 'APPROVED', 'PAYROLL', 'PAID'].includes(shift.status) && (
                                <TableIconFunction details={shift} />
                              )}
                          </InnerCell>

                          {/* Info ---------------------------------------------------- */}
                          {inputs.tab === 1 && (
                            <InnerCell>
                              {!shift.confirmedBy &&
                                !reset &&
                                !status.match(/no_chang/) &&
                                shift.workerSignOff?.reportedNotes && <TableIconFunction details={shift} />}
                              {/* Calc min breaks if needed -------- */}
                              {shift.shouldEnforceBreaks &&
                                shift.minimumBreakDuration !== null &&
                                reset &&
                                calMinBreaks > 0 && (
                                  <p style={{ margin: 0, fontSize: 11 }}>
                                    Shift has minimum enforced breaks of <strong>{calMinBreaks} mins</strong>
                                  </p>
                                )}
                            </InnerCell>
                          )}
                        </TableInnerRow>
                      )
                    })}
                  </TimesheetsInnerTableBody>
                </TimesheetsInnerTable>
              </ExpandedRow>
            )}
          </>
        )
      })}
    </>
  )
}

export default TimesheetsTableOne
