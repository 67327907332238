import { DefaultButton } from '@patchworkhealth/web-components'
import React from 'react'
import styled from 'styled-components'
import { ShiftModalTitle } from '../helpers/BlockComponents'
import ProfilePng from '../helpers/profilePic.png'

function ViewBlockApplications({ block, bookBlock, rejectBlock }) {
  const applications = block?.shiftBlockApplications

  return (
    <>
      <ShiftModalTitle>Applications</ShiftModalTitle>

      {applications && applications.nodes.length > 0 ? (
        <>
          <h5>
            The following workers have applied for block <strong> {block.uid}</strong>
          </h5>

          {applications.nodes.map((worker) => {
            return worker.status === 'REJECTED' || worker.status === 'REVOKED' ? null : (
              <ShiftApplication key={worker.id}>
                <ShiftApplicationInfo>
                  <img alt="pic" src={ProfilePng} />

                  <a href={`/workers?worker_id=${worker.applicant.user.id}`} rel="noreferrer" target="_blank">
                    {worker?.applicant?.user?.firstName} {worker?.applicant?.user?.lastName}
                  </a>

                  <h3>
                    <strong>{worker?.applicant?.user?.filterGrade?.title}</strong>
                  </h3>

                  <p>{worker?.applicant?.user?.email}</p>
                </ShiftApplicationInfo>

                <div>
                  <DefaultButton
                    action={() => rejectBlock(worker)}
                    color="red"
                    style={{ marginRight: 10 }}
                    text="Reject"
                  />

                  <DefaultButton action={() => bookBlock(worker)} color="blue" text="Select" />
                </div>
              </ShiftApplication>
            )
          })}
        </>
      ) : (
        <p>No Applications Currently</p>
      )}
    </>
  )
}

export default ViewBlockApplications

export const ShiftApplication = styled.div`
  align-items: center;
  background: rgb(245, 247, 250);
  border: 1px solid rgb(228, 231, 235);
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  height: 85px;
  justify-content: space-between;
  margin-bottom: 6px;
  padding: 16px;
  position: relative;
  width: 100%;
`

export const ShiftApplicationInfo = styled.div`
  padding-left: 65px;
  position: relative;
  h3 {
    font-size: 10px;
    margin-bottom: 5px;
  }

  p {
    font-size: 10px;
  }
  img {
    border-radius: 50%;
    height: 50px;
    left: 0px;
    position: absolute;
    top: 5px;
    width: 50px;
  }

  a {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 8px;
    text-decoration: underline;
  }
`
