import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components'
import Pagy from '../../../Helpers/Pagy'
import { TableRowEditIcon } from '../../Assets/Icons'
import { ErrorContainer, ShiftModalLabel, ShiftModalReactSelect } from '../../Forms/FormElements'
import { Loading } from '../../Modals/ModalElements'
import {
  TableContainer,
  TableHeaderContainer,
  TableHeaderWrapper,
  TableSearchInput,
  TableComponent,
} from '../../Tables/TableElements'
import { GET_AGENCIES, GET_ORGANISATION_STAFF_GROUPS } from './AgencyListHelpers'
import AgencyListModal from './AgencyListModal'

function AgencyList({ organisationId, isMod }) {
  const [agency, setAgency] = useState(undefined)
  const [deactivated, setDeactivated] = useState(undefined)
  const [name, setName] = useState(undefined)
  const [page, setPage] = useState(1)
  const [show, setShow] = useState(false)

  const {
    loading,
    error,
    data: agencies,
    refetch,
  } = useQuery(GET_AGENCIES, {
    variables: {
      page,
      items: 25,
      agencyTitle: name,
      archived: deactivated,
    },
    fetchPolicy: 'network-only',
  })
  const { data: organisationStaffGroups } = useQuery(GET_ORGANISATION_STAFF_GROUPS, {
    variables: {
      organisationId,
    },
    fetchPolicy: 'network-only',
  })
  const handleClose = () => {
    setAgency(undefined)
    setShow(false)
  }

  const handleShowEdit = (editShowAgency) => {
    setAgency(editShowAgency)
    setShow(true)
  }

  // setup archived? type for react-select
  const options = [{ value: true, label: 'Yes' }]

  const handleDeactivatedChange = (event) => {
    if (event === null) {
      setDeactivated(undefined)
    } else {
      setDeactivated(event.value)
    }
  }

  const filteredAgencies = agencies?.agencies.list
  const pagination = agencies?.agencies.pagination

  return (
    <>
      <TableContainer style={{ marginTop: '-95px' }}>
        <TableHeaderWrapper>
          <div className="shift-table__container">
            <TableHeaderContainer>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 20 }}>
                  <ShiftModalLabel style={{ color: '#fff' }}>Search Agencies</ShiftModalLabel>
                  <TableSearchInput
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Search..."
                    type="text"
                    value={name}
                  />
                </div>
                <div>
                  <ShiftModalLabel style={{ color: '#fff' }}>Show Archived?</ShiftModalLabel>
                  <FilterDropdown
                    className="react-select-container"
                    classNamePrefix="react-select"
                    isClearable
                    onChange={handleDeactivatedChange}
                    options={options}
                    placeholder="Select..."
                  />
                </div>
              </div>
              <div>
                <ShiftModalLabel />
              </div>
            </TableHeaderContainer>
          </div>
        </TableHeaderWrapper>
        {loading && <Loading />} {error && <ErrorContainer>Theres an error loading the agencies.</ErrorContainer>}
        {!loading && !error && (
          <div className="shift-table__container">
            <TableComponent striped>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Agency Name</th>
                  <th>Agency Short Title</th>
                  <th colSpan="2">Staff Groups</th>
                </tr>
              </thead>
              <tbody>
                {filteredAgencies?.map((a) => {
                  const { organisationAgencies, shortTitle, title } = a

                  const orgStaffGroups = organisationAgencies
                    .filter((organisationAgency) => organisationAgency.organisationId === organisationId)
                    .map((organisationAgency) => organisationAgency.organisationStaffGroup)

                  const staffGroups = orgStaffGroups
                    .map((organisationStaffGroup) => organisationStaffGroup.staffGroup.title)
                    .sort()
                    .join(', ')

                  return (
                    <tr>
                      <td> {a.id}</td>
                      <td>
                        <button className="btn btn-text" onClick={() => handleShowEdit(a)} type="button">
                          {title}
                        </button>
                      </td>

                      <td>{shortTitle}</td>
                      <td>{staffGroups}</td>

                      <td style={{ textAlign: 'right' }}>
                        <button className="btn btn-text" onClick={() => handleShowEdit(a)} type="button">
                          <TableRowEditIcon />
                        </button>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </TableComponent>
            <Pagy pagination={pagination} setPage={setPage} />
          </div>
        )}
      </TableContainer>
      {show && (
        <AgencyListModal
          agency={agency}
          handleClose={handleClose}
          handleShowEdit={handleShowEdit}
          isMod={isMod}
          organisationStaffGroups={organisationStaffGroups?.organisationStaffGroups}
          refetchAgencies={refetch}
          show={show}
        />
      )}
    </>
  )
}

export default AgencyList

const FilterDropdown = styled(ShiftModalReactSelect)`
  min-width: 200px;
`
