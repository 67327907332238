import { Controller } from "stimulus";
import queryString from "query-string";

export default class ActionBarController extends Controller {
  static get targets() {
    return [
      'authToken',
      'actionBar',
      'dropup',
      'signOff',
      'approveForPayment',
      'sendToAgency',
      'sendToAgencyVms',
      'updateAgencyTier',
      'assignWorker',
      'recallAgency',
      'assignAgencyWorker',
      'assignAgencyWorkerSessional',
      'undoApproval',
      'undoSignOff',
      'recalculateRates',
      'cancelBooking',
      'cancelAgencyBooking',
      'removeRateEscalation',
      'watch',
      'unwatch',
      'delete',
      'restore',
      'syncToRpa',
      'selectAll',
      'close',
      'sendToSecondaryBank',
      'recallFromSecondaryBank',
      'processPayment',
      'flagReview',
      'returnPayments',
      'amendRates',
      'undoRpaLock',
      'createNewBlock',
      'removeFromBlock',
      'addToBlock',
      'lockToAgency',
      'unlockFromAgency',
      'previewAsBatch',
    ]
  }

  connect() {
    this.shiftType = this.data.get('shift-type')
    this.shiftWord =
      this.shiftType == 'receipt'
        ? { singular: 'Receipt', plural: 'Receipts' }
        : { singular: 'Shift', plural: 'Shifts' }
    this.count = 0
    const controller_this = this
    this.element[this.identifier] = this
    let shiftList = document.getElementsByClassName('shift-row--available')
    const selectAll = this.selectAllTarget
    const close = this.closeTarget
    const shiftSelectAll = document.getElementsByClassName('table-shift-all')[0]
    const actionBarSelected = document.querySelector('.action-bar__checkbox')
    const shiftSelectedLabel = document.querySelector('.action-bar__label')
    const shiftBlockReasonLabel = document.querySelector('.action-bar__main-button--tooltip-text')
    const agencyLockedButton = document.querySelector('.action-bar__main-button--tooltip-text')
    const signOffButtonTooltip = document.querySelector('.action-bar__signoff-button--tooltip-text')

    let selected_shifts = {}
    let selected_staffs = {}
    let selected_grades = {}
    let selected_reasons = {}
    let selected_status = {}
    let selected_agency_tiers = {}
    let selected_agency_locked = {}
    let selected_amended_hours = {}
    let selected_on_call_with_timeframes = {}

    function check() {
      for (let i = 0; i < shiftList.length; i++) {
        let shiftCheckbox = shiftList[i].getElementsByTagName('input')[0]
        shiftCheckbox.addEventListener('change', function () {
          const shift_actions = this.dataset.availableActions ? JSON.parse(this.dataset.availableActions) : null
          const staff_groups = this.dataset.staffGroupId ? JSON.parse(this.dataset.staffGroupId) : null

          const grades = this.dataset.gradeId ? JSON.parse(this.dataset.gradeId) : null

          const reasons = this.dataset.shiftBlockReason ? this.dataset.shiftBlockReason : null

          const status = this.dataset.status ? this.dataset.status : null

          const agency_tier = this.dataset.status ? this.dataset.agencyTierId : null

          const agency_locked = this.dataset.agencyLocked ? this.dataset.agencyLocked : null

          const amended_hours = this.dataset.amendedHours ? JSON.parse(this.dataset.amendedHours) : null

          const on_call_with_timeframes = this.dataset.onCallWithTimeframes
            ? JSON.parse(this.dataset.onCallWithTimeframes)
            : null

          this.checked
            ? controller_this.increment(
                shift_actions,
                selected_shifts,
                staff_groups,
                selected_staffs,
                grades,
                selected_grades,
                reasons,
                selected_reasons,
                status,
                selected_status,
                agency_tier,
                selected_agency_tiers,
                agency_locked,
                selected_agency_locked,
                amended_hours,
                selected_amended_hours,
                on_call_with_timeframes,
                selected_on_call_with_timeframes
              )
            : controller_this.decrement(
                shift_actions,
                selected_shifts,
                staff_groups,
                selected_staffs,
                grades,
                selected_grades,
                reasons,
                selected_reasons,
                status,
                selected_status,
                agency_tier,
                selected_agency_tiers,
                agency_locked,
                selected_agency_locked,
                null,
                amended_hours,
                selected_amended_hours,
                on_call_with_timeframes,
                selected_on_call_with_timeframes
              )

          let available_actions = controller_this.updateShiftActions(selected_shifts)
          let staff_groups_equal = controller_this.checkStaffGroups(selected_staffs)
          let grades_equal = controller_this.checkGrades(selected_grades)
          let block_reasons = controller_this.blockReasons(selected_reasons)
          let status_equal = controller_this.checkStatus(selected_status)
          let agency_tiers_equal = controller_this.checkAgencyTiers(selected_agency_tiers)
          let agency_locked_equal = controller_this.checkAgencyLocked(selected_agency_locked)
          let no_amended_hours = controller_this.checkAmendedHours(selected_amended_hours)
          let no_on_call_with_timeframes = controller_this.checkOnCallWithTimeframes(selected_on_call_with_timeframes)
          const actionBarButtons = document.querySelectorAll('.action-bar__main-button')
          const otherActionBarButtons = document.querySelectorAll('.action-bar__drop-up-btn')

          let gradeReason = ''
          let statusReason = ''

          if (!grades_equal) {
            gradeReason = 'Shift Grades must be the same '
          }

          if (!status_equal) {
            statusReason = 'Shift Status must be the same '
          }

          if (shiftBlockReasonLabel) {
            // label placeholder only available if shift block feature enabled
            if (Object.keys(selected_reasons).length === 0) {
              if (controller_this.count > 1) {
                shiftBlockReasonLabel.innerHTML = gradeReason || statusReason
              } else {
                shiftBlockReasonLabel.innerHTML = 'Please select more than one Shift'
              }
            } else {
              shiftBlockReasonLabel.innerHTML =
                gradeReason + statusReason + controller_this.blockReasons(selected_reasons)
            }
          }

          if (signOffButtonTooltip && no_amended_hours && no_on_call_with_timeframes) {
            signOffButtonTooltip.style.display = 'none'
          } else if (signOffButtonTooltip && !no_on_call_with_timeframes) {
            signOffButtonTooltip.innerHTML =
              'Shifts with call-out timeframes must be opened individually to be signed-off'
            signOffButtonTooltip.style.display = 'block'
          } else if (signOffButtonTooltip && no_on_call_with_timeframes) {
            signOffButtonTooltip.innerHTML = 'Shifts with amended hours must be opened individually to be signed-off'
            signOffButtonTooltip.style.display = 'block'
          }

          for (let index = 0; index < actionBarButtons.length; index++) {
            actionBarButtons[index].disabled = true
          }

          for (let indexAction = 0; indexAction < otherActionBarButtons.length; indexAction++) {
            otherActionBarButtons[indexAction].disabled = true
          }

          if (
            window.location.pathname == '/payments/review' ||
            window.location.pathname == '/payment_collaborative_banks/review' ||
            window.location.pathname == '/payment_receipts/review'
          ) {
            controller_this.returnPaymentsTarget.disabled = false
          }

          if (
            window.location.pathname == '/payments' ||
            window.location.pathname == '/payment_collaborative_banks' ||
            window.location.pathname == '/payment_receipts'
          ) {
            controller_this.processPaymentTarget.disabled = false
            controller_this.flagReviewTarget.disabled = false
            controller_this.previewAsBatchTarget.disabled = false
          }

          available_actions.forEach((name) => {
            if (
              window.location.pathname == '/payments/review' ||
              window.location.pathname == '/payments' ||
              window.location.pathname == '/payment_collaborative_banks' ||
              window.location.pathname == '/payment_collaborative_banks/review' ||
              window.location.pathname == '/payment_receipts' ||
              window.location.pathname == '/payment_receipts/review'
            ) {
              if (name == 'undo_approval' && controller_this.hasUndoApprovalTarget) {
                controller_this.undoApprovalTarget.disabled = false
              }
            } else if (staff_groups_equal == false) {
            } else {
              if (name == 'assign_locum' && controller_this.hasAssignWorkerTarget) {
                controller_this.assignWorkerTarget.disabled = false
              }

              if (name == 'amend_rates' && controller_this.hasAmendRatesTarget) {
                controller_this.amendRatesTarget.disabled = false
                controller_this.amendRatesTarget.style.display = 'block'
              }

              if (name == 'assign_agency_locum' && controller_this.hasAssignAgencyWorkerTarget) {
                controller_this.assignAgencyWorkerTarget.disabled = false
              }

              if (name == 'assign_agency_locum_sessional' && controller_this.hasAssignAgencyWorkerSessionalTarget) {
                controller_this.assignAgencyWorkerSessionalTarget.disabled = false
              }

              if (name == 'delete' && controller_this.hasDeleteTarget) {
                controller_this.deleteTarget.disabled = false
              }

              if (name == 'restore' && controller_this.hasRestoreTarget) {
                controller_this.restoreTarget.disabled = false
              }

              if (name == 'approve_for_payment' && controller_this.hasApproveForPaymentTarget) {
                controller_this.approveForPaymentTarget.disabled = false
              }

              if (name == 'cancel_bank_worker' && controller_this.hasCancelBookingTarget) {
                controller_this.cancelBookingTarget.disabled = false
              }

              if (name == 'cancel_agency_worker' && controller_this.hasCancelAgencyBookingTarget) {
                controller_this.cancelAgencyBookingTarget.disabled = false
              }

              if (name == 'remove_rate_escalation' && controller_this.hasRemoveRateEscalationTarget) {
                controller_this.removeRateEscalationTarget.disabled = false
              }

              if (name == 'recall_agency' && controller_this.hasRecallAgencyTarget) {
                controller_this.recallAgencyTarget.disabled = false
              }

              if (name == 'recalculate_rates' && controller_this.hasRecalculateRatesTarget) {
                controller_this.recalculateRatesTarget.disabled = false
                controller_this.recalculateRatesTarget.style.display = 'block'
              }

              if (name == 'sign_off' && controller_this.hasSignOffTarget) {
                if (no_amended_hours && no_on_call_with_timeframes) {
                  controller_this.signOffTarget.disabled = false
                  controller_this.hideSignOffCaution(controller_this.signOffTarget)
                } else {
                  controller_this.showSignOffCaution(controller_this.signOffTarget)
                }
              }

              if (name == 'send_to_agency' && controller_this.hasSendToAgencyTarget) {
                if (document.querySelector('.send-to-agency')) {
                  controller_this.sendToAgencyTarget.disabled = false
                  controller_this.sendToAgencyTarget.style.display = 'block'
                }
              }

              if (name == 'send_to_agency_vms' && controller_this.hasSendToAgencyVmsTarget) {
                if (document.querySelector('.send-to-agency-vms')) {
                  controller_this.sendToAgencyVmsTarget.disabled = false
                  controller_this.sendToAgencyVmsTarget.style.display = 'block'
                }
              }

              if (name == 'update_tier' && controller_this.hasUpdateAgencyTierTarget) {
                controller_this.updateAgencyTierTarget.disabled = false
                controller_this.updateAgencyTierTarget.style.display = 'block'
              }

              if (name == 'undo_approval' && controller_this.hasUndoApprovalTarget) {
                controller_this.undoApprovalTarget.disabled = false
              }

              if (name == 'undo_sign_off' && controller_this.hasUndoSignOffTarget) {
                controller_this.undoSignOffTarget.disabled = false
              }

              if (name == 'watch_shift' && controller_this.hasWatchTarget) {
                controller_this.watchTarget.disabled = false
              }
              if (name == 'unwatch_shift' && controller_this.hasUnwatchTarget) {
                controller_this.unwatchTarget.disabled = false
              }

              if (name == 'send_to_secondary_bank' && controller_this.hasSendToSecondaryBankTarget) {
                controller_this.sendToSecondaryBankTarget.disabled = false
              }

              if (name == 'recall_from_secondary_bank' && controller_this.hasRecallFromSecondaryBankTarget) {
                controller_this.recallFromSecondaryBankTarget.disabled = false
              }

              if (name == 'sync_to_rpa' && controller_this.hasSyncToRpaTarget) {
                controller_this.syncToRpaTarget.disabled = false
              }

              if (name == 'undo_rpa_lock' && controller_this.hasUndoRpaLockTarget) {
                controller_this.undoRpaLockTarget.disabled = false
              }

              if (
                name == 'add_to_block' &&
                controller_this.hasAddToBlockTarget &&
                grades_equal &&
                status_equal &&
                agency_tiers_equal
              ) {
                controller_this.addToBlockTarget.disabled = false
              }

              if (
                name == 'create_new_block' &&
                controller_this.hasCreateNewBlockTarget &&
                controller_this.count > 1 &&
                grades_equal &&
                status_equal &&
                agency_tiers_equal
              ) {
                controller_this.createNewBlockTarget.disabled = false
              }

              if (name == 'remove_from_block' && controller_this.hasRemoveFromBlockTarget) {
                controller_this.removeFromBlockTarget.disabled = false
              }

              if (name == 'lock_shift_to_agency' && controller_this.hasLockToAgencyTarget && agency_locked_equal) {
                controller_this.lockToAgencyTarget.disabled = false
              }

              if (
                name == 'unlock_shift_from_agency' &&
                controller_this.hasUnlockFromAgencyTarget &&
                agency_locked_equal
              ) {
                controller_this.unlockFromAgencyTarget.disabled = false
              }
            }
          })
        })

        close.addEventListener('click', function () {
          controller_this.count = 1

          actionBarSelected.checked = false
          shiftSelectAll.checked = false
          shiftCheckbox.checked = false

          controller_this.actionBarTarget.classList.remove('action-bar--toggle')

          triggerEvent(shiftCheckbox, 'change')
        })

        shiftSelectAll.addEventListener('click', function () {
          if (!controller_this.actionBarTarget.classList.contains('action-bar--toggle')) {
            controller_this.actionBarTarget.classList.add('action-bar--toggle')
          }

          if (selectAll.innerText === 'Select All') {
            //Needed - halts code even with value of 0

            setTimeout(() => {
              selectAll.innerText = 'Deselect All'
            }, 0)

            actionBarSelected.checked = true
            shiftSelectAll.checked = true

            let conditionalCount =
              controller_this.count <= 1
                ? (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.singular + ' selected')
                : (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.plural + '  selected')

            shiftCheckbox.checked = true
            shiftSelectAll.checked = true
          } else {
            setTimeout(() => {
              selectAll.innerText = 'Select All'
            }, 0)

            controller_this.count = 1

            actionBarSelected.checked = false
            shiftSelectAll.checked = false
            shiftCheckbox.checked = false

            controller_this.actionBarTarget.classList.remove('action-bar--toggle')

            let conditionalCount =
              controller_this.count <= 1
                ? (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.singular + ' selected')
                : (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.plural + ' selected')
          }

          triggerEvent(shiftCheckbox, 'change')
        })

        selectAll.addEventListener('click', function () {
          // Remove overlay + active checkall box class from react select all modal code `SelectAll.js`
          const selectAllOverlay = document.querySelector('.select-all-overlay')
          selectAllOverlay.style.display = 'none'
          document.querySelector('.select-all-toggle').classList.remove('active')
          // -----------------------------------------------------------------------------------------

          if (!controller_this.actionBarTarget.classList.contains('action-bar--toggle')) {
            controller_this.actionBarTarget.classList.add('action-bar--toggle')
          }

          if (selectAll.innerText === 'Select All') {
            //Needed - halts code even with value of 0

            setTimeout(() => {
              selectAll.innerText = 'Deselect All'
            }, 0)

            actionBarSelected.checked = true
            shiftSelectAll.checked = true

            // controller_this.count = 9;

            let conditionalCount =
              controller_this.count <= 1
                ? (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.singular + ' selected')
                : (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.plural + ' selected')

            shiftCheckbox.checked = true
            shiftSelectAll.checked = true

            if (window.location.pathname == '/payments' || window.location.pathname == '/payment_collaborative_banks') {
              document.querySelector('.select-all-toggle').classList.add('active')
            }
          } else {
            setTimeout(() => {
              selectAll.innerText = 'Select All'
            }, 0)

            controller_this.count = 1

            actionBarSelected.checked = false
            shiftSelectAll.checked = false
            shiftCheckbox.checked = false

            controller_this.actionBarTarget.classList.remove('action-bar--toggle')

            let conditionalCount =
              controller_this.count <= 1
                ? (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.singular + ' selected')
                : (shiftSelectedLabel.innerHTML =
                    controller_this.count + ' ' + controller_this.shiftWord.plural + '  selected')

            if (
              window.location.pathname == '/payments' ||
              window.location.pathname == '/payment_collaborative_banks' ||
              window.location.pathname == '/payment_receipts'
            ) {
              document.getElementById('all-shifts-toggle').value = 0
              document.querySelector('.select-all-overlay').style.display = 'none'
              document.querySelector('.select-all-toggle').classList.remove('active')
            }
          }

          triggerEvent(shiftCheckbox, 'change')
        })

        function triggerEvent(element, eventName) {
          var event
          if (typeof Event === 'function') {
            event = new Event(eventName)
          } else {
            event = document.createEvent('Event')
            event.initEvent(eventName, true, true)
          }
          element.dispatchEvent(event)
        }
      }
    }
    check()
  }

  checkStaffGroups(selected_staffs) {
    const newArray = []

    Object.keys(selected_staffs).map(function (key, index) {
      newArray.push(selected_staffs[key])
    })

    const allEqual = (arr) => arr.every((v) => v === arr[0])
    return allEqual(newArray)
  }

  checkGrades(selected_grades) {
    const newArray = []

    Object.keys(selected_grades).map(function (key, index) {
      newArray.push(selected_grades[key])
    })

    const allEqual = (arr) => arr.every((v) => v === arr[0])
    return allEqual(newArray)
  }

  checkStatus(selected_status) {
    const newArray = []

    Object.keys(selected_status).map(function (key, index) {
      if (
        selected_status[key] == 'booked' ||
        selected_status[key] == 'to_sign_off_on_hub_or_app' ||
        selected_status[key] == 'to_sign_off_on_hub'
      ) {
        newArray.push('booked')
      } else {
        newArray.push(selected_status[key])
      }
    })

    const allEqual = (arr) => arr.every((v) => v === arr[0])
    return allEqual(newArray)
  }

  checkAgencyTiers(selected_agency_tiers) {
    const newArray = []

    Object.keys(selected_agency_tiers).map(function (key, index) {
      newArray.push(selected_agency_tiers[key])
    })

    const allEqual = (arr) => arr.every((v) => v === arr[0])
    return allEqual(newArray)
  }

  checkAgencyLocked(selected_agency_locked) {
    const newArray = []

    Object.keys(selected_agency_locked).map(function (key, index) {
      newArray.push(selected_agency_locked[key])
    })

    const allEqual = (arr) => arr.every((v) => v === arr[0])
    return allEqual(newArray)
  }

  checkAmendedHours(selected_amended_hours) {
    const ids = Object.keys(selected_amended_hours)

    return ids.every((i) => selected_amended_hours[i] === false)
  }

  checkOnCallWithTimeframes(selected_on_call_with_timeframes) {
    const ids = Object.keys(selected_on_call_with_timeframes)

    return ids.every((i) => selected_on_call_with_timeframes[i] === false)
  }

  blockReasons(selected_reasons) {
    const newArray = []

    Object.keys(selected_reasons).map(function (key, index) {
      newArray.push(selected_reasons[key])
    })

    return newArray.join(', ')
  }

  updateShiftActions(selected_shifts) {
    let available_actions = []
    let ids = Object.keys(selected_shifts)

    if (ids.length === 0) {
      return available_actions
    }

    available_actions = selected_shifts[ids[0]]

    for (const id in selected_shifts) {
      let shift_actions = selected_shifts[id]
      available_actions = available_actions.filter((action) => shift_actions.includes(action))
    }
    return available_actions
  }

  increment(
    shift_actions,
    selected_shifts,
    staff_groups,
    selected_staffs,
    grades,
    selected_grades,
    reasons,
    selected_reasons,
    status,
    selected_status,
    agency_tier,
    selected_agency_tiers,
    agency_locked,
    selected_agency_locked,
    amended_hours,
    selected_amended_hours,
    on_call_with_timeframes,
    selected_on_call_with_timeframes
  ) {
    this.count++

    const dropup = document.querySelector('.action-bar__dropup')

    //USED FOR PAYMENTS & BATCHES SECTION ONLY
    if (
      window.location.pathname == '/payments/review' ||
      window.location.pathname == '/payments' ||
      window.location.pathname == '/payment_collaborative_banks' ||
      window.location.pathname == '/payment_collaborative_banks/review' ||
      window.location.pathname == '/payment_receipts' ||
      window.location.pathname == '/payment_receipts/review'
    ) {
      const actionableArea = document.querySelector('.action-bar__actionable-area')
      const lastSection = document.querySelector('.action-bar__last-section')
      const paymentsSection = document.querySelector('.action-bar__payments-section')
      const flagForReview = document.querySelector('.action-bar__flag-for-review')

      if ((actionableArea, lastSection)) {
        actionableArea.remove()
        lastSection.remove()
      }

      if (
        window.location.pathname == '/payments/review' ||
        window.location.pathname == '/payment_collaborative_banks/review' ||
        window.location.pathname == '/payment_receipts/review'
      ) {
        paymentsSection.style.display = 'none'
        flagForReview.style.display = 'block'
      } else {
        paymentsSection.style.display = 'flex'
        flagForReview.style.display = 'none'
      }
    }

    if (this.count >= 1) {
      this.actionBarTarget.classList.add('action-bar--toggle')
    } else {
      this.actionBarTarget.classList.remove('action-bar--toggle')
      if (dropup) {
        this.dropupTarget.classList.remove('action-bar__dropup--toggle')
      }
    }

    const count = document.querySelectorAll('input[name="ordersSelect"]:checked').length

    const shiftSelectedLabel = document.querySelector('.action-bar__label')
    let conditionalCount =
      this.count <= 1
        ? (shiftSelectedLabel.innerHTML = this.count + ' ' + this.shiftWord.singular + ' selected')
        : (shiftSelectedLabel.innerHTML = count + ' ' + this.shiftWord.plural + ' selected')

    shift_actions ? (selected_shifts[shift_actions['id']] = shift_actions['actions']) : null
    if (window.location.pathname == '/shifts') {
      staff_groups ? (selected_staffs[shift_actions['id']] = staff_groups) : null
    }

    grades ? (selected_grades[shift_actions['id']] = grades) : null

    reasons ? (selected_reasons[shift_actions['id']] = reasons) : null

    status ? (selected_status[shift_actions['id']] = status) : null

    agency_tier ? (selected_agency_tiers[shift_actions['id']] = agency_tier) : null

    agency_locked ? (selected_agency_locked[shift_actions['id']] = agency_locked) : null

    amended_hours ? (selected_amended_hours[shift_actions['id']] = amended_hours) : null

    on_call_with_timeframes ? (selected_on_call_with_timeframes[shift_actions.id] = on_call_with_timeframes) : null
  }

  decrement(
    shift_actions,
    selected_shifts,
    staff_groups,
    selected_staffs,
    grades,
    selected_grades,
    reasons,
    selected_reasons,
    status,
    selected_status,
    agency_tier,
    selected_agency_tiers,
    agency_locked,
    selected_agency_locked,
    payments,
    amended_hours,
    selected_amended_hours,
    on_call_with_timeframes,
    selected_on_call_with_timeframes
  ) {
    this.count--
    const dropup = document.querySelector('.action-bar__dropup')

    if (this.count >= 1) {
      this.actionBarTarget.classList.add('action-bar--toggle')
    } else if (payments) {
      this.actionBarTarget.classList.remove('action-bar--toggle')
      if (dropup) {
        this.dropupTarget.classList.remove('action-bar__dropup--toggle')
      }
    } else {
      this.actionBarTarget.classList.remove('action-bar--toggle')
      if (dropup) {
        this.dropupTarget.classList.remove('action-bar__dropup--toggle')
      }
    }

    const count = document.querySelectorAll('input[name="ordersSelect"]:checked').length

    const shiftSelectedLabel = document.querySelector('.action-bar__label')
    let conditionalCount =
      this.count <= 1
        ? (shiftSelectedLabel.innerHTML = this.count + ' ' + this.shiftWord.singular + ' selected')
        : (shiftSelectedLabel.innerHTML = count + ' ' + this.shiftWord.plural + ' selected')

    shift_actions ? delete selected_shifts[shift_actions['id']] : null
    if (window.location.pathname == '/shifts') {
      staff_groups ? delete selected_staffs[shift_actions['id']] : null
    }
    grades ? delete selected_grades[shift_actions['id']] : null
    reasons ? delete selected_reasons[shift_actions['id']] : null
    status ? delete selected_status[shift_actions['id']] : null
    agency_tier ? delete selected_agency_tiers[shift_actions['id']] : null
    agency_locked ? delete selected_agency_locked[shift_actions['id']] : null
    amended_hours ? delete selected_amended_hours[shift_actions['id']] : null
    on_call_with_timeframes ? delete selected_on_call_with_timeframes[shift_actions.id] : null
  }

  otherActions() {
    const dropup = document.querySelector('.action-bar__dropup')
    if (dropup) {
      this.dropupTarget.classList.toggle('action-bar__dropup--toggle')
    }
  }

  shiftQuery() {
    const shiftList = document.querySelector('.list')
    const checked = Array.from(shiftList.querySelectorAll('input[type=checkbox]:checked')).map((item) => item.value)

    let params = {}

    if (this.shiftType == 'receipt') {
      params['receipt_ids[]'] = checked
    } else {
      params['shift_ids[]'] = checked
    }
    return queryString.stringify(params)
  }

  signOff(event) {
    event.preventDefault()
    let signOff = document.querySelector('#sign-off-form')
    signOff.setAttribute('action', `/bulk_sign_off/?` + this.shiftQuery())
    document.getElementById('sign-off-form').submit()
  }

  undoSignOff(event) {
    event.preventDefault()

    let undoSignOff = document.createElement('form')
    undoSignOff.setAttribute('method', 'post')
    undoSignOff.setAttribute('action', `/bulk_undo_sign_off/?` + this.shiftQuery())
    undoSignOff.style.display = 'hidden'
    undoSignOff.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(undoSignOff)
    undoSignOff.submit()
  }

  approveForPayment(event) {
    event.preventDefault()

    let approveForPayment = document.createElement('form')
    approveForPayment.setAttribute('method', 'post')
    approveForPayment.setAttribute('action', `/bulk_approve_for_payment/?` + this.shiftQuery())
    approveForPayment.style.display = 'hidden'
    approveForPayment.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(approveForPayment)
    approveForPayment.submit()
  }

  previewAsBatch(event) {
    event.preventDefault()

    let previewAsBatch = document.createElement('form')
    previewAsBatch.setAttribute('method', 'post')
    const path = this.shiftType == 'receipt' ? '/ppi_batches/preview?' : '/batches/preview?'
    previewAsBatch.setAttribute('action', path + this.shiftQuery())
    previewAsBatch.style.display = 'hidden'
    previewAsBatch.appendChild(this.authTokenTarget.cloneNode())

    let exitButtonPath = document.createElement('input')
    exitButtonPath.type = 'hidden'
    exitButtonPath.value = window.location.pathname + window.location.search
    exitButtonPath.name = 'exit_button_path'
    previewAsBatch.appendChild(exitButtonPath)

    document.body.appendChild(previewAsBatch)
    previewAsBatch.submit()
  }

  sendToAgency(event) {
    event.preventDefault()
    let sendToAgency = document.createElement('form')

    sendToAgency.setAttribute('method', 'post')
    sendToAgency.setAttribute('action', `/bulk_send_to_agency/?` + this.shiftQuery())
    sendToAgency.style.display = 'hidden'
    sendToAgency.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(sendToAgency)
    sendToAgency.submit()
  }

  syncToRpa(event) {
    event.preventDefault()
    let syncToRpa = document.createElement('form')

    syncToRpa.setAttribute('method', 'post')
    syncToRpa.setAttribute('action', `/bulk_sync_to_rpa/?` + this.shiftQuery())
    syncToRpa.style.display = 'hidden'
    syncToRpa.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(syncToRpa)
    syncToRpa.submit()
  }

  undoRpaLock(event) {
    event.preventDefault()
    let undoRpaLock = document.createElement('form')

    undoRpaLock.setAttribute('method', 'post')
    undoRpaLock.setAttribute('action', `/bulk_undo_rpa_lock/?` + this.shiftQuery())
    undoRpaLock.style.display = 'hidden'
    undoRpaLock.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(undoRpaLock)
    undoRpaLock.submit()
  }

  addToBlock(event) {
    event.preventDefault()

    const shiftList = document.querySelector('.list')
    const checked = Array.from(shiftList.querySelectorAll('input[type=checkbox]:checked')).map((item) => item.value)
    const blockId = document.querySelector('#shift-block-id').value

    let params = {}

    params['shift_ids[]'] = checked
    params['shift_block_id'] = blockId

    let addToBlock = document.createElement('form')
    addToBlock.setAttribute('method', 'post')
    addToBlock.setAttribute('action', `/blocks/add_shifts_to_block?` + queryString.stringify(params))
    addToBlock.style.display = 'hidden'
    addToBlock.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(addToBlock)
    addToBlock.submit()
  }

  assignWorker(event) {
    event.preventDefault()

    const value = document.querySelector('#assign-worker-id').value

    const workerVal = document.querySelector('.modal__validation--worker')

    let assignWorkerForm = document.createElement('form')

    assignWorkerForm.setAttribute('method', 'post')
    assignWorkerForm.setAttribute(
      'action',
      `/bulk_assign_worker/?` + this.shiftQuery() + '&shift[worker_id]=' + value + '&shift[safety_compliant]=' + true
    )
    assignWorkerForm.style.display = 'hidden'
    assignWorkerForm.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(assignWorkerForm)

    if (value == '') {
      console.log('Please select a worker...')
      return
    }

    const shiftIds = document.getElementsByClassName('action__bar__safety')[0]
    const shiftIdLength = shiftIds.value.split(',').length
    let safetyCheckEnabled = document.querySelector('#safety-check-enabled-result').value

    if (shiftIdLength == 1) {
      console.log('We have 1 shift to assign workers to')
    } else if (shiftIdLength > 1) {
      console.log("Multiple shifts let's skip safety checks for now...", workerVal)

      if (value) {
        workerVal.style.display = 'none'
        assignWorkerForm.submit()
      } else {
        workerVal.style.display = 'block'
      }

      return
    }

    console.log('AssignWorker:', value)
    console.log('ShiftIds:', shiftIds)
    console.log('WorkerVAL', workerVal)
    console.log('SafteyCheck:', safetyCheckEnabled)

    if (safetyCheckEnabled === 'true') {
      let safetyApiData = {
        shift_id: shiftIds.value,
        worker_ids: [value],
      }

      let mockData = {
        shift_id: 722051,
        worker_ids: [16496],
      }

      const safetyUrl =
        // Production
        window.location.hostname.match('api.patchwork.health')
          ? 'https://safetychecks.patchwork.health/v1/worker_status'
          : // Api-test
          window.location.hostname.match('api-test.patchwork.health')
          ? 'https://staging-safetychecks.patchwork.health/v1/worker_status'
          : //Local host
            '//' + window.location.hostname + ':8888/v1/worker_status'
      fetch(safetyUrl, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(safetyApiData),
        // body: JSON.stringify(mockData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data)

          // Contact Api, if worker is un-safe we display 'hidden safety breach' screen, else book as normal

          const testApi = true

          // Worker Is not Safe ********************************
          if (!data[0].safe) {
            // Assign Worker flagged as unsafe show user hidden warning screen....
            document.getElementById('action__bar__safety').style.display = 'none'
            document.getElementById('safety__action__wrapper').style.display = 'block'
            return
          }

          // Worker Is Safe ********************************
          this.processAssignWorker(value, workerVal, assignWorkerForm)
        })
        .catch((err) => {
          console.log(err)
          alert('There was an Error when checking safety checks compatibility:', err)
        })
    } else {
      // Safe Checks is disabled ********************************
      this.processAssignWorker(value, workerVal, assignWorkerForm)
    } // end of safetyCheckEnabled if block
  }



  createNewBlock(event) {
    event.preventDefault()

    const shiftList = document.querySelector('.list')
    const checked = Array.from(shiftList.querySelectorAll('input[type=checkbox]:checked')).map((item) => item.value)
    const blockName = document.querySelector("input[name='block_name']").value

    let params = {}

    params['shift_ids[]'] = checked
    params['block_name'] = blockName

    let createShiftBlock = document.createElement('form')
    createShiftBlock.setAttribute('method', 'post')
    createShiftBlock.setAttribute('action', `/blocks?` + queryString.stringify(params))
    createShiftBlock.style.display = 'hidden'
    createShiftBlock.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(createShiftBlock)
    createShiftBlock.submit()
  }

  removeFromBlock(event) {
    event.preventDefault()

    const shiftList = document.querySelector('.list')
    const checked = Array.from(shiftList.querySelectorAll('input[type=checkbox]:checked')).map((item) => item.value)

    let params = {}

    params['shift_ids[]'] = checked

    let createShiftBlock = document.createElement('form')
    createShiftBlock.setAttribute('method', 'post')
    createShiftBlock.setAttribute('action', `/blocks/remove_from_block?` + queryString.stringify(params))
    createShiftBlock.style.display = 'hidden'
    createShiftBlock.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(createShiftBlock)
    createShiftBlock.submit()
  }

  processAssignWorker(value, workerVal, assignWorkerForm) {
    if (value) {
      workerVal.style.display = 'none'
      assignWorkerForm.submit()
    } else {
      workerVal.style.display = 'block'
    }
  }

  recallAgency(event) {
    event.preventDefault()
    let recallAgency = document.createElement('form')
    recallAgency.setAttribute('method', 'post')
    recallAgency.setAttribute('action', `/bulk_recall_from_agency/?` + this.shiftQuery())
    recallAgency.style.display = 'hidden'
    recallAgency.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(recallAgency)
    recallAgency.submit()
  }

  undoApproval(event) {
    event.preventDefault()
    let undoApproval = document.createElement('form')
    undoApproval.setAttribute('method', 'post')
    undoApproval.setAttribute('action', `/bulk_undo_payment_approval?` + this.shiftQuery())
    undoApproval.style.display = 'hidden'
    undoApproval.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(undoApproval)
    undoApproval.submit()
  }

  recalculateRates(event) {
    // Should open a modal with the new rates not sure what is this action supposed to do
    event.preventDefault()
    let recalculateRates = document.createElement('form')
    recalculateRates.setAttribute('method', 'post')
    recalculateRates.setAttribute('action', `/bulk_recalculate_rates/?` + this.shiftQuery())
    recalculateRates.style.display = 'hidden'
    recalculateRates.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(recalculateRates)
    recalculateRates.submit()
  }

  cancelBooking(event) {
    event.preventDefault()
    let cancelBooking = document.createElement('form')
    cancelBooking.setAttribute('method', 'post')
    cancelBooking.setAttribute('action', `/bulk_cancel_worker/?` + this.shiftQuery())
    cancelBooking.style.display = 'hidden'
    cancelBooking.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(cancelBooking)
    cancelBooking.submit()
  }

  cancelAgencyBooking(event) {
    event.preventDefault()
    let cancelAgencyBooking = document.createElement('form')
    cancelAgencyBooking.setAttribute('method', 'post')
    cancelAgencyBooking.setAttribute('action', `/bulk_cancel_agency_worker/?` + this.shiftQuery())
    cancelAgencyBooking.style.display = 'hidden'
    cancelAgencyBooking.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(cancelAgencyBooking)
    cancelAgencyBooking.submit()
  }

  removeRateEscalation(event) {
    event.preventDefault()
    let removeRateEscalation = document.createElement('form')
    removeRateEscalation.setAttribute('method', 'post')
    removeRateEscalation.setAttribute('action', `/bulk_remove_rate_escalation/?` + this.shiftQuery())
    removeRateEscalation.style.display = 'hidden'
    removeRateEscalation.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(removeRateEscalation)
    removeRateEscalation.submit()
  }

  watch(event) {
    event.preventDefault()
    let watch = document.createElement('form')
    watch.setAttribute('method', 'post')
    watch.setAttribute('action', `/bulk_watch/?` + this.shiftQuery())
    watch.style.display = 'hidden'
    watch.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(watch)
    watch.submit()
  }

  unwatch(event) {
    event.preventDefault()
    let unwatch = document.createElement('form')
    unwatch.setAttribute('method', 'post')
    unwatch.setAttribute('action', `/bulk_unwatch/?` + this.shiftQuery())
    unwatch.style.display = 'hidden'
    unwatch.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(unwatch)
    unwatch.submit()
  }

  bulkDelete(event) {
    event.preventDefault()
    const bulkDelete = document.createElement('form')
    const reasonForDeletionValue = document.querySelector('#reason_for_deletion_input').value

    // If no deletion reason is selected, show error message

    if (reasonForDeletionValue == '') {
      window.alert('Please select a reason for deletion')
      return
    }

    bulkDelete.setAttribute('method', 'post')
    bulkDelete.setAttribute(
      'action',
      `/bulk_delete/?${this.shiftQuery()}&reason_for_deletion_id=${reasonForDeletionValue}`
    )
    bulkDelete.style.display = 'hidden'
    bulkDelete.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(bulkDelete)

    console.log('reasonForDeletionValue', reasonForDeletionValue)

    bulkDelete.submit()
  }

  restore(event) {
    event.preventDefault()
    let bulkRestore = document.createElement('form')
    bulkRestore.setAttribute('method', 'post')
    bulkRestore.setAttribute('action', '/bulk_restore/?' + this.shiftQuery())
    bulkRestore.style.display = 'hidden'
    bulkRestore.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(bulkRestore)
    bulkRestore.submit()
  }

  sendToSecondaryBank(event) {
    event.preventDefault()
    let sendToSecondaryBank = document.createElement('form')

    sendToSecondaryBank.setAttribute('method', 'post')
    sendToSecondaryBank.setAttribute('action', `/bulk_send_to_secondary_bank/?` + this.shiftQuery())
    sendToSecondaryBank.style.display = 'hidden'
    sendToSecondaryBank.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(sendToSecondaryBank)
    sendToSecondaryBank.submit()
  }

  recallFromSecondaryBank(event) {
    event.preventDefault()
    let recallFromSecondaryBank = document.createElement('form')

    recallFromSecondaryBank.setAttribute('method', 'post')
    recallFromSecondaryBank.setAttribute('action', `/bulk_recall_from_secondary_bank/?` + this.shiftQuery())
    recallFromSecondaryBank.style.display = 'hidden'
    recallFromSecondaryBank.appendChild(this.authTokenTarget.cloneNode())
    document.body.appendChild(recallFromSecondaryBank)
    recallFromSecondaryBank.submit()
  }

  showSignOffCaution(target) {
    const sign = target.querySelector('div.action-bar__caution')

    if (sign) {
      sign.classList.remove('d-none')
    } else {
      target.appendChild(this.cautionSignHtml())
    }
  }

  hideSignOffCaution(target) {
    const sign = target.querySelector('div.action-bar__caution')

    if (sign) sign.classList.add('d-none')
  }

  cautionSignHtml() {
    let el = document.createElement('div')
    el.classList.add('action-bar__caution')
    el.innerText = '!'

    return el
  }
}

import { application } from "../../frontend/controllers/index";
application.register("action_bar", ActionBarController);
