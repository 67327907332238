import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";

const TimesheetAuthoriserUploadModal = ({ handleClose, show }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size={"lg"}
    >
      <ModalHeader>
        <ModalTitle>File Information</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ minHeight: 0 }}>
        <p style={{ margin: 0 }}>
          <strong style={{ fontSize: 20 }}>
            What to include in the spreadsheet:
          </strong>
          <br />
          Name
          <br />
          Position
          <br />
          Email
          <br />
          Department names comma separated e.g. A&E, Baby ward, Paediatrics
          <br />
          <i style={{ fontSize: 12 }}> Note: Please note that the departments associated with a worker must be a department already configured within the Patchwork system, please ensure that the spelling/formatting conventions match. If the department names do not align, that worker will not be uploaded.
          </i>
        </p>
      </ModalBody>
      <ModalFooter>
        <div />
        <div>
          <button
            className="btn text-white btn-cyan--modal"
            onClick={handleClose}
          >
            OK, Got it
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TimesheetAuthoriserUploadModal;
