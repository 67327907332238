// @ts-nocheck
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import styled from 'styled-components'
import { convertToBase64, resetInputs } from './helpers'
import { CloseIcon, UploadIcon, FileCheck, DownloadIcon } from './icons'
import { ADD_WORKER_UPLOAD_FROM_HUB } from './mutations'

function UploadedDocument({ cc }) {
  return (
    <DocumentsUploaded>
      <div className="uploaded__card">
        <h3 className="uploaded__title">Right to Work</h3>

        <div className="uploaded__check">
          <FileCheck />
        </div>

        <p className="uploaded__filename">{cc?.fileName?.slice(0, 25)}...</p>
        <a
          className="uploaded__button"
          href={`${window.location.origin}${cc?.imageUrl}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <DownloadIcon />
          Download Doc
        </a>
      </div>
    </DocumentsUploaded>
  )
}

function Documents({ documentTypeId, workerId }) {
  const [addWorkerUploadFromHub] = useMutation(ADD_WORKER_UPLOAD_FROM_HUB)
  const [document, setDocument] = useState(false)

  const onChangePicture = async (e) => {
    e.persist()
    const file = e.target.files[0] // eslint-disable-line

    let base64result

    const toastId = toast.loading('Loading...')

    try {
      const base64 = await convertToBase64(file)

      if (base64) {
        base64result = base64.split(",")[1] // eslint-disable-line
        addWorkerUploadFromHub({
          variables: {
            file: base64result,
            fileName: e.target.files[0].name,
            workerId: parseInt(workerId, 10),
            documentTypeId: parseInt(documentTypeId, 10),
            documentTitle: e.target.files[0].name,
          },
        })
          .then((res) => {
            window.scrollTo(0, 0)
            const errors = res.data?.addWorkerUpload?.errors

            resetInputs()

            if (errors?.length > 0) {
              toast.error(
                'We can’t accept that file type. Please try uploading one of the following file types: JPEG, PNG, etc',
                {
                  id: toastId,
                }
              )
              return
            }

            setDocument({
              fileName: res.data.addWorkerUploadFromHub.documentType.workerUploads[0].fileName,
              imageUrl: res.data.addWorkerUploadFromHub.documentType.workerUploads[0].imageUrl,
            })
            toast.success('Document Uploaded!', { id: toastId })
          })
          .catch(() => {
            toast.error('There was an error!', { id: toastId })
            window.location.reload()
          })
      }
    } catch (err) {
      toast.error('There was an error!', { id: toastId })
      window.location.reload()
    }
  }

  return (
    <WorkerFileUploader>
      <UploadedDivider>
        {/* eslint-disable-next-line */}
        <label>Worker Document Upload</label>
        <div />
      </UploadedDivider>

      {document ? (
        <UploadedDocument cc={document} />
      ) : (
        <DocumentsAwaiting>
          <div className="type mandatory__doc">
            {/* eslint-disable-next-line */}
          <label className="custom-doc-upload">
              <UploadIcon />
              <input className="documents__value" onChange={(e) => onChangePicture(e)} type="file" />
              <i className="fa fa-cloud-upload" /> Upload File
            </label>

            <div className="type__footer">
              <p>
                Right to Work
                <span>*</span>
              </p>
              <CloseIcon />
            </div>
          </div>
        </DocumentsAwaiting>
      )}
      <Toaster />
    </WorkerFileUploader>
  )
}

export default Documents

const WorkerFileUploader = styled.div`
  input[type='file'] {
    display: none;
  }

  .custom-doc-upload {
    align-items: center;
    background: #f5f7fa;
    border: 1px dashed #9ca5b0 !important;
    border: 1px solid #e4e7eb;
    border-radius: 8px;
    color: #17bfd6;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 600;
    height: 100px;
    justify-content: center;
    margin: 0;
    text-align: center;
    width: 100%;
    svg {
      margin-bottom: 7px;
    }
  }

  .type {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    height: 172px;
    margin: 0 24px 24px 0;
    padding: 17px 14px 17px;
    position: relative;
    width: 216px;

    .type__footer {
      svg {
        bottom: 12px;
        position: absolute;
        right: 14px;
      }
    }

    p {
      bottom: 0px;
      color: #002033;
      font-size: 13px;
      left: 14px;
      position: absolute;
    }

    span {
      color: red;
      font-size: 18px;
    }
  }

  @media (max-width: 568px) {
    .type {
      margin: 10px auto;
      width: 100%;
    }
  }
`
const DocumentsUploaded = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .uploaded__check {
    svg {
      position: absolute;
      right: 18px;
      top: 14px;
    }
  }

  .uploaded__card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    height: 172px;
    margin: 0 24px 24px 0;
    padding: 17px 14px 17px;
    position: relative;
    width: 216px;
  }

  .uploaded__filename {
    bottom: 57.56%;
    color: #646e7b;
    display: flex;
    font-size: 13px;
    font-weight: 600;

    left: 6.48%;
    line-height: 17px;
    position: absolute;
    right: 6.48%;
    text-decoration: underline;
    top: 28.49%;
  }

  .uploaded__button {
    align-items: center;
    background: #17bfd6;
    border-radius: 8px;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    color: #ffffff;
    display: flex;
    height: 42px;
    justify-content: center;
    left: 16px;
    position: absolute;
    top: 88px;
    width: 184px;
    svg {
      margin-right: 8px;
    }
  }

  .uploaded__delete {
    bottom: 2px;
    color: #646e7b;
    font-size: 13px;
    font-weight: 600;
    height: 43px;
    left: 50%;
    line-height: 20px;
    position: absolute;
    text-decoration-line: underline;
    transform: translate(-50%, 0);
    width: 100%;
  }

  .uploaded__title {
    color: #002033;
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
  }

  @media (max-width: 568px) {
    .uploaded__card {
      margin: 10px auto;
      width: 100%;
    }

    .uploaded__button {
      left: 20px;
      width: 90%;
    }
  }
`

const UploadedDivider = styled.div`
  align-items: center;
  display: flex;
  margin: 25px 0;
  label {
    color: #414c58;
    margin: 0;
    text-transform: uppercase;
  }
  div {
    border: 1px solid #e4e7eb;
    flex: 1;
    height: 1px;
    margin-left: 14px;
  }
`

export const DocumentsAwaiting = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  position: relative;
  span {
    color: #414c58;
    font-size: 12px;
    font-weight: bold;
    position: absolute;
    text-transform: uppercase;
    top: 0px;
  }
`
