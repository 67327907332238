import React from "react";
import Select from "react-select";
import styled from "styled-components";

export const ErrorContainer = styled.div`
  background: #ff6c6c;
  border: 1px solid #bd4848;
  border-radius: 6px;
  color: #ffffff;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const SuccessContainer = styled.div`
  background: rgba(139, 230, 138, 0.5);
  border: 1px solid #338532;
  border-radius: 6px;
  color: #338532;
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 10px 15px;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const FormDetailGroup = styled.div`
  padding-bottom: 25px;

  &:not(:last-of-type) {
    border-bottom: 4px solid #f0f2f4;
    margin-bottom: 25px;
  }
`;

export const ShiftModalInputGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShiftModalInput = styled.input`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36;
  font-size: 16px;
  height: 40px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;

  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

export const ShiftModalSelect = styled.select`
  -webkit-appearance: none;
  background: #ffffff;
  background-image: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.03) 0%,
    rgba(0, 0, 0, 0.03) 100%
  );

  border: 1px solid #cbd2d9;
  border-radius: 6px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  color: #1e2d36;
  font-size: 16px;
  height: 40px;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

export const ShiftModalLabel = styled.label`
  display: block;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  min-height: 19px;
  text-transform: uppercase;
`;

const ShiftModalTextarea = styled.textarea`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  font-size: 15px;
  min-height: 160px;
  line-height: 20px;
  padding: 12px 16px;
  ::placeholder {
    color: #3e4d58;
    opacity: 0.5;
  }

  &:active,
  &:focus {
    border: 1px solid #1e2d36;
    box-shadow: 0 0 3px 0 #9ca8b4;
    outline: none;
  }
`;

const ShiftModalCheckbox = styled.div`
  align-items: center;
  background: #f5f7fa;
  border-radius: 4px;
  display: flex;
  padding: 16px;
  width: 100%;

  input {
    margin-right: 8px;
  }

  label {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 1;
    margin: 0;
    text-transform: capitalize;
  }

  button {
    align-items: center;
    background: #ffffff;
    border: 1px solid #cbd2d9;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    height: 22px;
    justify-content: center;
    margin-right: 8px;
    width: 23px;

    &.on {
      background: #ff7f4d;
      border: 1px solid #ec7040;
      border-radius: 4px;
      box-shadow: none;
      padding: 0;
    }
  }
`;

export const FormGroup = ({
  children,
  component,
  defaultValue,
  disabled,
  error,
  footnote,
  isClearable,
  isMulti,
  label,
  name,
  menuPlacement,
  onChange,
  options,
  placeholder,
  required,
  type,
  value,
}) => {
  return (
    <ShiftModalInputGroupContainer className="form-element-container">
      {component === "checkbox" ? (
        <ShiftModalCheckbox>
          <input
            id={name}
            checked={value}
            name={name}
            onChange={onChange}
            type={type}
          />
          <ShiftModalLabel
            htmlFor={name}
            style={{ fontWeight: 400, textTransform: "unset" }}
          >
            {label}
          </ShiftModalLabel>
        </ShiftModalCheckbox>
      ) : (
        <>
          {label && <ShiftModalLabel>{label}</ShiftModalLabel>}
          {component === "input" ? (
            <ShiftModalInput
              defaultValue={defaultValue}
              disabled={disabled}
              type={type}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              value={value}
            />
          ) : component === "select" ? (
            <ShiftModalSelect
              onChange={onChange}
              name={name}
              defaultValue={defaultValue}
              disabled={disabled}
            >
              {children}
            </ShiftModalSelect>
          ) : component === "textarea" ? (
            <ShiftModalTextarea
              disabled={disabled}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              required={required}
              value={value}
            />
          ) : component === "react-select" ? (
            <ShiftModalReactSelect
              className={`react-select-container ${disabled && "disabled"}`}
              classNamePrefix="react-select"
              onChange={onChange}
              placeholder={placeholder}
              required={required}
              name={name}
              isClearable={isClearable}
              menuPlacement={menuPlacement ? menuPlacement : "auto"}
              defaultValue={defaultValue}
              value={value}
              options={options}
              isMulti={isMulti ? true : false}
              value={value}
            />
          ) : component === "currency" ? (
            <ShiftModalInput
              defaultValue={defaultValue}
              disabled={disabled}
              type={type}
              name={name}
              onChange={onChange}
              min="0.01"
              step="0.01"
            />
          ) : component === "custom-checkbox" ? (
            <ShiftModalCheckbox>
              <button onClick={onChange} className={value === true ? "on" : ""}>
                {value === true ? (
                  <svg width="14" height="9" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.795 2.23L6.421 8.602a1.059 1.059 0 01-.749.314c-.27 0-.542-.107-.749-.314L1.205 4.885a1.049 1.049 0 010-1.498 1.049 1.049 0 011.498 0l2.97 2.964 5.624-5.62a1.049 1.049 0 011.498 0c.42.415.42 1.084 0 1.498z"
                      fill="#FFF"
                      fillRule="evenodd"
                    />
                  </svg>
                ) : (
                  ""
                )}
              </button>{" "}
              <ShiftModalLabel>{label}</ShiftModalLabel>
            </ShiftModalCheckbox>
          ) : (
            children
          )}
        </>
      )}
      {footnote && (
        <p
          style={{
            fontSize: 12,
            marginBottom: 0,
            marginTop: 10,
            opacity: "0.7",
          }}
        >
          {footnote}
        </p>
      )}
      {error && <ShiftModalInputError>{error}</ShiftModalInputError>}
    </ShiftModalInputGroupContainer>
  );
};

export const FormGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  grid-template-columns: ${(props) =>
    props.size ? props.size : `repeat(${(props) => props.columns}, 1fr)`};
  max-width: ${(props) => (props.full === true ? "100%" : "658px")};

  &:not(:last-of-type) {
    margin-bottom: 24px;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;

    .form-element-container {
      width: ${(props) =>
        props.columns === 1
          ? "100%"
          : props.columns === 2
          ? "50%"
          : props.columns === 3
          ? "33%"
          : props.columns === 4
          ? "25%"
          : "100%"};

      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
`;

export const FormButtonToggle = styled.div`
  display: flex;

  button {
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.015) 0%,
        rgba(0, 0, 0, 0.015) 100%
      ),
      #ffffff;
    padding: 0 32px;
    border: 1px solid #cbd2d9;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    color: #8b959a;
    font-size: 16px;
    font-weight: 600;
    height: 40px;
    position: relative;

    &.active {
      background: #ffece4;
      border: 1px solid #ff7f4d;
      color: #ff7f4d;
      z-index: 1;
    }

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    &:not(:first-child) {
      left: -1px;
    }
  }
`;

export const ShiftModalReactSelect = styled(Select)`
  .react-select__control {
    -webkit-appearance: none;
    background: #ffffff;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    );
    border: 1px solid #cbd2d9;
    border-radius: 6px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
    color: #1e2d36;
    font-size: 16px;
    height: ${(props) => (props.isMulti ? "auto" : "40px")};
    overflow: hidden;
    padding-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    ::placeholder {
      color: #3e4d58;
      opacity: 0.5;
    }

    &:hover {
      border: 1px solid #cbd2d9;
    }

    &:active,
    &:focus {
      border: 1px solid #1e2d36;
      box-shadow: 0 0 3px 0 #9ca8b4;
      outline: none;
    }
  }

  .react-select__placeholder {
    max-width: calc(100% - 8px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__clear-indicator {
    padding: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__option--is-focused {
    background-color: rgba(245, 221, 213, 0.25);
  }

  .react-select__option {
    &:hover {
      background-color: rgba(245, 221, 213, 0.25);
    }
  }

  .react-select__option--is-selected {
    background-color: #f5ddd5;
    color: #1e2d36;
  }

  .react-select__single-value {
    color: #1e2d36;
  }

  &.disabled {
    .react-select__control {
      pointer-events: none;
    }

    .react-select__single-value {
      color: #9ca8b4;
    }
  }
`;

const ShiftModalInputError = styled.span`
  color: #bd4848;
  margin-top: 5px;
`;

export const FormToggleContainer = styled.div`
  background: #eaedf2;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  min-height: 88px;
  padding: 12px;
  text-align: center;
  width: 100%;

  p {
    font-size: 14px;
    margin-bottom: 12px;
  }
`;

export const FormToggleComponent = styled.div`
  background: #ffffff;
  border: 1px solid #cbd2d9;
  border-radius: 13px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  cursor: pointer;
  height: 26px;
  margin: 0 auto;
  position: relative;
  width: 52px;

  &.disabled {
    cursor: not-allowed;
  }

  &.on {
    background: #8ad389;
    background-image: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.03) 0%,
      rgba(0, 0, 0, 0.03) 100%
    );
    border: 1px solid #73bb72;

    button {
      background: #ffffff;
      background-image: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.03) 0%,
        rgba(0, 0, 0, 0.03) 100%
      );
      border: 1px solid #eee9e9;
      box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
      left: 27px;
    }
  }

  button {
    align-items: center;
    background: #c9d2da;
    border: 0;
    border-radius: 50%;
    display: flex;
    height: 20px;
    justify-content: center;
    left: 2px;
    line-height: 1;
    padding: 0;
    position: absolute;
    top: 2px;
    transition: left 0.25s ease-in-out;
    width: 20px;
  }
`;
