import React from 'react'
import styled from 'styled-components'

// Your React component 3352326, 3459275 , 2730949, 3459276,
function RateDetails({ proposal }) {
  const comparatorDiff = (rate) => {
    const diff = rate.comparatorRate - rate.initialRates.comparatorRate

    if (diff > 0) {
      return `+£${diff.toFixed(2)}`
    }
    if (diff < 0) {
      return `-£${diff.toFixed(2)}`
    }

    return ''
  }

  return (
    <Table>
      <TableLeft>
        <div className="grey" />
        <div className="left__col">
          <p style={{ fontWeight: 'bold' }}>Charge Rate/hr (inc. VAT):</p>
        </div>

        <div className="left__col">
          <p style={{ fontWeight: 'bold' }}>Charge Rate/hr (exc. VAT):</p>
          <p>Vs Rate Card:</p>
        </div>

        <div className="left__col">
          <p>Pay to worker/hr:</p>
        </div>
        <div className="left__col">
          <p>Employer NI/hr:</p>
        </div>
        <div className="left__col">
          <p>Agency margin/hr:</p>
        </div>
      </TableLeft>

      <TableRight>
        {proposal.rates.map((r) => (
          <div className="right__col">
            <div className="right__col__header">{r.name}</div>

            {/* Body */}
            <div className="right__col__body">
              <div className="cell">
                <p> £{r.comparatorRateIncVat.toFixed(2)}</p>
              </div>
              <div className="cell">
                <p>£{r.comparatorRate}</p>
                {r.comparatorRate.toFixed(2) !== r.initialRates.comparatorRate.toFixed(2) && (
                  <span className="rate_diff">{comparatorDiff(r)}</span>
                )}
              </div>
              <div className="cell">£{r.workerRate} </div>
              <div className="cell">£{r.niContribution} </div>
              <div className="cell">£{r.agencyMargin} </div>
            </div>
          </div>
        ))}
      </TableRight>
    </Table>
  )
}

export default RateDetails

const TableLeft = styled.div`
  width: 180px;

  .grey {
    background: #e4e7eb;
    width: 100%;
    height: 40px;
  }

  .left__col {
    line-height: 1.5 !important;
    height: 40px;
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;
    justify-content: space-evenly;
    p {
      margin: 0;
    }
  }
`

const TableRight = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  .right__col {
    flex: 1;
  }

  .right__col__header {
    height: 40px;
    background: #e4e7eb;
    color: #717171;
    display: flex;
    justify-content: center;
    font-size: 11px;
    align-items: center;
  }

  .right__col__body {
    background: white;

    .cell {
      height: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1.5;
      font-size: 11px;
      p {
        margin: 0;
      }
    }
  }
`
const Table = styled.div`
  border: 1px solid #33333321;
  margin-top: 20px;
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

  .rate_diff {
    background: #ffe4d9;
    border-radius: 4px;
    color: #bd2d2e;
    line-height: 17px;
    padding: 1px 8px;
  }
`
