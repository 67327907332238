import { useQuery } from '@apollo/client'
import { FormGroup, FormLabel, FormSelect } from '@patchworkhealth/web-components'
import React from 'react'
import styled from 'styled-components'
import { statuses, PAID_PER_ITEM_RECEIPTS_FILTERS } from './ReceiptsHelpers'

const getFilterDropdownOptions = ({ departments, grades, workers }) => {
  return {
    departmentsOptions: departments?.map((department) => ({
      label: department?.name,
      value: department?.id,
    })),
    gradesOptions: grades?.map((grade) => ({
      label: grade?.title,
      value: grade?.id,
    })),
    workersOptions: workers?.map((worker) => ({
      label: worker?.fullName,
      value: worker?.userId,
    })),
  }
}

function ReceiptFilters({ departments, grades, workers, inputs, setInputs, resetPagination }) {
  const { data: itemFilterOptionsData } = useQuery(PAID_PER_ITEM_RECEIPTS_FILTERS)
  const { departmentsOptions, gradesOptions, workersOptions } = getFilterDropdownOptions({
    departments,
    grades,
    workers,
  })

  const itemFilterOptions = itemFilterOptionsData?.paidPerItemReceiptsFilters?.itemFilterOptions?.map((item) => ({
    label: item?.name,
    value: item?.id,
  }))

  return (
    <ShiftsFilterWrapper>
      <ShiftsFiltersContainer>
        <FilterFormGroup col={1} row={1}>
          <FormLabel>Department</FormLabel>
          <FormSelect
            defaultValue={inputs.departmentIds}
            isClearable
            onChange={(option) => {
              const newValue = option?.value ? [option?.value] : []
              setInputs({ ...inputs, departmentIds: newValue })
              resetPagination()
            }}
            options={departmentsOptions}
            placeholder="Select Department"
          />
        </FilterFormGroup>

        <FilterFormGroup col={2} row={1}>
          <FormLabel>Workers</FormLabel>
          <FormSelect
            defaultValue={inputs.workerIds}
            isClearable
            onChange={(option) => {
              const newValue = option?.value ? [option?.value] : []
              setInputs({ ...inputs, workerIds: newValue })

              // handleFilter(e, 'workerIds')
              resetPagination()
            }}
            options={workersOptions}
            placeholder="Select Workers"
          />
        </FilterFormGroup>

        <FilterFormGroup col={3} row={1}>
          <FormLabel>Status</FormLabel>
          <FormSelect
            defaultValue={inputs.statuses}
            isClearable
            onChange={(option) => {
              const newValue = option?.value ? [option?.value] : []
              setInputs({ ...inputs, status: newValue })
              resetPagination()
            }}
            options={statuses}
            placeholder="Select Status"
          />
        </FilterFormGroup>

        <FilterFormGroup col={4} row={1}>
          <FormLabel>Item</FormLabel>
          <FormSelect
            defaultValue={inputs.itemIds}
            isClearable
            name="itemIds"
            onChange={(option) => {
              const newValue = option?.value ? [option?.value] : []
              setInputs({ ...inputs, itemIds: newValue })
              resetPagination()
            }}
            options={itemFilterOptions}
            placeholder="Select Item"
          />
        </FilterFormGroup>

        <FilterFormGroup col={5} row={1}>
          <FormLabel>Grades</FormLabel>
          <FormSelect
            defaultValue={inputs.gradeIds}
            isClearable
            name="gradeIds"
            onChange={(option) => {
              const newValue = option?.value ? [option?.value] : []
              setInputs({ ...inputs, gradeIds: newValue })
              resetPagination()
            }}
            options={gradesOptions}
            placeholder="Select Grades"
          />
        </FilterFormGroup>
      </ShiftsFiltersContainer>
    </ShiftsFilterWrapper>
  )
}

export default ReceiptFilters

export const ShiftsFiltersContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(5, 280px);
  margin-bottom: 24px;

  label {
    color: white;
  }

  @media (max-width: 1740px) and (min-width: 1151px) {
    grid-template-columns: repeat(3, 280px);
  }

  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 200px);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;
    -ms-grid-columns: 400px 400px 400px;
    -ms-grid-row: 200px 300px;
  }
`

const FilterFormGroup = styled(FormGroup)`
  -ms-grid-column: ${(props) => props.col};
  -ms-grid-row: ${(props) => props.row};
  padding: 5px;

  input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }
`

export const ShiftsFilterWrapper = styled.div`
  background: #002033;
`
