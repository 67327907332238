/* eslint-disable no-nested-ternary */
import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  FormGrid,
  FormGroup,
  FormLabel,
  Loading,
  TableSearchInput,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import 'react-day-picker/lib/style.css'
import { toast } from 'react-toastify'
import {
  loadingToast,
  successToast,
  errorToast,
  SHIFT_BLOCK_ASSIGN_ORGANISATION_REGISTRATION,
  WORKERS,
  formatError,
} from '../helpers/BlockBookingsHelpers'
import { ShiftModalTitle } from '../helpers/BlockComponents'
import ProfilePng from '../helpers/profilePic.png'
import { ShiftApplication, ShiftApplicationInfo } from './ViewBlockApplications'

function ViewBlockAssign({ block, handleClose, refetch }) {
  const [workerName, setWorkerName] = useState('')
  const [workers, setWorkers] = useState([])

  // Gql **************************************

  const [shiftBlockAssignOrganisationRegistration] = useMutation(SHIFT_BLOCK_ASSIGN_ORGANISATION_REGISTRATION)

  const { loading: loadingWorkers } = useQuery(WORKERS, {
    skip: workerName.length < 3,
    variables: {
      workerName,
    },
    onCompleted: (res) => {
      if (res) {
        setWorkers(res.shiftBlockWorkers)
      }
    },
    onError: () => {
      toast.error("Couldn't fetch workers", { hideProgressBar: true, position: 'top-right' })
    },
  })

  // Functions **********************************

  const closeModalFunction = () => {
    setWorkers([])
    setWorkerName('')
    handleClose()
  }

  const assignWorker = (worker) => {
    toast.loading('Loading', loadingToast)
    const orgRegistration = worker.organisationRegistration

    shiftBlockAssignOrganisationRegistration({
      variables: {
        shiftBlockId: Number(block?.id),
        organisationRegistrationId: orgRegistration.id,
      },
    })
      .then((response) => {
        const errors = response.data?.shiftBlockAssignOrganisationRegistration?.errors
        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: formatError(errors) })
          return
        }
        closeModalFunction()
        refetch()

        toast.update(2, { ...successToast, render: 'Worker Assigned' })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Worker could not be Assigned' })
      })
  }

  return (
    <>
      <ShiftModalTitle>Assign a Worker</ShiftModalTitle>

      <FormGrid columns={2} style={{ marginBottom: 20 }}>
        <FormGroup>
          <TableSearchInput
            name="name"
            onChange={(e) => setWorkerName(e.target.value)}
            placeholder="Search for a Worker"
            type="text"
          />
        </FormGroup>
      </FormGrid>

      <FormGrid columns={1}>
        <FormLabel>Results: {workers?.length}</FormLabel>

        {loadingWorkers && <Loading />}
        {!loadingWorkers &&
          workers.length > 0 &&
          workers.map((worker) => (
            <ShiftApplication key={worker.id}>
              <ShiftApplicationInfo>
                <img alt="pic" src={ProfilePng} />
                <a href={`/workers?worker_id=${worker.id}`} rel="noreferrer" target="_blank">
                  {worker?.fullName}
                </a>
                <h3>
                  <strong>{worker?.organisationRegistration?.grade?.title}</strong>
                </h3>
                <p>{worker?.email}</p>
              </ShiftApplicationInfo>
              <DefaultButton action={() => assignWorker(worker)} color="blue" text="Assign" />
            </ShiftApplication>
          ))}
      </FormGrid>
    </>
  )
}

export default ViewBlockAssign
