import { gql } from '@apollo/client'

export const GET_SHIFT = gql`
  query GetShift($id: Int!) {
    shift(id: $id) {
      id
      autoCascadeTriggers {
        id
        createdAt
        updatedAt
        fromTier
        toTier
        trashedAt
        triggerType
        triggerableType
        triggerableId
        triggeredAt
        status
      }
      grade {
        organisationStaffGroup {
          vms
          agencyTiers {
            id
            rank
          }
        }
      }
    }
  }
`

export const UPDATE_AGENCY_TIER = gql`
  mutation BulkUpdateAgencyTier($shiftIds: [Int!]!, $agencyTierId: Int!) {
    bulkUpdateAgencyTier(shiftIds: $shiftIds, agencyTierId: $agencyTierId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`
