import { Controller } from 'stimulus'
import Ajax from '../../frontend/packs/ajax'

export default class PushNotificationController extends Controller {
  static get targets() {
    return ['email', 'prepareDiv', 'sendDiv', 'devicesList',
            'userInfo', 'prepareError', 'sendNotificationButton',
            'title', 'body', 'dataParams', 'app',
            'sendReportDiv', 'sendReport', 'sendError']
  }

  findUser() {
    this.cleanAll();
    var ajax = new Ajax();
    ajax.post('/workers/push_notification_prepare', {
      email: this.emailTarget.value
    }, (data) => {
      this.processPrepareResponse(data);
    });
  }

  sendNotification() {
    this.clearSendReport()
    var ajax = new Ajax();
    ajax.post('/workers/push_notification_send', {
      user_id: this.userId,
      title: this.titleTarget.value,
      body: this.bodyTarget.value,
      data_params: this.dataParamsTarget.value,
      app: this.appTarget.value
    }, (data) => {
      this.processSendResponse(data);
    });
  }

  processPrepareResponse(data) {
    data = JSON.parse(data);
    if(data.success) {
      this.showUser(data.user);
      this.showDevices(data.devices);
      this.prepareDivTarget.classList.remove('d-none');
    } else {
      this.showPrepareError(data.errors.join('<br>'));
    }
  }

  showUser(user) {
    this.userId = user.id;
    var html = "ID: " + user.id + "<br>";
    html += user.first_name + ' ' + user.last_name;
    this.userInfoTarget.innerHTML = html;
    this.userInfoTarget.classList.remove('d-none');
  }

  showDevices(devices) {
    if(devices.length == 0) {
      this.showPrepareError('The user has no devices');
      return;
    }

    var html = '<ul>';
    for(var i=0; i < devices.length; i++) {
      html += "<li> ["+ devices[i].id+'] '+ devices[i].device_name + ' (' + devices[i].device_app + ')</li>';
    }
    html += '</ul>';
    this.devicesListTarget.innerHTML = html;
    this.devicesListTarget.classList.remove('d-none');
    this.prepareDivTarget.classList.remove('d-none');
    this.sendDivTarget.classList.remove('d-none');
  }

  showPrepareError(error) {
    this.prepareErrorTarget.innerHTML = error;
    this.prepareDivTarget.classList.remove('d-none');
    this.prepareErrorTarget.classList.remove('d-none');
  }

  cleanAll() {
    this.prepareDivTarget.classList.add('d-none');
    this.userInfoTarget.classList.add('d-none');
    this.devicesListTarget.classList.add('d-none');
    this.prepareErrorTarget.classList.add('d-none');

    this.sendDivTarget.classList.add('d-none');

    this.clearSendReport();
  }

  clearSendReport() {
    this.sendReportDivTarget.classList.add('d-none');
    this.sendReportTarget.classList.add('d-none');
    this.sendErrorTarget.classList.add('d-none');
  }

  processSendResponse(data) {
    data = JSON.parse(data);
    if(data.success) {
      this.showReport(data.report);
    } else {
      this.showSendError(data.errors.join('<br>'));
    }
  }

  showReport(report) {
    this.sendReportTarget.innerHTML = JSON.stringify(report, null, 2);
    this.sendReportDivTarget.classList.remove('d-none');
    this.sendReportTarget.classList.remove('d-none');
  }

  showSendError(error) {
    this.sendErrorTarget.innerHTML = error;
    this.sendErrorTarget.classList.remove('d-none');
    this.sendReportDivTarget.classList.remove('d-none');
  }
}

import {application} from '../../frontend/controllers/index'
application.register("push_notification", PushNotificationController)
