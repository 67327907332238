import React from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

export const Loading = () => (
  <LoadingContainer>
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#ff7f4d"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(1 1)" strokeWidth="2">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
  </LoadingContainer>
);

export const LoadingContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 220px;
`;

export const ModalHeader = styled(Modal.Header)`
  background: #f5f7fa;
  padding: 24px 32px;
`;

export const ModalTitle = styled(Modal.Title)`
  font-size: 22px;
  font-weight: 600;
  margin: 0;
`;

export const CloseButton = styled.button`
  background: transparent;
  border: 0;
  padding: 0;
`;

export const ModalBody = styled(Modal.Body)`
  min-height: 220px;
  max-height: 600px;
  overflow-y: auto;
  padding: 32px;
`;

export const ModalFooter = styled(Modal.Footer)`
  align-items: center;
  border-top: 1px solid #cbd2d9;
  display: flex;
  justify-content: space-between;
  margin-left: 32px;
  margin-right: 32px;
  min-height: 100px;
  padding: 24px 0;

  button {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
    padding: 8px 20px;

    &.btn-close {
      background: transparent;
      color: #9ca8b4;
      padding: 0;

      &:hover {
        color: #1e2d36;
      }
    }

    &.btn-white {
      border-color: #cbd2d9;
    }

    &:not(:last-child) {
      margin-right: 32px;
    }

    svg {
      margin-right: 6px;
    }
  }

  * {
    margin: 0;
    &:first-child {
      max-width: 600px;
    }
  }
`;
