import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../packs/application";

import Grades from "./Grades";

function GradesIndex({ grades, organisation, hello }) {
  return (
    <ApolloProvider client={client}>
      <Grades grades={grades} organisation={organisation} hello={hello} />
    </ApolloProvider>
  );
}

export default GradesIndex;
