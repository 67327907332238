import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../packs/application";

import AgencyUsers from "./AgencyUsers";

function AgencyUsersIndex({ agencies, currentAgencyUser, vms }) {
  return (
    <ApolloProvider client={client}>
      <AgencyUsers
        agencies={agencies}
        currentAgencyUser={currentAgencyUser}
        vms={vms}
      />
    </ApolloProvider>
  );
}

export default AgencyUsersIndex;
