import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { createFilterGrade, editFilterGrade } from "./FilterGradesHelpers";

const FilterGradesModal = ({ handleClose, filterGrade, show, staffGroups }) => {
  const [error, setError] = useState({ title: "", staff_group_id: null });
  const [inputs, setInputs] = useState({ title: "", staff_group_id: null });

  // remap staffGroups into an array react-select can read
  const orgStaffGroups = staffGroups.map(function (staffGroup) {
    return { value: staffGroup.id, label: staffGroup.title };
  });

  useEffect(() => {
    if (filterGrade) {
      setInputs({
        title: filterGrade.title ? filterGrade.title : "",
        staff_group_id: filterGrade.staff_group_id
          ? filterGrade.staff_group_id
          : null,
      });
    }
  }, [filterGrade]);

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleRegulationBodyChange = (event) => {
    setError({});
    setInputs((inputs) => ({ ...inputs, staff_group_id: event.value }));
  };

  const createFilterGradeAction = () => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a name." });
    } else if (inputs.staff_group_id === null) {
      setError({ ...error, staff_group_id: "Please select a staff group." });
    } else {
      setError({});
      if (inputs) {
        const body = {
          filter_grade: {
            title: inputs.title,
            staff_group_id: inputs.staff_group_id,
          },
        };
        createFilterGrade(body).then((data) => {
          if (data !== "err") {
            window.location.reload(true);
          } else {
            setError({
              error: true,
              message: `There was a problem creating this Filter Grade.`,
            });
          }
        });
      }
    }
  };

  const editFilterGradeAction = (filterGrade) => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a name." });
    } else if (inputs.staff_group_id === null) {
      setError({ ...error, staff_group_id: "Please select a staff group." });
    } else {
      const body = {
        filter_grade: {
          title: inputs.title,
          staff_group_id: inputs.staff_group_id,
        },
      };
      editFilterGrade(filterGrade, body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setError({
            error: true,
            message: `There was a problem updating this Filter Grade.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {filterGrade ? `Edit ${filterGrade.title}` : "Create Filter Grade"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ minHeight: "auto", overflow: "visible" }}>
        <FilterGradesForm>
          <FormDetailGroup>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                value={inputs.title}
                placeholder={"Enter a name"}
                label={"Filter Grade Name"}
                name="title"
                onChange={handleInputChange}
                type="text"
                required
                error={error.title}
              />
              <FormGroup
                component={"react-select"}
                label={"Staff Group"}
                options={orgStaffGroups}
                placeholder="Please select a staff group"
                name="staff_group_id"
                onChange={handleRegulationBodyChange}
                error={error.staff_group_id}
                defaultValue={
                  filterGrade && {
                    label: filterGrade.staff_group?.title,
                    value: filterGrade.staff_group?.id,
                  }
                }
              />
            </FormGrid>
          </FormDetailGroup>
        </FilterGradesForm>
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={() => handleCloseAction()}>
            Cancel
          </button>
          {filterGrade ? (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => editFilterGradeAction(filterGrade.id)}
            >
              Save Changes
            </button>
          ) : (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => createFilterGradeAction()}
            >
              Create Filter Grade
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default FilterGradesModal;

const FilterGradesForm = styled.div`
  max-width: 600px;
`;
