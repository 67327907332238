import { Controller } from "stimulus";

export default class NoticeAlertController extends Controller {
  static get targets() {
    return ["notice", "dismiss", "alert"];
  }

  connect() {
    const noticeTarget = this.noticeTarget;

    setTimeout(function () {
      noticeTarget.classList.add("notice-alert--fade");
    }, 10000);
  }

  dismissNotice() {
    if (this.hasNoticeTarget) {
      this.noticeTarget.classList.add("notice-alert--none");
    }

    if (this.hasAlertTarget) {
      this.alertTarget.classList.add("notice-alert--none");
    }
  }
}
import { application } from "../../frontend/controllers/index";

  application.register("notice_alert", NoticeAlertController);
