import SlimSelect from "slim-select";
import queryString from "query-string";

export default class SkillsSelectorController extends SearchController {
  static get targets() {
    return ["select"];
  }

  connect() {
    this.element[this.identifier] = this;
    window.qs = queryString;
    this.queryString = queryString.parse(location.search);
    const target = this.selectTarget;
    const searchOnSelect = this.data.get("search-on-select");
    const closeOnSelect = this.data.get("keep-open") ? false : true;

    this.select = new SlimSelect({
      select: target,
      placeholder: "Select Skills",
      closeOnSelect: closeOnSelect,
      onChange: (info) => {
        this.storeFilters("skills_ids[]", info);
        if (searchOnSelect) {
          this.multiSearchOnSelect("skills_ids[]", info);
        }
      },
    });

    if (this.data.get("initial-value")) {
      this.select.set(this.data.get("initial-value"));
    }
  }
}

import { application } from "../../frontend/controllers/index";
import SearchController from "../search_controller";
application.register("skills_selector", SkillsSelectorController);
