import { Controller } from 'stimulus'
import Handlebars from 'handlebars'

export default class WorkerSelectorController extends Controller {

  static get targets() {
    return ['results', 'input', 'template', 'spinner', 'value', 'list']
  }

  connect() {
    this.template = Handlebars.compile(this.templateTarget.innerHTML.toString())
    this.listDrawer = false;
  }

  setUpWorkers() {
    this.clearWorkers();
    this.appendWorkers();
  }

  appendWorkers() {
    this.workers.forEach(user => {
      const resultItem = this.template(user)
      this.resultsTarget.insertAdjacentHTML('beforeend', resultItem)
    })
  }

  open() {
    this.listTarget.classList.remove('worker-selector__list--hide')
    this.listDrawer = true
  }

  close(event) {
    this.listTarget.classList.add('worker-selector__list--hide')
    this.listDrawer = false
  }

  toggleDrawer() {
    if (this.listDrawer) {
      this.close()
    } else {
      this.open()
    }
  }

  clearWorkers() {
    this.resultsTarget.innerHTML = ''
  }

  staffGroupId() {
    const bulkStaffGroupInput = document.getElementById('assign-worker-staffgroup-id')
    const shiftList = document.querySelector('.list')
    const checkedArray = Array.from(shiftList.querySelectorAll('.custom-control-input:checked'))
      .map(item => item.value)

    if (checkedArray.length > 0) {
      const id = Number(checkedArray[0])
      const query = `query Shift($id: Int!){
        shift(id: $id){
          id
          grade {
            id
            staffGroupId
          }
        }
      }`;
      fetch('/graphql', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          query,
          variables: { id },
        })
      }).then(r => r.json()).then(data => {
        bulkStaffGroupInput.value = data.data.shift.grade.staffGroupId
      });
    }
    return document.getElementById('assign-worker-staffgroup-id') ? Number(document.getElementById('assign-worker-staffgroup-id').value) : bulkStaffGroupInput.value
  }

  fetchUsers(staffGroupId) {
    const name = this.inputTarget.value
    const query = `query Workers($name: String, $page: Int!, $items: Int!, $staffGroupId: Int!){
      workers(name: $name, page: $page, items: $items, staffGroupId: $staffGroupId){
        id
        firstName
        lastName
        email
        onlySecondaryBank
        suspended
        grade {
          title
        }
      }
    }`;

    const page = 1;
    const items = 10;
    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { name, page, items, staffGroupId },
      })
    })
      .then(r => r.json())
      .then(data => {
        this.workers = data.data.workers;
        this.setUpWorkers();
        this.hideSpinner();
      });
  }

  find(event) {
    const isNumber = new RegExp('^[0-9]+$');
    const isNumberTest = isNumber.test(this.inputTarget.value);
    const staffGroupId = this.staffGroupId()

    if (this.inputTarget.value.length >= 3) {
      this.debounceFetchUsers(staffGroupId)
      this.showSpinner()
    } else if (isNumberTest) {
      this.inputTarget.value = '';
    } else {
      this.clearWorkers()
      this.hideSpinner()
    }
  }

  showSpinner() {
    this.spinnerTarget.classList.remove('d-none')
  }

  hideSpinner() {
    this.spinnerTarget.classList.add('d-none')
  }

  debounceFetchUsers(staffGroupId) {
    let inDebounce
    const context = this
    clearTimeout(inDebounce)
    inDebounce = setTimeout(function () {
      context.fetchUsers(staffGroupId)
    }, 1000)
  }

  selectUser(event) {
    event.preventDefault();
    this.valueTarget.value = event.target.dataset.value
  }
}
import { application } from '../../frontend/controllers/index'

application.register("worker_selector", WorkerSelectorController)
