import React from "react";
import styled from "styled-components";

import {
  ShiftModalFormGrid,
  ShiftModalFormGroup,
} from "./ShiftModalComponents";

export const AgencyTabSingle = ({
  error,
  handleSingleInputChange,
  singleDirectEngagement,
  toggleSingleDirectEngagement,
  toggleSingleFrameWorkerCompliant,
  singleFrameWorkerCompliant,
  vms,
}) => (
  <div>
    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Worker Rate / hr"}
        name="core_locum_rate"
        onChange={handleSingleInputChange}
        type="number"
        error={error.single_core_locum_rate && error.message}
      />
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"agency margin / hr"}
        name="core_agency_margin"
        onChange={handleSingleInputChange}
        type="number"
        error={error.single_core_agency_margin && error.message}
      />
    </ShiftModalFormGrid>

    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"NI Contribution / hr"}
        name="core_ni_contribution"
        onChange={handleSingleInputChange}
        type="number"
        error={error.single_core_ni_contribution && error.message}
      />

      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Total Rate / hr"}
        name="core_total_rate"
        onChange={handleSingleInputChange}
        type="number"
        error={error.single_core_total_rate && error.message}
      />
    </ShiftModalFormGrid>

    <ShiftModalFormGrid columns={vms ? 2 : 3} full>
      <ShiftModalFormGroup
        component={"custom-checkbox"}
        label={"Direct Engagement?"}
        name="direct_engagement"
        onChange={toggleSingleDirectEngagement}
        value={singleDirectEngagement}
      />
    </ShiftModalFormGrid>
  </div>
);

export const AgencyTabSplit = ({
  error,
  handleSplitInputChange,
  splitDirectEngagement,
  toggleSplitDirectEngagement,
  toggleSplitFrameWorkerCompliant,
  splitFrameWorkerCompliant,
  vms,
}) => (
  <div>
    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Core Worker Rate / hr"}
        name="core_locum_rate"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_core_locum_rate && error.message}
      />
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Unsocial Worker Rate / hr"}
        name="unsocial_locum_rate"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_unsocial_locum_rate && error.message}
      />
    </ShiftModalFormGrid>
    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Core NI Contribution / hr"}
        name="core_ni_contribution"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_core_ni_contribution && error.message}
      />

      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Unsocial NI Contribution / hr"}
        name="unsocial_ni_contribution"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_unsocial_ni_contribution && error.message}
      />
    </ShiftModalFormGrid>

    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Core agency margin / hr"}
        name="core_agency_margin"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_core_agency_margin && error.message}
      />

      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Unsocial Agency Margin / hr"}
        name="unsocial_agency_margin"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_unsocial_agency_margin && error.message}
      />
    </ShiftModalFormGrid>

    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Total Core Rate / hr"}
        name="core_total_rate"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_core_total_rate && error.message}
      />

      <ShiftModalFormGroup
        component={"currency__test"}
        defaultValue={"0.00"}
        label={"Total Unsocial Rate / hr"}
        name="unsocial_total_rate"
        onChange={handleSplitInputChange}
        type="number"
        error={error.split_unsocial_total_rate && error.message}
      />
    </ShiftModalFormGrid>

    <ShiftModalFormGrid columns={vms ? 2 : 3} full>
      <ShiftModalFormGroup
        component={"custom-checkbox"}
        label={"Direct Engagement?"}
        name="direct_engagement"
        onChange={toggleSplitDirectEngagement}
        value={splitDirectEngagement}
      />
    </ShiftModalFormGrid>
  </div>
);

export const AgencyOptionalInfo = ({ handleOptionalInputChange }) => (
  <>
    <h2>Optional Info</h2>
    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"input"}
        label={"Requisition Number"}
        name="requisition_number"
        onChange={handleOptionalInputChange}
        type="number"
        placeholder="12345"
      />
      <ShiftModalFormGroup
        component={"input"}
        label={"PO Number"}
        name="po_number"
        onChange={handleOptionalInputChange}
        type="number"
        placeholder="12345"
      />
    </ShiftModalFormGrid>
    <ShiftModalFormGrid columns={3} full>
      <ShiftModalFormGroup
        component={"input"}
        label={"Receipt Number"}
        name="receipt_number"
        onChange={handleOptionalInputChange}
        type="number"
        placeholder="12345"
      />
    </ShiftModalFormGrid>
    <ShiftModalFormGrid columns={2} full>
      <ShiftModalFormGroup
        component={"textarea"}
        label={"Comments"}
        name="comment"
        onChange={handleOptionalInputChange}
        placeholder={"Leave any additional comments."}
        type="textarea"
      />
    </ShiftModalFormGrid>
  </>
);

export const AgencyLocumPicker = ({
  agencies,
  agencyLocums,
  fetchAgencyWorkersAction,
  handleSelectChange,
}) => (
  <>
    <ShiftModalFormGroup
      component={"react-select"}
      label={"Agency"}
      options={agencies}
      placeholder="Please select agency"
      name="agency_id"
      onChange={(event) => {
        handleSelectChange(event, "agency_id");
        fetchAgencyWorkersAction(event.value);
      }}
    />
    <ShiftModalFormGroup
      component={"react-select"}
      label={"Worker"}
      options={agencyLocums}
      placeholder="Please select worker"
      name="agency_worker_id"
      onChange={(event) => handleSelectChange(event, "agency_worker_id")}
    />
  </>
);

export const LockShift = ({ agencyLocked, toggleAgencyLocked }) => (
  <LockShiftContainer>
    <h3>Lock Shift to agency</h3>
    <LockShiftButtonContainer>
      <button
        onClick={toggleAgencyLocked}
        className={agencyLocked === true ? "on" : ""}
      >
        <svg width="12" height="15" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.624 12.032a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zm4.063-5.469v-2.5A4.063 4.063 0 005.427.006C3.244.108 1.563 1.992 1.563 4.179v.04a.781.781 0 001.563 0v-.068c0-1.328 1-2.493 2.328-2.58a2.501 2.501 0 012.671 2.493v2.5H1.562C.7 6.563 0 7.263 0 8.125v5.312C0 14.3.7 15 1.562 15h8.125c.862 0 1.562-.7 1.562-1.562V8.126c0-.863-.7-1.563-1.562-1.563z"
            fill="#FFF"
            fillRule="evenodd"
          />
        </svg>
      </button>
    </LockShiftButtonContainer>
  </LockShiftContainer>
);

export const AssignAgencyWorkerContainer = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 250px;
  margin-bottom: 24px;
`;

export const AssignAgencyWorkerTabs = styled.div`
  border-bottom: 1px solid #cbd2d9;
  margin-bottom: 32px;
  padding-left: 12px;

  button {
    background: transparent;
    border: 0;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    bottom: -1px;
    box-shadow: none;
    color: #9ca8b4;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    padding: 15px 24px;
    position: relative;

    &.active {
      background: #ffffff;
      border-left: 1px solid #cbd2d9;
      border-right: 1px solid #cbd2d9;
      border-top: 1px solid #cbd2d9;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #1e2d36;
    }
  }
`;

export const LockShiftContainer = styled.div`
  background: #f5f7fa;
  border: 1px solid #cbd2d9;
  border-radius: 4px;
  padding: 16px;
  text-align: center;
  width: 100%;

  h3 {
    font-size: 15px;
    margin-bottom: 16px;
    text-transform: uppercase;
  }
`;

export const LockShiftButtonContainer = styled.div`
  background: #ffffff;
  border: 1px solid #c9d2da;
  border-radius: 13px;
  height: 26px;
  margin: 0 auto;
  position: relative;
  width: 52px;

  button {
    align-items: center;
    background: #c9d2da;
    border: 0;
    border-radius: 50%;
    display: flex;
    height: 30px;
    justify-content: center;
    left: -4px;
    line-height: 1;
    padding: 0;
    position: absolute;
    top: -3px;
    transition: all 0.25s ease-in-out;
    width: 30px;

    &.on {
      background: #008822;
      left: 25px;
    }

    svg {
      left: 1px;
      position: relative;
      top: -1px;
    }
  }
`;
