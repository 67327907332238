import React, { useState } from "react";

import {
  ErrorContainer,
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalButtonClose,
  ShiftModalDetailGroup,
  ShiftModalFormGrid,
  ShiftModalFormGroup,
} from "../../ShiftModalComponents";
import {
  AgencyTabSingle,
  AgencyTabSplit,
  AgencyOptionalInfo,
  AssignAgencyWorkerContainer,
  AssignAgencyWorkerTabs,
  LockShift,
} from "../../ShiftModalAgency";

import {
  amendAgencySingle,
  amendAgencySplit,
  fetchAgencyWorkers,
} from "../../../../../Helpers/ShiftHelpers";

const ShiftModalAmendAgencyBooking = ({
  fetchShift,
  fetchShifts,
  handleClose,
  setSelectedTab,
  setShiftLoading,
  shift,
}) => {
  const [agencyInputs] = useState({
    agency_id: null,
    agency_worker_id: null,
  });
  const [agencyLocked, setAgencyLocked] = useState(false);
  const [agencyTab, setAgencyTab] = useState("single");
  const [error, setError] = useState({ error: false, message: null });
  const [optionalInputs, setOptionalInputs] = useState({});
  const [singleDirectEngagement, setSingleDirectEngagement] = useState(false);
  const [singleInputs, setSingleInputs] = useState({
    core_total_rate: 0,
    core_locum_rate: 0,
    core_agency_margin: 0,
    core_ni_contribution: 0,
  });
  const [splitDirectEngagement, setSplitDirectEngagement] = useState(false);
  const [splitInputs, setSplitInputs] = useState({
    core_total_rate: 0,
    unsocial_total_rate: 0,
    core_locum_rate: 0,
    unsocial_locum_rate: 0,
    core_ni_contribution: 0,
    unsocial_ni_contribution: 0,
    core_agency_margin: 0,
    unsocial_agency_margin: 0,
  });

  const toggleAgencyLocked = () => setAgencyLocked(!agencyLocked);
  const toggleSingleDirectEngagement = () =>
    setSingleDirectEngagement(!singleDirectEngagement);
  const toggleSplitDirectEngagement = () =>
    setSplitDirectEngagement(!splitDirectEngagement);

  const handleSingleInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setSingleInputs((singleInputs) => ({
      ...singleInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSplitInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setSplitInputs((splitInputs) => ({
      ...splitInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleOptionalInputChange = (event) => {
    setError({ error: false });
    event.persist();
    setOptionalInputs((optionalInputs) => ({
      ...optionalInputs,
      [event.target.name]: event.target.value,
    }));
  };

  const singleBody = {
    shift: {
      agency_id: agencyInputs.agency_id,
      agency_worker_id: agencyInputs.agency_worker_id,
      core_total_rate: singleInputs.core_total_rate,
      core_locum_rate: singleInputs.core_locum_rate,
      core_agency_margin: singleInputs.core_agency_margin,
      core_ni_contribution: singleInputs.core_ni_contribution,
      direct_engagement: singleDirectEngagement,
      requisition_number: optionalInputs.requisition_number,
      po_number: optionalInputs.po_number,
      receipt_number: optionalInputs.receipt_number,
      comment: optionalInputs.comment,
      agency_locked: agencyLocked,
    },
  };

  const splitBody = {
    shift: {
      agency_id: agencyInputs.agency_id,
      agency_worker_id: agencyInputs.agency_worker_id,
      core_total_rate: splitInputs.core_total_rate,
      unsocial_total_rate: splitInputs.unsocial_total_rate,
      core_locum_rate: splitInputs.core_locum_rate,
      unsocial_locum_rate: splitInputs.unsocial_locum_rate,
      core_ni_contribution: splitInputs.core_ni_contribution,
      unsocial_ni_contribution: splitInputs.unsocial_ni_contribution,
      core_agency_margin: splitInputs.core_agency_margin,
      unsocial_agency_margin: splitInputs.unsocial_agency_margin,
      direct_engagement: splitDirectEngagement,
      requisition_number: optionalInputs.requisition_number,
      po_number: optionalInputs.po_number,
      receipt_number: optionalInputs.receipt_number,
      comment: optionalInputs.comment,
      agency_locked: agencyLocked,
    },
  };

  const assignAgencySingleAction = (e) => {
    setError({ error: false });
    if (agencyInputs.agency_id === null) {
      setError({
        ...error,
        agency_id: true,
        message: "Please select an agency.",
      });
    } else if (agencyInputs.agency_worker_id === null) {
      setError({
        ...error,
        agency_worker_id: true,
        message: "Please select an agency worker.",
      });
    } else if (singleInputs.core_total_rate <= 0) {
      setError({
        ...error,
        single_core_total_rate: true,
        message: "Please enter the total rate.",
      });
    } else {
      e.preventDefault();
      setShiftLoading(true);
      amendAgencySingle(shift.id, singleBody).then((data) => {
        if (data !== "err") {
          fetchShift(shift.id);
          setTimeout(() => {
            setSelectedTab("details");
            setShiftLoading(false);
            fetchShifts();
          }, 300);
        } else {
          setShiftLoading(false);
          setError({
            ...error,
            main: true,
            message:
              "There was a problem assigning the shift, please try again.",
          });
        }
      });
    }
  };

  const assignAgencySplitAction = (e) => {
    setError({ error: false });
    if (agencyInputs.agency_id === null) {
      setError({
        ...error,
        agency_id: true,
        message: "Please select an agency.",
      });
    } else if (agencyInputs.agency_worker_id === null) {
      setError({
        ...error,
        agency_worker_id: true,
        message: "Please select an agency worker.",
      });
    } else if (splitInputs.core_total_rate <= 0) {
      setError({
        ...error,
        split_core_total_rate: true,
        message: "Please enter the total rate.",
      });
    } else if (splitInputs.unsocial_total_rate <= 0) {
      setError({
        ...error,
        split_unsocial_total_rate: true,
        message: "Please enter the unsocial total rate.",
      });
    } else {
      e.preventDefault();
      setShiftLoading(true);
      amendAgencySplit(shift.id, splitBody).then((data) => {
        if (data !== "err") {
          fetchShift(shift.id);
          setTimeout(() => {
            setSelectedTab("details");
            setShiftLoading(false);
            fetchShifts();
          }, 300);
        } else {
          setShiftLoading(false);
          setError({
            ...error,
            main: true,
            message:
              "There was a problem assigning the shift, please try again.",
          });
        }
      });
    }
  };

  return (
    <>
      <ShiftModalDetailFooter>
        <div>
          {error.main && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <ShiftModalButtonClose onClick={handleClose}>
            Close
          </ShiftModalButtonClose>
          {agencyTab === "single" ? (
            <ShiftModalButton
              theme={"blue"}
              onClick={(e) => assignAgencySingleAction(e)}
            >
              Save Changes
            </ShiftModalButton>
          ) : (
            <ShiftModalButton
              theme={"blue"}
              onClick={(e) => assignAgencySplitAction(e)}
            >
              Save Changes
            </ShiftModalButton>
          )}
        </div>
      </ShiftModalDetailFooter>
      <ShiftModalContainer>
        <ShiftModalDetailGroup>
          <AssignAgencyWorkerContainer>
            <div>
              <h2>Agency Info</h2>
              <ShiftModalFormGrid columns={2}>
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={"Test Agency"}
                  label={"Agency"}
                  name="agency_id"
                  type="text"
                  disabled={true}
                />
                <ShiftModalFormGroup
                  component={"input"}
                  defaultValue={"Test Agency Worker"}
                  label={"Worker"}
                  name="agency_worker_id"
                  type="text"
                  disabled={true}
                />
                {/* <AgencyLocumPicker agencies={agencies} agencyLocums={agencyLocums} fetchAgencyWorkersAction={fetchAgencyWorkersAction} handleSelectChange={handleSelectChange} /> */}
              </ShiftModalFormGrid>
              {(error.agency_id || error.agency_worker_id) && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <LockShift
              agencyLocked={agencyLocked}
              toggleAgencyLocked={toggleAgencyLocked}
            />
          </AssignAgencyWorkerContainer>
          <div>
            <AssignAgencyWorkerTabs>
              <button
                className={agencyTab === "single" ? "active" : ""}
                onClick={() => setAgencyTab("single")}
              >
                Single Rate
              </button>
              <button
                className={agencyTab === "split" ? "active" : ""}
                onClick={() => setAgencyTab("split")}
              >
                Split Core/Unsocial Rate
              </button>
            </AssignAgencyWorkerTabs>
            {agencyTab === "single" && (
              <AgencyTabSingle
                error={error}
                handleSingleInputChange={handleSingleInputChange}
                singleDirectEngagement={singleDirectEngagement}
                toggleSingleDirectEngagement={toggleSingleDirectEngagement}
              />
            )}
            {agencyTab === "split" && (
              <AgencyTabSplit
                error={error}
                handleSplitInputChange={handleSplitInputChange}
                toggleSplitDirectEngagement={toggleSplitDirectEngagement}
                splitDirectEngagement={splitDirectEngagement}
              />
            )}
          </div>
        </ShiftModalDetailGroup>
        <ShiftModalDetailGroup>
          <AgencyOptionalInfo
            handleOptionalInputChange={handleOptionalInputChange}
          />
        </ShiftModalDetailGroup>
      </ShiftModalContainer>
    </>
  );
};

export default ShiftModalAmendAgencyBooking;
