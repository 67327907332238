import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'
import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
  FormToggleComponent,
  FormToggleContainer,
} from '../../Forms/FormElements'
import { CloseButton, Loading, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../Modals/ModalElements'
import { GET_ORGANISATION_AGENCIES } from '../AgencyList/AgencyListHelpers'
import {
  SUSPEND_AGENCY_USER,
  UNSUSPEND_AGENCY_USER,
  VMS_SUSPEND_AGENCY_USER,
  VMS_UNSUSPEND_AGENCY_USER,
  GET_AGENCY_USER_PERMISSIONS,
  GET_VMS_AGENCY_USER_PERMISSIONS,
} from './AgencyUsersHelpers'
import AgencyUsersPermissions from './AgencyUsersPermissions'

function AgencyUsersModal({ agencies, agencyUser, handleClose, refetch, show, vms }) {
  const [action, setAction] = useState(undefined)
  const [error, setError] = useState({
    first_name: '',
    last_name: '',
    job_title: '',
    email_address: '',
    phone_number: '',
    agency_id: '',
  })
  const [inputs, setInputs] = useState({
    first_name: '',
    last_name: '',
    job_title: '',
    email_address: '',
    phone_number: '',
    agency_id: '',
  })
  const [loading, setLoading] = useState(false)
  const [manageAgencyUsers, setManageAgencyUsers] = useState(false)
  const [manageAgencyWorkers, setManageAgencyWorkers] = useState(false)
  const [showNewAgencyUserPermissions, setShowNewAgencyUserPermissions] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [suspended, setSuspended] = useState(undefined)

  const handleCloseConfirm = () => {
    setShowConfirm(false)
  }

  const handleShowConfirm = (actionId) => {
    setAction(actionId)
    setShowConfirm(true)
  }

  const [suspendAgencyUser] = useMutation(SUSPEND_AGENCY_USER)
  const [unsuspendAgencyUser] = useMutation(UNSUSPEND_AGENCY_USER)
  const [vmsSuspendAgencyUser] = useMutation(VMS_SUSPEND_AGENCY_USER)
  const [vmsUnsuspendAgencyUser] = useMutation(VMS_UNSUSPEND_AGENCY_USER)

  const { data, refetch: refetchAgencyUserPermissions } = useQuery(
    vms ? GET_VMS_AGENCY_USER_PERMISSIONS : GET_AGENCY_USER_PERMISSIONS,
    {
      variables: {
        agencyUserId: Number(agencyUser?.id),
      },
    }
  )
  const agencyUserPermissions = vms ? data?.vmsAgencyUserPermissions : data?.agencyUserPermissions

  const { data: organisationAgenciesData } = useQuery(GET_ORGANISATION_AGENCIES, {
    variables: {
      agencyId: agencyUser ? Number(agencyUser.agency?.id) : undefined,
    },
  })
  const organisationAgencies = organisationAgenciesData?.organisationAgencies

  // remap agencies into an array react-select can read
  const orgAgencies = agencies?.map(function (agency) {
    return { value: agency.id, label: agency.title }
  })

  useEffect(() => {
    if (agencyUser) {
      setInputs({
        first_name: agencyUser.firstName ? agencyUser.firstName : '',
        last_name: agencyUser.lastName ? agencyUser.lastName : '',
        job_title: agencyUser.jobTitle ? agencyUser.jobTitle : '',
        email_address: agencyUser.email ? agencyUser.email : '',
        phone_number: agencyUser.phoneNumber ? agencyUser.phoneNumber : '',
        agency_id: agencyUser.agency ? agencyUser.agency.id : '',
      })
      setManageAgencyUsers(agencyUser.canManageUsers ? agencyUser.canManageUsers : false)
      setSuspended(agencyUser ? agencyUser.suspended : undefined)
      setManageAgencyWorkers(agencyUser.canCreateAgencyWorkers ? agencyUser.canCreateAgencyWorkers : false)
    }
  }, [agencyUser])

  const handleCloseAction = () => {
    setInputs({})
    setManageAgencyUsers(false)
    setManageAgencyWorkers(false)
    setError({})
    handleClose()
  }

  const handleInputChange = (event) => {
    setError({})
    event.persist()
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }))
  }

  const handleAgencyChange = (event) => {
    setError({})
    setInputs((inputs) => ({ ...inputs, agency_id: event.value }))
  }

  const toggleManageAgencyUsers = () => setManageAgencyUsers(!manageAgencyUsers)
  const toggleManageAgencyWorkers = () => setManageAgencyWorkers(!manageAgencyWorkers)

  return (
    <>
      <Modal backdrop="static" centered onHide={handleClose} show={show} size="lg">
        <ModalHeader>
          <ModalTitle>{agencyUser ? `Edit Agency User` : 'Create Agency User'}</ModalTitle>
          <CloseButton onClick={() => handleCloseAction()}>
            <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
                fill="#9CA8B4"
                fillRule="evenodd"
              />
            </svg>
          </CloseButton>
        </ModalHeader>
        {loading ? (
          <Loading />
        ) : (
          <>
            <ModalBody>
              <AgencyForm>
                <FormDetailGroup>
                  <h2>User Information</h2>
                  <FormGrid columns={3}>
                    <FormGroup
                      component="input"
                      error={error.first_name}
                      label="First Name"
                      name="first_name"
                      onChange={handleInputChange}
                      placeholder="Enter First Name"
                      type="text"
                      value={inputs.first_name}
                    />
                    <FormGroup
                      component="input"
                      error={error.last_name}
                      label="Last Name"
                      name="last_name"
                      onChange={handleInputChange}
                      placeholder="Enter Last Name"
                      type="text"
                      value={inputs.last_name}
                    />
                    <FormGroup
                      component="input"
                      error={error.job_title}
                      label="Job Title"
                      name="job_title"
                      onChange={handleInputChange}
                      placeholder="Add Title here..."
                      type="text"
                      value={inputs.job_title}
                    />
                  </FormGrid>
                  <FormGrid columns={2} size="2fr 1fr">
                    <FormGroup
                      component="input"
                      error={error.email_address}
                      label="Email Address"
                      name="email_address"
                      onChange={handleInputChange}
                      placeholder="Enter Email Address"
                      type="text"
                      value={inputs.email_address}
                    />
                    <FormGroup
                      component="input"
                      error={error.phone_number}
                      label="Phone No."
                      name="phone_number"
                      onChange={handleInputChange}
                      placeholder="Add Phone No."
                      type="text"
                      value={inputs.phone_number}
                    />
                  </FormGrid>
                  <FormGrid columns={3}>
                    {!vms && (
                      <FormToggleContainer>
                        <p>Can manage workers</p>
                        <FormToggleComponent
                          className={manageAgencyWorkers === true ? 'on' : ''}
                          onClick={toggleManageAgencyWorkers}
                        >
                          <button />
                        </FormToggleComponent>
                      </FormToggleContainer>
                    )}
                    {agencyUser && agencyUser.agency?.canManageUsers && (
                      <FormToggleContainer style={{ maxWidth: 200 }}>
                        <p>Can manage users</p>
                        <FormToggleComponent
                          className={manageAgencyUsers === true ? 'on' : ''}
                          onClick={toggleManageAgencyUsers}
                        >
                          <button />
                        </FormToggleComponent>
                      </FormToggleContainer>
                    )}
                  </FormGrid>
                </FormDetailGroup>
                {!vms && (
                  <FormDetailGroup>
                    <h2>Agency Permissions</h2>
                    <FormGrid columns={3}>
                      <FormGroup
                        component="react-select"
                        error={error.agency_id}
                        label="Assign to Agency"
                        name="agency_id"
                        onChange={handleAgencyChange}
                        options={orgAgencies}
                        placeholder="Select Agency"
                        value={
                          agencyUser && {
                            label: agencyUser.agency?.title,
                            value: agencyUser.agency?.id,
                          }
                        }
                      />
                    </FormGrid>
                  </FormDetailGroup>
                )}
              </AgencyForm>
              {agencyUser?.id && (
                <FormDetailGroup>
                  {vms && <h2>Permissions</h2>}
                  {agencyUserPermissions?.map((agencyUserPermission, index) => {
                    return (
                      <AgencyUsersPermissions
                        key={agencyUserPermission.id}
                        agencyId={agencyUser?.agency?.id}
                        agencyUserPermission={agencyUserPermission}
                        agencyUserPermissions={agencyUserPermissions}
                        index={index}
                        organisationAgencies={organisationAgencies}
                        refetchAgencyUserPermissions={refetchAgencyUserPermissions}
                        refetchAgencyUsers={refetch}
                        vms={vms}
                      />
                    )
                  })}
                  {(showNewAgencyUserPermissions || !agencyUserPermissions?.length > 0) && (
                    <AgencyUsersPermissions
                      agencyId={agencyUser?.agency?.id}
                      agencyUserId={agencyUser?.id}
                      agencyUserPermissions={agencyUserPermissions}
                      organisationAgencies={organisationAgencies}
                      refetchAgencyUserPermissions={refetchAgencyUserPermissions}
                      refetchAgencyUsers={refetch}
                      setShowNewAgencyUserPermissions={setShowNewAgencyUserPermissions}
                      vms={vms}
                    />
                  )}
                  {agencyUserPermissions?.length > 0 &&
                    agencyUserPermissions?.length !== organisationAgencies?.length && (
                      <AddStaffGroupButton onClick={() => setShowNewAgencyUserPermissions(true)}>
                        Add Another Staff Group
                      </AddStaffGroupButton>
                    )}
                </FormDetailGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <div>
                {agencyUser && (
                  <>
                    {suspended !== true ? (
                      // <button className="btn btn-white" onClick={() => handleShowConfirm('suspend')} type="button">
                      //   Suspend Agency User
                      // </button>

                      <div />
                    ) : (
                      <button className="btn btn-white" onClick={() => handleShowConfirm('reactivate')} type="button">
                        <svg fill="none" height="18" width="22" xmlns="http://www.w3.org/2000/svg">
                          <path
                            clipRule="evenodd"
                            d="M11.697 10.584a.92.92 0 01-.907.927.92.92 0 01-.908-.927V5.486a.92.92 0 01.908-.927.92.92 0 01.907.927v5.098zm-.907 4.171a.92.92 0 01-.908-.927.92.92 0 01.908-.926.92.92 0 01.907.927.92.92 0 01-.907.926zm10.42-.338L12.69 1.05a2.245 2.245 0 00-3.801 0L.37 14.417C-.61 15.961.47 18 2.27 18h17.038c1.801 0 2.881-2.04 1.901-3.583z"
                            fill="#FF6C6C"
                            fillRule="evenodd"
                          />
                        </svg>{' '}
                        Reactivate User
                      </button>
                    )}
                  </>
                )}
                {error && error.message && (
                  <ErrorContainer>
                    <p>{error.message}</p>
                  </ErrorContainer>
                )}
              </div>
              <div>
                <button className="btn btn-close" onClick={() => handleCloseAction()}>
                  Cancel
                </button>
                {agencyUser && (
                  <button className="btn text-white btn-cyan--modal" onClick={() => handleCloseAction()}>
                    Save Changes
                  </button>
                )}
              </div>
            </ModalFooter>
          </>
        )}
      </Modal>
      {showConfirm && (
        <ConfirmModal
          action={action}
          agencyUser={agencyUser}
          handleCloseConfirm={handleCloseConfirm}
          refetch={refetch}
          setLoading={setLoading}
          setSuspended={setSuspended}
          showConfirm={showConfirm}
          suspendAgencyUser={suspendAgencyUser}
          unsuspendAgencyUser={unsuspendAgencyUser}
          vms={vms}
          vmsSuspendAgencyUser={vmsSuspendAgencyUser}
          vmsUnsuspendAgencyUser={vmsUnsuspendAgencyUser}
        />
      )}
    </>
  )
}

export default AgencyUsersModal

function ConfirmModal({
  action,
  agencyUser,
  handleCloseConfirm,
  refetch,
  setLoading,
  setSuspended,
  showConfirm,
  suspendAgencyUser,
  unsuspendAgencyUser,
  vms,
  vmsSuspendAgencyUser,
  vmsUnsuspendAgencyUser,
}) {
  const [error, setError] = useState({})

  const suspendAgencyUserAction = (agencyUserId) => {
    if (vms) {
      vmsSuspendAgencyUser({
        variables: {
          id: Number(agencyUserId),
        },
      }).then((data) => {
        setLoading(false)
        if (data.data.vmsAgencyUserSuspend === true) {
          setSuspended(true)
          refetch()
          handleCloseConfirm()
        } else {
          setError({
            error: true,
            message: `There was a problem suspending this Agency User.`,
          })
        }
      })
    } else {
      setLoading(true)
      suspendAgencyUser({
        variables: {
          id: Number(agencyUser.id),
        },
      }).then((data) => {
        setLoading(false)
        if (data.data.suspendAgencyUser === true) {
          setSuspended(true)
          refetch()
          handleCloseConfirm()
        } else {
          setError({
            error: true,
            message: `There was a problem suspending this Agency User.`,
          })
        }
      })
    }
  }

  const unsuspendAgencyUserAction = (agencyUserId) => {
    if (vms) {
      vmsUnsuspendAgencyUser({
        variables: {
          id: Number(agencyUserId),
        },
      }).then((data) => {
        setLoading(false)
        if (data.data.vmsAgencyUserUnsuspend === true) {
          setSuspended(false)
          refetch()
          handleCloseConfirm()
        } else {
          setError({
            error: true,
            message: `There was a problem unsuspending this Agency User.`,
          })
        }
      })
    } else {
      setLoading(true)
      unsuspendAgencyUser({
        variables: {
          id: Number(agencyUser.id),
        },
      }).then((data) => {
        setLoading(false)
        if (data.data.unsuspendAgencyUser === true) {
          setSuspended(false)
          refetch()
          handleCloseConfirm()
        } else {
          setError({
            error: true,
            message: `There was a problem unsuspending this Agency User.`,
          })
        }
      })
    }
  }

  return (
    <Modal backdrop="static" centered onHide={handleCloseConfirm} show={showConfirm} size="xl">
      <ModalHeader>
        <ModalTitle>
          <span style={{ textTransform: 'capitalize' }}>{action}</span> Agency User
        </ModalTitle>
        <CloseButton onClick={() => handleCloseConfirm()}>
          <svg height="18" width="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ fontSize: 20, minHeight: 0 }}>
        Are you sure you want to {action} {agencyUser.firstName} {agencyUser.lastName}?
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleCloseConfirm}>
            Cancel
          </button>
          {action === 'suspend' ? (
            <button className="btn text-white btn-cyan--modal" onClick={() => suspendAgencyUserAction(agencyUser.id)}>
              Confirm Suspend
            </button>
          ) : (
            <button className="btn text-white btn-cyan--modal" onClick={() => unsuspendAgencyUserAction(agencyUser.id)}>
              Confirm Reactivate
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  )
}

const AgencyForm = styled.div`
  max-width: 800px;
  pointer-events: none;
`

const AddStaffGroupButton = styled.button`
  align-self: flex-end;
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #1e2d36;
  height: fit-content;
  font-size: 14px;
  font-weight: 600;
  margin: 0 auto;
  margin-bottom: 25px;
  margin-top: 15px;
  padding: 0;
  text-align: center;
  text-decoration: underline;
  width: 100%;
`
