import React from 'react'
import styled from "styled-components";

export const Pagination = ({ pageInfo, pagination, setPagination }) => {
  return (
    <PaginationContainer>
      <PaginationButton
        onClick={() => {
          window.history.replaceState(
            null,
            null,
            `${pagination.url}?beforePage=${pageInfo.startCursor}`
          );
          setPagination((pagination) => ({
            ...pagination,
            after: undefined,
            before: pageInfo.startCursor,
            first: undefined,
            last: pagination.items,
          }));
        }}
        disabled={pageInfo.hasPreviousPage ? false : true}
      >
        <svg
          width="8"
          height="14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13L1 7l6-6"
            stroke="#9CA5B0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PaginationButton>
      <PaginationButton
        onClick={() => {
          window.history.replaceState(
            null,
            null,
            `${pagination.url}?afterPage=${pageInfo.endCursor}`
          );
          setPagination((pagination) => ({
            ...pagination,
            after: pageInfo.endCursor,
            before: undefined,
            first: pagination.items,
            last: undefined,
          }));
        }}
        disabled={pageInfo.hasNextPage ? false : true}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 6L15 12L9 18"
            stroke="#9CA5B0"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </PaginationButton>
    </PaginationContainer>
  );
};
const PaginationContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
`;
const PaginationButton = styled.button`
  align-items: center;
  background: #ffffff;
  border: 1px solid #E4E7EB;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  height: 32px;
  justify-content: center;
  width: 32px;

  &:disabled {
    background: #CCD2D8;
    border: 0;
    box-shadow: none;
    opacity: 0.5;
  }

  &:not(:first-child) {
    margin-left: 4px;
  }

  &:not(:last-child) {
    margin-right: 4px;
  }
`;
