import { ApolloProvider } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import createApolloClient from '../createApolloClient'
import Documents from './Documents'

document.addEventListener('DOMContentLoaded', () => {
  if (window.location.pathname === '/workers') {
    const client = createApolloClient()
    const config = { attributes: true, childList: true, subtree: true, characterData: true }

    const observer = new MutationObserver(() => {
      const rootElement = document.querySelector('[data-react-component="worker_file_uploader"]')
      if (rootElement && rootElement.childElementCount === 0) {
        ReactDOM.render(
          <ApolloProvider client={client}>
            <Documents documentTypeId={rootElement.dataset.documentTypeId} workerId={rootElement.dataset.workerId} />
          </ApolloProvider>,
          rootElement
        )
      }
    })

    observer.observe(document, config)
  }
})
