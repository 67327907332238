export async function uploadCsv(formData) {
  const url = `/upload_worker_ratings`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: formData,
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response.json();
  });
  return data;
}
