import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class BreakSelectorController extends Controller {
    static get targets() {
        return ['staff_group_id','grade_id','label', 'select']
    }

    connect() {
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');

        this.select = new SlimSelect({
            select: target,
            onChange: () => {
                if(searchOnSelect){
                    let params = queryString.parse(location.search);
                    if( params['grade_id'] != target.value) {
                        params['grade_id'] = target.value;
                        const url = queryString.stringify(params);
                        window.location.search = url
                    }
                }
            }
        });

        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }
    }

    select(event) {
        event.preventDefault()
        const grade_id = event.toElement.dataset.id
        this.grade_idTarget.value = grade_id
        this.staff_group_idTarget.value = "1" // TODO: Use the real staff group

        const staff_group_label = this.findParentMenuButton(event).textContent.trim()
        const grade_label = event.toElement.textContent.trim()
        const new_label = `${staff_group_label}, ${grade_label}`
        this.labelTarget.textContent = new_label
    }

    findParentMenuButton(event){
        return event.toElement.parentElement.parentElement.getElementsByTagName('button')[0]
    }
}
import { application } from '../../frontend/controllers/index'

application.register("break_selector", BreakSelectorController)