import React, { useState } from "react";
import moment from "moment";
import styled from "styled-components";

import FilterGradesModal from "./FilterGradesModal";
import Pagination from "../Components/Pagination";
import {
  TableContainer,
  TableHeaderContainer,
  TableSearchInput,
  TableComponent,
} from "../Tables/TableElements";
import { TableRowEditIcon } from "../Assets/Icons";

const FilterGrades = ({ filterGrades, pagy, staffGroups }) => {
  const [filterGrade, setFilterGrade] = useState(undefined);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");

  const handleClose = () => {
    setFilterGrade(undefined);
    setShow(false);
  };
  const handleShowCreate = () => {
    setFilterGrade(undefined);
    setShow(true);
  };

  const handleShowEdit = (filterGrade) => {
    setFilterGrade(filterGrade && filterGrade);
    setShow(true);
  };

  // search function
  const filteredFilterGrades = value
    ? filterGrades.filter((item) => {
        return item.title.toLowerCase().includes(value.toLowerCase());
      })
    : filterGrades;

  return (
    <>
      <TableContainer>
        <TableHeaderContainer>
          <TableSearchInput
            placeholder="Search Filter Grades..."
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <button className="btn btn-cyan" onClick={handleShowCreate}>
            <svg
              width="22"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.125 11.917h-3.208v3.208a.92.92 0 01-.917.917.92.92 0 01-.917-.917v-3.208H6.875A.92.92 0 015.958 11a.92.92 0 01.917-.917h3.208V6.875A.92.92 0 0111 5.958a.92.92 0 01.917.917v3.208h3.208a.92.92 0 01.917.917.92.92 0 01-.917.917zM11 0C4.927 0 0 4.927 0 11s4.927 11 11 11 11-4.927 11-11S17.073 0 11 0z"
                fill="#1E2D36"
              />
            </svg>
            Create Filter Grade
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Staff Group</th>
              <th>Created At</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredFilterGrades?.map((filterGrade, index) => (
              <tr key={index}>
                <th>{filterGrade.id}</th>
                <td>{filterGrade.title}</td>
                <td>{filterGrade.staff_group?.title}</td>
                <td>{moment(filterGrade.created_at).format("MMMM Do YYYY")}</td>
                <td style={{ textAlign: "right" }}>
                  <button onClick={() => handleShowEdit(filterGrade)}>
                    <TableRowEditIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
        <Pagination pagy={pagy} />
      </TableContainer>
      {show && (
        <FilterGradesModal
          handleClose={handleClose}
          filterGrade={filterGrade}
          show={show}
          staffGroups={staffGroups}
        />
      )}
    </>
  );
};

export default FilterGrades;
