import PropTypes from 'prop-types'
import React from 'react'
import Success from './svg/Success'

function SuccessStage({ state }) {
  return (
    <div className="reset-pin__success">
      <Success />
      <p className="reset-pin__success-descr">{state.successDescr}</p>
    </div>
  )
}

SuccessStage.propTypes = {
  state: PropTypes.shape({
    successDescr: PropTypes.string.isRequired,
  }).isRequired,
}

export default SuccessStage
