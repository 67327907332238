/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useMutation, useQuery } from '@apollo/client'
import { DefaultButton, GLOBAL, GREY } from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import {
  loadingToast,
  successToast,
  infoToast,
  errorToast,
  VMS_SHIFT_BLOCK_PROPOSAL_REJECT,
  VMS_SHIFT_BLOCK_PROPOSAL_ACCEPT,
  SHORTLIST_MUTATION,
  DE_CONTRACTS_CONTRACTS,
} from '../helpers/BlockBookingsHelpers'
import { ShiftModalTitle, CheckboxSVG, CheckboxWrap, HiddenCheckbox, StyledCheckbox } from '../helpers/BlockComponents'
import { ViewBlockProposalContract } from './ViewBlockProposalContract'
import { BlockProposalTable } from './ViewBlockProposalHelpers'
import { ViewBlockWorkerModal } from './ViewBlockWorkerModal'

const initialInputs = {
  contractOptions: [],
  selectedProposal: null,
  deProposal: null,
  showWorkerDetails: null,
  selectedContract: null,
}

function ViewBlockProposals({
  block,
  canManageProposals,
  canShortlistProposals,
  canOnlyViewProposals,
  handleClose,
  refetch,
  refetchDetails,
  usesDeContracts,
}) {
  const [inputs, setInputs] = useState(initialInputs)
  const [rejectReason, setRejectReason] = useState('')
  const [showRejectTextBox, setShowRejectTextBox] = useState(false)
  // console.log('Inputs', inputs)

  /* Mutations ------------------------------------------------------------- */

  const [shiftBlockRejectProposal] = useMutation(VMS_SHIFT_BLOCK_PROPOSAL_REJECT)
  const [shiftBlockAcceptProposal] = useMutation(VMS_SHIFT_BLOCK_PROPOSAL_ACCEPT)

  /* Queries ------------------------------------------------------------- */

  const { loading } = useQuery(DE_CONTRACTS_CONTRACTS, {
    skip: !usesDeContracts,
    variables: {
      status: 1,
    },
    onCompleted: (res) => {
      setInputs({
        ...inputs,
        contractOptions: res.deContractsContracts.list,
      })
    },
  })

  /* Functions ------------------------------------------------------------- */
  const handleRejectClick = (proposal) => {
    setInputs({
      ...inputs,
      selectedProposal: proposal.id,
    })
    setShowRejectTextBox(true)
  }
  const handleBackClick = () => {
    setRejectReason('')
    setShowRejectTextBox(false)
  }

  const rejectProposal = (proposal, reason = '') => {
    toast.loading('Loading', loadingToast)
    shiftBlockRejectProposal({
      variables: {
        agencyWorkerBlockProposalId: Number(proposal?.id),
        reason: reason !== '' ? reason : null,
      },
    })
      .then((response) => {
        const errors = response.data?.vmsShiftBlockProposalReject?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: 'Application could not be rejected' })
          return
        }
        refetch()
        refetchDetails()
        toast.update(2, { ...successToast, render: 'Application Rejected' })
        setShowRejectTextBox(false)
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Application could not be rejected' })
        setShowRejectTextBox(false)
      })
  }

  const handleAcceptText = (proposal) => {
    // 1. Check if Worker is direct_engagement, if not we return accept, else we continue
    // 2. IF de, we then loop through contract options and see if there are any paymentsTypes that match worker

    if (!usesDeContracts || proposal?.agencyWorker?.employmentType !== 'direct_engagement') {
      return 'Accept'
    }

    if (proposal.agencyWorker.employmentType === 'direct_engagement') {
      const paymentType =
        proposal.agencyWorker.paymentType === 'paye'
          ? 'de_paye'
          : proposal.agencyWorker.isSoleTrader
          ? 'de_sole_trader'
          : 'de_ltd'
      const contractOptionsFiltered = inputs.contractOptions.filter((i) => paymentType === i.contractType)

      if (contractOptionsFiltered.length === 0) {
        return 'Accept, No Contracts'
      }

      return 'Attach DE Contract'
    }

    return 'Accept'
  }

  const acceptProposal = (proposal) => {
    const check = handleAcceptText(proposal)

    if (check === 'Attach DE Contract') {
      setInputs({
        ...inputs,
        deProposal: proposal,
      })
      return
    }

    toast.loading('Loading', loadingToast)
    shiftBlockAcceptProposal({
      variables: {
        agencyWorkerBlockProposalId: Number(proposal?.id),
      },
    })
      .then((response) => {
        const errors = response.data?.vmsShiftBlockProposalAccept?.errors

        if (errors.length > 0) {
          toast.update(2, { ...errorToast, render: 'Application could not be accepted' })
          return
        }

        refetch()
        refetchDetails()
        handleClose()
        toast.update(2, {
          ...infoToast,
          render:
            'Please Wait, Application is being processed! We will inform you upon completion. ( Click to dismiss )',
        })
      })
      .catch(() => {
        toast.update(2, { ...errorToast, render: 'Application could not be accepted' })
      })
  }

  const handleCheckboxChange = (proposal) => {
    setInputs({
      ...inputs,
      selectedProposal: proposal.id === inputs.selectedProposal ? null : proposal.id,
    })
    setShowRejectTextBox(false)
    setRejectReason('')
  }

  /* DE CONTRACTS ------------------------ */

  const handleAssignContract = async (proposal) => {
    toast.loading('Loading', loadingToast)

    const findOption = inputs.contractOptions.find((option) => option.id === inputs.selectedContract.value)

    try {
      const { data: result } = await shiftBlockAcceptProposal({
        variables: {
          agencyWorkerBlockProposalId: Number(proposal?.id),
          deContractVersionId: Number(findOption.activeVersion.id),
        },
      })
      const errors = result.vmsShiftBlockProposalAccept?.errors
      if (errors.length > 0) {
        toast.update(2, { ...errorToast, render: errors[0].message })
        return
      }

      refetch()
      refetchDetails()
      handleClose()
      toast.update(2, {
        ...infoToast,
        render: 'Please Wait, Application is being processed! We will inform you upon completion. ( Click to dismiss )',
      })
    } catch (err) {
      toast.update(2, errorToast)
    }
  }

  const handlePaymentType = (proposal) => {
    const type = proposal.agencyWorker.paymentType
    const soleTrader = proposal.agencyWorker.isSoleTrader

    if (type === 'ltd' && soleTrader) {
      return 'Sole Trader'
    }

    if (type === 'ltd' && !soleTrader) {
      return 'LTD'
    }

    if (type === 'paye') {
      return 'paye'
    }

    if (type === 'umbrella_company') {
      return 'umbrella'
    }

    return 'ltd'
  }

  const proposals = block?.agencyWorkerBlockProposals

  return (
    <>
      <ShiftModalTitle>Agency Proposals</ShiftModalTitle>

      {!canManageProposals && proposals && proposals.length > 0 && (
        <h4>You don&apos;t have permissions to see shortListed proposals</h4>
      )}

      {loading && <h4>Loading...</h4>}
      {canManageProposals && proposals && proposals.length > 0 && !loading && (
        <>
          <h4>
            The following agency workers have been proposed for <strong>block B{block.id}</strong>
          </h4>
          <br />

          {proposals.map((proposal) => {
            const employmentType = proposal?.agencyWorker?.employmentType
            const paymentType = handlePaymentType(proposal)
            const showVatRegistered =
              (paymentType === 'Sole Trader' || paymentType === 'LTD') && employmentType === 'direct_engagement'

            if (inputs.deProposal && inputs.deProposal.id === proposal.id) {
              return (
                <ViewBlockProposalContract
                  employmentType={employmentType}
                  handleAssignContract={handleAssignContract}
                  inputs={inputs}
                  paymentType={paymentType}
                  proposal={proposal}
                  setInputs={setInputs}
                  showVatRegistered={showVatRegistered}
                />
              )
            }

            return (
              proposal.status === 'proposed' && (
                <ProposeWorkerContainer>
                  <div style={{ marginBottom: 25 }}>
                    <ProposeWorkerDetailsContainer>
                      <ProposeWorkerDetails>
                        <ProposeWorkerDetailsName
                          onClick={() => setInputs({ ...inputs, showWorkerDetails: proposal.agencyWorker.id })}
                        >
                          {proposal?.agencyWorker?.firstName} {proposal?.agencyWorker.lastName}
                        </ProposeWorkerDetailsName>
                        <ProposeWorkerDetailsGrade color={proposal?.agencyWorker?.grade?.colour}>
                          {proposal?.agencyWorker?.grade?.title}
                        </ProposeWorkerDetailsGrade>
                        <ProposeWorkerDetailsEmail>{proposal?.agencyWorker?.email}</ProposeWorkerDetailsEmail>
                      </ProposeWorkerDetails>
                    </ProposeWorkerDetailsContainer>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex' }}>
                        <EmploymentIcon>{employmentType === 'direct_engagement' ? 'DE' : 'NonDE'}</EmploymentIcon>
                        <EmploymentIcon>{paymentType}</EmploymentIcon>
                      </div>
                      {showVatRegistered && (
                        <div style={{ marginLeft: 'auto' }}>
                          <EmploymentIcon>
                            VAT registered: {proposal?.agencyWorker?.isVatRegistered ? 'Yes' : 'No'}
                          </EmploymentIcon>
                        </div>
                      )}
                    </div>
                  </div>
                  {proposal.rates.length > 0 && <BlockProposalTable proposal={proposal} />}
                  <ProposalReviewSection>
                    {!canOnlyViewProposals && (
                      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <CheckboxWrap checked style={{ display: 'flex' }}>
                          <HiddenCheckbox
                            isChecked={inputs.selectedProposal === proposal?.id}
                            onChange={() => handleCheckboxChange(proposal)}
                          />
                          <StyledCheckbox isChecked={inputs.selectedProposal === proposal.id}>
                            <CheckboxSVG />
                          </StyledCheckbox>
                          <span style={{ marginLeft: 8 }}>I have reviewed all proposed rates</span>
                        </CheckboxWrap>
                        <div style={{ display: 'flex' }}>
                          {!showRejectTextBox && (
                            <>
                              {canShortlistProposals && (
                                <ShortListButton proposal={proposal} refetchDetails={refetchDetails} />
                              )}
                              <DefaultButton
                                action={() => handleRejectClick(proposal)}
                                color="red"
                                style={{
                                  marginRight: 8,
                                  opacity: inputs.selectedProposal === proposal.id ? 1 : '0.4',
                                  pointerEvents: inputs.selectedProposal === proposal.id ? 'initial' : 'none',
                                }}
                                text="Reject"
                              />
                              <DefaultButton
                                action={() => acceptProposal(proposal)}
                                color="blue"
                                style={{
                                  opacity: inputs.selectedProposal === proposal.id ? 1 : '0.4',
                                  pointerEvents: inputs.selectedProposal === proposal.id ? 'initial' : 'none',
                                }}
                                text={handleAcceptText(proposal)}
                              />
                            </>
                          )}
                          {showRejectTextBox && inputs.selectedProposal === proposal.id && (
                            <>
                              <span>Reason for Rejection:</span>
                              <input
                                id="rejectReason"
                                onChange={(e) => setRejectReason(e.target.value)}
                                placeholder="Optional"
                                type="text"
                                value={rejectReason}
                              />
                              <DefaultButton
                                action={() => rejectProposal(proposal, rejectReason)}
                                color="red"
                                style={{
                                  marginLeft: 8,
                                }}
                                text="Submit"
                              />
                              <DefaultButton
                                action={handleBackClick}
                                color="blue"
                                style={{
                                  marginLeft: 8,
                                }}
                                text="Back"
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </ProposalReviewSection>
                  <ViewBlockWorkerModal inputs={inputs} proposal={proposal} setInputs={setInputs} />
                </ProposeWorkerContainer>
              )
            )
          })}
        </>
      )}
    </>
  )
}

export default ViewBlockProposals

const ProposalReviewSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
`

export const ProposeWorkerContainer = styled.div`
  align-items: center;
  background-color: ${GLOBAL.backgroundColor};
  border-radius: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`

export const ProposeWorkerDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-align: center;
  margin-bottom: 5px;
`

export const ProposeWorkerDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProposeWorkerDetailsName = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: underline;
  cursor: pointer;
`

export const ProposeWorkerDetailsGrade = styled(ProposeWorkerDetailsName)`
  color: ${({ color }) => color || GLOBAL.fontDark};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: none;
`

export const ProposeWorkerDetailsEmail = styled(ProposeWorkerDetailsGrade)`
  color: ${GREY.six};
  font-weight: 400;
  margin: 0;
`

export const EmploymentIcon = styled.div`
  align-items: center;
  background-color: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  padding: 0 8px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 10px;
`

function ShortListButton({ proposal, refetchDetails }) {
  /* State ------------------------------------------------------------- */

  const [shortListMutation] = useMutation(SHORTLIST_MUTATION)

  const handleShortList = () => {
    toast.loading('Loading', loadingToast)

    shortListMutation({
      variables: {
        id: Number(proposal.id),
        className: 'AgencyWorkerBlockProposal',
      },
    })
      .then((data) => {
        const result = data.data.updateProposalShortlistingStatus.shortlistedAt

        if (result) {
          toast.update(2, { ...successToast, render: 'Proposal ShortListed' })
        } else {
          toast.update(2, { ...successToast, render: 'Removed Proposal from ShortList' })
        }

        refetchDetails()
      })
      .catch((error) => {
        toast.update(2, { ...errorToast, render: error })
      })
  }

  return (
    <StyledButton isShortListed={proposal.shortlistedAt} onClick={handleShortList} type="button">
      {proposal.shortlistedAt && <ShortListedIcon />}
      {!proposal.shortlistedAt && <ShortListIcon />}
      {proposal.shortlistedAt ? 'Shortlisted' : 'ShortList'}
    </StyledButton>
  )
}

const StyledButton = styled.button`
  margin-right: 8px;
  height: 42px;
  border-radius: 8px;
  border-radius: 8px;
  border: ${(props) => (props.isShortListed ? '1px solid #193647' : '1px solid #4B4B4B;')};
  background: ${(props) => (props.isShortListed ? '#193647' : 'white')};
  padding: 7px 22px;
  color: ${(props) => (props.isShortListed ? '#fff' : '#193647')};
`

function ShortListedIcon() {
  return (
    <svg
      fill="none"
      height="24"
      style={{ marginRight: 6 }}
      viewBox="0 0 25 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5 4H6.5V18.764L12.5 15.764L18.5 18.764V4ZM6.5 2H18.5C19.0304 2 19.5391 2.21071 19.9142 2.58579C20.2893 2.96086 20.5 3.46957 20.5 4V22L12.5 18L4.5 22V4C4.5 3.46957 4.71071 2.96086 5.08579 2.58579C5.46086 2.21071 5.96957 2 6.5 2ZM14.5 6H16.5V12H14.5V6Z"
        fill="white"
      />
    </svg>
  )
}

function ShortListIcon() {
  return (
    <svg
      fill="none"
      height="24"
      style={{ marginRight: 6 }}
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 4H6V18.764L12 15.764L18 18.764V4ZM6 2H18C18.5304 2 19.0391 2.21071 19.4142 2.58579C19.7893 2.96086 20 3.46957 20 4V22L12 18L4 22V4C4 3.46957 4.21071 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2ZM14 6H16V12H14V6Z"
        fill="#545454"
      />
    </svg>
  )
}

/* DE CONTRACTS STYLING ------------------------ */

export const AgencyProposalFooter = styled.div`
  margin-top: 12px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`
