import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import moment from "moment";
import styled from 'styled-components'

const Batches = ({ batches, shifts, showPayrollCycle, showStaffGroup, inAgency, forReceipts }) => {
  const [value, setValue] = useState('')

  return (
    <>
      <BatchesContainer>
        <BatchesHeaderContainer>
          <div />
          <SearchInput placeholder={batches ? "Search Batch IDs..." : shifts ? "Search Shift IDs..." : "Search..."} type="text" value={value} onChange={e => setValue(e.target.value)} />
        </BatchesHeaderContainer>
        <BatchesTable striped hover>
          {batches ?
            <BatchesContent batches={batches} showPayrollCycle={showPayrollCycle} showStaffGroup={showStaffGroup} value={value} inAgency={inAgency} forReceipts={forReceipts} />
            : shifts ? <BatchShiftsContent shifts={shifts} value={value} />
              : ""
          }
        </BatchesTable>
      </BatchesContainer>
    </>
  )
}

export default Batches

const BatchesContent = ({ batches, showPayrollCycle, showStaffGroup, value, inAgency, forReceipts }) => {
  const [processingBatches, setProcessingBatches] = useState(undefined)
  const urlPrefix = forReceipts ? '/ppi_batches' : '/batches'

  useEffect(() => {
    fetch(`${urlPrefix}/actively_processing`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    }).then(r => r.json())
      .then(data => {
        setProcessingBatches(data.batch_ids)
      });
  }, [setProcessingBatches]);

  const filteredBatches = value ? batches.filter(item => {
    return (
      item.reference.includes(value)
    )
  }) : batches;

  return (
    <>
      <thead>
        <tr>
          <th>Batch ID</th>
          {showStaffGroup &&
            <th>Staff Group</th>
          }
          {showPayrollCycle &&
            <th>Payroll Cycle</th>
          }
          <th>No. of {forReceipts ? 'Receipts' : 'Shifts'}</th>
          <th>Generated By</th>
          <th>Date Submitted</th>
          <th>Time Submitted</th>
          <th className="text-center">View Shifts</th>
          <th className="text-center">Export CSV</th>
          { inAgency && <th className="text-center">Export ZIP</th> }
        </tr>
      </thead>
      <tbody>
        {filteredBatches.map((batch, index) => {
          const isProcessing = processingBatches?.indexOf(batch.id) > -1

          return (
            <tr key={index}>
              <th><a href={`${urlPrefix}/${batch.id}`}>{batch.reference}</a></th>
              {showStaffGroup &&
                <td>{batch.organisation_staff_group?.title}</td>
              }
              {showPayrollCycle &&
                <td>{batch.monthly ? "Monthly" : "Weekly"}</td>
              }
              <td>{forReceipts ? batch.receipt_count : batch.shift_count}</td>
              <td>{batch.hub_user ? `${batch.hub_user.first_name} ${batch.hub_user.last_name}` : ''}</td>
              {batch["finished?"] ?
                <>
                  <td>{moment(batch.finished_processing_time).format("Do MMMM YYYY")}</td>
                  <td>{moment(batch.finished_processing_time).format("h:mma")}</td>
                  <td className="text-center">
                    <a href={`${urlPrefix}/${batch.id}`}>
                      <svg className="icon-shadow" width="34" height="34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><rect stroke="#CBD2D9" fill="#FFF" x=".5" y=".5" width="33" height="33" rx="6" /><path d="M7.194 16.997c5.87-6.33 11.743-6.33 17.612 0a.748.748 0 010 1.006c-5.87 6.33-11.743 6.33-17.612 0a.748.748 0 010-1.006zM16 14.125a3.376 3.376 0 100 6.75 3.376 3.376 0 000-6.75zm0 1.5a1.875 1.875 0 110 3.75 1.875 1.875 0 010-3.75z" fill="#9CA8B4" /></g></svg>
                    </a>
                  </td>
                  <td className="text-center">
                    <a href={`${batch.csv_url}`}>
                      <svg className="icon-shadow" width="34" height="34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><rect stroke="#CBD2D9" fill="#FFF" x=".5" y=".5" width="33" height="33" rx="6" /><path d="M12.02 15.286c.573 0 1.098.373 1.236.93a3.859 3.859 0 007.488 0c.138-.557.663-.93 1.236-.93h1.572c1.49 0 2.448.96 2.448 2.143v6.428C26 25.04 25.04 26 23.857 26H10.143A2.143 2.143 0 018 23.857V17.43c0-1.183.96-2.143 2.143-2.143h1.877zM17.857 8c.473 0 .857.386.857.857v3.223h1.496c.763 0 1.144.926.604 1.466l-3.21 3.205a.854.854 0 01-1.208 0l-3.21-3.205c-.54-.54-.159-1.466.604-1.466h1.496V8.857c0-.471.383-.857.857-.857h1.714z" fill="#9CA8B4" /></g></svg>
                    </a>
                  </td>
                  {inAgency &&
                    <td className="text-center">
                    {batch.zip_url &&
                      <a href={`${batch.zip_url}`}>
                        <svg className="icon-shadow" width="34" height="34" xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd"><rect stroke="#CBD2D9" fill="#FFF" x=".5" y=".5" width="33" height="33" rx="6" /><path d="M12.02 15.286c.573 0 1.098.373 1.236.93a3.859 3.859 0 007.488 0c.138-.557.663-.93 1.236-.93h1.572c1.49 0 2.448.96 2.448 2.143v6.428C26 25.04 25.04 26 23.857 26H10.143A2.143 2.143 0 018 23.857V17.43c0-1.183.96-2.143 2.143-2.143h1.877zM17.857 8c.473 0 .857.386.857.857v3.223h1.496c.763 0 1.144.926.604 1.466l-3.21 3.205a.854.854 0 01-1.208 0l-3.21-3.205c-.54-.54-.159-1.466.604-1.466h1.496V8.857c0-.471.383-.857.857-.857h1.714z" fill="#9CA8B4" /></g></svg>
                      </a>
                    }
                    </td>
                  }
                </>
                : <td colSpan="4">Processing</td>
              }
            </tr>
          )
        })}
      </tbody>
    </>
  )
}

const BatchShiftsContent = ({ shifts, value }) => {
  const numberValue = Number(value)
  const filteredShifts = value ? shifts.filter(item => item.id === numberValue) : shifts;

  return (
    <>
      <thead>
        <tr>
          <th style={{ width: 40 }}>ID</th>
          <th style={{ width: 60 }}>Part</th>
          <th>Emp No.</th>
          <th>Locums's Name</th>
          <th>Grade</th>
          <th>Department</th>
          <th>Shift Date</th>
          <th style={{ width: 130 }}>Shift Hours</th>
          <th>Basic Rate</th>
          <th>WTD</th>
          <th>Total Rate</th>
          <th>Total Pay</th>
        </tr>
      </thead>
      <tbody>
        {filteredShifts.map((shift) => (
          shift.payments.map((payment, index) => (
            <tr key={index}>
              <th>{shift.id}</th>
              <td>{payment.part}</td>
              <td>{payment.esr_number}</td>
              <td>{shift.booked_by?.full_name}</td>
              <td>{shift.worker_grade?.title}</td>
              <td>{shift.department?.name}</td>
              <td>{moment(shift.start_time).format("DD/MM/YY")}</td>
              <td>{moment(payment.start_time).format("HH:mm")} - <br />{moment(payment.end_time).format("HH:mm")} <strong>({payment.hours}hrs)</strong></td>
              <td>£{Number(payment.basic_rate).toFixed(2)}</td>
              <td>£{Number(payment.benefit).toFixed(2)}</td>
              <td>£{Number(payment.total_rate).toFixed(2)}</td>
              <td>£{Number(payment.total_rate * payment.hours).toFixed(2)}</td>
            </tr>
          )
          )
        ))}
      </tbody>
    </>
  )
}

const BatchesHeaderContainer = styled.div`
  align-items:center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`

const BackLink = styled.button`
  background: transparent;
  border: 0;
  box-shadow: none;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 0;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const SearchInput = styled.input`
  background: #FFFFFF;
  border: 0;
  border-radius: 6px;
  box-shadow: none;
  font-size: 16px;
  height: 40px;
  max-width: 320px;
  padding: 0 16px 2px;
  width: 100%;
`

const BatchesContainer = styled.div`
  margin-top: -70px;
`

const BatchesTable = styled(Table)`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
  margin: 0 !important;
  overflow: hidden;

  thead {

    th {
      border: 0;
      border-bottom: 1px solid #D3D9DF;
      color: #1E2D36;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 17px;
      padding: 16px 16px;
      vertical-align: middle;

      &:first-child {
        padding-left: 24px;
      }
    }
  }

  tbody {
    th, td {
      border: 0;
      color: #1E2D36;
      font-size: 14px;
      font-weight: 400;
      height: 84px;
      letter-spacing: 0;
      line-height: 17px;
      padding: 16px 16px;
      vertical-align: middle;

      &:first-child {
        padding-left: 24px;
      }
    }

    th {
      font-weight: 500;

       a {
         text-decoration: underline;
       }
    }

    tr {
      &:nth-of-type(odd) {
        background-color: #F8F9FB;
      }
    }
  }

  .icon-shadow {
    border-radius: 6px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
`
