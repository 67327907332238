import SearchController from "../search_controller";
import flatpickr from "flatpickr";
import queryString from 'query-string'
import moment from "moment";

class DateInputSingleController extends SearchController {
  static targets = ["filterName", "input", "picker"]

  initialize() {
    let controller = this;
    const params = queryString.parse(location.search)

    let paramName = this.filterNameTarget.value
    let paramValue = ""
    Object.keys(params).forEach(function (key) {
      if (key === paramName) {
        return paramValue = params[key]
      }
    })

    let flatPicker = flatpickr(this.pickerTarget, {
      dateFormat: "d/m/Y",
      allowInput: true,
      defaultDate: paramValue ? new Date(paramValue) : null,
      onClose: (selectedDates, dateStr, instance) => {
        if (dateStr == "") {
          controller.inputTarget.value = "";
          instance.clear();
        } else {
          let d = instance.parseDate(controller.pickerTarget.value);
          flatPicker.setDate(d);
          controller.inputTarget.value = moment(d, "d/m/Y").format("YYYY-MM-DD");
          controller.pickerTarget.value = instance.formatDate(d, "d/m/Y");
          this.storeFilters(this.filterNameTarget.value, [{ value: moment(d, "d/m/Y").format("YYYY-MM-DD") }])
        }
      }
    });
  }
}

import { application } from '../../frontend/controllers/index'
application.register("date_input_single", DateInputSingleController);