import React, { useState } from "react";
import styled from "styled-components";

import {
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalDetailGroup,
  ShiftModalButton,
  ShiftModalButtonClose,
  ShiftModalFormGrid,
} from "../../ShiftModalComponents";
import ShiftModalAssign from "../../ShiftModalAssign";

import { approveApplication } from "../../../../../Helpers/ShiftHelpers";

const ShiftApplications = ({
  fetchShift,
  fetchShifts,
  handleClose,
  setSelectedTab,
  setShiftLoading,
  shift,
}) => {
  const [error, setError] = useState({ error: false, message: null });
  const [worker, setWorker] = useState(undefined);
  const applications = shift.appliedBy;

  const setWorkerAction = (worker) => {
    setWorker(worker);
  };

  const approveApplicationAction = () => {
    setShiftLoading(true);
    setError({ error: false, message: null });

    if (worker) {
      const body = {
        shift: {
          worker_id: worker.id,
        },
      };
      approveApplication(shift.id, body).then((data) => {
        if (data !== "err") {
          fetchShift(shift.id);
          setTimeout(() => {
            setSelectedTab("details");
            setShiftLoading(false);
            fetchShifts();
          }, 300);
        } else {
          setShiftLoading(false);
          setError({
            error: true,
            message: `There was a problem selecting this applicant, please try again.`,
          });
        }
      });
    }
  };

  return (
    <>
      {worker && (
        <ShiftModalDetailFooter>
          <div>
            {error && error.message && (
              <ErrorContainer>
                <p>{error.message}</p>
              </ErrorContainer>
            )}
          </div>
          <div>
            <ShiftModalButtonClose onClick={handleClose}>
              Close
            </ShiftModalButtonClose>
            <ShiftModalButton
              theme={"blue"}
              onClick={(e) => approveApplicationAction(e)}
            >
              Confirm Assign
            </ShiftModalButton>
          </div>
        </ShiftModalDetailFooter>
      )}
      <ShiftModalContainer locked={worker ? false : true}>
        <ShiftModalDetailGroup>
          <h2>Applications</h2>
          <ShiftModalApplicationsGrid>
            <div>
              <p>
                The following workers have applied for{" "}
                <strong>Shift #{shift.id}</strong>
              </p>
              <ShiftModalFormGrid columns={1}>
                {applications && (
                  <ShiftModalAssign
                    action={setWorkerAction}
                    data={applications}
                    type={"applicant"}
                    selectedValue={worker && worker.id}
                  />
                )}
              </ShiftModalFormGrid>
            </div>
          </ShiftModalApplicationsGrid>
        </ShiftModalDetailGroup>
      </ShiftModalContainer>
    </>
  );
};

export default ShiftApplications;

const ShiftModalApplicationsGrid = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 250px;
`;
