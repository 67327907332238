import { Controller } from 'stimulus'

export default class InputController extends Controller {
    static get targets() {
        return ['timeInput']
    }

    connect() {}

    //Use for Javascript validation when intended

    // timeInput (e) {
    //     const timeInput = this.timeInputTarget
    //     timeInput.maxLength = 5;

    //     const integerReg = /^[0-9]+$/;

    //     if(timeInput.value.match(integerReg)) {
    //         timeInput.value = e.target.value;
    //         console.log('matches regex')
    //     } else {
    //         timeInput.value = ''
    //         console.log("doesn't match regex")
    //     }
    //  }
}

import { application } from '../../frontend/controllers/index'
application.register("input", InputController)