import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class WorkerSpecialitiesSelectorController extends SearchController {
    static get targets() {
        return ['label', 'select']
    }

    connect() {
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');

        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select Speciality',
            onChange: (info) => {
                this.storeFilters('speciality_ids[]', info)
                if (searchOnSelect) {
                    this.multiSearchOnSelect('speciality_ids[]', info);
                }
            }
        });

        if (this.data.get('initial-value')) {
            this.select.set(this.data.get('initial-value'))
        }
    }
}
import { application } from '../../frontend/controllers/index'
import queryString from "query-string";
import SearchController from "../search_controller";

application.register("worker_specialities_selector", WorkerSpecialitiesSelectorController)
