import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { TableContainer, TableComponent } from "../../Tables/TableElements";
import { ErrorContainer } from "../../Forms/FormElements";
import { Loading } from "../../Modals/ModalElements";
import ShiftModal from "../../Modals/ShiftModal/ShiftModal";
import AgencyLocumModal from "../AgencyLocums/AgencyLocumModal";
import AgencyShiftsFilters from "./AgencyShiftsFilters";

import { VMS_AGENCY_SHIFTS } from "./AgencyShiftsHelpers";
import { usePagination } from "../../../Helpers/usePagination";
import { Pagination } from "../../Components/PaginationGQL";

const AgencyShifts = ({ user }) => {
  const [pagination, setPagination] = usePagination();
  const [shift, setShift] = useState(undefined);
  const [worker, setWorker] = useState(undefined);
  const [show, setShow] = useState(false);
  const [workerShow, setWorkerShow] = useState(false);
  const [filters, setFilters] = useState({
    siteIds: [],
    sites: [],
    departmentIds: [],
    departments: [],
    specialityIds: [],
    specialitys: [],
    staffGroupIds: [],
    staffGroups: [],
    gradeIds: [],
    grades: [],
    agencyWorkerIds: [],
    agencyWorkers: [],
    rate: undefined,
    rateValue: null,
    fromStartTime: undefined,
    toStartTime: undefined,
    shiftType: undefined,
    typeValue: null,
    bookedByAgency: undefined,
    bookedValue: null,
    fromDateValue: undefined,
    toDateValue: undefined,
  });

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (shiftId) => {
    setShift(shiftId);
    setShow(true);
  };

  const handleWorkerClose = () => {
    setWorker(undefined);
    setWorkerShow(false);
  };

  const handleWorkerShow = (worker) => {
    setWorker(worker);
    setWorkerShow(true);
  };

  const handleChangeSite = (selectedOptions) => {
    if (selectedOptions === null) {
      setFilters((inputs) => ({ ...inputs, siteIds: [] }));
      setFilters((inputs) => ({ ...inputs, sites: [] }));
    } else {
      let array = [];
      selectedOptions.map((o) => array.push(Number(o.value)));
      setFilters((inputs) => ({ ...inputs, siteIds: array }));
      setFilters((inputs) => ({ ...inputs, sites: selectedOptions }));
    }
  };

  const handleChangeDepartment = (selectedOptions) => {
    if (selectedOptions === null) {
      setFilters((inputs) => ({ ...inputs, departmentIds: [] }));
      setFilters((inputs) => ({ ...inputs, departments: [] }));
    } else {
      let array = [];
      selectedOptions.map((o) => array.push(Number(o.value)));
      setFilters((inputs) => ({ ...inputs, departmentIds: array }));
      setFilters((inputs) => ({ ...inputs, departments: selectedOptions }));
    }
  };

  const handleChangeStaffGroup = (selectedOptions) => {
    if (selectedOptions === null) {
      setFilters((inputs) => ({ ...inputs, staffGroupIds: [] }));
      setFilters((inputs) => ({ ...inputs, staffGroups: [] }));
    } else {
      let array = [];
      selectedOptions.map((o) => array.push(Number(o.value)));
      setFilters((inputs) => ({ ...inputs, staffGroupIds: array }));
      setFilters((inputs) => ({ ...inputs, staffGroups: selectedOptions }));
    }
  };

  const handleChangeGrade = (selectedOptions) => {
    if (selectedOptions === null) {
      setFilters((inputs) => ({ ...inputs, gradeIds: [] }));
      setFilters((inputs) => ({ ...inputs, grades: [] }));
    } else {
      let array = [];
      selectedOptions.map((o) => array.push(Number(o.value)));
      setFilters((inputs) => ({ ...inputs, gradeIds: array }));
      setFilters((inputs) => ({ ...inputs, grades: selectedOptions }));
    }
  };

  const handleChangeWorkers = (selectedOptions) => {
    if (selectedOptions === null) {
      setFilters((inputs) => ({ ...inputs, agencyWorkerIds: [] }));
      setFilters((inputs) => ({ ...inputs, agencyWorkers: [] }));
    } else {
      let array = [];
      selectedOptions.map((o) => array.push(Number(o.value)));
      setFilters((inputs) => ({ ...inputs, agencyWorkerIds: array }));
      setFilters((inputs) => ({ ...inputs, agencyWorkers: selectedOptions }));
    }
  };

  const handleChangeType = (event) => {
    if (event === null) {
      setFilters((inputs) => ({ ...inputs, shiftType: undefined }));
      setFilters((inputs) => ({ ...inputs, typeValue: null }));
    } else {
      setFilters((inputs) => ({ ...inputs, shiftType: event.value }));
      setFilters((inputs) => ({ ...inputs, typeValue: event }));
    }
  };

  const handleChangeRate = (event) => {
    if (event === null) {
      setFilters((inputs) => ({ ...inputs, rate: undefined }));
      setFilters((inputs) => ({ ...inputs, rateValue: null }));
    } else {
      setFilters((inputs) => ({ ...inputs, rate: event.value }));
      setFilters((inputs) => ({ ...inputs, rateValue: event }));
    }
  };

  const handleChangeBooked = (event) => {
    if (event === null) {
      setFilters((inputs) => ({ ...inputs, bookedByAgency: undefined }));
      setFilters((inputs) => ({ ...inputs, bookedValue: null }));
    } else {
      setFilters((inputs) => ({ ...inputs, bookedByAgency: true }));
      setFilters((inputs) => ({ ...inputs, bookedValue: event }));
    }
  };

  const handleChangeToDate = (day) => {
    if (day === undefined) {
      setFilters((inputs) => ({ ...inputs, toStartTime: undefined }));
      setFilters((inputs) => ({ ...inputs, toDateValue: undefined }));
    } else {
      setFilters((inputs) => ({
        ...inputs,
        toStartTime: day,
      }));
      setFilters((inputs) => ({ ...inputs, toDateValue: day }));
    }
  };

  const handleChangeFromDate = (day) => {
    if (day === undefined) {
      setFilters((inputs) => ({ ...inputs, fromStartTime: undefined }));
      setFilters((inputs) => ({ ...inputs, fromDateValue: undefined }));
    } else {
      setFilters((inputs) => ({
        ...inputs,
        fromStartTime: day,
      }));
      setFilters((inputs) => ({ ...inputs, fromDateValue: day }));
    }
  };

  const ifFilters =
    filters.siteIds.length > 0 ||
    filters.departmentIds.length > 0 ||
    filters.specialityIds.length > 0 ||
    filters.staffGroupIds.length > 0 ||
    filters.gradeIds.length > 0 ||
    filters.rate !== undefined ||
    filters.agencyWorkerIds.length > 0 ||
    filters.fromStartTime !== undefined ||
    filters.toStartTime !== undefined ||
    filters.shiftType !== undefined ||
    filters.bookedByAgency !== undefined;

  const { loading, error, data, refetch } = useQuery(VMS_AGENCY_SHIFTS, {
    variables: {
      siteIds: filters.siteIds,
      departmentIds: filters.departmentIds,
      specialityIds: filters.specialityIds,
      staffGroupIds: filters.staffGroupIds,
      gradeIds: filters.gradeIds,
      rate: filters.rate,
      agencyWorkerIds: filters.agencyWorkerIds,
      fromStartTime: filters.fromStartTime,
      toStartTime: filters.toStartTime,
      shiftType: filters.shiftType,
      bookedByAgency: filters.bookedByAgency,
      ...pagination
    },
    fetchPolicy: "network-only",
  });

  const shifts = data?.vmsShifts.edges;
  const pageInfo = data?.vmsShifts.pageInfo

  return (
    <>
      <TableContainer style={{ marginTop: "-95px" }}>
        <AgencyShiftsFilters
          departments={user.agency.organisation.departments}
          grades={user.agency.organisation.grades}
          sites={user.agency.organisation.sites}
          staffGroups={user.agency.organisation.staff_groups}
          handleChangeSite={handleChangeSite}
          handleChangeDepartment={handleChangeDepartment}
          handleChangeStaffGroup={handleChangeStaffGroup}
          handleChangeGrade={handleChangeGrade}
          handleChangeWorkers={handleChangeWorkers}
          handleChangeType={handleChangeType}
          handleChangeRate={handleChangeRate}
          handleChangeBooked={handleChangeBooked}
          handleChangeToDate={handleChangeToDate}
          handleChangeFromDate={handleChangeFromDate}
          ifFilters={ifFilters}
          filters={filters}
        />
        <div className="shift-table__container">
          {loading ? (
            <Loading />
          ) : error ? (
            <ErrorContainer>
              There was an error loading the shifts.
            </ErrorContainer>
          ) : (
            <>
              <TableComponent striped>
                <thead>
                  <tr>
                    <th>Shift ID</th>
                    <th>Shift Date</th>
                    <th>Hours</th>
                    <th>Department</th>
                    <th>Grade</th>
                    <th>Status</th>
                    <th>Total Rate</th>
                    <th>Agency Rate</th>
                    <th>Worker</th>
                  </tr>
                </thead>
                <tbody>
                  {shifts?.map(({ node: shift }) => (
                    <tr key={shift.id}>
                      <td>
                        <a onClick={() => handleShow(shift.id)}>{shift.id}</a>
                      </td>
                      <td>{moment(shift.startTime).format("DD MMM YYYY")}</td>
                      <td>
                        {moment(shift.startTime).format("h:mma")} →
                        <br />
                        {moment(shift.endTime).format("h:mma")}
                      </td>
                      <td>
                        {shift.department?.name}
                        <br />
                        {shift.site?.name}
                      </td>
                      <td>{shift.grade?.title}</td>
                      <td>
                        <span className={`badge badge-soft-${shift.status}`}>
                          {shift.status}
                        </span>
                      </td>
                      <td>{shift.totalRateSummary}</td>
                      <td>{shift.agencyRateSummary}</td>
                      <td>
                        <a
                          onClick={() =>
                            handleWorkerShow(shift.agencyBooking?.agencyWorker)
                          }
                        >
                          {shift.agencyBooking?.agencyWorker.firstName}{" "}
                          {shift.agencyBooking?.agencyWorker.lastName}
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableComponent>
            </>
          )}
        </div>
      </TableContainer>
      {pageInfo && <Pagination pageInfo={pageInfo} pagination={pagination} setPagination={setPagination} />}
      {show && (
        <ShiftModal
          fetchShifts={refetch}
          handleClose={handleClose}
          shiftId={shift}
          show={show}
          tab={"detailsReadOnly"}
          userId={user.id}
          vms={true}
        />
      )}
      {workerShow && (
        <AgencyLocumModal
          agencyWorker={worker}
          disabled={true}
          handleClose={handleWorkerClose}
          show={workerShow}
          vms={true}
        />
      )}
    </>
  );
};

export default AgencyShifts;
