import { gql } from "@apollo/client";

export const GET_DEPARTMENTS = gql`
  query($siteIds: [Int!], $includeDeleted: Boolean) {
    departments(siteIds: $siteIds, includeDeleted: $includeDeleted) {
      email
      id
      name
      number
      createdAt
      trashedAt
      sites {
        name
      }
      division {
        name
      }
    }
  }
`;

export const GET_DEPARTMENT = gql`
  query getDepartmentInfo($id: Int!) {
    department(id: $id) {
      email
      id
      name
      number
      colour
      covidRating
      externalName
      paymentApproverEmail
      trashedAt
      departmentsPreference {
        isEnhanced
        allowAppSignOff
        allowEarlyAppSignOff
        autoReleaseToSecondaryBank
        bookHigher
        bookLower
        cutOffAgency
        cutOffAlert
        emailOnBooking
        emailOnCancellation
        emailOnSignOff
        excludeFromRpa
        higherPayToGrade
        instantBookingForCollabBanks
        isEnhanced
        lowerGradeSelfBook
        lowerPayToGrade
        autoApprove
        shiftReleaseOption
        shiftReleaseOverride
        signatureForShiftsSignOff
        timedReleaseDays
      }
      primarySpeciality {
        id
        title
      }
      specialities {
        id
        title
      }
      sites {
        id
        name
      }
      division {
        id
        name
      }
      costCentre {
        code
        id
      }
      associatedCostCentres {
        code
        id
      }
    }
  }
`;

export const GET_DEPARTMENT_INFO = gql`
  query getDepartmentInfo($orgId: Int!) {
    specialities {
      id
      title
    }
    organisation(id: $orgId) {
      sites {
        id
        name
      }
      costCentres {
        id
        code
      }
      divisions {
        id
        name
      }
    }
  }
`;

export const DELETE_DEPARTMENT = gql`
  mutation DeleteDepartment($departmentId: Int!) {
    deleteDepartment(departmentId: $departmentId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;

export const RESTORE_DEPARTMENT = gql`
  mutation RestoreDepartment($departmentId: Int!) {
    restoreDepartment(departmentId: $departmentId) {
      errors {
        attribute
        message
      }
      success
    }
  }
`;


export async function createDepartment(body) {
  const url = `/departments`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => (response.status === 200 ? "ok" : response.text()));

  return data;
}

export async function editDepartment(Id, body) {
  const url = `/departments/${Id}`;
  const data = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => (response.status === 200 ? "ok" : response.text()));

  return data;
}

export async function editDepartmentContactDetails(Id, body) {
  const url = `/departments/${Id}/update_contacts`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => (response.status === 200 ? "ok" : response.text()));

  return data;
}
