import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import styled from "styled-components";

import { TableContainer, TableComponent } from "../../Tables/TableElements";
import { ErrorContainer } from "../../Forms/FormElements";
import { Loading } from "../../Modals/ModalElements";

import { GET_ORGANISATION_STAFF_GROUPS } from "../AgencyList/AgencyListHelpers";
import { createAgencyTier, removeAgencyTier } from "./AgencyTiersHelpers";

const AgencyTiers = ({ organisationId }) => {
  const { loading, data, refetch, error: fetchError } = useQuery(
    GET_ORGANISATION_STAFF_GROUPS,
    {
      variables: {
        organisationId: organisationId,
      },
      fetchPolicy: "network-only",
    }
  );
  const [error, setError] = useState(undefined);

  const staffGroups = data?.organisationStaffGroups?.filter(grp => grp.vms);

  const createAgencyTierAction = (organisation_staff_group_id, index) => {
    setError({});
    const body = {
      organisation_staff_group_id: organisation_staff_group_id,
    };
    createAgencyTier(body).then((data) => {
      if (data !== "err") {
        refetch();
      } else {
        setError({
          error: true,
          index: index,
          message: `There was a problem creating this Agency Tier.`,
        });
      }
    });
  };

  const removeAgencyTierAction = (organisation_staff_group_id, agency_tier_id, index) => {
    setError({});
    const body = {
      organisation_staff_group_id: organisation_staff_group_id
    };
    removeAgencyTier(agency_tier_id, body).then((data) => {
      if (data.success === true) {
        refetch();
      } else {
        setError({
          error: true,
          index: index,
          message: data.errors[0],
        });
      }
    });
  };

  return (
    <>
      <TableContainer>
        {loading ? (
          <Loading />
        ) : fetchError ? (
          <ErrorContainer>
            There was an error loading the Agency Tiers.
          </ErrorContainer>
        ) : (
          <TableComponent striped>
            <thead>
              <tr>
                <th style={{ width: 300 }}>Staff Group</th>
                <th>Agency Tiers</th>
              </tr>
            </thead>
            <tbody>
              {staffGroups?.map((staffGroup, index) => (
                <tr key={index}>
                  <th>{staffGroup.title}</th>
                  <td>
                    <TierButtonsContainer>
                      {staffGroup.agencyTiers?.map((tier, tier_index) => (
                        <TierButton key={tier_index}>
                          <span>Tier {tier.rank}</span>
                          {tier_index === staffGroup.agencyTiers.length - 1 && (
                            <span
                              onClick={() =>
                                removeAgencyTierAction(staffGroup.id, tier.id, index)
                              }
                            >
                              x
                            </span>
                          )}
                        </TierButton>
                      ))}
                      {staffGroup.agencyTiers.length < 40 && (
                        <TierButton
                          onClick={() =>
                            createAgencyTierAction(staffGroup.id, index)
                          }
                        >
                          <span>Add Tier</span>
                          <TierButtonIconAdd />
                        </TierButton>
                      )}
                    </TierButtonsContainer>
                    {error && error.message && error.index === index && (
                      <ErrorContainer>
                        <p>{error.message}</p>
                      </ErrorContainer>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponent>
        )}
      </TableContainer>
    </>
  );
};

export default AgencyTiers;

const TierButtonsContainer = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: repeat(auto-fill, 100px);

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    flex-flow: wrap;
  }
`;

const TierButton = styled.button`
  align-items: center;
  background: #ffffff !important;
  border: 1px solid #cbd2d9 !important;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08) !important;
  color: #1e2d36;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  height: 34px;
  justify-content: space-between;
  margin: 0 !important;
  padding-left: 12px !important;
  padding-right: 8px !important;

  &:hover {
    background: #f6f6f6;
    border-color: rgba(30, 45, 54, 0.25);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    margin-bottom: 8px !important;
    margin-right: 8px !important;

    svg {
      margin-left: 4px;
    }
  }
`;

const TierButtonIconAdd = () => (
  <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 8.667H8.667V11c0 .367-.3.667-.667.667A.669.669 0 017.333 11V8.667H5A.669.669 0 014.333 8c0-.367.3-.667.667-.667h2.333V5c0-.367.3-.667.667-.667.367 0 .667.3.667.667v2.333H11c.367 0 .667.3.667.667 0 .367-.3.667-.667.667zM8 0C3.583 0 0 3.583 0 8s3.583 8 8 8 8-3.583 8-8-3.583-8-8-8z"
      fill="#9CA8B4"
    />
  </svg>
);
