import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";

const SelectAll = ({ shiftsCount, receiptsCount }) => {
  const [selected, setSelected] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const selectAllFunction = () => {
    setSelected(false);
    const actionBarTarget = document.querySelector(".action-bar");
    const checkedShifts = document.querySelector(".list");
    const selectAllButton = document.querySelector(".action-bar__label");
    const duplicatesShifts = Array.from(
      checkedShifts.querySelectorAll(".custom-control-input:checked")
    ).map((item) => item.value);
    const totalShifts = Array.from(
      checkedShifts.querySelectorAll(".custom-control-input")
    );

    const shifts = duplicatesShifts.filter(function (elem, pos) {
      return duplicatesShifts.indexOf(elem) == pos;
    });

    if (shifts.length === totalShifts.length) {
      document.querySelector(".select-all-toggle").classList.remove("active");
      selectAllButton.innerText = "Select All";
      const selectedShifts = checkedShifts.querySelectorAll(
        ".custom-control-input:checked"
      );
      for (let index = 0; index < selectedShifts.length; index++) {
        selectedShifts[index].checked = false;
      }
      actionBarTarget.classList.remove("action-bar--toggle");
    } else {
      handleShow();
    }
  };

  return (
    <>
      <CheckboxButton
        onClick={selectAllFunction}
        className={selected ? "active select-all-toggle" : "select-all-toggle"}
      />
      <SelectAllModal
        handleClose={handleClose}
        setSelected={setSelected}
        shiftsCount={shiftsCount}
        receiptsCount={receiptsCount}
        show={show}
      />
      <Overlay className="select-all-overlay" />
    </>
  );
};

export default SelectAll;

const SelectAllModal = ({ handleClose, setSelected, shiftsCount, receiptsCount, show }) => {
  const actionBarTarget = document.querySelector(".action-bar");
  const shiftSelectedLabel = document.querySelector(".action-bar__label");
  const selectAllButton = document.querySelector(".action-bar__select");
  const paymentsSection = document.querySelector(
    ".action-bar__payments-section"
  );
  const flagForReview = document.querySelector(".action-bar__flag-for-review");

  const actionsShifts = document.querySelector(".action-bar__actionable-area");
  const actionsLast = document.querySelector(".action-bar__last-section");
  const selectAllOverlay = document.querySelector(".select-all-overlay");

  const allShifts = document.getElementById("all-shifts").value;
  const removeBrackets = allShifts.substring(1, allShifts.length - 1);
  const cleanedShifts = removeBrackets.split(", ");
  const shiftsOrReceiptsWord = shiftsCount > 0 ? 'shifts' : 'receipts'

  const selectAllShifts = () => {
    setSelected(true);
    document.getElementById("all-shifts-toggle").value = 1;

    const checkedShifts = document.querySelector(".list");
    const selectedShifts = checkedShifts.querySelectorAll(
      ".custom-control-input"
    );
    for (let index = 0; index < selectedShifts.length; index++) {
      selectedShifts[index].checked = true;
    }

    shiftSelectedLabel.innerHTML = `${cleanedShifts.length} of ${cleanedShifts.length}
                                    ${shiftsCount > 0 ? 'Shifts' : 'Receipts'} Selected`;
    selectAllButton.innerText = "Deselect All";

    if (actionsShifts) {
      actionsShifts.style.display = "none";
    }
    if (actionsLast) {
      actionsLast.style.display = "none";
    }
    // check if its in review section to show review actions
    if (
      window.location.pathname == "/payments/review" ||
      window.location.pathname == "/payment_collaborative_banks/review"
    ) {
      paymentsSection.style.display = "none";
      flagForReview.style.display = "block";
    } else {
      paymentsSection.style.display = "flex";
      flagForReview.style.display = "none";
    }
    selectAllOverlay.style.display = "block";

    actionBarTarget.classList.add("action-bar--toggle");
    handleClose();
  };

  const selectVisibleShifts = () => {
    setSelected(true);
    document.getElementById("all-shifts-toggle").value = 0;
    const checkedShifts = document.querySelector(".list");

    const selectedShifts = checkedShifts.querySelectorAll(
      ".custom-control-input"
    );
    for (let index = 0; index < selectedShifts.length; index++) {
      selectedShifts[index].checked = true;
    }

    const duplicatesShifts = Array.from(
      checkedShifts.querySelectorAll(".custom-control-input:checked")
    ).map((item) => item.value);

    const shifts = duplicatesShifts.filter(function (elem, pos) {
      return duplicatesShifts.indexOf(elem) == pos;
    });

    shiftSelectedLabel.innerHTML = `${shifts.length} of ${cleanedShifts.length}
                                    ${shiftsCount > 0 ? 'Shifts' : 'Receipts'} Selected`;
    selectAllButton.innerText = "Deselect All";

    if (actionsShifts) {
      actionsShifts.style.display = "none";
    }

    if (actionsLast) {
      actionsLast.style.display = "none";
    }
    // check if its in review section to show review actions
    if (
      window.location.pathname == "/payments/review" ||
      window.location.pathname == "/payment_collaborative_banks/review"
    ) {
      paymentsSection.style.display = "none";
      flagForReview.style.display = "block";
    } else {
      paymentsSection.style.display = "flex";
      flagForReview.style.display = "none";
    }

    selectAllOverlay.style.display = "block";

    actionBarTarget.classList.add("action-bar--toggle");
    handleClose();
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      size="lg"
      centered
    >
      <ModalHeader>
        <ModalTitle>Select amount of {shiftsCount > 0 ? 'Shifts' : 'Receipts'}</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ fontSize: 20, minHeight: 0 }}>
        You have chosen to select all {shiftsOrReceiptsWord}, please choose below how many
        total {shiftsOrReceiptsWord} you would like to select.
      </ModalBody>
      <ModalFooter>
        <div></div>
        <div>
          <button className="btn btn-close" onClick={() => selectAllShifts()}>
            Select All {shiftsCount > 0 ? 'Shifts' : 'Receipts'}
          </button>
          <button
            className="btn text-white btn-cyan--modal"
            onClick={() => selectVisibleShifts()}
          >
            Select {shiftsCount > 0 ? shiftsCount : receiptsCount} Visible
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const CheckboxButton = styled.button`
  background-color: #ffffff;
  border: 1px solid #cbd2d9;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  height: 1rem;
  width: 1rem;

  &.active {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='1.30859' y='0.627655' width='15' height='15' rx='3.5' fill='%2390D78F' stroke='%2377BF77'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.0233 6.355L8.38762 10.9906C8.23696 11.1413 8.03994 11.2186 7.84293 11.2186C7.64591 11.2186 7.4489 11.1413 7.29824 10.9906L4.59412 8.28652C4.28894 7.9852 4.28894 7.49846 4.59412 7.19714C4.89544 6.89196 5.38218 6.89196 5.68349 7.19714L7.84293 9.35272L11.9339 5.26563C12.2352 4.96045 12.7219 4.96045 13.0233 5.26563C13.3284 5.56694 13.3284 6.05369 13.0233 6.355Z' fill='white'/%3E%3C/svg%3E%0A");
    border: 0;
    box-shadow: none;
    height: 17px;
    width: 17px;
  }
`;

const Overlay = styled.div`
  background: rgba(30, 45, 54, 0.5);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1040;
`;
