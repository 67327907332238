import { Controller } from 'stimulus'

export default class TotalPayAfterEscalationController extends Controller {

    static get targets() {
        return ['escalateCoreRate', 'escalateCorePercentage', 'escalateUnsocialRate', 'escalateUnsocialPercentage', 'totalPayRate', 'percent']
    }

    connect() {
        this.element[this.identifier] = this
        this.initialTotalRate = (
            (this.data.get('core_hours') * this.data.get('core_total_rate'))
            + (this.data.get('unsocial_hours') * this.data.get('unsocial_total_rate'))
        )
        this.updateEvent = document.createEvent("CustomEvent")
        this.updateEvent.initCustomEvent("total-pay-after-escalation-updated", true, true, null)
        this.escalateCorePercentageTarget.style.display = "none"
        this.escalateUnsocialPercentageTarget.style.display = "none"
    }

    calculate() {
        const coreTotalRate = this.data.get('core_total_rate')
        const unsocialTotalRate = this.data.get('unsocial_total_rate')
        const escalateCoreRate = this.escalateCoreRateTarget.value
        const escalateUnsocialRate = this.escalateUnsocialRateTarget.value
        const requestRateEscalationBtn = document.querySelector('.btn-rate-esc')
        const coreRateValidation = document.querySelector('.modal__rate-escalation-card--coreRate')
        const unsocialRateValidation = document.querySelector('.modal__rate-escalation-card--unsocialRate')
        const coreHours = this.data.get('core_hours')
        const unsocialHours = this.data.get('unsocial_hours')
        const newCoreTotal = escalateCoreRate * coreHours
        const newUnsocialTotal = escalateUnsocialRate * unsocialHours

        if (escalateCoreRate <= 0) {
            coreRateValidation.style.display = "block";
            requestRateEscalationBtn.disabled = true;
        } else {
            coreRateValidation.style.display = "none";
            requestRateEscalationBtn.disabled = false;
        }

        if (escalateUnsocialRate <= 0) {
            unsocialRateValidation.style.display = "block";
            requestRateEscalationBtn.disabled = true;
        } else {
            unsocialRateValidation.style.display = "none";
            requestRateEscalationBtn.disabled = false;
        }

        let totalPayAfterEscalation = (newCoreTotal + newUnsocialTotal).toFixed(2)
        let percentIncreased = (((totalPayAfterEscalation / this.initialTotalRate) * 100) - 100).toFixed(2)

        this.totalPayRateTarget.innerText = '£' + totalPayAfterEscalation

        if (totalPayAfterEscalation) {
            this.percentTarget.innerText = percentIncreased + '%'
        } else {
            this.percentTarget.innerText = '...'
        }

        const escalatedCorePercentageIncreased = (((escalateCoreRate / coreTotalRate) * 100) - 100).toFixed(2)
        const escalatedUnsocialPercentageIncreased = (((escalateUnsocialRate / unsocialTotalRate) * 100) - 100).toFixed(2)

        if (escalatedCorePercentageIncreased > 0) {
            this.escalateCorePercentageTarget.innerText = '+' + escalatedCorePercentageIncreased + '%'
            this.escalateCorePercentageTarget.style.display = "block"
        } else {
            this.escalateCorePercentageTarget.style.display = "none"
        }

        if (escalatedUnsocialPercentageIncreased > 0) {
            this.escalateUnsocialPercentageTarget.innerText = '+' + escalatedUnsocialPercentageIncreased + '%'
            this.escalateUnsocialPercentageTarget.style.display = "block"
        } else {
            this.escalateUnsocialPercentageTarget.style.display = "none"
        }

        this.element.dispatchEvent(this.updateEvent)
    }
}

import { application } from '../../frontend/controllers/index'
application.register("total_pay_after_escalation", TotalPayAfterEscalationController)
