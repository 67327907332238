export async function createSite(body) {
  const url = `/sites`;

  const data = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function editSite(siteId, body) {
  const url = `/sites/${siteId}`;

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}

export async function deleteSite(siteId) {
  const url = `/sites/${siteId}`;

  const data = fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  });
  return data;
}
