/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
import { TableContainer, FormBreak, GLOBAL, GREY } from '@patchworkhealth/web-components'
import React from 'react'
import styled from 'styled-components'
import { ShiftModalTitle } from '../helpers/BlockComponents'

export default function WorkerRatesTable({ blockData }) {
  const rates = blockData?.agencyWorkerRates
  const user = blockData?.bookedBy?.user
  const employmentType = blockData?.bookedBy?.employmentType
  const paymentType = blockData?.bookedBy?.paymentType
  if (!blockData?.bookedBy || !rates.length) return null

  return (
    <>
      <FormBreak />
      <ShiftModalTitle>Rates</ShiftModalTitle>
      <div>
        <WorkerDetailsContainer>
          <WorkerDetails>
            <WorkerDetailsName>
              {user?.firstName} {user?.lastName}
            </WorkerDetailsName>
            <WorkerDetailsGrade color={user?.grade?.colour}>{blockData?.bookedBy?.grade?.title}</WorkerDetailsGrade>
            <WorkerDetailsEmail>{user?.email}</WorkerDetailsEmail>

            <div style={{ display: 'flex' }}>
              <EmploymentIcon>{employmentType === 'direct_engagement' ? 'DE' : 'NonDE'}</EmploymentIcon>
              <EmploymentIcon>
                {paymentType === 'paye' ? 'PAYE' : paymentType === 'umbrella_company' ? 'Umbrella' : 'Ltd'}
              </EmploymentIcon>
            </div>
          </WorkerDetails>
        </WorkerDetailsContainer>
      </div>
      <TableContainer>
        <TableHead>
          <th />
          {rates?.map((rate) => (
            <th style={{ textAlign: 'center', padding: '10px 0px' }}>{rate.name}</th>
          ))}
          <th style={{ padding: 0, width: 0 }} />
        </TableHead>
        <tbody>
          <tr>
            <TableTd
              style={{
                width: 190,
                padding: 8,
              }}
            >
              {employmentType === 'direct_engagement' && paymentType !== 'ltd' && (
                <>
                  <TableCell big right>
                    <strong> Comparator Rate:</strong>
                    <span> Vs rate card:</span>
                  </TableCell>
                  <TableCell right> Worker total rate:</TableCell>
                  <TableCell right> Employer NI:</TableCell>
                  <TableCell right>Agency Charge:</TableCell>
                </>
              )}

              {(employmentType === 'non_direct_engagement' ||
                (employmentType === 'direct_engagement' && paymentType === 'ltd')) && (
                <TableCell big right>
                  <strong style={{ whiteSpace: 'nowrap' }}> Charge Rate:</strong>
                  <span> Vs rate card:</span>
                  <span>Agency Margin Included:</span>
                </TableCell>
              )}
            </TableTd>

            {rates?.map((rate, index) => {
              const comparatorDiff = rate.comparatorRate - rate.initialRates.comparatorRate

              return employmentType === 'direct_engagement' && paymentType !== 'ltd' ? (
                <TableTd index={index}>
                  <TableCell big>
                    <span>
                      <strong>£{rate.comparatorRate.toFixed(2)}</strong>
                    </span>
                    {!Number.isNaN(comparatorDiff) && comparatorDiff !== 0 ? (
                      <EsculationCard>+£{comparatorDiff.toFixed(2)}</EsculationCard>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <span>£{rate.workerRate.toFixed(2)}</span>
                  </TableCell>
                  <TableCell>
                    <span>£{rate.niContribution}</span>
                  </TableCell>
                  <TableCell>
                    <span>£{rate.agencyMargin.toFixed(2)}</span>
                  </TableCell>
                </TableTd>
              ) : (
                <TableTd index={index}>
                  <TableCell between big>
                    <span>
                      <strong>£{rate.comparatorRate.toFixed(2)}</strong>
                    </span>
                    {!Number.isNaN(comparatorDiff) && comparatorDiff !== 0 ? (
                      <EsculationCard>+£{comparatorDiff.toFixed(2)}</EsculationCard>
                    ) : null}
                    <span style={{ fontSize: 13 }}>£{rate.agencyMargin.toFixed(2)}</span>
                  </TableCell>
                </TableTd>
              )
            })}
          </tr>
        </tbody>
      </TableContainer>
    </>
  )
}

const TableHead = styled.thead`
  background: red !important;
`

const TableCell = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ big }) => (big ? '60px' : '26px')};
  align-items: ${({ right }) => (right ? 'flex-start' : 'center')};
  justify-content: ${({ between }) => (between ? 'space-between' : 'flex-start')};
`

const TableTd = styled.td`
  width: 100px;
  background: ${({ index }) => index % 2 === 0 && '#F5F7FA'};
  padding: 5px;
`

const EsculationCard = styled.div`
  background: #fae4e4;
  border-radius: 4px;
  height: 20px;
  color: #bd2d2e;
  font-size: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px;
`
export const WorkerContainer = styled.div`
  align-items: center;
  border: 1px solid red;
  background-color: ${GLOBAL.backgroundColor};
  border-radius: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`

export const WorkerDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-align: center;
  margin-bottom: 30px;
`

export const WorkerDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const WorkerDetailsName = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: underline;
  cursor: pointer;
`

export const WorkerDetailsGrade = styled(WorkerDetailsName)`
  color: ${({ color }) => color || GLOBAL.fontDark};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: none;
`

export const WorkerDetailsEmail = styled(WorkerDetailsGrade)`
  color: ${GREY.six};
  font-weight: 400;
  margin: 0;
`
export const EmploymentIcon = styled.div`
  align-items: center;
  background-color: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  padding: 0 8px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 10px;
`
