import React from 'react'
import Pagination from 'react-bootstrap/Pagination'

function Pagy({ pagination, setPage }) {
  const items = []
  for (let number = 1; number <= pagination.last; number += 1) {
    items.push(
      <Pagination.Item key={number} active={number === pagination.page} onClick={() => setPage(number)}>
        {number}
      </Pagination.Item>
    )
  }

  return (
    <Pagination style={{ marginTop: 30 }}>
      <Pagination.Prev disabled={!pagination.prev} onClick={() => setPage(pagination.prev)}>
        ‹&nbsp;Prev
      </Pagination.Prev>
      {items}
      <Pagination.Next disabled={!pagination.next} onClick={() => setPage(pagination.next)}>
        Next&nbsp;›
      </Pagination.Next>
    </Pagination>
  )
}

export default Pagy
