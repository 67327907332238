export async function createReason(body, callType) {
  const url = `/${callType}`

  const data = fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}

export async function editReason(reasonId, body, callType) {
  const url = `/${callType}/${reasonId}`

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}


export async function deleteReason(reasonId, callType) {
  const url = `/${callType}/${reasonId}`

  const data = fetch(url, {
    method: "DELETE",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}