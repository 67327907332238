import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class PaymentTypeSelectorController extends SearchController {

    static get targets() {
        return ['select']
    }
    connect() {
        this.element[this.identifier] = this
        window.qs = queryString
        this.queryString = queryString.parse(location.search)
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search_on_select');

        this.select = new SlimSelect({
            select: target,
            placeholder: "Select shift type",
            onChange: (info) => {
                this.storeFilters('shift_type', [info])
                if (searchOnSelect) {
                    this.singleSearchOnSelect('shift_type', info)
                }
            }
        })

        if (this.data.get('initial_value')) {
            this.select.set(this.data.get('initial_value'))
        }
    }

}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("payment_type_selector", PaymentTypeSelectorController)