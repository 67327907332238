import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class ShiftTypeSelectorController extends SearchController {

    static get targets() {
        return ['select']
    }
    connect() {
        this.element[this.identifier] = this
        window.qs = queryString
        this.queryString = queryString.parse(location.search)
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search_on_select');
        const closeOnSelect = this.data.get('keep-open') ? false : true

        this.select = new SlimSelect({
            select: target,
            placeholder: "Select shift type",
            closeOnSelect: closeOnSelect,
            onChange: (info) => {
                this.storeFilters('types[]', info)
                if (searchOnSelect) {
                    this.multiSearchOnSelect('types[]', info)
                }
            }
        })

        if (this.data.get('initial_value')) {
            this.select.set(this.data.get('initial_value'))
        }
    }

}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("shift_type_selector", ShiftTypeSelectorController)