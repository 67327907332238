/* eslint-disable no-nested-ternary */
/* eslint-disable no-alert */
import { ApolloProvider, useMutation } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { ExportDocsIcon } from '../agency_proposals/proposalIcons'
import createApolloClient from '../createApolloClient'
import { AGENCY_WORKER_DOCS_EXPORT_SHIFT_AND_BLOCK_COMPLIANCE } from './graphql'

document.addEventListener('DOMContentLoaded', () => {
  const client = createApolloClient()
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const observer = new MutationObserver(() => {
    const rootElement = document.querySelector('[data-react-component="compliance_docs"]')
    const agencyRegistrationId = rootElement?.getAttribute('agencyRegistrationId')
    const bookableType = rootElement?.getAttribute('bookableType')
    const bookableId = rootElement?.getAttribute('bookableId')
    const bookedDate = rootElement?.getAttribute('bookedDate')

    if (rootElement && rootElement.childElementCount === 0) {
      ReactDOM.render(
        <>
          <ToastContainer position="top-center" transition={Slide} />
          <ApolloProvider client={client}>
            <ComplianceDocs
              agencyRegistrationId={agencyRegistrationId}
              bookableId={bookableId}
              bookableType={bookableType}
              bookedDate={bookedDate}
            />
          </ApolloProvider>
        </>,
        rootElement
      )
    }
  })
  observer.observe(document, config)
})

function ComplianceDocs({ agencyRegistrationId, bookableType, bookableId, bookedDate }) {
  const [complianceDocs] = useMutation(AGENCY_WORKER_DOCS_EXPORT_SHIFT_AND_BLOCK_COMPLIANCE)

  const handleExportDocs = async () => {
    try {
      const { data: result } = await complianceDocs({
        variables: {
          agencyRegistrationId: Number(agencyRegistrationId),
          objectType: bookableType,
          objectId: Number(bookableId),
        },
      })

      const errors = result?.agencyWorkerDocsExportShiftAndBlockCompliance?.errors
      if (errors.length > 0) {
        window.alert(`${errors[0].message}`)
        return
      }

      if (result?.agencyWorkerDocsExportShiftAndBlockCompliance?.success) {
        const downloadLink = `data:application/zip;base64,${result?.agencyWorkerDocsExportShiftAndBlockCompliance?.zipString}`
        const link = document.createElement('a')
        link.href = downloadLink
        link.download = 'documents.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        alert('Error exporting documents. Please try again.')
      }
    } catch (err) {
      window.alert('There was an Error!')
    }
  }

  return (
    <div
      style={{
        borderBottom: '3px solid rgba(203, 210, 217, 0.3)',
        marginBottom: 35,
        paddingBottom: 20,
      }}
    >
      <button
        onClick={() => handleExportDocs()}
        style={{
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          marginTop: -21,
          marginLeft: -10,
        }}
        type="button"
      >
        <ExportDocsIcon />
      </button>

      <p
        style={{
          color: '#666666',
          fontSize: 10,
          marginTop: 6,
        }}
      >
        Date Booked: {bookedDate}{' '}
      </p>
    </div>
  )
}
