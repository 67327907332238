import Flatpickr from "stimulus-flatpickr";
import "flatpickr/dist/flatpickr.min.css";

class TimeInputController extends Flatpickr {

    connect() {
        const timeValue = this.data.get("value")
        this.config = {
            ...this.config, //spread options in case some where defined in initialize
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true,
            defaultDate: timeValue
        };
            
    super.connect();
    }

}
import { application } from '../../frontend/controllers/index'
application.register("time_input", TimeInputController);
