export default class ShiftIdInput extends SearchController {

  static get targets() {
    return ['input', 'payment']
  }

  form() {
    event.preventDefault()
    if (this.inputTarget.value.length > 0) {
      const shiftIds = this.inputTarget.value.split(',')
      const url = "shift_ids[]=" + shiftIds.join("&shift_ids[]=")
      if (this.paymentTarget.value == 'true') {
        let host = window.location.origin;
        let path = window.location.pathname;
        let searchParams = new URLSearchParams(window.location.search);
        searchParams.delete('page');  
        window.location.href = `${host}${path}?${searchParams.toString()}&${url}`
      } else {
        window.location.href = `shifts?${url}`
      }
    }
  }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("shift_id_input", ShiftIdInput)
