import { Controller } from "stimulus";

export default class ApplicationColumns extends Controller {
  static get targets() {
    return ["column", "content", "error", "menu", "overlay"];
  }

  connect() {
    this.isOpen = false;
  }

  toggle(event) {
    this.menuTarget.classList.toggle("show");
    this.isOpen = !this.isOpen;
    event.stopPropagation();
  }

  hide() {
    if (this.isOpen) {
      this.menuTarget.classList.remove("show");
      this.isOpen = false;
    }
  }

  dragstart(event) {
    event.dataTransfer.setData('text', event.target.getAttribute('data-worker-id'));
    event.dataTransfer.effectAllowed = "move";
  }

  dragover(event) {
    event.preventDefault();
    return true;
  }

  dragenter(event) {
    event.preventDefault();
    // set the drop target
    const dropTarget = event.target.id;
    // close all open empty placeholders
    const columns = document.querySelectorAll(".applications-table-column")
    columns.forEach(function (column) {
      column.classList.remove("show")
    })
    // open empty placeholders in drop column
    let columnSelector = document.querySelector("#applications-table-column-" + dropTarget)
    if (columnSelector) {
      columnSelector.classList.add("show")
    }
    // when the drag is entered disable drag on other cards
    const cards = document.querySelectorAll('.applications-table-item');
    cards.forEach(function (card) {
      card.setAttribute('draggable', false);
    })
  }

  drop(event) {
    // set the drop target
    const dropTarget = event.target.id;
    // close all open empty placeholders
    const columns = document.querySelectorAll(".applications-table-column")
    columns.forEach(function (column) {
      column.classList.remove("show")
    })
    // get the dragged item (card)
    let data = event.dataTransfer.getData("text");
    const draggedItem = this.element.querySelector(`[data-worker-id='${data}']`);

    const query = `mutation ChangeStatus($worker_id: Int!, $organisation_id: Int!, $start: String!, $finish: String!){
      changeStatus(workerId: $worker_id, organisationId: $organisation_id, start: $start, finish: $finish){
        organisationRegistration {
          id
        }
        errors {
          attribute
          message
        }
      }
    }`;

    // grab details of the card to pass through the mutation
    const organisation_id = parseInt(draggedItem.dataset.orgId);
    const worker_id = parseInt(draggedItem.dataset.workerId);
    const start = draggedItem.dataset.columnId;
    const finish = dropTarget;

    const card = document.getElementById("card_" + worker_id.toString());
    const column = document.getElementById(finish);

    if (start !== finish) {
      // fetch call
      fetch("/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          query,
          variables: { worker_id, organisation_id, start, finish },
        }),
      }).then((response) => {
        this.overlayTarget.classList.add("show");
        if (response.status != 200) {
          console.log("error");
        } else if (response.status == 200) {
          // move card to correct column
          if (column) {
            column.appendChild(card);
          }
          location.reload();
        }
      }).catch((err) => {
        this.overlayTarget.classList.remove("show");
        this.errorTarget.classList.add("show");
        location.reload();
      });
    }

    // make cards draggable again
    const cards = document.querySelectorAll('.applications-table-item');
    cards.forEach(function (card) {
      card.setAttribute('draggable', true);
    })

    event.preventDefault();
  }

  // move applicant internal function
  moveApplicant() {
    const query = `mutation moveToInternal($worker_id: Int!, $organisation_id: Int!){
      moveToInternal(workerId: $worker_id, organisationId: $organisation_id)
    }`;

    const organisation_id = parseInt(this.data.get("organisation_id"));
    const worker_id = parseInt(this.data.get("worker_id"));

    // fetch call
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { worker_id, organisation_id },
      }),
    })
      .then((response) => {
        if (response.status != 200) {
          console.log("error");
        } else if (response.status == 200) {
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      });
  }
  // reject applicant function
  rejectApplicant() {
    const query = `mutation RejectApplication($worker_id: Int!, $organisation_id: Int!){
      rejectApplication(workerId: $worker_id, organisationId: $organisation_id)
    }`;

    const organisation_id = parseInt(this.data.get("organisation_id"));
    const worker_id = parseInt(this.data.get("worker_id"));

    // fetch call
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { worker_id, organisation_id },
      }),
    })
      .then((response) => {
        if (response.status != 200) {
          console.log("error");
        } else if (response.status == 200) {
          window.location.reload(true);
        }
      })
      .catch((err) => {
        console.log("Fetch Error", err);
      });
  }

  // manual move
  moveItem() {
    const query = `mutation ChangeStatus($worker_id: Int!, $organisation_id: Int!, $start: String!, $finish: String!){
      changeStatus(workerId: $worker_id, organisationId: $organisation_id, start: $start, finish: $finish){
        organisationRegistration {
          id
        }
        errors {
          attribute
          message
        }
      }
    }`;
    // grab details of the card to pass through the mutation
    const organisation_id = parseInt(this.data.get("organisation_id"));
    const worker_id = parseInt(this.data.get("worker_id"));
    const start = this.data.get("start_pos");
    const finish = this.data.get("end_pos");
    // grab the details for the card/column for the move function
    const card = document.getElementById("card_" + worker_id.toString());
    const column = document.getElementById(finish.toString());
    // fetch call
    fetch("/graphql", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        query,
        variables: { worker_id, organisation_id, start, finish },
      }),
    }).then((response) => {
        const overlay = document.querySelector('.applications-table-column-overlay')
        const error = document.querySelector('.applications-table-column-error')
        overlay.classList.add("show");
        if (response.status != 200) {
        } else if (response.status == 200) {
          // move card to correct column
          column.appendChild(card);
          // reload page
          window.location.reload(true);
        }
      }).catch((err) => {
        overlay.classList.remove("show");
        error.classList.add("show");
        location.reload();
      });
  }


}

import { application } from "../../frontend/controllers/index";
application.register("application_columns", ApplicationColumns);
