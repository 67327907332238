import SlimSelect from 'slim-select'

export default class ReasonForCancellingController extends SearchController {
    static get targets() {
        return ['select']
    }

    connect() {
        const target = this.selectTarget;
        new SlimSelect({
            select: target,
            placeholder: 'Select reason'
        })
    }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";

application.register("reason_for_cancelling_controller", ReasonForCancellingController)