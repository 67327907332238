import { Controller } from 'stimulus'
import consumer from "../../frontend/channels/consumer"

export default class BatchDownloadButtonController extends Controller {

  static get targets() {
    return ['waitMessage', 'downloadButton', 'downloadLink'];
  }

  connect () {
    this.forReceipts = this.data.get('for_receipts') == 'true';
    this.readyForDownload = this.data.get('ready_for_download') == 'true';
    this.showProperDiv('connect');
  }

  showProperDiv(stage = 'update') {
    if( this.readyForDownload ) {
      this.waitMessageTarget.classList.add('d-none');
      this.downloadButtonTarget.classList.remove('d-none');
    } else { // still waiting for batch readiness
      this.waitMessageTarget.classList.remove('d-none');
      this.downloadButtonTarget.classList.add('d-none');
      if(stage == 'connect') {
        // establish channel connection
        let controllerThis = this;
        this.consumer = consumer.subscriptions.create(
          {
            channel: this.forReceipts ? 'PaidPerItem::BatchesChannel' : 'BatchesChannel',
            batch_id: this.data.get('batch_id')
          },
          {
            connected() {
              console.log('Connected to channel')
              // schedule ajax request to ensure that
              //   we did not missed notification which could be sent
              //   between the moment when view renderered
              //   and the moment when channel created
              setTimeout(function() { controllerThis.refreshViaRest() }, 500)
            },

            disconnected() {
              console.log('Disconnected from channel')
              // Called when the subscription has been terminated by the server
            },

            received(data) {
              console.log('Got data from channel')
              // Called when there's incoming data on the websocket for this channel
              controllerThis.updateWithData(data);
            }
          }
        );

      }
    }
  }

  refreshViaRest() {
    const urlPrefix = this.forReceipts ? '/ppi_batches/' : '/batches/';
    const url = urlPrefix + this.data.get('batch_id') + '.json';
    fetch(url)
      .then(
        response => {
          if (response.status !== 200) {
            return;
          }

          response.text().then(data => {
            this.updateWithData({ batch: JSON.parse(data) } )
          });
        }
      )
      .catch(err => {
        console.log('Fetch Error', err);
      });
  }

  updateWithData(data) {
    console.log(data)
    url = data.batch.zip_url || data.batch.csv_url;
    if( url != null) {
      this.downloadLinkTarget.href = url;
      this.readyForDownload = true;
    }
    this.showProperDiv();
  }
}

import { application } from '../../frontend/controllers/index'
application.register("batch_download_button", BatchDownloadButtonController)
