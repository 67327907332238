import { useCallback, useState, useEffect } from 'react'

const useShiftSelector = () => {
  // An unfiltered list of all the Shift IDs that have been selected
  const [shiftIds, setShiftIds] = useState([])
  const [activeWeek, setActiveWeek] = useState(Array(7).fill(false))
  const [actionBarVisible, setActionBarVisible] = useState(false)

  useEffect(() => {
    if (shiftIds.length > 0) {
      setActionBarVisible(true)
    } else {
      setActionBarVisible(false)
    }
  }, [shiftIds])

  // Determine if a Shift has been selected. Returns Boolean
  const isShiftSelected = useCallback((shiftId) => {
    return shiftIds.includes(shiftId)
  })

  // Add a Shift's ID to shiftIds, using Array.push
  const selectShift = useCallback(
    (shiftId) => {
      setShiftIds((currentShiftIds) => [...currentShiftIds, shiftId])
    },
    [shiftIds]
  )

  // Remove a Shift's ID from shiftIds, using Array.splice
  const deselectShift = useCallback(
    (shiftId) => {
      setShiftIds(shiftIds.filter((id) => id !== shiftId))
    },
    [shiftIds]
  )

  // Remove duplicates from shiftIds, using Array.filter, and returning a new array
  const filterShiftIds = useCallback(() => {
    return shiftIds.filter((shiftId, index) => shiftIds.indexOf(shiftId) === index)
  })

  // Selects or Deselects a Shift ID based on its presence in shiftIds.
  // If it is not present in the array, it is selected.
  // If it is present in the array, it is deselected.
  const toggleShiftSelection = useCallback((shiftId) => {
    if (isShiftSelected(shiftId)) {
      deselectShift(shiftId)
      return
    }
    selectShift(shiftId)
  })

  // Adds a passed array of values to shiftIds
  const selectAllShifts = useCallback((allShiftIds) => {
    setShiftIds(allShiftIds)
  })

  // Resets shiftIds to an empty array
  const deselectAllShifts = useCallback(() => {
    setShiftIds([])
    setActionBarVisible(false)
    setActiveWeek(Array(7).fill(false))
  })

  const toggleActionBar = useCallback((param) => {
    setActionBarVisible(param)
    setActiveWeek(Array(7).fill(false))
  })

  const selectWeeklyShifts = useCallback((allShiftIds) => {
    const newShiftIds = shiftIds.slice()

    allShiftIds.forEach((id) => {
      if (shiftIds.includes(id)) {
        newShiftIds.splice(newShiftIds.indexOf(id), 1)
      } else {
        newShiftIds.push(id)
      }
    })

    setShiftIds(newShiftIds)
  })

  return {
    deselectAllShifts,
    selectAllShifts,
    get selectedShiftId() {
      if (shiftIds.length === 1) {
        return shiftIds[0]
      }

      return null
    },
    get selectedShifts() {
      return filterShiftIds()
    },
    isShiftSelected,
    toggleShiftSelection,
    //
    toggleActionBar,
    get actionBarVisible() {
      return actionBarVisible
    },
    selectWeeklyShifts,
    activeWeek,
    setActiveWeek,
  }
}

export default useShiftSelector
