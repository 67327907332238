import { Controller } from "stimulus";

export default class ApplicationListController extends Controller {
  static get targets() {
    return ["assignButton", "authToken", "authoriserPosition", "shiftId"];
  }

  connect() {}

  rejectOverlay(e) {
    e.preventDefault();
    // get applicant id
    let rejectButton = e.srcElement;
    let value = rejectButton.dataset.value;

    // close all rejection overlays when a reject button is pressed
    const rejectOverlays = Array.from(
      document.querySelectorAll(".assign-a-worker__overlay")
    );
    rejectOverlays.forEach(function (rejectOverlay) {
      rejectOverlay.style.display = "none";
    });

    // set low opacity and no pointer-events on applicant boxes
    const applicantBoxes = Array.from(
      document.querySelectorAll(".assign-a-worker")
    );
    applicantBoxes.forEach(function (applicantBox) {
      applicantBox.style.opacity = "0.2";
      applicantBox.style.pointerEvents = "none";
    });

    // set high opacity and pointer-events on selected applicant box
    document.querySelector(".assign-a-worker-" + value).style.opacity = "1";
    document.querySelector(".assign-a-worker-" + value).style.pointerEvents =
      "all";

    //  open overlay
    document.querySelector(".assign-a-worker__overlay-" + value).style.display =
      "flex";
  }

  closeRejectOverlay(e) {
    e.preventDefault();
    // set opacity back on applicant boxes
    const applicantBoxes = Array.from(
      document.querySelectorAll(".assign-a-worker")
    );
    applicantBoxes.forEach(function (applicantBox) {
      applicantBox.style.opacity = "1";
      applicantBox.style.pointerEvents = "all";
    });
    // set pointer-events back on applicant boxes
    const rejectOverlays = Array.from(
      document.querySelectorAll(".assign-a-worker__overlay")
    );
    rejectOverlays.forEach(function (rejectOverlay) {
      rejectOverlay.style.display = "none";
    });
  }

  rejectApplicant(e) {
    e.preventDefault();
    let rejectButton = e.srcElement;
    let value = rejectButton.dataset.value;

    const shiftId = this.shiftIdTarget.value;

    const url = `/shifts/${shiftId}/reject_application?shift[worker_id]=${value}`;

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        // Removes an element from the document
        var element = document.querySelector(".assign-a-worker-" + value);
        element.parentNode.removeChild(element);
        const applicantBoxes = Array.from(
          document.querySelectorAll(".assign-a-worker")
        );
        applicantBoxes.forEach(function (applicantBox) {
          applicantBox.style.opacity = "1";
          applicantBox.style.pointerEvents = "all";
        });
        // set pointer-events back on applicant boxes
        const rejectOverlays = Array.from(
          document.querySelectorAll(".assign-a-worker__overlay")
        );
        rejectOverlays.forEach(function (rejectOverlay) {
          rejectOverlay.style.display = "none";
        });
      }
    });
  }

  assignApplicant(e) {
    e.preventDefault();

    let selectButton = e.srcElement;
    const value = selectButton.dataset.value;

    if (value == "") {
      console.log("Please select a worker...");
      return;
    }

    const shiftId = document.querySelector("#assign-worker-shift-id").value;
    const safetyCheckEnabled = document.querySelector("#safety-check-enabled-result").value;


    let assignWorker = document.createElement("form");
    let applicationForm = document.getElementById("applications-form");
    assignWorker.method = "post";
    if (shiftId) {
      assignWorker.setAttribute(
        "action",
        `/shifts/${shiftId}/assign_worker?shift[worker_id]=${value}&shift[safety_compliant]=${true}`
      );
      assignWorker.innerHTML +=
        '<input name="_method" type="hidden" value="patch" />';
    }
    assignWorker.style.display = "hidden";
    assignWorker.appendChild(this.authTokenTarget.cloneNode());
    document.body.appendChild(assignWorker);

    console.log("ShiftIds:", shiftId);

    const selectBtns = Array.from(document.querySelectorAll(".applicant-btn"));
    selectBtns.forEach(function (selectBox, i) {
      if (selectBox.classList.contains("innertext--toggle")) {
        selectBox.innerText = "Select";
        selectBox.classList.remove("innertext--toggle");
      }
    });

    let authoriserValue = selectButton.dataset.value;
    this.authoriserPositionTarget.value = authoriserValue;

    if (
      selectButton.innerText == "Select" ||
      selectButton.innerText == " Select "
    ) {
      selectButton.innerText = "Selected";
      selectButton.classList.add("innertext--toggle");
    }

    if(safetyCheckEnabled === 'true'){

      let safetyApiData = {
        shift_id: shiftId,
        worker_ids: [value],
      };

      const safetyUrl =
      // Production
      window.location.hostname.match("api.patchwork.health")
        ? "https://safetychecks.patchwork.health/v1/worker_status"
        : // Api-test
        window.location.hostname.match("api-test.patchwork.health")
        ? "https://staging-safetychecks.patchwork.health/v1/worker_status"
        : //Local host
          "//" + window.location.hostname + ":8888/v1/worker_status";
      fetch(safetyUrl, {
        headers: {
          "Content-Type": "application/json",
          "Sec-Fetch-Mode": "no-cors",
        },
        method: "POST",
        body: JSON.stringify(safetyApiData),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data);

          if (!data[0].safe) {
            // If unsafe re-direct the user to new screen and inform them that it breaches trust's max hours
            document.getElementById("application__safety__ul").style.display =
              "none";
            document.getElementById("application__safety__p").style.display =
              "none";

            document.getElementById("application__safety__hidden").style.display =
              "block";

            assignWorker.setAttribute(
              "action",
              `/shifts/${shiftId}/assign_worker?shift[worker_id]=${value}&shift[safety_compliant]=${false}`
            );
            applicationForm.innerHTML +=
              '<input type="hidden" name="shift[safety_compliant]" data-target="application_list.safetyCompliant" value="false">';
          }
        })
        .catch((err) => {
          alert(
            "There was an Error when checking safety checks compatibility:",
            err
          );
        });
    }
  }
}

import { application } from "../../frontend/controllers/index";
application.register("application_list", ApplicationListController);
