import { Controller } from 'stimulus'

export default class CheckboxToggleController extends Controller {

    static get targets() {
        return ['checkbox']
    }

    connect() {}

    toggle(e) {
        e.preventDefault();
        this.checkboxTarget.checked = !this.checkboxTarget.checked
    }

    selectAll(e) {
        e.preventDefault();
        const exportModal = document.querySelector('.export-content');
        const checkedArray = Array.from(exportModal.getElementsByTagName('input'))
        .map(item => item.checked = true)
    }


    deselectAll (e) {
        e.preventDefault();
        const exportModal = document.querySelector('.export-content');
        const checkedArray = Array.from(exportModal.getElementsByTagName('input'))
        .map(item => item.checked = false)
    }
}

import { application } from '../../frontend/controllers/index'

application.register("checkbox_toggle", CheckboxToggleController)