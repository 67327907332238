export async function uploadCsv(formData) {
  const url = `/cost_centres/upload`;

  const data = fetch(url, {
    method: "POST",
    body: formData,
  }).then((response) => {
    if (response.status !== 204) return "err";
    else return 'success';
  });
  return data;
}
