import { Controller } from 'stimulus'
import Ajax from '../../frontend/packs/ajax'

export default class ShiftEditBreaksController extends Controller {

  static get targets() {
    return ['startButton', 'editGroup', 'editField', 'saveButton',
            'cancelButton', 'errors']
  }

  connect () {
    this.fieldset = document.getElementById("fieldset_details_readonly");
    this.ajax = new Ajax();
    this.shiftId = this.data.get('shift_id');
    this.lockedField = document.getElementsByName('shift_breaks_field')[0];
  }

  startEdit() {
    this.startButtonTarget.classList.add('d-none');
    this.editGroupTarget.classList.remove('d-none');
    this.enableFieldset();
    this.enableEditButtons();
  }

  cancelEdit() {
    this.hideErrors();
    this.disableFieldset();
    this.closeGroup();
  }

  saveEdit() {
    this.hideErrors();
    this.disableFieldset();
    this.disableEditButtons();
    this.ajax.post('/shifts/'+this.shiftId+'/update_breaks', {
      breaks: this.editFieldTarget.value
    }, (data) => {
      this.processResponse(data);
    });
  }

  processResponse(data) {
    try {
      data = JSON.parse(data);
    } catch (err) {
      console.log(data);
      alert('Server error!');
      this.closeGroup();
      return;
    }
    if (data.success) {
      this.editFieldTarget.value = data.shift.breaks;
      document.getElementsByName('shift_breaks_field')[0].value = data.shift.breaks;
      this.closeGroup();
    } else {
      this.showErrors(data.errors);
      this.enableEditButtons();
      this.enableFieldset();
    }
  }

  disableEditButtons() {
    this.saveButtonTarget.classList.add('d-none');
    this.cancelButtonTarget.classList.add('d-none');
  }

  enableEditButtons() {
    this.saveButtonTarget.classList.remove('d-none');
    this.cancelButtonTarget.classList.remove('d-none');
  }

  closeGroup() {
    this.editGroupTarget.classList.add('d-none');
    this.startButtonTarget.classList.remove('d-none');
  }

  enableFieldset() {
    this.fieldset.removeAttribute('disabled');
  }

  disableFieldset() {
    this.fieldset.setAttribute('disabled', 'true');
  }

  showErrors(errors) {
    this.errorsTarget.classList.remove('d-none');
    this.errorsTarget.innerHTML = errors.join('<br>');
  }

  hideErrors() {
    this.errorsTarget.classList.add('d-none');
    this.errorsTarget.innerHTML = '';
  }
}

import { application } from '../../frontend/controllers/index'
application.register("shift_edit_breaks", ShiftEditBreaksController)
