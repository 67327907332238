import SlimSelect from 'slim-select'

export default class OrgTypeSelectController extends SearchController {
    static get targets() {
        return ['select']
    }
    connect() {
        const target = this.selectTarget;
        const closeOnSelect = this.data.get('keep-open') ? false : true

        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select organisation type',
            closeOnSelect: closeOnSelect,
        });
        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }
    }
}

import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";
application.register("org_type_select",  OrgTypeSelectController);
