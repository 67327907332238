/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable one-var */
/* eslint-disable prettier/prettier */
/* eslint-disable no-var */
/* eslint-disable sort-vars */
/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable import/order */
import React, { useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import moment from 'moment'
import styled from 'styled-components'

import ShiftsCalenderModal from './ShiftsCalendarModal'
import { argumentsObjectFromField } from '@apollo/client/utilities'

const ShiftsCalendarView = ({
  events,
  fetchShifts,
  calendarLoading,
  setCalendarLoading,

  usesMspShortlisting,
  canShortlistProposals,
}) => {
  const calendarComponentRef = React.createRef()
  const [calendarTab, setCalendarTab] = useState(undefined)
  const [date, setDate] = useState(undefined)
  const [dates, setDates] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [modalDates, setModalDates] = useState(undefined)
  const [shifts, setShifts] = useState(undefined)
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (modalDates && fetchShifts) {
      fetchSingleDayShifts(modalDates.start, modalDates.end)
    }
  }, [fetchShifts])

  const handleClose = () => setShow(false)

  const eventClick = (arg) => {
    setShifts(undefined)
    setDate(moment(arg.event.start).format('dddd, Do MMMM YYYY'))
    setLoading(true)
    setCalendarTab(arg.event.extendedProps.status)
    setShow(true)
    fetchSingleDayShifts(arg.event.start, arg.event.start)
    setModalDates({ start: arg.event.start, end: arg.event.start })
  }

  const handleDates = (rangeInfo) => {
    setCalendarLoading(true)
    fetchShifts(rangeInfo.start, rangeInfo.end)
    setDates({ start: rangeInfo.start, end: rangeInfo.end })
  }

  const fetchSingleDayShifts = (start, end) => {
    const from_start_time = start
    const to_start_time = end

    const query = `
      query Shifts($from_start_time: DateTime!, $to_start_time: DateTime!){
        shifts(fromStartTime: $from_start_time, toStartTime: $to_start_time, limitToScope: NO_SCOPE, types: [HOURLY, SESSIONAL]){
          agencyRateSummary
          appliedBy {
            id
          }
          agencyWorkerProposals {
            nodes {
              id
              shortlistedAt
            }
          }
          availableActions
          bookedBy {
            id
            firstName
            lastName
          }
           agencyBooking {       
            agencyWorker {
              firstName
              lastName
              id
            }
          }
          department {
            id
            name
          }
          endTime
          escalatedBy
          grade {
            id
            title
          }
          id
          rateRequest {
            id
          }
          site {
            id
            name
          }
          startTime
          status
          totalRateSummary
          watchingHubUsers {
            id
          }
        }
      }
    `

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { from_start_time, to_start_time },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        setShifts(data.data.shifts)
        setLoading(false)
      })
  }

  return (
    <Container>
      {calendarLoading && (
        <LoadingBg>
          <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingBg>
      )}
      <BetaLabel>Beta</BetaLabel>
      <FullCalendar
        ref={calendarComponentRef}
        defaultView="dayGridMonth"
        events={events}
        headerToolbar={{
          left: 'prev',
          center: 'title',
          right: 'next',
        }}
        datesSet={handleDates}
        eventContent={(arg) => (
          <>
            <span className="count">{arg.event.extendedProps.count}</span>
            <>{arg.event.extendedProps.status}</>
            <div className="dash" />
          </>
        )}
        eventClick={(arg) => eventClick(arg)}
        plugins={[dayGridPlugin]}
      />
      {show && (
        <ShiftsCalenderModal
          calendarTab={calendarTab}
          date={date}
          fetchShifts={fetchShifts}
          handleClose={handleClose}
          loading={loading}
          setCalendarTab={setCalendarTab}
          shifts={shifts}
          show={show}
          usesMspShortlisting={usesMspShortlisting}
          canShortlistProposals={canShortlistProposals}
        />
      )}
    </Container>
  )
}

export default ShiftsCalendarView

const BetaLabel = styled.span`
  background: #fff;
  border-radius: 4px;
  box-shadow: rgba(255, 108, 108, 0.25) 0px 1px 3px 0px;
  color: #ff6c6c;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 4px 10px;
  position: absolute;
  right: 0;
  text-transform: uppercase;
  top: 0;
  z-index: 1;
`

const LoadingBg = styled.div`
  align-items: center;
  background: rgba(18, 38, 63, 0.5);
  border-radius: 6px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 5;
`

const Container = styled.div`
  margin: 0 auto 25px;
  margin-top: -75px;
  max-width: 1100px;
  position: relative;

  .fc-view {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }

  .fc-toolbar {
    justify-content: center;

    &-title {
      color: #fff;
      z-index: 2000;
    }

    .fc-button {
      background: transparent;
      border: 0;

      &:focus {
        box-shadow: none !important;
      }

      .fc-icon {
        color: #9ca8b4;
      }
    }
  }

  .fc-day-today {
    background: transparent !important;

    .fc-daygrid-day-number {
      background: #1e2d36;
      border-radius: 4px;
      color: #fff;
      font-weight: 700;
      padding: 0 8px;
    }
  }

  .fc-head {
    &-container {
      border-color: #d8dee8;
    }
  }

  .fc-day {
    border-color: #d8dee8;
  }

  .fc-day-header {
    border-color: #d8dee8;
  }

  .fc-daygrid-day-number {
    color: #002035;
    font-size: 16px;
    margin-bottom: 12px;
    padding: 0;
    position: relative;
    right: 8px;
    top: 8px;
  }

  .fc-daygrid-event {
    align-items: center;
    background: #ffece4;
    border: 1px solid #ffd8c8;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    height: 24px;
    margin: 4px 5px 0;
    margin-left: 8px !important;
    margin-right: 8px !important;
    padding-left: 4px;
    position: relative;

    &.applications {
      background: #ffffff;
      border: 1px solid #ff6c6c;
      color: #1e2d36;

      .count {
        background: #ff6c6c;
      }

      .dash {
        background: #ff6c6c;
      }
    }

    &.proposed {
      background: #ffffff;
      border: 1px solid #ff6c6c;
      color: #1e2d36;

      .count {
        background: #ff6c6c;
      }

      .dash {
        background: #ff6c6c;
      }
    }

    &.approved {
      background: #fff4d4;
      border: 1px solid #ffdb70;

      .count {
        background: #aa861b;
      }

      .dash {
        background: #aa861b;
      }
    }

    &.available {
      background: #ffece4;
      border: 1px solid #ffd8c8;

      .count {
        background: #df6738;
      }

      .dash {
        background: #df6738;
      }
    }

    &.booked {
      background: #dff4c9;
      border: 1px solid rgba(158, 211, 104, 0.5);

      .count {
        background: #659138;
      }

      .dash {
        background: #659138;
      }
    }

    &.needsSignoff {
      background: #dbf7fb;
      border: 1px solid #a0eaf4;

      .count {
        background: #1aa1b5;
      }

      .dash {
        background: #1aa1b5;
      }
    }

    &.paid {
      background: #3e4d58;
      border: 1px solid #1e2d36;

      .fc-event-main {
        color: #fff;
      }

      .count {
        background: #ffffff;
        color: #1e2d36;
      }

      .dash {
        background: #ffffff;
      }
    }

    &.signedOff {
      background: #9ca8b4;
      border: 1px solid #7c8c9b;

      .fc-event-main {
        color: #fff;
      }

      .count {
        background: #6f7d8a;
      }

      .dash {
        background: #6f7d8a;
      }
    }

    &.urgent {
      background: #f9d0d5;
      border: 1px solid rgba(231, 150, 160, 0.5);

      .count {
        background: #c93346;
      }

      .dash {
        background: #c93346;
      }
    }

    .fc-event-main {
      align-items: center;
      color: #1e2d36;
      display: flex;
      padding: 0;
      width: 100%;
    }

    .fc-event-title {
      font-size: 12px;
    }

    .dash {
      background: #df6738;
      border-radius: 2px;
      height: 16px;
      position: absolute;
      right: 4px;
      top: 2px;
      width: 2px;
    }

    .count {
      align-items: center;
      background: #df6738;
      border-radius: 2px;
      color: #ffffff;
      font-size: 11px;
      font-weight: 700;
      height: 16px;
      justify-content: center;
      margin-right: 6px;
      min-width: 16px;
      padding-left: 3px;
      padding-right: 3px;
      text-align: center;
    }
  }
`
