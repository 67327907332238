import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  FormBreak,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormLabel,
  FormSelect,
  ModalFooter,
} from '@patchworkhealth/web-components'
import { useFormik, FieldArray, FormikProvider } from 'formik'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { SIGN_OFF_RECEIPT, COST_CENTRES, UPDATE_RECEIPT, GET_RECEIPT } from '../helpers/ReceiptsHelpers'

function CustomFormInputGroup({ id, label, disabled, onChange, value }) {
  return (
    <CustomFormInputContainer disabled={disabled}>
      <CustomFormLabel htmlFor={id}>{label}</CustomFormLabel>
      <CustomFormInput disabled={disabled} id={id} onChange={onChange} value={value} />
    </CustomFormInputContainer>
  )
}

function SignOffReceipt({ receipt, receiptId, onCloseModal, refetchReceipts }) {
  const [signOffReceipt] = useMutation(SIGN_OFF_RECEIPT, {
    onCompleted: () => {
      toast.success('Receipt signed off successfully')
      onCloseModal()
      refetchReceipts()
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: [{ query: GET_RECEIPT, variables: { receiptId: receipt.id } }],
  })
  const [updateReceipt] = useMutation(UPDATE_RECEIPT, {
    refetchQueries: [{ query: GET_RECEIPT, variables: { receiptId: receipt.id } }],
  })
  const [costCentres, setCostCentres] = useState([])

  const [signOffAllowed, setSignOffAllowed] = useState(false)
  const [editAllowed, setEditAllowed] = useState(false)
  const [formValuesChanged, setFormValuesChanged] = useState(false)

  useEffect(() => {
    if (receipt) {
      const canBeSignedOff = receipt.status === 'sign_off_requested'
      setSignOffAllowed(canBeSignedOff)

      const canBeEdited = receipt.status === 'sign_off_requested' || receipt.status === 'signed_off'
      setEditAllowed(canBeEdited)
    }
  }, [receipt])

  useQuery(COST_CENTRES, {
    onCompleted: (res) => {
      setCostCentres(res.costCentres.nodes)
    },
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      total: receipt.totalCost,
      receiptItems: receipt.receiptItems.map((ri) => ({
        id: ri.id,
        costCentre: costCentres.find((cc) => cc.label === ri.costCentre),
        quantity: ri.count.toString(),
        itemName: ri.itemGradeCost.item.name,
        costPerUnit: ri.itemCost,
        wtdPercentage: ri.wtdPercentage > 0 ? `${ri.wtdPercentage}%` : '',
        subtotal: ri.totalCost,
        gradeTitle: receipt.grade.title,
        itemCostWithWtd: ri.itemCostWithWtd,
      })),
    },
    onSubmit: (values) => {
      toast.loading('Loading', { toastId: 2, position: 'top-right' })

      const receiptItemsAttributes = values.receiptItems.map((ri) => ({
        id: ri.id,
        count: Number(ri.quantity),
        costCentre: ri.costCentre.label,
      }))

      updateReceipt({
        variables: {
          id: receipt.id,
          receiptItemsAttributes,
        },
      })
        .then(() => {
          onCloseModal()
          refetchReceipts()
          toast.update(2, {
            type: 'success',
            isLoading: false,
            autoClose: 3000,
            position: 'top-right',
            render: 'Item updated',
          })
        })
        .catch((err) => {
          toast.error(err.message)
        })
    },
  })

  useEffect(() => {
    const initialValues = JSON.stringify(formik.initialValues)
    const currentValues = JSON.stringify(formik.values)
    setFormValuesChanged(currentValues !== initialValues)
  }, [formik.values])

  const onSignOff = async () => {
    await signOffReceipt({
      variables: {
        receiptId,
      },
    })
  }

  const onUpdateQuantity = (e, index) => {
    const receiptItem = formik.values.receiptItems[index]
    const quantity = e.currentTarget.value
    formik.setFieldValue(`receiptItems[${index}].quantity`, quantity)

    const newSubtotal = receiptItem.itemCostWithWtd * quantity
    formik.setFieldValue(`receiptItems[${index}].subtotal`, newSubtotal)

    const otherReceiptItems = formik.values.receiptItems.filter((_value, indx) => index !== indx)
    const totalOtherReceiptItems = otherReceiptItems.map((ri) => ri.subtotal).reduce((prev, next) => prev + next, 0)
    formik.setFieldValue(`total`, newSubtotal + totalOtherReceiptItems)
  }

  return (
    <>
      <FormGridContainer>
        <FormGrid columns={3} style={{ marginBottom: 30 }}>
          <FormGroupMod>
            <FormLabel>Date Completed</FormLabel>
            <ModFormInput
              disabled
              name="dateCompleted"
              type="text"
              value={moment(receipt.dateCompleted).format('YYYY-MM-DD')}
            />

            <div style={{ marginTop: 10 }} />

            <FormLabel>Date Submitted</FormLabel>
            <ModFormInput
              disabled
              name="dateSubmitted"
              type="text"
              value={moment(receipt.createdAt).format('YYYY-MM-DD')}
            />
          </FormGroupMod>

          <div />

          <ItemCard>
            <ItemCardGrade>{receipt.grade.title}</ItemCardGrade>
            <ItemCardName>{receipt.worker.fullName}</ItemCardName>
            <ItemCardEmail>{receipt.worker.email}</ItemCardEmail>
            <ItemCardPhone>{receipt.worker.phoneNumber}</ItemCardPhone>
          </ItemCard>
        </FormGrid>

        <FormikProvider value={formik}>
          <FieldArray
            name="receiptItems"
            render={() => (
              <div>
                {formik.values.receiptItems.map((rec, index) => (
                  <div key={rec.id}>
                    <FormGrid columns={3}>
                      <FormGroupMod>
                        <FormLabel>Item</FormLabel>
                        <ModFormInput disabled name="item" type="text" value={rec.itemName} />
                      </FormGroupMod>
                      <FormGroupMod>
                        <FormLabel>Payment Grade</FormLabel>
                        <ModFormInput disabled name="paymentGrade" type="text" value={rec.gradeTitle} />
                      </FormGroupMod>
                      <FormGroupMod>
                        <FormLabel>Cost Centre</FormLabel>
                        <FormSelect
                          id={`receiptItems[${index}].costCentre`}
                          isDisabled={!editAllowed}
                          onChange={(option) => {
                            formik.setFieldValue(`receiptItems[${index}].costCentre`, option)
                          }}
                          options={costCentres}
                          placeholder="Select cost centre"
                          value={rec.costCentre}
                        />
                      </FormGroupMod>
                    </FormGrid>
                    <Flex>
                      <CustomFormInputGroup
                        disabled
                        id="cpu"
                        label="Cost per unit"
                        onChange={() => null}
                        value={`£${rec.costPerUnit.toFixed(2)}`}
                      />
                      <CustomFormInputGroup
                        disabled={!editAllowed}
                        id={`receiptItems[${index}].quantity`}
                        label="Qty completed"
                        onChange={(e) => {
                          onUpdateQuantity(e, index)
                        }}
                        value={rec.quantity}
                      />

                      <CustomFormInputGroup
                        disabled
                        id="wtd"
                        label="WTD"
                        onChange={() => null}
                        value={rec.wtdPercentage}
                      />
                      <CustomFormInputGroup
                        disabled
                        label="Subtotal"
                        onChange={() => null}
                        value={`£${rec.subtotal.toFixed(2)}`}
                      />
                    </Flex>
                    {receipt.receiptItems.length > 1 && <FormBreak />}
                  </div>
                ))}
              </div>
            )}
          />
        </FormikProvider>
        {receipt.receiptItems.length > 1 && (
          <div>
            <Flex>
              <CustomFormInputGroup disabled label="Total" value={`£${formik.values.total.toFixed(2)}`} />
            </Flex>
          </div>
        )}

        {signOffAllowed && (
          <div>
            <FlexRight>
              <DisableableButton
                action={() => {
                  onSignOff()
                }}
                color="blue"
                disabled={formValuesChanged}
                text="Sign Off"
              />
            </FlexRight>
          </div>
        )}
      </FormGridContainer>

      <ReceiptModalFooter>
        <div />
        <div>
          <DefaultButton action={onCloseModal} text="Cancel" type="white" />
          {editAllowed && (
            <DisableableButton
              action={formik.handleSubmit}
              color="blue"
              disabled={!formValuesChanged}
              text="Save Changes"
            />
          )}
        </div>
      </ReceiptModalFooter>
    </>
  )
}

export default SignOffReceipt

const DisableableButton = styled(DefaultButton)`
  &:disabled {
    background-color: hsl(0, 0%, 80%);
    > span {
      color: hsl(0, 0%, 60%);
    }
  }
`

const ModFormInput = styled(FormInput)`
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`

const CustomFormInputContainer = styled.div`
  max-width: 130px;
  max-height: 62px;
  padding: 8px 16px 11px;
  border: 1px solid #e4e7eb;
  border-radius: 8px;
  background-color: ${({ disabled }) => (disabled ? '#f5f7fa' : '#fff')};
  margin-right: 12px;
`

const CustomFormLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: #002033;
  margin-bottom: 0;
`

const CustomFormInput = styled.input`
  width: 100%;
  border: 0;
  font-weight: 400;
  font-size: 14px;
  color: #002033;
  background-color: transparent;
  outline: none;

  &:focus {
    outline: none;
  }
`

export const FormGroupMod = styled(FormGroup)`
  position: relative;
  svg:hover + div {
    opacity: 1 !important;
  }

  .input-group-text {
    border: 1px solid #ccd2d8;
  }
`
const ReceiptModalFooter = styled(ModalFooter)`
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-bottom: 0 !important;
  min-height: auto !important;
`

const ItemCard = styled.div`
  padding: 39px 46px 51px 40px;
  background: #f5f7fa;
  border-radius: 8px;
`

const ItemCardName = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: #002033;
`

const ItemCardEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #646e7b;
  text-decoration: underline;
`

const ItemCardPhone = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #646e7b;
`

const ItemCardGrade = styled.div`
  position: absolute;
  top: 60px;
  right: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
  padding: 2px 13px;
  border-radius: 188px;
  background-color: #7b61ff;
  margin-right: 5px;
`

const Flex = styled.div`
  display: flex;
  margin-top: 14px;
`
const FlexRight = styled.div`
  display: flex;
  margin-top: 14px;
  justify-content: flex-end;
`
