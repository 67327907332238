/* eslint-disable no-new */
/* eslint-disable no-console */
import SlimSelect from 'slim-select'
import { application } from '../../frontend/controllers/index'
import SearchController from '../search_controller'

export default class ReasonForDeletionController extends SearchController {
  static get targets() {
    return ['select']
  }

  connect() {
    const target = this.selectTarget
    new SlimSelect({
      select: target,
      placeholder: 'Select reason',
      onChange: (info) => {
        // Set the value of the hidden input to the selected value
        const hiddenInput = document.getElementById('reason_for_deletion_input')
        hiddenInput.value = info.value
      },
    })
  }
}

application.register('reason_for_deletion_controller', ReasonForDeletionController)
