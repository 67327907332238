import { Controller } from "stimulus";

export default class WorkerPayrollInformationController extends Controller {
  static targets = [
    "createSection",
    "newSection",
    "upcomingESR",
    "upcomingESRButton",
    "deactivateESR",
    "confirmDeactivateESR",
  ];

  showCreateESR() {
    this.createSectionTarget.classList.remove("show");
    this.createSectionTarget.classList.add("hide");
    this.newSectionTarget.classList.remove("hide");
    this.newSectionTarget.classList.add("show");
  }

  hideCreateESR() {
    this.createSectionTarget.classList.remove("hide");
    this.createSectionTarget.classList.add("show");
    this.newSectionTarget.classList.remove("show");
    this.newSectionTarget.classList.add("hide");
  }

  toggleUpcomingESR() {
    this.upcomingESRTarget.classList.add("show");
    this.upcomingESRButtonTarget.classList.add("show");
  }

  showDeactivateESR() {
    this.deactivateESRTarget.classList.add("hide");
    this.deactivateESRTarget.classList.remove("show");
    this.confirmDeactivateESRTarget.classList.add("show");
    this.confirmDeactivateESRTarget.classList.remove("hide");
  }

  hideDeactivateESR() {
    this.deactivateESRTarget.classList.add("show");
    this.deactivateESRTarget.classList.remove("hide");
    this.confirmDeactivateESRTarget.classList.add("hide");
    this.confirmDeactivateESRTarget.classList.remove("show");
  }
}

import { application } from "../../frontend/controllers/index";
application.register(
  "worker-payroll-information",
  WorkerPayrollInformationController
);
