/* eslint-disable no-alert */
import { ApolloProvider, useMutation, gql } from '@apollo/client'
import { DefaultButton, FormGroup, FormLabel, FormInput, Loading } from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import createApolloClient from '../createApolloClient'

const DID_NOT_ATTEND_CREATE = gql`
  mutation DidNotAttendCreate($comment: String, $deleteMode: Boolean!, $shiftId: Int!) {
    didNotAttendCreate(comment: $comment, deleteMode: $deleteMode, shiftId: $shiftId) {
      errors {
        attribute
        message
      }
      result
    }
  }
`

document.addEventListener('DOMContentLoaded', () => {
  const client = createApolloClient()
  const config = { attributes: true, childList: true, subtree: true, characterData: true }

  const observer = new MutationObserver(() => {
    const rootElement = document.querySelector('[data-react-component="denote_as_dna"]')
    const shiftId = rootElement?.getAttribute('shiftId')
    const didNotAttend = rootElement?.getAttribute('didNotAttend')
    const shiftStatus = rootElement?.getAttribute('status')

    if (rootElement && rootElement.childElementCount === 0) {
      ReactDOM.render(
        <ApolloProvider client={client}>
          <DnaComponent didNotAttend={didNotAttend === 'true'} shiftId={shiftId} shiftStatus={shiftStatus} />
        </ApolloProvider>,
        rootElement
      )
    }
  })
  observer.observe(document, config)
})

const initialInputs = {
  comments: '',
  deleteMode: false,
  laoding: false,
  confirmStep: false,
}

function DnaComponent({ shiftId, didNotAttend, shiftStatus }) {
  /* State -------------------------- */
  const [inputs, setInputs] = useState(initialInputs)
  const [didnotAttendMutation] = useMutation(DID_NOT_ATTEND_CREATE)

  /* Functions -------------------------- */

  const handleConfirm = (e) => {
    setInputs({
      ...inputs,
      confirmStep: true,
      deleteMode: e,
    })
  }

  const handleCancelConfirm = () => {
    setInputs({
      ...inputs,
      confirmStep: false,
      deleteMode: false,
    })
  }

  const handleSubmit = async () => {
    setInputs({
      ...inputs,
      loading: true,
    })

    didnotAttendMutation({
      variables: {
        comment: inputs.comments,
        deleteMode: inputs.deleteMode,
        shiftId: Number(shiftId),
      },
    })
      .then((res) => {
        setInputs({
          ...inputs,
          loading: false,
          confirmStep: false,
        })

        const errors = res?.data?.didNotAttendCreate?.errors

        if (errors.length > 0) {
          alert('Error occurred')
          return
        }

        alert('Shift Marked as DNA')
        window.location.reload()

        const link = document.createElement('a')
        link.href = `/shifts?only_search_id=true&shift_ids[]=${shiftId}&filter_by_shift_blocks=true`
        link.target = '_blank'

        // Append it to the body and click it
        document.body.appendChild(link)
        link.click()
        // Remove the link from the body
        document.body.removeChild(link)
      })
      .catch(() => {
        alert('Error occurred 111')
        setInputs({
          ...inputs,
          loading: false,
        })
      })
  }

  if (didNotAttend) {
    return <h4>Shift has been marked as 'Did Not Attend'</h4>
  }

  if (inputs.loading) {
    return <Loading />
  }

  return (
    <div>
      {!inputs.confirmStep && (
        <>
          {shiftStatus === 'sign_off_requested' && (
            <p style={{ fontWeight: 'bold' }}>User submitted Timehseet! Click below to Mark this shift as DNA.</p>
          )}

          <h4 style={{ marginBottom: 40 }}>
            If a worker has not attended a shift, it can help to provide the reason for future reference.
          </h4>
          <FormGroup>
            <FormLabel>Comments</FormLabel>
            <FormInput
              as="textarea"
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  comments: e.target.value,
                })
              }}
              placeholder="Enter comments..."
              rows={5}
              type="text"
            />
          </FormGroup>

          <br />

          <p>
            The Worker will recieve an automated email with your comments attached. They will not be able to submit a
            timesheet once you save changes.
          </p>
          <div
            style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #e5e5e5', paddingTop: 12 }}
          >
            <div />

            {shiftStatus !== 'sign_off_requested' && (
              <div>
                <DefaultButton
                  action={() => handleConfirm(false)}
                  style={{ marginRight: 12 }}
                  text="DNA Cancel Booking"
                  type="white"
                />
                <DefaultButton action={() => handleConfirm(true)} color="blue" text="DNA Delete Booking" />
              </div>
            )}

            {shiftStatus === 'sign_off_requested' && (
              <DefaultButton action={() => handleConfirm(true)} color="blue" text="Mark as DNA" />
            )}
          </div>
        </>
      )}

      {inputs.confirmStep && (
        <div>
          <p>Are you sure you want to mark this shift as DNA?</p>

          <div
            style={{ display: 'flex', justifyContent: 'space-between', borderTop: '1px solid #e5e5e5', paddingTop: 12 }}
          >
            <div />

            <div>
              <DefaultButton action={handleCancelConfirm} style={{ marginRight: 12 }} text="Cancel" type="white" />
              <DefaultButton action={handleSubmit} color="blue" text="Confirm" />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
