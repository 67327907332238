import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class GradeSelectorController extends SearchController {
    static get targets() {
        return ['staff_group_id', 'grade_id', 'label', 'select']
    }

    connect() {
        this.element[this.identifier] = this
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target = this.selectTarget;
        const searchOnSelect = this.data.get('search-on-select');
        const gradeSelectorVal = document.querySelector('.modal__validation--grades');
        const closeOnSelect = this.data.get('keep-open') ? false : true

        this.select = new SlimSelect({
            select: target,
            placeholder: 'Select grade',
            closeOnSelect: closeOnSelect,
            onChange: (info) => {
                this.storeFilters('grade_ids[]', info);
                if (searchOnSelect) {
                    this.multiSearchOnSelect('grade_ids[]', info);
                }
                if (gradeSelectorVal) {
                    gradeSelectorVal.style.display = "none";
                }
            }
        });

        if (this.data.get('initial-value')) {
            this.select.set(this.data.get('initial-value'))
        }
    }
}
import { application } from '../../frontend/controllers/index'
import SearchController from "../search_controller";

application.register("grade_selector", GradeSelectorController)