import React, { useState } from "react";

const HelloWorld = ({ data }) => {
    console.log(data)

    return (
        <></>
    )
}

export default HelloWorld; 