import {Controller} from 'stimulus'

export default class PagingNavigationController extends Controller {
    static get targets() {
        return ['itemsSelect']
    }

    connect() {
        const target = this.itemsSelectTarget;
        let params = queryString.parse(location.search);
        if (!params['page_items']) {
            target.value = 25
        }
        else if (params['page_items'] > 100) {
            target.value = 100;
        }
        else if (params['page_items']) {
            target.value = params['page_items'];
        }
        target.addEventListener("change", this.changeItemsPerPage);
    }

    changeItemsPerPage(event) {
        let params = queryString.parse(location.search);
        delete (params['page']);
        params['page_items'] = event.target.value;

        const url = queryString.stringify(params);
        window.location.search = url
    }
}

import {application} from '../../frontend/controllers/index'
import queryString from "query-string";

application.register("paging_navigation", PagingNavigationController)