import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import Modal from "react-bootstrap/Modal";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  LoadingContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";

import { CREATE_SPECIALITY, UPDATE_SPECIALITY } from "./SpecialitiesHelpers";

const SpecialitiesModal = ({ handleClose, refetch, show, speciality }) => {
  const [error, setError] = useState({ title: "" });
  const [inputs, setInputs] = useState({ title: "" });
  const [loading, setLoading] = useState(false);

  const [createSpeciality] = useMutation(CREATE_SPECIALITY);
  const [updateSpeciality] = useMutation(UPDATE_SPECIALITY);

  useEffect(() => {
    setInputs({ title: "" });
    if (speciality) {
      setInputs({
        title: speciality.title ? speciality.title : "",
      });
    }
  }, [speciality]);

  const handleCloseAction = () => {
    setInputs({ title: "" });
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const createSpecialityAction = () => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a title." });
    } else {
      setLoading(true);
      createSpeciality({
        variables: {
          title: inputs.title,
        },
      }).then((data) => {
        setLoading(false);
        if (!data.data.createSpeciality.errors.length > 0) {
          refetch();
          handleCloseAction();
        } else {
          setError({
            error: true,
            message: `There was a problem creating this speciality.`,
          });
        }
      });
    }
  };

  const updateSpecialityAction = (specialityId) => {
    setError({});
    if (inputs.title === "") {
      setError({ ...error, title: "Please enter a title." });
    } else {
      setLoading(true);
      updateSpeciality({
        variables: {
          id: Number(specialityId),
          title: inputs.title,
        },
      }).then((data) => {
        setLoading(false);
        if (!data.data.updateSpeciality.errors.length > 0) {
          refetch();
          handleCloseAction();
        } else {
          setError({
            error: true,
            message: `There was a problem updating this speciality.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {speciality ? `Edit ${speciality.title}` : "Create Speciality"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0, overflowY: "visible" }}>
            <FormDetailGroup>
              <FormGrid columns={2}>
                <FormGroup
                  component={"input"}
                  value={inputs.title}
                  placeholder={"Enter title here"}
                  label={"Speciality Title"}
                  name="title"
                  onChange={handleInputChange}
                  type="text"
                  required
                  error={error.title}
                />
              </FormGrid>
            </FormDetailGroup>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleCloseAction}>
                Cancel
              </button>
              {speciality ? (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => updateSpecialityAction(speciality.id)}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => createSpecialityAction()}
                >
                  Create Speciality
                </button>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default SpecialitiesModal;
