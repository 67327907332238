export async function createStaffGroup(body) {
  const url = `/staff_groups`

  const data = fetch(url, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}

export async function editStaffGroup(staffGroupId, body) {
  const url = `/staff_groups/${staffGroupId}`

  const data = fetch(url, {
    method: "PATCH",
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.status !== 200) return "err";
    else return response;
  })
  return data;
}