import React, { useState } from "react";
import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import moment from "moment";
import styled from "styled-components";

// import ConfirmModal from "./ConfirmModal";
import {
  TableHeaderContainer,
  TableSearchInput,
  TableComponent,
} from "../Tables/TableElements";
import GradesModal from "./GradesModal";

export const GET_FILTERS = gql`
  query {
    organisationStaffGroup(organisationId: 2) {
      agencyEmail

      askQuestion
      id
      question
      rateNotApplicable
      rateUrl
      staffBankAgreementForm
      title
      tsoEmail
      tsoName
      tsoPhone
      vms
    }
  }
`;

export const GET_STAFF = gql`
  query {
    organisationStaffGroups(organisationId: 2) {
      title
    }
  }
`;

const Grades = ({ grades, organisation, hello }) => {
  // console.log({ grades, oprganisation, grade1 });
  const [grade, setGrade] = useState(undefined);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const { loading, error, data, refetch } = useQuery(GET_FILTERS);
  const {
    loading: loading1,
    error: error1,
    data: data1,
    refetch: refetch1,
  } = useQuery(GET_STAFF);

  // console.log({ grades, organisation, hello });
  // console.log({ loading, data, error });
  // console.log({ loading1, data1, error1 });

  const handleClose = () => {
    setGrade(undefined);
    setShow(false);
  };
  const handleShowCreate = () => {
    setGrade(undefined);
    setShow(true);
  };

  const handleShowEdit = (grade) => {
    setGrade(grade && grade);
    setShow(true);
  };

  const handleCloseConfirm = () => {
    setSite(undefined);
    setShowConfirm(false);
  };

  const handleShowConfirm = (site) => {
    setSite(site && site);
    setShowConfirm(true);
  };

  // search function
  const filteredGrades = grades?.sort((b, a) => (b > a ? -1 : 1));

  // console.log({ filteredGrades });

  return (
    <>
      <SitesContainer>
        <TableHeaderContainer>
          <button
            className="btn btn-cyan"
            // onClick={handleShowCreate}
            onClick={() => {
              window.location.pathname = "/external_grades";
            }}
          >
            View External Grades
          </button>
          <button
            className="btn btn-cyan"
            style={{ marginLeft: "10px" }}
            onClick={handleShowCreate}
          >
            Add Grade
          </button>
        </TableHeaderContainer>
        <TableComponent striped>
          <thead>
            <tr>
              <th>Id</th>
              <th>Title</th>
              <th>DEFAULT EXTERNAL GRADE NAME</th>
              <th>Date Created</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {filteredGrades?.map((item, index) => (
              <tr key={index}>
                <th>{item.id}</th>
                <th>{item.title}</th>
                <th>{item.default_external_grade?.external_name}</th>
                <td>{moment(item.created_at).format("MMMM Do YYYY")}</td>
                <td style={{ textAlign: "right" }}>
                  <button onClick={() => handleShowEdit(item)}>
                    <svg
                      className="icon-shadow"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.182.5h21.636A5.682 5.682 0 0133.5 6.182v21.636a5.682 5.682 0 01-5.682 5.682H6.182A5.682 5.682 0 01.5 27.818V6.182A5.682 5.682 0 016.182.5z"
                        fill="#fff"
                        stroke="#CBD2D9"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17 19.688c-1.749 0-3.167-1.428-3.167-3.188 0-1.76 1.418-3.188 3.167-3.188 1.749 0 3.167 1.428 3.167 3.188 0 1.76-1.418 3.188-3.167 3.188zm7.647-1.198l-.51-.297a.71.71 0 01-.346-.708 6.964 6.964 0 000-1.97c-.038-.287.096-.563.412-.747l.444-.258a.71.71 0 00.313-.85 8.143 8.143 0 00-.567-1.265 8.287 8.287 0 00-.971-1.413c-.222-.262-.595-.322-.954-.113l-.45.262a.693.693 0 01-.781-.053 6.745 6.745 0 00-1.693-.988.702.702 0 01-.433-.652v-.599a.712.712 0 00-.577-.7A8.44 8.44 0 0017 8a8.44 8.44 0 00-1.534.138.713.713 0 00-.577.701v.599a.702.702 0 01-.433.652 6.745 6.745 0 00-1.693.988.693.693 0 01-.781.053l-.514-.301a.702.702 0 00-.89.152 8.29 8.29 0 00-.971 1.413 8.145 8.145 0 00-.567 1.265.71.71 0 00.313.85l.51.297a.71.71 0 01.346.708 6.964 6.964 0 000 1.97.71.71 0 01-.345.708l-.51.297a.71.71 0 00-.314.85c.152.44.345.861.567 1.265a8.29 8.29 0 00.971 1.413.702.702 0 00.89.152l.514-.3a.693.693 0 01.781.052c.51.408 1.077.74 1.693.988a.702.702 0 01.433.652v.599c0 .343.243.637.577.7.5.093 1.01.139 1.534.139s1.035-.046 1.534-.138a.712.712 0 00.577-.701v-.599c0-.283.17-.545.433-.652a6.745 6.745 0 001.693-.988.693.693 0 01.781-.053l.514.301c.295.17.668.11.89-.152a8.287 8.287 0 00.971-1.413c.222-.404.415-.825.567-1.265a.71.71 0 00-.313-.85z"
                        fill="#9CA8B4"
                      />
                    </svg>
                  </button>
                  <button
                  // onClick={() => handleShowConfirm(item)}
                  >
                    <svg
                      className="icon-shadow"
                      width="34"
                      height="34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.182.5h21.636A5.682 5.682 0 0133.5 6.182v21.636a5.682 5.682 0 01-5.682 5.682H6.182A5.682 5.682 0 01.5 27.818V6.182A5.682 5.682 0 016.182.5z"
                        fill="#fff"
                        stroke="#CBD2D9"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.075 20.943c0 .367-.314.667-.698.667-.384 0-.698-.3-.698-.667v-6.666c0-.367.314-.667.698-.667.384 0 .698.3.698.667v6.666zm-3.49 0c0 .367-.315.667-.699.667-.384 0-.698-.3-.698-.667v-6.666c0-.367.314-.667.698-.667.384 0 .698.3.698.667v6.666zm6.63-11h-3.838c0-.92-.782-1.666-1.745-1.666-.964 0-1.746.746-1.746 1.666h-3.839c-.58 0-1.047.447-1.047 1 0 .554.468 1 1.047 1l.607 10.757c.053.883.817 1.577 1.745 1.577h6.464c.929 0 1.693-.694 1.746-1.577l.607-10.757c.58 0 1.047-.446 1.047-1 0-.553-.468-1-1.047-1z"
                        fill="#9CA8B4"
                      />
                    </svg>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </TableComponent>
      </SitesContainer>

      {show && (
        <GradesModal
          data1={data1}
          handleClose={handleClose}
          show={show}
          grades={grade}
        />
      )}
      {/* 
      {showConfirm && (
        <ConfirmModal
          handleCloseConfirm={handleCloseConfirm}
          site={site}
          show={showConfirm}
        />
      )} */}
    </>
  );
};

export default Grades;

const SitesContainer = styled.div`
  margin-top: -75px;
`;
