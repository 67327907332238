/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import Pagy from '../../../Helpers/Pagy'
import { TableRowEditIcon } from '../../Assets/Icons'
import { ErrorContainer, ShiftModalLabel } from '../../Forms/FormElements'
import { Loading } from '../../Modals/ModalElements'
import {
  TableContainer,
  TableHeaderContainer,
  TableHeaderWrapper,
  TableComponent,
  TableSearchInput,
} from '../../Tables/TableElements'
import { GET_AGENCY_USERS, GET_VMS_AGENCY_USERS } from './AgencyUsersHelpers'
import AgencyUsersModal from './AgencyUsersModal'

function AgencyUsers({ agencies, vms }) {
  const [agencyUser, setAgencyUser] = useState(undefined)
  const [show, setShow] = useState(false)
  const [page, setPage] = useState(1)
  const [name, setName] = useState(undefined)
  const [email, setEmail] = useState(undefined)

  const { loading, error, data, refetch } = useQuery(vms ? GET_VMS_AGENCY_USERS : GET_AGENCY_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      page,
      items: 25,
      name,
      email,
    },
  })

  const handleClose = () => {
    setAgencyUser(undefined)
    setShow(false)
  }

  const handleShowEdit = (user) => {
    setAgencyUser(user)
    setShow(true)
  }

  const filteredAgencyUsers = vms ? data?.vmsAgencyUsers.list : data?.agencyUsers.list

  const pagination = vms ? data?.vmsAgencyUsers.pagination : data?.agencyUsers.pagination

  return (
    <>
      <TableContainer style={{ marginTop: '-95px' }}>
        <TableHeaderWrapper>
          <div className="shift-table__container">
            <TableHeaderContainer>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: 20 }}>
                  <ShiftModalLabel style={{ color: '#fff' }}>By user name</ShiftModalLabel>
                  <TableSearchInput
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name"
                    type="text"
                    value={name}
                  />
                </div>
                <div>
                  <ShiftModalLabel style={{ color: '#fff' }}>By user email</ShiftModalLabel>
                  <TableSearchInput
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    type="text"
                    value={email}
                  />
                </div>
              </div>
              <div />
            </TableHeaderContainer>
          </div>
        </TableHeaderWrapper>
        {loading ? (
          <Loading />
        ) : error ? (
          <ErrorContainer>Theres an error loading the Agency Users.</ErrorContainer>
        ) : (
          <TableComponent striped>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                {!vms && <th>Agency</th>}
              </tr>
            </thead>
            <tbody>
              {filteredAgencyUsers?.map((agencyUser, index) => (
                <tr key={index} style={{ background: agencyUser.suspended && '#E39AA7' }}>
                  <td>
                    <a onClick={() => handleShowEdit(agencyUser)}>
                      {agencyUser.firstName} {agencyUser.lastName}
                    </a>
                  </td>
                  <td>
                    {!agencyUser.suspended && <span>{agencyUser.email}</span>}

                    {agencyUser.suspended && (
                      <div style={{ textDecoration: 'none', fontWeight: 'bold', fontSize: 15 }}>
                        <span>Agency User Suspended</span>

                        <svg
                          fill="none"
                          height="18"
                          style={{
                            margin: '-3px 0px 0px 5px',
                          }}
                          viewBox="0 0 18 18"
                          width="18"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            clipRule="evenodd"
                            d="M7.89623 1.71223C8.2332 1.52252 8.61337 1.42285 9.00008 1.42285C9.38678 1.42285 9.76696 1.52252 10.1039 1.71223C10.4409 1.90195 10.7233 2.17531 10.9238 2.50594L10.926 2.5095L17.2785 13.1145L17.2846 13.1249C17.481 13.4651 17.585 13.8508 17.5861 14.2437C17.5872 14.6366 17.4854 15.0229 17.2908 15.3642C17.0963 15.7055 16.8158 15.99 16.4771 16.1892C16.1385 16.3884 15.7537 16.4955 15.3608 16.4999L15.3526 16.5L2.63933 16.4999C2.24648 16.4956 1.86161 16.3884 1.52301 16.1892C1.1844 15.99 0.903866 15.7055 0.709314 15.3642C0.514761 15.0229 0.412971 14.6366 0.414071 14.2437C0.415171 13.8508 0.519123 13.4651 0.715584 13.1249L0.721676 13.1145L7.07418 2.50951L7.71756 2.8949L7.07633 2.50594C7.27688 2.17531 7.55927 1.90195 7.89623 1.71223ZM8.35973 3.28239L2.01209 13.8793C1.94821 13.9916 1.91443 14.1186 1.91407 14.2479C1.9137 14.3789 1.94763 14.5076 2.01248 14.6214C2.07733 14.7352 2.17084 14.83 2.28371 14.8964C2.39559 14.9622 2.52262 14.9979 2.65237 14.9999H15.3478C15.4775 14.9979 15.6046 14.9622 15.7164 14.8964C15.8293 14.83 15.9228 14.7352 15.9877 14.6214C16.0525 14.5076 16.0865 14.3789 16.0861 14.2479C16.0857 14.1186 16.052 13.9917 15.9881 13.8793L9.64133 3.28388L9.64042 3.28239C9.57363 3.17285 9.47984 3.08226 9.36803 3.01931C9.2557 2.95607 9.12898 2.92285 9.00008 2.92285C8.87118 2.92285 8.74445 2.95607 8.63213 3.01931C8.52031 3.08226 8.42653 3.17285 8.35973 3.28239ZM9.00008 5.9999C9.4143 5.9999 9.75008 6.33569 9.75008 6.7499V9.7499C9.75008 10.1641 9.4143 10.4999 9.00008 10.4999C8.58587 10.4999 8.25008 10.1641 8.25008 9.7499V6.7499C8.25008 6.33569 8.58587 5.9999 9.00008 5.9999ZM9.00008 11.9999C8.58587 11.9999 8.25008 12.3357 8.25008 12.7499C8.25008 13.1641 8.58587 13.4999 9.00008 13.4999H9.00758C9.4218 13.4999 9.75758 13.1641 9.75758 12.7499C9.75758 12.3357 9.4218 11.9999 9.00758 11.9999H9.00008Z"
                            fill="#B03943"
                            fillRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </td>

                  {!vms && (
                    <td>
                      <strong>{agencyUser.agency?.title}</strong> ({agencyUser.agency?.id})
                    </td>
                  )}
                  <td style={{ textAlign: 'right' }}>
                    <button className="btn btn-text" onClick={() => handleShowEdit(agencyUser)}>
                      <TableRowEditIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableComponent>
        )}
      </TableContainer>
      {pagination && <Pagy pagination={pagination} setPage={setPage} />}
      {show && (
        <AgencyUsersModal
          agencies={agencies}
          agencyUser={agencyUser}
          handleClose={handleClose}
          refetch={refetch}
          show={show}
          vms={vms}
        />
      )}
    </>
  )
}

export default AgencyUsers
