import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'
import queryString from 'query-string'

export default class LocumSearchByAgencyController extends Controller {
    static get targets() {
        return ['agency_name','agency_id','label']
    }

    connect() {
        window.qs = queryString;
        this.queryString = queryString.parse(location.search);
        const target = document.querySelector('#agency-selector');
        this.select = new SlimSelect({
            select: '#agency-selector',
            onChange: () => {
                let params = queryString.parse(location.search);
                event.preventDefault();
                if( target.value === "All"){
                    params['agency_id'] = null;
                    const url = queryString.stringify(params);
                    window.location.search = url
                    return
                }
                if( params['agency_id'] != target.value){
                    params['agency_id'] = target.value;
                    const url = queryString.stringify(params);
                    window.location.search = url
                }
            }
        });

        if(this.data.get('initial-value')){
            this.select.set(this.data.get('initial-value'))
        }
    }

    // select(event) {
    //     event.preventDefault()
    //     const agency_id = event.toElement.dataset.id
    //     this.agency_idTarget.value = agency_id
    //     this.staff_group_idTarget.value = "1" // TODO: Use the real staff group
    //
    //     const staff_group_label = this.findParentMenuButton(event).textContent.trim()
    //     const agency_label = event.toElement.textContent.trim()
    //     const new_label = `${staff_group_label}, ${agency_label}`
    //     this.labelTarget.textContent = new_label
    // }

    findParentMenuButton(event){
        return event.toElement.parentElement.parentElement.getElementsByTagName('button')[0]
    }
}
import { application } from '../../frontend/controllers/index'

application.register("locum_search_by_agency", LocumSearchByAgencyController)