import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  LoadingContainer,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { editSuperAdmins, createSuperAdmins } from "./SuperAdminHelpers";

const SuperAdminModal = ({ show, handleClose, superAdmin }) => {
  const [error, setError] = useState({ first_name: "" });
  const [inputs, setInputs] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setInputs({});
    if (superAdmin) {
      setInputs({
        first_name: superAdmin.first_name ? superAdmin.first_name : "",
        last_name: superAdmin.last_name ? superAdmin.last_name : "",
        email: superAdmin.email ? superAdmin.email : "",
      });
    }
  }, [superAdmin]);

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const createSuperAdminAction = () => {
    setError({});

    if (inputs.first_name === "") {
      setError({ ...error, first_name: "Please enter a name." });
    } else if (inputs.last_name === "") {
      setError({ ...error, last_name: "Please enter a name." });
    } else if (inputs.email === "") {
      setError({ ...error, email: "Please enter an Email." });
    } else {
      setLoading(true);
      const body = {
        super_admin: {
          first_name: inputs.first_name,
          last_name: inputs.last_name,
          email: inputs.email,
        },
      };
      createSuperAdmins(body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setLoading(false);
          setError({
            error: true,
            message: `There was a problem Creating this Super Admin.`,
          });
        }
      });
    }
  };

  const editSuperAdminAction = (adminId) => {
    setError({});
    if (inputs.first_name === "") {
      setError({ ...error, first_name: "Please enter a name." });
    } else if (inputs.last_name === "") {
      setError({ ...error, last_name: "Please enter a name." });
    } else if (inputs.email === "") {
      setError({ ...error, email: "Please enter an Email." });
    } else {
      setLoading(true);
      const body = {
        super_admin: {
          first_name: inputs.first_name,
          last_name: inputs.last_name,
          email: inputs.email,
        },
      };
      editSuperAdmins(adminId, body).then((data) => {
        if (data !== "err") {
          window.location.reload(true);
        } else {
          setLoading(false);
          setError({
            error: true,
            message: `There was a problem updating this Super Admin.`,
          });
        }
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>
          {superAdmin
            ? `Edit ${superAdmin.first_name} ${superAdmin.last_name}`
            : "Create Super Admin"}
        </ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http:www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      {loading ? (
        <LoadingContainer>
          <svg
            width="38"
            height="38"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#ff7f4d"
          >
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)" strokeWidth="2">
                <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                <path d="M36 18c0-9.94-8.06-18-18-18">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="1s"
                    repeatCount="indefinite"
                  />
                </path>
              </g>
            </g>
          </svg>
        </LoadingContainer>
      ) : (
        <>
          <ModalBody style={{ minHeight: 0, overflowY: "visible" }}>
            <SuperAdminForm>
              <FormDetailGroup>
                <FormGrid columns={2}>
                  <FormGroup
                    component={"input"}
                    value={inputs.first_name}
                    placeholder={"First Name..."}
                    label={"FIRST NAME"}
                    name="first_name"
                    onChange={handleInputChange}
                    type="text"
                    required
                    error={error.first_name}
                  />
                  <FormGroup
                    component={"input"}
                    value={inputs.last_name}
                    placeholder={"Last Name..."}
                    label={"Last Name"}
                    name="last_name"
                    onChange={handleInputChange}
                    type="text"
                    required
                    error={error.last_name}
                  />
                </FormGrid>
                <FormGrid columns={1}>
                  <FormGroup
                    component={"input"}
                    value={inputs.email}
                    placeholder={"someone@gmail.com"}
                    label={"EMAIL"}
                    name="email"
                    onChange={handleInputChange}
                    type="text"
                    required
                    error={error.email}
                  />
                </FormGrid>
              </FormDetailGroup>
            </SuperAdminForm>
          </ModalBody>
          <ModalFooter>
            <div>
              {error && error.message && (
                <ErrorContainer>
                  <p>{error.message}</p>
                </ErrorContainer>
              )}
            </div>
            <div>
              <button className="btn btn-close" onClick={handleCloseAction}>
                Cancel
              </button>
              {superAdmin ? (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => editSuperAdminAction(superAdmin.id)}
                >
                  Save Changes
                </button>
              ) : (
                <button
                  className="btn text-white btn-cyan--modal"
                  onClick={() => createSuperAdminAction()}
                >
                  Create Super Admin
                </button>
              )}
            </div>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default SuperAdminModal;

const SuperAdminForm = styled.div``;
