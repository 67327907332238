import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class QuickSightDashboardController extends Controller {
  connect() {
    const dashboard = async () => {
      const { createEmbeddingContext } = QuickSightEmbedding

      const embeddingContext = await createEmbeddingContext({
        onChange: (changeEvent, metadata) => {},
      })

      const frameOptions = {
        url: this.data.get('url'),
        container: `#${this.data.get('container')}`,
        height: '800px',
        width: '1200px',
        resizeHeightOnSizeChangedEvent: false,
        onChange: (changeEvent, metadata) => {},
      }

      const contentOptions = {
        parameters: [
          {
            Name: 'Organisation',
            Values: ['All'],
          },
          {
            Name: 'Department',
            Values: ['All'],
          },
          {
            Name: 'ShiftType',
            Values: ['Hourly'],
          },
          {
            Name: 'GradeSpecific',
            Values: ['All'],
          },
          {
            Name: 'Grade',
            Values: ['All'],
          },
          {
            Name: 'StaffGroup',
            Values: ['All'],
          },
          {
            Name: 'DateStart',
            Values: ['2023/11/02'],
          },
          {
            Name: 'DateEnd',
            Values: ['2023/12/18'],
          },
          {
            Name: 'Rate',
            Values: ['All'],
          },
        ],
        locale: 'en-US',
      }
      await embeddingContext.embedDashboard(frameOptions, contentOptions)
    }
    dashboard()
  }
}
application.register('quicksight-dashboard', QuickSightDashboardController)
