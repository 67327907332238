import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'

import ShiftModalWorkerCard from '../../ShiftModalWorkerCard'
import {
  ErrorContainer, ShiftModalDetailFooter, ShiftModalContainer, ShiftModalButton,
  ShiftModalButtonClose, ShiftModalDetailGroup, ShiftModalFormGrid, ShiftModalFormGroup
} from '../../ShiftModalComponents'
import ShiftModalAssign from '../../ShiftModalAssign'

import { signOffShift } from '../../../../../Helpers/ShiftHelpers'

const ShiftSignOff = ({ fetchShift, fetchShifts, handleClose, setSelectedTab, setShiftLoading, shift }) => {
  const [error, setError] = useState(undefined)
  const authorisers = shift?.department?.timesheetAuthorisers
  const [authoriser, setAuthoriser] = useState(undefined);
  const [inputs, setInputs] = useState({
    breaks: shift.breakMinutes,
    proposed_end_time: moment(shift.endTime).format("hh:mm"),
    proposed_start_time: moment(shift.startTime).format("hh:mm"),
    start_day: moment(shift.startTime).format("YYYY-MM-DD"),
  })

  const handleInputChange = (event) => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  }

  const assignAction = (worker) => {
    setAuthoriser(worker)
  }

  const body = {
    shift: {
      breaks: inputs.breaks,
      proposed_end_time: inputs.proposed_end_time,
      proposed_start_time: inputs.proposed_start_time,
      start_day: inputs.start_day,
      sign_off_authoriser_id: authoriser && authoriser.id
    }
  }

  const signOffShiftAction = (e, shiftId) => {
    setShiftLoading(true);
    e.preventDefault()
    signOffShift(shift.id, body).then((data) => {
      if (data !== "err") {
        fetchShift(shiftId);
        setTimeout(() => {
          setSelectedTab("details");
          setShiftLoading(false);
          fetchShifts();
        }, 300)
      } else {
        setShiftLoading(false);
        setError('There was an error signing off the shift, please try again.')
      }
    })
  }

  return (
    <>
      <ShiftModalDetailFooter>
        <div>
          {(error && error) &&
            <ErrorContainer><p>{error}</p></ErrorContainer>
          }
        </div>
        <div>
          <ShiftModalButtonClose onClick={handleClose}>Close</ShiftModalButtonClose>
          <ShiftModalButton theme={'blue'} onClick={signOffShiftAction}>Sign Off</ShiftModalButton>
        </div>
      </ShiftModalDetailFooter>
      <ShiftModalContainer>
        <ShiftModalDetailGroup>
          <SignOffContainer>
            <div>
              <div style={{ marginBottom: 40 }}>
                <h2>Sign Off</h2>
                <ShiftModalFormGrid columns={2}>
                  <ShiftModalFormGroup
                    component={"input"}
                    defaultValue={moment(shift.startTime).format('YYYY-MM-DD')}
                    label={"Start Date"}
                    name="start_day"
                    onChange={handleInputChange}
                    type="date"
                  />
                </ShiftModalFormGrid>
                <ShiftModalFormGrid columns={2}>
                  <ShiftModalFormGroup
                    component={"input"}
                    defaultValue={moment(shift.startTime).format('hh:mm')}
                    label={"Start Time"}
                    name="proposed_start_time"
                    onChange={handleInputChange}
                    type="time"
                  />
                  <ShiftModalFormGroup
                    component={"input"}
                    defaultValue={moment(shift.endTime).format('hh:mm')}
                    label={"End Time"}
                    name="proposed_end_time"
                    onChange={handleInputChange}
                    type="time"
                  />
                </ShiftModalFormGrid>
                <ShiftModalFormGrid columns={2}>
                  <ShiftModalFormGroup
                    component={"input"}
                    defaultValue={shift.breakMinutes}
                    label={"Break Minutes"}
                    name="breaks"
                    onChange={handleInputChange}
                    type="number"
                  />
                </ShiftModalFormGrid>
              </div>
              <div>
                <h2>Select Authoriser</h2>
                <ShiftModalFormGrid columns={1}>
                  {authorisers &&
                    <ShiftModalAssign
                      action={assignAction}
                      data={authorisers}
                      error={error}
                      type={'authoriser'}
                      selectedValue={body.shift.sign_off_authoriser_id}
                    />
                  }
                </ShiftModalFormGrid>
              </div>
            </div>
            <div>
              {shift.bookedBy &&
                <ShiftModalWorkerCard shift={shift} />
              }
            </div>
          </SignOffContainer>
        </ShiftModalDetailGroup>
      </ShiftModalContainer>
    </>
  )
}

export default ShiftSignOff

const SignOffContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 250px;
`