import { gql } from '@apollo/client'

export const GET_AGENCIES = gql`
  query GetAgencies($page: Int, $items: Int, $agencyTitle: String, $archived: Boolean) {
    agencies(page: $page, items: $items, agencyTitle: $agencyTitle, archived: $archived) {
      list {
        id
        canManageUsers
        deactivated
        description
        notificationEmail
        shortTitle
        title
        organisationAgencies {
          organisationId
          organisationStaffGroup {
            staffGroup {
              title
            }
          }
        }
      }
      pagination {
        last
        next
        page
        pages
        prev
      }
    }
  }
`

export const GET_ORGANISATION_AGENCIES = gql`
  query GetOrganisationAgencies($agencyId: Int) {
    organisationAgencies(agencyId: $agencyId) {
      id
      agencyMarginLocked
      canAssignWorker
      canProposeWorker
      canEditChargeRate
      organisationStaffGroup {
        id
        title
        vms
      }
      agencyTiers {
        id
        rank
        organisationStaffGroup {
          id
          title
        }
      }
    }
  }
`

export const GET_ORGANISATION_STAFF_GROUPS = gql`
  query GetOrganisationStaffGroups($organisationId: Int!) {
    organisationStaffGroups(organisationId: $organisationId) {
      id
      agencyTiers {
        id
        rank
      }
      defaultAgencyTier {
        id
      }
      title
      vms
    }
  }
`

export const CREATE_ORGANISATION_AGENCY = gql`
  mutation CreateOrganisationAgency($agencyId: Int!, $agencyTierIds: [Int!]!, $organisationStaffGroupId: Int!) {
    createOrganisationAgency(
      agencyId: $agencyId
      agencyTierIds: $agencyTierIds
      organisationStaffGroupId: $organisationStaffGroupId
    ) {
      errors {
        attribute
        message
      }
      organisationAgency {
        id
      }
    }
  }
`

export const DELETE_ORGANISATION_AGENCY = gql`
  mutation DeleteOrganisationAgency($id: Int!) {
    deleteOrganisationAgency(id: $id) {
      errors {
        attribute
        message
      }
    }
  }
`

export const REACTIVATE_AGENCY = gql`
  mutation ReactivateAgency($id: Int!) {
    reactivateAgency(id: $id) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const DEACTIVATE_AGENCY = gql`
  mutation DeactivateAgency($id: Int!) {
    archiveAgency(id: $id) {
      errors {
        attribute
        message
      }
      success
    }
  }
`

export const UPDATE_AGENCY = gql`
  mutation UpdateAgency(
    $id: Int!
    $title: String!
    $shortTitle: String
    $canManageUsers: Boolean
    $description: String
    $notificationEmail: String
  ) {
    updateAgency(
      id: $id
      title: $title
      shortTitle: $shortTitle
      canManageUsers: $canManageUsers
      description: $description
      notificationEmail: $notificationEmail
    ) {
      agency {
        id
      }
      errors {
        attribute
        message
      }
    }
  }
`

export const UPDATE_ORGANISATION_AGENCY = gql`
  mutation UpdateOrganisationAgency(
    $id: Int!
    $agencyMarginLocked: Boolean
    $agencyTierIds: [Int!]!
    $canAssignWorker: Boolean
    $canProposeWorker: Boolean
    $canEditChargeRate: Boolean
  ) {
    updateOrganisationAgency(
      id: $id
      agencyMarginLocked: $agencyMarginLocked
      agencyTierIds: $agencyTierIds
      canAssignWorker: $canAssignWorker
      canProposeWorker: $canProposeWorker
      canEditChargeRate: $canEditChargeRate
    ) {
      errors {
        attribute
        message
      }
      organisationAgency {
        id
      }
    }
  }
`
