import { useMutation } from '@apollo/client'
import { DefaultButton } from '@patchworkhealth/web-components'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { BULK_APPROVE_FOR_PAYMENT } from '../helpers/ReceiptsHelpers'

function ItemisedReceiptsActionBar({ selectedRows, setSelectedRows, refetchReceipts }) {
  const [barVisible, setBarVisible] = useState(false)
  const [allCanBeApproved, setAllCanBeApproved] = useState(true)
  const [bulkApprove] = useMutation(BULK_APPROVE_FOR_PAYMENT, {})

  const onSelectReceipts = () => {
    if (selectedRows.every((row) => row.status === 'pending_approval')) {
      setAllCanBeApproved(true)
    } else {
      setAllCanBeApproved(false)
    }
  }

  useEffect(() => {
    if (selectedRows.length > 0) {
      setBarVisible(true)
      onSelectReceipts()
    } else {
      setBarVisible(false)
    }
  }, [selectedRows])

  const onApproveReceipts = async () => {
    const ids = selectedRows.map((row) => row.receiptId)
    await bulkApprove({
      variables: {
        receiptIds: ids,
      },
      onCompleted: (res) => {
        if (res.paidPerItemReceiptsBulkApproveForPayment.success) {
          toast.success(`Receipt${selectedRows.length === 1 ? `` : `s`} approved for payment`)
        } else {
          const [error] = res.paidPerItemReceiptsBulkApproveForPayment.errors
          toast.error(`${error.attribute} ${error.message}`)
        }
        refetchReceipts()
      },
      onError: (error) => {
        toast.error(error.message)
      },
    })
    setSelectedRows([])
  }

  return (
    <ActionBar show={barVisible}>
      <ReceiptCount>{`${selectedRows.length} receipt${selectedRows.length === 1 ? `` : `s`}`} selected.</ReceiptCount>
      <Controls>
        <DisableableButton
          action={onApproveReceipts}
          disabled={!allCanBeApproved}
          text="Approve for Payment"
          type="white"
        />
        <ControlMessage show={!allCanBeApproved}>
          Only receipts pending approval can be approved for payment
        </ControlMessage>
      </Controls>
      <Empty></Empty>
    </ActionBar>
  )
}

export default ItemisedReceiptsActionBar

const ActionBar = styled.div`
  bottom: ${({ show }) => (show ? '0px' : '-92px')};
  position: ${({ show }) => (show ? 'absolute' : 'fixed')};
  width: 100%;
  background: #1e2d36;
  height: 92px;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: #fff;
  transition: bottom 0.5s ease;
  z-index: 1041;
`

const ReceiptCount = styled.p`
  color: #959ca0;
  margin: 0;
  margin-left: 12px;
  flex: 1;
`

const Controls = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 26px;
`
const Empty = styled.div`
  flex: 1;
`

const ControlMessage = styled.p`
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  color: #959ca0;
  font-size: 0.75rem;
`

const DisableableButton = styled(DefaultButton)`
  &:disabled {
    background-color: hsl(0, 0%, 80%);
    > span {
      color: hsl(0, 0%, 60%);
    }
  }
`
