import { Controller } from "stimulus";

export default class Activations extends Controller {

    // reject applicant function
    rejectApplicant() {
        const query = `mutation RejectInternal($worker_id: Int!, $organisation_id: Int!){
            rejectInternal(workerId: $worker_id, organisationId: $organisation_id)
        }`;

        const organisation_id = parseInt(this.data.get("organisation_id"));
        const worker_id = parseInt(this.data.get("worker_id"));

        // fetch call
        fetch("/graphql", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            body: JSON.stringify({
                query,
                variables: { worker_id, organisation_id },
            }),
        })
            .then((response) => {
                console.log(response);
                if (response.status != 200) {
                    console.log("error");
                } else if (response.status == 200) {
                    window.location.reload(true);
                }
            })
            .catch((err) => {
                console.log("Fetch Error", err);
            });
    }
}

import { application } from "../../frontend/controllers/index";
application.register("activations", Activations);
