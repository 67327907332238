import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import Modal from "react-bootstrap/Modal";

import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { ErrorContainer } from "../Forms/FormElements";
import { DELETE_SKILL } from "./SkillSetsHelpers";

const ConfirmModal = ({ handleCloseConfirm, refetch, skill, show }) => {
  const [error, setError] = useState({ skill: "" });

  const [deleteSkill] = useMutation(DELETE_SKILL);

  const deleteSkillAction = (skillId) => {
    setError({});
    deleteSkill({
      variables: {
        id: Number(skillId),
      },
    }).then((data) => {
      if (data.data.deleteSkill.success === true) {
        refetch();
        handleCloseConfirm();
      } else {
        setError({
          error: true,
          message: `There was a problem deleting this skill.`,
        });
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseConfirm}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>Confirm Delete {skill?.name}</ModalTitle>
        <CloseButton onClick={() => handleCloseConfirm()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ fontSize: 20, minHeight: 0 }}>
        Are you sure you want to delete the skill, {skill?.name}?
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleCloseConfirm}>
            Cancel
          </button>
          <button
            className="btn text-white btn-salmon"
            onClick={() => deleteSkillAction(skill.id)}
          >
            Delete Skill
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
