/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/no-cycle */
import {
  DefaultButton,
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  FormGroup,
  FormInput,
  FormLabel,
  FormGrid,
  FormSelect,
} from '@patchworkhealth/web-components'
import React, { useState } from 'react'
import 'react-day-picker/lib/style.css'
import Modal from 'react-bootstrap/Modal'
import { modHelperTextFunction, FormGroupMod } from '../Modals/CreateBlockModal'
import { ShiftCard } from '../Modals/ViewBlockDetails'
import { calculateHours, RateCardChange, calculateMinimumBreaks } from './TimesheetHelpers'

/* -------------------------------------------------------------------------
Sign Off Modal 
 ---------------------------------------------------------------------------*/

export function TimesheetsSignOffModal({
  setInputs,
  inputs,
  requestedShifts,
  expectedHours,
  authoriser,
  signOffHandler,
  modOrganisation,
}) {
  // internal state

  const [signOffTab, setSignOffTab] = useState(1)

  const closeSignoffModal = () => {
    setSignOffTab(1)
    setInputs({
      ...inputs,
      showSignOffModal: false,
      approverNote: '',
    })
  }

  const checkBreakReason = (shifts) => {
    const breakExceptionReasons = shifts.filter((s) => {
      const minBreaks = calculateMinimumBreaks(s)
      const showBreakExceptionReason = s.shouldEnforceBreaks && s.workerSignOff?.reportedBreaks < minBreaks
      return showBreakExceptionReason
    })

    if (breakExceptionReasons.length === 0) return null

    const handleBreakExceptionNotesChange = (e, breakIds) => {
      const updatedTimesheet = inputs.activeTimesheet.map((j) =>
        breakIds.includes(j.id) ? { ...j, breaksExceptionNotes: e.target.value } : j
      )

      setInputs({
        ...inputs,
        activeTimesheet: updatedTimesheet,
      })
    }
    const breakIds = breakExceptionReasons.map((s) => s.id)

    return (
      <FormGroup style={{ marginTop: 12 }}>
        <FormLabel>Enter Break Exception Reason for {breakExceptionReasons.length} shift(s)</FormLabel>
        <FormInput
          defaultValue=""
          onChange={(e) => handleBreakExceptionNotesChange(e, breakIds)}
          placeholder="Break exception Reason"
          type="text"
        />
      </FormGroup>
    )
  }

  const handleAuthoriserNoteChange = (e) => {
    const updatedShifts = inputs.activeTimesheet?.map((s) => {
      if (s.status === 'SIGN_OFF_REQUESTED') {
        return {
          ...s,
          workerSignOff: {
            ...s.workerSignOff,
            confirmationNotes: e.target.value,
          },
        }
      }
      return s
    })

    setInputs({
      ...inputs,
      activeTimesheet: updatedShifts,
    })
  }

  const difference = calculateHours('SIGN_OFF_REQUESTED', inputs.activeTimesheet) - expectedHours
  let isConfirmButtonDisabled = true

  const renderConfirmButton = () => {
    if (difference === 0) {
      isConfirmButtonDisabled = inputs.authoriserValue === null
    } else {
      isConfirmButtonDisabled =
        inputs.authoriserValue === null || requestedShifts[0]?.workerSignOff?.confirmationNotes === ''
    }

    //  If MOD is enabled, the confirm button should be disabled until the MOD declaration statement is checked

    if (modOrganisation) {
      isConfirmButtonDisabled = isConfirmButtonDisabled || !inputs.modDeclarationStatement
    }

    const confirmButtonStyle = {
      opacity: isConfirmButtonDisabled ? 0.5 : 1,
      pointerEvents: isConfirmButtonDisabled ? 'none' : 'initial',
    }

    return (
      <div style={confirmButtonStyle}>
        <DefaultButton action={signOffHandler} color="blue" disabled={isConfirmButtonDisabled} text="Confirm" />
      </div>
    )
  }

  const handleCheckbox = () => {
    setInputs({
      ...inputs,
      modDeclarationStatement: !inputs.modDeclarationStatement,
    })
  }

  return (
    <Modal backdrop="static" centered onHide={closeSignoffModal} show={inputs.showSignOffModal} size="lg">
      <ModalHeader>
        <ModalTitle>Sign Off {requestedShifts?.length} Shift(s)</ModalTitle>
        <CloseButton onClick={closeSignoffModal} />
      </ModalHeader>

      {signOffTab === 1 && (
        <>
          <div style={{ padding: 22 }}>
            <FormGrid columns={3}>
              <ShiftCard>
                <FormLabel> {requestedShifts?.length} Scheduled Shifts</FormLabel>
                <p>{expectedHours} (hrs)</p>
              </ShiftCard>

              <ShiftCard>
                <FormLabel>Worked</FormLabel>
                <p>{calculateHours('SIGN_OFF_REQUESTED', inputs.activeTimesheet)} (hrs)</p>
              </ShiftCard>

              {inputs.activeTimesheet?.length > 0 && (
                <ShiftCard>
                  <FormLabel>Difference</FormLabel>
                  <RateCardChange>
                    {(calculateHours('SIGN_OFF_REQUESTED', inputs.activeTimesheet) - expectedHours).toFixed(2)} hrs
                  </RateCardChange>
                </ShiftCard>
              )}
            </FormGrid>

            <FormGrid columns={2} style={{ marginTop: 40 }}>
              <FormGroup>
                <FormLabel>
                  Select Authoriser <span>*</span>
                </FormLabel>
                <FormSelect
                  isClearable
                  onChange={(e) => {
                    setInputs({ ...inputs, authoriserValue: e })
                  }}
                  options={authoriser?.department?.timesheetAuthorisers}
                  placeholder="Select Authoriser"
                  value={inputs.authoriserValue}
                />
              </FormGroup>
              <FormGroupMod>
                {difference === 0 && <FormLabel>Authoriser Note {modHelperTextFunction(13, true)}</FormLabel>}
                {difference !== 0 && (
                  <FormLabel>
                    Authoriser Note <span>*</span> {modHelperTextFunction(13, true)}
                  </FormLabel>
                )}
                <FormInput
                  onChange={handleAuthoriserNoteChange}
                  placeholder="Enter Confirmation Notes"
                  type="text"
                  value={requestedShifts && requestedShifts[0]?.workerSignOff?.confirmationNotes}
                />
              </FormGroupMod>
            </FormGrid>

            {checkBreakReason(requestedShifts)}
          </div>
          <ModalFooter style={{ position: 'relative' }}>
            <div />

            {modOrganisation && (
              <div
                style={{
                  position: 'absolute',
                  left: '0px',
                  top: '36px',
                  color: 'black',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <input
                  checked={inputs.modDeclarationStatement}
                  id="checkbox"
                  onChange={handleCheckbox}
                  style={{ height: 30, width: 22, marginRight: 6, cursor: 'pointer' }}
                  type="checkbox"
                />
                I have read and accepted the MOD compliance statement
                <button
                  onClick={() => {
                    setSignOffTab(2)
                  }}
                  style={{
                    fontWeight: 'bold',
                    color: '#537694',
                    border: 0,
                    background: 0,
                    textDecoration: 'underline',
                  }}
                  type="button"
                >
                  here
                </button>
              </div>
            )}

            <div style={{ display: 'flex' }}>
              <DefaultButton action={closeSignoffModal} text="Cancel" type="white" />
              {renderConfirmButton()}
            </div>
          </ModalFooter>
        </>
      )}

      {signOffTab === 2 && (
        <>
          <div style={{ padding: 22 }}>
            <p>
              I certify I have checked and the information on this timesheet is correct and reflects the actual hours
              worked and actual breaks taken by the temporary worker. I understand that if I knowingly approve false
              information this may result in disciplinary action and I may be liable for prosecution and civil recovery
              proceedings.
            </p>
          </div>
          <ModalFooter style={{ position: 'relative' }}>
            <div />
            <DefaultButton action={() => setSignOffTab(1)} text="Back" type="white" />
          </ModalFooter>
        </>
      )}
    </Modal>
  )
}

/* --------------------------------------------------------------------------
  Approval Modal 
 ---------------------------------------------------------------------------*/

export function TimesheetsApproveModal({ setInputs, inputs, approvedShifts, approveHandler }) {
  const closeApprovalModal = () => {
    setInputs({
      ...inputs,
      showApprovalModal: false,
      approverNote: '',
    })
  }

  const calculateDifference = () => {
    const expectedHours = inputs.activeTimesheets?.filter((row) => row.id === inputs.expandedRow)[0]?.hoursExpected ?? 0
    const workedHours = calculateHours('SIGNED_OFF', approvedShifts)
    return (workedHours - expectedHours).toFixed(2)
  }

  const difference = calculateDifference()

  const renderApproverNoteLabel = () => {
    if (difference !== '0.00') {
      return (
        <FormLabel>
          Approver Note <span>*</span> {modHelperTextFunction(13, false)}
        </FormLabel>
      )
    }
    return (
      <FormLabel>
        Approver Note
        {modHelperTextFunction(13, false)}
      </FormLabel>
    )
  }

  const isConfirmButtonDisabled = difference !== '0.00' && inputs.approverNote === ''

  return (
    <Modal backdrop="static" centered onHide={closeApprovalModal} show={inputs.showApprovalModal} size="lg">
      <ModalHeader>
        <ModalTitle>Approve {approvedShifts.length} Shift(s)</ModalTitle>
        <CloseButton onClick={closeApprovalModal} />
      </ModalHeader>
      <ModalBody style={{ minHeight: '200px' }}>
        <FormGrid columns={3}>
          <ShiftCard>
            <FormLabel>{approvedShifts?.length} Scheduled Shifts</FormLabel>
            <p>
              {inputs?.activeTimesheets?.filter((row) => row.id === inputs.expandedRow)[0]?.hoursExpected ?? 0} (hrs)
            </p>
          </ShiftCard>

          <ShiftCard>
            <FormLabel>Worked</FormLabel>
            <p>{calculateHours('SIGNED_OFF', approvedShifts)} (hrs)</p>
          </ShiftCard>

          {inputs.activeTimesheet?.length > 0 && (
            <ShiftCard>
              <FormLabel>Difference</FormLabel>
              <RateCardChange>{difference} hrs</RateCardChange>
            </ShiftCard>
          )}

          <FormGroupMod style={{ marginTop: 12 }}>
            {renderApproverNoteLabel()}

            <FormInput
              onChange={(e) => {
                setInputs({
                  ...inputs,
                  approverNote: e.target.value,
                })
              }}
              placeholder="Approval note"
              type="text"
              value={inputs.approverNote}
            />
          </FormGroupMod>
        </FormGrid>
      </ModalBody>
      <ModalFooter>
        <div />
        <div style={{ display: 'flex' }}>
          <DefaultButton action={closeApprovalModal} text="Cancel" type="white" />
          <div
            style={{
              opacity: difference !== '0.00' && inputs.approverNote === '' ? 0.5 : 1,
              pointerEvents: difference !== '0.00' && inputs.approverNote === '' ? 'none' : 'initial',
            }}
          >
            <DefaultButton action={approveHandler} color="blue" disabled={isConfirmButtonDisabled} text="Confirm" />
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
