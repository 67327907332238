import React, { useEffect, useState } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";

import Form from "react-bootstrap/Form";

import {
  ErrorContainer,
  FormDetailGroup,
  FormGrid,
  FormGroup,
} from "../Forms/FormElements";
import {
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../Modals/ModalElements";
import { createGrade, editGrade } from "./GradesHelpers";

const GradesModal = ({ handleClose, show, grades, data1 }) => {
  const [error, setError] = useState({ name: "" });
  const [inputs, setInputs] = useState({
    title: "",
    basic_rate: "",
    basic_rate_unsocial: "",
    enhanced_rate: "",
    enhanced_rate_unsocial: "",
    night_start: "",
    night_end: "",
    default_break_minutes: "",
    break_repeat_minutes: "",
    agency_cap: "",
    staff_group: "",
    auto_apply_unsocial_night_rates: false,
    core_weekends_and_bank_holidays: false,
    use_default_breaks: false,
  });

  console.log({ grades, data1 });

  let organisations = data1?.organisationStaffGroups?.map((item, index) => {
    return {
      label: item.title,
      value: item.title,
    };
  });

  console.log(organisations);

  useEffect(() => {
    if (grades) {
      setInputs({
        title: grades.title ? grades.title : "",
        basic_rate: grades.basic_rate ? grades.basic_rate : "",
        basic_rate_unsocial: grades.basic_rate_unsocial
          ? grades.basic_rate_unsocial
          : "",
        enhanced_rate: grades.enhanced_rate ? grades.enhanced_rate : "",
        enhanced_rate_unsocial: grades.enhanced_rate_unsocial
          ? grades.enhanced_rate_unsocial
          : "",
        night_start: grades.night_start ? grades.night_start : "",
        night_end: grades.night_end ? grades.night_end : "",
        default_break_minutes: grades.default_break_minutes
          ? grades.default_break_minutes
          : "",
        break_repeat_minutes: grades.break_repeat_minutes
          ? grades.break_repeat_minutes
          : "",
        staff_group: grades.staff_group?.title ? grades.staff_group.title : "",
        agency_cap: grades.agency_cap ? grades.agency_cap : "",
        auto_apply_unsocial_night_rates: grades.auto_apply_unsocial_night_rates
          ? grades.auto_apply_unsocial_night_rates
          : false,
        core_weekends_and_bank_holidays: grades.core_weekends_and_bank_holidays
          ? grades.core_weekends_and_bank_holidays
          : false,
        use_default_breaks: grades.use_default_breaks
          ? grades.use_default_breaks
          : false,

        // latitude: grades.latitude ? grades.latitude : "",
        // longitude: grades.longitude ? grades.longitude : "",
        // maps_url: grades.maps_url ? grades.maps_url : "",
      });
    }

    console.log(typeof inputs.auto_apply_unsocial_night_rates);
  }, [grades]);

  const handleCloseAction = () => {
    setInputs({});
    setError({});
    handleClose();
  };

  const handleInputChange = (event) => {
    console.log(event.target.value);
    setError({});
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckbox = (event) => {
    console.log(event.target.value, event.target.checked);

    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.checked,
    }));
    console.log(inputs, typeof inputs.auto_apply_unsocial_night_rates);
  };

  const createGradeAction = () => {
    setError({});

    if (inputs) {
      const body = {
        site: {
          title: inputs.title,
          basic_rate: inputs.basic_rate,
          basic_rate_unsocial: inputs.basic_rate_unsocial,
          enhanced_rate: inputs.enhanced_rate,
          enhanced_rate_unsocial: inputs.enhanced_rate_unsocial,
          night_start: inputs.night_start,
          night_end: inputs.night_end,
          default_break_minutes: inputs.default_break_minutes,
          break_repeat_minutes: inputs.break_repeat_minutes,
          agency_cap: inputs.agency_cap,
          auto_apply_unsocial_night_rates:
            inputs.auto_apply_unsocial_night_rates,
          core_weekends_and_bank_holidays:
            inputs.core_weekends_and_bank_holidays,
          use_default_breaks: inputs.use_default_breaks,
        },
      };

      createGrade(body)
        .then((data) => {
          console.log(data);
          if (data !== "err") {
            console.log("NOOO...");
          } else {
            setError({
              error: true,
              message: `There was a problem creating this Site.`,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    }
  };

  const editsiteAction = (siteId) => {
    console.log("eitd:", siteId);
    setError({});
    // if (inputs.name === "") {
    //   setError({ ...error, name: "Please enter a name." });
    // } else if (!isLatitude(inputs.latitude)) {
    //   setError({
    //     ...error,
    //     latitude: "Enter correct Latitude.",
    //   });
    // } else if (!isLongitude(inputs.longitude)) {
    //   setError({
    //     ...error,
    //     longitude: "Enter Correct Longitude",
    //   });
    // } else {
    const body = {
      grade: {
        id: siteId,
        staff_group_id: 1,
        title: inputs.title,
        basic_rate: inputs.basic_rate,
        basic_rate_unsocial: inputs.basic_rate_unsocial,
        enhanced_rate: inputs.enhanced_rate,
        enhanced_rate_unsocial: inputs.enhanced_rate_unsocial,
        night_shift_start_time: "20:00",
        night_shift_end_time: "08:00",
        filter_grade_id: 2,
        default_break_minutes: inputs.default_break_minutes,
        break_repeat_minutes: inputs.break_repeat_minutes,
        agency_cap: inputs.agency_cap,
        auto_apply_unsocial_night_rates: inputs.auto_apply_unsocial_night_rates,
        core_weekends_and_bank_holidays: true,
        use_default_breaks: inputs.use_default_breaks,
      },
    };

    //     grade[title]: FY2
    // grade[staff_group_id]: 1
    // grade[colour]: #17b8c2
    // grade[basic_rate]: 40.0
    // grade[basic_rate_unsocial]: 45.01
    // grade[enhanced_rate]: 50.0
    // grade[enhanced_rate_unsocial]: 55.0
    // grade[night_shift_start_time]: 20:00
    // grade[night_shift_end_time]: 08:00
    // grade[core_weekends_and_bank_holidays]: false
    // grade[default_break_minutes]: 30
    // grade[break_repeat_minutes]: 360
    // grade[use_default_breaks]: false
    // grade[use_default_breaks]: true
    // grade[agency_cap]: 50.0
    // grade[filter_grade_id]: 2
    // grade[auto_apply_unsocial_night_rates]: false
    // grade[auto_apply_unsocial_night_rates]: true

    console.log(body);
    editGrade(siteId, body).then((data) => {
      console.log({ data });
      if (data !== "err") {
        // window.location.reload(true);
      } else {
        setError({
          error: true,
          message: `There was a problem updating this Site.`,
        });
      }
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleCloseAction}
      backdrop="static"
      centered
      size="lg"
    >
      <ModalHeader>
        <ModalTitle>{grades ? `Edit ${grades.title}` : "Add Grade"}</ModalTitle>
        <CloseButton onClick={() => handleCloseAction()}>
          <svg width="18" height="18" xmlns="http:www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ minHeight: 0, overflowY: "scroll" }}>
        <SiteForm>
          <FormDetailGroup>
            <h2>Grade Info</h2>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.title}
                placeholder={"Latitude"}
                label={"Title"}
                name="title"
                onChange={handleInputChange}
                type="text"
                // error={error.latitude}
              />
              <FormGroup
                component={"react-select"}
                label={"Staff Group"}
                options={organisations}
                placeholder="Select Staff Group"
                name="staff_group"
                onChange={handleInputChange}
                value={"Hello"}
                // disabled={agencyUserPermission ? true : false}
              />
            </FormGrid>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.basic_rate}
                placeholder={""}
                label={"STANDARD CORE RATE (/HR)"}
                name="basic_rate"
                onChange={handleInputChange}
                type="number"
                // error={error.latitude}
              />
              <FormGroup
                component={"input"}
                defaultValue={inputs.basic_rate_unsocial}
                placeholder={"Longitude"}
                label={"STANDARD UNSOCIAL RATE (/HR)"}
                name="basic_rate_unsocial"
                onChange={handleInputChange}
                type="number"
                // error={error.longitude}
              />
            </FormGrid>
            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.enhanced_rate}
                placeholder={"Latitude"}
                label={"ENHANCED CORE RATE (/HR)"}
                name="enhanced_rate"
                onChange={handleInputChange}
                type="text"
                // error={error.latitude}
              />
              <FormGroup
                component={"input"}
                defaultValue={inputs.enhanced_rate_unsocial}
                placeholder={"Longitude"}
                label={"ENHANCED UNSOCIAL RATE (/HR)"}
                name="enhanced_rate_unsocial"
                onChange={handleInputChange}
                type="text"
                // error={error.longitude}
              />
            </FormGrid>
          </FormDetailGroup>
          <FormDetailGroup>
            <h2>Grade Preferences</h2>

            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.night_start}
                // defaultValue={moment(inputs.night_start).format("MMMM Do YYYY")}
                placeholder={"Latitude"}
                label={"NIGHT SHIFT START TIME"}
                name="night_start"
                onChange={handleInputChange}
                type="time"
                // error={error.latitude}
              />
              <FormGroup
                component={"input"}
                defaultValue={inputs.night_end}
                placeholder={"Longitude"}
                label={"NIGHT SHIFT END TIME"}
                name="night_end"
                onChange={handleInputChange}
                type="time"
                // error={error.longitude}
              />
            </FormGrid>

            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.default_break_minutes}
                placeholder={"Latitude"}
                label={"DEFAULT BREAK MINUTES"}
                name="default_break_minutes"
                onChange={handleInputChange}
                type="text"
                // error={error.latitude}
              />
              <FormGroup
                component={"input"}
                defaultValue={inputs.break_repeat_minutes}
                placeholder={"Longitude"}
                label={"BREAK REPEAT MINUTES"}
                name="break_repeat_minutes"
                onChange={handleInputChange}
                type="text"
                // error={error.longitude}
              />
            </FormGrid>

            <FormGrid columns={2}>
              <FormGroup
                component={"input"}
                defaultValue={inputs.agency_cap}
                placeholder={"Latitude"}
                label={"AGENCY CAP"}
                name="agency_cap"
                onChange={handleInputChange}
                type="text"
                // error={error.latitude}
              />
              <FormGroup
                component={"react-select"}
                label={"Staff Group"}
                options={[
                  {
                    label: "Medical",
                    value: "Medical",
                  },
                  {
                    label: "Social",
                    value: "Social",
                  },
                  {
                    label: "Hospital",
                    value: "Hospital",
                  },
                ]}
                placeholder="Select Staff Group"
                name="staff_group_id"
                // onChange={handleStaffGroupChange}
                // defaultValue={
                //   agencyUserPermission && {
                //     label: agencyUserPermission.organisationStaffGroup.title,
                //     value: agencyUserPermission.organisationStaffGroup.id,
                //   }
                // }
                // disabled={agencyUserPermission ? true : false}
              />
            </FormGrid>

            <FormGrid columns={1}>
              <Form.Check
                type="switch"
                id="custom-switch1"
                label="Core Weekends and Bank Holidays"
                checked={inputs.core_weekends_and_bank_holidays}
                onChange={handleCheckbox}
                name="core_weekends_and_bank_holidays"
              />
              <Form.Check
                type="switch"
                id="custom-switch2"
                label="Use Default Breaks"
                checked={inputs.use_default_breaks}
                onChange={handleCheckbox}
                name="use_default_breaks"
              />
              <Form.Check
                type="switch"
                id="custom-switch3"
                label="Auto Apply Unsocial Night Rates"
                name="auto_apply_unsocial_night_rates"
                onChange={handleCheckbox}
                checked={inputs.auto_apply_unsocial_night_rates}
              />
            </FormGrid>
          </FormDetailGroup>
        </SiteForm>
      </ModalBody>
      <ModalFooter>
        <div>
          {error && error.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleCloseAction}>
            Cancel
          </button>
          {grades ? (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => editsiteAction(grades.id)}
            >
              Save Changes
            </button>
          ) : (
            <button
              className="btn text-white btn-cyan--modal"
              onClick={() => createGradeAction()}
            >
              Add Grade
            </button>
          )}
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default GradesModal;

const SiteForm = styled.div`
  /* max-width: 100; */
`;

function isLatitude(lat) {
  return isFinite(lat) && Math.abs(lat) <= 90;
}

function isLongitude(lng) {
  return isFinite(lng) && Math.abs(lng) <= 180;
}
