/* eslint-disable import/order */
/* eslint-disable import/no-duplicates */
/* eslint-disable react/function-component-definition */
/* eslint-disable func-names */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-sort-props */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import Modal from 'react-bootstrap/Modal'

import { ErrorContainer } from '../../../Forms/FormElements'
import { CloseButton, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../../Modals/ModalElements'
import { ShiftModalReactSelect } from '../../../Forms/FormElements'

import { SEND_TO_AGENCY_TIERS } from './SendToAgencyHelpers'

const SendToAgencyModal = ({ handleClose, show, shift }) => {
  const [error, setError] = useState({})
  const [tierId, setTierId] = useState(undefined)
  const [sendToAgencyTiers] = useMutation(SEND_TO_AGENCY_TIERS)

  // remap tiers into an array react-select can read
  const tiers =
    shift?.shift.grade.organisationStaffGroup.agencyTiers.map(function (tier) {
      return { value: tier.id, label: tier.rank }
    }) || []

  useEffect(() => {
    if (shift?.shift.grade.organisationStaffGroup.vms !== true) {
      setTierId(shift?.shift.grade.organisationStaffGroup.defaultAgencyTier)
    }
  }, [setTierId])

  const handleTierChange = (event) => {
    setTierId(Number(event.value))
  }

  const checkedShifts = document.querySelector('.list')
  const shifts = Array.from(checkedShifts.querySelectorAll('.custom-control-input:checked')).map((item) =>
    Number(item.value)
  )

  const sendToAgencyTiersAction = () => {
    setError({})
    sendToAgencyTiers({
      variables: {
        shiftIds: shifts,
        agencyTierId: Number(tierId),
      },
    }).then((data) => {
      if (data.data.bulkSendToAgencyTier.success === true) {
        window.location.reload(true)
      } else {
        setError({
          error: true,
          message: `There was a problem sending shifts to Agency Tiers.`,
        })
      }
    })
  }

  const findSelectedId = tiers?.find((tier) => Number(tier.value) === tierId)

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" centered size={'lg'}>
      <ModalHeader>
        <ModalTitle>Send to Agency</ModalTitle>
        <CloseButton onClick={handleClose}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
              fill="#9CA8B4"
              fillRule="evenodd"
            />
          </svg>
        </CloseButton>
      </ModalHeader>
      <ModalBody style={{ fontSize: 20, minHeight: 0, overflowY: 'visible' }}>
        {/* SAM ----------------------------------------------------------------- */}
        {shift?.shift.grade.organisationStaffGroup.vms !== true ? (
          <p>
            Are you sure you want to send shift{' '}
            {shifts?.map((shiftId) => (
              <React.Fragment key={shiftId}>#{shiftId}, </React.Fragment>
            ))}{' '}
            to Agency?
          </p>
        ) : (
          // PAM ----------------------------------------------------------------
          <>
            <p>
              Select the tier that you would like to send shift{' '}
              {shifts?.map((shiftId, index) => (
                <React.Fragment key={shiftId}>
                  #{shiftId}
                  {shifts.length - 1 !== index && ', '}
                </React.Fragment>
              ))}
              .
            </p>
            <div style={{ maxWidth: 200 }}>
              <ShiftModalReactSelect
                className={`react-select-container`}
                classNamePrefix="react-select"
                isClearable={true}
                options={tiers}
                placeholder="Select a Tier"
                onChange={handleTierChange}
              />
            </div>

            {shift?.shift?.autoCascadeTriggers?.length > 0 && (
              <div style={{ marginBottom: 24 }}>
                {findSelectedId && findSelectedId.label > shift.shift.autoCascadeTriggers[0].toTier && (
                  <div
                    style={{
                      border: '1px solid #e74c3c',
                      padding: 12,
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 22,
                    }}
                  >
                    <svg fill="none" height="21" viewBox="0 0 24 21" width="24" xmlns="http://www.w3.org/2000/svg">
                      <path
                        clipRule="evenodd"
                        d="M10.528 1.28312C10.9773 1.03017 11.4842 0.897278 11.9998 0.897278C12.5154 0.897278 13.0223 1.03017 13.4716 1.28312C13.9209 1.53607 14.2974 1.90055 14.5648 2.34139L14.5677 2.34614L23.0376 16.4862L23.0458 16.5C23.3077 16.9536 23.4463 17.4679 23.4478 17.9917C23.4493 18.5156 23.3135 19.0307 23.0541 19.4858C22.7947 19.9408 22.4207 20.3201 21.9692 20.5857C21.5177 20.8514 21.0046 20.9942 20.4808 21L20.4698 21.0001L3.51879 21C2.99498 20.9943 2.48182 20.8514 2.03035 20.5857C1.57887 20.3201 1.20483 19.9408 0.945426 19.4858C0.686022 19.0307 0.550303 18.5156 0.55177 17.9917C0.553236 17.4679 0.691839 16.9536 0.953786 16.5L0.961909 16.4862L9.43191 2.34615L10.2898 2.86001L9.43478 2.34139C9.70218 1.90055 10.0787 1.53607 10.528 1.28312ZM11.146 3.37666L2.68246 17.5058C2.59729 17.6556 2.55224 17.8249 2.55176 17.9973C2.55127 18.172 2.59651 18.3436 2.68298 18.4953C2.76945 18.647 2.89413 18.7735 3.04462 18.862C3.1938 18.9498 3.36317 18.9973 3.53617 19H20.4634C20.6364 18.9973 20.8058 18.9498 20.9549 18.862C21.1054 18.7735 21.2301 18.647 21.3166 18.4953C21.403 18.3436 21.4483 18.172 21.4478 17.9973C21.4473 17.825 21.4023 17.6557 21.3171 17.5059L12.8548 3.37865L12.8536 3.37666C12.7645 3.23061 12.6395 3.10983 12.4904 3.02589C12.3406 2.94157 12.1716 2.89728 11.9998 2.89728C11.8279 2.89728 11.6589 2.94157 11.5092 3.02589C11.3601 3.10983 11.235 3.23061 11.146 3.37666ZM11.9998 7.00002C12.5521 7.00002 12.9998 7.44773 12.9998 8.00002V12C12.9998 12.5523 12.5521 13 11.9998 13C11.4475 13 10.9998 12.5523 10.9998 12V8.00002C10.9998 7.44773 11.4475 7.00002 11.9998 7.00002ZM11.9998 15C11.4475 15 10.9998 15.4477 10.9998 16C10.9998 16.5523 11.4475 17 11.9998 17H12.0098C12.5621 17 13.0098 16.5523 13.0098 16C13.0098 15.4477 12.5621 15 12.0098 15H11.9998Z"
                        fill="#E6515D"
                        fillRule="evenodd"
                      />
                    </svg>
                    <div style={{ marginLeft: 12 }}>
                      <p
                        style={{
                          fontSize: '14px',
                          fontWeight: 'bold',
                          margin: 0,
                        }}
                      >
                        You are about to override your departments rules for autocascading to agency.
                      </p>
                      <span style={{ display: 'block', fontSize: 14 }}>
                        Cascade trigger to be sent to Tier ({shift.shift.autoCascadeTriggers[0].toTier})
                      </span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <div>
          {error?.message && (
            <ErrorContainer>
              <p>{error.message}</p>
            </ErrorContainer>
          )}
        </div>
        <div>
          <button className="btn btn-close" onClick={handleClose}>
            Cancel
          </button>
          <button className="btn text-white btn-cyan--modal" onClick={() => sendToAgencyTiersAction()}>
            Send to Agency
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default SendToAgencyModal
