import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";

import {
  fetchShift,
  watchShift,
  unwatchShift,
} from "../../../Helpers/ShiftHelpers";
import { statusMap } from "../../../Helpers/StatusHelpers";

import {
  ErrorContainer,
  ModalContentWrapper,
  ModalContentContainer,
  ShiftModalMenu,
  ShiftModalMenuOption,
} from "./ShiftModalComponents";

import ShiftModalAmendAgencyBooking from "./Tabs/AmendBooking/ShiftModalAmendAgencyBooking";
import ShiftModalApplications from "./Tabs/Applications/ShiftModalApplications";
import ShiftModalAuditTrail from "./Tabs/AuditTrail/ShiftModalAuditTrail";
import ShiftModalAssignWorker from "./Tabs/AssignWorker/ShiftModalAssignWorker";
import ShiftModalAssignAgencyWorker from "./Tabs/AssignAgencyWorker/ShiftModalAssignAgencyWorker";
import ShiftModalCancel from "./Tabs/CancelBooking/ShiftModalCancel";
import ShiftModalDetails from "./Tabs/ShiftDetails/ShiftModalDetails";
import ShiftModalVMSAssignAgencyWorker from "./Tabs/VMSAssignAgencyWorker/ShiftModalVMSAssignAgencyWorker";
import ShiftModalVMSProposeAgencyWorker from "./Tabs/VMSProposeAgencyWorker/ShiftModalVMSProposeAgencyWorker";
import ShiftModalRequestEscalation from "./Tabs/RequestEscalation/ShiftModalRequestEscalation";
import ShiftModalSignOff from "./Tabs/SignOff/ShiftModalSignOff";

const ShiftModal = ({
  fetchShifts,
  handleClose,
  shiftId,
  show,
  tab,
  userId,
  vms,
}) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(tab);
  const [shift, setShift] = useState(undefined);
  const [shiftLoading, setShiftLoading] = useState(false);
  const [statusAttributes, setStatusAttributes] = useState(undefined);
  const [watched, setWatched] = useState(undefined);

  useEffect(() => {
    if (shiftId) {
      setTimeout(() => {
        updateShift(shiftId);
      }, 300);
    }
  }, [shiftId]);

  useEffect(() => {
    if (shift) {
      setStatusAttributes({
        format: statusMap[shift.status].format,
        background: statusMap[shift.status].background,
        dark: statusMap[shift.status].dark,
      });
    }
  }, [shift]);

  const watchAction = () => {
    setError({});
    if (watched) {
      return unwatchShift(shift).then((data) => {
        if (data !== "err") {
          setError({
            error: true,
            message: "Unable to unwatch shift, please try again.",
          });
          setWatched(false);
          updateShift(shift.id);
          fetchShifts;
        } else {
          setError({
            error: true,
            message: "Unable to unwatch shift, please try again.",
          });
          setTimeout(() => {
            setError({});
          }, 5000);
        }
      });
    }
    return watchShift(shift).then((data) => {
      if (data !== "err") {
        setWatched(true);
        updateShift(shift.id);
        fetchShifts;
      } else {
        setError({
          error: true,
          message: "Unable to watch shift, please try again.",
        });
        setTimeout(() => {
          setError({});
        }, 5000);
      }
    });
  };

  // Test
  const updateShift = (shiftId) => {
    setError({});
    if (shiftId)
      fetchShift(shiftId).then((data) => {
        if (data !== "err") {
          setShift(data.data.shift);
          setWatched(
            data.data.shift?.watchingHubUsers.map(
              (item) => userId === item.user_id
            ).length === 1
          );
          setLoading(false);
        } else {
          setError({
            error: true,
            message: "Unable to update shift, please try again.",
          });
          setTimeout(() => {
            setError({});
          }, 5000);
        }
      });
  };

  return (
    <>
      {error && error.error && (
        <ErrorWrapper>
          <ErrorContainer>
            <p>{error.message}</p>
          </ErrorContainer>
        </ErrorWrapper>
      )}
      {loading ? (
        ""
      ) : (
        <ModalWrapper
          show={show}
          size="lg"
          onHide={handleClose}
          centered
          backdrop="static"
        >
          <>
            {shiftLoading ? (
              <LoadingBg>
                <svg
                  width="38"
                  height="38"
                  viewBox="0 0 38 38"
                  xmlns="http://www.w3.org/2000/svg"
                  stroke="#fff"
                >
                  <g fill="none" fillRule="evenodd">
                    <g transform="translate(1 1)" strokeWidth="2">
                      <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
                      <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                          attributeName="transform"
                          type="rotate"
                          from="0 18 18"
                          to="360 18 18"
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </path>
                    </g>
                  </g>
                </svg>
              </LoadingBg>
            ) : (
              <></>
            )}
            <>
              <ModalHeader>
                <ModalHeaderItems>
                  <ModalHeaderItem>
                    <h3>Shift #{shift.id}</h3>
                  </ModalHeaderItem>
                  <ModalHeaderItem>
                    <h3>
                      {moment(shift.startTime).format("h:mma")} →{" "}
                      {moment(shift.endTime).format("h:mma")}
                    </h3>
                  </ModalHeaderItem>
                  <ModalHeaderItem>
                    <h3>{shift.department.name}</h3>
                  </ModalHeaderItem>
                  <ModalHeaderItem>
                    <h3>{shift.grade.title}</h3>
                  </ModalHeaderItem>
                  <ModalHeaderItem>
                    {statusAttributes && (
                      <ModalHeaderStatus
                        style={{
                          background: statusAttributes.background,
                          color: statusAttributes.dark,
                        }}
                      >
                        {statusAttributes.format}
                      </ModalHeaderStatus>
                    )}
                  </ModalHeaderItem>
                  <ModalHeaderItem>
                    {watched === true ? (
                      <UnwatchButton onClick={() => watchAction()}>
                        <WatchIcon /> Unwatch
                      </UnwatchButton>
                    ) : (
                      <WatchButton onClick={() => watchAction()}>
                        <WatchIcon /> Watch
                      </WatchButton>
                    )}
                  </ModalHeaderItem>
                </ModalHeaderItems>
                <CloseButton onClick={handleClose}>
                  <svg
                    width="18"
                    height="18"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.518 13.622a2.05 2.05 0 010 2.896c-.4.399-.921.593-1.453.593a2.028 2.028 0 01-1.443-.593l-5.064-5.065-5.064 5.065c-.4.399-.92.593-1.443.593A2.034 2.034 0 01.6 16.518a2.05 2.05 0 010-2.896l5.064-5.064L.599 3.494a2.05 2.05 0 010-2.895 2.05 2.05 0 012.895 0l5.064 5.064L13.622.599a2.05 2.05 0 012.896 0 2.05 2.05 0 010 2.895l-5.065 5.064 5.065 5.064z"
                      fill="#9CA8B4"
                      fillRule="evenodd"
                    />
                  </svg>
                </CloseButton>
              </ModalHeader>
              <ModalBody>
                <ModalContentWrapper>
                  <ShiftModalMenu>
                    <ul>
                      {!vms &&
                        shift.availableActions.includes("accept_booking") && (
                          <li onClick={() => setSelectedTab("applications")}>
                            <ApplicationsButton>
                              <span>{shift.appliedBy.length}</span> Applications
                            </ApplicationsButton>
                          </li>
                        )}
                      {!vms && shift.availableActions.includes("assign_locum") && (
                        <li onClick={() => setSelectedTab("assignWorker")}>
                          <ShiftModalMenuOption
                            className={
                              selectedTab === "assignWorker" && "active"
                            }
                          >
                            Assign a Worker
                          </ShiftModalMenuOption>
                        </li>
                      )}
                      {!vms &&
                        shift.availableActions.includes(
                          "assign_agency_locum"
                        ) && (
                          <li onClick={() => setSelectedTab("assignAgency")}>
                            <ShiftModalMenuOption
                              className={
                                selectedTab === "assignAgency" && "active"
                              }
                            >
                              Assign Agency Worker
                            </ShiftModalMenuOption>
                          </li>
                        )}
                      {!vms &&
                        (shift.availableActions.includes(
                          "cancel_bank_worker"
                        ) ||
                          shift.availableActions.includes(
                            "cancel_agency_worker"
                          )) && (
                          <li onClick={() => setSelectedTab("cancelBooking")}>
                            <ShiftModalMenuOption
                              className={
                                selectedTab === "cancelBooking" && "active"
                              }
                            >
                              Cancel Booking
                            </ShiftModalMenuOption>
                          </li>
                        )}
                      {!vms &&
                        shift.availableActions.includes(
                          "amend_agency_locum"
                        ) && (
                          <li
                            onClick={() => setSelectedTab("amendAgencyBooking")}
                          >
                            <ShiftModalMenuOption
                              className={
                                selectedTab === "amendAgencyBooking" && "active"
                              }
                            >
                              Amend Agency Booking
                            </ShiftModalMenuOption>
                          </li>
                        )}
                      {!vms &&
                        (shift.availableActions.includes("accept_booking") ||
                          shift.availableActions.includes("assign_locum") ||
                          shift.availableActions.includes(
                            "assign_agency_locum"
                          ) ||
                          shift.availableActions.includes(
                            "cancel_bank_worker"
                          ) ||
                          shift.availableActions.includes(
                            "cancel_agency_worker"
                          )) && <ShiftModalMenuDivider />}
                      {!vms &&
                      shift.availableActions.includes("update_shift") ? (
                        <li onClick={() => setSelectedTab("details")}>
                          <ShiftModalMenuOption
                            className={selectedTab === "details" && "active"}
                          >
                            Shift Details
                          </ShiftModalMenuOption>
                        </li>
                      ) : (
                        <li onClick={() => setSelectedTab("detailsReadOnly")}>
                          <ShiftModalMenuOption
                            className={
                              selectedTab === "detailsReadOnly" && "active"
                            }
                          >
                            Shift Details
                          </ShiftModalMenuOption>
                        </li>
                      )}
                      {!vms &&
                        shift.availableActions.includes(
                          "advanced_escalate_rates"
                        ) && (
                          <li onClick={() => setSelectedTab("escalation")}>
                            <ShiftModalMenuOption
                              className={
                                selectedTab === "escalation" && "active"
                              }
                            >
                              Request Escalation
                            </ShiftModalMenuOption>
                          </li>
                        )}
                      {!vms && shift.availableActions.includes("sign_off") && (
                        <li onClick={() => setSelectedTab("signOff")}>
                          <ShiftModalMenuOption
                            className={selectedTab === "signOff" && "active"}
                          >
                            Sign Off
                          </ShiftModalMenuOption>
                        </li>
                      )}
                      {!vms && (
                        <li onClick={() => setSelectedTab("audit")}>
                          <ShiftModalMenuOption
                            className={selectedTab === "audit" && "active"}
                          >
                            Audit Trail
                          </ShiftModalMenuOption>
                        </li>
                      )}
                      {vms && (
                        <>
                          <ShiftModalMenuDivider />
                          {(shift.availableForAgencyUserActions.includes(
                            "assign_worker"
                          ) ||
                            shift.availableForAgencyUserActions.includes(
                              "cancel_worker"
                            )) && (
                            <li
                              onClick={() =>
                                setSelectedTab("vmsAssignAgencyWorker")
                              }
                            >
                              <ShiftModalMenuOption
                                className={
                                  selectedTab === "vmsAssignAgencyWorker" &&
                                  "active"
                                }
                              >
                                {shift.agencyLocum
                                  ? "Agency Worker"
                                  : "Assign Agency Worker"}
                              </ShiftModalMenuOption>
                            </li>
                          )}
                          {shift.availableForAgencyUserActions.includes(
                            "propose_worker"
                          ) && (
                            <li
                              onClick={() =>
                                setSelectedTab("vmsProposeAgencyWorker")
                              }
                            >
                              <ShiftModalMenuOption
                                className={
                                  selectedTab === "vmsProposeAgencyWorker" &&
                                  "active"
                                }
                              >
                                Propose Agency Worker
                              </ShiftModalMenuOption>
                            </li>
                          )}
                        </>
                      )}
                    </ul>
                  </ShiftModalMenu>
                  <ModalContentContainer>
                    {selectedTab === "applications" ? (
                      <ShiftModalApplications
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "audit" ? (
                      <ShiftModalAuditTrail shift={shift} show={show} />
                    ) : selectedTab === "details" ? (
                      <ShiftModalDetails
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "detailsReadOnly" ? (
                      <ShiftModalDetails
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        locked={true}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "assignWorker" ? (
                      <ShiftModalAssignWorker
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "assignAgency" ? (
                      <ShiftModalAssignAgencyWorker
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                      />
                    ) : selectedTab === "cancelBooking" ? (
                      <ShiftModalCancel
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "amendAgencyBooking" ? (
                      <ShiftModalAmendAgencyBooking
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "escalation" ? (
                      <ShiftModalRequestEscalation
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "signOff" ? (
                      <ShiftModalSignOff
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "vmsAssignAgencyWorker" ? (
                      <ShiftModalVMSAssignAgencyWorker
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : selectedTab === "vmsProposeAgencyWorker" ? (
                      <ShiftModalVMSProposeAgencyWorker
                        fetchShift={updateShift}
                        fetchShifts={fetchShifts}
                        handleClose={handleClose}
                        setSelectedTab={setSelectedTab}
                        setShiftLoading={setShiftLoading}
                        shift={shift}
                        show={show}
                      />
                    ) : (
                      <></>
                    )}
                  </ModalContentContainer>
                </ModalContentWrapper>
              </ModalBody>
            </>
          </>
        </ModalWrapper>
      )}
    </>
  );
};

export default ShiftModal;

const ErrorWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1060;
`;

const LoadingBg = styled.div`
  align-items: center;
  background: rgba(18, 38, 63, 0.5);
  border-radius: 6px;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 5;
`;

const ModalWrapper = styled(Modal)`
  // position: relative;

  .modal-lg {
    max-width: 1040px;
  }
`;

const ModalHeader = styled(Modal.Header)`
  background: #f5f7fa;
  border-bottom: 1px solid #cbd2d9;

  button {
    border: 0;
    box-shadow: none;
  }
`;

const CloseButton = styled.button`
  background: transparent;
`;

const ModalHeaderItems = styled.div`
  align-items: center;
  display: flex;
`;

const ModalHeaderItem = styled.div`
  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:not(:first-of-type) {
    h3 {
      font-weight: 400;
    }
  }

  &:not(:last-of-type) {
    border-right: 1px solid #cbd2d9;
    margin-right: 25px;
    padding-right: 25px;
  }
`;

const ModalHeaderStatus = styled.span`
  align-items: center;
  background: #ffccb8;
  border-radius: 4px;
  color: #c34412;
  display: flex;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.56px;
  padding: 3px 15px;
  text-transform: uppercase;
`;

const WatchButton = styled.button`
  align-items: center;
  background: #dfe2e5;
  border-radius: 4px;
  color: #1e2d36;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  padding: 3px 10px;

  svg {
    margin-right: 6px;
  }
`;

const UnwatchButton = styled(WatchButton)`
  background: #1e2d36;
  color: #ffffff;

  svg {
    path {
      fill: rgba(255, 255, 255, 0.5);
    }
  }
`;

const ModalBody = styled(Modal.Body)`
  overflow-y: auto;
  padding: 0;
`;

const ApplicationsButton = styled.button`
  align-items: center;
  background: #ffffff;
  border: 1px solid #ff6c6c;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-left: 16px;
  margin-right: 16px;
  padding: 4px 10px;
  width: calc(100% - 32px);

  span {
    background: #ff6c6c;
    border-radius: 50%;
    color: #ffffff;
    height: 18px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.4;
    margin-right: 8px;
    width: 18px;
  }
`;

const ShiftModalMenuDivider = styled.hr`
  border-color: #e2e5e9;
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100% - 50px);
`;

const WatchIcon = () => (
  <svg width="26" height="15" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M.277 6.782c8.384-9.043 16.773-9.043 25.157 0 .37.407.37 1.029 0 1.436-8.384 9.043-16.773 9.043-25.157 0a1.068 1.068 0 010-1.436zM12.856 2.68a4.822 4.822 0 100 9.643 4.822 4.822 0 000-9.643zm0 2.142a2.679 2.679 0 110 5.358 2.679 2.679 0 010-5.358z"
      fill="#1E2D36"
      fillRule="evenodd"
    />
  </svg>
);
