import React from 'react'
import styled from 'styled-components'

const ShiftCalendarHeader = ({ shiftCount }) => {

  return (
    <HeaderWrapper>
      <HeaderAreaTop>
        <HeaderAreaDeatails>
          <span>Shifts Found</span>
          <h4>{shiftCount} Matches</h4>
        </HeaderAreaDeatails>
        <HeaderAreaUser></HeaderAreaUser>
      </HeaderAreaTop>
    </HeaderWrapper>
  )
}

export default ShiftCalendarHeader

const HeaderWrapper = styled.div`
  background: #1E2D36;
  color: #ffffff;
  // height: 200px;
  margin-bottom: 25px;
  padding: 16px 28px;
  width: 100%;
`

const HeaderAreaTop = styled.div`
  align-items: center;
  // border-bottom: 1px solid rgba(151, 151, 151, .2);
  display: flex;
  justify-content: space-between;
  // margin-bottom: 24px;
  // padding-bottom: 24px;
`

const HeaderAreaDeatails = styled.div`

  span {
    display: block;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.5;
    letter-spacing: 0.7px;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  h4 {
    font-size: 28px;
    line-height: 21px;
  }
`

const HeaderAreaUser = styled.div``


