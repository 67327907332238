import React, { useState } from 'react'
import styled from 'styled-components'

import {
  ShiftModalDetailFooter,
  ShiftModalContainer,
  ShiftModalButton,
  ShiftModalButtonClose,
} from '../../ShiftModalComponents'
import ShiftModalAssign from '../../ShiftModalAssign'

import { assignWorker, fetchWorkers } from '../../../../../Helpers/WorkerHelpers'

const ShiftModalDetails = ({ fetchShift, fetchShifts, handleClose, setSelectedTab, setShiftLoading, shift }) => {
  const [error, setError] = useState({ error: false, message: null });
  const [worker, setWorker] = useState(undefined);
  const [workers, setWorkers] = useState(undefined);
  const [workerName, setWorkerName] = useState("");

  const setWorkerAction = (worker) => {
    setWorker(worker)
  }

  const fetchWorkersAction = () => {
    fetchWorkers(workerName).then((data) => {
      if (data !== "err") {
        setWorkers(data.data.workers)
      } else {
        setError({ error: true, message: `Unable to fetch workers.` })
      }
    })
  }

  const assignWorkerAction = () => {
    const workerId = Number(worker.id)
    setShiftLoading(true);
    setError({ error: false, message: null })
    assignWorker(shift.id, workerId).then((data) => {
      const assignWorker = data?.data.assignWorker
      if (assignWorker.errors.length > 0) {
        setShiftLoading(false);
        setError({ error: true, message: `Worker ${errors[0].message}.` })
      } else {
        fetchShift(shift.id);
        setTimeout(() => {
          setSelectedTab("details");
          setShiftLoading(false);
          fetchShifts();
        }, 300)
      }
    });
  }

  const inputFunction = (e) => {
    setWorkerName(e.target.value)
    if (workerName.length > 1) {
      fetchWorkersAction()
    }
    setError({ error: false, message: null });
  }

  return (
    <>
      {worker &&
        <ShiftModalDetailFooter>
          <div>
            {(error && error.message) &&
              <ErrorContainer><p>{error.message}</p></ErrorContainer>
            }
          </div>
          <div>
            <ShiftModalButtonClose onClick={handleClose}>Close</ShiftModalButtonClose>
            <ShiftModalButton theme={'blue'} onClick={(e) => assignWorkerAction(e)}>Confirm Assign</ShiftModalButton>
          </div>
        </ShiftModalDetailFooter>
      }
      <ShiftModalContainer locked={worker ? false : true}>
        <AssignWorkerContainer>
          <ShiftModalAssign
            action={setWorkerAction}
            data={workers}
            error={error}
            inputFunction={inputFunction}
            inputValue={workerName}
            selectedValue={worker && worker.id}
            title={"Assign a Worker"}
            type={'worker'}
          />
        </AssignWorkerContainer>
      </ShiftModalContainer>
    </>
  )
}

export default ShiftModalDetails

const AssignWorkerContainer = styled.div`
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr 250px;
`