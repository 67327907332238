import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class ActionBarModal extends Controller {
  static get targets() {
    return ['shiftIds', 'getShiftIds']
  }

  connect() {
    const shiftList = document.querySelector('.list')
    const checkedArray = Array.from(shiftList.querySelectorAll('.custom-control-input:checked')).map(
      (item) => item.value
    )

    const checked = checkedArray.join(', ')

    if (this.hasShiftIdsTarget) {
      const shiftIds = this.shiftIdsTarget
      shiftIds.innerText = `#${checked}`
    }

    if (this.hasGetShiftIdsTarget) {
      const getShiftIds = this.getShiftIdsTarget
      getShiftIds.value = checkedArray
    }

    const rpaShifts = Array.from(shiftList.querySelectorAll('.external__id')).map((item) => item?.value)
    const checkedRpaShifts = rpaShifts.filter((item) => checkedArray.includes(item))

    if (checkedRpaShifts.length === 0) {
      return
    }

    console.log('check33edArray', checkedRpaShifts, checkedRpaShifts.length)
    const item = document.getElementById('externalShift')

    if (checkedRpaShifts.length > 0) {
      item.style.display = 'block'
      item.innerHTML =
        `<h2>Warning:</h2>` +
        `<p>You are attempting to delete ${checkedRpaShifts.length} shift(s) synced with an external system. ` +
        `It is preferred that you delete shifts in the external system, not Patchwork.</p>`
    } else {
      item.style.display = 'none'
    }
  }
}

application.register('action_bar_modal', ActionBarModal)
