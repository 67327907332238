import { FormGroup, FormLabel, FormSelect } from '@patchworkhealth/web-components'
import React from 'react'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import styled from 'styled-components'
import { statuses } from './BlockBookingsHelpers'

function BlockFilters({ agencyWorkers, bankWorkers, details, handleFilter, filtersInputs, resetPagination }) {
  return (
    <ShiftsFilterWrapper>
      <ShiftsFiltersContainer>
        <FilterFormGroup col={1} row={1}>
          <FormLabel>Units</FormLabel>
          <FormSelect
            isClearable
            isMulti
            onChange={(e) => {
              handleFilter(e, 'departmentIds')
              resetPagination()
            }}
            options={details && details.organisation.departments}
            placeholder="Select Units"
            value={filtersInputs.departmentIds}
          />
        </FilterFormGroup>

        <FilterFormGroup col={2} row={1}>
          <FormLabel>Sites</FormLabel>
          <FormSelect
            isClearable
            isMulti
            onChange={(e) => {
              handleFilter(e, 'siteIds')
              resetPagination()
            }}
            options={details && details.sites}
            placeholder="Select Sites"
            value={filtersInputs.siteIds}
          />
        </FilterFormGroup>

        <FilterFormGroup col={3} row={1}>
          <FormLabel>Grades</FormLabel>
          <FormSelect
            isClearable
            isMulti
            onChange={(e) => {
              handleFilter(e, 'gradeIds')
              resetPagination()
            }}
            options={details && details.organisation?.grades}
            placeholder="Select Grades"
            value={filtersInputs.gradeIds}
          />
        </FilterFormGroup>

        <FilterFormGroup col={1} row={2}>
          <FormLabel>Status</FormLabel>
          <FormSelect
            isClearable
            isMulti
            onChange={(e) => {
              handleFilter(e, 'statuses')
              resetPagination()
            }}
            options={statuses}
            placeholder="Select Block Status"
            value={filtersInputs.statuses}
          />
        </FilterFormGroup>

        <FilterFormGroup col={2} row={1}>
          <FormLabel>Bank Worker</FormLabel>
          <FormSelect
            isClearable
            name="organisationRegistrationId"
            onChange={(e) => {
              handleFilter(e, 'organisationRegistrationId')
              resetPagination()
            }}
            options={
              bankWorkers &&
              bankWorkers?.map((worker) => ({
                label: `${worker?.user?.firstName} ${worker?.user?.lastName}`,
                value: worker?.id,
              }))
            }
            placeholder="Select Bank Worker"
            value={filtersInputs.organisationRegistrationId}
          />
        </FilterFormGroup>

        <FilterFormGroup col={2} row={1}>
          <FormLabel>Agency Worker</FormLabel>
          <FormSelect
            isClearable
            name="agencyRegistrationId"
            onChange={(e) => {
              handleFilter(e, 'agencyRegistrationId')
              resetPagination()
            }}
            options={
              agencyWorkers &&
              agencyWorkers?.map((worker) => ({
                label: `${worker?.user?.firstName} ${worker?.user?.lastName}`,
                value: worker?.id,
              }))
            }
            placeholder="Select Agency Worker"
            value={filtersInputs.agencyRegistrationId}
          />
        </FilterFormGroup>

        <FilterFormGroup col={2} row={2}>
          <FormLabel>Block Start Date</FormLabel>
          <DayPickerInput
            name="startDateFrom"
            onDayChange={(e) => {
              handleFilter(e, 'startDateFrom')
              resetPagination()
            }}
            value={filtersInputs.startDateFrom}
          />
        </FilterFormGroup>

        <FilterFormGroup col={3} row={2}>
          <FormLabel>Block End Date</FormLabel>
          <DayPickerInput
            name="endDateTo"
            onDayChange={(e) => {
              handleFilter(e, 'endDateTo')
              resetPagination()
            }}
            value={filtersInputs.endDateTo}
          />
        </FilterFormGroup>
      </ShiftsFiltersContainer>
    </ShiftsFilterWrapper>
  )
}

export default BlockFilters

export const ShiftsFiltersContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 10px;
  grid-template-columns: repeat(4, 280px);
  margin-bottom: 24px;

  label {
    color: white;
  }

  @media (max-width: 1550px) and (min-width: 1151px) {
    grid-template-columns: repeat(3, 280px);
  }

  @media (max-width: 1150px) {
    grid-template-columns: repeat(3, 200px);
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;
    -ms-grid-columns: 400px 400px 400px;
    -ms-grid-row: 200px 300px;
  }
`

const FilterFormGroup = styled(FormGroup)`
  -ms-grid-column: ${(props) => props.col};
  -ms-grid-row: ${(props) => props.row};
  padding: 5px;

  input {
    border-bottom-left-radius: 6px !important;
    border-top-left-radius: 6px !important;
  }
`

export const ShiftsFilterWrapper = styled.div`
  background: #002033;
`
