import Handlebars from 'handlebars'
import { Controller } from 'stimulus'
import { application } from '../../frontend/controllers/index'

export default class RateRequestAuthoriserSelectorController extends Controller {
  static get targets() {
    return ['results', 'template', 'spinner', 'selectButton']
  }

  connect() {
    this.element[this.identifier] = this

    const debouncedFetchUsersFromEvent = this.debounce(this.fetchUsersFromEvent, 1000).bind(this)

    if (this.data.get('update_event')) {
      this.listen = document.addEventListener(this.data.get('update_event'), (event) => {
        debouncedFetchUsersFromEvent(event)
      })
    }

    const existingEscalation = document.querySelector("input[name='shift[rate_request_id]']")

    if (existingEscalation) {
      const rateCardChanges = document.querySelector("input[name='shift[rate_card_rate_changes]']").value

      let rateChanges = JSON.parse(rateCardChanges.replaceAll('&quot;', '"'))
      rateChanges = rateChanges.map((rateChange) => {
        return { oldRate: parseFloat(rateChange.oldRate), newRate: parseFloat(rateChange.newRate) }
      })
      const coreRate = parseFloat(document.querySelector("input[name='shift[core_rate]']").value)
      const unsocialRate = parseFloat(document.querySelector("input[name='shift[unsocial_rate]']").value)
      const shiftId = parseInt(this.data.get('shift_id'), 10)
      this.fetchUsers(coreRate, unsocialRate, shiftId, rateChanges)
    }

    this.template = Handlebars.compile(this.templateTarget.innerHTML.toString())
    if (document.getElementById('rate-self-auth')) {
      document.getElementById('rate-self-auth').style.display = 'none'
    }
  }

  disconnect() {
    if (this.listen !== undefined) {
      document.removeEventListener(this.listen)
    }
  }

  setUpRateAuthorisers() {
    this.clearRateAuthorisers()
    this.appendRateAuthorisers()
  }

  appendRateAuthorisers() {
    this.rate_authorisers.forEach((user) => {
      const resultItem = this.template(user)

      this.resultsTargets.forEach((resultTarget) => {
        resultTarget.insertAdjacentHTML('beforeend', resultItem)
      })
    })
  }

  clearRateAuthorisers() {
    this.resultsTargets.forEach((resultTarget) => {
      const theElement = resultTarget
      theElement.innerHTML = ''
    })
  }

  fetchUsersFromEvent(event) {
    const newCoreRate = parseFloat(event.srcElement.querySelector("input[name='shift[core_rate]']")?.value)
    const newUnsocialRate = parseFloat(event.srcElement.querySelector("input[name='shift[unsocial_rate]']")?.value)
    const rateChanges = event.detail?.rateChanges
    const shiftId = parseInt(this.data.get('shift_id'), 10)
    this.fetchUsers(newCoreRate, newUnsocialRate, shiftId, rateChanges)
    this.canHubUserSelfAuth(newCoreRate, newUnsocialRate, shiftId, rateChanges)
    document.querySelector(`.accordion-section__rate--permissions-${shiftId}`).style.opacity = 1
    document.querySelector(`.accordion-section__rate--permissions-${shiftId}`).style.pointerEvents = 'all'
  }

  fetchUsers(newCoreRate, newUnsocialRate, shiftId, rateChanges) {
    const query = `query  RateAuthorisers( $newCoreRate: Float, $newUnsocialRate: Float, $shiftId: Int!, $rateChanges: [InputRateChanges!]){
            rateAuthorisers(newCoreRate: $newCoreRate, newUnsocialRate: $newUnsocialRate, shiftId: $shiftId, rateChanges: $rateChanges){
              id
              name
              email
              position
            }
          }`

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { newCoreRate, newUnsocialRate, shiftId, rateChanges },
      }),
    })
      .then((r) => r.json())
      .then((content) => {
        this.rate_authorisers = content.data.rateAuthorisers
        this.setUpRateAuthorisers()
        this.hideSpinner()
      })
  }

  canHubUserSelfAuth(newCoreRate, newUnsocialRate, shiftId, rateChanges) {
    const query = `query CanHubUserSelfAuthorise( $newCoreRate: Float, $newUnsocialRate: Float, $shiftId: Int!, $rateChanges: [InputRateChanges!]){
            canHubUserSelfAuthorise(newCoreRate: $newCoreRate, newUnsocialRate: $newUnsocialRate, shiftId: $shiftId, rateChanges: $rateChanges)
          }`

    fetch('/graphql', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        query,
        variables: { newCoreRate, newUnsocialRate, shiftId, rateChanges },
      }),
    })
      .then((r) => r.json())
      .then((data) => {
        if (!data.errors) {
          if (data.data.canHubUserSelfAuthorise === undefined || data.data.canHubUserSelfAuthorise === false) {
            document.getElementById('rate-self-auth').style.display = 'none'
          } else {
            document.getElementById('rate-self-auth').style.display = 'block'
          }
        }
      })
  }

  showSpinner() {
    this.spinnerTargets.forEach((spinnerTarget) => {
      spinnerTarget.classList.remove('d-none')
    })
  }

  hideSpinner() {
    this.spinnerTargets.forEach((spinnerTarget) => {
      spinnerTarget.classList.add('d-none')
    })
  }

  debounce(mainFunction, delay) {
    let timeoutId

    return function (...args) {
      clearTimeout(timeoutId)

      timeoutId = setTimeout(() => {
        mainFunction.apply(this, args)
      }, delay)
    }
  }

  selectUser(event) {
    event.preventDefault()

    const selectButton = event.srcElement

    const selectBtns = Array.from(document.querySelectorAll('.authoriser-btn'))

    selectBtns.forEach(function (selectBox, i) {
      if (selectBox.classList.contains('innertext--toggle')) {
        selectBox.innerText = 'Select'
        selectBox.classList.remove('innertext--toggle')
      }
    })

    if (selectButton.innerText == 'Select' || selectButton.innerText == ' Select ') {
      selectButton.innerText = 'Selected'
      selectButton.classList.add('innertext--toggle')
    }

    if (this.data.get('update_input_id')) {
      const idOfInputToUpdateWithValue = this.data.get('update_input_id')
      const inputElementToUpdate = document.querySelector(`#${idOfInputToUpdateWithValue}`)
      inputElementToUpdate.value = event.target.dataset.value
    }
  }

  selectMultiple(event) {
    event.preventDefault()
    // get the hidden input where we will send the auth ids
    const idOfInputToUpdateWithValue = this.data.get('update_input_id')
    const inputElementToUpdate = document.querySelector(`#${idOfInputToUpdateWithValue}`)
    // setup empty array for auth ids
    let authIds = []
    // if the hidden input already has values push into the array
    if (inputElementToUpdate.value.length > 0) {
      authIds = inputElementToUpdate.value.split(',')
    }
    // set which button is being pressed
    const selectButton = event.srcElement
    if (selectButton.innerText == 'Select') {
      // do things with text/styling for being selected
      selectButton.innerText = 'Selected'
      selectButton.classList.add('innertext--toggle')
      // push the value of the selected auth to the array
      authIds.push(event.target.dataset.value)
      // set hidden input to have the value of the array
      inputElementToUpdate.value = authIds
    } else {
      // undo things with text/styling for being unselected
      selectButton.innerText = 'Select'
      selectButton.classList.remove('innertext--toggle')
      // get the value of the id you are removing
      const index = authIds.indexOf(event.target.dataset.value)
      // remove the id
      if (index > -1) {
        authIds.splice(index, 1)
      }
      // update the hidden input
      inputElementToUpdate.value = authIds
    }
  }
}

application.register('rate_request_authoriser_selector', RateRequestAuthoriserSelectorController)
