import React from "react";
import { ApolloProvider } from "@apollo/client";

import { client } from "../../../../packs/application";

import AgencyShifts from "./AgencyShifts";

function AgencyShiftsIndex({ user }) {
  return (
    <ApolloProvider client={client}>
      <AgencyShifts user={user} />
    </ApolloProvider>
  );
}

export default AgencyShiftsIndex;
