/* eslint-disable no-nested-ternary */
import { FormLabel, GLOBAL, GREY } from '@patchworkhealth/web-components'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
/* Timesheet styling ------------------------------------------ */

export const TimeSheetActions = styled.div`
  pointer-events: ${({ disabled }) => disabled && 'none'};
  position: absolute;
  right: 22px;
  top: 6px;
  width: 500px;
  display: flex;
  justify-content: flex-end;
`

export const TimesheetsTable = styled.div`
  margin-top: 40px;
  border-bottom: 1px solid #d7cfcf;
`

export const TimesheetsTableHeader = styled.div`
  display: flex;
  text-transform: uppercase;
  background: #f5f7fa;
  border: 1px solid #d7cfcf;
  border-bottom: 0;
  div {
    padding: 12px;
    min-width: 140px;
    font-size: 10px;
    font-weight: 600;
    color: #7a7a7a;
  }
`

export const TableRow = styled.div`
  border: ${({ active }) => (active ? `1px solid #005100` : `1px solid #d7cfcf;`)};
  border-bottom: 0;
  background: ${({ odd }) => (!odd ? `#f5f7fa` : `#fff`)};
  display: flex;
  align-items: center;
  cursor: pointer;
  div {
    min-width: 140px;
    padding: 12px;
    font-size: 13px;
  }

  h3 {
    margin: 0px;
    font-size: 13px !important;
  }

  .openIcon__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    * {
      transition: all 0.5s ease;
    }

    &.active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`

export const ExpandedRow = styled.div`
  border: 1px solid #005100;
  border-top: 1px solid transparent;
  width: 100%;
  min-height: 260px;
  background-color: #f5f7fa;
  padding: 8px;
  .extended__row__header {
    height: 60px;
    padding: 12px;
    position: relative;
  }
`

export const OpenIconWrapper = styled.div`
  &.active {
    svg {
      transform: rotate(180deg);
    }
  }
`

// Inner cell ------------------------------------------

export const InnerCell = styled.div`
  position: relative;
  padding: 6px 0px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 12px;
  flex-direction: column;
  min-width: ${({ width }) => width || '100px'};

  .break__input {
    width: 70px;
    height: 30px;
    background: #ffffff;
    border: 1px solid #ccd2d8;
    color: #002033 !important;
  }
`

// 2nd Inner table ------------------------------------------

export const TimesheetsInnerTable = styled.div`
  padding: 4px 12px;
`

export const TimesheetsInnerTableHeader = styled.div`
  display: flex;
  div {
    min-width: 100px;
    font-size: 11px;
  }
`

export const TimesheetsInnerTableBody = styled.div``

export const TableInnerRow = styled.div`
  border-top: 1px solid #ccd1cc;
  display: flex;
  height: 60px;

  h4 {
    margin: 0;
  }
`

// Other ---------------------------------------------

export const SignOffContainer = styled.div`
  width: 800px;
  padding-top: 20px;
  h3 {
    font-weight: normal;
    font-size: 12px !important;
    line-height: 0.4;
  }
  h4 {
    font-weight: normal;
    font-size: 11px !important;
    line-height: 0.6;
  }

  .modal__timesheets__body {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }

  .custom__date__picker {
    font-size: 11px;
    width: 143px;
    border: 1px solid #8080805c;
  }
`

// Tabs ---------------------------------------------

export const TimesheetTabs = styled.div`
  display: flex;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  margin: 0px 20px;
  position: absolute;
  padding: 0px;
  left: 0;
  right: 0;
  top: 5px;
  height: 45px;
`

export const TabButton = styled.button`
  margin: 16;
  flex: 1;
  padding: 8;
  font-size: 14;
  transition: all 0.3s ease;
  border: 0;
  background: none;
  border-bottom: ${(props) => (props.active ? '2px solid black' : '2px solid white')};
  font-weight: ${(props) => (!props.active ? '400' : '500')};
`

export const TimesheetPermissions = styled.div`
  margin-top: 10px;
  p {
    font-size: 12px;
    margin: 0px;
  }
`

// Table tooltip ------------------------------------------

export function TableIconFunction({ details }) {
  return (
    <ModWrapper>
      <svg fill="none" height="25" viewBox="0 0 25 25" width="25" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" fill="#94D2FF" r="12.5" />
        <path
          d="M12.7825 15.7175L12.67 15.6275C12.6282 15.5991 12.5828 15.5764 12.535 15.56L12.4 15.5C12.2784 15.4746 12.1523 15.4798 12.0332 15.5151C11.9141 15.5505 11.8056 15.6148 11.7175 15.7025C11.6513 15.7754 11.598 15.8591 11.56 15.95C11.5032 16.0867 11.4882 16.237 11.5167 16.3822C11.5452 16.5274 11.616 16.6609 11.7203 16.766C11.8245 16.871 11.9575 16.9429 12.1024 16.9725C12.2474 17.0021 12.3979 16.9882 12.535 16.9325C12.6246 16.889 12.7079 16.8335 12.7825 16.7675C12.8866 16.662 12.9571 16.5281 12.9851 16.3826C13.0131 16.2371 12.9974 16.0866 12.94 15.95C12.9026 15.8633 12.8492 15.7844 12.7825 15.7175ZM15.25 5H9.25C8.65326 5 8.08097 5.23705 7.65901 5.65901C7.23705 6.08097 7 6.65326 7 7.25V17.75C7 18.3467 7.23705 18.919 7.65901 19.341C8.08097 19.7629 8.65326 20 9.25 20H15.25C15.8467 20 16.419 19.7629 16.841 19.341C17.2629 18.919 17.5 18.3467 17.5 17.75V7.25C17.5 6.65326 17.2629 6.08097 16.841 5.65901C16.419 5.23705 15.8467 5 15.25 5ZM16 17.75C16 17.9489 15.921 18.1397 15.7803 18.2803C15.6397 18.421 15.4489 18.5 15.25 18.5H9.25C9.05109 18.5 8.86032 18.421 8.71967 18.2803C8.57902 18.1397 8.5 17.9489 8.5 17.75V7.25C8.5 7.05109 8.57902 6.86032 8.71967 6.71967C8.86032 6.57902 9.05109 6.5 9.25 6.5H15.25C15.4489 6.5 15.6397 6.57902 15.7803 6.71967C15.921 6.86032 16 7.05109 16 7.25V17.75Z"
          fill="#0081DE"
        />
      </svg>
      <ToolTipWrapper>
        {details.confirmedBy !== null && (
          <>
            <div>
              <FormLabel>Signed-off by:</FormLabel>
              <strong>{details?.confirmedBy}</strong>
            </div>
            <div>
              <FormLabel>Date of signoff:</FormLabel>
              <h4>{moment(details?.confirmedAt).format('Do MMM YYYY - HH:mma ')}</h4>
            </div>
          </>
        )}

        {details.confirmedBy !== null && details?.workerSignOff?.confirmationNotes !== '' && (
          <div>
            <FormLabel>Authoriser Note:</FormLabel>
            <h4>{details.workerSignOff?.confirmationNotes}</h4>
          </div>
        )}

        {details?.workerSignOff?.reportedNotes !== '' && (
          <div>
            <FormLabel>Worker Note:</FormLabel>
            <h4>{details.workerSignOff?.reportedNotes}</h4>
          </div>
        )}

        {details.workerSignOff?.approvedBy !== '' && (
          <div>
            <FormLabel>Approved by:</FormLabel>
            <h4>{details.workerSignOff?.approvedBy}</h4>
          </div>
        )}

        {details.workerSignOff?.approvedDate !== '' && (
          <div>
            <FormLabel>Date of approval:</FormLabel>
            <h4>{moment(details.workerSignOff?.approvedDate).format('Do MMM YYYY - HH:mma ')}</h4>
          </div>
        )}

        {details.paymentApproverNotes !== null && (
          <div>
            <FormLabel>Approver Note:</FormLabel>
            <h4>{details?.paymentApproverNotes ?? '-'}</h4>
          </div>
        )}

        {details.breaksExceptionNotes !== null && (
          <div>
            <FormLabel>Break Exception Notes:</FormLabel>
            <h4>{details?.breaksExceptionNotes ?? '-'}</h4>
          </div>
        )}
      </ToolTipWrapper>
    </ModWrapper>
  )
}

export const ModWrapper = styled.span`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  svg:hover + div {
    opacity: 1 !important;
  }
`

export const ToolTipWrapper = styled.div`
  background: #eaedf2;
  border: 1px solid #cbd2d9;
  border-radius: 8px;
  left: -360px;
  opacity: 0;
  display: flex;
  pointer-events: none;
  flex-wrap: wrap;
  position: absolute;
  top: -120px;
  transition: all 0.2s ease-in-out;
  width: 350px;
  min-height: 150px;
  z-index: 100 !important;
  padding: 6px;
  div {
    padding: 6px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RateCardChange = styled.h6`
  align-items: center;
  background: #fae4e4;
  border-radius: 4px;
  color: #c54714;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  justify-content: center;
  min-width: 41px;
  padding: 2px 9px;
  position: relative;
  right: ${({ fixed }) => (!fixed ? '0px' : '0px ')};
  top: 0px;
  max-width: 100px;
  margin: 0px;
`

export const checkStatus = (status) => {
  const mapping = {
    TO_SIGN_OFF_ON_HUB: 'BOOKED_',
    TO_SIGN_OFF_ON_HUB_OR_APP: 'BOOKED_',
    PROVISIONALLY_BOOKED: 'BOOKED',
  }
  return mapping[status] || status.replace(/[_]/g, ' ')
}

export const hrsCalculator = (rate) => {
  const hours = rate.hoursWorked - rate.hoursExpected

  return rate.hoursWorked === 0 ? (
    <div />
  ) : hours === 0 ? (
    <div />
  ) : (
    <RateCardChange fixed>
      {hours > 0 && '+'}
      {hours.toFixed(1)}hrs
    </RateCardChange>
  )
}

export const calculateHours = (status, shifts) => {
  if (status === 'SIGNED_OFF') {
    const totalHours = shifts.reduce((acc, item) => {
      return acc + item.totalHours
    }, 0)

    return totalHours.toFixed(2)
  }

  let totalHours = 0
  shifts?.forEach((shift) => {
    const start = moment(shift?.workerSignOff?.reportedStartTime)
    const end = moment(shift?.workerSignOff?.reportedEndTime)
    const breaks = shift?.workerSignOff?.reportedBreaks
    totalHours += (end.diff(start, 'minutes') - breaks) / 60
  })

  return totalHours.toFixed(2)
}

export const calculateMinimumBreaks = (s) => {
  if (s.workerSignOff === null) return 0

  const startTime = moment(s.workerSignOff.reportedStartTime)
  const endTime = moment(s.workerSignOff.reportedEndTime)
  const gradeBreakMinutes = s.gradeBreakMinutes ?? 0
  const gradeBreakRepeatMinutes = s.gradeBreakRepeatMinutes ?? 0

  const totalMinutes = endTime.diff(startTime, 'minutes')
  const totalGradeBreaks = Math.floor(totalMinutes / gradeBreakRepeatMinutes)
  const minimumBreaks = totalGradeBreaks * gradeBreakMinutes

  return minimumBreaks
}
