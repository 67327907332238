import { Controller } from 'stimulus'
import SlimSelect from 'slim-select'

export default class  ClinicianSelfBookingSelectorController extends Controller {

    static get targets() {
        return ['select']
    }
    connect() {
        const target = this.selectTarget;
        this.select = new SlimSelect({
            select: target,
            showSearch: true
        });

    }
}

import { application } from '../../frontend/controllers/index'
application.register("clinician_self_booking_selector",  ClinicianSelfBookingSelectorController);