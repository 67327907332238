import WorkerSelectorController from '../worker_selector/worker_selector_controller'

export default class AgencyWorkerSelectorController extends WorkerSelectorController {

    static get targets() {
        return ['results', 'input', 'template', 'query', 'spinner']
    }

    fetchUsers() {
        const name = this.inputTarget.value;
        const query = `query AgencyWorkers($name: String, $page: Int!, $items: Int!){
            agencyWorkers(name: $name, page: $page, items: $items){
              id
              firstName
              lastName
              grade {
                title
              }
            }
          }`;

        const page = 1;
        const items = 10;
        fetch('/graphql', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({
                query,
                variables: {name, page, items},
            })
        })
            .then(r => r.json())
            .then(data => {
                this.workers = data.data.agencyWorkers;
                this.setUpWorkers();
                this.hideSpinner();
            });
    }
}
import {application} from '../../frontend/controllers/index'

application.register("agency_worker_selector", AgencyWorkerSelectorController)