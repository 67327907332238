import { FormGrid, FormGroup, ModalHeader, CloseButton, FormLabel, GLOBAL, GREY } from '@patchworkhealth/web-components'
import React from 'react'
import Modal from 'react-bootstrap/Modal'
import styled from 'styled-components'

export function ViewBlockWorkerModal({ proposal, inputs, setInputs }) {
  return (
    <Modal
      backdrop="static"
      onHide={() => setInputs({ ...inputs, showWorkerDetails: null })}
      show={inputs.showWorkerDetails === proposal.agencyWorker.id}
      size="lg"
      style={{ marginTop: 50, marginLeft: 60 }}
    >
      <ModalHeader style={{ height: 80, fontSize: 12 }}>
        <ShiftModalHeaderTitle>
          {proposal?.agencyWorker.firstName} {proposal.agencyWorker.lastName}
        </ShiftModalHeaderTitle>

        <CloseButton onClick={() => setInputs({ ...inputs, showWorkerDetails: null })} />
      </ModalHeader>

      <div style={{ padding: '20px 20px 40px' }}>
        <FormGrid columns={2}>
          <FormGroup>
            <FormLabel>Email</FormLabel>
            <p>{proposal.agencyWorker.email}</p>
          </FormGroup>

          <FormGroup>
            <FormLabel>NI Number</FormLabel>
            <p>{proposal.agencyWorker.niNumber}</p>
          </FormGroup>

          <FormGroup>
            <FormLabel>Reg Body Number</FormLabel>
            <p>{proposal.agencyWorker.regBodyNumber}</p>
          </FormGroup>

          <FormGroup>
            <FormLabel>Agency</FormLabel>
            <p>{proposal.agencyWorker.agency.title}</p>
          </FormGroup>
        </FormGrid>

        <FormGrid columns={2}>
          <FormGroup>
            {proposal.agencyWorker.compliancePackUrl === null ? (
              <FormLabel>No Compliance Pack</FormLabel>
            ) : (
              <>
                <FormLabel>Compliance Pack</FormLabel>
                <a
                  href={`${window.location.origin}${proposal.agencyWorker.compliancePackUrl}`}
                  rel="noopener noreferrer"
                  style={{ display: 'block' }}
                  target="_blank"
                >
                  Download
                </a>
              </>
            )}
          </FormGroup>

          <FormGroup>
            {proposal.agencyWorker.cvUrl === null ? (
              <FormLabel>No CV Uploaded</FormLabel>
            ) : (
              <>
                <FormLabel>CV</FormLabel>
                <a
                  href={`${window.location.origin}${proposal.agencyWorker.cvUrl}`}
                  rel="noopener noreferrer"
                  style={{ display: 'block' }}
                  target="_blank"
                >
                  Download
                </a>
              </>
            )}
          </FormGroup>
        </FormGrid>
      </div>
    </Modal>
  )
}

export const ProposeWorkerContainer = styled.div`
  align-items: center;
  background-color: ${GLOBAL.backgroundColor};
  border-radius: 8px;
  height: 100%;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
`

export const ProposeWorkerDetailsContainer = styled.div`
  align-items: center;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-align: center;
  margin-bottom: 30px;
`

export const ProposeWorkerDetails = styled.div`
  display: flex;
  flex-direction: column;
`

export const ProposeWorkerDetailsName = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: underline;
  cursor: pointer;
`

export const ProposeWorkerDetailsGrade = styled(ProposeWorkerDetailsName)`
  color: ${({ color }) => color || GLOBAL.fontDark};
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 4px;
  text-decoration: none;
`

export const ProposeWorkerDetailsEmail = styled(ProposeWorkerDetailsGrade)`
  color: ${GREY.six};
  font-weight: 400;
  margin: 0;
`

export const EmploymentIcon = styled.div`
  align-items: center;
  background-color: ${GREY.two};
  border-radius: 4px;
  color: ${GREY.six};
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 21px;
  padding: 0 8px;
  justify-content: center;
  margin-right: 8px;
  margin-top: 10px;
`

const ShiftModalHeaderTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin: 0;
`

/* DE CONTRACTS STYLING ------------------------ */

export const AgencyProposalFooter = styled.div`
  margin-top: 12px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: space-between;
`
