/* eslint-disable sort-keys */
import { useMutation, useQuery } from '@apollo/client'
import {
  DefaultButton,
  FormErrorLabel,
  FormGrid,
  FormGridContainer,
  FormGroup,
  FormInput,
  FormInputWithSymbol,
  FormLabel,
  FormSelect,
  FormBreak,
  CloseButton,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@patchworkhealth/web-components'
import { ButtonAddDark } from '@patchworkhealth/web-components/icons'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import * as Yup from 'yup'
import {
  errorToast,
  loadingToast,
  successToast,
  STAFF_GROUPS,
  PAID_PER_ITEM_CREATE_ITEM,
} from '../helpers/PayPerItemItemsHelpers'

const idForFirstGrade = uuidv4()

function CreateItemModal({ departments, refetchItems, handleClose, inputs }) {
  const { showCreateModal, organisation } = inputs
  const [organisationStaffGroups, setOrganisationStaffGroups] = useState([])

  const [validate, setValidate] = useState(false)
  const [grades, setGrades] = useState([])
  const [, triggerStateUpdate] = useState(null)
  const [createItem] = useMutation(PAID_PER_ITEM_CREATE_ITEM)
  const sortedGrades = [...grades]?.sort((a, b) => a.label.localeCompare(b.label))
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      departmentId: null,
      organisationStaffGroupId: null,
      itemGradeCostsAttributes: [
        {
          id: idForFirstGrade,
          gradeId: null,
          cost: null,
        },
      ],
    },
    validateOnChange: validate,
    validationSchema: Yup.object({
      name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
      itemGradeCostsAttributes: Yup.array().of(
        Yup.object().shape({
          id: Yup.string().required('Required'),
          gradeId: Yup.number().typeError('Required').required('Select a grade'),
          cost: Yup.number()
            .typeError('Required')
            .required('Provide a unit cost')
            .max(999.99, 'Unit cost must be less than £1000'),
        })
      ),
      departmentId: Yup.number().typeError('Required').required('Select a department'),
      organisationStaffGroupId: Yup.number().typeError('Required').required('Select a staff group'),
    }),
    onSubmit: (values, { resetForm }) => {
      toast.loading('Loading', loadingToast)

      const gradesWithoutId = values.itemGradeCostsAttributes.map(({ id, ...rest }) => ({
        ...rest,
      }))

      createItem({
        variables: {
          name: values.name,
          departmentId: values.departmentId,
          organisationStaffGroupId: values.organisationStaffGroupId,
          organisationId: organisation.id,
          itemGradeCostsAttributes: gradesWithoutId,
        },
      })
        .then((response) => {
          const errors = response.data?.paidPerItemCreateItem?.errors

          if (errors.length > 0) {
            const attribute = errors[0].attribute[0].toUpperCase() + errors[0].attribute.slice(1)
            toast.update(2, { ...errorToast, render: `${attribute}: ${errors[0].message}` })
            return
          }

          handleClose()
          resetForm()
          refetchItems()

          toast.update(2, { ...successToast, render: 'Item Created' })
        })
        .catch(() => {
          toast.update(2, { ...errorToast, render: 'Item could not be created' })
        })
    },
  })

  useQuery(STAFF_GROUPS, {
    variables: {
      organisationId: organisation.id,
    },
    onCompleted: (res) => {
      if (res) setOrganisationStaffGroups(res.organisationStaffGroups)
    },
  })

  const gradeInputs = formik.values.itemGradeCostsAttributes

  return (
    <Modal backdrop="static" centered onHide={handleClose} show={showCreateModal} size="lg">
      <ModalHeader>
        <ModalTitle>Add new Item</ModalTitle>
        <CloseButton onClick={() => handleClose()} />
      </ModalHeader>

      <ModalBody style={{ minHeight: 500 }}>
        <FormGridContainer>
          <FormGrid columns={2}>
            <FormGroupMod>
              <FormLabel>
                Item Name <span>*</span>
              </FormLabel>
              <FormInput name="name" onChange={formik.handleChange} placeholder="Enter item name..." type="text" />
              {formik.errors.name && <FormErrorLabel>{formik.errors.name}</FormErrorLabel>}
            </FormGroupMod>

            <FormGroup>
              <FormLabel>
                Department <span>*</span>
              </FormLabel>
              <FormSelect
                name="reason"
                onChange={(option) => {
                  formik.setFieldValue('departmentId', option !== null ? Number(option.value) : null)
                }}
                options={departments.map((d) => ({ label: d.name, value: d.id }))}
                placeholder="Select department"
              />
              {formik.errors.departmentId && <FormErrorLabel>{formik.errors.departmentId}</FormErrorLabel>}
            </FormGroup>
          </FormGrid>
        </FormGridContainer>

        <FormBreak />

        <FormGridContainer>
          <ItemModalTitle>Staff grade rates</ItemModalTitle>

          <FormGrid columns={2} style={{ marginBottom: 16 }}>
            <FormGroupMod>
              <FormLabel>
                Staff Group <span>*</span>
              </FormLabel>
              <FormSelect
                name="reason"
                onChange={(option) => {
                  formik.setFieldValue('organisationStaffGroupId', option !== null ? Number(option.value) : null)
                  formik.setFieldValue('itemGradeCostsAttributes', [
                    {
                      id: idForFirstGrade,
                      gradeId: null,
                      cost: null,
                    },
                  ])

                  triggerStateUpdate(null)

                  const staffGroupGrades = organisationStaffGroups.find(
                    (sg) => Number(sg.id) === Number(option.value)
                  )?.grades

                  setGrades(staffGroupGrades)
                }}
                options={organisationStaffGroups.map((sg) => ({ label: sg.title, value: sg.id }))}
                placeholder="Select Staff Group"
              />
              {formik.errors.organisationStaffGroupId && (
                <FormErrorLabel>{formik.errors.organisationStaffGroupId}</FormErrorLabel>
              )}
            </FormGroupMod>
          </FormGrid>
          {gradeInputs.map((input, index) => (
            <GradesFormGrid key={input.id} columns={3} style={{ width: '80%' }}>
              <FormGroupMod>
                <FormLabel>
                  Select Grade <span>*</span>
                </FormLabel>
                <FormSelect
                  name="grade"
                  onChange={(option) => {
                    const newGrade = { ...input, gradeId: Number(option.value) }
                    const updatedInputs = [...gradeInputs]
                    updatedInputs[index] = newGrade
                    formik.setFieldValue('itemGradeCostsAttributes', updatedInputs)
                    triggerStateUpdate(null)
                  }}
                  options={sortedGrades?.filter((sg) => {
                    const isNotCurrentGrade = Number(sg.value) !== input.gradeId
                    const isNotSelectedAlready = !gradeInputs.map((gi) => gi.gradeId).includes(Number(sg.value))

                    return isNotCurrentGrade && isNotSelectedAlready
                  })}
                  placeholder="Select Grade"
                />

                {formik.errors.itemGradeCostsAttributes?.[index]?.gradeId && (
                  <FormErrorLabel>{formik.errors.itemGradeCostsAttributes[index].gradeId}</FormErrorLabel>
                )}
              </FormGroupMod>
              <FormGroupMod>
                <FormLabel>
                  Unit cost <span>*</span>
                </FormLabel>
                <ModFormInputWithSymbol
                  before
                  max={999.99}
                  name="unitCost"
                  onChange={(e) => {
                    const newGrade = { ...input, cost: Number(e.target.value) || null }
                    const updatedInputs = [...gradeInputs]
                    updatedInputs[index] = newGrade
                    formik.setFieldValue('itemGradeCostsAttributes', updatedInputs)
                    triggerStateUpdate(null)
                  }}
                  placeholder="Enter cost"
                  symbol=" £ "
                  type="number"
                />
                {formik.errors.itemGradeCostsAttributes?.[index]?.cost && (
                  <FormErrorLabel>{formik.errors.itemGradeCostsAttributes[index].cost}</FormErrorLabel>
                )}
              </FormGroupMod>

              {gradeInputs.length > 1 && (
                <FormGroupMod>
                  <RemoveBtn
                    as="a"
                    onClick={() => {
                      const updatedInputs = [...gradeInputs.filter((g) => g.id !== input.id)]
                      formik.setFieldValue('itemGradeCostsAttributes', updatedInputs)
                      triggerStateUpdate(null)
                    }}
                  >
                    Remove
                  </RemoveBtn>
                </FormGroupMod>
              )}
            </GradesFormGrid>
          ))}
          {gradeInputs.length < sortedGrades.length && (
            <FormGrid columns={2}>
              <AddStaffGroupButton
                action={() => {
                  const newGrade = { id: uuidv4(), gradeId: null, cost: null }
                  formik.setFieldValue('itemGradeCostsAttributes', [...gradeInputs, newGrade])
                  triggerStateUpdate(null)
                }}
                icon={ButtonAddDark}
                style={{ marginRight: 20 }}
                text="Add another staff grade"
                type="grey"
              />
            </FormGrid>
          )}
        </FormGridContainer>
      </ModalBody>

      <ModalFooter>
        <div />
        <div>
          <DefaultButton action={() => handleClose()} text="Cancel" type="white" />
          <DefaultButton
            action={() => {
              setValidate(true)
              formik.handleSubmit()
            }}
            color="blue"
            text="Save Item"
          />
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default CreateItemModal

const ItemModalTitle = styled.h4`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`

const AddStaffGroupButton = styled(DefaultButton)`
  width: 70%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  font-weight: 500;

  span {
    top: 0;
  }
`

const RemoveBtn = styled(DefaultButton)`
  display: inline-block;
  color: #dc5a54;
  text-decoration: underline;
  margin-top: 33px;

  &:hover {
    color: #dc5a54;
    cursor: pointer;
    text-decoration: underline;
  }
`

const GradesFormGrid = styled(FormGrid)`
  margin-bottom: 20px;
`

export const FormGroupMod = styled(FormGroup)`
  position: relative;
  svg:hover + div {
    opacity: 1 !important;
  }
`

const ModFormInputWithSymbol = styled(FormInputWithSymbol)`
  &:disabled {
    background-color: hsl(0, 0%, 95%);
  }
`
